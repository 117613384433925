import { gql } from '@apollo/client';

export default class ApplicantsGraphql {
    static GET_APPLICANTS_LIST_BY_COMPANY = gql`
    query applicants(
          $paging: PaginationInput
          $sort: [SortField]
          $filters: [Filters]
          $search: String
          $usePaging: Boolean
    ) {
    applicants(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
          success
          message
          error
          total
          data {
            ApplicantId
            Name
            LastName
            Fase {
              Es
              Fr
              En
            }
          }
        }
    }`;

    static GET_APPLICANTS_LIST_USER = gql`
    query applicants(
          $paging: PaginationInput
          $sort: [SortField]
          $filters: [Filters]
          $search: String
          $usePaging: Boolean
    ) {
      applicants(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
        success
        message
        error
        total
        pages
        data {
          ApplicantId
          Name
          LastName
          UserId
          User{
            Email
            PictureURL
            ValidatedAccount
          }
        }
      }
    }`;

    static GET_APPLICANTS_BY_COMPANY_AND_FASE = gql`
  query applicants(
    $paging: PaginationInput
    $sort: [SortField]
    $filters: [Filters]
    $search: String
    $usePaging: Boolean
  ) {
      applicants(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging){
        success
        message
        error
        total
        data {
          ApplicantId
          UserId
          Tags
          Name
          LastName
          Email
          FaseId
          # PercentageFilled
          # Profession
          # JobPositionsLabels
          # Age
          User{
            PictureURL
          }
        }
      }
  }`;

    static GET_APPLICANT_MAIN_LIST = gql`
    query applicantsMainList(
      $paging: PaginationInput
      $sort: [SortField]
      $filters: ApplicantParameters
      $search: String
      $usePaging: Boolean
    ) {
        applicantsMainList(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging){
          success
          message
          error
          total
          pages
          data {
            ApplicantId
            UserId
            Name
            LastName
            Email
            FaseId
            Birthday
            ResidentCity
            PhoneNumberOne
            JobPositionIds
            CountryId
            ProfessionId
            User{
              PictureURL
            }
          }
        }
    }`;

    static GET_APPLICANT = gql`
    query applicant($id: ID!) {
      applicant(id: $id) {
        success
        message
        error
        data {
        ApplicantId
        Name
        LastName
        Birthday
        MaritalStatusId
        GenderId
        IdentificationCode
        HasDriverLicenseId
        DriverLicenseCategory
        ProfessionId
        EnglishLevelId
        FrenchLevelId
        Childrens
        AboutMe
        CountryId
        City
        Town
        ResidentCountryId
        ResidentCity
        ResidentTown
        AddressLineOne
        AddressLineTwo
        PhoneNumberOne
        PhoneNumberTwo
        Email
        HasPassportId
        PassportNumber
        ExpirationPassport
        CountryVisitedOneId
        CountryVisitedOneStartDate
        CountryVisitedOneEndDate
        CountryVisitedTwoId
        CountryVisitedTwoStartDate
        CountryVisitedTwoEndDate

        LivedCountryMoreThan6MonthsId
        LivedCountryId
        LivedCountryDate
        LivedCountryMigrateStatus

        VisaDeniedId
        VisaDeniedDate
        VisaDeniedComments
        JobPositionIds
        JobPositions
        UserId
        User {
          PictureURL
        }

        Studies{
          StudiesId
          StudyCenter
          StartDate
          EndDate
          CertificateObtained
          CertificateObtainedType
          CertificateUrl
        }
        Experiences{
          ExperienceId
          CompanyName
          StartDate
          EndDate
          JobTitle
          DescriptionJob
          CompanyLocation
        }
        LeadSourceId
        }
      }
    }`;

    static CREATE_APPLICANT = gql`
    mutation addApplicant($record: ApplicantInput!) {
      addApplicant(record: $record){
        success,
        message,
        error,
        data{
          ApplicantId
        }
      }
    }`;

    static UPDATE_APPLICANT = gql`
    mutation updateApplicant($record: ApplicantInput!) {
      updateApplicant(record: $record){
        success,
        message,
        error,
        data{
          ApplicantId
        }
      }
    }`;

    static GET_JOB_DETAILS = gql`
    query jobPositionDetails(
          $paging: PaginationInput
          $sort: [SortField]
          $filters: [Filters]
          $search: String
          $usePaging: Boolean
    ) {
    jobPositionDetails(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
          message
          error
          total
          data {
            JobPositionId
            JobPositionDetailId
            Description
          }
        }
    }`;

    static GET_DASHBOARD_INFO = gql`
      query getDashboardInfo($filters: [Filters]){
        getDashboardInfo{
          Description
          Total
          Key
          Sorting
        }
        lastLogins{
          Username
          FullName
          PictureUrl
          ApplicantId
          LastDate
          CountryName
        }
        testApplicants(filters: $filters) {
          data {
            TestApplicantId
            ApplicantId
            TypeTest
            Status
            Active
            ExpirationDate
          }
        }
      }`;

    static DASHBOARD_CHANGED = gql`
    subscription dashboardChanged{
      dashboardChanged{
        success
        id
        action
      }
    }`;

    static GET_APPLICANT_TO_SEND_FILES = gql`
    query applicants(
      $paging: PaginationInput
      $sort: [SortField]
      $filters: [Filters]
      $usePaging: Boolean
    ) {
        applicants(paging: $paging, sort: $sort, filters: $filters, usePaging: $usePaging){
          success
          message
          error
          total
          data {
            ApplicantId
            Name
            LastName
            Email
          }
        }
    }`;

    static GET_PERCENTAGE_BY_APPLICANT = gql`
    query getPercentageByApplicant($ids: [ID!]) {
      getPercentageByApplicant(ids: $ids){
        success
        message
        error
        data {
          ApplicantId
          PercentageFilled
          PendingPoints
        }
      }
    }`;

    static GET_MESSAGE_BY_APPLICANT = gql`
    query messagesPaging($paging: PaginationInput
      $sort: [SortField]
      $filters: [Filters]
      $search: String
      $usePaging: Boolean) {
      messagesPaging(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging){
        success
        message
        error
        data {
          MessageId
          ApplicantId
          UserId
          UserName
          DataMessage
          MessageDate
          Importance
        }
      }
    }`;

    static SAVE_MESSAGE = gql`
    mutation addMessage($record: MessageInput!) {
      addMessage(record: $record){
        success,
        message,
        error,
        data{
          MessageId
          ApplicantId
          UserId
          UserName
          DataMessage
          MessageDate
          Importance
        }
      }
    }`;

    static MESSAGE_CHANGED = gql`
    subscription messagesChanged{
      messagesChanged{
        success
        id
        action
        data
      }
    }
    `;

    static APPLICANT_ASSIGN_TO_PROCESS = gql`
    query applicantAssignToProcess($ids: [Int!]!, $updateType: String!, $value: String ) {
      applicantAssignToProcess(ids: $ids, updateType: $updateType, value: $value)
    }`;

    static PRINT_APPLICANT_LIST = gql`
    query printApplicantList(
      $paging: PaginationInput
          $sort: [SortField]
          $filters: ApplicantParameters
          $search: String
          $usePaging: Boolean,
          $fileType: String,
          $options: String!
    ) {
      printApplicantList(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging, fileType: $fileType, options: $options)
    }`;

    static PRINT_APPLICANT_REPORT = gql`
    query printApplicantReport($id: Int!, $options: String!) {
      printApplicantReport(id: $id, options: $options)
    }`;

    static SEND_EMAIL = gql`
    mutation sendEmail($record: EmailInput!) {
      sendEmail(record: $record){
        success
        message
        error
      }
    }`;

    static GET_USER_LIST_DROPDOWN = gql`
    query applicantDropdown {
      applicantDropdown {
        UserId
        FullName
      }
    }`;

    static GET_USER_COMPANY_LIST_DROPDOWN = gql`
    query usersCompanyDropdown {
      usersCompanyDropdown {
        UserId
        FullName
      }
    }`;

    static GET_DASHBOARD_INFO_FOR_USER = gql`
    query getDashboardInfoForUser($ids: [ID!], $filters: [Filters]){
      getPercentageByApplicant(ids: $ids){
        success
        message
        error
        data {
          ApplicantId
          PercentageFilled
          PendingPoints
        }
      }
      testApplicants(filters: $filters) {
        data {
          TestApplicantId
          ApplicantId
          TypeTest
          Status
          Active
          ExpirationDate
        }
      }
    }`;

    static GET_EMAIL_LIST_USER = gql`
query users(
      $paging: PaginationInput
      $sort: [SortField]
      $filters: [Filters]
      $search: String
      $usePaging: Boolean
) {
  users(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
    success
    message
    error
    total
    pages
    data {
      UserId
      Email
    }
  }
}`;
}
