import React, { useContext } from 'react';
import AppContext from '../../../config/appContext';

function FooterCopyRight() {
    const { languageTitles } = useContext(AppContext);
    return (
        <p className="text-center text-grey-darker">
            &copy;
            {' '}
            {languageTitles.loginCopyRight}
            {' '}
            2024
        </p>
    );
}

export default FooterCopyRight;
