import { gql } from '@apollo/client';

export default class NotificationGraphql {
    static GET_NOTIFICATION_BY_USER = gql`
query notificationByUser($userId: Int){
  notificationByUser(userId: $userId) {
    success
    message
    error
    data {
      NotificationId
      Message
      Link
      Read
      CreatedOn
      CreatedBy
      Title
    }
  }
}`;

    static READ_NOTIFICATION = gql`
mutation readNotification($userId: Int!, $notificationId: Int!) {
  readNotification(userId: $userId, notificationId: $notificationId)
}
`;

    static NOTIFICATION_CHANGED = gql`
      subscription notificationChanged{
        notificationChanged{
          success
          id
          action
        }
      }
`;
}
