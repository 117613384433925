import { gql } from '@apollo/client';

export default class JobPositionGraphql {
    static GET_JOB_POSITION_LIST = gql`
    query jobPositions(
      $paging: PaginationInput
      $sort: [SortField]
      $filters: [Filters]
      $search: String
      $usePaging: Boolean
    ) {
      jobPositions(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
        success
        message
        error
        total
        data {
          JobPositionId
          Es
          En
          Fr
          PictureUrl
          JobPositionDetail {
            JobPositionDetailId
            Es
            En
            Fr
          }
        }
      }
  }`;

    static GET_JOB_POSITION_FOR_SKILLS_LIST = gql`
query jobPositions(
  $paging: PaginationInput
  $sort: [SortField]
  $filters: [Filters]
  $search: String
  $usePaging: Boolean
) {
  jobPositions(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
    success
    message
    error
    total
    data {
      JobPositionId
      Es
      En
      Fr
      JobPositionDetail {
        JobPositionDetailId
        Es
        En
        Fr
      }
    }
  }
}`;
}
