/* eslint-disable import/prefer-default-export */
const FetchPolicy = Object.freeze({
    NETWORK_ONLY: 'network-only',
    NO_CACHE: 'no-cache',
    CACHE_FIRST: 'cache-first',
});

const NotificationType = Object.freeze({
    DANGER: 'danger',
    SUCCESS: 'success',
    INFO: 'info',
});

const LineActions = {
    NEW: 'New',
    UPDATED: 'Updated',
    DELETED: 'Deleted',
};

const TestStatus = {
    ACTIVA: 'Activa',
    COMPLETADA: 'Completada',
    VENCIDA: 'Vencida',
    PROCESO: 'Proceso',
    PENDIENTE: 'Pendiente',
};

const FileType = {
    CURRICULUM: 27,
    IDENTIFICATION: 28,
    PASSPORT: 29,
    DIPLOMA: 30,
    TITLE: 31,
    CERTIFICATE: 32,
    PICTURE_PROFILE: 33,
    PICTURE_COMPANY: 34,
    FILES_RECEIVED: 35,
    FILES_SENT: 36,
    FILES_TEMPLATE: 37,
    GOVERMENT_FILES: 70,
    LICENCE: 73,
    OTHERS: 74,
};

const Catalogs = {
    MARITAL_STATUS: 'MaritalStatus',
    GENDER: 'Gender',
    FASE: 'Fase',
    FILE_TYPE: 'FileType',
    LANGUAGE_LEVEL: 'LanguageLevel',
    OPTIONAL: 'Optional',
    PASSPORT_OPTIONS: 'PassportOptions',
    COUNTRY: 'Country',
    LEAD_SOURCE: 'LeadSource',
    COMPANY_ORGANIZATION_TYPE: 'CompanyOrganizationType',
    INTERNATIONAL_RECRUITMENT_SALARY_TYPE: 'InternationalRecruitmentSalaryType',
    OVERTIME_HOURS_ALLOWED_AFTER: 'OvertimeHoursAllowedAfter',
    QUARTER_SCHEDULE: 'QuarterSchedule',
    SALARAY_PAYMENT_FRECUENCY: 'SalaryPaymentFrecuency',
    BONUS: 'Bonus',
    EDUCATIONAL_LEVEL: 'EducationalLevel',
    EMPLOYMENT_STATUS: 'EmploymentStatus',
    PROFESSION: 'Profession',
    POSITION: 'Position',
};

const EnrollProcess = {
    FASE: 'fase',
    TAG: 'tag',
    COMPANY: 'company',
    REMOVE_COMPANY: 'removeCompany',
};

const DateFormat = {
    LONG_DATETIME_AMPM: 'ddd DD MMM YY, hh:mm A',
    DEFAULT_DATETIME: 'MM/DD/YYYY HH:mm:ss',
    DATETIME_WITHOUT_SECONDS: 'MM/DD/YYYY hh:mm A',
    TIME_AMPM: 'hh:mm A',
    DAY_MONTH: 'MMM DD',
};

const SubscriptionType = {
    ADDED: 'added',
    UPDATED: 'updated',
    DELETED: 'deleted',
    USED: 'used',
};

export {
    FetchPolicy, NotificationType, LineActions, TestStatus, FileType, Catalogs,
    EnrollProcess, DateFormat, SubscriptionType,
};
