/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

function ContentForProcess(props) {
    const { reduceHeight, children } = props;
    const initHeight = (window.innerHeight > 600) ? window.innerHeight - reduceHeight : 210;

    return (
        <div style={{ height: `${initHeight}px` }}>
            {children}
        </div>
    );
}

ContentForProcess.propTypes = {
    reduceHeight: PropTypes.number,
};

ContentForProcess.defaultProps = {
    reduceHeight: 218,
};

export default ContentForProcess;
