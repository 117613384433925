import React from 'react';
import Logo from '../../layout/logo/logo';

function HeaderTitle() {
    return (
        <div className="login-header">
            <div className="brand">
                <div className="d-flex align-items-center">
                    <Logo customWidth="10" />
                    <b>Travailleur Sans Frontière</b>
                </div>
            </div>
        </div>

    );
}

export default HeaderTitle;
