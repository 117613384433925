/* eslint-disable import/prefer-default-export */
const pdfOptions = {
    format: 'A4',
    orientation: 'portrait',
    border: {
        left: 20,
        right: 20,
        bottom: 20,
        top: 20,
    },
};

export { pdfOptions };
