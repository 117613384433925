/* eslint-disable no-undef */
import { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const NonAuthLayout = (props) => {
    const { children, location } = props;

    const capitalizeFirstLetter = useCallback((string) => string.charAt(1).toUpperCase() + string.slice(2), []);

    useEffect(() => {
        const currentage = capitalizeFirstLetter(location.pathname);

        document.title = currentage;
    }, [location.pathname, capitalizeFirstLetter]);

    return children;
};

export default (withRouter(NonAuthLayout));
