import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import CompanyGraphql from '../../../servicesapollo/companyGraphql';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import AppContext from '../../../config/appContext';
import useMessageHelper from '../../../helpers/messageHelper';

function CompanyDropdown(props) {
    const { showNotification, languageTitles } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const {
        returnSelectedValue, name, value, placeHolder, className,
    } = props;

    const [data, setData] = useState([]);

    const [getData] = useLazyQuery(CompanyGraphql.GET_COMPANY_LIST, {
        onCompleted: (res) => {
            if (res.companies) {
                const {
                    data: dataResult, success, message, error,
                } = res.companies;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                const dataProcessed = dataResult.map((c) => ({
                    value: c.CompanyId,
                    label: c.CompanyName,
                }));

                setData(dataProcessed);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        getData({
            variables: {
                usePaging: false,
            },
        });
    }, []);

    const onChange = (selectedOption, options) => {
        returnSelectedValue(selectedOption?.value ?? 0, options);
    };

    const selectedValue = data.filter((c) => Number(c.value) === Number(value))
        .map((c) => (
            {
                value: c.value,
                label: c.label,
            }
        ));

    return (
        <Select
            isClearable
            name={name}
            onChange={(selectedOption, options) => onChange(selectedOption, options)}
            options={data}
            value={selectedValue}
            placeholder={placeHolder}
            Clearable
            className={className}
        />
    );
}

CompanyDropdown.propTypes = {
    returnSelectedValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    placeHolder: PropTypes.string,
    className: PropTypes.string,
};

CompanyDropdown.defaultProps = {
    value: 0,
    placeHolder: 'Seleccione',
    className: '',
};

export default CompanyDropdown;
