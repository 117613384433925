/* eslint-disable max-len */
const patronType = {
    7777: { patronName: 'Superactivo', patronNumber: 16 },
    7776: { patronName: 'Superactivo', patronNumber: 16 },
    7775: { patronName: 'Superactivo', patronNumber: 16 },
    7774: { patronName: 'Alentador', patronNumber: 1 },
    7773: { patronName: 'Alentador', patronNumber: 1 },
    7772: { patronName: 'Alentador', patronNumber: 1 },
    7771: { patronName: 'Alentador', patronNumber: 1 },
    7767: { patronName: 'Superactivo', patronNumber: 16 },
    7766: { patronName: 'Superactivo', patronNumber: 16 },
    7765: { patronName: 'Superactivo', patronNumber: 16 },
    7764: { patronName: 'Alentador', patronNumber: 1 },
    7763: { patronName: 'Alentador', patronNumber: 1 },
    7762: { patronName: 'Alentador', patronNumber: 1 },
    7761: { patronName: 'Alentador', patronNumber: 1 },
    7757: { patronName: 'Superactivo', patronNumber: 16 },
    7756: { patronName: 'Superactivo', patronNumber: 16 },
    7755: { patronName: 'Superactivo', patronNumber: 16 },
    7754: { patronName: 'Alentador', patronNumber: 1 },
    7753: { patronName: 'Alentador', patronNumber: 1 },
    7752: { patronName: 'Alentador', patronNumber: 1 },
    7751: { patronName: 'Alentador', patronNumber: 1 },
    7747: { patronName: 'Evaluador', patronNumber: 10 },
    7746: { patronName: 'Evaluador', patronNumber: 10 },
    7745: { patronName: 'Evaluador', patronNumber: 10 },
    7744: { patronName: 'Alentador', patronNumber: 1 },
    7743: { patronName: 'Alentador', patronNumber: 1 },
    7742: { patronName: 'Alentador', patronNumber: 1 },
    7741: { patronName: 'Alentador', patronNumber: 1 },
    7737: { patronName: 'Evaluador', patronNumber: 10 },
    7736: { patronName: 'Evaluador', patronNumber: 10 },
    7735: { patronName: 'Evaluador', patronNumber: 10 },
    7734: { patronName: 'Alentador', patronNumber: 1 },
    7733: { patronName: 'Alentador', patronNumber: 1 },
    7732: { patronName: 'Alentador', patronNumber: 1 },
    7731: { patronName: 'Alentador', patronNumber: 1 },
    7727: { patronName: 'Evaluador', patronNumber: 10 },
    7726: { patronName: 'Evaluador', patronNumber: 10 },
    7725: { patronName: 'Evaluador', patronNumber: 10 },
    7724: { patronName: 'Alentador', patronNumber: 1 },
    7723: { patronName: 'Alentador', patronNumber: 1 },
    7722: { patronName: 'Alentador', patronNumber: 1 },
    7721: { patronName: 'Alentador', patronNumber: 1 },
    7717: { patronName: 'Evaluador', patronNumber: 10 },
    7716: { patronName: 'Evaluador', patronNumber: 10 },
    7715: { patronName: 'Evaluador', patronNumber: 10 },
    7714: { patronName: 'Alentador', patronNumber: 1 },
    7713: { patronName: 'Alentador', patronNumber: 1 },
    7712: { patronName: 'Alentador', patronNumber: 1 },
    7711: { patronName: 'Alentador', patronNumber: 1 },
    7677: { patronName: 'Superactivo', patronNumber: 16 },
    7676: { patronName: 'Superactivo', patronNumber: 16 },
    7675: { patronName: 'Superactivo', patronNumber: 16 },
    7674: { patronName: 'Realizador', patronNumber: 2 },
    7673: { patronName: 'Realizador', patronNumber: 2 },
    7672: { patronName: 'Realizador', patronNumber: 2 },
    7671: { patronName: 'Realizador', patronNumber: 2 },
    7667: { patronName: 'Superactivo', patronNumber: 16 },
    7666: { patronName: 'Superactivo', patronNumber: 16 },
    7665: { patronName: 'Superactivo', patronNumber: 16 },
    7664: { patronName: 'Alentador', patronNumber: 1 },
    7663: { patronName: 'Alentador', patronNumber: 1 },
    7662: { patronName: 'Alentador', patronNumber: 1 },
    7661: { patronName: 'Alentador', patronNumber: 1 },
    7657: { patronName: 'Superactivo', patronNumber: 16 },
    7656: { patronName: 'Superactivo', patronNumber: 16 },
    7655: { patronName: 'Superactivo', patronNumber: 16 },
    7654: { patronName: 'Alentador', patronNumber: 1 },
    7653: { patronName: 'Alentador', patronNumber: 1 },
    7652: { patronName: 'Alentador', patronNumber: 1 },
    7651: { patronName: 'Alentador', patronNumber: 1 },
    7647: { patronName: 'Creativo', patronNumber: 4 },
    7646: { patronName: 'Creativo', patronNumber: 4 },
    7645: { patronName: 'Creativo', patronNumber: 4 },
    7644: { patronName: 'Alentador', patronNumber: 1 },
    7643: { patronName: 'Alentador', patronNumber: 1 },
    7642: { patronName: 'Alentador', patronNumber: 1 },
    7641: { patronName: 'Alentador', patronNumber: 1 },
    7637: { patronName: 'Creativo', patronNumber: 4 },
    7636: { patronName: 'Creativo', patronNumber: 4 },
    7635: { patronName: 'Creativo', patronNumber: 4 },
    7634: { patronName: 'Alentador', patronNumber: 1 },
    7633: { patronName: 'Alentador', patronNumber: 1 },
    7632: { patronName: 'Alentador', patronNumber: 1 },
    7631: { patronName: 'Alentador', patronNumber: 1 },
    7627: { patronName: 'Creativo', patronNumber: 4 },
    7626: { patronName: 'Creativo', patronNumber: 4 },
    7625: { patronName: 'Creativo', patronNumber: 4 },
    7624: { patronName: 'Alentador', patronNumber: 1 },
    7623: { patronName: 'Alentador', patronNumber: 1 },
    7622: { patronName: 'Alentador', patronNumber: 1 },
    7621: { patronName: 'Alentador', patronNumber: 1 },
    7617: { patronName: 'Creativo', patronNumber: 4 },
    7616: { patronName: 'Creativo', patronNumber: 4 },
    7615: { patronName: 'Creativo', patronNumber: 4 },
    7614: { patronName: 'Alentador', patronNumber: 1 },
    7613: { patronName: 'Alentador', patronNumber: 1 },
    7612: { patronName: 'Alentador', patronNumber: 1 },
    7611: { patronName: 'Alentador', patronNumber: 1 },
    7577: { patronName: 'Superactivo', patronNumber: 16 },
    7576: { patronName: 'Superactivo', patronNumber: 16 },
    7575: { patronName: 'Superactivo', patronNumber: 16 },
    7574: { patronName: 'Realizador', patronNumber: 2 },
    7573: { patronName: 'Realizador', patronNumber: 2 },
    7572: { patronName: 'Realizador', patronNumber: 2 },
    7571: { patronName: 'Realizador', patronNumber: 2 },
    7567: { patronName: 'Superactivo', patronNumber: 16 },
    7566: { patronName: 'Superactivo', patronNumber: 16 },
    7565: { patronName: 'Superactivo', patronNumber: 16 },
    7564: { patronName: 'Realizador', patronNumber: 2 },
    7563: { patronName: 'Realizador', patronNumber: 2 },
    7562: { patronName: 'Realizador', patronNumber: 2 },
    7561: { patronName: 'Realizador', patronNumber: 2 },
    7557: { patronName: 'Superactivo', patronNumber: 16 },
    7556: { patronName: 'Superactivo', patronNumber: 16 },
    7555: { patronName: 'Superactivo', patronNumber: 16 },
    7554: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7553: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7552: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7551: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7547: { patronName: 'Creativo', patronNumber: 4 },
    7546: { patronName: 'Creativo', patronNumber: 4 },
    7545: { patronName: 'Creativo', patronNumber: 4 },
    7544: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7543: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7542: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7541: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7537: { patronName: 'Creativo', patronNumber: 4 },
    7536: { patronName: 'Creativo', patronNumber: 4 },
    7535: { patronName: 'Creativo', patronNumber: 4 },
    7534: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7533: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7532: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7531: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7527: { patronName: 'Creativo', patronNumber: 4 },
    7526: { patronName: 'Creativo', patronNumber: 4 },
    7525: { patronName: 'Creativo', patronNumber: 4 },
    7524: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7523: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7522: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7521: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7517: { patronName: 'Creativo', patronNumber: 4 },
    7516: { patronName: 'Creativo', patronNumber: 4 },
    7515: { patronName: 'Creativo', patronNumber: 4 },
    7514: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7513: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7512: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7511: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7477: { patronName: 'Investigador', patronNumber: 13 },
    7476: { patronName: 'Investigador', patronNumber: 13 },
    7475: { patronName: 'Investigador', patronNumber: 13 },
    7474: { patronName: 'Realizador', patronNumber: 2 },
    7473: { patronName: 'Realizador', patronNumber: 2 },
    7472: { patronName: 'Realizador', patronNumber: 2 },
    7471: { patronName: 'Realizador', patronNumber: 2 },
    7467: { patronName: 'Investigador', patronNumber: 13 },
    7466: { patronName: 'Investigador', patronNumber: 13 },
    7465: { patronName: 'Investigador', patronNumber: 13 },
    7464: { patronName: 'Realizador', patronNumber: 2 },
    7463: { patronName: 'Realizador', patronNumber: 2 },
    7462: { patronName: 'Realizador', patronNumber: 2 },
    7461: { patronName: 'Realizador', patronNumber: 2 },
    7457: { patronName: 'Investigador', patronNumber: 13 },
    7456: { patronName: 'Investigador', patronNumber: 13 },
    7455: { patronName: 'Investigador', patronNumber: 13 },
    7454: { patronName: 'Realizador', patronNumber: 2 },
    7453: { patronName: 'Realizador', patronNumber: 2 },
    7452: { patronName: 'Realizador', patronNumber: 2 },
    7451: { patronName: 'Realizador', patronNumber: 2 },
    7447: { patronName: 'Creativo', patronNumber: 4 },
    7446: { patronName: 'Creativo', patronNumber: 4 },
    7445: { patronName: 'Creativo', patronNumber: 4 },
    7444: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7443: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7442: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7441: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7437: { patronName: 'Creativo', patronNumber: 4 },
    7436: { patronName: 'Creativo', patronNumber: 4 },
    7435: { patronName: 'Creativo', patronNumber: 4 },
    7434: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7433: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7432: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7431: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7427: { patronName: 'Creativo', patronNumber: 4 },
    7426: { patronName: 'Creativo', patronNumber: 4 },
    7425: { patronName: 'Creativo', patronNumber: 4 },
    7424: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7423: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7422: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7421: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7417: { patronName: 'Creativo', patronNumber: 4 },
    7416: { patronName: 'Creativo', patronNumber: 4 },
    7415: { patronName: 'Creativo', patronNumber: 4 },
    7414: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7413: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7412: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7411: { patronName: 'Orientado a resultados', patronNumber: 14 },
    7377: { patronName: 'Investigador', patronNumber: 13 },
    7376: { patronName: 'Investigador', patronNumber: 13 },
    7375: { patronName: 'Investigador', patronNumber: 13 },
    7374: { patronName: 'Realizador', patronNumber: 2 },
    7373: { patronName: 'Realizador', patronNumber: 2 },
    7372: { patronName: 'Realizador', patronNumber: 2 },
    7371: { patronName: 'Realizador', patronNumber: 2 },
    7367: { patronName: 'Investigador', patronNumber: 13 },
    7366: { patronName: 'Investigador', patronNumber: 13 },
    7365: { patronName: 'Investigador', patronNumber: 13 },
    7364: { patronName: 'Realizador', patronNumber: 2 },
    7363: { patronName: 'Realizador', patronNumber: 2 },
    7362: { patronName: 'Realizador', patronNumber: 2 },
    7361: { patronName: 'Realizador', patronNumber: 2 },
    7357: { patronName: 'Investigador', patronNumber: 13 },
    7356: { patronName: 'Investigador', patronNumber: 13 },
    7355: { patronName: 'Investigador', patronNumber: 13 },
    7354: { patronName: 'Realizador', patronNumber: 2 },
    7353: { patronName: 'Realizador', patronNumber: 2 },
    7352: { patronName: 'Realizador', patronNumber: 2 },
    7351: { patronName: 'Realizador', patronNumber: 2 },
    7347: { patronName: 'Creativo', patronNumber: 4 },
    7346: { patronName: 'Creativo', patronNumber: 4 },
    7345: { patronName: 'Creativo', patronNumber: 4 },
    7344: { patronName: 'Resolutivo', patronNumber: 11 },
    7343: { patronName: 'Resolutivo', patronNumber: 11 },
    7342: { patronName: 'Resolutivo', patronNumber: 11 },
    7341: { patronName: 'Resolutivo', patronNumber: 11 },
    7337: { patronName: 'Creativo', patronNumber: 4 },
    7336: { patronName: 'Creativo', patronNumber: 4 },
    7335: { patronName: 'Creativo', patronNumber: 4 },
    7334: { patronName: 'Resolutivo', patronNumber: 11 },
    7333: { patronName: 'Resolutivo', patronNumber: 11 },
    7332: { patronName: 'Resolutivo', patronNumber: 11 },
    7331: { patronName: 'Resolutivo', patronNumber: 11 },
    7327: { patronName: 'Creativo', patronNumber: 4 },
    7326: { patronName: 'Creativo', patronNumber: 4 },
    7325: { patronName: 'Creativo', patronNumber: 4 },
    7324: { patronName: 'Resolutivo', patronNumber: 11 },
    7323: { patronName: 'Resolutivo', patronNumber: 11 },
    7322: { patronName: 'Resolutivo', patronNumber: 11 },
    7321: { patronName: 'Resolutivo', patronNumber: 11 },
    7317: { patronName: 'Creativo', patronNumber: 4 },
    7316: { patronName: 'Creativo', patronNumber: 4 },
    7315: { patronName: 'Creativo', patronNumber: 4 },
    7314: { patronName: 'Resolutivo', patronNumber: 11 },
    7313: { patronName: 'Resolutivo', patronNumber: 11 },
    7312: { patronName: 'Resolutivo', patronNumber: 11 },
    7311: { patronName: 'Resolutivo', patronNumber: 11 },
    7277: { patronName: 'Investigador', patronNumber: 13 },
    7276: { patronName: 'Investigador', patronNumber: 13 },
    7275: { patronName: 'Investigador', patronNumber: 13 },
    7274: { patronName: 'Realizador', patronNumber: 2 },
    7273: { patronName: 'Realizador', patronNumber: 2 },
    7272: { patronName: 'Realizador', patronNumber: 2 },
    7271: { patronName: 'Realizador', patronNumber: 2 },
    7267: { patronName: 'Investigador', patronNumber: 13 },
    7266: { patronName: 'Investigador', patronNumber: 13 },
    7265: { patronName: 'Investigador', patronNumber: 13 },
    7264: { patronName: 'Realizador', patronNumber: 2 },
    7263: { patronName: 'Realizador', patronNumber: 2 },
    7262: { patronName: 'Realizador', patronNumber: 2 },
    7261: { patronName: 'Realizador', patronNumber: 2 },
    7257: { patronName: 'Investigador', patronNumber: 13 },
    7256: { patronName: 'Investigador', patronNumber: 13 },
    7255: { patronName: 'Investigador', patronNumber: 13 },
    7254: { patronName: 'Realizador', patronNumber: 2 },
    7253: { patronName: 'Realizador', patronNumber: 2 },
    7252: { patronName: 'Realizador', patronNumber: 2 },
    7251: { patronName: 'Realizador', patronNumber: 2 },
    7247: { patronName: 'Creativo', patronNumber: 4 },
    7246: { patronName: 'Creativo', patronNumber: 4 },
    7245: { patronName: 'Creativo', patronNumber: 4 },
    7244: { patronName: 'Resolutivo', patronNumber: 11 },
    7243: { patronName: 'Resolutivo', patronNumber: 11 },
    7242: { patronName: 'Resolutivo', patronNumber: 11 },
    7241: { patronName: 'Resolutivo', patronNumber: 11 },
    7237: { patronName: 'Creativo', patronNumber: 4 },
    7236: { patronName: 'Creativo', patronNumber: 4 },
    7235: { patronName: 'Creativo', patronNumber: 4 },
    7234: { patronName: 'Resolutivo', patronNumber: 11 },
    7233: { patronName: 'Resolutivo', patronNumber: 11 },
    7232: { patronName: 'Resolutivo', patronNumber: 11 },
    7231: { patronName: 'Resolutivo', patronNumber: 11 },
    7227: { patronName: 'Creativo', patronNumber: 4 },
    7226: { patronName: 'Creativo', patronNumber: 4 },
    7225: { patronName: 'Creativo', patronNumber: 4 },
    7224: { patronName: 'Resolutivo', patronNumber: 11 },
    7223: { patronName: 'Resolutivo', patronNumber: 11 },
    7222: { patronName: 'Resolutivo', patronNumber: 11 },
    7221: { patronName: 'Resolutivo', patronNumber: 11 },
    7217: { patronName: 'Creativo', patronNumber: 4 },
    7216: { patronName: 'Creativo', patronNumber: 4 },
    7215: { patronName: 'Creativo', patronNumber: 4 },
    7214: { patronName: 'Resolutivo', patronNumber: 11 },
    7213: { patronName: 'Resolutivo', patronNumber: 11 },
    7212: { patronName: 'Resolutivo', patronNumber: 11 },
    7211: { patronName: 'Resolutivo', patronNumber: 11 },
    7177: { patronName: 'Investigador', patronNumber: 13 },
    7176: { patronName: 'Investigador', patronNumber: 13 },
    7175: { patronName: 'Investigador', patronNumber: 13 },
    7174: { patronName: 'Realizador', patronNumber: 2 },
    7173: { patronName: 'Realizador', patronNumber: 2 },
    7172: { patronName: 'Realizador', patronNumber: 2 },
    7171: { patronName: 'Realizador', patronNumber: 2 },
    7167: { patronName: 'Investigador', patronNumber: 13 },
    7166: { patronName: 'Investigador', patronNumber: 13 },
    7165: { patronName: 'Investigador', patronNumber: 13 },
    7164: { patronName: 'Realizador', patronNumber: 2 },
    7163: { patronName: 'Realizador', patronNumber: 2 },
    7162: { patronName: 'Realizador', patronNumber: 2 },
    7161: { patronName: 'Realizador', patronNumber: 2 },
    7157: { patronName: 'Investigador', patronNumber: 13 },
    7156: { patronName: 'Investigador', patronNumber: 13 },
    7155: { patronName: 'Investigador', patronNumber: 13 },
    7154: { patronName: 'Realizador', patronNumber: 2 },
    7153: { patronName: 'Realizador', patronNumber: 2 },
    7152: { patronName: 'Realizador', patronNumber: 2 },
    7151: { patronName: 'Realizador', patronNumber: 2 },
    7147: { patronName: 'Creativo', patronNumber: 4 },
    7146: { patronName: 'Creativo', patronNumber: 4 },
    7145: { patronName: 'Creativo', patronNumber: 4 },
    7144: { patronName: 'Resolutivo', patronNumber: 11 },
    7143: { patronName: 'Resolutivo', patronNumber: 11 },
    7142: { patronName: 'Resolutivo', patronNumber: 11 },
    7141: { patronName: 'Resolutivo', patronNumber: 11 },
    7137: { patronName: 'Creativo', patronNumber: 4 },
    7136: { patronName: 'Creativo', patronNumber: 4 },
    7135: { patronName: 'Creativo', patronNumber: 4 },
    7134: { patronName: 'Resolutivo', patronNumber: 11 },
    7133: { patronName: 'Resolutivo', patronNumber: 11 },
    7132: { patronName: 'Resolutivo', patronNumber: 11 },
    7131: { patronName: 'Resolutivo', patronNumber: 11 },
    7127: { patronName: 'Creativo', patronNumber: 4 },
    7126: { patronName: 'Creativo', patronNumber: 4 },
    7125: { patronName: 'Creativo', patronNumber: 4 },
    7124: { patronName: 'Resolutivo', patronNumber: 11 },
    7123: { patronName: 'Resolutivo', patronNumber: 11 },
    7122: { patronName: 'Resolutivo', patronNumber: 11 },
    7121: { patronName: 'Resolutivo', patronNumber: 11 },
    7117: { patronName: 'Creativo', patronNumber: 4 },
    7116: { patronName: 'Creativo', patronNumber: 4 },
    7115: { patronName: 'Creativo', patronNumber: 4 },
    7114: { patronName: 'Resolutivo', patronNumber: 11 },
    7113: { patronName: 'Resolutivo', patronNumber: 11 },
    7112: { patronName: 'Resolutivo', patronNumber: 11 },
    7111: { patronName: 'Resolutivo', patronNumber: 11 },
    6777: { patronName: 'Superactivo', patronNumber: 16 },
    6776: { patronName: 'Superactivo', patronNumber: 16 },
    6775: { patronName: 'Superactivo', patronNumber: 16 },
    6774: { patronName: 'Alentador', patronNumber: 1 },
    6773: { patronName: 'Alentador', patronNumber: 1 },
    6772: { patronName: 'Alentador', patronNumber: 1 },
    6771: { patronName: 'Alentador', patronNumber: 1 },
    6767: { patronName: 'Superactivo', patronNumber: 16 },
    6766: { patronName: 'Superactivo', patronNumber: 16 },
    6765: { patronName: 'Superactivo', patronNumber: 16 },
    6764: { patronName: 'Alentador', patronNumber: 1 },
    6763: { patronName: 'Alentador', patronNumber: 1 },
    6762: { patronName: 'Alentador', patronNumber: 1 },
    6761: { patronName: 'Alentador', patronNumber: 1 },
    6757: { patronName: 'Superactivo', patronNumber: 16 },
    6756: { patronName: 'Superactivo', patronNumber: 16 },
    6755: { patronName: 'Superactivo', patronNumber: 16 },
    6754: { patronName: 'Alentador', patronNumber: 1 },
    6753: { patronName: 'Alentador', patronNumber: 1 },
    6752: { patronName: 'Alentador', patronNumber: 1 },
    6751: { patronName: 'Alentador', patronNumber: 1 },
    6747: { patronName: 'Evaluador', patronNumber: 10 },
    6746: { patronName: 'Evaluador', patronNumber: 10 },
    6745: { patronName: 'Evaluador', patronNumber: 10 },
    6744: { patronName: 'Alentador', patronNumber: 1 },
    6743: { patronName: 'Alentador', patronNumber: 1 },
    6742: { patronName: 'Alentador', patronNumber: 1 },
    6741: { patronName: 'Alentador', patronNumber: 1 },
    6737: { patronName: 'Evaluador', patronNumber: 10 },
    6736: { patronName: 'Evaluador', patronNumber: 10 },
    6735: { patronName: 'Evaluador', patronNumber: 10 },
    6734: { patronName: 'Alentador', patronNumber: 1 },
    6733: { patronName: 'Alentador', patronNumber: 1 },
    6732: { patronName: 'Alentador', patronNumber: 1 },
    6731: { patronName: 'Alentador', patronNumber: 1 },
    6727: { patronName: 'Evaluador', patronNumber: 10 },
    6726: { patronName: 'Evaluador', patronNumber: 10 },
    6725: { patronName: 'Evaluador', patronNumber: 10 },
    6724: { patronName: 'Alentador', patronNumber: 1 },
    6723: { patronName: 'Alentador', patronNumber: 1 },
    6722: { patronName: 'Alentador', patronNumber: 1 },
    6721: { patronName: 'Alentador', patronNumber: 1 },
    6717: { patronName: 'Evaluador', patronNumber: 10 },
    6716: { patronName: 'Evaluador', patronNumber: 10 },
    6715: { patronName: 'Evaluador', patronNumber: 10 },
    6714: { patronName: 'Alentador', patronNumber: 1 },
    6713: { patronName: 'Alentador', patronNumber: 1 },
    6712: { patronName: 'Alentador', patronNumber: 1 },
    6711: { patronName: 'Alentador', patronNumber: 1 },
    6677: { patronName: 'Superactivo', patronNumber: 16 },
    6676: { patronName: 'Superactivo', patronNumber: 16 },
    6675: { patronName: 'Superactivo', patronNumber: 16 },
    6674: { patronName: 'Alentador', patronNumber: 1 },
    6673: { patronName: 'Alentador', patronNumber: 1 },
    6672: { patronName: 'Alentador', patronNumber: 1 },
    6671: { patronName: 'Alentador', patronNumber: 1 },
    6667: { patronName: 'Superactivo', patronNumber: 16 },
    6666: { patronName: 'Superactivo', patronNumber: 16 },
    6665: { patronName: 'Superactivo', patronNumber: 16 },
    6664: { patronName: 'Alentador', patronNumber: 1 },
    6663: { patronName: 'Alentador', patronNumber: 1 },
    6662: { patronName: 'Alentador', patronNumber: 1 },
    6661: { patronName: 'Alentador', patronNumber: 1 },
    6657: { patronName: 'Superactivo', patronNumber: 16 },
    6656: { patronName: 'Superactivo', patronNumber: 16 },
    6655: { patronName: 'Superactivo', patronNumber: 16 },
    6654: { patronName: 'Alentador', patronNumber: 1 },
    6653: { patronName: 'Alentador', patronNumber: 1 },
    6652: { patronName: 'Alentador', patronNumber: 1 },
    6651: { patronName: 'Alentador', patronNumber: 1 },
    6647: { patronName: 'Evaluador', patronNumber: 10 },
    6646: { patronName: 'Evaluador', patronNumber: 10 },
    6645: { patronName: 'Evaluador', patronNumber: 10 },
    6644: { patronName: 'Alentador', patronNumber: 1 },
    6643: { patronName: 'Alentador', patronNumber: 1 },
    6642: { patronName: 'Alentador', patronNumber: 1 },
    6641: { patronName: 'Alentador', patronNumber: 1 },
    6637: { patronName: 'Evaluador', patronNumber: 10 },
    6636: { patronName: 'Evaluador', patronNumber: 10 },
    6635: { patronName: 'Evaluador', patronNumber: 10 },
    6634: { patronName: 'Alentador', patronNumber: 1 },
    6633: { patronName: 'Alentador', patronNumber: 1 },
    6632: { patronName: 'Alentador', patronNumber: 1 },
    6631: { patronName: 'Alentador', patronNumber: 1 },
    6627: { patronName: 'Evaluador', patronNumber: 10 },
    6626: { patronName: 'Evaluador', patronNumber: 10 },
    6625: { patronName: 'Evaluador', patronNumber: 10 },
    6624: { patronName: 'Alentador', patronNumber: 1 },
    6623: { patronName: 'Alentador', patronNumber: 1 },
    6622: { patronName: 'Alentador', patronNumber: 1 },
    6621: { patronName: 'Alentador', patronNumber: 1 },
    6617: { patronName: 'Evaluador', patronNumber: 10 },
    6616: { patronName: 'Evaluador', patronNumber: 10 },
    6615: { patronName: 'Evaluador', patronNumber: 10 },
    6614: { patronName: 'Alentador', patronNumber: 1 },
    6613: { patronName: 'Alentador', patronNumber: 1 },
    6612: { patronName: 'Alentador', patronNumber: 1 },
    6611: { patronName: 'Alentador', patronNumber: 1 },
    6577: { patronName: 'Superactivo', patronNumber: 16 },
    6576: { patronName: 'Superactivo', patronNumber: 16 },
    6575: { patronName: 'Superactivo', patronNumber: 16 },
    6574: { patronName: 'Realizador', patronNumber: 2 },
    6573: { patronName: 'Realizador', patronNumber: 2 },
    6572: { patronName: 'Realizador', patronNumber: 2 },
    6571: { patronName: 'Realizador', patronNumber: 2 },
    6567: { patronName: 'Superactivo', patronNumber: 16 },
    6566: { patronName: 'Superactivo', patronNumber: 16 },
    6565: { patronName: 'Superactivo', patronNumber: 16 },
    6564: { patronName: 'Realizador', patronNumber: 2 },
    6563: { patronName: 'Realizador', patronNumber: 2 },
    6562: { patronName: 'Realizador', patronNumber: 2 },
    6561: { patronName: 'Realizador', patronNumber: 2 },
    6557: { patronName: 'Superactivo', patronNumber: 16 },
    6556: { patronName: 'Superactivo', patronNumber: 16 },
    6555: { patronName: 'Superactivo', patronNumber: 16 },
    6554: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6553: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6552: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6551: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6547: { patronName: 'Creativo', patronNumber: 4 },
    6546: { patronName: 'Creativo', patronNumber: 4 },
    6545: { patronName: 'Creativo', patronNumber: 4 },
    6544: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6543: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6542: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6541: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6537: { patronName: 'Creativo', patronNumber: 4 },
    6536: { patronName: 'Creativo', patronNumber: 4 },
    6535: { patronName: 'Creativo', patronNumber: 4 },
    6534: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6533: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6532: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6531: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6527: { patronName: 'Creativo', patronNumber: 4 },
    6526: { patronName: 'Creativo', patronNumber: 4 },
    6525: { patronName: 'Creativo', patronNumber: 4 },
    6524: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6523: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6522: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6521: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6517: { patronName: 'Creativo', patronNumber: 4 },
    6516: { patronName: 'Creativo', patronNumber: 4 },
    6515: { patronName: 'Creativo', patronNumber: 4 },
    6514: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6513: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6512: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6511: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6477: { patronName: 'Investigador', patronNumber: 13 },
    6476: { patronName: 'Investigador', patronNumber: 13 },
    6475: { patronName: 'Investigador', patronNumber: 13 },
    6474: { patronName: 'Realizador', patronNumber: 2 },
    6473: { patronName: 'Realizador', patronNumber: 2 },
    6472: { patronName: 'Realizador', patronNumber: 2 },
    6471: { patronName: 'Realizador', patronNumber: 2 },
    6467: { patronName: 'Investigador', patronNumber: 13 },
    6466: { patronName: 'Investigador', patronNumber: 13 },
    6465: { patronName: 'Investigador', patronNumber: 13 },
    6464: { patronName: 'Realizador', patronNumber: 2 },
    6463: { patronName: 'Realizador', patronNumber: 2 },
    6462: { patronName: 'Realizador', patronNumber: 2 },
    6461: { patronName: 'Realizador', patronNumber: 2 },
    6457: { patronName: 'Investigador', patronNumber: 13 },
    6456: { patronName: 'Investigador', patronNumber: 13 },
    6455: { patronName: 'Investigador', patronNumber: 13 },
    6454: { patronName: 'Realizador', patronNumber: 2 },
    6453: { patronName: 'Realizador', patronNumber: 2 },
    6452: { patronName: 'Realizador', patronNumber: 2 },
    6451: { patronName: 'Realizador', patronNumber: 2 },
    6447: { patronName: 'Creativo', patronNumber: 4 },
    6446: { patronName: 'Creativo', patronNumber: 4 },
    6445: { patronName: 'Creativo', patronNumber: 4 },
    6444: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6443: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6442: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6441: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6437: { patronName: 'Creativo', patronNumber: 4 },
    6436: { patronName: 'Creativo', patronNumber: 4 },
    6435: { patronName: 'Creativo', patronNumber: 4 },
    6434: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6433: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6432: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6431: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6427: { patronName: 'Creativo', patronNumber: 4 },
    6426: { patronName: 'Creativo', patronNumber: 4 },
    6425: { patronName: 'Creativo', patronNumber: 4 },
    6424: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6423: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6422: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6421: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6417: { patronName: 'Creativo', patronNumber: 4 },
    6416: { patronName: 'Creativo', patronNumber: 4 },
    6415: { patronName: 'Creativo', patronNumber: 4 },
    6414: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6413: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6412: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6411: { patronName: 'Orientado a resultados', patronNumber: 14 },
    6377: { patronName: 'Investigador', patronNumber: 13 },
    6376: { patronName: 'Investigador', patronNumber: 13 },
    6375: { patronName: 'Investigador', patronNumber: 13 },
    6374: { patronName: 'Realizador', patronNumber: 2 },
    6373: { patronName: 'Realizador', patronNumber: 2 },
    6372: { patronName: 'Realizador', patronNumber: 2 },
    6371: { patronName: 'Realizador', patronNumber: 2 },
    6367: { patronName: 'Investigador', patronNumber: 13 },
    6366: { patronName: 'Investigador', patronNumber: 13 },
    6365: { patronName: 'Investigador', patronNumber: 13 },
    6364: { patronName: 'Realizador', patronNumber: 2 },
    6363: { patronName: 'Realizador', patronNumber: 2 },
    6362: { patronName: 'Realizador', patronNumber: 2 },
    6361: { patronName: 'Realizador', patronNumber: 2 },
    6357: { patronName: 'Investigador', patronNumber: 13 },
    6356: { patronName: 'Investigador', patronNumber: 13 },
    6355: { patronName: 'Investigador', patronNumber: 13 },
    6354: { patronName: 'Realizador', patronNumber: 2 },
    6353: { patronName: 'Realizador', patronNumber: 2 },
    6352: { patronName: 'Realizador', patronNumber: 2 },
    6351: { patronName: 'Realizador', patronNumber: 2 },
    6347: { patronName: 'Creativo', patronNumber: 4 },
    6346: { patronName: 'Creativo', patronNumber: 4 },
    6345: { patronName: 'Creativo', patronNumber: 4 },
    6344: { patronName: 'Resolutivo', patronNumber: 11 },
    6343: { patronName: 'Resolutivo', patronNumber: 11 },
    6342: { patronName: 'Resolutivo', patronNumber: 11 },
    6341: { patronName: 'Resolutivo', patronNumber: 11 },
    6337: { patronName: 'Creativo', patronNumber: 4 },
    6336: { patronName: 'Creativo', patronNumber: 4 },
    6335: { patronName: 'Creativo', patronNumber: 4 },
    6334: { patronName: 'Resolutivo', patronNumber: 11 },
    6333: { patronName: 'Resolutivo', patronNumber: 11 },
    6332: { patronName: 'Resolutivo', patronNumber: 11 },
    6331: { patronName: 'Resolutivo', patronNumber: 11 },
    6327: { patronName: 'Creativo', patronNumber: 4 },
    6326: { patronName: 'Creativo', patronNumber: 4 },
    6325: { patronName: 'Creativo', patronNumber: 4 },
    6324: { patronName: 'Resolutivo', patronNumber: 11 },
    6323: { patronName: 'Resolutivo', patronNumber: 11 },
    6322: { patronName: 'Resolutivo', patronNumber: 11 },
    6321: { patronName: 'Resolutivo', patronNumber: 11 },
    6317: { patronName: 'Creativo', patronNumber: 4 },
    6316: { patronName: 'Creativo', patronNumber: 4 },
    6315: { patronName: 'Creativo', patronNumber: 4 },
    6314: { patronName: 'Resolutivo', patronNumber: 11 },
    6313: { patronName: 'Resolutivo', patronNumber: 11 },
    6312: { patronName: 'Resolutivo', patronNumber: 11 },
    6311: { patronName: 'Resolutivo', patronNumber: 11 },
    6277: { patronName: 'Investigador', patronNumber: 13 },
    6276: { patronName: 'Investigador', patronNumber: 13 },
    6275: { patronName: 'Investigador', patronNumber: 13 },
    6274: { patronName: 'Realizador', patronNumber: 2 },
    6273: { patronName: 'Realizador', patronNumber: 2 },
    6272: { patronName: 'Realizador', patronNumber: 2 },
    6271: { patronName: 'Realizador', patronNumber: 2 },
    6267: { patronName: 'Investigador', patronNumber: 13 },
    6266: { patronName: 'Investigador', patronNumber: 13 },
    6265: { patronName: 'Investigador', patronNumber: 13 },
    6264: { patronName: 'Realizador', patronNumber: 2 },
    6263: { patronName: 'Realizador', patronNumber: 2 },
    6262: { patronName: 'Realizador', patronNumber: 2 },
    6261: { patronName: 'Realizador', patronNumber: 2 },
    6257: { patronName: 'Investigador', patronNumber: 13 },
    6256: { patronName: 'Investigador', patronNumber: 13 },
    6255: { patronName: 'Investigador', patronNumber: 13 },
    6254: { patronName: 'Realizador', patronNumber: 2 },
    6253: { patronName: 'Realizador', patronNumber: 2 },
    6252: { patronName: 'Realizador', patronNumber: 2 },
    6251: { patronName: 'Realizador', patronNumber: 2 },
    6247: { patronName: 'Creativo', patronNumber: 4 },
    6246: { patronName: 'Creativo', patronNumber: 4 },
    6245: { patronName: 'Creativo', patronNumber: 4 },
    6244: { patronName: 'Resolutivo', patronNumber: 11 },
    6243: { patronName: 'Resolutivo', patronNumber: 11 },
    6242: { patronName: 'Resolutivo', patronNumber: 11 },
    6241: { patronName: 'Resolutivo', patronNumber: 11 },
    6237: { patronName: 'Creativo', patronNumber: 4 },
    6236: { patronName: 'Creativo', patronNumber: 4 },
    6235: { patronName: 'Creativo', patronNumber: 4 },
    6234: { patronName: 'Resolutivo', patronNumber: 11 },
    6233: { patronName: 'Resolutivo', patronNumber: 11 },
    6232: { patronName: 'Resolutivo', patronNumber: 11 },
    6231: { patronName: 'Resolutivo', patronNumber: 11 },
    6227: { patronName: 'Creativo', patronNumber: 4 },
    6226: { patronName: 'Creativo', patronNumber: 4 },
    6225: { patronName: 'Creativo', patronNumber: 4 },
    6224: { patronName: 'Resolutivo', patronNumber: 11 },
    6223: { patronName: 'Resolutivo', patronNumber: 11 },
    6222: { patronName: 'Resolutivo', patronNumber: 11 },
    6221: { patronName: 'Resolutivo', patronNumber: 11 },
    6217: { patronName: 'Creativo', patronNumber: 4 },
    6216: { patronName: 'Creativo', patronNumber: 4 },
    6215: { patronName: 'Creativo', patronNumber: 4 },
    6214: { patronName: 'Resolutivo', patronNumber: 11 },
    6213: { patronName: 'Resolutivo', patronNumber: 11 },
    6212: { patronName: 'Resolutivo', patronNumber: 11 },
    6211: { patronName: 'Resolutivo', patronNumber: 11 },
    6177: { patronName: 'Investigador', patronNumber: 13 },
    6176: { patronName: 'Investigador', patronNumber: 13 },
    6175: { patronName: 'Investigador', patronNumber: 13 },
    6174: { patronName: 'Realizador', patronNumber: 2 },
    6173: { patronName: 'Realizador', patronNumber: 2 },
    6172: { patronName: 'Realizador', patronNumber: 2 },
    6171: { patronName: 'Realizador', patronNumber: 2 },
    6167: { patronName: 'Investigador', patronNumber: 13 },
    6166: { patronName: 'Investigador', patronNumber: 13 },
    6165: { patronName: 'Investigador', patronNumber: 13 },
    6164: { patronName: 'Realizador', patronNumber: 2 },
    6163: { patronName: 'Realizador', patronNumber: 2 },
    6162: { patronName: 'Realizador', patronNumber: 2 },
    6161: { patronName: 'Realizador', patronNumber: 2 },
    6157: { patronName: 'Investigador', patronNumber: 13 },
    6156: { patronName: 'Investigador', patronNumber: 13 },
    6155: { patronName: 'Investigador', patronNumber: 13 },
    6154: { patronName: 'Realizador', patronNumber: 2 },
    6153: { patronName: 'Realizador', patronNumber: 2 },
    6152: { patronName: 'Realizador', patronNumber: 2 },
    6151: { patronName: 'Realizador', patronNumber: 2 },
    6147: { patronName: 'Creativo', patronNumber: 4 },
    6146: { patronName: 'Creativo', patronNumber: 4 },
    6145: { patronName: 'Creativo', patronNumber: 4 },
    6144: { patronName: 'Resolutivo', patronNumber: 11 },
    6143: { patronName: 'Resolutivo', patronNumber: 11 },
    6142: { patronName: 'Resolutivo', patronNumber: 11 },
    6141: { patronName: 'Resolutivo', patronNumber: 11 },
    6137: { patronName: 'Creativo', patronNumber: 4 },
    6136: { patronName: 'Creativo', patronNumber: 4 },
    6135: { patronName: 'Creativo', patronNumber: 4 },
    6134: { patronName: 'Resolutivo', patronNumber: 11 },
    6133: { patronName: 'Resolutivo', patronNumber: 11 },
    6132: { patronName: 'Resolutivo', patronNumber: 11 },
    6131: { patronName: 'Resolutivo', patronNumber: 11 },
    6127: { patronName: 'Creativo', patronNumber: 4 },
    6126: { patronName: 'Creativo', patronNumber: 4 },
    6125: { patronName: 'Creativo', patronNumber: 4 },
    6124: { patronName: 'Resolutivo', patronNumber: 11 },
    6123: { patronName: 'Resolutivo', patronNumber: 11 },
    6122: { patronName: 'Resolutivo', patronNumber: 11 },
    6121: { patronName: 'Resolutivo', patronNumber: 11 },
    6117: { patronName: 'Creativo', patronNumber: 4 },
    6116: { patronName: 'Creativo', patronNumber: 4 },
    6115: { patronName: 'Creativo', patronNumber: 4 },
    6114: { patronName: 'Resolutivo', patronNumber: 11 },
    6113: { patronName: 'Resolutivo', patronNumber: 11 },
    6112: { patronName: 'Resolutivo', patronNumber: 11 },
    6111: { patronName: 'Resolutivo', patronNumber: 11 },
    5777: { patronName: 'Superactivo', patronNumber: 16 },
    5776: { patronName: 'Superactivo', patronNumber: 16 },
    5775: { patronName: 'Superactivo', patronNumber: 16 },
    5774: { patronName: 'Consejero', patronNumber: 8 },
    5773: { patronName: 'Consejero', patronNumber: 8 },
    5772: { patronName: 'Consejero', patronNumber: 8 },
    5771: { patronName: 'Consejero', patronNumber: 8 },
    5767: { patronName: 'Superactivo', patronNumber: 16 },
    5766: { patronName: 'Superactivo', patronNumber: 16 },
    5765: { patronName: 'Superactivo', patronNumber: 16 },
    5764: { patronName: 'Consejero', patronNumber: 8 },
    5763: { patronName: 'Consejero', patronNumber: 8 },
    5762: { patronName: 'Consejero', patronNumber: 8 },
    5761: { patronName: 'Consejero', patronNumber: 8 },
    5757: { patronName: 'Superactivo', patronNumber: 16 },
    5756: { patronName: 'Superactivo', patronNumber: 16 },
    5755: { patronName: 'Superactivo', patronNumber: 16 },
    5754: { patronName: 'Persuasivo', patronNumber: 6 },
    5753: { patronName: 'Persuasivo', patronNumber: 6 },
    5752: { patronName: 'Persuasivo', patronNumber: 6 },
    5751: { patronName: 'Persuasivo', patronNumber: 6 },
    5747: { patronName: 'Evaluador', patronNumber: 10 },
    5746: { patronName: 'Evaluador', patronNumber: 10 },
    5745: { patronName: 'Evaluador', patronNumber: 10 },
    5744: { patronName: 'Persuasivo', patronNumber: 6 },
    5743: { patronName: 'Persuasivo', patronNumber: 6 },
    5742: { patronName: 'Persuasivo', patronNumber: 6 },
    5741: { patronName: 'Persuasivo', patronNumber: 6 },
    5737: { patronName: 'Evaluador', patronNumber: 10 },
    5736: { patronName: 'Evaluador', patronNumber: 10 },
    5735: { patronName: 'Evaluador', patronNumber: 10 },
    5734: { patronName: 'Persuasivo', patronNumber: 6 },
    5733: { patronName: 'Persuasivo', patronNumber: 6 },
    5732: { patronName: 'Persuasivo', patronNumber: 6 },
    5731: { patronName: 'Persuasivo', patronNumber: 6 },
    5727: { patronName: 'Evaluador', patronNumber: 10 },
    5726: { patronName: 'Evaluador', patronNumber: 10 },
    5725: { patronName: 'Evaluador', patronNumber: 10 },
    5724: { patronName: 'Persuasivo', patronNumber: 6 },
    5723: { patronName: 'Persuasivo', patronNumber: 6 },
    5722: { patronName: 'Persuasivo', patronNumber: 6 },
    5721: { patronName: 'Persuasivo', patronNumber: 6 },
    5717: { patronName: 'Evaluador', patronNumber: 10 },
    5716: { patronName: 'Evaluador', patronNumber: 10 },
    5715: { patronName: 'Evaluador', patronNumber: 10 },
    5714: { patronName: 'Persuasivo', patronNumber: 6 },
    5713: { patronName: 'Persuasivo', patronNumber: 6 },
    5712: { patronName: 'Persuasivo', patronNumber: 6 },
    5711: { patronName: 'Persuasivo', patronNumber: 6 },
    5677: { patronName: 'Superactivo', patronNumber: 16 },
    5676: { patronName: 'Superactivo', patronNumber: 16 },
    5675: { patronName: 'Superactivo', patronNumber: 16 },
    5674: { patronName: 'Agente', patronNumber: 9 },
    5673: { patronName: 'Agente', patronNumber: 9 },
    5672: { patronName: 'Agente', patronNumber: 9 },
    5671: { patronName: 'Agente', patronNumber: 9 },
    5667: { patronName: 'Superactivo', patronNumber: 16 },
    5666: { patronName: 'Superactivo', patronNumber: 16 },
    5665: { patronName: 'Superactivo', patronNumber: 16 },
    5664: { patronName: 'Consejero', patronNumber: 8 },
    5663: { patronName: 'Consejero', patronNumber: 8 },
    5662: { patronName: 'Consejero', patronNumber: 8 },
    5661: { patronName: 'Consejero', patronNumber: 8 },
    5657: { patronName: 'Superactivo', patronNumber: 16 },
    5656: { patronName: 'Superactivo', patronNumber: 16 },
    5655: { patronName: 'Superactivo', patronNumber: 16 },
    5654: { patronName: 'Persuasivo', patronNumber: 6 },
    5653: { patronName: 'Persuasivo', patronNumber: 6 },
    5652: { patronName: 'Persuasivo', patronNumber: 6 },
    5651: { patronName: 'Persuasivo', patronNumber: 6 },
    5647: { patronName: 'Evaluador', patronNumber: 10 },
    5646: { patronName: 'Evaluador', patronNumber: 10 },
    5645: { patronName: 'Evaluador', patronNumber: 10 },
    5644: { patronName: 'Persuasivo', patronNumber: 6 },
    5643: { patronName: 'Persuasivo', patronNumber: 6 },
    5642: { patronName: 'Persuasivo', patronNumber: 6 },
    5641: { patronName: 'Persuasivo', patronNumber: 6 },
    5637: { patronName: 'Evaluador', patronNumber: 10 },
    5636: { patronName: 'Evaluador', patronNumber: 10 },
    5635: { patronName: 'Evaluador', patronNumber: 10 },
    5634: { patronName: 'Persuasivo', patronNumber: 6 },
    5633: { patronName: 'Persuasivo', patronNumber: 6 },
    5632: { patronName: 'Persuasivo', patronNumber: 6 },
    5631: { patronName: 'Persuasivo', patronNumber: 6 },
    5627: { patronName: 'Evaluador', patronNumber: 10 },
    5626: { patronName: 'Evaluador', patronNumber: 10 },
    5625: { patronName: 'Evaluador', patronNumber: 10 },
    5624: { patronName: 'Persuasivo', patronNumber: 6 },
    5623: { patronName: 'Persuasivo', patronNumber: 6 },
    5622: { patronName: 'Persuasivo', patronNumber: 6 },
    5621: { patronName: 'Persuasivo', patronNumber: 6 },
    5617: { patronName: 'Evaluador', patronNumber: 10 },
    5616: { patronName: 'Evaluador', patronNumber: 10 },
    5615: { patronName: 'Evaluador', patronNumber: 10 },
    5614: { patronName: 'Persuasivo', patronNumber: 6 },
    5613: { patronName: 'Persuasivo', patronNumber: 6 },
    5612: { patronName: 'Persuasivo', patronNumber: 6 },
    5611: { patronName: 'Persuasivo', patronNumber: 6 },
    5577: { patronName: 'Superactivo', patronNumber: 16 },
    5576: { patronName: 'Superactivo', patronNumber: 16 },
    5575: { patronName: 'Superactivo', patronNumber: 16 },
    5574: { patronName: 'Agente', patronNumber: 9 },
    5573: { patronName: 'Agente', patronNumber: 9 },
    5572: { patronName: 'Agente', patronNumber: 9 },
    5571: { patronName: 'Agente', patronNumber: 9 },
    5567: { patronName: 'Superactivo', patronNumber: 16 },
    5566: { patronName: 'Superactivo', patronNumber: 16 },
    5565: { patronName: 'Superactivo', patronNumber: 16 },
    5564: { patronName: 'Agente', patronNumber: 9 },
    5563: { patronName: 'Agente', patronNumber: 9 },
    5562: { patronName: 'Agente', patronNumber: 9 },
    5561: { patronName: 'Agente', patronNumber: 9 },
    5557: { patronName: 'Superactivo', patronNumber: 16 },
    5556: { patronName: 'Superactivo', patronNumber: 16 },
    5555: { patronName: 'Superactivo', patronNumber: 16 },
    5554: { patronName: 'Desconcertante', patronNumber: 17 },
    5553: { patronName: 'Consejero', patronNumber: 8 },
    5552: { patronName: 'Consejero', patronNumber: 8 },
    5551: { patronName: 'Consejero', patronNumber: 8 },
    5547: { patronName: 'Evaluador', patronNumber: 10 },
    5546: { patronName: 'Evaluador', patronNumber: 10 },
    5545: { patronName: 'Evaluador', patronNumber: 10 },
    5544: { patronName: 'Desconcertante', patronNumber: 17 },
    5543: { patronName: 'Alentador', patronNumber: 1 },
    5542: { patronName: 'Alentador', patronNumber: 1 },
    5541: { patronName: 'Alentador', patronNumber: 1 },
    5537: { patronName: 'Evaluador', patronNumber: 10 },
    5536: { patronName: 'Evaluador', patronNumber: 10 },
    5535: { patronName: 'Evaluador', patronNumber: 10 },
    5534: { patronName: 'Alentador', patronNumber: 1 },
    5533: { patronName: 'Alentador', patronNumber: 1 },
    5532: { patronName: 'Alentador', patronNumber: 1 },
    5531: { patronName: 'Alentador', patronNumber: 1 },
    5527: { patronName: 'Evaluador', patronNumber: 10 },
    5526: { patronName: 'Evaluador', patronNumber: 10 },
    5525: { patronName: 'Evaluador', patronNumber: 10 },
    5524: { patronName: 'Alentador', patronNumber: 1 },
    5523: { patronName: 'Alentador', patronNumber: 1 },
    5522: { patronName: 'Alentador', patronNumber: 1 },
    5521: { patronName: 'Alentador', patronNumber: 1 },
    5517: { patronName: 'Evaluador', patronNumber: 10 },
    5516: { patronName: 'Evaluador', patronNumber: 10 },
    5515: { patronName: 'Evaluador', patronNumber: 10 },
    5514: { patronName: 'Alentador', patronNumber: 1 },
    5513: { patronName: 'Alentador', patronNumber: 1 },
    5512: { patronName: 'Alentador', patronNumber: 1 },
    5511: { patronName: 'Alentador', patronNumber: 1 },
    5477: { patronName: 'Investigador', patronNumber: 13 },
    5476: { patronName: 'Investigador', patronNumber: 13 },
    5475: { patronName: 'Investigador', patronNumber: 13 },
    5474: { patronName: 'Realizador', patronNumber: 2 },
    5473: { patronName: 'Realizador', patronNumber: 2 },
    5472: { patronName: 'Realizador', patronNumber: 2 },
    5471: { patronName: 'Realizador', patronNumber: 2 },
    5467: { patronName: 'Investigador', patronNumber: 13 },
    5466: { patronName: 'Investigador', patronNumber: 13 },
    5465: { patronName: 'Investigador', patronNumber: 13 },
    5464: { patronName: 'Realizador', patronNumber: 2 },
    5463: { patronName: 'Realizador', patronNumber: 2 },
    5462: { patronName: 'Realizador', patronNumber: 2 },
    5461: { patronName: 'Realizador', patronNumber: 2 },
    5457: { patronName: 'Investigador', patronNumber: 13 },
    5456: { patronName: 'Investigador', patronNumber: 13 },
    5455: { patronName: 'Investigador', patronNumber: 13 },
    5454: { patronName: 'Realizador', patronNumber: 2 },
    5453: { patronName: 'Realizador', patronNumber: 2 },
    5452: { patronName: 'Realizador', patronNumber: 2 },
    5451: { patronName: 'Realizador', patronNumber: 2 },
    5447: { patronName: 'Creativo', patronNumber: 4 },
    5446: { patronName: 'Creativo', patronNumber: 4 },
    5445: { patronName: 'Creativo', patronNumber: 4 },
    5444: { patronName: 'Desconcertante', patronNumber: 17 },
    5443: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5442: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5441: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5437: { patronName: 'Creativo', patronNumber: 4 },
    5436: { patronName: 'Creativo', patronNumber: 4 },
    5435: { patronName: 'Creativo', patronNumber: 4 },
    5434: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5433: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5432: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5431: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5427: { patronName: 'Creativo', patronNumber: 4 },
    5426: { patronName: 'Creativo', patronNumber: 4 },
    5425: { patronName: 'Creativo', patronNumber: 4 },
    5424: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5423: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5422: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5421: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5417: { patronName: 'Creativo', patronNumber: 4 },
    5416: { patronName: 'Creativo', patronNumber: 4 },
    5415: { patronName: 'Creativo', patronNumber: 4 },
    5414: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5413: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5412: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5411: { patronName: 'Orientado a resultados', patronNumber: 14 },
    5377: { patronName: 'Investigador', patronNumber: 13 },
    5376: { patronName: 'Investigador', patronNumber: 13 },
    5375: { patronName: 'Investigador', patronNumber: 13 },
    5374: { patronName: 'Realizador', patronNumber: 2 },
    5373: { patronName: 'Realizador', patronNumber: 2 },
    5372: { patronName: 'Realizador', patronNumber: 2 },
    5371: { patronName: 'Realizador', patronNumber: 2 },
    5367: { patronName: 'Investigador', patronNumber: 13 },
    5366: { patronName: 'Investigador', patronNumber: 13 },
    5365: { patronName: 'Investigador', patronNumber: 13 },
    5364: { patronName: 'Realizador', patronNumber: 2 },
    5363: { patronName: 'Realizador', patronNumber: 2 },
    5362: { patronName: 'Realizador', patronNumber: 2 },
    5361: { patronName: 'Realizador', patronNumber: 2 },
    5357: { patronName: 'Investigador', patronNumber: 13 },
    5356: { patronName: 'Investigador', patronNumber: 13 },
    5355: { patronName: 'Investigador', patronNumber: 13 },
    5354: { patronName: 'Realizador', patronNumber: 2 },
    5353: { patronName: 'Realizador', patronNumber: 2 },
    5352: { patronName: 'Realizador', patronNumber: 2 },
    5351: { patronName: 'Realizador', patronNumber: 2 },
    5347: { patronName: 'Creativo', patronNumber: 4 },
    5346: { patronName: 'Creativo', patronNumber: 4 },
    5345: { patronName: 'Creativo', patronNumber: 4 },
    5344: { patronName: 'Resolutivo', patronNumber: 11 },
    5343: { patronName: 'Resolutivo', patronNumber: 11 },
    5342: { patronName: 'Resolutivo', patronNumber: 11 },
    5341: { patronName: 'Resolutivo', patronNumber: 11 },
    5337: { patronName: 'Creativo', patronNumber: 4 },
    5336: { patronName: 'Creativo', patronNumber: 4 },
    5335: { patronName: 'Creativo', patronNumber: 4 },
    5334: { patronName: 'Resolutivo', patronNumber: 11 },
    5333: { patronName: 'Resolutivo', patronNumber: 11 },
    5332: { patronName: 'Resolutivo', patronNumber: 11 },
    5331: { patronName: 'Resolutivo', patronNumber: 11 },
    5327: { patronName: 'Creativo', patronNumber: 4 },
    5326: { patronName: 'Creativo', patronNumber: 4 },
    5325: { patronName: 'Creativo', patronNumber: 4 },
    5324: { patronName: 'Resolutivo', patronNumber: 11 },
    5323: { patronName: 'Resolutivo', patronNumber: 11 },
    5322: { patronName: 'Resolutivo', patronNumber: 11 },
    5321: { patronName: 'Resolutivo', patronNumber: 11 },
    5317: { patronName: 'Creativo', patronNumber: 4 },
    5316: { patronName: 'Creativo', patronNumber: 4 },
    5315: { patronName: 'Creativo', patronNumber: 4 },
    5314: { patronName: 'Resolutivo', patronNumber: 11 },
    5313: { patronName: 'Resolutivo', patronNumber: 11 },
    5312: { patronName: 'Resolutivo', patronNumber: 11 },
    5311: { patronName: 'Resolutivo', patronNumber: 11 },
    5277: { patronName: 'Investigador', patronNumber: 13 },
    5276: { patronName: 'Investigador', patronNumber: 13 },
    5275: { patronName: 'Investigador', patronNumber: 13 },
    5274: { patronName: 'Realizador', patronNumber: 2 },
    5273: { patronName: 'Realizador', patronNumber: 2 },
    5272: { patronName: 'Realizador', patronNumber: 2 },
    5271: { patronName: 'Realizador', patronNumber: 2 },
    5267: { patronName: 'Investigador', patronNumber: 13 },
    5266: { patronName: 'Investigador', patronNumber: 13 },
    5265: { patronName: 'Investigador', patronNumber: 13 },
    5264: { patronName: 'Realizador', patronNumber: 2 },
    5263: { patronName: 'Realizador', patronNumber: 2 },
    5262: { patronName: 'Realizador', patronNumber: 2 },
    5261: { patronName: 'Realizador', patronNumber: 2 },
    5257: { patronName: 'Investigador', patronNumber: 13 },
    5256: { patronName: 'Investigador', patronNumber: 13 },
    5255: { patronName: 'Investigador', patronNumber: 13 },
    5254: { patronName: 'Realizador', patronNumber: 2 },
    5253: { patronName: 'Realizador', patronNumber: 2 },
    5252: { patronName: 'Realizador', patronNumber: 2 },
    5251: { patronName: 'Realizador', patronNumber: 2 },
    5247: { patronName: 'Creativo', patronNumber: 4 },
    5246: { patronName: 'Creativo', patronNumber: 4 },
    5245: { patronName: 'Creativo', patronNumber: 4 },
    5244: { patronName: 'Resolutivo', patronNumber: 11 },
    5243: { patronName: 'Resolutivo', patronNumber: 11 },
    5242: { patronName: 'Resolutivo', patronNumber: 11 },
    5241: { patronName: 'Resolutivo', patronNumber: 11 },
    5237: { patronName: 'Creativo', patronNumber: 4 },
    5236: { patronName: 'Creativo', patronNumber: 4 },
    5235: { patronName: 'Creativo', patronNumber: 4 },
    5234: { patronName: 'Resolutivo', patronNumber: 11 },
    5233: { patronName: 'Resolutivo', patronNumber: 11 },
    5232: { patronName: 'Resolutivo', patronNumber: 11 },
    5231: { patronName: 'Resolutivo', patronNumber: 11 },
    5227: { patronName: 'Creativo', patronNumber: 4 },
    5226: { patronName: 'Creativo', patronNumber: 4 },
    5225: { patronName: 'Creativo', patronNumber: 4 },
    5224: { patronName: 'Resolutivo', patronNumber: 11 },
    5223: { patronName: 'Resolutivo', patronNumber: 11 },
    5222: { patronName: 'Resolutivo', patronNumber: 11 },
    5221: { patronName: 'Resolutivo', patronNumber: 11 },
    5217: { patronName: 'Creativo', patronNumber: 4 },
    5216: { patronName: 'Creativo', patronNumber: 4 },
    5215: { patronName: 'Creativo', patronNumber: 4 },
    5214: { patronName: 'Resolutivo', patronNumber: 11 },
    5213: { patronName: 'Resolutivo', patronNumber: 11 },
    5212: { patronName: 'Resolutivo', patronNumber: 11 },
    5211: { patronName: 'Resolutivo', patronNumber: 11 },
    5177: { patronName: 'Investigador', patronNumber: 13 },
    5176: { patronName: 'Investigador', patronNumber: 13 },
    5175: { patronName: 'Investigador', patronNumber: 13 },
    5174: { patronName: 'Realizador', patronNumber: 2 },
    5173: { patronName: 'Realizador', patronNumber: 2 },
    5172: { patronName: 'Realizador', patronNumber: 2 },
    5171: { patronName: 'Realizador', patronNumber: 2 },
    5167: { patronName: 'Investigador', patronNumber: 13 },
    5166: { patronName: 'Investigador', patronNumber: 13 },
    5165: { patronName: 'Investigador', patronNumber: 13 },
    5164: { patronName: 'Realizador', patronNumber: 2 },
    5163: { patronName: 'Realizador', patronNumber: 2 },
    5162: { patronName: 'Realizador', patronNumber: 2 },
    5161: { patronName: 'Realizador', patronNumber: 2 },
    5157: { patronName: 'Investigador', patronNumber: 13 },
    5156: { patronName: 'Investigador', patronNumber: 13 },
    5155: { patronName: 'Investigador', patronNumber: 13 },
    5154: { patronName: 'Realizador', patronNumber: 2 },
    5153: { patronName: 'Realizador', patronNumber: 2 },
    5152: { patronName: 'Realizador', patronNumber: 2 },
    5151: { patronName: 'Realizador', patronNumber: 2 },
    5147: { patronName: 'Creativo', patronNumber: 4 },
    5146: { patronName: 'Creativo', patronNumber: 4 },
    5145: { patronName: 'Creativo', patronNumber: 4 },
    5144: { patronName: 'Resolutivo', patronNumber: 11 },
    5143: { patronName: 'Resolutivo', patronNumber: 11 },
    5142: { patronName: 'Resolutivo', patronNumber: 11 },
    5141: { patronName: 'Resolutivo', patronNumber: 11 },
    5137: { patronName: 'Creativo', patronNumber: 4 },
    5136: { patronName: 'Creativo', patronNumber: 4 },
    5135: { patronName: 'Creativo', patronNumber: 4 },
    5134: { patronName: 'Resolutivo', patronNumber: 11 },
    5133: { patronName: 'Resolutivo', patronNumber: 11 },
    5132: { patronName: 'Resolutivo', patronNumber: 11 },
    5131: { patronName: 'Resolutivo', patronNumber: 11 },
    5127: { patronName: 'Creativo', patronNumber: 4 },
    5126: { patronName: 'Creativo', patronNumber: 4 },
    5125: { patronName: 'Creativo', patronNumber: 4 },
    5124: { patronName: 'Resolutivo', patronNumber: 11 },
    5123: { patronName: 'Resolutivo', patronNumber: 11 },
    5122: { patronName: 'Resolutivo', patronNumber: 11 },
    5121: { patronName: 'Resolutivo', patronNumber: 11 },
    5117: { patronName: 'Creativo', patronNumber: 4 },
    5116: { patronName: 'Creativo', patronNumber: 4 },
    5115: { patronName: 'Creativo', patronNumber: 4 },
    5114: { patronName: 'Resolutivo', patronNumber: 11 },
    5113: { patronName: 'Resolutivo', patronNumber: 11 },
    5112: { patronName: 'Resolutivo', patronNumber: 11 },
    5111: { patronName: 'Resolutivo', patronNumber: 11 },
    4777: { patronName: 'Profesional', patronNumber: 12 },
    4776: { patronName: 'Profesional', patronNumber: 12 },
    4775: { patronName: 'Profesional', patronNumber: 12 },
    4774: { patronName: 'Consejero', patronNumber: 8 },
    4773: { patronName: 'Consejero', patronNumber: 8 },
    4772: { patronName: 'Consejero', patronNumber: 8 },
    4771: { patronName: 'Consejero', patronNumber: 8 },
    4767: { patronName: 'Profesional', patronNumber: 12 },
    4766: { patronName: 'Profesional', patronNumber: 12 },
    4765: { patronName: 'Profesional', patronNumber: 12 },
    4764: { patronName: 'Consejero', patronNumber: 8 },
    4763: { patronName: 'Consejero', patronNumber: 8 },
    4762: { patronName: 'Consejero', patronNumber: 8 },
    4761: { patronName: 'Consejero', patronNumber: 8 },
    4757: { patronName: 'Profesional', patronNumber: 12 },
    4756: { patronName: 'Profesional', patronNumber: 12 },
    4755: { patronName: 'Profesional', patronNumber: 12 },
    4754: { patronName: 'Consejero', patronNumber: 8 },
    4753: { patronName: 'Consejero', patronNumber: 8 },
    4752: { patronName: 'Consejero', patronNumber: 8 },
    4751: { patronName: 'Consejero', patronNumber: 8 },
    4747: { patronName: 'Evaluador', patronNumber: 10 },
    4746: { patronName: 'Evaluador', patronNumber: 10 },
    4745: { patronName: 'Evaluador', patronNumber: 10 },
    4744: { patronName: 'Promotor', patronNumber: 7 },
    4743: { patronName: 'Promotor', patronNumber: 7 },
    4742: { patronName: 'Promotor', patronNumber: 7 },
    4741: { patronName: 'Promotor', patronNumber: 7 },
    4737: { patronName: 'Evaluador', patronNumber: 10 },
    4736: { patronName: 'Evaluador', patronNumber: 10 },
    4735: { patronName: 'Evaluador', patronNumber: 10 },
    4734: { patronName: 'Promotor', patronNumber: 7 },
    4733: { patronName: 'Promotor', patronNumber: 7 },
    4732: { patronName: 'Promotor', patronNumber: 7 },
    4731: { patronName: 'Promotor', patronNumber: 7 },
    4727: { patronName: 'Evaluador', patronNumber: 10 },
    4726: { patronName: 'Evaluador', patronNumber: 10 },
    4725: { patronName: 'Evaluador', patronNumber: 10 },
    4724: { patronName: 'Promotor', patronNumber: 7 },
    4723: { patronName: 'Promotor', patronNumber: 7 },
    4722: { patronName: 'Promotor', patronNumber: 7 },
    4721: { patronName: 'Promotor', patronNumber: 7 },
    4717: { patronName: 'Evaluador', patronNumber: 10 },
    4716: { patronName: 'Evaluador', patronNumber: 10 },
    4715: { patronName: 'Evaluador', patronNumber: 10 },
    4714: { patronName: 'Promotor', patronNumber: 7 },
    4713: { patronName: 'Promotor', patronNumber: 7 },
    4712: { patronName: 'Promotor', patronNumber: 7 },
    4711: { patronName: 'Promotor', patronNumber: 7 },
    4677: { patronName: 'Profesional', patronNumber: 12 },
    4676: { patronName: 'Profesional', patronNumber: 12 },
    4675: { patronName: 'Profesional', patronNumber: 12 },
    4674: { patronName: 'Agente', patronNumber: 9 },
    4673: { patronName: 'Agente', patronNumber: 9 },
    4672: { patronName: 'Agente', patronNumber: 9 },
    4671: { patronName: 'Agente', patronNumber: 9 },
    4667: { patronName: 'Profesional', patronNumber: 12 },
    4666: { patronName: 'Profesional', patronNumber: 12 },
    4665: { patronName: 'Profesional', patronNumber: 12 },
    4664: { patronName: 'Consejero', patronNumber: 8 },
    4663: { patronName: 'Consejero', patronNumber: 8 },
    4662: { patronName: 'Consejero', patronNumber: 8 },
    4661: { patronName: 'Consejero', patronNumber: 8 },
    4657: { patronName: 'Profesional', patronNumber: 12 },
    4656: { patronName: 'Profesional', patronNumber: 12 },
    4655: { patronName: 'Profesional', patronNumber: 12 },
    4654: { patronName: 'Consejero', patronNumber: 8 },
    4653: { patronName: 'Consejero', patronNumber: 8 },
    4652: { patronName: 'Consejero', patronNumber: 8 },
    4651: { patronName: 'Consejero', patronNumber: 8 },
    4647: { patronName: 'Evaluador', patronNumber: 10 },
    4646: { patronName: 'Evaluador', patronNumber: 10 },
    4645: { patronName: 'Evaluador', patronNumber: 10 },
    4644: { patronName: 'Promotor', patronNumber: 7 },
    4643: { patronName: 'Promotor', patronNumber: 7 },
    4642: { patronName: 'Promotor', patronNumber: 7 },
    4641: { patronName: 'Promotor', patronNumber: 7 },
    4637: { patronName: 'Evaluador', patronNumber: 10 },
    4636: { patronName: 'Evaluador', patronNumber: 10 },
    4635: { patronName: 'Evaluador', patronNumber: 10 },
    4634: { patronName: 'Promotor', patronNumber: 7 },
    4633: { patronName: 'Promotor', patronNumber: 7 },
    4632: { patronName: 'Promotor', patronNumber: 7 },
    4631: { patronName: 'Promotor', patronNumber: 7 },
    4627: { patronName: 'Evaluador', patronNumber: 10 },
    4626: { patronName: 'Evaluador', patronNumber: 10 },
    4625: { patronName: 'Evaluador', patronNumber: 10 },
    4624: { patronName: 'Promotor', patronNumber: 7 },
    4623: { patronName: 'Promotor', patronNumber: 7 },
    4622: { patronName: 'Promotor', patronNumber: 7 },
    4621: { patronName: 'Promotor', patronNumber: 7 },
    4617: { patronName: 'Evaluador', patronNumber: 10 },
    4616: { patronName: 'Evaluador', patronNumber: 10 },
    4615: { patronName: 'Evaluador', patronNumber: 10 },
    4614: { patronName: 'Promotor', patronNumber: 7 },
    4613: { patronName: 'Promotor', patronNumber: 7 },
    4612: { patronName: 'Promotor', patronNumber: 7 },
    4611: { patronName: 'Promotor', patronNumber: 7 },
    4577: { patronName: 'Profesional', patronNumber: 12 },
    4576: { patronName: 'Profesional', patronNumber: 12 },
    4575: { patronName: 'Profesional', patronNumber: 12 },
    4574: { patronName: 'Agente', patronNumber: 9 },
    4573: { patronName: 'Agente', patronNumber: 9 },
    4572: { patronName: 'Agente', patronNumber: 9 },
    4571: { patronName: 'Agente', patronNumber: 9 },
    4567: { patronName: 'Profesional', patronNumber: 12 },
    4566: { patronName: 'Profesional', patronNumber: 12 },
    4565: { patronName: 'Profesional', patronNumber: 12 },
    4564: { patronName: 'Agente', patronNumber: 9 },
    4563: { patronName: 'Agente', patronNumber: 9 },
    4562: { patronName: 'Agente', patronNumber: 9 },
    4561: { patronName: 'Agente', patronNumber: 9 },
    4557: { patronName: 'Profesional', patronNumber: 12 },
    4556: { patronName: 'Profesional', patronNumber: 12 },
    4555: { patronName: 'Desconcertante', patronNumber: 17 },
    4554: { patronName: 'Consejero', patronNumber: 8 },
    4553: { patronName: 'Consejero', patronNumber: 8 },
    4552: { patronName: 'Consejero', patronNumber: 8 },
    4551: { patronName: 'Consejero', patronNumber: 8 },
    4547: { patronName: 'Profesional', patronNumber: 12 },
    4546: { patronName: 'Profesional', patronNumber: 12 },
    4545: { patronName: 'Profesional', patronNumber: 12 },
    4544: { patronName: 'Desconcertante', patronNumber: 17 },
    4543: { patronName: 'Consejero', patronNumber: 8 },
    4542: { patronName: 'Consejero', patronNumber: 8 },
    4541: { patronName: 'Consejero', patronNumber: 8 },
    4537: { patronName: 'Evaluador', patronNumber: 10 },
    4536: { patronName: 'Evaluador', patronNumber: 10 },
    4535: { patronName: 'Evaluador', patronNumber: 10 },
    4534: { patronName: 'Promotor', patronNumber: 7 },
    4533: { patronName: 'Promotor', patronNumber: 7 },
    4532: { patronName: 'Promotor', patronNumber: 7 },
    4531: { patronName: 'Promotor', patronNumber: 7 },
    4527: { patronName: 'Evaluador', patronNumber: 10 },
    4526: { patronName: 'Evaluador', patronNumber: 10 },
    4525: { patronName: 'Evaluador', patronNumber: 10 },
    4524: { patronName: 'Promotor', patronNumber: 7 },
    4523: { patronName: 'Promotor', patronNumber: 7 },
    4522: { patronName: 'Promotor', patronNumber: 7 },
    4521: { patronName: 'Promotor', patronNumber: 7 },
    4517: { patronName: 'Evaluador', patronNumber: 10 },
    4516: { patronName: 'Evaluador', patronNumber: 10 },
    4515: { patronName: 'Evaluador', patronNumber: 10 },
    4514: { patronName: 'Promotor', patronNumber: 7 },
    4513: { patronName: 'Promotor', patronNumber: 7 },
    4512: { patronName: 'Promotor', patronNumber: 7 },
    4511: { patronName: 'Promotor', patronNumber: 7 },
    4477: { patronName: 'Perfeccionista', patronNumber: 3 },
    4476: { patronName: 'Perfeccionista', patronNumber: 3 },
    4475: { patronName: 'Perfeccionista', patronNumber: 3 },
    4474: { patronName: 'Especialista', patronNumber: 15 },
    4473: { patronName: 'Especialista', patronNumber: 15 },
    4472: { patronName: 'Especialista', patronNumber: 15 },
    4471: { patronName: 'Especialista', patronNumber: 15 },
    4467: { patronName: 'Perfeccionista', patronNumber: 3 },
    4466: { patronName: 'Perfeccionista', patronNumber: 3 },
    4465: { patronName: 'Perfeccionista', patronNumber: 3 },
    4464: { patronName: 'Especialista', patronNumber: 15 },
    4463: { patronName: 'Especialista', patronNumber: 15 },
    4462: { patronName: 'Especialista', patronNumber: 15 },
    4461: { patronName: 'Especialista', patronNumber: 15 },
    4457: { patronName: 'Perfeccionista', patronNumber: 3 },
    4456: { patronName: 'Perfeccionista', patronNumber: 3 },
    4455: { patronName: 'Desconcertante', patronNumber: 17 },
    4454: { patronName: 'Desconcertante', patronNumber: 17 },
    4453: { patronName: 'Especialista', patronNumber: 15 },
    4452: { patronName: 'Especialista', patronNumber: 15 },
    4451: { patronName: 'Especialista', patronNumber: 15 },
    4447: { patronName: 'Objetivo', patronNumber: 5 },
    4446: { patronName: 'Objetivo', patronNumber: 5 },
    4445: { patronName: 'Desconcertante', patronNumber: 17 },
    4444: { patronName: 'Desconcertante', patronNumber: 17 },
    4443: { patronName: 'Desconcertante', patronNumber: 17 },
    4442: { patronName: 'Subactivo', patronNumber: 18 },
    4441: { patronName: 'Subactivo', patronNumber: 18 },
    4437: { patronName: 'Objetivo', patronNumber: 5 },
    4436: { patronName: 'Objetivo', patronNumber: 5 },
    4435: { patronName: 'Objetivo', patronNumber: 5 },
    4434: { patronName: 'Desconcertante', patronNumber: 17 },
    4433: { patronName: 'Desconcertante', patronNumber: 17 },
    4432: { patronName: 'Subactivo', patronNumber: 18 },
    4431: { patronName: 'Subactivo', patronNumber: 18 },
    4427: { patronName: 'Objetivo', patronNumber: 5 },
    4426: { patronName: 'Objetivo', patronNumber: 5 },
    4425: { patronName: 'Objetivo', patronNumber: 5 },
    4424: { patronName: 'Subactivo', patronNumber: 18 },
    4423: { patronName: 'Subactivo', patronNumber: 18 },
    4422: { patronName: 'Subactivo', patronNumber: 18 },
    4421: { patronName: 'Subactivo', patronNumber: 18 },
    4417: { patronName: 'Objetivo', patronNumber: 5 },
    4416: { patronName: 'Objetivo', patronNumber: 5 },
    4415: { patronName: 'Objetivo', patronNumber: 5 },
    4414: { patronName: 'Subactivo', patronNumber: 18 },
    4413: { patronName: 'Subactivo', patronNumber: 18 },
    4412: { patronName: 'Subactivo', patronNumber: 18 },
    4411: { patronName: 'Subactivo', patronNumber: 18 },
    4377: { patronName: 'Perfeccionista', patronNumber: 3 },
    4376: { patronName: 'Perfeccionista', patronNumber: 3 },
    4375: { patronName: 'Perfeccionista', patronNumber: 3 },
    4374: { patronName: 'Especialista', patronNumber: 15 },
    4373: { patronName: 'Especialista', patronNumber: 15 },
    4372: { patronName: 'Especialista', patronNumber: 15 },
    4371: { patronName: 'Especialista', patronNumber: 15 },
    4367: { patronName: 'Perfeccionista', patronNumber: 3 },
    4366: { patronName: 'Perfeccionista', patronNumber: 3 },
    4365: { patronName: 'Perfeccionista', patronNumber: 3 },
    4364: { patronName: 'Especialista', patronNumber: 15 },
    4363: { patronName: 'Especialista', patronNumber: 15 },
    4362: { patronName: 'Especialista', patronNumber: 15 },
    4361: { patronName: 'Especialista', patronNumber: 15 },
    4357: { patronName: 'Perfeccionista', patronNumber: 3 },
    4356: { patronName: 'Perfeccionista', patronNumber: 3 },
    4355: { patronName: 'Perfeccionista', patronNumber: 3 },
    4354: { patronName: 'Especialista', patronNumber: 15 },
    4353: { patronName: 'Especialista', patronNumber: 15 },
    4352: { patronName: 'Especialista', patronNumber: 15 },
    4351: { patronName: 'Especialista', patronNumber: 15 },
    4347: { patronName: 'Objetivo', patronNumber: 5 },
    4346: { patronName: 'Objetivo', patronNumber: 5 },
    4345: { patronName: 'Objetivo', patronNumber: 5 },
    4344: { patronName: 'Desconcertante', patronNumber: 17 },
    4343: { patronName: 'Desconcertante', patronNumber: 17 },
    4342: { patronName: 'Subactivo', patronNumber: 18 },
    4341: { patronName: 'Subactivo', patronNumber: 18 },
    4337: { patronName: 'Objetivo', patronNumber: 5 },
    4336: { patronName: 'Objetivo', patronNumber: 5 },
    4335: { patronName: 'Objetivo', patronNumber: 5 },
    4334: { patronName: 'Desconcertante', patronNumber: 17 },
    4333: { patronName: 'Desconcertante', patronNumber: 17 },
    4332: { patronName: 'Subactivo', patronNumber: 18 },
    4331: { patronName: 'Subactivo', patronNumber: 18 },
    4327: { patronName: 'Objetivo', patronNumber: 5 },
    4326: { patronName: 'Objetivo', patronNumber: 5 },
    4325: { patronName: 'Objetivo', patronNumber: 5 },
    4324: { patronName: 'Subactivo', patronNumber: 18 },
    4323: { patronName: 'Subactivo', patronNumber: 18 },
    4322: { patronName: 'Subactivo', patronNumber: 18 },
    4321: { patronName: 'Subactivo', patronNumber: 18 },
    4317: { patronName: 'Objetivo', patronNumber: 5 },
    4316: { patronName: 'Objetivo', patronNumber: 5 },
    4315: { patronName: 'Objetivo', patronNumber: 5 },
    4314: { patronName: 'Subactivo', patronNumber: 18 },
    4313: { patronName: 'Subactivo', patronNumber: 18 },
    4312: { patronName: 'Subactivo', patronNumber: 18 },
    4311: { patronName: 'Subactivo', patronNumber: 18 },
    4277: { patronName: 'Perfeccionista', patronNumber: 3 },
    4276: { patronName: 'Perfeccionista', patronNumber: 3 },
    4275: { patronName: 'Perfeccionista', patronNumber: 3 },
    4274: { patronName: 'Especialista', patronNumber: 15 },
    4273: { patronName: 'Especialista', patronNumber: 15 },
    4272: { patronName: 'Especialista', patronNumber: 15 },
    4271: { patronName: 'Especialista', patronNumber: 15 },
    4267: { patronName: 'Perfeccionista', patronNumber: 3 },
    4266: { patronName: 'Perfeccionista', patronNumber: 3 },
    4265: { patronName: 'Perfeccionista', patronNumber: 3 },
    4264: { patronName: 'Especialista', patronNumber: 15 },
    4263: { patronName: 'Especialista', patronNumber: 15 },
    4262: { patronName: 'Especialista', patronNumber: 15 },
    4261: { patronName: 'Especialista', patronNumber: 15 },
    4257: { patronName: 'Perfeccionista', patronNumber: 3 },
    4256: { patronName: 'Perfeccionista', patronNumber: 3 },
    4255: { patronName: 'Perfeccionista', patronNumber: 3 },
    4254: { patronName: 'Especialista', patronNumber: 15 },
    4253: { patronName: 'Especialista', patronNumber: 15 },
    4252: { patronName: 'Especialista', patronNumber: 15 },
    4251: { patronName: 'Especialista', patronNumber: 15 },
    4247: { patronName: 'Objetivo', patronNumber: 5 },
    4246: { patronName: 'Objetivo', patronNumber: 5 },
    4245: { patronName: 'Objetivo', patronNumber: 5 },
    4244: { patronName: 'Subactivo', patronNumber: 18 },
    4243: { patronName: 'Subactivo', patronNumber: 18 },
    4242: { patronName: 'Subactivo', patronNumber: 18 },
    4241: { patronName: 'Subactivo', patronNumber: 18 },
    4237: { patronName: 'Objetivo', patronNumber: 5 },
    4236: { patronName: 'Objetivo', patronNumber: 5 },
    4235: { patronName: 'Objetivo', patronNumber: 5 },
    4234: { patronName: 'Subactivo', patronNumber: 18 },
    4233: { patronName: 'Subactivo', patronNumber: 18 },
    4232: { patronName: 'Subactivo', patronNumber: 18 },
    4231: { patronName: 'Subactivo', patronNumber: 18 },
    4227: { patronName: 'Objetivo', patronNumber: 5 },
    4226: { patronName: 'Objetivo', patronNumber: 5 },
    4225: { patronName: 'Objetivo', patronNumber: 5 },
    4224: { patronName: 'Subactivo', patronNumber: 18 },
    4223: { patronName: 'Subactivo', patronNumber: 18 },
    4222: { patronName: 'Subactivo', patronNumber: 18 },
    4221: { patronName: 'Subactivo', patronNumber: 18 },
    4217: { patronName: 'Objetivo', patronNumber: 5 },
    4216: { patronName: 'Objetivo', patronNumber: 5 },
    4215: { patronName: 'Objetivo', patronNumber: 5 },
    4214: { patronName: 'Subactivo', patronNumber: 18 },
    4213: { patronName: 'Subactivo', patronNumber: 18 },
    4212: { patronName: 'Subactivo', patronNumber: 18 },
    4211: { patronName: 'Subactivo', patronNumber: 18 },
    4177: { patronName: 'Perfeccionista', patronNumber: 3 },
    4176: { patronName: 'Perfeccionista', patronNumber: 3 },
    4175: { patronName: 'Perfeccionista', patronNumber: 3 },
    4174: { patronName: 'Especialista', patronNumber: 15 },
    4173: { patronName: 'Especialista', patronNumber: 15 },
    4172: { patronName: 'Especialista', patronNumber: 15 },
    4171: { patronName: 'Especialista', patronNumber: 15 },
    4167: { patronName: 'Perfeccionista', patronNumber: 3 },
    4166: { patronName: 'Perfeccionista', patronNumber: 3 },
    4165: { patronName: 'Perfeccionista', patronNumber: 3 },
    4164: { patronName: 'Especialista', patronNumber: 15 },
    4163: { patronName: 'Especialista', patronNumber: 15 },
    4162: { patronName: 'Especialista', patronNumber: 15 },
    4161: { patronName: 'Especialista', patronNumber: 15 },
    4157: { patronName: 'Perfeccionista', patronNumber: 3 },
    4156: { patronName: 'Perfeccionista', patronNumber: 3 },
    4155: { patronName: 'Perfeccionista', patronNumber: 3 },
    4154: { patronName: 'Especialista', patronNumber: 15 },
    4153: { patronName: 'Especialista', patronNumber: 15 },
    4152: { patronName: 'Especialista', patronNumber: 15 },
    4151: { patronName: 'Especialista', patronNumber: 15 },
    4147: { patronName: 'Objetivo', patronNumber: 5 },
    4146: { patronName: 'Objetivo', patronNumber: 5 },
    4145: { patronName: 'Objetivo', patronNumber: 5 },
    4144: { patronName: 'Subactivo', patronNumber: 18 },
    4143: { patronName: 'Subactivo', patronNumber: 18 },
    4142: { patronName: 'Subactivo', patronNumber: 18 },
    4141: { patronName: 'Subactivo', patronNumber: 18 },
    4137: { patronName: 'Objetivo', patronNumber: 5 },
    4136: { patronName: 'Objetivo', patronNumber: 5 },
    4135: { patronName: 'Objetivo', patronNumber: 5 },
    4134: { patronName: 'Subactivo', patronNumber: 18 },
    4133: { patronName: 'Subactivo', patronNumber: 18 },
    4132: { patronName: 'Subactivo', patronNumber: 18 },
    4131: { patronName: 'Subactivo', patronNumber: 18 },
    4127: { patronName: 'Objetivo', patronNumber: 5 },
    4126: { patronName: 'Objetivo', patronNumber: 5 },
    4125: { patronName: 'Objetivo', patronNumber: 5 },
    4124: { patronName: 'Subactivo', patronNumber: 18 },
    4123: { patronName: 'Subactivo', patronNumber: 18 },
    4122: { patronName: 'Subactivo', patronNumber: 18 },
    4121: { patronName: 'Subactivo', patronNumber: 18 },
    4117: { patronName: 'Objetivo', patronNumber: 5 },
    4116: { patronName: 'Objetivo', patronNumber: 5 },
    4115: { patronName: 'Objetivo', patronNumber: 5 },
    4114: { patronName: 'Subactivo', patronNumber: 18 },
    4113: { patronName: 'Subactivo', patronNumber: 18 },
    4112: { patronName: 'Subactivo', patronNumber: 18 },
    4111: { patronName: 'Subactivo', patronNumber: 18 },
    3777: { patronName: 'Profesional', patronNumber: 12 },
    3776: { patronName: 'Profesional', patronNumber: 12 },
    3775: { patronName: 'Profesional', patronNumber: 12 },
    3774: { patronName: 'Consejero', patronNumber: 8 },
    3773: { patronName: 'Consejero', patronNumber: 8 },
    3772: { patronName: 'Consejero', patronNumber: 8 },
    3771: { patronName: 'Consejero', patronNumber: 8 },
    3767: { patronName: 'Profesional', patronNumber: 12 },
    3766: { patronName: 'Profesional', patronNumber: 12 },
    3765: { patronName: 'Profesional', patronNumber: 12 },
    3764: { patronName: 'Consejero', patronNumber: 8 },
    3763: { patronName: 'Consejero', patronNumber: 8 },
    3762: { patronName: 'Consejero', patronNumber: 8 },
    3761: { patronName: 'Consejero', patronNumber: 8 },
    3757: { patronName: 'Profesional', patronNumber: 12 },
    3756: { patronName: 'Profesional', patronNumber: 12 },
    3755: { patronName: 'Profesional', patronNumber: 12 },
    3754: { patronName: 'Consejero', patronNumber: 8 },
    3753: { patronName: 'Consejero', patronNumber: 8 },
    3752: { patronName: 'Consejero', patronNumber: 8 },
    3751: { patronName: 'Consejero', patronNumber: 8 },
    3747: { patronName: 'Profesional', patronNumber: 12 },
    3746: { patronName: 'Profesional', patronNumber: 12 },
    3745: { patronName: 'Profesional', patronNumber: 12 },
    3744: { patronName: 'Promotor', patronNumber: 7 },
    3743: { patronName: 'Promotor', patronNumber: 7 },
    3742: { patronName: 'Promotor', patronNumber: 7 },
    3741: { patronName: 'Promotor', patronNumber: 7 },
    3737: { patronName: 'Evaluador', patronNumber: 10 },
    3736: { patronName: 'Evaluador', patronNumber: 10 },
    3735: { patronName: 'Evaluador', patronNumber: 10 },
    3734: { patronName: 'Promotor', patronNumber: 7 },
    3733: { patronName: 'Promotor', patronNumber: 7 },
    3732: { patronName: 'Promotor', patronNumber: 7 },
    3731: { patronName: 'Promotor', patronNumber: 7 },
    3727: { patronName: 'Evaluador', patronNumber: 10 },
    3726: { patronName: 'Evaluador', patronNumber: 10 },
    3725: { patronName: 'Evaluador', patronNumber: 10 },
    3724: { patronName: 'Promotor', patronNumber: 7 },
    3723: { patronName: 'Promotor', patronNumber: 7 },
    3722: { patronName: 'Promotor', patronNumber: 7 },
    3721: { patronName: 'Promotor', patronNumber: 7 },
    3717: { patronName: 'Evaluador', patronNumber: 10 },
    3716: { patronName: 'Evaluador', patronNumber: 10 },
    3715: { patronName: 'Evaluador', patronNumber: 10 },
    3714: { patronName: 'Promotor', patronNumber: 7 },
    3713: { patronName: 'Promotor', patronNumber: 7 },
    3712: { patronName: 'Promotor', patronNumber: 7 },
    3711: { patronName: 'Promotor', patronNumber: 7 },
    3677: { patronName: 'Profesional', patronNumber: 12 },
    3676: { patronName: 'Profesional', patronNumber: 12 },
    3675: { patronName: 'Profesional', patronNumber: 12 },
    3674: { patronName: 'Agente', patronNumber: 9 },
    3673: { patronName: 'Agente', patronNumber: 9 },
    3672: { patronName: 'Agente', patronNumber: 9 },
    3671: { patronName: 'Agente', patronNumber: 9 },
    3667: { patronName: 'Profesional', patronNumber: 12 },
    3666: { patronName: 'Profesional', patronNumber: 12 },
    3665: { patronName: 'Profesional', patronNumber: 12 },
    3664: { patronName: 'Consejero', patronNumber: 8 },
    3663: { patronName: 'Consejero', patronNumber: 8 },
    3662: { patronName: 'Consejero', patronNumber: 8 },
    3661: { patronName: 'Consejero', patronNumber: 8 },
    3657: { patronName: 'Profesional', patronNumber: 12 },
    3656: { patronName: 'Profesional', patronNumber: 12 },
    3655: { patronName: 'Profesional', patronNumber: 12 },
    3654: { patronName: 'Consejero', patronNumber: 8 },
    3653: { patronName: 'Consejero', patronNumber: 8 },
    3652: { patronName: 'Consejero', patronNumber: 8 },
    3651: { patronName: 'Consejero', patronNumber: 8 },
    3647: { patronName: 'Profesional', patronNumber: 12 },
    3646: { patronName: 'Profesional', patronNumber: 12 },
    3645: { patronName: 'Profesional', patronNumber: 12 },
    3644: { patronName: 'Promotor', patronNumber: 7 },
    3643: { patronName: 'Promotor', patronNumber: 7 },
    3642: { patronName: 'Promotor', patronNumber: 7 },
    3641: { patronName: 'Promotor', patronNumber: 7 },
    3637: { patronName: 'Evaluador', patronNumber: 10 },
    3636: { patronName: 'Evaluador', patronNumber: 10 },
    3635: { patronName: 'Evaluador', patronNumber: 10 },
    3634: { patronName: 'Promotor', patronNumber: 7 },
    3633: { patronName: 'Promotor', patronNumber: 7 },
    3632: { patronName: 'Promotor', patronNumber: 7 },
    3631: { patronName: 'Promotor', patronNumber: 7 },
    3627: { patronName: 'Evaluador', patronNumber: 10 },
    3626: { patronName: 'Evaluador', patronNumber: 10 },
    3625: { patronName: 'Evaluador', patronNumber: 10 },
    3624: { patronName: 'Promotor', patronNumber: 7 },
    3623: { patronName: 'Promotor', patronNumber: 7 },
    3622: { patronName: 'Promotor', patronNumber: 7 },
    3621: { patronName: 'Promotor', patronNumber: 7 },
    3617: { patronName: 'Evaluador', patronNumber: 10 },
    3616: { patronName: 'Evaluador', patronNumber: 10 },
    3615: { patronName: 'Evaluador', patronNumber: 10 },
    3614: { patronName: 'Promotor', patronNumber: 7 },
    3613: { patronName: 'Promotor', patronNumber: 7 },
    3612: { patronName: 'Promotor', patronNumber: 7 },
    3611: { patronName: 'Promotor', patronNumber: 7 },
    3577: { patronName: 'Profesional', patronNumber: 12 },
    3576: { patronName: 'Profesional', patronNumber: 12 },
    3575: { patronName: 'Profesional', patronNumber: 12 },
    3574: { patronName: 'Agente', patronNumber: 9 },
    3573: { patronName: 'Agente', patronNumber: 9 },
    3572: { patronName: 'Agente', patronNumber: 9 },
    3571: { patronName: 'Agente', patronNumber: 9 },
    3567: { patronName: 'Profesional', patronNumber: 12 },
    3566: { patronName: 'Profesional', patronNumber: 12 },
    3565: { patronName: 'Profesional', patronNumber: 12 },
    3564: { patronName: 'Agente', patronNumber: 9 },
    3563: { patronName: 'Agente', patronNumber: 9 },
    3562: { patronName: 'Agente', patronNumber: 9 },
    3561: { patronName: 'Agente', patronNumber: 9 },
    3557: { patronName: 'Profesional', patronNumber: 12 },
    3556: { patronName: 'Profesional', patronNumber: 12 },
    3555: { patronName: 'Profesional', patronNumber: 12 },
    3554: { patronName: 'Consejero', patronNumber: 8 },
    3553: { patronName: 'Consejero', patronNumber: 8 },
    3552: { patronName: 'Consejero', patronNumber: 8 },
    3551: { patronName: 'Consejero', patronNumber: 8 },
    3547: { patronName: 'Profesional', patronNumber: 12 },
    3546: { patronName: 'Profesional', patronNumber: 12 },
    3545: { patronName: 'Profesional', patronNumber: 12 },
    3544: { patronName: 'Promotor', patronNumber: 7 },
    3543: { patronName: 'Promotor', patronNumber: 7 },
    3542: { patronName: 'Promotor', patronNumber: 7 },
    3541: { patronName: 'Promotor', patronNumber: 7 },
    3537: { patronName: 'Evaluador', patronNumber: 10 },
    3536: { patronName: 'Evaluador', patronNumber: 10 },
    3535: { patronName: 'Evaluador', patronNumber: 10 },
    3534: { patronName: 'Promotor', patronNumber: 7 },
    3533: { patronName: 'Promotor', patronNumber: 7 },
    3532: { patronName: 'Promotor', patronNumber: 7 },
    3531: { patronName: 'Promotor', patronNumber: 7 },
    3527: { patronName: 'Evaluador', patronNumber: 10 },
    3526: { patronName: 'Evaluador', patronNumber: 10 },
    3525: { patronName: 'Evaluador', patronNumber: 10 },
    3524: { patronName: 'Promotor', patronNumber: 7 },
    3523: { patronName: 'Promotor', patronNumber: 7 },
    3522: { patronName: 'Promotor', patronNumber: 7 },
    3521: { patronName: 'Promotor', patronNumber: 7 },
    3517: { patronName: 'Evaluador', patronNumber: 10 },
    3516: { patronName: 'Evaluador', patronNumber: 10 },
    3515: { patronName: 'Evaluador', patronNumber: 10 },
    3514: { patronName: 'Promotor', patronNumber: 7 },
    3513: { patronName: 'Promotor', patronNumber: 7 },
    3512: { patronName: 'Promotor', patronNumber: 7 },
    3511: { patronName: 'Promotor', patronNumber: 7 },
    3477: { patronName: 'Perfeccionista', patronNumber: 3 },
    3476: { patronName: 'Perfeccionista', patronNumber: 3 },
    3475: { patronName: 'Perfeccionista', patronNumber: 3 },
    3474: { patronName: 'Especialista', patronNumber: 15 },
    3473: { patronName: 'Especialista', patronNumber: 15 },
    3472: { patronName: 'Especialista', patronNumber: 15 },
    3471: { patronName: 'Especialista', patronNumber: 15 },
    3467: { patronName: 'Perfeccionista', patronNumber: 3 },
    3466: { patronName: 'Perfeccionista', patronNumber: 3 },
    3465: { patronName: 'Perfeccionista', patronNumber: 3 },
    3464: { patronName: 'Especialista', patronNumber: 15 },
    3463: { patronName: 'Especialista', patronNumber: 15 },
    3462: { patronName: 'Especialista', patronNumber: 15 },
    3461: { patronName: 'Especialista', patronNumber: 15 },
    3457: { patronName: 'Perfeccionista', patronNumber: 3 },
    3456: { patronName: 'Perfeccionista', patronNumber: 3 },
    3455: { patronName: 'Perfeccionista', patronNumber: 3 },
    3454: { patronName: 'Especialista', patronNumber: 15 },
    3453: { patronName: 'Especialista', patronNumber: 15 },
    3452: { patronName: 'Especialista', patronNumber: 15 },
    3451: { patronName: 'Especialista', patronNumber: 15 },
    3447: { patronName: 'Objetivo', patronNumber: 5 },
    3446: { patronName: 'Objetivo', patronNumber: 5 },
    3445: { patronName: 'Objetivo', patronNumber: 5 },
    3444: { patronName: 'Desconcertante', patronNumber: 17 },
    3443: { patronName: 'Desconcertante', patronNumber: 17 },
    3442: { patronName: 'Subactivo', patronNumber: 18 },
    3441: { patronName: 'Subactivo', patronNumber: 18 },
    3437: { patronName: 'Objetivo', patronNumber: 5 },
    3436: { patronName: 'Objetivo', patronNumber: 5 },
    3435: { patronName: 'Objetivo', patronNumber: 5 },
    3434: { patronName: 'Desconcertante', patronNumber: 17 },
    3433: { patronName: 'Desconcertante', patronNumber: 17 },
    3432: { patronName: 'Subactivo', patronNumber: 18 },
    3431: { patronName: 'Subactivo', patronNumber: 18 },
    3427: { patronName: 'Objetivo', patronNumber: 5 },
    3426: { patronName: 'Objetivo', patronNumber: 5 },
    3425: { patronName: 'Objetivo', patronNumber: 5 },
    3424: { patronName: 'Subactivo', patronNumber: 18 },
    3423: { patronName: 'Subactivo', patronNumber: 18 },
    3422: { patronName: 'Subactivo', patronNumber: 18 },
    3421: { patronName: 'Subactivo', patronNumber: 18 },
    3417: { patronName: 'Objetivo', patronNumber: 5 },
    3416: { patronName: 'Objetivo', patronNumber: 5 },
    3415: { patronName: 'Objetivo', patronNumber: 5 },
    3414: { patronName: 'Subactivo', patronNumber: 18 },
    3413: { patronName: 'Subactivo', patronNumber: 18 },
    3412: { patronName: 'Subactivo', patronNumber: 18 },
    3411: { patronName: 'Subactivo', patronNumber: 18 },
    3377: { patronName: 'Perfeccionista', patronNumber: 3 },
    3376: { patronName: 'Perfeccionista', patronNumber: 3 },
    3375: { patronName: 'Perfeccionista', patronNumber: 3 },
    3374: { patronName: 'Especialista', patronNumber: 15 },
    3373: { patronName: 'Especialista', patronNumber: 15 },
    3372: { patronName: 'Especialista', patronNumber: 15 },
    3371: { patronName: 'Especialista', patronNumber: 15 },
    3367: { patronName: 'Perfeccionista', patronNumber: 3 },
    3366: { patronName: 'Perfeccionista', patronNumber: 3 },
    3365: { patronName: 'Perfeccionista', patronNumber: 3 },
    3364: { patronName: 'Especialista', patronNumber: 15 },
    3363: { patronName: 'Especialista', patronNumber: 15 },
    3362: { patronName: 'Especialista', patronNumber: 15 },
    3361: { patronName: 'Especialista', patronNumber: 15 },
    3357: { patronName: 'Perfeccionista', patronNumber: 3 },
    3356: { patronName: 'Perfeccionista', patronNumber: 3 },
    3355: { patronName: 'Perfeccionista', patronNumber: 3 },
    3354: { patronName: 'Especialista', patronNumber: 15 },
    3353: { patronName: 'Especialista', patronNumber: 15 },
    3352: { patronName: 'Especialista', patronNumber: 15 },
    3351: { patronName: 'Especialista', patronNumber: 15 },
    3347: { patronName: 'Objetivo', patronNumber: 5 },
    3346: { patronName: 'Objetivo', patronNumber: 5 },
    3345: { patronName: 'Objetivo', patronNumber: 5 },
    3344: { patronName: 'Desconcertante', patronNumber: 17 },
    3343: { patronName: 'Desconcertante', patronNumber: 17 },
    3342: { patronName: 'Subactivo', patronNumber: 18 },
    3341: { patronName: 'Subactivo', patronNumber: 18 },
    3337: { patronName: 'Objetivo', patronNumber: 5 },
    3336: { patronName: 'Objetivo', patronNumber: 5 },
    3335: { patronName: 'Objetivo', patronNumber: 5 },
    3334: { patronName: 'Desconcertante', patronNumber: 17 },
    3333: { patronName: 'Subactivo', patronNumber: 18 },
    3332: { patronName: 'Subactivo', patronNumber: 18 },
    3331: { patronName: 'Subactivo', patronNumber: 18 },
    3327: { patronName: 'Objetivo', patronNumber: 5 },
    3326: { patronName: 'Objetivo', patronNumber: 5 },
    3325: { patronName: 'Objetivo', patronNumber: 5 },
    3324: { patronName: 'Subactivo', patronNumber: 18 },
    3323: { patronName: 'Subactivo', patronNumber: 18 },
    3322: { patronName: 'Subactivo', patronNumber: 18 },
    3321: { patronName: 'Subactivo', patronNumber: 18 },
    3317: { patronName: 'Objetivo', patronNumber: 5 },
    3316: { patronName: 'Objetivo', patronNumber: 5 },
    3315: { patronName: 'Objetivo', patronNumber: 5 },
    3314: { patronName: 'Subactivo', patronNumber: 18 },
    3313: { patronName: 'Subactivo', patronNumber: 18 },
    3312: { patronName: 'Subactivo', patronNumber: 18 },
    3311: { patronName: 'Subactivo', patronNumber: 18 },
    3277: { patronName: 'Perfeccionista', patronNumber: 3 },
    3276: { patronName: 'Perfeccionista', patronNumber: 3 },
    3275: { patronName: 'Perfeccionista', patronNumber: 3 },
    3274: { patronName: 'Especialista', patronNumber: 15 },
    3273: { patronName: 'Especialista', patronNumber: 15 },
    3272: { patronName: 'Especialista', patronNumber: 15 },
    3271: { patronName: 'Especialista', patronNumber: 15 },
    3267: { patronName: 'Perfeccionista', patronNumber: 3 },
    3266: { patronName: 'Perfeccionista', patronNumber: 3 },
    3265: { patronName: 'Perfeccionista', patronNumber: 3 },
    3264: { patronName: 'Especialista', patronNumber: 15 },
    3263: { patronName: 'Especialista', patronNumber: 15 },
    3262: { patronName: 'Especialista', patronNumber: 15 },
    3261: { patronName: 'Especialista', patronNumber: 15 },
    3257: { patronName: 'Perfeccionista', patronNumber: 3 },
    3256: { patronName: 'Perfeccionista', patronNumber: 3 },
    3255: { patronName: 'Perfeccionista', patronNumber: 3 },
    3254: { patronName: 'Especialista', patronNumber: 15 },
    3253: { patronName: 'Especialista', patronNumber: 15 },
    3252: { patronName: 'Especialista', patronNumber: 15 },
    3251: { patronName: 'Especialista', patronNumber: 15 },
    3247: { patronName: 'Objetivo', patronNumber: 5 },
    3246: { patronName: 'Objetivo', patronNumber: 5 },
    3245: { patronName: 'Objetivo', patronNumber: 5 },
    3244: { patronName: 'Subactivo', patronNumber: 18 },
    3243: { patronName: 'Subactivo', patronNumber: 18 },
    3242: { patronName: 'Subactivo', patronNumber: 18 },
    3241: { patronName: 'Subactivo', patronNumber: 18 },
    3237: { patronName: 'Objetivo', patronNumber: 5 },
    3236: { patronName: 'Objetivo', patronNumber: 5 },
    3235: { patronName: 'Objetivo', patronNumber: 5 },
    3234: { patronName: 'Subactivo', patronNumber: 18 },
    3233: { patronName: 'Subactivo', patronNumber: 18 },
    3232: { patronName: 'Subactivo', patronNumber: 18 },
    3231: { patronName: 'Subactivo', patronNumber: 18 },
    3227: { patronName: 'Objetivo', patronNumber: 5 },
    3226: { patronName: 'Objetivo', patronNumber: 5 },
    3225: { patronName: 'Objetivo', patronNumber: 5 },
    3224: { patronName: 'Subactivo', patronNumber: 18 },
    3223: { patronName: 'Subactivo', patronNumber: 18 },
    3222: { patronName: 'Subactivo', patronNumber: 18 },
    3221: { patronName: 'Subactivo', patronNumber: 18 },
    3217: { patronName: 'Objetivo', patronNumber: 5 },
    3216: { patronName: 'Objetivo', patronNumber: 5 },
    3215: { patronName: 'Objetivo', patronNumber: 5 },
    3214: { patronName: 'Subactivo', patronNumber: 18 },
    3213: { patronName: 'Subactivo', patronNumber: 18 },
    3212: { patronName: 'Subactivo', patronNumber: 18 },
    3211: { patronName: 'Subactivo', patronNumber: 18 },
    3177: { patronName: 'Perfeccionista', patronNumber: 3 },
    3176: { patronName: 'Perfeccionista', patronNumber: 3 },
    3175: { patronName: 'Perfeccionista', patronNumber: 3 },
    3174: { patronName: 'Especialista', patronNumber: 15 },
    3173: { patronName: 'Especialista', patronNumber: 15 },
    3172: { patronName: 'Especialista', patronNumber: 15 },
    3171: { patronName: 'Especialista', patronNumber: 15 },
    3167: { patronName: 'Perfeccionista', patronNumber: 3 },
    3166: { patronName: 'Perfeccionista', patronNumber: 3 },
    3165: { patronName: 'Perfeccionista', patronNumber: 3 },
    3164: { patronName: 'Especialista', patronNumber: 15 },
    3163: { patronName: 'Especialista', patronNumber: 15 },
    3162: { patronName: 'Especialista', patronNumber: 15 },
    3161: { patronName: 'Especialista', patronNumber: 15 },
    3157: { patronName: 'Perfeccionista', patronNumber: 3 },
    3156: { patronName: 'Perfeccionista', patronNumber: 3 },
    3155: { patronName: 'Perfeccionista', patronNumber: 3 },
    3154: { patronName: 'Especialista', patronNumber: 15 },
    3153: { patronName: 'Especialista', patronNumber: 15 },
    3152: { patronName: 'Especialista', patronNumber: 15 },
    3151: { patronName: 'Especialista', patronNumber: 15 },
    3147: { patronName: 'Objetivo', patronNumber: 5 },
    3146: { patronName: 'Objetivo', patronNumber: 5 },
    3145: { patronName: 'Objetivo', patronNumber: 5 },
    3144: { patronName: 'Subactivo', patronNumber: 18 },
    3143: { patronName: 'Subactivo', patronNumber: 18 },
    3142: { patronName: 'Subactivo', patronNumber: 18 },
    3141: { patronName: 'Subactivo', patronNumber: 18 },
    3137: { patronName: 'Objetivo', patronNumber: 5 },
    3136: { patronName: 'Objetivo', patronNumber: 5 },
    3135: { patronName: 'Objetivo', patronNumber: 5 },
    3134: { patronName: 'Subactivo', patronNumber: 18 },
    3133: { patronName: 'Subactivo', patronNumber: 18 },
    3132: { patronName: 'Subactivo', patronNumber: 18 },
    3131: { patronName: 'Subactivo', patronNumber: 18 },
    3127: { patronName: 'Objetivo', patronNumber: 5 },
    3126: { patronName: 'Objetivo', patronNumber: 5 },
    3125: { patronName: 'Objetivo', patronNumber: 5 },
    3124: { patronName: 'Subactivo', patronNumber: 18 },
    3123: { patronName: 'Subactivo', patronNumber: 18 },
    3122: { patronName: 'Subactivo', patronNumber: 18 },
    3121: { patronName: 'Subactivo', patronNumber: 18 },
    3117: { patronName: 'Objetivo', patronNumber: 5 },
    3116: { patronName: 'Objetivo', patronNumber: 5 },
    3115: { patronName: 'Objetivo', patronNumber: 5 },
    3114: { patronName: 'Subactivo', patronNumber: 18 },
    3113: { patronName: 'Subactivo', patronNumber: 18 },
    3112: { patronName: 'Subactivo', patronNumber: 18 },
    3111: { patronName: 'Subactivo', patronNumber: 18 },
    2777: { patronName: 'Profesional', patronNumber: 12 },
    2776: { patronName: 'Profesional', patronNumber: 12 },
    2775: { patronName: 'Profesional', patronNumber: 12 },
    2774: { patronName: 'Consejero', patronNumber: 8 },
    2773: { patronName: 'Consejero', patronNumber: 8 },
    2772: { patronName: 'Consejero', patronNumber: 8 },
    2771: { patronName: 'Consejero', patronNumber: 8 },
    2767: { patronName: 'Profesional', patronNumber: 12 },
    2766: { patronName: 'Profesional', patronNumber: 12 },
    2765: { patronName: 'Profesional', patronNumber: 12 },
    2764: { patronName: 'Consejero', patronNumber: 8 },
    2763: { patronName: 'Consejero', patronNumber: 8 },
    2762: { patronName: 'Consejero', patronNumber: 8 },
    2761: { patronName: 'Consejero', patronNumber: 8 },
    2757: { patronName: 'Profesional', patronNumber: 12 },
    2756: { patronName: 'Profesional', patronNumber: 12 },
    2755: { patronName: 'Profesional', patronNumber: 12 },
    2754: { patronName: 'Consejero', patronNumber: 8 },
    2753: { patronName: 'Consejero', patronNumber: 8 },
    2752: { patronName: 'Consejero', patronNumber: 8 },
    2751: { patronName: 'Consejero', patronNumber: 8 },
    2747: { patronName: 'Profesional', patronNumber: 12 },
    2746: { patronName: 'Profesional', patronNumber: 12 },
    2745: { patronName: 'Profesional', patronNumber: 12 },
    2744: { patronName: 'Promotor', patronNumber: 7 },
    2743: { patronName: 'Promotor', patronNumber: 7 },
    2742: { patronName: 'Promotor', patronNumber: 7 },
    2741: { patronName: 'Promotor', patronNumber: 7 },
    2737: { patronName: 'Evaluador', patronNumber: 10 },
    2736: { patronName: 'Evaluador', patronNumber: 10 },
    2735: { patronName: 'Evaluador', patronNumber: 10 },
    2734: { patronName: 'Promotor', patronNumber: 7 },
    2733: { patronName: 'Promotor', patronNumber: 7 },
    2732: { patronName: 'Promotor', patronNumber: 7 },
    2731: { patronName: 'Promotor', patronNumber: 7 },
    2727: { patronName: 'Evaluador', patronNumber: 10 },
    2726: { patronName: 'Evaluador', patronNumber: 10 },
    2725: { patronName: 'Evaluador', patronNumber: 10 },
    2724: { patronName: 'Promotor', patronNumber: 7 },
    2723: { patronName: 'Promotor', patronNumber: 7 },
    2722: { patronName: 'Promotor', patronNumber: 7 },
    2721: { patronName: 'Promotor', patronNumber: 7 },
    2717: { patronName: 'Evaluador', patronNumber: 10 },
    2716: { patronName: 'Evaluador', patronNumber: 10 },
    2715: { patronName: 'Evaluador', patronNumber: 10 },
    2714: { patronName: 'Promotor', patronNumber: 7 },
    2713: { patronName: 'Promotor', patronNumber: 7 },
    2712: { patronName: 'Promotor', patronNumber: 7 },
    2711: { patronName: 'Promotor', patronNumber: 7 },
    2677: { patronName: 'Profesional', patronNumber: 12 },
    2676: { patronName: 'Profesional', patronNumber: 12 },
    2675: { patronName: 'Profesional', patronNumber: 12 },
    2674: { patronName: 'Agente', patronNumber: 9 },
    2673: { patronName: 'Agente', patronNumber: 9 },
    2672: { patronName: 'Agente', patronNumber: 9 },
    2671: { patronName: 'Agente', patronNumber: 9 },
    2667: { patronName: 'Profesional', patronNumber: 12 },
    2666: { patronName: 'Profesional', patronNumber: 12 },
    2665: { patronName: 'Profesional', patronNumber: 12 },
    2664: { patronName: 'Consejero', patronNumber: 8 },
    2663: { patronName: 'Consejero', patronNumber: 8 },
    2662: { patronName: 'Consejero', patronNumber: 8 },
    2661: { patronName: 'Consejero', patronNumber: 8 },
    2657: { patronName: 'Profesional', patronNumber: 12 },
    2656: { patronName: 'Profesional', patronNumber: 12 },
    2655: { patronName: 'Profesional', patronNumber: 12 },
    2654: { patronName: 'Consejero', patronNumber: 8 },
    2653: { patronName: 'Consejero', patronNumber: 8 },
    2652: { patronName: 'Consejero', patronNumber: 8 },
    2651: { patronName: 'Consejero', patronNumber: 8 },
    2647: { patronName: 'Profesional', patronNumber: 12 },
    2646: { patronName: 'Profesional', patronNumber: 12 },
    2645: { patronName: 'Profesional', patronNumber: 12 },
    2644: { patronName: 'Promotor', patronNumber: 7 },
    2643: { patronName: 'Promotor', patronNumber: 7 },
    2642: { patronName: 'Promotor', patronNumber: 7 },
    2641: { patronName: 'Promotor', patronNumber: 7 },
    2637: { patronName: 'Evaluador', patronNumber: 10 },
    2636: { patronName: 'Evaluador', patronNumber: 10 },
    2635: { patronName: 'Evaluador', patronNumber: 10 },
    2634: { patronName: 'Promotor', patronNumber: 7 },
    2633: { patronName: 'Promotor', patronNumber: 7 },
    2632: { patronName: 'Promotor', patronNumber: 7 },
    2631: { patronName: 'Promotor', patronNumber: 7 },
    2627: { patronName: 'Evaluador', patronNumber: 10 },
    2626: { patronName: 'Evaluador', patronNumber: 10 },
    2625: { patronName: 'Evaluador', patronNumber: 10 },
    2624: { patronName: 'Promotor', patronNumber: 7 },
    2623: { patronName: 'Promotor', patronNumber: 7 },
    2622: { patronName: 'Promotor', patronNumber: 7 },
    2621: { patronName: 'Promotor', patronNumber: 7 },
    2617: { patronName: 'Evaluador', patronNumber: 10 },
    2616: { patronName: 'Evaluador', patronNumber: 10 },
    2615: { patronName: 'Evaluador', patronNumber: 10 },
    2614: { patronName: 'Promotor', patronNumber: 7 },
    2613: { patronName: 'Promotor', patronNumber: 7 },
    2612: { patronName: 'Promotor', patronNumber: 7 },
    2611: { patronName: 'Promotor', patronNumber: 7 },
    2577: { patronName: 'Profesional', patronNumber: 12 },
    2576: { patronName: 'Profesional', patronNumber: 12 },
    2575: { patronName: 'Profesional', patronNumber: 12 },
    2574: { patronName: 'Agente', patronNumber: 9 },
    2573: { patronName: 'Agente', patronNumber: 9 },
    2572: { patronName: 'Agente', patronNumber: 9 },
    2571: { patronName: 'Agente', patronNumber: 9 },
    2567: { patronName: 'Profesional', patronNumber: 12 },
    2566: { patronName: 'Profesional', patronNumber: 12 },
    2565: { patronName: 'Profesional', patronNumber: 12 },
    2564: { patronName: 'Agente', patronNumber: 9 },
    2563: { patronName: 'Agente', patronNumber: 9 },
    2562: { patronName: 'Agente', patronNumber: 9 },
    2561: { patronName: 'Agente', patronNumber: 9 },
    2557: { patronName: 'Profesional', patronNumber: 12 },
    2556: { patronName: 'Profesional', patronNumber: 12 },
    2555: { patronName: 'Profesional', patronNumber: 12 },
    2554: { patronName: 'Consejero', patronNumber: 8 },
    2553: { patronName: 'Consejero', patronNumber: 8 },
    2552: { patronName: 'Consejero', patronNumber: 8 },
    2551: { patronName: 'Consejero', patronNumber: 8 },
    2547: { patronName: 'Profesional', patronNumber: 12 },
    2546: { patronName: 'Profesional', patronNumber: 12 },
    2545: { patronName: 'Profesional', patronNumber: 12 },
    2544: { patronName: 'Promotor', patronNumber: 7 },
    2543: { patronName: 'Promotor', patronNumber: 7 },
    2542: { patronName: 'Promotor', patronNumber: 7 },
    2541: { patronName: 'Promotor', patronNumber: 7 },
    2537: { patronName: 'Evaluador', patronNumber: 10 },
    2536: { patronName: 'Evaluador', patronNumber: 10 },
    2535: { patronName: 'Evaluador', patronNumber: 10 },
    2534: { patronName: 'Promotor', patronNumber: 7 },
    2533: { patronName: 'Promotor', patronNumber: 7 },
    2532: { patronName: 'Promotor', patronNumber: 7 },
    2531: { patronName: 'Promotor', patronNumber: 7 },
    2527: { patronName: 'Evaluador', patronNumber: 10 },
    2526: { patronName: 'Evaluador', patronNumber: 10 },
    2525: { patronName: 'Evaluador', patronNumber: 10 },
    2524: { patronName: 'Promotor', patronNumber: 7 },
    2523: { patronName: 'Promotor', patronNumber: 7 },
    2522: { patronName: 'Promotor', patronNumber: 7 },
    2521: { patronName: 'Promotor', patronNumber: 7 },
    2517: { patronName: 'Evaluador', patronNumber: 10 },
    2516: { patronName: 'Evaluador', patronNumber: 10 },
    2515: { patronName: 'Evaluador', patronNumber: 10 },
    2514: { patronName: 'Promotor', patronNumber: 7 },
    2513: { patronName: 'Promotor', patronNumber: 7 },
    2512: { patronName: 'Promotor', patronNumber: 7 },
    2511: { patronName: 'Promotor', patronNumber: 7 },
    2477: { patronName: 'Perfeccionista', patronNumber: 3 },
    2476: { patronName: 'Perfeccionista', patronNumber: 3 },
    2475: { patronName: 'Perfeccionista', patronNumber: 3 },
    2474: { patronName: 'Especialista', patronNumber: 15 },
    2473: { patronName: 'Especialista', patronNumber: 15 },
    2472: { patronName: 'Especialista', patronNumber: 15 },
    2471: { patronName: 'Especialista', patronNumber: 15 },
    2467: { patronName: 'Perfeccionista', patronNumber: 3 },
    2466: { patronName: 'Perfeccionista', patronNumber: 3 },
    2465: { patronName: 'Perfeccionista', patronNumber: 3 },
    2464: { patronName: 'Especialista', patronNumber: 15 },
    2463: { patronName: 'Especialista', patronNumber: 15 },
    2462: { patronName: 'Especialista', patronNumber: 15 },
    2461: { patronName: 'Especialista', patronNumber: 15 },
    2457: { patronName: 'Perfeccionista', patronNumber: 3 },
    2456: { patronName: 'Perfeccionista', patronNumber: 3 },
    2455: { patronName: 'Perfeccionista', patronNumber: 3 },
    2454: { patronName: 'Especialista', patronNumber: 15 },
    2453: { patronName: 'Especialista', patronNumber: 15 },
    2452: { patronName: 'Especialista', patronNumber: 15 },
    2451: { patronName: 'Especialista', patronNumber: 15 },
    2447: { patronName: 'Objetivo', patronNumber: 5 },
    2446: { patronName: 'Objetivo', patronNumber: 5 },
    2445: { patronName: 'Objetivo', patronNumber: 5 },
    2444: { patronName: 'Subactivo', patronNumber: 18 },
    2443: { patronName: 'Subactivo', patronNumber: 18 },
    2442: { patronName: 'Subactivo', patronNumber: 18 },
    2441: { patronName: 'Subactivo', patronNumber: 18 },
    2437: { patronName: 'Objetivo', patronNumber: 5 },
    2436: { patronName: 'Objetivo', patronNumber: 5 },
    2435: { patronName: 'Objetivo', patronNumber: 5 },
    2434: { patronName: 'Subactivo', patronNumber: 18 },
    2433: { patronName: 'Subactivo', patronNumber: 18 },
    2432: { patronName: 'Subactivo', patronNumber: 18 },
    2431: { patronName: 'Subactivo', patronNumber: 18 },
    2427: { patronName: 'Objetivo', patronNumber: 5 },
    2426: { patronName: 'Objetivo', patronNumber: 5 },
    2425: { patronName: 'Objetivo', patronNumber: 5 },
    2424: { patronName: 'Subactivo', patronNumber: 18 },
    2423: { patronName: 'Subactivo', patronNumber: 18 },
    2422: { patronName: 'Subactivo', patronNumber: 18 },
    2421: { patronName: 'Subactivo', patronNumber: 18 },
    2417: { patronName: 'Objetivo', patronNumber: 5 },
    2416: { patronName: 'Objetivo', patronNumber: 5 },
    2415: { patronName: 'Objetivo', patronNumber: 5 },
    2414: { patronName: 'Subactivo', patronNumber: 18 },
    2413: { patronName: 'Subactivo', patronNumber: 18 },
    2412: { patronName: 'Subactivo', patronNumber: 18 },
    2411: { patronName: 'Subactivo', patronNumber: 18 },
    2377: { patronName: 'Perfeccionista', patronNumber: 3 },
    2376: { patronName: 'Perfeccionista', patronNumber: 3 },
    2375: { patronName: 'Perfeccionista', patronNumber: 3 },
    2374: { patronName: 'Especialista', patronNumber: 15 },
    2373: { patronName: 'Especialista', patronNumber: 15 },
    2372: { patronName: 'Especialista', patronNumber: 15 },
    2371: { patronName: 'Especialista', patronNumber: 15 },
    2367: { patronName: 'Perfeccionista', patronNumber: 3 },
    2366: { patronName: 'Perfeccionista', patronNumber: 3 },
    2365: { patronName: 'Perfeccionista', patronNumber: 3 },
    2364: { patronName: 'Especialista', patronNumber: 15 },
    2363: { patronName: 'Especialista', patronNumber: 15 },
    2362: { patronName: 'Especialista', patronNumber: 15 },
    2361: { patronName: 'Especialista', patronNumber: 15 },
    2357: { patronName: 'Perfeccionista', patronNumber: 3 },
    2356: { patronName: 'Perfeccionista', patronNumber: 3 },
    2355: { patronName: 'Perfeccionista', patronNumber: 3 },
    2354: { patronName: 'Especialista', patronNumber: 15 },
    2353: { patronName: 'Especialista', patronNumber: 15 },
    2352: { patronName: 'Especialista', patronNumber: 15 },
    2351: { patronName: 'Especialista', patronNumber: 15 },
    2347: { patronName: 'Objetivo', patronNumber: 5 },
    2346: { patronName: 'Objetivo', patronNumber: 5 },
    2345: { patronName: 'Objetivo', patronNumber: 5 },
    2344: { patronName: 'Subactivo', patronNumber: 18 },
    2343: { patronName: 'Subactivo', patronNumber: 18 },
    2342: { patronName: 'Subactivo', patronNumber: 18 },
    2341: { patronName: 'Subactivo', patronNumber: 18 },
    2337: { patronName: 'Objetivo', patronNumber: 5 },
    2336: { patronName: 'Objetivo', patronNumber: 5 },
    2335: { patronName: 'Objetivo', patronNumber: 5 },
    2334: { patronName: 'Subactivo', patronNumber: 18 },
    2333: { patronName: 'Subactivo', patronNumber: 18 },
    2332: { patronName: 'Subactivo', patronNumber: 18 },
    2331: { patronName: 'Subactivo', patronNumber: 18 },
    2327: { patronName: 'Objetivo', patronNumber: 5 },
    2326: { patronName: 'Objetivo', patronNumber: 5 },
    2325: { patronName: 'Objetivo', patronNumber: 5 },
    2324: { patronName: 'Subactivo', patronNumber: 18 },
    2323: { patronName: 'Subactivo', patronNumber: 18 },
    2322: { patronName: 'Subactivo', patronNumber: 18 },
    2321: { patronName: 'Subactivo', patronNumber: 18 },
    2317: { patronName: 'Objetivo', patronNumber: 5 },
    2316: { patronName: 'Objetivo', patronNumber: 5 },
    2315: { patronName: 'Objetivo', patronNumber: 5 },
    2314: { patronName: 'Subactivo', patronNumber: 18 },
    2313: { patronName: 'Subactivo', patronNumber: 18 },
    2312: { patronName: 'Subactivo', patronNumber: 18 },
    2311: { patronName: 'Subactivo', patronNumber: 18 },
    2277: { patronName: 'Perfeccionista', patronNumber: 3 },
    2276: { patronName: 'Perfeccionista', patronNumber: 3 },
    2275: { patronName: 'Perfeccionista', patronNumber: 3 },
    2274: { patronName: 'Especialista', patronNumber: 15 },
    2273: { patronName: 'Especialista', patronNumber: 15 },
    2272: { patronName: 'Especialista', patronNumber: 15 },
    2271: { patronName: 'Especialista', patronNumber: 15 },
    2267: { patronName: 'Perfeccionista', patronNumber: 3 },
    2266: { patronName: 'Perfeccionista', patronNumber: 3 },
    2265: { patronName: 'Perfeccionista', patronNumber: 3 },
    2264: { patronName: 'Especialista', patronNumber: 15 },
    2263: { patronName: 'Especialista', patronNumber: 15 },
    2262: { patronName: 'Especialista', patronNumber: 15 },
    2261: { patronName: 'Especialista', patronNumber: 15 },
    2257: { patronName: 'Perfeccionista', patronNumber: 3 },
    2256: { patronName: 'Perfeccionista', patronNumber: 3 },
    2255: { patronName: 'Perfeccionista', patronNumber: 3 },
    2254: { patronName: 'Especialista', patronNumber: 15 },
    2253: { patronName: 'Especialista', patronNumber: 15 },
    2252: { patronName: 'Especialista', patronNumber: 15 },
    2251: { patronName: 'Especialista', patronNumber: 15 },
    2247: { patronName: 'Objetivo', patronNumber: 5 },
    2246: { patronName: 'Objetivo', patronNumber: 5 },
    2245: { patronName: 'Objetivo', patronNumber: 5 },
    2244: { patronName: 'Subactivo', patronNumber: 18 },
    2243: { patronName: 'Subactivo', patronNumber: 18 },
    2242: { patronName: 'Subactivo', patronNumber: 18 },
    2241: { patronName: 'Subactivo', patronNumber: 18 },
    2237: { patronName: 'Objetivo', patronNumber: 5 },
    2236: { patronName: 'Objetivo', patronNumber: 5 },
    2235: { patronName: 'Objetivo', patronNumber: 5 },
    2234: { patronName: 'Subactivo', patronNumber: 18 },
    2233: { patronName: 'Subactivo', patronNumber: 18 },
    2232: { patronName: 'Subactivo', patronNumber: 18 },
    2231: { patronName: 'Subactivo', patronNumber: 18 },
    2227: { patronName: 'Objetivo', patronNumber: 5 },
    2226: { patronName: 'Objetivo', patronNumber: 5 },
    2225: { patronName: 'Objetivo', patronNumber: 5 },
    2224: { patronName: 'Subactivo', patronNumber: 18 },
    2223: { patronName: 'Subactivo', patronNumber: 18 },
    2222: { patronName: 'Subactivo', patronNumber: 18 },
    2221: { patronName: 'Subactivo', patronNumber: 18 },
    2217: { patronName: 'Objetivo', patronNumber: 5 },
    2216: { patronName: 'Objetivo', patronNumber: 5 },
    2215: { patronName: 'Objetivo', patronNumber: 5 },
    2214: { patronName: 'Subactivo', patronNumber: 18 },
    2213: { patronName: 'Subactivo', patronNumber: 18 },
    2212: { patronName: 'Subactivo', patronNumber: 18 },
    2211: { patronName: 'Subactivo', patronNumber: 18 },
    2177: { patronName: 'Perfeccionista', patronNumber: 3 },
    2176: { patronName: 'Perfeccionista', patronNumber: 3 },
    2175: { patronName: 'Perfeccionista', patronNumber: 3 },
    2174: { patronName: 'Especialista', patronNumber: 15 },
    2173: { patronName: 'Especialista', patronNumber: 15 },
    2172: { patronName: 'Especialista', patronNumber: 15 },
    2171: { patronName: 'Especialista', patronNumber: 15 },
    2167: { patronName: 'Perfeccionista', patronNumber: 3 },
    2166: { patronName: 'Perfeccionista', patronNumber: 3 },
    2165: { patronName: 'Perfeccionista', patronNumber: 3 },
    2164: { patronName: 'Especialista', patronNumber: 15 },
    2163: { patronName: 'Especialista', patronNumber: 15 },
    2162: { patronName: 'Especialista', patronNumber: 15 },
    2161: { patronName: 'Especialista', patronNumber: 15 },
    2157: { patronName: 'Perfeccionista', patronNumber: 3 },
    2156: { patronName: 'Perfeccionista', patronNumber: 3 },
    2155: { patronName: 'Perfeccionista', patronNumber: 3 },
    2154: { patronName: 'Especialista', patronNumber: 15 },
    2153: { patronName: 'Especialista', patronNumber: 15 },
    2152: { patronName: 'Especialista', patronNumber: 15 },
    2151: { patronName: 'Especialista', patronNumber: 15 },
    2147: { patronName: 'Objetivo', patronNumber: 5 },
    2146: { patronName: 'Objetivo', patronNumber: 5 },
    2145: { patronName: 'Objetivo', patronNumber: 5 },
    2144: { patronName: 'Subactivo', patronNumber: 18 },
    2143: { patronName: 'Subactivo', patronNumber: 18 },
    2142: { patronName: 'Subactivo', patronNumber: 18 },
    2141: { patronName: 'Subactivo', patronNumber: 18 },
    2137: { patronName: 'Objetivo', patronNumber: 5 },
    2136: { patronName: 'Objetivo', patronNumber: 5 },
    2135: { patronName: 'Objetivo', patronNumber: 5 },
    2134: { patronName: 'Subactivo', patronNumber: 18 },
    2133: { patronName: 'Subactivo', patronNumber: 18 },
    2132: { patronName: 'Subactivo', patronNumber: 18 },
    2131: { patronName: 'Subactivo', patronNumber: 18 },
    2127: { patronName: 'Objetivo', patronNumber: 5 },
    2126: { patronName: 'Objetivo', patronNumber: 5 },
    2125: { patronName: 'Objetivo', patronNumber: 5 },
    2124: { patronName: 'Subactivo', patronNumber: 18 },
    2123: { patronName: 'Subactivo', patronNumber: 18 },
    2122: { patronName: 'Subactivo', patronNumber: 18 },
    2121: { patronName: 'Subactivo', patronNumber: 18 },
    2117: { patronName: 'Objetivo', patronNumber: 5 },
    2116: { patronName: 'Objetivo', patronNumber: 5 },
    2115: { patronName: 'Objetivo', patronNumber: 5 },
    2114: { patronName: 'Subactivo', patronNumber: 18 },
    2113: { patronName: 'Subactivo', patronNumber: 18 },
    2112: { patronName: 'Subactivo', patronNumber: 18 },
    2111: { patronName: 'Subactivo', patronNumber: 18 },
    1777: { patronName: 'Profesional', patronNumber: 12 },
    1776: { patronName: 'Profesional', patronNumber: 12 },
    1775: { patronName: 'Profesional', patronNumber: 12 },
    1774: { patronName: 'Consejero', patronNumber: 8 },
    1773: { patronName: 'Consejero', patronNumber: 8 },
    1772: { patronName: 'Consejero', patronNumber: 8 },
    1771: { patronName: 'Consejero', patronNumber: 8 },
    1767: { patronName: 'Profesional', patronNumber: 12 },
    1766: { patronName: 'Profesional', patronNumber: 12 },
    1765: { patronName: 'Profesional', patronNumber: 12 },
    1764: { patronName: 'Consejero', patronNumber: 8 },
    1763: { patronName: 'Consejero', patronNumber: 8 },
    1762: { patronName: 'Consejero', patronNumber: 8 },
    1761: { patronName: 'Consejero', patronNumber: 8 },
    1757: { patronName: 'Profesional', patronNumber: 12 },
    1756: { patronName: 'Profesional', patronNumber: 12 },
    1755: { patronName: 'Profesional', patronNumber: 12 },
    1754: { patronName: 'Consejero', patronNumber: 8 },
    1753: { patronName: 'Consejero', patronNumber: 8 },
    1752: { patronName: 'Consejero', patronNumber: 8 },
    1751: { patronName: 'Consejero', patronNumber: 8 },
    1747: { patronName: 'Profesional', patronNumber: 12 },
    1746: { patronName: 'Profesional', patronNumber: 12 },
    1745: { patronName: 'Profesional', patronNumber: 12 },
    1744: { patronName: 'Promotor', patronNumber: 7 },
    1743: { patronName: 'Promotor', patronNumber: 7 },
    1742: { patronName: 'Promotor', patronNumber: 7 },
    1741: { patronName: 'Promotor', patronNumber: 7 },
    1737: { patronName: 'Evaluador', patronNumber: 10 },
    1736: { patronName: 'Evaluador', patronNumber: 10 },
    1735: { patronName: 'Evaluador', patronNumber: 10 },
    1734: { patronName: 'Promotor', patronNumber: 7 },
    1733: { patronName: 'Promotor', patronNumber: 7 },
    1732: { patronName: 'Promotor', patronNumber: 7 },
    1731: { patronName: 'Promotor', patronNumber: 7 },
    1727: { patronName: 'Evaluador', patronNumber: 10 },
    1726: { patronName: 'Evaluador', patronNumber: 10 },
    1725: { patronName: 'Evaluador', patronNumber: 10 },
    1724: { patronName: 'Promotor', patronNumber: 7 },
    1723: { patronName: 'Promotor', patronNumber: 7 },
    1722: { patronName: 'Promotor', patronNumber: 7 },
    1721: { patronName: 'Promotor', patronNumber: 7 },
    1717: { patronName: 'Evaluador', patronNumber: 10 },
    1716: { patronName: 'Evaluador', patronNumber: 10 },
    1715: { patronName: 'Evaluador', patronNumber: 10 },
    1714: { patronName: 'Promotor', patronNumber: 7 },
    1713: { patronName: 'Promotor', patronNumber: 7 },
    1712: { patronName: 'Promotor', patronNumber: 7 },
    1711: { patronName: 'Promotor', patronNumber: 7 },
    1677: { patronName: 'Profesional', patronNumber: 12 },
    1676: { patronName: 'Profesional', patronNumber: 12 },
    1675: { patronName: 'Profesional', patronNumber: 12 },
    1674: { patronName: 'Agente', patronNumber: 9 },
    1673: { patronName: 'Agente', patronNumber: 9 },
    1672: { patronName: 'Agente', patronNumber: 9 },
    1671: { patronName: 'Agente', patronNumber: 9 },
    1667: { patronName: 'Profesional', patronNumber: 12 },
    1666: { patronName: 'Profesional', patronNumber: 12 },
    1665: { patronName: 'Profesional', patronNumber: 12 },
    1664: { patronName: 'Consejero', patronNumber: 8 },
    1663: { patronName: 'Consejero', patronNumber: 8 },
    1662: { patronName: 'Consejero', patronNumber: 8 },
    1661: { patronName: 'Consejero', patronNumber: 8 },
    1657: { patronName: 'Profesional', patronNumber: 12 },
    1656: { patronName: 'Profesional', patronNumber: 12 },
    1655: { patronName: 'Profesional', patronNumber: 12 },
    1654: { patronName: 'Consejero', patronNumber: 8 },
    1653: { patronName: 'Consejero', patronNumber: 8 },
    1652: { patronName: 'Consejero', patronNumber: 8 },
    1651: { patronName: 'Consejero', patronNumber: 8 },
    1647: { patronName: 'Profesional', patronNumber: 12 },
    1646: { patronName: 'Profesional', patronNumber: 12 },
    1645: { patronName: 'Profesional', patronNumber: 12 },
    1644: { patronName: 'Promotor', patronNumber: 7 },
    1643: { patronName: 'Promotor', patronNumber: 7 },
    1642: { patronName: 'Promotor', patronNumber: 7 },
    1641: { patronName: 'Promotor', patronNumber: 7 },
    1637: { patronName: 'Evaluador', patronNumber: 10 },
    1636: { patronName: 'Evaluador', patronNumber: 10 },
    1635: { patronName: 'Evaluador', patronNumber: 10 },
    1634: { patronName: 'Promotor', patronNumber: 7 },
    1633: { patronName: 'Promotor', patronNumber: 7 },
    1632: { patronName: 'Promotor', patronNumber: 7 },
    1631: { patronName: 'Promotor', patronNumber: 7 },
    1627: { patronName: 'Evaluador', patronNumber: 10 },
    1626: { patronName: 'Evaluador', patronNumber: 10 },
    1625: { patronName: 'Evaluador', patronNumber: 10 },
    1624: { patronName: 'Promotor', patronNumber: 7 },
    1623: { patronName: 'Promotor', patronNumber: 7 },
    1622: { patronName: 'Promotor', patronNumber: 7 },
    1621: { patronName: 'Promotor', patronNumber: 7 },
    1617: { patronName: 'Evaluador', patronNumber: 10 },
    1616: { patronName: 'Evaluador', patronNumber: 10 },
    1615: { patronName: 'Evaluador', patronNumber: 10 },
    1614: { patronName: 'Promotor', patronNumber: 7 },
    1613: { patronName: 'Promotor', patronNumber: 7 },
    1612: { patronName: 'Promotor', patronNumber: 7 },
    1611: { patronName: 'Promotor', patronNumber: 7 },
    1577: { patronName: 'Profesional', patronNumber: 12 },
    1576: { patronName: 'Profesional', patronNumber: 12 },
    1575: { patronName: 'Profesional', patronNumber: 12 },
    1574: { patronName: 'Agente', patronNumber: 9 },
    1573: { patronName: 'Agente', patronNumber: 9 },
    1572: { patronName: 'Agente', patronNumber: 9 },
    1571: { patronName: 'Agente', patronNumber: 9 },
    1567: { patronName: 'Profesional', patronNumber: 12 },
    1566: { patronName: 'Profesional', patronNumber: 12 },
    1565: { patronName: 'Profesional', patronNumber: 12 },
    1564: { patronName: 'Agente', patronNumber: 9 },
    1563: { patronName: 'Agente', patronNumber: 9 },
    1562: { patronName: 'Agente', patronNumber: 9 },
    1561: { patronName: 'Agente', patronNumber: 9 },
    1557: { patronName: 'Profesional', patronNumber: 12 },
    1556: { patronName: 'Profesional', patronNumber: 12 },
    1555: { patronName: 'Profesional', patronNumber: 12 },
    1554: { patronName: 'Consejero', patronNumber: 8 },
    1553: { patronName: 'Consejero', patronNumber: 8 },
    1552: { patronName: 'Consejero', patronNumber: 8 },
    1551: { patronName: 'Consejero', patronNumber: 8 },
    1547: { patronName: 'Profesional', patronNumber: 12 },
    1546: { patronName: 'Profesional', patronNumber: 12 },
    1545: { patronName: 'Profesional', patronNumber: 12 },
    1544: { patronName: 'Promotor', patronNumber: 7 },
    1543: { patronName: 'Promotor', patronNumber: 7 },
    1542: { patronName: 'Promotor', patronNumber: 7 },
    1541: { patronName: 'Promotor', patronNumber: 7 },
    1537: { patronName: 'Evaluador', patronNumber: 10 },
    1536: { patronName: 'Evaluador', patronNumber: 10 },
    1535: { patronName: 'Evaluador', patronNumber: 10 },
    1534: { patronName: 'Promotor', patronNumber: 7 },
    1533: { patronName: 'Promotor', patronNumber: 7 },
    1532: { patronName: 'Promotor', patronNumber: 7 },
    1531: { patronName: 'Promotor', patronNumber: 7 },
    1527: { patronName: 'Evaluador', patronNumber: 10 },
    1526: { patronName: 'Evaluador', patronNumber: 10 },
    1525: { patronName: 'Evaluador', patronNumber: 10 },
    1524: { patronName: 'Promotor', patronNumber: 7 },
    1523: { patronName: 'Promotor', patronNumber: 7 },
    1522: { patronName: 'Promotor', patronNumber: 7 },
    1521: { patronName: 'Promotor', patronNumber: 7 },
    1517: { patronName: 'Evaluador', patronNumber: 10 },
    1516: { patronName: 'Evaluador', patronNumber: 10 },
    1515: { patronName: 'Evaluador', patronNumber: 10 },
    1514: { patronName: 'Promotor', patronNumber: 7 },
    1513: { patronName: 'Promotor', patronNumber: 7 },
    1512: { patronName: 'Promotor', patronNumber: 7 },
    1511: { patronName: 'Promotor', patronNumber: 7 },
    1477: { patronName: 'Perfeccionista', patronNumber: 3 },
    1476: { patronName: 'Perfeccionista', patronNumber: 3 },
    1475: { patronName: 'Perfeccionista', patronNumber: 3 },
    1474: { patronName: 'Especialista', patronNumber: 15 },
    1473: { patronName: 'Especialista', patronNumber: 15 },
    1472: { patronName: 'Especialista', patronNumber: 15 },
    1471: { patronName: 'Especialista', patronNumber: 15 },
    1467: { patronName: 'Perfeccionista', patronNumber: 3 },
    1466: { patronName: 'Perfeccionista', patronNumber: 3 },
    1465: { patronName: 'Perfeccionista', patronNumber: 3 },
    1464: { patronName: 'Especialista', patronNumber: 15 },
    1463: { patronName: 'Especialista', patronNumber: 15 },
    1462: { patronName: 'Especialista', patronNumber: 15 },
    1461: { patronName: 'Especialista', patronNumber: 15 },
    1457: { patronName: 'Perfeccionista', patronNumber: 3 },
    1456: { patronName: 'Perfeccionista', patronNumber: 3 },
    1455: { patronName: 'Perfeccionista', patronNumber: 3 },
    1454: { patronName: 'Especialista', patronNumber: 15 },
    1453: { patronName: 'Especialista', patronNumber: 15 },
    1452: { patronName: 'Especialista', patronNumber: 15 },
    1451: { patronName: 'Especialista', patronNumber: 15 },
    1447: { patronName: 'Objetivo', patronNumber: 5 },
    1446: { patronName: 'Objetivo', patronNumber: 5 },
    1445: { patronName: 'Objetivo', patronNumber: 5 },
    1444: { patronName: 'Subactivo', patronNumber: 18 },
    1443: { patronName: 'Subactivo', patronNumber: 18 },
    1442: { patronName: 'Subactivo', patronNumber: 18 },
    1441: { patronName: 'Subactivo', patronNumber: 18 },
    1437: { patronName: 'Objetivo', patronNumber: 5 },
    1436: { patronName: 'Objetivo', patronNumber: 5 },
    1435: { patronName: 'Objetivo', patronNumber: 5 },
    1434: { patronName: 'Subactivo', patronNumber: 18 },
    1433: { patronName: 'Subactivo', patronNumber: 18 },
    1432: { patronName: 'Subactivo', patronNumber: 18 },
    1431: { patronName: 'Subactivo', patronNumber: 18 },
    1427: { patronName: 'Objetivo', patronNumber: 5 },
    1426: { patronName: 'Objetivo', patronNumber: 5 },
    1425: { patronName: 'Objetivo', patronNumber: 5 },
    1424: { patronName: 'Subactivo', patronNumber: 18 },
    1423: { patronName: 'Subactivo', patronNumber: 18 },
    1422: { patronName: 'Subactivo', patronNumber: 18 },
    1421: { patronName: 'Subactivo', patronNumber: 18 },
    1417: { patronName: 'Objetivo', patronNumber: 5 },
    1416: { patronName: 'Objetivo', patronNumber: 5 },
    1415: { patronName: 'Objetivo', patronNumber: 5 },
    1414: { patronName: 'Subactivo', patronNumber: 18 },
    1413: { patronName: 'Subactivo', patronNumber: 18 },
    1412: { patronName: 'Subactivo', patronNumber: 18 },
    1411: { patronName: 'Subactivo', patronNumber: 18 },
    1377: { patronName: 'Perfeccionista', patronNumber: 3 },
    1376: { patronName: 'Perfeccionista', patronNumber: 3 },
    1375: { patronName: 'Perfeccionista', patronNumber: 3 },
    1374: { patronName: 'Especialista', patronNumber: 15 },
    1373: { patronName: 'Especialista', patronNumber: 15 },
    1372: { patronName: 'Especialista', patronNumber: 15 },
    1371: { patronName: 'Especialista', patronNumber: 15 },
    1367: { patronName: 'Perfeccionista', patronNumber: 3 },
    1366: { patronName: 'Perfeccionista', patronNumber: 3 },
    1365: { patronName: 'Perfeccionista', patronNumber: 3 },
    1364: { patronName: 'Especialista', patronNumber: 15 },
    1363: { patronName: 'Especialista', patronNumber: 15 },
    1362: { patronName: 'Especialista', patronNumber: 15 },
    1361: { patronName: 'Especialista', patronNumber: 15 },
    1357: { patronName: 'Perfeccionista', patronNumber: 3 },
    1356: { patronName: 'Perfeccionista', patronNumber: 3 },
    1355: { patronName: 'Perfeccionista', patronNumber: 3 },
    1354: { patronName: 'Especialista', patronNumber: 15 },
    1353: { patronName: 'Especialista', patronNumber: 15 },
    1352: { patronName: 'Especialista', patronNumber: 15 },
    1351: { patronName: 'Especialista', patronNumber: 15 },
    1347: { patronName: 'Objetivo', patronNumber: 5 },
    1346: { patronName: 'Objetivo', patronNumber: 5 },
    1345: { patronName: 'Objetivo', patronNumber: 5 },
    1344: { patronName: 'Subactivo', patronNumber: 18 },
    1343: { patronName: 'Subactivo', patronNumber: 18 },
    1342: { patronName: 'Subactivo', patronNumber: 18 },
    1341: { patronName: 'Subactivo', patronNumber: 18 },
    1337: { patronName: 'Objetivo', patronNumber: 5 },
    1336: { patronName: 'Objetivo', patronNumber: 5 },
    1335: { patronName: 'Objetivo', patronNumber: 5 },
    1334: { patronName: 'Subactivo', patronNumber: 18 },
    1333: { patronName: 'Subactivo', patronNumber: 18 },
    1332: { patronName: 'Subactivo', patronNumber: 18 },
    1331: { patronName: 'Subactivo', patronNumber: 18 },
    1327: { patronName: 'Objetivo', patronNumber: 5 },
    1326: { patronName: 'Objetivo', patronNumber: 5 },
    1325: { patronName: 'Objetivo', patronNumber: 5 },
    1324: { patronName: 'Subactivo', patronNumber: 18 },
    1323: { patronName: 'Subactivo', patronNumber: 18 },
    1322: { patronName: 'Subactivo', patronNumber: 18 },
    1321: { patronName: 'Subactivo', patronNumber: 18 },
    1317: { patronName: 'Objetivo', patronNumber: 5 },
    1316: { patronName: 'Objetivo', patronNumber: 5 },
    1315: { patronName: 'Objetivo', patronNumber: 5 },
    1314: { patronName: 'Subactivo', patronNumber: 18 },
    1313: { patronName: 'Subactivo', patronNumber: 18 },
    1312: { patronName: 'Subactivo', patronNumber: 18 },
    1311: { patronName: 'Subactivo', patronNumber: 18 },
    1277: { patronName: 'Perfeccionista', patronNumber: 3 },
    1276: { patronName: 'Perfeccionista', patronNumber: 3 },
    1275: { patronName: 'Perfeccionista', patronNumber: 3 },
    1274: { patronName: 'Especialista', patronNumber: 15 },
    1273: { patronName: 'Especialista', patronNumber: 15 },
    1272: { patronName: 'Especialista', patronNumber: 15 },
    1271: { patronName: 'Especialista', patronNumber: 15 },
    1267: { patronName: 'Perfeccionista', patronNumber: 3 },
    1266: { patronName: 'Perfeccionista', patronNumber: 3 },
    1265: { patronName: 'Perfeccionista', patronNumber: 3 },
    1264: { patronName: 'Especialista', patronNumber: 15 },
    1263: { patronName: 'Especialista', patronNumber: 15 },
    1262: { patronName: 'Especialista', patronNumber: 15 },
    1261: { patronName: 'Especialista', patronNumber: 15 },
    1257: { patronName: 'Perfeccionista', patronNumber: 3 },
    1256: { patronName: 'Perfeccionista', patronNumber: 3 },
    1255: { patronName: 'Perfeccionista', patronNumber: 3 },
    1254: { patronName: 'Especialista', patronNumber: 15 },
    1253: { patronName: 'Especialista', patronNumber: 15 },
    1252: { patronName: 'Especialista', patronNumber: 15 },
    1251: { patronName: 'Especialista', patronNumber: 15 },
    1247: { patronName: 'Objetivo', patronNumber: 5 },
    1246: { patronName: 'Objetivo', patronNumber: 5 },
    1245: { patronName: 'Objetivo', patronNumber: 5 },
    1244: { patronName: 'Subactivo', patronNumber: 18 },
    1243: { patronName: 'Subactivo', patronNumber: 18 },
    1242: { patronName: 'Subactivo', patronNumber: 18 },
    1241: { patronName: 'Subactivo', patronNumber: 18 },
    1237: { patronName: 'Objetivo', patronNumber: 5 },
    1236: { patronName: 'Objetivo', patronNumber: 5 },
    1235: { patronName: 'Objetivo', patronNumber: 5 },
    1234: { patronName: 'Subactivo', patronNumber: 18 },
    1233: { patronName: 'Subactivo', patronNumber: 18 },
    1232: { patronName: 'Subactivo', patronNumber: 18 },
    1231: { patronName: 'Subactivo', patronNumber: 18 },
    1227: { patronName: 'Objetivo', patronNumber: 5 },
    1226: { patronName: 'Objetivo', patronNumber: 5 },
    1225: { patronName: 'Objetivo', patronNumber: 5 },
    1224: { patronName: 'Subactivo', patronNumber: 18 },
    1223: { patronName: 'Subactivo', patronNumber: 18 },
    1222: { patronName: 'Subactivo', patronNumber: 18 },
    1221: { patronName: 'Subactivo', patronNumber: 18 },
    1217: { patronName: 'Objetivo', patronNumber: 5 },
    1216: { patronName: 'Objetivo', patronNumber: 5 },
    1215: { patronName: 'Objetivo', patronNumber: 5 },
    1214: { patronName: 'Subactivo', patronNumber: 18 },
    1213: { patronName: 'Subactivo', patronNumber: 18 },
    1212: { patronName: 'Subactivo', patronNumber: 18 },
    1211: { patronName: 'Subactivo', patronNumber: 18 },
    1177: { patronName: 'Perfeccionista', patronNumber: 3 },
    1176: { patronName: 'Perfeccionista', patronNumber: 3 },
    1175: { patronName: 'Perfeccionista', patronNumber: 3 },
    1174: { patronName: 'Especialista', patronNumber: 15 },
    1173: { patronName: 'Especialista', patronNumber: 15 },
    1172: { patronName: 'Especialista', patronNumber: 15 },
    1171: { patronName: 'Especialista', patronNumber: 15 },
    1167: { patronName: 'Perfeccionista', patronNumber: 3 },
    1166: { patronName: 'Perfeccionista', patronNumber: 3 },
    1165: { patronName: 'Perfeccionista', patronNumber: 3 },
    1164: { patronName: 'Especialista', patronNumber: 15 },
    1163: { patronName: 'Especialista', patronNumber: 15 },
    1162: { patronName: 'Especialista', patronNumber: 15 },
    1161: { patronName: 'Especialista', patronNumber: 15 },
    1157: { patronName: 'Perfeccionista', patronNumber: 3 },
    1156: { patronName: 'Perfeccionista', patronNumber: 3 },
    1155: { patronName: 'Perfeccionista', patronNumber: 3 },
    1154: { patronName: 'Especialista', patronNumber: 15 },
    1153: { patronName: 'Especialista', patronNumber: 15 },
    1152: { patronName: 'Especialista', patronNumber: 15 },
    1151: { patronName: 'Especialista', patronNumber: 15 },
    1147: { patronName: 'Objetivo', patronNumber: 5 },
    1146: { patronName: 'Objetivo', patronNumber: 5 },
    1145: { patronName: 'Objetivo', patronNumber: 5 },
    1144: { patronName: 'Subactivo', patronNumber: 18 },
    1143: { patronName: 'Subactivo', patronNumber: 18 },
    1142: { patronName: 'Subactivo', patronNumber: 18 },
    1141: { patronName: 'Subactivo', patronNumber: 18 },
    1137: { patronName: 'Objetivo', patronNumber: 5 },
    1136: { patronName: 'Objetivo', patronNumber: 5 },
    1135: { patronName: 'Objetivo', patronNumber: 5 },
    1134: { patronName: 'Subactivo', patronNumber: 18 },
    1133: { patronName: 'Subactivo', patronNumber: 18 },
    1132: { patronName: 'Subactivo', patronNumber: 18 },
    1131: { patronName: 'Subactivo', patronNumber: 18 },
    1127: { patronName: 'Objetivo', patronNumber: 5 },
    1126: { patronName: 'Objetivo', patronNumber: 5 },
    1125: { patronName: 'Objetivo', patronNumber: 5 },
    1124: { patronName: 'Subactivo', patronNumber: 18 },
    1123: { patronName: 'Subactivo', patronNumber: 18 },
    1122: { patronName: 'Subactivo', patronNumber: 18 },
    1121: { patronName: 'Subactivo', patronNumber: 18 },
    1117: { patronName: 'Objetivo', patronNumber: 5 },
    1116: { patronName: 'Objetivo', patronNumber: 5 },
    1115: { patronName: 'Objetivo', patronNumber: 5 },
    1114: { patronName: 'Subactivo', patronNumber: 18 },
    1113: { patronName: 'Subactivo', patronNumber: 18 },
    1112: { patronName: 'Subactivo', patronNumber: 18 },
    1111: { patronName: 'Subactivo', patronNumber: 18 },
};

const discInterpretation = {
    E1: 'Acepta la agresión, tiende a aparentar dar poca importancia a la necesidad que tiene de afecto.',
    M1: 'Controlar su ambiente o a su público.',
    J1: 'La forma en que proyecta su fuerza personal, carácter  y posición social.',
    I1: 'Su encanto, dirección, intimidación , uso de recompensas.',
    S1: 'Mueve a la gente, inicia, ordena, felicita disciplina.',
    A1: 'Su enfoque de que “el fin justifica los medios”.',
    B1: 'Se vuelve manipulador, pendenciero, beligerante.',
    T1: 'Ser demasiado blando, perder su posición social.',
    SE1: 'Fuera más genuina su sensibilidad; estuviera más dispuesto a ayudar a otros a tener éxito en su propio desarrollo personal.',
    O1: 'Las personas con patrón alentador saben con exactitud los resultados que quieren, pero no siempre los verbalizan de inmediato.  Manifiestan cuáles son los resultados que quieren sólo después de que se haya creado un ambiente apropiado y la otra persona está dispuesta a aceptarlos.  Por ejemplo, estas personas ofrecen amistad a quienes desean ser aceptados, más autoridad a quienes buscan poder y seguridad a quienes buscan un ambiente predecible.',
    O12: 'El alentador pude ser encantador en su trato con los demás.  Es persuasivo para obtener ayuda cuando se le presentan detalles repetitivos y que consumen mucho tiempo.  Sin embargo, las personas a menudo experimentan ante ellos una sensación de conflicto,  al sentirse por un lado atraídos, y curiosamente al mismo tiempo distanciados.  Otras pueden sentirse “utilizadas”.  Aunque algunas veces el alentador inspira temor en los demás y rechaza sus decisiones, el Alentador suele ser apreciado por sus colaboradores.  Esto lo consigue al usar siempre que le es posible su enorme capacidad de palabra para persuadir.  El Alentador prefiere alcanzar sus objetivos no dominando a las personas sino haciendo de agente para realizar el trabajo.',
    O13: '',
    E2: 'Activo, diligente, muestra frustración.',
    M2: 'Logros personales, en ocasiones a expensas de la meta de grupo.',
    J2: 'El logro de resultados concretos.',
    I2: 'La aceptación de responsabilidad por su propio trabajo.',
    S2: 'Se propone y consigue resultados en áreas clave.',
    A2: 'Confianza en si mismo, absorción en el trabajo.',
    B2: 'Se frustra e impacienta con los demás, se convierte en una persona que “lo hace todo”  en vez de ser alguien que delega.',
    T2: 'A quienes tienen niveles inferiores o competitivos de trabajo, que afectan los resultados.',
    SE2: 'Dejara de pensar en “esto o lo otro”, estableciera su prioridades con mayor claridad y aceptara enfoques alternativos, estuviera dispuesto a sacrificar los beneficios a corto plazo por otros a largo plazo.',
    O21: 'La motivación del Patrón Realizador surge en gran parte de su interior y de metas personales muy profundas.  Este compromiso previo con sus propias metas impide que acepte automáticamente las metas del grupo.  El Realizador necesita combinar sus metas personales con las metas de la organización.  Como el Realizador siempre ha ejercido control sobre los aspectos más importantes de su vida, desarrolla a menudo un fuerte sentido de la responsabilidad',
    O22: 'El Realizador demuestra un profundo interés por su trabajo y un continuo e intenso afán por conseguir lo que se propone.   Tiene una alta opinión de su trabajo y suele realizar las cosas por él mismo para asegurarse de que todo esté bien hecho.  Valora el trabajo arduo y bajo presión “prefiere hacer” que delegar en otro.   Cuando delega algo, suele volver ha realizarlo si no satisface sus expectativas.  Su premisa dice: “si tengo éxito, el mérito me corresponde, pero si fracaso, asumo la responsabilidad”',
    O23: 'Si el Realizador se comunica más con los demás dejaría de pensar en “esto o lo otro”, del “yo mismo lo tengo que hacer” o “quiero todo el crédito para mí”.  Tal vez necesite ayuda para considerar otras propuestas y conseguir los resultados que desea.  El Realizador sabe que funciona al máximo de su capacidad y espera un reconocimiento similar a su contribución, en ciertas organizaciones mediante ganancias elevadas y en otras con posiciones de mando',
    E3: 'Competente para hacer bien las cosas, reservado, cauteloso.',
    M3: 'Logros estables, predecible.',
    J3: 'Normas precisas.',
    I3: 'La atención al detalle y precisión.',
    S3: 'Concienzudo, conserva las normas, control de calidad.',
    A3: 'Los procedimientos y controles excesivos para evitar las fallas, depende demasiado de la gente, productos y procesos que le funcionaron en el pasado.',
    B3: 'Es discreto, diplomático.',
    T3: 'El antagonismo.',
    SE3: 'Fuera más flexible en su papel, fuera más independiente e interdependiente, tuviera más fe en sí mismo y si se viera a sí mismo como una persona valiosa.',
    O31: 'El Perfeccionista es metódico y preciso en su forma de pensar y trabajar, por lo que suele seguir procedimientos ordenados tanto en su vida personal como laboral.   Es extremadamente concienzudo y se esmera en el trabajo detallado y preciso.   El Perfeccionista desea condiciones estables y actividades fáciles de predecir, por lo que se siente cómodo en un ambiente laboral claramente definido.  Desea claridad respecto a lo que se espera de él en el trabajo, de cuánto tiempo dispone y cómo se va a evaluar su trabajo',
    O32: 'El perfeccionista se puede empantanar en los detalles cuando tiene que tomar decisiones.  Sabe tomar decisiones importantes, pero se le puede criticar por el tiempo que le toma reunir y analizar la información antes de decidir.  Aunque le agrada conocer la opinión de sus superiores, el Perfeccionista es capaz de arriesgarse  cuando cuenta con datos que puede interpretar y usar para sacar conclusiones propias',
    O33: 'El Perfeccionista se evalúa y evalúa a los demás bajo normas precisas que aseguren resultados concretos y se adhiere a procedimientos operativos normales. Para la organización es valiosa esta atención concienzuda a las normas y calidad, sin embargo, el Perfeccionista tiende a definir su valor más por lo que hace que por lo que es como persona.  Por lo tanto, suele reaccionar a los cumplidos personales con la idea de que: “¿Qué querrá esta persona?” , si aceptará un cumplido sincero por quien es, podría aumentar su confianza en sí mismo',
    E4: 'Acepta la agresión, puede contenerse al expresarse.',
    M4: 'Dominar, logros únicos.',
    J4: 'Sus propias normas, las ideas progresivas al llevar a cabo el trabajo.',
    I4: 'El establecimiento de un ritmo a seguir para desarrollar sistemas y enfoques innovadores.',
    S4: 'El iniciar o diseñar cambios.',
    A4: 'La brusquedad, la actitud crítica o condescendiente.',
    B4: 'Se aburre fácilmente con el trabajo rutinario, cuando se le restringe se torna malhumorado, es independiente.',
    T4: 'No poder influir, no alcanzar el nivel establecido.',
    SE4: 'Fuera más amable, usara más tacto al comunicarse, cooperara más con el equipo, reconociera que existen sanciones.',
    O41: 'Las personas con un Patrón Creativo muestran dos fuerzas opuestas en su comportamiento.   El deseo de resultados tangibles se contrapone a un impulso de igual magnitud por la perfección.  Su agresividad se templa con su sensibilidad.  La rapidez de pensamiento y tiempo de reacción se ven frenados por el deseo de explorar todas las soluciones posibles antes de tomar una decisión',
    O42: 'Las personas creativas preveen de manera extraordinaria el enfoque que hay que dar a un proyecto y efectúan los cambios oportunos.  En vista de que las personas con un Patrón Creativo son perfeccionistas y cuentan con una gran habilidad para planear, los cambios que efectúan suelen ser apropiados, aunque  les pueda faltar atención a las relaciones interpersonales',
    O43: 'La persona creativa desea libertad para explorar y la autoridad para examinar y verificar los resultados.  Puede tomar las decisiones diarias con rapidez,  pero puede ser extremadamente cauteloso al tomar decisiones de verdadera importancia.  “¿Debería aceptar este ascenso?”, “¿debería mudarme a otro sitio?”.   Por su necesidad de obtener resultados y perfección, la persona creativa no se preocupa mucho por las formas sociales.  Puede parecer fría, ajena y brusca',
    E5: 'Puede rechazar la agresión interpersonal.',
    M5: 'La exactitud.',
    J5: 'Su capacidad de pensamiento analítico. ',
    I5: 'La información objetiva, los argumentos lógicos.',
    S5: 'Define,  esclarece, obtiene información, evalúa, comprueba.',
    A5: 'El análisis.',
    B5: 'Se vuelve aprensivo.',
    T5: 'Actos irracionales, el ridículo.',
    SE5: 'Fuera más abierto, compartiera en público su perspicacia y opiniones.',
    O51: 'La capacidad de pensamiento crítico suele estar muy desarrollada en el Objetivo.  Recalca la importancia de sacar conclusiones y basar las acciones en hechos.  Busca la precisión y exactitud en todo lo que hace.  Sin embargo, para llevar a cabo con eficiencia su trabajo, el Objetivo suele combinar la información intuitiva con los datos que posee.  Cuando duda sobre el curso a tomar, evita hacer el ridículo preparándose meticulosamente.  Por ejemplo, el Objetivo perfeccionará una nueva habilidad en privado antes de usarla en alguna actividad de grupo.',
    O52: 'El Objetivo prefiere trabajar con personas que , como él, prefieren mantener un ambiente laboral tranquilo.  Como puede mostrarse reticente en expresar sus sentimiento, hay quienes lo consideran tímido.  Se siente particularmente incómodo ante personas agresivas.  A pesar de esta apariencia templada, el Objetivo tiene un fuerte necesidad de controlar el ambiente.  Suele ejercer este control en forma indirecta solicitando el apego a reglas y normas.',
    O53: 'El Objetivo se preocupa por llegar a respuestas “correctas” y le puede resultar difícil tomar decisiones en situaciones ambiguas.  Su tendencia a preocuparse le puede llevar a una “parálisis por análisis”.  Con demasiada frecuencia, cuando comete un error, titubea en reconocerlo y se empreña en buscar información que le permita apoyar su postura.',
    E6: 'Confía en los demás es entusiasta.',
    M6: 'Autoridad y prestigio; diversos símbolos de prestigio.',
    J6: 'Su capacidad de expresión verbal; su flexibilidad.',
    I6: 'Un comportamiento amistoso; franqueza; habilidad en su expresión verbal.',
    S6: 'Sabe vender y cerrar tratos; delega responsabilidades; sereno, seguridad en sí mismo.',
    A6: 'Su entusiasmo; su habilidad para vender; su optimismo.',
    B6: 'Es discreto, diplomático.',
    T6: 'Un ambiente inalterable; relaciones complejas.',
    SE6: 'Se le asignaran tareas que le impliquen un reto; prestara más atención al servicio y detalles elementales clave para el trabajo; hiciera un análisis objetivo de la información.',
    O61: 'El persuasivo trabaja con y a través de otros.  Esto es, se esfuerza por hacer negocios en forma amistosa al mismo tiempo que pugna por alcanzar sus propios objetivos.  El Persuasivo, al ser franco por naturaleza y mostrar interés por las personas, se gana el respeto y confianza de diversos tipos de personas.   El Persuasivo tiene la capacidad de convencer a los demás de su punto de vista, no sólo los conquista, también los retiene como clientes o amigos.  Esta habilidad les es particularmente útil para obtener puestos de autoridad al venderse a sí mismos y sus ideas.',
    O62: 'El trabajo con gente, las tareas que le suponen  un reto y la variedad de trabajos y actividades que impliquen movilidad , proporcionan un ambiente favorable para el Persuasivo.  Además, suele buscar tareas laborales que le proporcionen oportunidades de quedar bien.  Como resultado de su entusiasmo natural, el persuasivo tiende a ser demasiado optimista respecto a los resultados de los proyectos y el potencial de otras personas.  El Persuasivo también suele sobreestimar su capacidad de cambiar el comportamiento de los demás.',
    O63: 'Al mismo tiempo que rechaza las rutinas y reglamentos, el Persuasivo necesita que se le proporcione información analítica de manera sistemática y periódica.  Cuando se le hace ver la importancia de los “pequeños detalles”, la información adecuada les ayuda a equilibrar su entusiasmo con una evaluación realista de la situación',
    E7: 'Dispuesto a aceptar a los demás.',
    M7: 'Aprobación, popularidad.',
    J7: 'Su forma de expresarse.',
    I7: 'Alabanzas, oportunidades, haciendo favores.',
    S7: 'Alivia tensiones; promueve proyectos y personas, incluso a sí mismo.',
    A7: 'Los elogios, optimismo.',
    B7: 'Descuidado y sentimental; actúa en forma desorganizada; no sabe cómo llevar a cabo las cosas.',
    T7: 'Perder aceptación social y su autoestima.',
    SE7: 'Tuviera más control del tiempo; fuera más objetivo; fuera más sensible a lo que significa “urgente”, controlara sus emociones; cumpliera hasta el final sus promesas, tareas.',
    O71: 'El promotor cuenta con una extensa red de contactos que le proporciona una base activa para realizar sus negocios.  Gregario y sociable, le es fácil hacer amigos.  Rara vez se opone intencionalmente a alguien.  El promotor busca ambientes socialmente favorables donde pueda continuar desarrollando y conservando sus contactos.  Con su excelente capacidad de palabra, promueve muy bien sus propias ideas y genera entusiasmo hacia proyectos ajenos.  Gracias  a su amplia esfera de contactos, el Promotor tiene acceso a las personas apropiadas cuando necesita ayuda.',
    O72: 'En vista de que el promotor prefiere por naturaleza la interacción con otros y participa en actividades que implican contacto con gente, se interesa menos en la realización del trabajo.  Aunque su trabajo imponga actividades solitarias, seguirá buscando situaciones que impliquen reuniones y vida social activa.  Le agrada participar en reuniones, comités y conferencias.',
    O73: 'Por su optimismo natural, el Promotor tiende a sobreestimar la capacidad de los demás.  Suele llegar a conclusiones favorables sin haber considerado todos los hechos.  Con entrenamiento y dirección adecuados se puede ayudar al Promotor a desarrollar objetividad y a dar la importancia debida a los resultados.  Planear y controlar el tiempo le puede significar un reto.  Le conviene limitar el tiempo dedicado a conversar y de esta manera recordarse a sí mismo la urgencia de “concluir” y llevar a término una tarea.',
    E8: 'Es fácil de abordar, afectuoso y comprensivo.',
    M8: 'La amistad; la felicidad.',
    J8: 'Su aceptación positiva; generalmente busca el lado bueno de las personas.',
    I8: 'Las relaciones personales, al practicar la política de “puertas abiertas”.',
    S8: 'Estable, predecible; una amplia esfera de amistades; sabe escuchar.',
    A8: 'Acercamiento indirecto, tolerancia.',
    B8: 'Se torna demasiado flexible e íntimo; confía demasiado en todos sin distinción.',
    T8: 'Presionar a los demás; que se le acuse de hacer daño.',
    SE8: 'Presenta más atención a las fechas límite; tuviera más iniciativa para realizar el trabajo.',
    O81: 'El Consejero tiene  el don particular de resolver los problemas de los demás.  Impresiona con su afecto, empatía y comprensión.  Al Consejero le es fácil encontrar lo bueno en las personas y asume una actitud optimista.  El consejero prefiere tratar con la gente sobre la base de una relación íntima.  Al saber escuchar, en especial a los problemas, es discreto en sus sugerencias y no trata de imponer sus ideas a los demás.',
    O82: 'El Consejero suele ser en extremo tolerante y paciente con las personas que no rinden en el trabajo.  Bajo presión, se le dificulta confrontar los problemas de desempeño en forma directa.  Suele ser demasiado indirecto para ordenar,  exigir o disciplinar a otros.  Con su actitud de que la “gente es importante”, el Consejero suele dar menos importancia al rendimiento.  En ocasiones requiere ayuda para fijar y cumplir fechas límites realistas.',
    O83: 'A menudo, el Consejero toma la crítica como una afrenta personal, pero responde en forma positiva si recibe atención y cumplidos por un trabajo bien hecho.  Cuando tiene un puesto de responsabilidad, suele prestar atención a la calidad de las condiciones de trabajo y proporcionar reconocimiento adecuado a los miembros de su equipo.',
    E9: 'Acepta el afecto; rechaza la agresión.',
    M9: 'Ser aceptado por los demás.',
    J9: 'La tolerancia y participación.',
    I9: 'La Comprensión; amistad.',
    S9: 'Apoya; armoniza; proyecta empatía; está orientado al servicio.',
    A9: 'La amabilidad.',
    B9: 'Se vuelve persuasivo haciendo, si fuese necesario, uso de información que posee o de amistades clave.',
    T9: 'El desacuerdo, el conflicto.',
    SE9: 'Tuviera más conciencia de quién es y de lo que puede hacer;  mostrara más firmeza y agresividad; dijera “no” en los momentos adecuados.',
    O91: 'Al Agente le interesa tanto las relaciones humanas como los variados  aspectos del trabajo.   Gracias a su empatía y tolerancia sabe escuchar y se le conoce por su buena disposición.   El Agente hace que los demás sientan que se les quiere y necesita.   No hay quien tema ser rechazado por un Agente.  Es más, el agente ofrece amistad y está dispuesto a ayudar.',
    O92: 'En cuanto al trabajo, el Agente cuenta con un excelente potencial para la organización y eficiente ejecución.  Es excelente en hacer para otros lo que ellos encuentran difícil de realizar.  El Agente busca por naturaleza la armonía y cooperación en el grupo.',
    O93: 'Sin embargo, el Agente teme el conflicto y desacuerdo.  Su tendencia a ayudar puede instar a otros a tolerar una situación en lugar de buscar una solución del problema.   Además,  la tendencia del Agente a adoptar un perfil “bajo” en lugar de aceptar una confrontación franca con personas agresivas, lo que puede ser visto como una falta de “dureza”.   A pesar de todo, el Agente cuenta con un buen nivel de independencia aunque le preocupa su integración en el grupo.',
    E10: 'Un fuerte impulso por causar buena impresión.',
    M10: '“Ganar” con estilo.',
    J10: 'Su capacidad de tomar iniciativa.',
    I10: 'Influye en los demás al:  Hacerles competir por su reconocimiento.',
    S10: 'Obtiene sus metas a través de los demás.',
    A10: 'Su autoridad e ingenio.',
    B10: 'Se torna intranquilo; crítico;  impaciente.',
    T10: '“Perder”; quedar mal ante los demás.',
    SE10: 'Llevara a cabo el seguimiento hasta el final; mostrara empatía al estar en desacuerdo; se marcara un ritmo más realista para sus actividades.',
    O101: 'El  Evaluador toma las ideas creativas y las utiliza para fines prácticos.   Es competitivo y usa métodos directos para conseguir resultados.   Sin embargo, hay quienes consideran al Evaluador menos agresivo ya que suele mostrar consideración hacia los demás.   En lugar de ordenar o mandar, el Evaluador involucra a las personas en el trabajo usando métodos persuasivos.  Obtiene la cooperación de quienes le rodean al explicar la lógica de las actividades propuestas.',
    O102: 'El Evaluador suele ser capaz de ayudar a los demás a visualizar los pasos necesarios para lograr resultados.  Por lo general, habla de un plan de acción detallado que él mismo desarrollará  para asegurar una progresión ordenada hacia los resultados.  Sin embargo, en su afán de ganar, el Evaluador se puede impacientar cuando no se mantiene a los niveles establecidos o cuando se requiere mucho seguimiento.',
    O103: 'El Evaluador tiene un pensamiento bastante analítico y es hábil para expresar en palabras sus críticas.   Sus palabras pueden ser bastante hirientes.   El Evaluador controla mejor la situación si se relaja y disminuye su ritmo de trabajo.  Un axioma que le sería útil para lograrlo es:  “algunas veces se gana y otras se pierde”.',
    E11: 'Individualista en lo que se refiere a sus necesidades personales.',
    M11: 'Una nueva oportunidad; un nuevo reto.',
    J11: 'Su capacidad para alcanzar las normas establecidas por él mismo.',
    I11: 'Las soluciones a los problemas; al proyectar una imagen de poder.',
    S11: 'Acepta la responsabilidad, no dice “no es mi culpa”; ofrece formas  nuevas e innovadoras de resolver problemas.',
    A11: 'Del control que ejerce sobre los demás en su afán de alcanzar sus propios  resultados.',
    B11: 'Se aparta cuando se tienen que hacer las cosas; se torna beligerante cuando ve su individualidad amenazada o se le cierran las puertas al reto.',
    T11: 'Al aburrimiento;  a la pérdida del control.',
    SE11: 'Mostrara más paciencia, empatía;  participara y colaborara con los demás;  diera más seguimiento y atención a la importancia del control de calidad.',
    O111: 'El Resolutivo suele ser una persona fuertemente individualista que busca continuamente nuevos horizontes.  Como es extremadamente autosuficiente e independiente de pensamiento y acción, prefiere encontrar sus propias soluciones.   Relativamente libre de la influencia restrictiva del grupo, el Resolutivo es capaz de eludir los convencionalismos y suele aportar soluciones innovadoras.',
    O112: 'Aunque con bastante frecuencia tiende a ser directo y enérgico, el Resolutivo es asimismo astuto para manipular personas y situaciones.  Sin embargo, cuando se requiere que el Resolutivo coopere con otros en situaciones que limitan su individualidad, el Resolutivo pude tornarse beligerante.  Es sumamente persistente para conseguir los resultados que desea, y hace todo lo que está en sus manos para vencer los obstáculos que se le presentan.  Además, sus expectativas respecto a los demás son altas y puede ser muy crítico cuando no se cumplen sus normas.',
    O113: 'Al Resolutivo le interesa mucho alcanzar sus propias metas, así como tener oportunidades de progreso y retos.   Como su empeño se enfoca tanto en el resultado final, suele carecer de empatía y parecer indiferente a las personas.  Podría decir algo como:  “tómate una aspirina, yo estoy igual” o “no seas niño, ya se te pasará”.',
    E12: 'Quiere mantenerse a la altura de los demás en cuanto a esfuerzo y desempeño técnico.',
    M12: 'Profundo afán por el desarrollo personal.',
    J12: 'Su autodisciplina;  sus posiciones y ascensos.',
    I12: 'La confianza en su habilidad para perfeccionar nuevos conocimientos; al desarrollar y seguir procedimientos y acciones “correctos”.',
    S12: 'Hábil para resolver problemas técnicos y humanos;  profesionalismo en su especialidad.',
    A12: 'Una atención excesiva a objetivos personales;  expectativas poco realistas sobre los demás.',
    B12: 'Se cohibe; sensible a la crítica.',
    T12: 'Ser demasiado predecible;  que no se le reconozca como “experto”.',
    SE12: 'Colaborara en forma genuina para beneficio general; delegara tareas importantes a las personas apropiadas.',
    O121: 'El profesional valora la destreza en áreas especializadas.  Su enorme deseo de “destacar en algo”, lo lleva a un esmerado control de su propio desempeño en el trabajo.  Aunque su meta es ser “el” experto en un área determinada, el Profesional da la impresión de saber un poco de todo.  Esta imagen es más marcada cuando pone en palabras el conocimiento que posee sobre diversos temas.',
    O122: 'En su relación con otros, el Profesional suele proyectar un estilo relajado, diplomático y afable.  Esta actitud puede cambiar de súbito en su área de especialización cuando se concentra demasiado en alcanzar altos niveles de rendimiento.  Al valorar la autodisciplina, el Profesional evalúa a los demás sobre la base de su autodisciplina, la que mide por su rendimiento diario.  Sus expectativas en relación consigo mismo y con los demás son elevadas.  Suele exteriorizar su desilusión.',
    O123: 'Al mismo tiempo que su naturaleza le pide concentrarse en desarrollar una propuesta organizada del trabajo y en aumentar sus propias capacidades,   El Profesional necesita asimismo ayudar a otros a perfeccionar sus talentos.  Además, necesita saber apreciar mejor a quienes contribuyen en el esfuerzo del trabajo, aunque no usen lo que el Profesional considera el “método correcto”.',
    E13: 'Desapasionado; autodisciplinado.',
    M13: 'El poder que generan la autoridad, la posición y los roles formales.',
    J13: 'El uso de la información objetiva.',
    I13: 'Su determinación; su tenacidad.',
    S13: 'Seguimiento concienzudo para realizar su trabajo en forma constante y persistente sea individual o en grupos pequeños.',
    A13: 'La franqueza; su desconfianza hacia los demás.',
    B13: 'Tiende a interiorizar los conflictos; recuerda el mal que se le ha hecho.',
    T13: 'Involucrarse con las masas; vender ideas abstractas.',
    SE13: 'Fuera más flexible; aceptara a los demás; si participara más con los demás.',
    O131: 'Objetivo y analítico, el investigador, está “enclavado en la realidad”.  Por lo general reservado, sigue con calma y firmeza un camino independiente hacia la meta establecida.  El Investigador tiene éxito en muchas cosas, no por su versatilidad sino por la tenaz determinación de llegar hasta el final.   Busca un claro propósito o meta sobre el que puede desarrollar un plan ordenado y organizar sus acciones.   Una vez embarcado en un proyecto, el Investigador lucha con tenacidad por alcanzar sus objetivos.  En ocasiones es necesario intervenir para que cambie de parecer.  Puede ser visto  por otros como terco y obstinado.',
    O132: 'El investigador se desempeña de maravilla en tareas de naturaleza técnica que le impliquen un reto, donde pueda usar e interpretar información real y sacar conclusiones.  Responde a la lógica más que a la emoción.  Al vender o comercializar una idea, puede lograr gran éxito si su producto es concreto.',
    O133: 'El Investigador prefiere trabajar solo y no se interesa en agradar a los demás.   Se le puede considerar sumamente directo, brusco y sin tacto.  Al valorar su propia capacidad de pensamiento, el Investigador evalúa a los demás por su objetividad y lógica.  Para mejorar la efectividad de sus relaciones con las personas necesita desarrollar una mayor comprensión de los demás, incluso de sus emociones.',
    E14: 'Una gran expresión verbal de la fuerza del ego; muestra un fuerte individualismo.',
    M14: 'Dominio e independencia.',
    J14: 'Su capacidad para realizar las tareas con rapidez.',
    I14: 'Su fuerza de carácter; su persistencia.',
    S14: 'Sus acciones y actitud de “yo les muestro cómo”.',
    A14: 'La impaciencia; sentido competitivo de “ganar o perder”.',
    B14: 'Se vuelve criticón y se dedica a encontrar errores;  se niega a trabajar en equipo; se excede en sus prerrogativas.',
    T14: 'Que otros se aprovechen de él; la lentitud, en especial en las actividades del trabajo; ser demasiado “blando” o “íntimo” con los demás.',
    SE14: 'Verbalizara su proceso de razonamiento; buscara otros puntos de vista e ideas sobre sus objetivos al resolver problemas; su preocupación por los demás fuera más genuina; fuera más paciente y humilde.',
    O141: 'El Orientado a Resultados muestra tal confianza en sí mismo que algunos lo interpretan como arrogancia.  Busca sin descanso oportunidades que prueben y desarrollen sus capacidades para alcanzar resultados.  A estas personas les gustan las tareas difíciles, situaciones competitivas, cometidos únicos y puestos “importantes”.  Aceptan la responsabilidad con un aire de “yo lo hago” y,  cuando terminan, de “dije que yo lo podía hacer”.',
    O142: 'El Orientado a Resultados tiende a evitar factores que lo restrinjan, como controles directos, detalles que le consuman tiempo y trabajos rutinarios.  Enérgico y directo, este individuo puede tener dificultades con los demás.  Por ser tan independiente, el Orientado a Resultados puede impacientarse cuando se ve involucrado en actividades de grupo.  Aunque por lo general prefiere trabajar solo, logra persuadir a otros para que apoyen sus esfuerzos, en especial para completar actividades de rutina.',
    O143: 'El Orientado a Resultados es rápido de pensamiento y acción.  Se impacienta con quienes son diferentes a él y los critica.  Valora a aquellos que muestran destreza para obtener resultados.  Son determinados y persistentes, incluso frente al antagonismo.  Estas personas, si creen que es necesario, toman el mando de la situación, les corresponda o no.  En su impulso tenaz en busca de resultados, pueden parecer ásperos y desatentos.',
    E15: 'Moderación calculada; afán de servir, de adaptarse a los demás.',
    M15: 'Conservar el “status quo”, controlar el ambiente.',
    J15: 'Las normas de amistad, después por su capacidad.',
    I15: 'Su constancia en el desempeño; por su afán de servir, de adaptarse a las necesidades de los demás.',
    S15: 'Planifica a corto plazo; es predecible, es congruente; mantiene un ritmo uniforme y seguro.',
    A15: 'La modestia; su miedo a correr riesgos; su resistencia pasiva hacia las innovaciones.',
    B15: 'Se adapta a quienes tienen autoridad y a lo que opina el grupo.',
    T15: 'Los cambios; la desorganización.',
    SE15: 'Compartiera más sus ideas; aumentara su confianza en sí mismo basándose en la retroalimentación que recibe; utilizara métodos más sencillos y directos.',
    O151: 'El Especialista se “lleva bien” con los demás.  Por su actitud moderada y controlada y por su comportamiento modesto, puede trabajar en armonía con diversos estilos de conducta.  El Especialista es considerado paciente y siempre está dispuesto a ayudar a quienes considera sus amigos.  De hecho, tiende a desarrollar en el trabajo una estrecha relación con un grupo relativamente reducido de compañeros.',
    O152: 'Se esfuerza por conservar pautas de comportamiento conocidos y predecibles.  El Especialista, al ser bastante eficiente en áreas especializadas, planea su trabajo, lo enfoca de manera clara y directa y consigue una notoria constancia en su desempeño.  El reconocimiento que recibe de los demás le ayuda a conservar este nivel.',
    O153: 'El Especialista es lento para adaptarse a los cambios.  Una preparación previa le concede el tiempo que requiere para cambiar sus procedimientos y conservar su nivel de rendimiento.  El Especialista puede necesitar ayuda al home de un nuevo proyecto y para desarrollar métodos prácticos y sencillos para cubrir plazos establecidos.  Suele dejar a un lado los proyectos terminados para posteriormente concluirlos.  Un pequeño consejo: ¡tire algunas de esas carpetas viejas de su archivo!.',
};

const disccorrectanswer = {
    1: {
        dam: 2, dal: 2, iam: 1, ial: 1, sam: 4, sal: 4, cam: 3, cal: 4,
    },
    2: {
        dam: 2, dal: 2, iam: 3, ial: 3, sam: 4, sal: 4, cam: 1, cal: 1,
    },
    3: {
        dam: 3, dal: 3, iam: 1, ial: 1, sam: 4, sal: 4, cam: 2, cal: 2,
    },
    4: {
        dam: 4, dal: 4, iam: 1, ial: 1, sam: 3, sal: 3, cam: 2, cal: 2,
    },
    5: {
        dam: 1, dal: 1, iam: 3, ial: 3, sam: 4, sal: 4, cam: 2, cal: 2,
    },
    6: {
        dam: 4, dal: 4, iam: 2, ial: 2, sam: 1, sal: 1, cam: 3, cal: 3,
    },
    7: {
        dam: 3, dal: 3, iam: 1, ial: 1, sam: 4, sal: 4, cam: 2, cal: 2,
    },
    8: {
        dam: 4, dal: 4, iam: 1, ial: 1, sam: 3, sal: 3, cam: 2, cal: 2,
    },
    9: {
        dam: 4, dal: 4, iam: 3, ial: 3, sam: 2, sal: 2, cam: 1, cal: 1,
    },
    10: {
        dam: 0, dal: 1, iam: 2, ial: 2, sam: 3, sal: 3, cam: 4, cal: 4,
    },
    11: {
        dam: 3, dal: 3, iam: 4, ial: 4, sam: 2, sal: 2, cam: 1, cal: 1,
    },
    12: {
        dam: 4, dal: 4, iam: 1, ial: 1, sam: 2, sal: 2, cam: 3, cal: 3,
    },
    13: {
        dam: 1, dal: 1, iam: 3, ial: 3, sam: 2, sal: 2, cam: 4, cal: 4,
    },
    14: {
        dam: 3, dal: 3, iam: 4, ial: 4, sam: 2, sal: 2, cam: 1, cal: 1,
    },
    15: {
        dam: 3, dal: 3, iam: 1, ial: 1, sam: 4, sal: 4, cam: 2, cal: 2,
    },
    16: {
        dam: 2, dal: 2, iam: 4, ial: 4, sam: 3, sal: 0, cam: 1, cal: 1,
    },
    17: {
        dam: 3, dal: 3, iam: 1, ial: 1, sam: 2, sal: 2, cam: 4, cal: 4,
    },
    18: {
        dam: 2, dal: 2, iam: 4, ial: 4, sam: 1, sal: 1, cam: 3, cal: 3,
    },
    19: {
        dam: 1, dal: 1, iam: 2, ial: 2, sam: 3, sal: 3, cam: 4, cal: 4,
    },
    20: {
        dam: 4, dal: 4, iam: 1, ial: 1, sam: 2, sal: 2, cam: 3, cal: 3,
    },
    21: {
        dam: 4, dal: 4, iam: 3, ial: 3, sam: 2, sal: 2, cam: 1, cal: 1,
    },
    22: {
        dam: 3, dal: 3, iam: 1, ial: 1, sam: 4, sal: 4, cam: 2, cal: 2,
    },
    23: {
        dam: 3, dal: 3, iam: 1, ial: 1, sam: 4, sal: 4, cam: 2, cal: 2,
    },
    24: {
        dam: 3, dal: 3, iam: 1, ial: 1, sam: 2, sal: 2, cam: 4, cal: 4,
    },
    25: {
        dam: 1, dal: 1, iam: 4, ial: 4, sam: 3, sal: 3, cam: 2, cal: 2,
    },
    26: {
        dam: 3, dal: 3, iam: 1, ial: 1, sam: 4, sal: 4, cam: 2, cal: 2,
    },
    27: {
        dam: 1, dal: 1, iam: 3, ial: 3, sam: 2, sal: 2, cam: 4, cal: 4,
    },
    28: {
        dam: 2, dal: 2, iam: 3, ial: 3, sam: 4, sal: 4, cam: 1, cal: 1,
    },
};

export { patronType, discInterpretation, disccorrectanswer };
