import React from 'react';
import PropTypes from 'prop-types';

function LogoBlack(props) {
    const { customWidth } = props;
    const width = customWidth ? `${customWidth}%` : 'auto';

    return (
        <img width={width} style={{ margin: '10px' }} src="/assets/img/logo.png" alt="" />
    );
}

LogoBlack.propTypes = {
    customWidth: PropTypes.string,
};

LogoBlack.defaultProps = {
    customWidth: 'auto',
};

export default LogoBlack;
