/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
    useEffect, useContext, useReducer,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    ButtonGroup, Button, Nav, NavItem, TabContent, TabPane, NavLink,
} from 'reactstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import {
    Panel, PanelHeader, PanelBody,
} from '../../panel/panel';
import AppContext from '../../../config/appContext';
import {
    FetchPolicy, NotificationType,
} from '../../../enums/general';

import ErrorPage from '../extraPages/error';
import TSFButton from '../../actionButtons/tsfButton';

import 'react-datetime/css/react-datetime.css';
import './companyFull.css';
import CompanyGraphql from '../../../servicesapollo/companyGraphql';
import useMessageHelper from '../../../helpers/messageHelper';

const initData = {
    employeeNumber: [{
        id: uuidv4(),
        items: [{
            id: uuidv4(),
            type: 1,
            tfte: '',
            tpte: '',
            cfte: '',
            cpte: '',
        },
        {
            id: uuidv4(),
            type: 2,
            tfte: '',
            tpte: '',
            cfte: '',
            cpte: '',
        },
        {
            id: uuidv4(),
            type: 3,
            jobTitle: '',
            tfte: '',
            tpte: '',
            cfte: '',
            cpte: '',
        }],
    }],
    employeeRegister: [
        {
            id: uuidv4(),
            type: 1,
            jobTitle: '',
            backToSchool: '',
            sickness: '',
            sicknessReturn: '',
            voluntaryResignation: '',
            maternity: '',
            maternityrReturn: '',
            retirement: '',
            lackOfWork: '',
            lackOfWorkReturn: '',
            dismissal: '',
            other: [],
        },
        {
            id: uuidv4(),
            type: 2,
            jobTitle: '',
            backToSchool: '',
            sickness: '',
            sicknessReturn: '',
            voluntaryResignation: '',
            maternity: '',
            maternityrReturn: '',
            retirement: '',
            lackOfWork: '',
            lackOfWorkReturn: '',
            dismissal: '',
            other: [],
        },
    ],
};

const initState = {
    isEditing: false,
    isDirty: false,
    activeTab: '1',
    record: {
        employeeNumber: [{
            id: uuidv4(),
            items: [{
                id: uuidv4(),
                type: 1,
                tfte: '',
                tpte: '',
                cfte: '',
                cpte: '',
            },
            {
                id: uuidv4(),
                type: 2,
                tfte: '',
                tpte: '',
                cfte: '',
                cpte: '',
            },
            {
                id: uuidv4(),
                type: 3,
                jobTitle: '',
                tfte: '',
                tpte: '',
                cfte: '',
                cpte: '',
            }],
        }],
        employeeRegister: [
            {
                id: uuidv4(),
                type: 1,
                jobTitle: '',
                backToSchool: '',
                sickness: '',
                sicknessReturn: '',
                voluntaryResignation: '',
                maternity: '',
                maternityrReturn: '',
                retirement: '',
                lackOfWork: '',
                lackOfWorkReturn: '',
                dismissal: '',
                other: [],
            },
            {
                id: uuidv4(),
                type: 2,
                jobTitle: '',
                backToSchool: '',
                sickness: '',
                sicknessReturn: '',
                voluntaryResignation: '',
                maternity: '',
                maternityrReturn: '',
                retirement: '',
                lackOfWork: '',
                lackOfWorkReturn: '',
                dismissal: '',
                other: [],
            },
        ],
    },
};

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
    SET_ITEM_VALUE_CHANGED: 'setItemValueChanged',
    SET_REMOVE_ITEM_SECTION: 'setRemoveItemSection',
    SET_ADD_ITEM_SECTION: 'setAddItemSection',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...state,
            record: action.payload,
            isDirty: false,
        };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        const { payload, isDirty } = action;
        return { ...state, record: payload, isDirty };
    }
    case ACTION_TYPES.SET_UPDATE_ITEM_SECTION: {
        return {
            ...state,
            record: action.value,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.payload };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

const timerForUpdateInfo = 2000;
let saveInfoTimeout = null;

function CompanyEmployee() {
    const { companyId } = useParams();
    const history = useHistory();

    const {
        showNotification, languageTitles, loggedUser,
        setIsDirtyData,
    } = useContext(AppContext);

    const { getErrorMessage } = useMessageHelper();

    if ((Number(companyId) !== Number(loggedUser.EntityId) && loggedUser.UserType !== 'companyadmin')
    || !loggedUser.UserType.includes('company')) return <ErrorPage />;

    const [state, dispatch] = useReducer(reducer, ({ ...initState, isEditing: loggedUser.IsCompanyUser }));

    const {
        record, isEditing, isDirty, activeTab,
    } = state;

    const [getCompanyEmployee, { loading: isLoading }] = useLazyQuery(CompanyGraphql.GET_COMPANY_EMPLOYEE, {
        onCompleted: (res) => {
            if (res.company) {
                const {
                    data, success, message, error,
                } = res.company;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                const result = data.CompanyEmployees && data.CompanyEmployee !== '' ? JSON.parse(data.CompanyEmployees) : null;

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    payload: {
                        employeeNumber: result && result?.employeeNumber?.length > 0 ? result?.employeeNumber : initData.employeeNumber,
                        employeeRegister: result && result?.employeeRegister?.length > 0 ? result?.employeeRegister : initData.employeeRegister,
                    },
                });
                setIsDirtyData(false);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const callGetData = () => {
        getCompanyEmployee({
            variables: {
                id: Number(companyId),
            },
        });
    };

    const [saveCompany, { loading: isSaving }] = useMutation(CompanyGraphql.UPDATE_COMPANY, {
        onCompleted: (mutationData) => {
            if (mutationData?.updateCompany) {
                const {
                    success, message, error,
                } = mutationData?.updateCompany;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS, 500);

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    payload: {
                        isEditing: loggedUser.IsCompanyUser || false,
                        isDirty: false,
                    },
                });
                setIsDirtyData(false);
                // callGetData();
            }
        },
        onError: (error) => {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                payload: {
                    isDirty: true,
                },
            });
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSave = (currentRecord) => {
        saveCompany({
            variables: {
                record: {
                    CompanyEmployees: JSON.stringify(currentRecord),
                    CompanyId: Number(companyId),
                },
            },
        });
    };

    const onActionButtons = (e, action) => {
        e.preventDefault();

        if (action === 'edit') {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                payload: {
                    isEditing: true,
                },
            });
        } else if (action === 'cancel') {
            if (isDirty) {
                callGetData();
            }

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                payload: {
                    isEditing: false,
                    isDirty: false,
                },
            });
            setIsDirtyData(false);
        } else if (action === 'save') {
            onSave(record);
        } else if (action === 'refresh') {
            callGetData();

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                payload: {
                    isEditing: loggedUser.IsCompanyUser || false,
                    isDirty: false,
                },
            });
            setIsDirtyData(false);
        }
    };

    const handleInputChange = (section, item, e, index = 0) => {
        e.preventDefault();

        const { name, value } = e.target;

        const currentRecord = record;

        const currentRecords = section === 'employeeRegister' ? currentRecord.employeeRegister : currentRecord.employeeNumber[index].items;

        const dirtyRecords = currentRecords.map((m) => {
            if (item.id === m.id && item.type === m.type) {
                if (name !== 'other') {
                    m[name] = value;

                    if (name === 'dismissal') {
                        const plusOrRemove = Number(value) - item?.other?.length;
                        if (plusOrRemove !== 0) {
                            let currentdismissalOther = item?.other || [];
                            if (plusOrRemove < 0) currentdismissalOther = currentdismissalOther?.slice(0, plusOrRemove);
                            else {
                                for (let i = 0; i < plusOrRemove; i += 1) {
                                    currentdismissalOther?.push('');
                                }
                            }

                            m.other = currentdismissalOther;
                        }
                    }
                } else {
                    const currentDismisalOther = m.other.map((c, j) => {
                        let currentValue = c;
                        if (j === index) {
                            currentValue = value;
                        }

                        return currentValue;
                    });

                    m.other = currentDismisalOther;
                }
            }

            return m;
        });

        if (section === 'employeeRegister') {
            currentRecord.employeeRegister = dirtyRecords;
        } else {
            currentRecord.employeeNumber[index].items = dirtyRecords;
        }

        clearTimeout(saveInfoTimeout);

        saveInfoTimeout = setTimeout(() => {
            onSave(currentRecord);
        }, timerForUpdateInfo);

        dispatch({
            type: ACTION_TYPES.SET_UPDATE_ITEM_SECTION,
            value: currentRecord,
        });
        // setIsDirtyData(true);
    };

    const onRemoveItemFromSection = (section, item, rowIndex) => {
        const currentRecord = record;

        if (section === 'employeeNumber' && item === null) {
            const currentRecords = currentRecord.employeeNumber.filter((c, index) => index !== rowIndex);
            currentRecord.employeeNumber = currentRecords;
        } else {
            const currentRecords = section === 'employeeRegister' ? record.employeeRegister.filter((c) => c.id !== item.id)
                : record.employeeNumber[rowIndex].items.filter((c) => c.id !== item.id);

            if (section === 'employeeRegister') {
                currentRecord.employeeRegister = currentRecords;
            } else {
                currentRecord.employeeNumber[rowIndex].items = currentRecords;
            }
        }

        // clearTimeout(saveInfoTimeout);

        onSave(currentRecord);

        dispatch({
            type: ACTION_TYPES.SET_UPDATE_ITEM_SECTION,
            value: currentRecord,
        });
        // setIsDirtyData(true);
    };

    const onAddItemSection = (section, type, rowIndex) => {
        const currentRecord = record;

        if (section === 'employeeNumber' && type === 0) {
            currentRecord.employeeNumber.push({
                id: uuidv4(),
                items: [{
                    id: uuidv4(),
                    type: 1,
                    tfte: '',
                    tpte: '',
                    cfte: '',
                    cpte: '',
                },
                {
                    id: uuidv4(),
                    type: 2,
                    tfte: '',
                    tpte: '',
                    cfte: '',
                    cpte: '',
                },
                {
                    id: uuidv4(),
                    type: 3,
                    jobTitle: '',
                    tfte: '',
                    tpte: '',
                    cfte: '',
                    cpte: '',
                },
                {
                    id: uuidv4(),
                    type: 4,
                    jobTitle: '',
                    tfte: '',
                    tpte: '',
                    cfte: '',
                    cpte: '',
                }],
            });
        } else {
            const currentRecords = section === 'employeeRegister' ? currentRecord.employeeRegister
                : currentRecord.employeeNumber[rowIndex].items;

            if (section === 'employeeRegister') {
                currentRecords.push({
                    id: uuidv4(),
                    type,
                    jobTitle: '',
                    backToSchool: '',
                    sickness: '',
                    sicknessReturn: '',
                    voluntaryResignation: '',
                    maternity: '',
                    maternityrReturn: '',
                    retirement: '',
                    lackOfWork: '',
                    lackOfWorkReturn: '',
                    dismissal: '',
                    other: [],
                });
            } else {
                currentRecords.push(
                    {
                        id: uuidv4(),
                        type,
                        jobTitle: '',
                        tfte: '',
                        tpte: '',
                        cfte: '',
                        cpte: '',
                    },
                );
            }

            if (section === 'employeeRegister') {
                currentRecord.employeeRegister = currentRecords;
            } else {
                currentRecord.employeeNumber[rowIndex].items = currentRecords;
            }
        }

        // clearTimeout(saveInfoTimeout);

        onSave(currentRecord);

        dispatch({
            type: ACTION_TYPES.SET_UPDATE_ITEM_SECTION,
            value: currentRecord,
        });
        // setIsDirtyData(true);
    };

    const togglePill = (tab) => {
        if (state.activeTab !== tab) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                payload: {
                    activeTab: tab,
                },
            });
        }
    };

    useEffect(() => {
        if (Number(companyId) > 0) {
            callGetData();
        }
    }, [companyId]);

    return (
        <Panel>
            <PanelHeader title={languageTitles.companyTabEmployeeTitle}>
                <ButtonGroup className="pull-right">
                    <Button
                        color="green"
                        title={languageTitles.candidatesEditBtnTitle}
                        className={`btn-sm me-1 ${(isEditing || isLoading) ? 'hide ' : ''}`}
                        onClick={(e) => onActionButtons(e, 'edit')}
                    >
                        <i className="fa fa-edit" />
                        {' '}
                        {languageTitles.updateTitle}
                    </Button>
                    <TSFButton
                        action="save"
                        isWorking={isSaving}
                        isHiddden={!(isEditing && isDirty)}
                        onClick={(e) => onActionButtons(e, 'save')}
                    />
                    <TSFButton
                        action="refresh"
                        isWorking={isLoading}
                        isDisabled={isSaving}
                        onClick={(e) => onActionButtons(e, 'refresh')}
                    />
                </ButtonGroup>
            </PanelHeader>
            <PanelBody>
                <div className="card-body">
                    <div className="row row-space-2 mb-3">
                        <div className="nav-wizards-container">
                            <Nav className="nav nav-wizards-2" pills>
                                <NavItem className="nav-item col">
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' }, 'completed')}
                                        onClick={() => { togglePill('1'); }}
                                    >
                                        <span className="d-sm-none nav-text">
                                            {languageTitles.companyTabEmployeeTab1Title}
                                        </span>
                                        <span className="d-sm-block d-none nav-text">{languageTitles.companyTabEmployeeTab1Title}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="nav-item col">
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' }, 'completed')}
                                        onClick={() => { togglePill('2'); }}
                                    >
                                        <span className="d-sm-none nav-text">
                                            {languageTitles.companyTabEmployeeTab2Title}
                                        </span>
                                        <span className="d-sm-block d-none nav-text">{languageTitles.companyTabEmployeeTab2Title}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                </div>
                <TabContent className="rounded bg-white" activeTab={activeTab}>
                    <TabPane tabId="1">
                        {record.employeeNumber.map((rec, rowIndex) => (
                            <div className="card mb-3" key={rec.id}>
                                <div>
                                    {rec.items.filter((c) => c.type < 3).map((item) => (
                                        <div key={item.id}>
                                            <div className="row m-1">
                                                <label className="form-label col-form-label labelTitle">
                                                    {item.type === 1 ? languageTitles.nationalEmployeeTitle
                                                        : item.type === 2 ? languageTitles.localEmployeeTitle : ''}

                                                </label>
                                                <div className="col-md-6">
                                                    <div className="row mb-1">
                                                        <label className="form-label col-form-label col-md-7">{languageTitles.temporaryFullTimeEmployees}</label>
                                                        <div className="col-md-5">
                                                            {!isEditing && <label className="col-form-label field">{item.tfte}</label>}
                                                            {isEditing && (
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="tfte"
                                                                    required
                                                                    onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                    value={item.tfte}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <label className="form-label col-form-label col-md-7">{languageTitles.temporaryPartTimeEmployees}</label>
                                                        <div className="col-md-5">
                                                            {!isEditing && <label className="col-form-label field">{item.tpte}</label>}
                                                            {isEditing && (
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="tpte"
                                                                    required
                                                                    onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                    value={item.tpte}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row mb-1">
                                                        <label className="form-label col-form-label col-md-7">{languageTitles.canadianFullTimeEmployees}</label>
                                                        <div className="col-md-5">
                                                            {!isEditing && <label className="col-form-label field">{item.cfte}</label>}
                                                            {isEditing && (
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="cfte"
                                                                    required
                                                                    onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                    value={item.cfte}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <label className="form-label col-form-label col-md-7">{languageTitles.canadianPartTimeEmployees}</label>
                                                        <div className="col-md-5">
                                                            {!isEditing && <label className="col-form-label field">{item.cpte}</label>}
                                                            {isEditing && (
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="cpte"
                                                                    required
                                                                    onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                    value={item.cpte}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <label className="form-label col-form-label labelTitle ps-3">
                                        {languageTitles.localWorkingEmployeeTitle}
                                    </label>
                                    {rec.items.filter((c) => c.type === 3).map((item, index) => (
                                        <div className="row m-1 border-bottom-separate" key={item.id}>
                                            <div className="col-md-6">
                                                <div className="row mb-1">
                                                    <label className="form-label col-form-label col-md-7">{languageTitles.companyEmployeeJobTitle}</label>
                                                    <div className="col-md-5">
                                                        {!isEditing && <label className="col-form-label field">{item.jobTitle}</label>}
                                                        {isEditing && (
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="jobTitle"
                                                                required
                                                                onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                value={item.jobTitle}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">&nbsp;</div>
                                            <div className="col-md-6">
                                                <div className="row mb-1">
                                                    <label className="form-label col-form-label col-md-7">{languageTitles.temporaryFullTimeEmployees}</label>
                                                    <div className="col-md-5">
                                                        {!isEditing && <label className="col-form-label field">{item.tfte}</label>}
                                                        {isEditing && (
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="tfte"
                                                                required
                                                                onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                value={item.tfte}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <label className="form-label col-form-label col-md-7">{languageTitles.temporaryPartTimeEmployees}</label>
                                                    <div className="col-md-5">
                                                        {!isEditing && <label className="col-form-label field">{item.tpte}</label>}
                                                        {isEditing && (
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="tpte"
                                                                required
                                                                onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                value={item.tpte}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row mb-1">
                                                    <label className="form-label col-form-label col-md-7">{languageTitles.canadianFullTimeEmployees}</label>
                                                    <div className="col-md-5">
                                                        {!isEditing && <label className="col-form-label field">{item.cfte}</label>}
                                                        {isEditing && (
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="cfte"
                                                                required
                                                                onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                value={item.cfte}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <label className="form-label col-form-label col-md-7">{languageTitles.canadianPartTimeEmployees}</label>
                                                    <div className="col-md-5">
                                                        {!isEditing && <label className="col-form-label field">{item.cpte}</label>}
                                                        {isEditing && (
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="cpte"
                                                                required
                                                                onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                value={item.cpte}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isEditing && (
                                                    <div className="col">
                                                        {(rec.items.filter((c) => c.type === 3).length > 0 && !(rec.items.filter((c) => c.type === 3).length === 1 && index === 0))
                                                            && (
                                                                <TSFButton
                                                                    action="custom"
                                                                    btnColor="danger"
                                                                    className="mb-3 me-3"
                                                                    useIcon
                                                                    iconClass="fas fa-ban"
                                                                    label={languageTitles.removeButton}
                                                                    onClick={() => onRemoveItemFromSection('employeeNumber', item, rowIndex)}
                                                                />
                                                            )}
                                                        {index === rec.items.filter((c) => c.type === 3).length - 1
                                                        && (
                                                            <TSFButton
                                                                action="custom"
                                                                btnColor="primary"
                                                                className="mb-3 me-3"
                                                                label={languageTitles.addJobButton}
                                                                useIcon
                                                                iconClass="fas fa-plus"
                                                                onClick={() => onAddItemSection('employeeNumber', 3, rowIndex)}
                                                            />
                                                        ) }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    {rec.items.filter((c) => c.type === 4).map((item, index) => (
                                        <>
                                            {index === 0
                                                && (
                                                    <label className="form-label col-form-label labelTitle ps-3">
                                                        {languageTitles.otherWorkingEmployeeTitle}
                                                    </label>
                                                )}
                                            <div className="row m-1 border-bottom-separate" key={item.id}>
                                                <div className="col-md-6">
                                                    <div className="row mb-1">
                                                        <label className="form-label col-form-label col-md-7">{languageTitles.companyEmployeeJobTitle}</label>
                                                        <div className="col-md-5">
                                                            {!isEditing && <label className="col-form-label field">{item.jobTitle}</label>}
                                                            {isEditing && (
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="jobTitle"
                                                                    required
                                                                    onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                    value={item.jobTitle}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">&nbsp;</div>
                                                <div className="col-md-6">
                                                    <div className="row mb-1">
                                                        <label className="form-label col-form-label col-md-7">{languageTitles.temporaryFullTimeEmployees}</label>
                                                        <div className="col-md-5">
                                                            {!isEditing && <label className="col-form-label field">{item.tfte}</label>}
                                                            {isEditing && (
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="tfte"
                                                                    required
                                                                    onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                    value={item.tfte}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <label className="form-label col-form-label col-md-7">{languageTitles.temporaryPartTimeEmployees}</label>
                                                        <div className="col-md-5">
                                                            {!isEditing && <label className="col-form-label field">{item.tpte}</label>}
                                                            {isEditing && (
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="tpte"
                                                                    required
                                                                    onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                    value={item.tpte}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row mb-1">
                                                        <label className="form-label col-form-label col-md-7">{languageTitles.canadianFullTimeEmployees}</label>
                                                        <div className="col-md-5">
                                                            {!isEditing && <label className="col-form-label field">{item.cfte}</label>}
                                                            {isEditing && (
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="cfte"
                                                                    required
                                                                    onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                    value={item.cfte}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <label className="form-label col-form-label col-md-7">{languageTitles.canadianPartTimeEmployees}</label>
                                                        <div className="col-md-5">
                                                            {!isEditing && <label className="col-form-label field">{item.cpte}</label>}
                                                            {isEditing && (
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="cpte"
                                                                    required
                                                                    onChange={(e) => handleInputChange('employeeNumber', item, e, rowIndex)}
                                                                    value={item.cpte}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    isEditing && (
                                                        <div className="col">
                                                            <TSFButton
                                                                action="custom"
                                                                btnColor="danger"
                                                                className="mb-3 me-3"
                                                                useIcon
                                                                iconClass="fas fa-ban"
                                                                label={languageTitles.removeButton}
                                                                onClick={() => onRemoveItemFromSection('employeeNumber', item, rowIndex)}
                                                            />
                                                            {index === rec.items.filter((c) => c.type === 4).length - 1
                                                            && (
                                                                <TSFButton
                                                                    action="custom"
                                                                    btnColor="success"
                                                                    className="mb-3 me-3"
                                                                    label={languageTitles.addJobNewSection}
                                                                    useIcon
                                                                    iconClass="fas fa-plus"
                                                                    onClick={() => onAddItemSection('employeeNumber', 4, rowIndex)}
                                                                />
                                                            ) }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </>
                                    ))}
                                    {rec?.items?.filter((c) => c.type === 4).length === 0
                                    && (
                                        <div className="col m-1 ms-3">
                                            <TSFButton
                                                action="custom"
                                                btnColor="success"
                                                label={languageTitles.addJobNewSection}
                                                useIcon
                                                iconClass="fas fa-plus"
                                                onClick={() => onAddItemSection('employeeNumber', 4, rowIndex)}
                                            />
                                        </div>
                                    ) }
                                </div>
                            </div>
                        ))}
                        <div className="float-end">
                            <TSFButton
                                action="custom"
                                btnColor="primary"
                                useIcon
                                iconClass="fas fa-arrow-right"
                                label={languageTitles.nextBtn}
                                onClick={() => { togglePill('2'); }}
                            />
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="card mb-3">
                            <label className="form-label col-form-label labelTitle ps-3">
                                {languageTitles.temporaryRegisterEmployees}
                            </label>
                            {record.employeeRegister.filter((c) => c.type === 1).map((item) => (
                                <div className="row m-1 border-bottom-separate" key={item.id}>
                                    <div className="col-md-10">
                                        <div className="row">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.registerEmployeesJobTitle}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{item.jobTitle}</label>}
                                                {isEditing && (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="jobTitle"
                                                        required
                                                        onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                        value={item.jobTitle}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 text-end">
                                        {
                                            isEditing && (
                                                <TSFButton
                                                    action="custom"
                                                    btnColor="danger"
                                                    useIcon
                                                    iconClass="fas fa-ban"
                                                    label={languageTitles.removeButton}
                                                    onClick={() => onRemoveItemFromSection('employeeRegister', item)}
                                                />
                                            )
                                        }
                                    </div>
                                    <label className="form-label col-form-label labelTitle">
                                        {languageTitles.canadianRegisterQuestion}
                                    </label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesBackToSchool}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.backToSchool}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="backToSchool"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.backToSchool}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesSickness}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.sickness}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="sickness"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.sickness}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-8">{languageTitles.registerEmployeesReturn}</label>
                                                <div className="col-md-4">
                                                    {!isEditing && <label className="col-form-label field">{item.sicknessReturn}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="sicknessReturn"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.sicknessReturn}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesVoluntaryResignation}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.voluntaryResignation}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="voluntaryResignation"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.voluntaryResignation}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesMaternity}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.maternity}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="maternity"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.maternity}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-8">{languageTitles.registerEmployeesReturn}</label>
                                                <div className="col-md-4">
                                                    {!isEditing && <label className="col-form-label field">{item.maternityReturn}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="maternityReturn"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.maternityReturn}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesRetirement}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.retirement}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="retirement"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.retirement}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerLackOfWork}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.lackOfWork}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="lackOfWork"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.lackOfWork}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-8">{languageTitles.registerEmployeesReturn}</label>
                                                <div className="col-md-4">
                                                    {!isEditing && <label className="col-form-label field">{item.lackOfWorkReturn}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="lackOfWorkReturn"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.lackOfWorkReturn}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesDismissal}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.dismissal}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="dismissal"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.dismissal}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {item.dismissal !== ''
                                            && item?.other.map((c, i) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <div className="row mb-1" key={i}>
                                                    <label className="form-label col-form-label col-md-3">
                                                        {languageTitles.registerEmployeesOther}
                                                        {' '}
                                                        {i + 1}
                                                    </label>
                                                    <div className="col-md-9">
                                                        {!isEditing && <label className="col-form-label field">{c}</label>}
                                                        {isEditing && (
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                rows="1"
                                                                name="other"
                                                                onChange={(e) => handleInputChange('employeeRegister', item, e, i)}
                                                                value={c}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                            {isEditing
                            && (
                                <div className="col m-1 ms-3">
                                    <TSFButton
                                        action="custom"
                                        className="btn btn-primary"
                                        label={languageTitles.addJobButton}
                                        useIcon
                                        iconClass="fas fa-plus"
                                        onClick={() => onAddItemSection('employeeRegister', 1)}
                                    />
                                </div>
                            ) }
                        </div>
                        <div className="card mb-1">
                            <label className="form-label col-form-label labelTitle ps-3">
                                {languageTitles.canadianRegisterEmployees}
                            </label>
                            {record.employeeRegister.filter((c) => c.type === 2).map((item) => (
                                <div className="row m-1 border-bottom-separate" key={item.id}>
                                    <div className="col-md-10">
                                        <div className="row">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.registerEmployeesJobTitle}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{item.jobTitle}</label>}
                                                {isEditing && (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="jobTitle"
                                                        required
                                                        onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                        value={item.jobTitle}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 text-end">
                                        {
                                            isEditing && (
                                                <TSFButton
                                                    action="custom"
                                                    btnColor="danger"
                                                    useIcon
                                                    iconClass="fas fa-ban"
                                                    label={languageTitles.removeButton}
                                                    onClick={() => onRemoveItemFromSection('employeeRegister', item)}
                                                />
                                            )
                                        }
                                    </div>
                                    <label className="form-label col-form-label labelTitle">
                                        {languageTitles.canadianRegisterQuestion}
                                    </label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesBackToSchool}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.backToSchool}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="backToSchool"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.backToSchool}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesSickness}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.sickness}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="sickness"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.sickness}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-8">{languageTitles.registerEmployeesReturn}</label>
                                                <div className="col-md-4">
                                                    {!isEditing && <label className="col-form-label field">{item.sicknessReturn}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="sicknessReturn"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.sicknessReturn}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesVoluntaryResignation}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.voluntaryResignation}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="voluntaryResignation"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.voluntaryResignation}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesMaternity}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.maternity}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="maternity"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.maternity}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-8">{languageTitles.registerEmployeesReturn}</label>
                                                <div className="col-md-4">
                                                    {!isEditing && <label className="col-form-label field">{item.maternityReturn}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="maternityReturn"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.maternityReturn}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesRetirement}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.retirement}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="retirement"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.retirement}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerLackOfWork}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.lackOfWork}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="lackOfWork"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.lackOfWork}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-8">{languageTitles.registerEmployeesReturn}</label>
                                                <div className="col-md-4">
                                                    {!isEditing && <label className="col-form-label field">{item.lackOfWorkReturn}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="lackOfWorkReturn"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.lackOfWorkReturn}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row mb-1">
                                                <label className="form-label col-form-label col-md-7">{languageTitles.registerEmployeesDismissal}</label>
                                                <div className="col-md-5">
                                                    {!isEditing && <label className="col-form-label field">{item.dismissal}</label>}
                                                    {isEditing && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="dismissal"
                                                            required
                                                            onChange={(e) => handleInputChange('employeeRegister', item, e)}
                                                            value={item.dismissal}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {item.dismissal !== ''
                                            && item?.other.map((c, i) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <div className="row mb-1" key={i}>
                                                    <label className="form-label col-form-label col-md-3">
                                                        {languageTitles.registerEmployeesOther}
                                                        {' '}
                                                        {i + 1}
                                                    </label>
                                                    <div className="col-md-9">
                                                        {!isEditing && <label className="col-form-label field">{c}</label>}
                                                        {isEditing && (
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                rows="1"
                                                                name="other"
                                                                onChange={(e) => handleInputChange('employeeRegister', item, e, i)}
                                                                value={c}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                            <div className="col ms-15px">
                                {isEditing && (
                                    <TSFButton
                                        action="custom"
                                        btnColor="primary"
                                        className="mb-1"
                                        label={languageTitles.addJobButton}
                                        useIcon
                                        iconClass="fas fa-plus"
                                        onClick={() => onAddItemSection('employeeRegister', 2)}
                                    />
                                ) }
                                {!isDirty && (
                                    <div className="float-end">
                                        <TSFButton
                                            action="custom"
                                            btnColor="primary"
                                            className="me-2"
                                            useIcon
                                            iconClass="fas fa-arrow-left"
                                            label={languageTitles.previousBtn}
                                            onClick={() => { togglePill('1'); }}
                                        />
                                        <TSFButton
                                            action="custom"
                                            btnColor="success"
                                            useIcon
                                            iconClass="fas fa-share"
                                            label={languageTitles.nextSectionJobPostingBtn}
                                            onClick={() => { history.push(`/files/company/${Number(companyId)}`); }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </PanelBody>
        </Panel>
    );
}

export default CompanyEmployee;
