/* eslint-disable no-undef */
import React, { useContext, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import AppContext from '../../../config/appContext';
import { authProtectedRoutes, publicRoutes } from '../../../routes';

function Content() {
    const {
        appContentClass,
    } = useContext(AppContext);

    const history = useHistory();

    const allRoutes = [...authProtectedRoutes, ...publicRoutes];

    const setTitlePage = (path, routeArray) => {
        let pageTitle;
        for (let i = 0; i < routeArray.length; i += 1) {
            if (routeArray[i].path === path) {
                pageTitle = `Travailleur Sans Frontiere | ${routeArray[i].title}`;
            }
        }
        document.title = (pageTitle) || 'Travailleur Sans Frontiere App';
    };

    useEffect(() => {
        setTitlePage(history.location.pathname, allRoutes);
    }, [history.location.pathname]);

    return (
        <div className={`app-content ${appContentClass}`}>
            {
                allRoutes.map((route) => (
                    <Route
                        key={route.id}
                        path={route.path}
                        exact={route.exact ?? true}
                        component={route.component}
                    />
                ))
            }
        </div>
    );
}

export default Content;
