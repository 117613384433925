/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

// components
import Header from './components/layout/header/header';
import Sidebar from './components/layout/sidebar/sidebar';
import SidebarRight from './components/layout/sidebar-right/sidebar-right';
import Content from './components/layout/content/content';
import AppContext from './config/appContext';
import Splash from './components/layout/splash/splash';

function MainLayout() {
    const {
        appGradientEnabled, appHeaderNone, appHeaderFixed, appSidebarFixed, appSidebarNone, appSidebarEnd,
        appSidebarWide, appSidebarLight, appSidebarMinify, appSidebarMobileToggled, appTopMenu, appContentFullHeight,
        appSidebarTwo, appSidebarEndToggled, appSidebarEndMobileToggled, hasScroll, appContentNone, loggedUser,
    } = useContext(AppContext);

    if (!loggedUser || Object.keys(loggedUser).length === 0) {
        return <Splash />;
    }

    return (
        <div className={
            `app 
            ${appGradientEnabled ? 'app-gradient-enabled ' : ''}
            ${appHeaderNone ? 'app-without-header ' : ''}
            ${appHeaderFixed && !appHeaderNone ? 'app-header-fixed ' : ''}
            ${appSidebarFixed ? 'app-sidebar-fixed ' : ''}
            ${appSidebarNone ? 'app-without-sidebar ' : ''}
            ${appSidebarEnd ? 'app-with-end-sidebar ' : ''}
            ${appSidebarWide ? 'app-with-wide-sidebar ' : ''}
            ${appSidebarLight ? 'app-with-light-sidebar ' : ''}
            ${appSidebarMinify ? 'app-sidebar-minified ' : ''}
            ${appSidebarMobileToggled ? 'app-sidebar-mobile-toggled ' : ''}
            ${appTopMenu ? 'app-with-top-menu ' : ''}
            ${appContentFullHeight ? 'app-content-full-height ' : ''}
            ${appSidebarTwo ? 'app-with-two-sidebar ' : ''}
            ${appSidebarEndToggled ? 'app-sidebar-end-toggled ' : ''}
            ${appSidebarEndMobileToggled ? 'app-sidebar-end-mobile-toggled ' : ''}
            ${hasScroll ? 'has-scroll ' : ''}`
        }
        >
            {!appHeaderNone && (<Header />)}
            {!appSidebarNone && (<Sidebar />)}
            {appSidebarTwo && (<SidebarRight />)}
            {/* {appTopMenu && (<TopMenu />)} */}
            {!appContentNone && (<Content />)}
            {/* <FloatSubMenu /> */}
            {/* <ThemePanel /> */}
        </div>
    );
}

export default withRouter(MainLayout);
