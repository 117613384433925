/* eslint-disable max-len */
const termantest = [
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 1',
        TestNumber: 1,
        TotalQuestions: 16,
        Timer: 120,
        Instruction:
      'Elija la letra correspondiente a la palabra que complete correctamente la oración, tal como lo muestra el ejemplo',
        Example: {
            Number: 0,
            IsExample: true,
            Title: 'El iniciador de nuestra guerra de independencia fue:',
            Options: [
                { 1: 'Morelos' },
                { 2: 'Zaragoza' },
                { 3: 'Iturbide' },
                { 4: 'Hidalgo' },
            ],
            CorrectAnswer: 4,
        },
        Data: [
            {
                IdentityId: 1,
                Number: 1,
                IsExample: false,
                Title: 'La gasolina se extrae de:',
                Options: [
                    { 1: 'Granos' },
                    { 2: 'Petróleo' },
                    { 3: 'Trementina' },
                    { 4: 'Semilla' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 2,
                Number: 2,
                IsExample: false,
                Title: 'Una tonelada tiene:',
                Options: [
                    { 1: '1000 Kgs' },
                    { 2: '2000 Kg' },
                    { 3: '3000 Kgs' },
                    { 4: '4000 Kgs' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 3,
                Number: 3,
                IsExample: false,
                Title: 'La mayoría de nuestras exportaciones salen por:',
                Options: [
                    { 1: 'Mazatlán' },
                    { 2: 'Veracruz' },
                    { 3: 'Progreso' },
                    { 4: 'Acapulco' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 4,
                Number: 4,
                IsExample: false,
                Title: 'El nervio óptico sirve para:',
                Options: [
                    { 1: 'Ver' },
                    { 2: 'Oír' },
                    { 3: 'Probar' },
                    { 4: 'Sentir' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 5,
                Number: 5,
                IsExample: false,
                Title: 'El café es una especie de:',
                Options: [
                    { 1: 'Corteza' },
                    { 2: 'Fruto' },
                    { 3: 'Hojas' },
                    { 4: 'Raíz' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 6,
                Number: 6,
                IsExample: false,
                Title: 'El jamón es una especie de:',
                Options: [
                    { 1: 'Carnero' },
                    { 2: 'Vaca' },
                    { 3: 'Gallina' },
                    { 4: 'Cerdo' },
                ],
                CorrectAnswer: 4,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 7,
                Number: 7,
                IsExample: false,
                Title: 'La laringe está en:',
                Options: [
                    { 1: 'Abdomen' },
                    { 2: 'Cabeza' },
                    { 3: 'Garganta' },
                    { 4: 'Espalda' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 8,
                Number: 8,
                IsExample: false,
                Title: 'La guillotina causa:',
                Options: [
                    { 1: 'Muerte' },
                    { 2: 'Enfermedad' },
                    { 3: 'Fiebre' },
                    { 4: 'Malestar' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 9,
                Number: 9,
                IsExample: false,
                Title: 'La grúa se usa para:',
                Options: [
                    { 1: 'Perforar' },
                    { 2: 'Cortar' },
                    { 3: 'Levantar' },
                    { 4: 'Exprimir' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 10,
                Number: 10,
                IsExample: false,
                Title: 'Una figura de seis lados se llama:',
                Options: [
                    { 1: 'Pentágono' },
                    { 2: 'Paralelogramo' },
                    { 3: 'Hexágono' },
                    { 4: 'Trapecio' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 11,
                Number: 11,
                IsExample: false,
                Title: 'El Kilowatt mide:',
                Options: [
                    { 1: 'Lluvia' },
                    { 2: 'Viento' },
                    { 3: 'Electricidad' },
                    { 4: 'Presión' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 12,
                Number: 12,
                IsExample: false,
                Title: 'La pauta se usa en:',
                Options: [
                    { 1: 'Agricultura' },
                    { 2: 'Música' },
                    { 3: 'Fotografía' },
                    { 4: 'Estenografía' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 13,
                Number: 13,
                IsExample: false,
                Title: 'La esmeraldas son:',
                Options: [
                    { 1: 'Azules' },
                    { 2: 'Verdes' },
                    { 3: 'Rojas' },
                    { 4: 'Amarillas' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 14,
                Number: 14,
                IsExample: false,
                Title: 'El metro es aproximadamente igual a:',
                Options: [
                    { 1: 'Pie' },
                    { 2: 'Pulgada' },
                    { 3: 'Yarda' },
                    { 4: 'Milla' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 15,
                Number: 15,
                IsExample: false,
                Title: 'Las esponjas se obtienen de:',
                Options: [
                    { 1: 'Animales' },
                    { 2: 'Hierbas' },
                    { 3: 'Yarda' },
                    { 4: 'Milla' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 16,
                Number: 16,
                IsExample: false,
                Title: 'Fraude es término usado en:',
                Options: [
                    { 1: 'Medicina' },
                    { 2: 'Teología' },
                    { 3: 'Leyes' },
                    { 4: 'Pedagogía' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 2',
        TestNumber: 2,
        TotalQuestions: 11,
        Timer: 120,
        Instruction: 'Elija la letra correspondiente a la palabra que complete correctamente la oración, tal como lo muestra el ejemplo',
        Example: {
            Number: 0,
            IsExample: true,
            Title: '¿Por qué compramos relojes?',
            Options: [
                { 1: 'Nos gusta oírlos sonar' },
                { 2: 'Tiene manecillas' },
                { 3: 'Nos indican las horas' },
            ],
            CorrectAnswer: '3',
        },
        Data: [
            {
                IdentityId: 17,
                Number: 1,
                IsExample: false,
                Title: 'Si la tierra estuviera más cerca del sol:',
                Options: [
                    { 1: 'Las estrellas desaparecerían' },
                    { 2: 'Los meses serían más largos' },
                    { 3: 'La tierra estaría más caliente' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 18,
                Number: 2,
                IsExample: false,
                Title:
          'Los rayos de una rueda están frecuentemente hechos de nogal porque :',
                Options: [
                    { 1: 'El nogal es fuerte' },
                    { 2: 'Se corta fácilmente' },
                    { 3: 'Sus frenos no son buenos' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 19,
                Number: 3,
                IsExample: false,
                Title: 'Un tren se detiene con más dificultad que un automóvil porque:',
                Options: [
                    { 1: 'Tiene más ruedas' },
                    { 2: 'Es más pesado' },
                    { 3: 'Sus frenos no son buenos' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 20,
                Number: 4,
                IsExample: false,
                Title: 'El dicho “a golpecitos se derriba un roble” quiere decir:',
                Options: [
                    { 1: 'Que los robles son débiles' },
                    { 2: 'Que son mejores los golpes pequeños' },
                    { 3: 'Que el esfuerzo constante logra resultados sorprendentes' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 21,
                Number: 5,
                IsExample: false,
                Title: 'El dicho “Una olla vigilada nunca hierve” quiere decir:',
                Options: [
                    { 1: 'Que no debemos vigilarla cuando está en el fuego' },
                    { 2: 'Que tarda en hervir' },
                    { 3: 'Que el tiempo se alarga cuando esperamos algo' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 22,
                Number: 6,
                IsExample: false,
                Title: 'El dicho “Siembra pasto mientras haya sol” quiere decir:',
                Options: [
                    { 1: 'Que el pasto se siembra en verano' },
                    { 2: 'Que debemos aprovechar nuestras oportunidades' },
                    { 3: 'Que el pasto no debe cortarse en la noche' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 23,
                Number: 7,
                IsExample: false,
                Title: 'El dicho “Zapatero a tus zapatos” quiere decir:',
                Options: [
                    { 1: 'Que el zapatero no debe abandonar sus zapatos' },
                    { 2: 'Que los zapateros no deben estar ociosos' },
                    { 3: 'Que debemos trabajar en lo que podamos hacer mejor' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 24,
                Number: 8,
                IsExample: false,
                Title:
          'El dicho “La cuña para que apriete tiene que ser del mismo palo” quiere decir:',
                Options: [
                    { 1: 'Que el palo sirve para apretar' },
                    { 2: 'Que las cuñas siempre son de madera' },
                    { 3: 'Nos exigen más las personas que nos conocen' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 25,
                Number: 9,
                IsExample: false,
                Title: 'Un acorazado de acero flota porque:',
                Options: [
                    { 1: 'La maquina lo hace flotar' },
                    { 2: 'Porque tiene grandes espacios huecos' },
                    { 3: 'Contiene algo de madera' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 26,
                Number: 10,
                IsExample: false,
                Title: 'Las plumas de las alas ayudan al pájaro a volar porque:',
                Options: [
                    { 1: 'Las alas ofrecen una amplia superficie ligera' },
                    { 2: 'Mantienen el aire afuera del cuerpo' },
                    { 3: 'Disminuye su peso' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 27,
                Number: 11,
                IsExample: false,
                Title: 'El dicho “Una golondrina no hace el verano” quiere decir:',
                Options: [
                    { 1: 'Que las golondrinas regresan' },
                    { 2: 'Que un simple dato no es suficiente' },
                    { 3: 'Que los pájaros se agregan a nuestros placeres del verano' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 3',
        TestNumber: 3,
        TotalQuestions: 30,
        Timer: 120,
        Instruction:
      'Cuando las dos palabras signifiquen lo mismo, elija el numero (1) de igual; cuando signifiquen lo opuesto, elija el numero (0)',
        Example: {
            Number: 0,
            IsExample: true,
            Title: 'Tirar - Arrojar',
            Options: [
                { 1: '1' },
                { 2: '0' },
            ],
            CorrectAnswer: 1,
        },
        Data: [
            {
                IdentityId: 28,
                Number: 1,
                IsExample: false,
                Title: 'Salado - Dulce:',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 29,
                Number: 2,
                IsExample: false,
                Title: 'Alegrarse - Regocijarse',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 30,
                Number: 3,
                IsExample: false,
                Title: 'Mayor - Menor',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 31,
                Number: 4,
                IsExample: false,
                Title: 'Sentarse - Pararse',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 32,
                Number: 5,
                IsExample: false,
                Title: 'Desperdiciar - Aprovechar',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 33,
                Number: 6,
                IsExample: false,
                Title: 'Conceder - Negar',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 34,
                Number: 7,
                IsExample: false,
                Title: 'Tonico - Estimulante',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 35,
                Number: 8,
                IsExample: false,
                Title: 'Rebajar - Denigrar',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 36,
                Number: 9,
                IsExample: false,
                Title: 'Prohibir - Permitir',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 37,
                Number: 10,
                IsExample: false,
                Title: 'Osado - Audaz',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 38,
                Number: 11,
                IsExample: false,
                Title: 'Arrebatado - Prudente',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 39,
                Number: 12,
                IsExample: false,
                Title: 'Obtuso - Agudo',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 40,
                Number: 13,
                IsExample: false,
                Title: 'Inepto - Experto',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 41,
                Number: 14,
                IsExample: false,
                Title: 'Esquivar - Huir',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 42,
                Number: 15,
                IsExample: false,
                Title: 'Rebelarse - Someterse',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 43,
                Number: 16,
                IsExample: false,
                Title: 'Monotonía - Variedad',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 44,
                Number: 17,
                IsExample: false,
                Title: 'Confortar - Consolar',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 45,
                Number: 18,
                IsExample: false,
                Title: 'Expeler - Retener',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 46,
                Number: 19,
                IsExample: false,
                Title: 'Dócil - Sumiso',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 47,
                Number: 20,
                IsExample: false,
                Title: 'Transitorio - Permanente',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 48,
                Number: 21,
                IsExample: false,
                Title: 'Seguridad - Riesgo',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 49,
                Number: 22,
                IsExample: false,
                Title: 'Aprobar - Objetar',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 50,
                Number: 23,
                IsExample: false,
                Title: 'Expeler - Arrojar',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 51,
                Number: 24,
                IsExample: false,
                Title: 'Engaño - Impostura',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 52,
                Number: 25,
                IsExample: false,
                Title: 'Mitigar - Apaciguar',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 53,
                Number: 26,
                IsExample: false,
                Title: 'Iniciar - Aplacar',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 54,
                Number: 27,
                IsExample: false,
                Title: 'Reverencia - Veneración',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 55,
                Number: 28,
                IsExample: false,
                Title: 'Sobriedad - Frugalidad',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 56,
                Number: 29,
                IsExample: false,
                Title: 'Aumentar - Menguar',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 57,
                Number: 30,
                IsExample: false,
                Title: 'Incitar - Instigar',
                Options: [
                    { 1: '1' },
                    { 2: '0' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 4',
        TestNumber: 4,
        TotalQuestions: 18,
        Timer: 180,
        Instruction:
      'Seleccione las dos palabras que indican algo que siempre tiene el sujeto.',
        Example: {
            Number: 0,
            IsExample: true,
            Title: 'Un hombre tiene siempre',
            Options: [
                { 1: 'Cuerpo, Dinero' },
                { 2: 'Gorra, Dinero' },
                { 3: 'Guantes, Boca' },
                { 4: 'Boca, Cuerpo' },
                { 5: 'Dinero, Guantes' },
            ],
            CorrectAnswer: '4',
        },
        Data: [
            {
                IdentityId: 58,
                Number: 1,
                IsExample: false,
                Title: 'Un CIRCULO tiene siempre',
                Options: [
                    { 1: 'Altura, Circunferencia' },
                    { 2: 'Circunferencia, Radio' },
                    { 3: 'Latitud, Radio' },
                    { 4: 'Longitud, Altura' },
                    { 5: 'Radio, Longitud' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 59,
                Number: 2,
                IsExample: false,
                Title: 'Un PÁJARO tiene siempre',
                Options: [
                    { 1: 'Huesos, Pico' },
                    { 2: 'Huevos, Nido' },
                    { 3: 'Pico, Canto' },
                    { 4: 'Huesos, Nido' },
                    { 5: 'Canto, Huesos' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 60,
                Number: 3,
                IsExample: false,
                Title: 'La MÚSICA tiene siempre',
                Options: [
                    { 1: 'Oyente, Ritmo' },
                    { 2: 'Piano, Violin' },
                    { 3: 'Ritmo, Sonido' },
                    { 4: 'Sonido, Oyente' },
                    { 5: 'Violin, Oyente' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 61,
                Number: 4,
                IsExample: false,
                Title: 'Un CABALLO tiene siempre',
                Options: [
                    { 1: 'Arnés, Casco' },
                    { 2: 'Casco, Cola' },
                    { 3: 'Herradura, Cola' },
                    { 4: 'Establo, Arnés' },
                    { 5: 'Cola, Arnés' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 62,
                Number: 5,
                IsExample: false,
                Title: 'Un JUEGO tiene siempre',
                Options: [
                    { 1: 'Cartas, Reglas' },
                    { 2: 'Multas, Jugadores' },
                    { 3: 'Jugadores, Cartas' },
                    { 4: 'Castigos, Reglas' },
                    { 5: 'Reglas, Jugadores' },
                ],
                CorrectAnswer: '5',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 63,
                Number: 6,
                IsExample: false,
                Title: 'Un OBJETO tiene siempre',
                Options: [
                    { 1: 'Calor, Sabor' },
                    { 2: 'Tamaño, Peso' },
                    { 3: 'Sabor, Peso' },
                    { 4: 'Valor, Tamaño' },
                    { 5: 'Peso, Valor' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 64,
                Number: 7,
                IsExample: false,
                Title: 'Una CONVERSACIÓN tiene siempre',
                Options: [
                    { 1: 'Acuerdos, Preguntas' },
                    { 2: 'Personas, Palabras' },
                    { 3: 'Preguntas, Personas' },
                    { 4: 'Ingenio, Acuerdos' },
                    { 5: 'Palabras, Preguntas' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 65,
                Number: 8,
                IsExample: false,
                Title: 'Una DEUDA implica siempre',
                Options: [
                    { 1: 'Acreedor, Deudor' },
                    { 2: 'Deudor, Pago' },
                    { 3: 'Interés, Deudor' },
                    { 4: 'Hipoteca, Pago' },
                    { 5: 'Pago, Interés' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 66,
                Number: 9,
                IsExample: false,
                Title: 'Un CIUDADANO tiene siempre',
                Options: [
                    { 1: 'País, Propiedad' },
                    { 2: 'Ocupación, Voto' },
                    { 3: 'Derechos, País' },
                    { 4: 'Propiedad, Voto' },
                    { 5: 'Voto, Derechos' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 67,
                Number: 10,
                IsExample: false,
                Title: 'Un MUSEO tiene siempre',
                Options: [
                    { 1: 'Animales, Visitantes' },
                    { 2: 'Orden, Visitantes' },
                    { 3: 'Colecciones, Orden' },
                    { 4: 'Minerales, Animales' },
                    { 5: 'Visitantes, Colecciones' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 68,
                Number: 11,
                IsExample: false,
                Title: 'Un COMPROMISO implica siempre',
                Options: [
                    { 1: 'Obligación, Respeto' },
                    { 2: 'Acuerdo, Obligación' },
                    { 3: 'Amistad, Respeto' },
                    { 4: 'Respeto, Acuerdo' },
                    { 5: 'Satisfacción, Amistad' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 69,
                Number: 12,
                IsExample: false,
                Title: 'Un BOSQUE tiene siempre',
                Options: [
                    { 1: 'Animales, Árboles' },
                    { 2: 'Flores, Sombras' },
                    { 3: 'Sombras, Animales' },
                    { 4: 'Maleza, Animales' },
                    { 5: 'Árboles, Sombras' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 70,
                Number: 13,
                IsExample: false,
                Title: 'Los OBSTÁCULOS tienen siempre',
                Options: [
                    { 1: 'Dificultad, Fracaso' },
                    { 2: 'Desaliento, Estímulo' },
                    { 3: 'Fracaso, Impedimento' },
                    { 4: 'Impedimento, Dificultad' },
                    { 5: 'Estímulo, Dificultad' },
                ],
                CorrectAnswer: '4',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 71,
                Number: 14,
                IsExample: false,
                Title: 'El ABORRECIMIENTO tiene siempre',
                Options: [
                    { 1: 'Aversión, Temor' },
                    { 2: 'Desagrado, Ira' },
                    { 3: 'Temor, Ira' },
                    { 4: 'Aversión, Desagrado' },
                    { 5: 'Timidez, Temor' },
                ],
                CorrectAnswer: '4',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 72,
                Number: 15,
                IsExample: false,
                Title: 'Una REVISTA tiene siempre',
                Options: [
                    { 1: 'Anuncios, Fotografías' },
                    { 2: 'Papel, Impresión' },
                    { 3: 'Fotografías, Grabados' },
                    { 4: 'Grabados, Impresión' },
                    { 5: 'Impresión, Grabados' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 73,
                Number: 16,
                IsExample: false,
                Title: 'La CONTROVERSIA implica siempre',
                Options: [
                    { 1: 'Argumentos, Público' },
                    { 2: 'Desacuerdos, Argumentos' },
                    { 3: 'Aversión, Resumen' },
                    { 4: 'Público, Desacuerdos' },
                    { 5: 'Resumen, Argumentos' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 74,
                Number: 17,
                IsExample: false,
                Title: 'Un BARCO tiene siempre',
                Options: [
                    { 1: 'Maquinaria, Cañones' },
                    { 2: 'Cañones, Velas' },
                    { 3: 'Quilla, Maquinaria' },
                    { 4: 'Timón, Maquinaria' },
                    { 5: 'Velas,Quilla' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 75,
                Number: 18,
                IsExample: false,
                Title: 'Un BANQUETE tiene siempre',
                Options: [
                    { 1: 'Alimentos, Anfitrión' },
                    { 2: 'Música, Personas' },
                    { 3: 'Personas, Alimentos' },
                    { 4: 'Discursos, Música' },
                    { 5: 'Anfitrión, Personas' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 5',
        TestNumber: 5,
        TotalQuestions: 12,
        Timer: 300,
        Instruction:
      'Encuentre las respuestas lo más pronto posible.',
        Example: {
            Number: 0,
            IsExample: true,
            Title: 'Cuanto es el 15% de 200?',
            Options: [
                { 1: '10' },
                { 2: '25' },
                { 3: '30' },
                { 4: '15' },
                { 5: '40' },
            ],
            CorrectAnswer: '3',
        },
        Data: [
            {
                IdentityId: 76,
                Number: 1,
                IsExample: false,
                Title: 'A 2 por 5 pesos, ¿Cuántos lápices puede comprarse con 50 pesos?',
                Options: [
                    { 1: '30' },
                    { 2: '20' },
                    { 3: '25' },
                    { 4: '10' },
                    { 5: '15' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 77,
                Number: 2,
                IsExample: false,
                Title: '¿Cuántas horas tardaría un automóvil en recorrer 660 kilómetros a la velocidad de 60 kilómetros por hora ?',
                Options: [
                    { 1: '12' },
                    { 2: '10' },
                    { 3: '13' },
                    { 4: '9' },
                    { 5: '11' },
                ],
                CorrectAnswer: '5',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 78,
                Number: 3,
                IsExample: false,
                Title: 'Si un hombre gana $200.00 diarios y gasta $140.00 ¿cuantos días tardaría en ahorrar $3,000.00?',
                Options: [
                    { 1: '50' },
                    { 2: '52' },
                    { 3: '45' },
                    { 4: '48' },
                    { 5: '70' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 79,
                Number: 4,
                IsExample: false,
                Title: 'Si dos pasteles cuestan $600.00 ¿Cuantos pesos cuesta la sexta parte de un pastel?',
                Options: [
                    { 1: '60' },
                    { 2: '10' },
                    { 3: '6' },
                    { 4: '100' },
                    { 5: '50' },
                ],
                CorrectAnswer: '5',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 80,
                Number: 5,
                IsExample: false,
                Title: '¿Cuantas veces más es 2 x 3 x 4 x 6, que 3 x 4 ?',
                Options: [
                    { 1: '12' },
                    { 2: '10' },
                    { 3: '5' },
                    { 4: '8' },
                    { 5: '15' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 81,
                Number: 6,
                IsExample: false,
                Title: '¿Cuanto es el 15% de 120.00?',
                Options: [
                    { 1: '15' },
                    { 2: '18' },
                    { 3: '16' },
                    { 4: '20' },
                    { 5: '10' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 82,
                Number: 7,
                IsExample: false,
                Title: 'El cuatro por ciento de $1,000.00 es igual al ocho por ciento ¿de qué cantidad?',
                Options: [
                    { 1: '800' },
                    { 2: '400' },
                    { 3: '100' },
                    { 4: '200' },
                    { 5: '500' },
                ],
                CorrectAnswer: '5',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 83,
                Number: 8,
                IsExample: false,
                Title: `La capacidad de un refrigerador rectangular es de 48 metros cúbicos. Si tiene seis metros de
        largo por cuatro de ancho ¿Cual es su altura?`,
                Options: [
                    { 1: '2' },
                    { 2: '3' },
                    { 3: '5' },
                    { 4: '4' },
                    { 5: '1' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 84,
                Number: 9,
                IsExample: false,
                Title: 'Si 7 hombres hacen un pozo de 40 metros en 2 dias, ¿Cuantos hombres se necesitan para hacerlo en medio día?',
                Options: [
                    { 1: '25' },
                    { 2: '20' },
                    { 3: '10' },
                    { 4: '28' },
                    { 5: '30' },
                ],
                CorrectAnswer: '4',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 85,
                Number: 10,
                IsExample: false,
                Title: 'A tiene $180.00; B tiene 2/3 de lo que tiene A, y C 1⁄2 de lo que tiene B, ¿Cuanto tienen todos juntos?',
                Options: [
                    { 1: '300' },
                    { 2: '400' },
                    { 3: '360' },
                    { 4: '350' },
                    { 5: '250' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 86,
                Number: 11,
                IsExample: false,
                Title: 'Si un hombre corre 100 mts en 10 segundos, ¿Cuantos metros correrá como promedio en 1/5 de segundo?',
                Options: [
                    { 1: '1.5' },
                    { 2: '2.5' },
                    { 3: '4' },
                    { 4: '2' },
                    { 5: '6' },
                ],
                CorrectAnswer: '4',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 87,
                Number: 12,
                IsExample: false,
                Title: 'Un hombre gasta 1⁄4 de su sueldo en casa y alimentos y 4/8 en otros gastos ¿Que tanto por ciento de su sueldo ahorra?',
                Options: [
                    { 1: '20%' },
                    { 2: '30%' },
                    { 3: '25%' },
                    { 4: '15%' },
                    { 5: '10%' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 6',
        TestNumber: 6,
        TotalQuestions: 20,
        Timer: 120,
        Instruction:
      'Elija (SI) o (NO) dependiendo de cual sea la contestación correcta',
        Example: {
            Number: 0,
            IsExample: true,
            Title: 'Se hace el carbón de la madera',
            Options: [
                { 1: 'SI' },
                { 2: 'NO' },
            ],
            CorrectAnswer: 1,
        },
        Data: [
            {
                IdentityId: 88,
                Number: 1,
                IsExample: false,
                Title: 'La higiene es esencial para la salud',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 1,
            },
            {
                IdentityId: 89,
                Number: 2,
                IsExample: false,
                Title: 'Los taquígrafos usan microscopio',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 90,
                Number: 3,
                IsExample: false,
                Title: 'Los tiranos son justos con sus inferiores',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 91,
                Number: 4,
                IsExample: false,
                Title: 'Las personas desamparadas están sujetas con frecuencia a la caridad',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 92,
                Number: 5,
                IsExample: false,
                Title: 'Las personas venerables son por lo común respetadas',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 93,
                Number: 6,
                IsExample: false,
                Title: 'Es el escorbuto un medicamento',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 94,
                Number: 7,
                IsExample: false,
                Title: 'Es la amonestación una clase de instrumento musical',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 95,
                Number: 8,
                IsExample: false,
                Title: 'Son los colores opacos preferidos para las banderas nacionales',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 96,
                Number: 9,
                IsExample: false,
                Title: 'Las cosas misteriosas son a veces pavorosas',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 97,
                Number: 10,
                IsExample: false,
                Title: 'Personas conscientes cometen alguna vez errores',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 98,
                Number: 11,
                IsExample: false,
                Title: 'Son carnívoros los carneros',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 99,
                Number: 12,
                IsExample: false,
                Title: 'Se dan asignaturas a los caballos',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 100,
                Number: 13,
                IsExample: false,
                Title: 'Las cartas anónimas llevan alguna vez firma de quien las escribe',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 101,
                Number: 14,
                IsExample: false,
                Title: 'Son discontinuos los sonidos intermitentes',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 102,
                Number: 15,
                IsExample: false,
                Title: 'Las enfermedades estimulan el buen juicio',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 103,
                Number: 16,
                IsExample: false,
                Title: 'Son siempre perversos los hechos premeditados',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 104,
                Number: 17,
                IsExample: false,
                Title: 'El contacto social tiende a reducir la timidez',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 105,
                Number: 18,
                IsExample: false,
                Title: 'Son enfermas las personas que tienen mal carácter',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 106,
                Number: 19,
                IsExample: false,
                Title: 'Se caracteriza generalmente el rencor por la persistencia',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 107,
                Number: 20,
                IsExample: false,
                Title: 'Meticuloso quiere decir lo mismo que cuidadoso',
                Options: [
                    { 1: 'SI' },
                    { 2: 'NO' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 7',
        TestNumber: 7,
        TotalQuestions: 20,
        Timer: 120,
        Instruction:
      'Elija la respuesta correspondiente a la palabra que complete correctamente la oración, tal como lo muestra el ejemplo',
        Example: {
            Number: 0,
            IsExample: true,
            Title: 'El OÍDO es a OÍR como el OJO es a',
            Options: [
                { 1: 'Mesa' },
                { 2: 'Ver' },
                { 3: 'Mano' },
                { 4: 'Jugar' },
            ],
            CorrectAnswer: 2,
        },
        Data: [
            {
                IdentityId: 108,
                Number: 1,
                IsExample: false,
                Title: 'ABRIGO es a USAR como PAN es a',
                Options: [
                    { 1: 'Comer' },
                    { 2: 'Hambre' },
                    { 3: 'Agua' },
                    { 4: 'Cocinar' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 109,
                Number: 2,
                IsExample: false,
                Title: 'SEMANA es a MES como MES es a',
                Options: [
                    { 1: 'Año' },
                    { 2: 'Hora' },
                    { 3: 'Minuto' },
                    { 4: 'Siglo' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 110,
                Number: 3,
                IsExample: false,
                Title: 'LEON es a ANIMAL como ROSA es a',
                Options: [
                    { 1: 'Olor' },
                    { 2: 'Hoja' },
                    { 3: 'Planta' },
                    { 4: 'Espina' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 111,
                Number: 4,
                IsExample: false,
                Title: 'LIBERTAD es a INDEPENDENCIA como CAUTIVERIO es a',
                Options: [
                    { 1: 'Negro' },
                    { 2: 'Esclavitud' },
                    { 3: 'Libre' },
                    { 4: 'Sufrir' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 112,
                Number: 5,
                IsExample: false,
                Title: 'DECIR es a DIJO como ESTAR es a',
                Options: [
                    { 1: 'Cantar' },
                    { 2: 'Estuvo' },
                    { 3: 'Hablando' },
                    { 4: 'Cantó' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 113,
                Number: 6,
                IsExample: false,
                Title: 'LUNES es a MARTES como VIERNES es a',
                Options: [
                    { 1: 'Semana' },
                    { 2: 'Jueves' },
                    { 3: 'Día' },
                    { 4: 'Sábado' },
                ],
                CorrectAnswer: 4,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 114,
                Number: 7,
                IsExample: false,
                Title: 'PLOMO es a PESADO como CORCHO es a',
                Options: [
                    { 1: 'Botella' },
                    { 2: 'Peso' },
                    { 3: 'Ligero' },
                    { 4: 'Flotar' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 115,
                Number: 8,
                IsExample: false,
                Title: 'ÉXITO es a ALEGRÍA como FRACASO es a',
                Options: [
                    { 1: 'Tristeza' },
                    { 2: 'Suerte' },
                    { 3: 'Fracasar' },
                    { 4: 'Trabajo' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 116,
                Number: 9,
                IsExample: false,
                Title: 'GATO es a tigre como PERRO es a',
                Options: [
                    { 1: 'Lobo' },
                    { 2: 'Ladrido' },
                    { 3: 'Mordida' },
                    { 4: 'Agarrar' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 117,
                Number: 10,
                IsExample: false,
                Title: '4 es a 16 como 5 es a',
                Options: [
                    { 1: '7' },
                    { 2: '45' },
                    { 3: '35' },
                    { 4: '25' },
                ],
                CorrectAnswer: 4,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 118,
                Number: 11,
                IsExample: false,
                Title: 'LLORAR es REÍR como TRISTE es a',
                Options: [
                    { 1: 'Muerte' },
                    { 2: 'Alegre' },
                    { 3: 'Mortaja' },
                    { 4: 'Doctor' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 119,
                Number: 12,
                IsExample: false,
                Title: 'VENENO es a MUERTE como ALIMENTO es a',
                Options: [
                    { 1: 'Comer' },
                    { 2: 'Pájaro' },
                    { 3: 'Vida' },
                    { 4: 'Malo' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 120,
                Number: 13,
                IsExample: false,
                Title: '1 es a 3 como 9 es a',
                Options: [
                    { 1: '18' },
                    { 2: '27' },
                    { 3: '36' },
                    { 4: '45' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 121,
                Number: 14,
                IsExample: false,
                Title: 'ALIMENTO es a HAMBRE como AGUA es a',
                Options: [
                    { 1: 'Beber' },
                    { 2: 'Claro' },
                    { 3: 'Sed' },
                    { 4: 'Puro' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 122,
                Number: 15,
                IsExample: false,
                Title: 'AQUÍ es a ALLÍ como ESTE es a',
                Options: [
                    { 1: 'Estos' },
                    { 2: 'Aquel' },
                    { 3: 'Ese' },
                    { 4: 'Entonces' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 123,
                Number: 16,
                IsExample: false,
                Title: 'TIGRE es a PELO como TRUCHA es a',
                Options: [
                    { 1: 'Agua' },
                    { 2: 'Pex' },
                    { 3: 'Escama' },
                    { 4: 'Nadar' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 124,
                Number: 17,
                IsExample: false,
                Title: 'PERVERTIDO es a DEPRAVADO como INCORRUPTO es a',
                Options: [
                    { 1: 'Patria' },
                    { 2: 'Honrado' },
                    { 3: 'Canción' },
                    { 4: 'Estudio' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 125,
                Number: 18,
                IsExample: false,
                Title: 'B es a D como SEGUNDO es a',
                Options: [
                    { 1: 'Tercero' },
                    { 2: 'Último' },
                    { 3: 'Cuarto' },
                    { 4: 'Posterior' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 126,
                Number: 19,
                IsExample: false,
                Title: 'ESTADO es a GOBERNADOR como EJERCITO es a',
                Options: [
                    { 1: 'Marina' },
                    { 2: 'Soldado' },
                    { 3: 'General' },
                    { 4: 'Sargento' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 127,
                Number: 20,
                IsExample: false,
                Title: 'SUJETO es a PREDICADOR como NOMBRE es a',
                Options: [
                    { 1: 'Pronombre' },
                    { 2: 'Adverbio' },
                    { 3: 'Verbo' },
                    { 4: 'Adjetivo' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 8',
        TestNumber: 8,
        TotalQuestions: 17,
        Timer: 180,
        Instruction: 'Las palabras de cada una de las siguientes oraciones están mezcladas. Ordene cada una de ellas. Si el significado de la oración es VERDADERO seleccione la letra V, si el significado de la oración es FALSO seleccione la letra F',
        Example: {
            Number: 0,
            IsExample: true,
            Title: 'Oír son los para oídos.',
            Options: [
                { 1: 'Verdadero' },
                { 2: 'Falso' },
            ],
            CorrectAnswer: 1,
        },
        Data: [
            {
                IdentityId: 128,
                Number: 1,
                IsExample: false,
                Title: 'Con crecen los niños edad la',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 129,
                Number: 2,
                IsExample: false,
                Title: 'Buena mar beber el agua de es',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 130,
                Number: 3,
                IsExample: false,
                Title: 'Lo es paz la guerra opuesto la a',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 131,
                Number: 4,
                IsExample: false,
                Title: 'Caballos automóvil un que camina los despacio más',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 132,
                Number: 5,
                IsExample: false,
                Title: 'Consejo a veces es buen seguir un difícil',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 133,
                Number: 6,
                IsExample: false,
                Title: 'Cuatrocientas todas páginas contienen libros los',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 134,
                Number: 7,
                IsExample: false,
                Title: 'Crecen las que fresas el más roble',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 135,
                Number: 8,
                IsExample: false,
                Title: 'Verdadera comparada no puede amistad ser',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 136,
                Number: 9,
                IsExample: false,
                Title: 'Envidia la perjudiciales gula son y la',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 137,
                Number: 10,
                IsExample: false,
                Title: 'Nunca acciones premiadas las deben buenas ser',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 138,
                Number: 11,
                IsExample: false,
                Title: 'Exteriores engañan nunca apariencias las nos',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 139,
                Number: 12,
                IsExample: false,
                Title: 'Nunca es hombre las que acciones demuestran un lo',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 140,
                Number: 13,
                IsExample: false,
                Title: 'Ciertas siempre muerte de causan clases enfermedades',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 141,
                Number: 14,
                IsExample: false,
                Title: 'Odio indeseables aversión sentimientos el son y la',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 142,
                Number: 15,
                IsExample: false,
                Title: 'Frecuentemente por juzgar podemos acciones hombres nosotros sus a los',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 143,
                Number: 16,
                IsExample: false,
                Title: 'una es sábana sarapes tan nunca los caliente como',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 144,
                Number: 17,
                IsExample: false,
                Title: 'Nunca que descuidados los tropiezan son',
                Options: [
                    { 1: 'Verdadero' },
                    { 2: 'Falso' },
                ],
                CorrectAnswer: 2,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 9',
        TestNumber: 9,
        TotalQuestions: 18,
        Timer: 120,
        Instruction:
      'Ponga la letra de la palabra que no corresponde con las demás del renglón.',
        Example: {
            Number: 0,
            IsExample: true,
            Title: '',
            Options: [
                { 1: 'Bala' },
                { 2: 'Cañon' },
                { 3: 'Pistola' },
                { 4: 'Espada' },
                { 5: 'Lápiz' },
            ],
            CorrectAnswer: 5,
        },
        Data: [
            {
                IdentityId: 145,
                Number: 1,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Saltar' },
                    { 2: 'Correr' },
                    { 3: 'Brincar' },
                    { 4: 'Pararse' },
                    { 5: 'Caminar' },
                ],
                CorrectAnswer: 4,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 146,
                Number: 2,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Monarquía' },
                    { 2: 'Comunista' },
                    { 3: 'Demócrata' },
                    { 4: 'Anarquista' },
                    { 5: 'Católico' },
                ],
                CorrectAnswer: 5,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 147,
                Number: 3,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Muerte' },
                    { 2: 'Duelo' },
                    { 3: 'Paseo' },
                    { 4: 'Pobreza' },
                    { 5: 'Tristeza' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 148,
                Number: 4,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Carpintero' },
                    { 2: 'Doctor' },
                    { 3: 'Abogado' },
                    { 4: 'Ingeniero' },
                    { 5: 'Profesor' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 149,
                Number: 5,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Cama' },
                    { 2: 'Silla' },
                    { 3: 'Plato' },
                    { 4: 'Sofá' },
                    { 5: 'Mesa' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 150,
                Number: 6,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Francisco' },
                    { 2: 'Santiago' },
                    { 3: 'Juan' },
                    { 4: 'Sara' },
                    { 5: 'Guillermo' },
                ],
                CorrectAnswer: 4,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 151,
                Number: 7,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Duro' },
                    { 2: 'Aspero' },
                    { 3: 'Liso' },
                    { 4: 'Suave' },
                    { 5: 'Dulce' },
                ],
                CorrectAnswer: 5,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 152,
                Number: 8,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Digestión' },
                    { 2: 'Oído' },
                    { 3: 'Vista' },
                    { 4: 'Olfato' },
                    { 5: 'Tacto' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 153,
                Number: 9,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Automóvil' },
                    { 2: 'Bicicleta' },
                    { 3: 'Guayin' },
                    { 4: 'Telégrafo' },
                    { 5: 'Tren' },
                ],
                CorrectAnswer: 4,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 154,
                Number: 10,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Abajo' },
                    { 2: 'Acá' },
                    { 3: 'Reciente' },
                    { 4: 'Arriba' },
                    { 5: 'Allá' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 155,
                Number: 11,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Hidalgo' },
                    { 2: 'Morelos' },
                    { 3: 'Bravo' },
                    { 4: 'Matamoros' },
                    { 5: 'Bolívar' },
                ],
                CorrectAnswer: 5,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 156,
                Number: 12,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Danés' },
                    { 2: 'Galgo' },
                    { 3: 'Buldog' },
                    { 4: 'Pequinés' },
                    { 5: 'Longhorm' },
                ],
                CorrectAnswer: 5,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 157,
                Number: 13,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Tela' },
                    { 2: 'Algodón' },
                    { 3: 'Lino' },
                    { 4: 'Seda' },
                    { 5: 'Lana' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 158,
                Number: 14,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Ira' },
                    { 2: 'Odio' },
                    { 3: 'Alegría' },
                    { 4: 'Piedad' },
                    { 5: 'Razonamiento' },
                ],
                CorrectAnswer: 5,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 159,
                Number: 15,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Edison' },
                    { 2: 'Franklin' },
                    { 3: 'Marconi' },
                    { 4: 'Fulton' },
                    { 5: 'Shakespeare' },
                ],
                CorrectAnswer: 5,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 160,
                Number: 16,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Mariposa' },
                    { 2: 'Halcón' },
                    { 3: 'Avestruz' },
                    { 4: 'Petirrojo' },
                    { 5: 'Golondrina' },
                ],
                CorrectAnswer: 1,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 161,
                Number: 17,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Dar' },
                    { 2: 'Prestar' },
                    { 3: 'Perder' },
                    { 4: 'Ahorrar' },
                    { 5: 'Derrochar' },
                ],
                CorrectAnswer: 4,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 162,
                Number: 18,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Australia' },
                    { 2: 'Cuba' },
                    { 3: 'Córcega' },
                    { 4: 'Irlanda' },
                    { 5: 'España' },
                ],
                CorrectAnswer: 3,
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 10',
        TestNumber: 10,
        TotalQuestions: 11,
        Timer: 240,
        Instruction:
      'Procure encontrar la forma en que están hechas las series. Elija los dos números que faltan.',
        Example: {
            Number: 0,
            IsExample: true,
            Title: '5 10 15 20',
            Options: [
                { 1: '30 40' },
                { 2: '25 30' },
                { 3: '30 45' },
            ],
            CorrectAnswer: '2',
        },
        Data: [
            {
                IdentityId: 163,
                Number: 1,
                IsExample: false,
                Title: '8 7 6 5 4 3 ',
                Options: [
                    { 1: '1 2' },
                    { 2: '2 1' },
                    { 3: '2 0' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 164,
                Number: 2,
                IsExample: false,
                Title: '3 8 13 18 23 28',
                Options: [
                    { 1: '33 38' },
                    { 2: '33 35' },
                    { 3: '33 37' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 165,
                Number: 3,
                IsExample: false,
                Title: '1 2 4 8 16 32',
                Options: [
                    { 1: '96 128' },
                    { 2: '64 96' },
                    { 3: '64 128' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 166,
                Number: 4,
                IsExample: false,
                Title: '8 8 6 6 4 4',
                Options: [
                    { 1: '3 3' },
                    { 2: '2 2' },
                    { 3: '5 5' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 167,
                Number: 5,
                IsExample: false,
                Title: '11.3/4  12  12.1/4  12.3/4',
                Options: [
                    { 1: '13.1/4 15' },
                    { 2: '14 15.1/4' },
                    { 3: '13.1/4 14' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 168,
                Number: 6,
                IsExample: false,
                Title: '8 9 12 13 16 17',
                Options: [
                    { 1: '18 19' },
                    { 2: '20 21' },
                    { 3: '18 20' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 169,
                Number: 7,
                IsExample: false,
                Title: '16 8 4 2 1 1/2',
                Options: [
                    { 1: '1/8 1/16' },
                    { 2: '1/4 1/8' },
                    { 3: '1/4 1/16' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 170,
                Number: 8,
                IsExample: false,
                Title: '31.3 40.3 49.3 58.3 67.3 76.3',
                Options: [
                    { 1: '85.3 94.3' },
                    { 2: '80.3 85.3' },
                    { 3: '87.3 96.3' },
                ],
                CorrectAnswer: '1',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 171,
                Number: 9,
                IsExample: false,
                Title: '3 5 3 6 5 7',
                Options: [
                    { 1: '6 7' },
                    { 2: '5 8' },
                    { 3: '6 8' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 172,
                Number: 10,
                IsExample: false,
                Title: '7 11 15 16 20 24 25 29',
                Options: [
                    { 1: '30 32' },
                    { 2: '33 34' },
                    { 3: '31 33' },
                ],
                CorrectAnswer: '2',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 173,
                Number: 11,
                IsExample: false,
                Title: '1/25 1/5 1 5',
                Options: [
                    { 1: '10 15' },
                    { 2: '10 20' },
                    { 3: '25 125' },
                ],
                CorrectAnswer: '3',
                UserAnswer: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
];

const disctest = [
    {
        Started: false,
        LastQuestion: 0,
        TestName: 'Prueba 2',
        TestNumber: 1,
        TotalQuestions: 28,
        Timer: 1200,
        Instruction:
    'En cada uno de los 28 grupos de palabras, escoja la palabra que más lo(a) represente y márquela en la columna MAS  y escoja una palabra que menos lo(a) represente y márquela en la columna MENOS.',
        Example: {
            Number: 0,
            IsExample: true,
            Title: '',
            Options: [],
            CorrectAnswer: '',
        },
        Data: [
            {
                IdentityId: 1,
                Number: 1,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Entusiasta' },
                    { 2: 'Rápido(a)' },
                    { 3: 'Lógico(a)' },
                    { 4: 'Apacible' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 2,
                Number: 2,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Cauteloso(a)' },
                    { 2: 'Decidido(a)' },
                    { 3: 'Receptivo(a)' },
                    { 4: 'Bondadoso(a)' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 3,
                Number: 3,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Amigable' },
                    { 2: 'Preciso(a)' },
                    { 3: 'Franco(a)' },
                    { 4: 'Tranquilo(a)' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 4,
                Number: 4,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Elocuente' },
                    { 2: 'Controlado(a)' },
                    { 3: 'Tolerante' },
                    { 4: 'Decisivo(a)' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 5,
                Number: 5,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Atrevido(a)' },
                    { 2: 'Concienzudo(a)' },
                    { 3: 'Comunicativo(a)' },
                    { 4: 'Moderado(a)' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 6,
                Number: 6,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Ameno(a)' },
                    { 2: 'Ingenioso(a)' },
                    { 3: 'Investigador(a)' },
                    { 4: 'Acepta Riesgos' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 7,
                Number: 7,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Expresivo(a)' },
                    { 2: 'Cuidadoso(a)' },
                    { 3: 'Dominante' },
                    { 4: 'Sensible' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 8,
                Number: 8,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Extrovertido(a)' },
                    { 2: 'Precavido(a)' },
                    { 3: 'Constante' },
                    { 4: 'Impaciente' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 9,
                Number: 9,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Discreto(a)' },
                    { 2: 'Complaciente' },
                    { 3: 'Encantador(a)' },
                    { 4: 'Insistente' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 10,
                Number: 10,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Valeroso(a)' },
                    { 2: 'Anima a los demas' },
                    { 3: 'Pacifico(a)' },
                    { 4: 'Perfeccionista' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 11,
                Number: 11,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Reservado(a)' },
                    { 2: 'Atento(a)' },
                    { 3: 'Osado(a)' },
                    { 4: 'Alegre' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 12,
                Number: 12,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Estimulante' },
                    { 2: 'Gentil' },
                    { 3: 'Perceptivo' },
                    { 4: 'Independiente' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 13,
                Number: 13,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Competitivo(a)' },
                    { 2: 'Considerado(a)' },
                    { 3: 'Alegre' },
                    { 4: 'Sagaz' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 14,
                Number: 14,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Meticuloso' },
                    { 2: 'Obediente' },
                    { 3: 'Ideas Firmes' },
                    { 4: 'Alentador(a)' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 15,
                Number: 15,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Popular' },
                    { 2: 'Reflexivo(a)' },
                    { 3: 'Tenaz' },
                    { 4: 'Calmado(a)' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 16,
                Number: 16,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Analítico(a)' },
                    { 2: 'Audaz' },
                    { 3: 'Leal' },
                    { 4: 'Promotor(a)' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 17,
                Number: 17,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Sociable' },
                    { 2: 'Paciente' },
                    { 3: 'Autosuficiente' },
                    { 4: 'Certero(a)' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 18,
                Number: 18,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Adaptable' },
                    { 2: 'Resuelto(a)' },
                    { 3: 'Prevenido(a)' },
                    { 4: 'Vivaz' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 19,
                Number: 19,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Agresivo(a)' },
                    { 2: 'Impetuoso(a)' },
                    { 3: 'Amistoso(a)' },
                    { 4: 'Discerniente' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 20,
                Number: 20,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'De trato fácil' },
                    { 2: 'Compasivo(a)' },
                    { 3: 'Cauto(a)' },
                    { 4: 'Habla Directo' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            }, {
                IdentityId: 21,
                Number: 21,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Evaluador(a)' },
                    { 2: 'Generoso(a)' },
                    { 3: 'Animado(a)' },
                    { 4: 'Persistente' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 22,
                Number: 22,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Impulsivo(a)' },
                    { 2: 'Cuida los detalles' },
                    { 3: 'Enérgico(a)' },
                    { 4: 'Tranquilo' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 23,
                Number: 23,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Sociable' },
                    { 2: 'Sistematico(a)' },
                    { 3: 'Vigoroso(a)' },
                    { 4: 'Tolerante' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 24,
                Number: 24,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Cautivador' },
                    { 2: 'Contento(a)' },
                    { 3: 'Exigente(a)' },
                    { 4: 'Apegado(a) a las normas' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 25,
                Number: 25,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Le agrada discutir' },
                    { 2: 'Metódico(a)' },
                    { 3: 'Comedido(a)' },
                    { 4: 'Desenvuelto(a)' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 26,
                Number: 26,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Jovial' },
                    { 2: 'Preciso(a)' },
                    { 3: 'Directo(a)' },
                    { 4: 'Ecuánime' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 27,
                Number: 27,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Inquieto(a)' },
                    { 2: 'Amable' },
                    { 3: 'Elocuente' },
                    { 4: 'Cuidadoso(a)' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
            {
                IdentityId: 28,
                Number: 28,
                IsExample: false,
                Title: '',
                Options: [
                    { 1: 'Prudente' },
                    { 2: 'Pionero(a)' },
                    { 3: 'Espontáneo(a)' },
                    { 4: 'Colaborador' },
                ],
                UserAnswerMore: '',
                UserAnswerLess: '',
                Timer: 20,
                RemainTimer: 0,
                Answered: false,
            },
        ],
    },
];

export { termantest, disctest };
