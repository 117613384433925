import React, { useContext, useEffect, useReducer } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLazyQuery, useSubscription } from '@apollo/client';
import moment from 'moment';
import AppContext from '../../../config/appContext';
import EmailMessageGraphql from '../../../servicesapollo/emailMessagesGraphql';
import { FetchPolicy, NotificationType } from '../../../enums/general';

import DocumentSender from '../documents/documentSender';

import './emailInbox.css';
import EmailDetail from './emailDetail';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

function EmailInbox() {
    const {
        showNotification,
        loggedUser, languageTitles,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const history = useHistory();
    const { pageNumber, pageResult } = useParams();

    const initState = {
        records: [],
        total: 0,
        isSearching: true,
        canPreviousPage: false,
        canNextPage: false,

        showAlertAction: false,
        whichAction: '',
        actionTitle: '',
        actionDescription: '',
        itemSelected: null,

        // params
        search: '',
        page: Number(pageNumber),
        limit: Number(pageResult) || 15,
        pages: 0,
        usePaging: true,
        openEmailPopup: false,
        folderType: 'received', // received, important, sent
        emailCount: null,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        isSearching, records, itemSelected,
        canPreviousPage, canNextPage, emailCount,
        search, page, limit, usePaging, pages, folderType,
    } = state;

    const dispatchEvent = (value, type = ACTION_TYPES.SET_STATE_VALUES) => {
        dispatch({
            type,
            value,
        });
    };

    const [getData, { loading, refetch }] = useLazyQuery(EmailMessageGraphql.GET_EMAIL_MESSAGES_PAGING, {
        onCompleted: (res) => {
            if (res.emailMessagesPaging) {
                const {
                    data, total, success, message, error, pages: totalPages,
                } = res.emailMessagesPaging;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoadMails,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatchEvent({
                    records: data,
                    total,
                    pages: totalPages,
                    isSearching: false,
                    canPreviousPage: page > 1,
                    canNextPage: page < totalPages,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getEmailCount] = useLazyQuery(EmailMessageGraphql.GET_EMAIL_COUNT, {
        variables: {
            userId: loggedUser.UserId,
        },
        onCompleted: (res) => {
            if (res.getEmailCount) {
                dispatchEvent({
                    emailCount: res.getEmailCount,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [setImportant] = useLazyQuery(EmailMessageGraphql.SET_IMPORTANT_EMAIL, {
        onCompleted: (res) => {
            if (res.setImportantEmail) {
                dispatchEvent({
                    isSearching: true,
                });
            }
        },
    });

    const callGetData = () => {
        if (loggedUser) {
            const newUrl = `/emails/page/${page}/show/${limit}`;
            history.push(newUrl);

            const filters = [];

            if (folderType === 'received') filters.push({ fieldName: 'ToUserId', value: String(loggedUser.UserId) });
            if (folderType === 'sent') filters.push({ fieldName: 'FromUserId', value: String(loggedUser.UserId) });
            if (folderType === 'no-read') {
                filters.push({ fieldName: 'ToUserId', value: String(loggedUser.UserId) });
                filters.push({ fieldName: 'Read', value: String(false) });
            }
            if (folderType === 'important') {
                filters.push({ fieldName: 'ToUserId', value: String(loggedUser.UserId) });
                filters.push({ fieldName: 'Important', value: String(true) });
            }

            getData({
                variables: {
                    paging: {
                        page,
                        limit,
                    },
                    usePaging,
                    search,
                    filters: filters.length === 0 ? null : filters,
                },
            });
        }
    };

    // eslint-disable-next-line no-unused-vars
    const setPage = (type) => {
        switch (type) {
        case 'first':
            dispatchEvent({
                page: 1,
                isSearching: true,
            });

            break;
        case 'previous':
            dispatchEvent({
                page: page - 1,
                isSearching: true,
            });
            break;
        case 'next':
            dispatchEvent({
                page: page + 1,
                isSearching: true,
            });
            break;
        case 'last':
            dispatchEvent({
                page: pages,
                isSearching: true,
            });
            break;
        default:
            break;
        }
    };

    useEffect(() => {
        if (isSearching && loggedUser.UserId > 0) {
            callGetData();
            getEmailCount();
        }
    }, [isSearching, loggedUser.UserId]);

    useSubscription(EmailMessageGraphql.SUBSCRIBE_EMAIL_MESSAGE, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { success } = subscriptionData?.data?.emailMessagesChanged;
            if (success) {
                dispatchEvent({
                    isSearching: true,
                });
            }
        },
    });

    const setFolderType = (folderT) => {
        dispatchEvent({
            folderType: folderT,
            isSearching: true,
            records: [],
            itemSelected: null,
        });
    };

    const onReadEmail = (item) => {
        dispatchEvent(
            {
                itemSelected: item,
            },
        );
    };

    const onImportant = (item) => {
        setImportant({
            variables: {
                userId: loggedUser.UserId,
                emailMessageId: item.EmailMessageId,
                important: !item.Important,
            },
        });
    };

    const enableButtons = loading;

    return (

        <div className="mailbox">
            <div className="mailbox-sidebar">
                <div className="mailbox-sidebar-header d-flex justify-content-center">
                    <Button
                        color="dark"
                        onClick={() => dispatchEvent({ openEmailPopup: true })}
                        className="ps-40px pe-40px btn-sm"
                    >
                        {languageTitles.mailInboxCreateEmail}
                    </Button>
                </div>
                <div className="mailbox-sidebar-content collapse d-lg-block">
                    <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                        <div className="nav-title"><b>FOLDERS</b></div>
                        <ul className="nav nav-inbox">
                            <li className={folderType === 'received' ? 'active' : ''}>
                                <span
                                    onClick={() => setFolderType('received')}
                                >
                                    <i className="fa fa-hdd fa-lg fa-fw me-2" />
                                    {' '}
                                    {languageTitles.mailInboxFolderAll}
                                    {' '}
                                    <span className="badge bg-gray-600 fs-10px rounded-pill ms-auto fw-bolder pt-4px pb-5px px-8px">{emailCount?.Total}</span>
                                </span>
                            </li>
                            <li className={folderType === 'no-read' ? 'active' : ''}>
                                <span
                                    onClick={() => setFolderType('no-read')}
                                >
                                    <i className="fa fa-hdd fa-lg fa-fw me-2" />
                                    {' '}
                                    {languageTitles.mailInboxFolderUnread}
                                    {' '}
                                    <span className="badge bg-gray-600 fs-10px rounded-pill ms-auto fw-bolder pt-4px pb-5px px-8px">{emailCount?.Unread}</span>
                                </span>
                            </li>
                            <li className={folderType === 'important' ? 'active' : ''}>
                                <span
                                    onClick={() => setFolderType('important')}
                                >
                                    <i className="fa fa-flag fa-lg fa-fw me-2" />
                                    {' '}
                                    {languageTitles.mailInboxFolderImportant}
                                    {' '}
                                    <span className="badge bg-gray-600 fs-10px rounded-pill ms-auto fw-bolder pt-4px pb-5px px-8px">{emailCount?.Important}</span>
                                </span>
                            </li>
                            <li className={folderType === 'sent' ? 'active' : ''}>
                                <span
                                    onClick={() => setFolderType('sent')}
                                >
                                    <i className="fa fa-envelope fa-lg fa-fw me-2" />
                                    {' '}
                                    {languageTitles.mailInboxFolderSent}
                                    {' '}
                                    <span className="badge bg-gray-600 fs-10px rounded-pill ms-auto fw-bolder pt-4px pb-5px px-8px">{emailCount?.Sent}</span>
                                </span>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </div>
            {!itemSelected
            && (
                <div className="mailbox-content">
                    <div className="mailbox-content-header">
                        <div className="btn-toolbar align-items-center">
                            {/* <div className="form-check me-2">
                            <input onChange={handleOnChange} type="checkbox" className="form-check-input" data-checked="email-checkbox" id="emailSelectAll" data-change="email-select-all" />
                            <label className="form-check-label" htmlFor="emailSelectAll" />
                        </div> */}
                            <Button
                                color="white"
                                size="sm"
                                className={`me-1 ${loading ? 'disabled' : ''}`}
                                onClick={() => refetch()}
                            >
                                { loading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-redo" /> }
                            </Button>
                            <div className="w-100 d-sm-none d-block mb-2 hide" data-email-action="divider" />
                            <div className="btn-group ms-auto">
                                <Button
                                    color="white"
                                    title={languageTitles.pageFirst}
                                    size="sm"
                                    className={`me-1 ${!canPreviousPage || enableButtons ? 'disabled' : ''}`}
                                    disabled={!canPreviousPage || enableButtons}
                                    onClick={() => setPage('first')}
                                >
                                    <i className="fa fa-angle-double-left" />
                                </Button>
                                <Button
                                    color="white"
                                    title={languageTitles.pagePrevious}
                                    size="sm"
                                    className={`me-1 ${!canPreviousPage || enableButtons ? 'disabled' : ''}`}
                                    disabled={!canPreviousPage || enableButtons}
                                    onClick={() => setPage('previous')}
                                >
                                    <i className="fa fa-angle-left" />
                                </Button>
                                <div style={{ alignSelf: 'center' }} className="me-1">
                                    <strong>
                                        Pagina
                                        {' '}
                                        {page}
                                        {' '}
                                        de
                                        {' '}
                                        {pages}
                                    </strong>
                                </div>
                                <Button
                                    color="white"
                                    title={languageTitles.pageNext}
                                    size="sm"
                                    className={`me-1 ${!canNextPage || enableButtons ? 'disabled' : ''}`}
                                    disabled={!canNextPage || enableButtons}
                                    onClick={() => setPage('next')}
                                >
                                    <i className="fa fa-angle-right" />
                                </Button>
                                <Button
                                    color="white"
                                    title={languageTitles.pageLast}
                                    size="sm"
                                    className={`${!canNextPage || enableButtons ? 'disabled' : ''}`}
                                    disabled={!canNextPage || enableButtons}
                                    onClick={() => setPage('last')}
                                >
                                    <i className="fa fa-angle-double-right" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="mailbox-content-body">
                        <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                            <ul className="list-group list-group-lg no-radius list-email">
                                {records.map((item) => (
                                    <li className={`list-group-item ${item.Read ? 'read' : 'unread'}`} key={item.EmailMessageId}>
                                        <div className="email-checkbox">
                                            <i
                                                className={`fa fa-star fa-20px ${item.Important ? 'color-important' : 'color-unimportant'}`}
                                                onClick={() => onImportant(item)}
                                            />
                                            {/* <div className="form-check">
                                                <input
                                                    onChange={handleOnChange}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    data-checked="email-checkbox"
                                                    id={`emailCheckbox${item.EmailMessageId}`}
                                                />
                                                <label className="form-check-label" htmlFor={`emailCheckbox${item.EmailMessageId}`} />
                                            </div> */}
                                        </div>
                                        <div className="email-user">
                                            <img src={(folderType === 'sent' ? item?.ToUser?.PictureURL : item?.FromUser?.PictureURL) ?? '/assets/img/user/user-13.jpg'} alt="" />
                                        </div>
                                        <div className="email-info">
                                            <div>
                                                <span
                                                    className="email-sender"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => onReadEmail(item)}
                                                >
                                                    {folderType === 'sent' ? item.ToUser.Email : item.FromUser.Email}

                                                </span>
                                                <span
                                                    className="email-title"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => onReadEmail(item)}
                                                >
                                                    {item.TitleMessage}

                                                </span>
                                                <span
                                                    className="email-desc"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => onReadEmail(item)}
                                                >
                                                    {item.DataMessage}
                                                </span>
                                                <span className="email-time">{moment(item.MessageDate).fromNow()}</span>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </PerfectScrollbar>
                    </div>
                    <div className="mailbox-content-footer d-flex align-items-center">
                        <div className="text-dark fw-bold">
                            {state.total}
                            {' '}
                            {languageTitles.mailInboxMessageLabel}
                        </div>
                        <div className="btn-group ms-auto">
                            <Button
                                color="white"
                                size="sm"
                                className={`btn-md me-1 ${!canPreviousPage || enableButtons ? 'disabled' : ''}`}
                                disabled={!canPreviousPage || enableButtons}
                                onClick={() => setPage('first')}
                            >
                                <i className="fa fa-angle-double-left" />
                            </Button>
                            <Button
                                color="white"
                                size="sm"
                                className={`btn-md me-1 ${!canPreviousPage || enableButtons ? 'disabled' : ''}`}
                                disabled={!canPreviousPage || enableButtons}
                                onClick={() => setPage('previous')}
                            >
                                <i className="fa fa-angle-left" />
                            </Button>
                            <div style={{ alignSelf: 'center' }} className="me-1">
                                <strong>
                                    {languageTitles.pageLabel}
                                    {' '}
                                    {page}
                                    {' '}
                                    {languageTitles.pageSeparator}
                                    {' '}
                                    {pages}
                                </strong>
                            </div>
                            <Button
                                color="white"
                                size="sm"
                                className={`btn-md me-1 ${!canNextPage || enableButtons ? 'disabled' : ''}`}
                                disabled={!canNextPage || enableButtons}
                                onClick={() => setPage('next')}
                            >
                                <i className="fa fa-angle-right" />
                            </Button>
                            <Button
                                color="white"
                                size="sm"
                                className={`btn-md ${!canNextPage || enableButtons ? 'disabled' : ''}`}
                                disabled={!canNextPage || enableButtons}
                                onClick={() => setPage('last')}
                            >
                                <i className="fa fa-angle-double-right" />
                            </Button>
                        </div>
                    </div>
                </div>
            ) }
            {itemSelected
            && (
                <EmailDetail
                    item={itemSelected}
                    onClose={() => {
                        dispatchEvent({
                            itemSelected: null,
                        });
                    }}
                />
            ) }
            {state.openEmailPopup && (
                <DocumentSender
                    showInPopup
                    openPopup={state.openEmailPopup}
                    onClosePopup={() => dispatchEvent({ openEmailPopup: false, emailsToSend: [] })}
                    comeFromEmails
                    // ids={emailsToSend.length === 0 ? recordsSelected : emailsToSend}
                />
            )}
        </div>
    );
}

export default EmailInbox;
