import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import AppContext from '../../config/appContext';

function TSFButton(props) {
    const { languageTitles } = useContext(AppContext);
    const {
        action,
        useIcon,
        useLabel,
        onClick,
        isDisabled,
        isWorking,
        isHiddden,
        className,
        label,
        iconClass,
        btnColor,
        title,
    } = props;

    const renderButton = () => {
        switch (action) {
        case 'save':
            return (
                <Button
                    color={btnColor || 'yellow'}
                    title={languageTitles.saveTitle}
                    onClick={(e) => ((isWorking || isDisabled || isHiddden) ? null : onClick(e))}
                    disabled={isDisabled || isWorking}
                    className={`${className} ${isHiddden ? 'hide' : ''}`}
                >
                    {useIcon && !isWorking
                    && <i className={`fa fa-save ${useLabel ? 'fa-fw' : ''}`} />}
                    {useIcon && isWorking
                    && <i className={`fa fa-spinner fa-spin ${useLabel ? 'fa-fw' : ''}`} />}
                    {useLabel && (label || languageTitles.saveTitle)}
                </Button>
            );
        case 'cancel':
            return (
                <Button
                    color={btnColor || 'white'}
                    title={languageTitles.cancelTitle}
                    onClick={(e) => ((isWorking || isDisabled || isHiddden) ? null : onClick(e))}
                    disabled={isDisabled || isWorking}
                    className={`${className} ${isHiddden ? 'hide' : ''}`}
                >
                    {useIcon && !isWorking
                    && <i className={`fa fa-times ${useLabel ? 'fa-fw' : ''}`} />}
                    {useIcon && isWorking
                    && <i className={`fa fa-spinner fa-spin ${useLabel ? 'fa-fw' : ''}`} />}
                    {useLabel && (label || languageTitles.cancelTitle)}
                </Button>
            );
        case 'refresh':
            return (
                <Button
                    color={btnColor || 'info'}
                    title={languageTitles.refreshTitle}
                    onClick={(e) => ((isWorking || isDisabled || isHiddden) ? null : onClick(e))}
                    disabled={isDisabled || isWorking}
                    className={`${className} ${isHiddden ? 'hide' : ''}`}
                >
                    {useIcon && !isWorking
                    && <i className={`fa fa-redo ${useLabel ? 'fa-fw' : ''}`} />}
                    {useIcon && isWorking
                    && <i className={`fa fa-spinner fa-spin ${useLabel ? 'fa-fw' : ''}`} />}
                    {useLabel && (label || languageTitles.refreshTitle)}
                </Button>
            );
        case 'delete':
            return (
                <Button
                    color={btnColor || 'danger'}
                    title={languageTitles.deleteTitle}
                    onClick={(e) => ((isWorking || isDisabled || isHiddden) ? null : onClick(e))}
                    disabled={isDisabled || isWorking}
                    className={`${className} ${isHiddden ? 'hide' : ''}`}
                >
                    {useIcon && !isWorking
                    && <i className={`fa fa-trash ${useLabel ? 'fa-fw' : ''}`} />}
                    {useIcon && isWorking
                    && <i className={`fa fa-spinner fa-spin ${useLabel ? 'fa-fw' : ''}`} />}
                    {useLabel && (label || languageTitles.deleteTitle)}
                </Button>
            );
        case 'custom':
            return (
                <Button
                    color={btnColor}
                    title={title}
                    onClick={(e) => ((isWorking || isDisabled || isHiddden) ? null : onClick(e))}
                    disabled={isDisabled || isWorking}
                    className={`${className} ${isHiddden ? 'hide' : ''}`}
                >
                    {useIcon && !isWorking
                    && <i className={`fa ${iconClass} ${useLabel ? 'fa-fw' : ''}`} />}
                    {useIcon && isWorking
                    && <i className={`fa fa-spinner fa-spin ${useLabel ? 'fa-fw' : ''}`} />}
                    {useLabel && label}
                </Button>
            );
        default:
            return (
                <Button
                    color="default"
                    onClick={onClick}
                />
            );
        }
    };

    return renderButton();
}

TSFButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    action: PropTypes.string,
    useIcon: PropTypes.bool,
    useLabel: PropTypes.bool,
    isHiddden: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isWorking: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    iconClass: PropTypes.string,
    btnColor: PropTypes.string,
    title: PropTypes.string,
};

TSFButton.defaultProps = {
    action: '',
    useIcon: true,
    useLabel: true,
    isHiddden: false,
    isDisabled: false,
    isWorking: false,
    className: 'me-1',
    label: '',
    iconClass: '',
    btnColor: '',
    title: '',
};

export default TSFButton;
