/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, { useEffect, useReducer, useContext } from 'react';
import * as Highcharts from 'highcharts';
import { useLazyQuery } from '@apollo/client';
import HighchartsReact from 'highcharts-react-official';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Panel, PanelBody, PanelHeader } from '../../panel/panel';
import { patronType, discInterpretation, disccorrectanswer } from './disctestinterpretation';
import TestApplicantGraphql from '../../../servicesapollo/testApplicantGraphql';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import AppContext from '../../../config/appContext';

import './psycoanalytics.css';
import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...state,
            record: action.value,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

function DiscAnalytics() {
    const { showNotification, languageTitles } = useContext(AppContext);
    const { id } = useParams();
    const { getErrorMessage } = useMessageHelper();

    const initState = {
        record: {},
        cidata: [
            {
                name: languageTitles.discChart1Serie1,
                y: 0,
            },
            {
                name: languageTitles.discChart1Serie2,
                y: 0,
            },
            {
                name: languageTitles.discChart1Serie3,
                y: 0,
            },
            {
                name: languageTitles.discChart1Serie4,
                y: 0,
            },
        ],
        patron: {},
        answered: [],
        answeredbad: [],
        noanswered: [],
        qansweredTotal: [],
        percentages: [],
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        record,
        cidata,
        patron,
    } = state;

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: languageTitles.discChart1Title,
        },
        xAxis: {
            categories: [
                languageTitles.discChart1Serie1,
                languageTitles.discChart1Serie2,
                languageTitles.discChart1Serie3,
                languageTitles.discChart1Serie4,
            ],
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: `${languageTitles.termanChart1TotalLabel}: {point.y}`,
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
            },
        },
        legend: {
            show: true,
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor:
      Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
        },
        series: [
            {
                colorByPoint: true,
                data: cidata,
            },
        ],
    };

    const getFinalValue = (segment, value) => {
        let finalvalue = 0;

        if (segment === 'd') {
            if (value < -7) { finalvalue = 1; } else if (value < -3 && value > -8) { finalvalue = 2; } else if (value < 0 && value > -4) { finalvalue = 3; } else if (value < 2 && value > -1) { finalvalue = 4; } else if (value > 1 && value < 5) { finalvalue = 5; } else if (value > 4 && value < 9) { finalvalue = 6; } else if (value > 8) { finalvalue = 7; }
        } else if (segment === 'i') {
            if (value < -7) { finalvalue = 1; } else if (value < -3 && value > -8) { finalvalue = 2; } else if (value < -1 && value > -4) { finalvalue = 3; } else if (value < 2 && value > -2) { finalvalue = 4; } else if (value > 1 && value < 4) { finalvalue = 5; } else if (value > 3 && value < 7) { finalvalue = 6; } else if (value > 6) { finalvalue = 7; }
        } else if (segment === 's') {
            if (value < -10) { finalvalue = 1; } else if (value < -6 && value > -11) { finalvalue = 2; } else if (value < -3 && value > -7) { finalvalue = 3; } else if (value < 0 && value > -4) { finalvalue = 4; } else if (value > -1 && value < 3) { finalvalue = 5; } else if (value > 2 && value < 8) { finalvalue = 6; } else if (value > 7) { finalvalue = 7; }
        } else if (segment === 'c') {
            if (value < -5) { finalvalue = 1; } else if (value < -2 && value > -6) { finalvalue = 2; } else if (value < 0 && value > -3) { finalvalue = 3; } else if (value < 3 && value > -1) { finalvalue = 4; } else if (value > 2 && value < 5) { finalvalue = 5; } else if (value > 4 && value < 9) { finalvalue = 6; } else if (value > 8) { finalvalue = 7; }
        }

        return finalvalue;

        // IF(T34<-5,1,IF(AND(T34<-2,T34>-6),2,IF(AND(T34<0,T34>-3),3,IF(AND(T34<3,T34>-1),4,IF(AND(T34>2,T34<5),5,IF(AND(T34>4,T34<9),6,IF(T34>8,7)))))))
        // IF(S34<-10,1,IF(AND(S34<-6,S34>-11),2,IF(AND(S34<-3,S34>-7),3,IF(AND(S34<0,S34>-4),4,IF(AND(S34>-1,S34<3),5,IF(AND(S34>2,S34<8),6,IF(S34>7,7)))))))
        // IF(R34<-7,1,IF(AND(R34<-3,R34>-8),2,IF(AND(R34<-1,R34>-4),3,IF(AND(R34<2,R34>-2),4,IF(AND(R34>1,R34<4),5,IF(AND(R34>3,R34<7),6,IF(R34>6,7)))))))
        // IF(Q34<-7,1,IF(AND(Q34<-3,Q34>-8),2,IF(AND(Q34<0,Q34>-4),3,IF(AND(Q34<2,Q34>-1),4,IF(AND(Q34>1,Q34<5),5,IF(AND(Q34>4,Q34<9),6,IF(Q34>8,7)))))))
    };

    const prepareDataChart = (currentData) => {
        let cidatal = [];
        let dr = 0;
        let ir = 0;
        let sr = 0;
        let cr = 0;

        for (let j = 0; j < currentData?.Data?.length; j += 1) {
            const qt = currentData.Data[j];
            const uam = Number(qt.UserAnswerMore);
            const ual = Number(qt.UserAnswerLess);

            if (qt.Answered && qt.UserAnswerMore !== '') {
                dr += (disccorrectanswer[qt.IdentityId].dam === uam ? 1 : 0) - (disccorrectanswer[qt.IdentityId].dal === ual ? 1 : 0);
                ir += (disccorrectanswer[qt.IdentityId].iam === uam ? 1 : 0) - (disccorrectanswer[qt.IdentityId].ial === ual ? 1 : 0);
                sr += (disccorrectanswer[qt.IdentityId].sam === uam ? 1 : 0) - (disccorrectanswer[qt.IdentityId].sal === ual ? 1 : 0);
                cr += (disccorrectanswer[qt.IdentityId].cam === uam ? 1 : 0) - (disccorrectanswer[qt.IdentityId].cal === ual ? 1 : 0);
            }
        }

        dr = getFinalValue('d', dr);
        ir = getFinalValue('i', ir);
        sr = getFinalValue('s', sr);
        cr = getFinalValue('c', cr);

        cidatal = [
            {
                name: languageTitles.discChart1Serie1,
                y: dr,
            },
            {
                name: languageTitles.discChart1Serie2,
                y: ir,
            },
            {
                name: languageTitles.discChart1Serie3,
                y: sr,
            },
            {
                name: languageTitles.discChart1Serie4,
                y: cr,
            },
        ];

        const currentPatron = `${dr}${ir}${sr}${cr}`;
        const patronFromList = patronType[currentPatron];

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                patron: patronFromList,
                cidata: cidatal,
            },
        });
    };

    const [getTestData, { loading }] = useLazyQuery(TestApplicantGraphql.GET_APPLICANTS_TEST, {
        onCompleted: (res) => {
            if (res.testApplicant) {
                const {
                    data, success, message, error,
                } = res.testApplicant;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                const jsonTest = JSON.parse(data.JsonTest);
                // setRecord(data.Record);
                prepareDataChart(jsonTest);

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        Name: data.Applicant?.Name,
                        LastName: data.Applicant?.LastName,
                        PictureURL: data.PictureURL,
                        JobPositionsLabels: data.JobPositionsLabels,
                    },
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const printReport = () => {
        window.print();
    };

    useEffect(() => {
        getTestData({
            variables: {
                id,
            },
        });
    }, []);

    return (
        <Panel>
            <PanelHeader>
                <div className="d-flex me-2 justify-content-end">
                    <TSFButton
                        action="refresh"
                        isWorking={loading}
                        onClick={() => getTestData({
                            variables: {
                                id,
                            },
                        })}
                    />
                    <Button
                        color="success"
                        className={`me-1 ${loading ? 'disabled' : ''}`}
                        onClick={printReport}
                    >
                        <i className="fa fa-print fa-fw" />
                        { languageTitles.printTitle }
                    </Button>
                </div>
            </PanelHeader>
            <PanelBody>
                <div className="row">
                    <div className="col-xl-1 col-xs-1">
                        <img alt="" src={(record.PictureURL === '' || record.PictureURL === null) ? '/assets/img/user/user-13.jpg' : record.PictureURL} className="img-rounded img-responsive" />
                    </div>
                    <div className="col-xl-11 col-xs-11">
                        <div className="col-xl-12">
                            {languageTitles.termanCandidateName}
                            :
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {record.Name}
                                {' '}
                                {record.LastName}
                            </span>
                        </div>
                        <div className="col-xl-12">
                            {languageTitles.termanApplyTo}
                            :
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>{record.JobPositionsLabels?.join(', ')}</span>
                        </div>
                    </div>
                    <div className="col-xl-12 col-xs-12">
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>
                    <br />
                </div>

                <div className="row" style={{ paddingTop: '20px' }}>
                    <div className="col-xl-12">
                        <div className="table-responsive table-resume table-striped">
                            <table className="table table-valign-middle mb-0">
                                <thead>
                                    <tr>
                                        <th className="p-l-15 bold">
                                            {languageTitles.discPatternLabel}
                                            {' '}
                                            {patron.patronName}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="p-l-15">
                                            <span className="bold">
                                                {languageTitles.discPatternLabel1}
                                                :
                                                {' '}
                                            </span>
                                            {' '}
                                            {discInterpretation[`E${patron.patronNumber}`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            <span className="bold">
                                                {languageTitles.discPatternLabel2}
                                                :
                                                {' '}
                                            </span>
                                            {' '}
                                            {discInterpretation[`M${patron.patronNumber}`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            <span className="bold">
                                                {languageTitles.discPatternLabel3}
                                                :
                                                {' '}
                                            </span>
                                            {' '}
                                            {discInterpretation[`J${patron.patronNumber}`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            <span className="bold">
                                                {languageTitles.discPatternLabel4}
                                                :
                                                {' '}
                                            </span>
                                            {' '}
                                            {discInterpretation[`I${patron.patronNumber}`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            <span className="bold">
                                                {languageTitles.discPatternLabel5}
                                                :
                                                {' '}
                                            </span>
                                            {' '}
                                            {discInterpretation[`S${patron.patronNumber}`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            <span className="bold">
                                                {languageTitles.discPatternLabel6}
                                                :
                                                {' '}
                                            </span>
                                            {' '}
                                            {discInterpretation[`A${patron.patronNumber}`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            <span className="bold">
                                                {languageTitles.discPatternLabel7}
                                                :
                                                {' '}
                                            </span>
                                            {' '}
                                            {discInterpretation[`B${patron.patronNumber}`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            <span className="bold">
                                                {languageTitles.discPatternLabel8}
                                                :
                                                {' '}
                                            </span>
                                            {' '}
                                            {discInterpretation[`T${patron.patronNumber}`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            <span className="bold">
                                                {languageTitles.discPatternLabel9}
                                                :
                                                {' '}
                                            </span>
                                            {' '}
                                            {discInterpretation[`SE${patron.patronNumber}`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            {discInterpretation[`O${patron.patronNumber}1`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            {discInterpretation[`O${patron.patronNumber}2`]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-l-15">
                                            {discInterpretation[`O${patron.patronNumber}3`]}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </PanelBody>
        </Panel>
    );
}

export default DiscAnalytics;
