/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import {
    DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { Link } from 'react-router-dom';
import AppContext from '../../../config/appContext';
import {
    Panel, PanelHeader, PanelFooter,
} from '../../panel/panel';

import { FetchPolicy, NotificationType, SubscriptionType } from '../../../enums/general';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';
import useDateHelper from '../../../helpers/dateHelper';

import './sidebar-right.css';
import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';

function SidebarRight() {
    const {
        languageTitles, showNotification, applicantMessageId, loggedUser,
        setApplicantMessageId, toggleAppSidebarEndMobile,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const { formatDate } = useDateHelper();

    const initState = {
        DataMessage: '',
        Importance: '',
        records: [],
    };

    const [currentState, setCurrentState] = useState(initState);

    const {
        records, DataMessage, Importance,
    } = currentState;

    const [getMessages, { loading }] = useLazyQuery(ApplicantsGraphql.GET_MESSAGE_BY_APPLICANT, {
        onCompleted: (res) => {
            if (res.messagesPaging) {
                const {
                    data, success, message, error,
                } = res.messagesPaging;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoadMessages,
                        NotificationType.DANGER,
                    );
                    return;
                }

                setCurrentState({
                    ...initState,
                    records: data,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (applicantMessageId > 0) {
            getMessages({
                variables: {
                    usePaging: false,
                    filters: [{
                        fieldName: 'ApplicantId',
                        value: String(applicantMessageId),
                    }],
                    sort: [
                        {
                            fieldName: 'MessageDate',
                            dir: 'DESC',
                        },
                    ],
                },
            });
        }
    }, [applicantMessageId]);

    const handleInputChange = (name, value) => {
        setCurrentState({
            ...currentState,
            [name]: value,
        });
    };

    const setImportance = (colorSelected) => {
        handleInputChange('Importance', colorSelected);
    };

    const [saveMessage, { loading: isSaving }] = useMutation(ApplicantsGraphql.SAVE_MESSAGE, {
        onCompleted: (mutationData) => {
            if (mutationData.addMessage) {
                const {
                    success, message, error, data,
                } = mutationData.addMessage;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS);

                setCurrentState({
                    ...currentState,
                    Importance: '',
                    DataMessage: '',
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSave = () => {
        saveMessage({
            variables: {
                record: {
                    ApplicantId: applicantMessageId,
                    UserId: loggedUser.UserId,
                    DataMessage,
                    Importance,
                    UserName: loggedUser.FullName,
                },
            },
        });
    };

    useSubscription(ApplicantsGraphql.MESSAGE_CHANGED, {
        onSubscriptionData: ({ subscriptionData }) => {
            const {
                success, id, action, data,
            } = subscriptionData?.data?.messagesChanged;
            if (success) {
                const currentRecord = JSON.parse(data);
                if (action === SubscriptionType.ADDED && Number(applicantMessageId) === Number(currentRecord.ApplicantId)) {
                    records.unshift(currentRecord);
                    setCurrentState({
                        ...currentState,
                        records,
                    });
                }
            }
        },
    });

    return (
        <>
            <div id="sidebar-right" className="app-sidebar app-sidebar-end">
                <div className="app-sidebar-content h-100 bg-light">
                    <Panel className="bg-light">
                        <PanelHeader showBackHistory={false}>
                            <div className="d-flex">
                                {languageTitles.candidateMessageLabel}
                                {' '}
                                <TSFButton
                                    action="cancel"
                                    className="btn btn-xs ms-auto"
                                    label={languageTitles.closeTitle}
                                    onClick={() => setApplicantMessageId(0)}
                                />
                            </div>
                        </PanelHeader>
                        <PerfectScrollbar className="chats applicantComments" options={{ suppressScrollX: true }}>
                            {records?.map((element) => (
                                <div className="chats-item start" key={element.MessageId}>
                                    <span className="date-time">{formatDate(element.MessageDate)}</span>
                                    <Link to="/dashboard/v2" className="name">{element.UserName}</Link>
                                    <div className={`message bg-${element.Importance} btn-${element.Importance}`}>
                                        {element.DataMessage}
                                    </div>
                                </div>
                            ))}
                        </PerfectScrollbar>
                        <PanelFooter>
                            <div>
                                <div className="input-group mb-2">
                                    <textarea
                                        rows="4"
                                        name="DataMessage"
                                        className="form-control"
                                        placeholder="Escribir mensaje"
                                        onChange={(e) => handleInputChange('DataMessage', e.target.value)}
                                        value={DataMessage}
                                    />
                                </div>
                                <div className="input-group">
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret color={Importance === '' ? 'default' : Importance}>
                                            Importancia
                                            {' '}
                                            <span className="caret" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem className="btn btn-default mb-1" onClick={() => setImportance('')}>&nbsp;</DropdownItem>
                                            <DropdownItem className="btn btn-primary mb-1" onClick={() => setImportance('primary')}>&nbsp;</DropdownItem>
                                            <DropdownItem className="btn btn-info mb-1" onClick={() => setImportance('info')}>&nbsp;</DropdownItem>
                                            <DropdownItem className="btn btn-warning mb-1" onClick={() => setImportance('warning')}>&nbsp;</DropdownItem>
                                            <DropdownItem className="btn btn-danger mb-1" onClick={() => setImportance('danger')}>&nbsp;</DropdownItem>
                                            <DropdownItem className="btn btn-success mb-1" onClick={() => setImportance('success')}>&nbsp;</DropdownItem>
                                            <DropdownItem className="btn btn-yellow mb-1" onClick={() => setImportance('yellow')}>&nbsp;</DropdownItem>
                                            <DropdownItem className="btn btn-green" onClick={() => setImportance('green')}>&nbsp;</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <TSFButton
                                        action="save"
                                        isDisabled={isSaving || loading || !DataMessage}
                                        isWorking={isSaving}
                                        className="ms-auto"
                                        onClick={onSave}
                                    />
                                </div>
                            </div>
                        </PanelFooter>
                    </Panel>
                </div>
            </div>
            <div className="app-sidebar-bg app-sidebar-end" />
            <div className="app-sidebar-mobile-backdrop app-sidebar-end">
                <Link to="/" onClick={toggleAppSidebarEndMobile} className="stretched-link" />
            </div>
        </>
    );
}

export default SidebarRight;
