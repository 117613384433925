/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Button } from 'reactstrap';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import UserGraphql from '../../../servicesapollo/userGraphql';

import useCustomLocalStorage from '../../hooks/useCustomLocalStorage';
import AppContext from '../../../config/appContext';

import './login.css';
import FooterCopyRight from './footerCopyRight';
import LogoBlack from '../../layout/logo/logoBlack';
import useMessageHelper from '../../../helpers/messageHelper';

function LoginCompany() {
    const { showNotification, languageTitles } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    const { setToken, setUserAuth, clearLocalStorage } = useCustomLocalStorage();

    const initRecord = {
        email: '',
        password: '',
        rememberMe: false,
    };

    const [record, setRecord] = useState(initRecord);

    const [loginCompany, { loading }] = useLazyQuery(UserGraphql.LOGIN_COMPANY, {
        onCompleted: (res) => {
            if (res.authenticateCompany) {
                const {
                    success, data, error, message,
                } = res.authenticateCompany;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(error?.length > 0 ? error : languageTitles.errorWhenAuthenticate, NotificationType.DANGER);
                    return;
                }

                setToken(data.Token);
                setUserAuth({
                    UserId: data.UserId,
                    Email: data.Email,
                });
                window.location.href = '/#/home';
                window.location.reload();
            }
        },
        onError: (serverError) => {
            showNotification(getErrorMessage(serverError), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onSave = (e) => {
        e.preventDefault();

        loginCompany({
            variables: {
                ux: record.email,
                px: record.password,
            },
        });
    };

    const handleInputChange = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setRecord({
            ...record,
            [target.name]: value,
        });
    };

    useEffect(() => {
        clearLocalStorage();
    }, []);

    return (
        <div className="login login-with-news-feed">
            <div className="news-feed">
                <div style={{
                    backgroundColor: '#00000080',
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    zIndex: 1,
                }}
                />
                <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/jobs-bg.jpg)' }} />
            </div>
            <div className="login-container">
                <div className="login-header">
                    <div className="brand">
                        <div className="align-items-center">
                            <LogoBlack customWidth="40" />
                        </div>
                    </div>
                </div>
                <div className="login-content">
                    <form onSubmit={onSave} className="fs-13px">
                        <div className="form-floating mb-15px">
                            <input
                                type="email"
                                className="form-control h-45px fs-13px"
                                name="email"
                                id="emailAddress"
                                value={record.email}
                                onChange={handleInputChange}
                                required
                            />
                            <label htmlFor="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">{languageTitles.loginEmail}</label>
                        </div>
                        <div className="form-floating mb-15px">
                            <input
                                type="password"
                                className="form-control h-45px fs-13px"
                                name="password"
                                id="password"
                                value={record.password}
                                onChange={handleInputChange}
                                required
                            />
                            <label htmlFor="password" className="d-flex align-items-center fs-13px text-gray-600">{languageTitles.loginPassword}</label>
                        </div>
                        <div className="mb-15px">
                            <Button
                                color="yellow"
                                className="btn btn-success d-block h-45px w-100 btn-lg fs-14px"
                                disabled={loading}
                                type="submit"
                            >
                                {loading && <i className="fa fa-spinner fa-spin fa-fw" /> }
                                {loading ? languageTitles.loginBtnAuthLabel : languageTitles.loginBtnLabel}
                            </Button>
                        </div>
                        <div className="mb-40px pb-40px text-inverse">
                            {languageTitles.loginForgotPasswordLabel}
                            {' '}
                            <Link to="/forgotpassword">
                                {' '}
                                {languageTitles.loginForgotPasswordLinkLabel}
                            </Link>
                        </div>
                        <hr className="bg-gray-600 opacity-2" />
                        <FooterCopyRight />
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginCompany;
