/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useContext, useReducer } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import {
    Panel, PanelHeader, PanelBody,
} from '../../panel/panel';
import AppContext from '../../../config/appContext';
import {
    FetchPolicy, NotificationType, Catalogs, LineActions,
} from '../../../enums/general';

import ErrorPage from '../extraPages/error';
import TSFButton from '../../actionButtons/tsfButton';

import 'react-datetime/css/react-datetime.css';
import './companyFull.css';
import CompanyGraphql from '../../../servicesapollo/companyGraphql';
import UploadFile from '../uploadFile/uploadFile';
import Catalog from '../../catalog/catalog-dropdown';
import useMessageHelper from '../../../helpers/messageHelper';
import { pdfOptions } from '../../../helpers/pdfHelper';

export const ACTION_TYPES = {
    SET_RECORDS: 'setRecords',
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
    ADD_JOB_POSITIONS: 'addJobPositions',
    UPDATE_JOB_POSITIONS: 'updateJobPositions',
    REMOVE_JOB_POSITIONS: 'removeJobPositions',
    SET_PRINT_COMPANY_JOB_POSITION: 'setCompanyJobPositionReport',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORDS: {
        return {
            ...state,
            records: action.value,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.ADD_JOB_POSITIONS: {
        return {
            ...state,
            records: action.value,
            indexJobPosition: state.indexJobPosition - 1,
        };
    }
    case ACTION_TYPES.UPDATE_JOB_POSITIONS: {
        return {
            ...state,
            records: action.value,
        };
    }
    case ACTION_TYPES.REMOVE_JOB_POSITIONS: {
        return {
            ...state,
            records: action.value,
        };
    }
    case ACTION_TYPES.SET_PRINT_COMPANY_JOB_POSITION: {
        const {
            printCompanyJobPositionId, fireEvent,
        } = action.value;

        if (fireEvent) fireEvent(printCompanyJobPositionId);
        return { ...state, printCompanyJobPositionId };
    }
    default:
        return state;
    }
};

const initState = {
    isEditing: false,
    isDirty: false,
    idUploading: '',
    idDeleting: 0,
    indexJobPosition: -1,
    printCompanyJobPositionId: 0,
    records: [{
        Status: 'Saved',
        CompanyJobPositionId: -1,
        JobPositionTitle: '',
        JobPositionNumber: 0,
        SalaryPerHour: '',
        JobDescription: '',
        JobDescriptionDocumentUrl: '',
        MiscellaneousConditions: '',
        EmploymentStatusId: null,
        MonthsEmploymentPerYear: '',
        EducationalLevelId: null,
        YearsOfExperience: '',
        IsDirty: false,
    }],
};

const timerForUpdateInfo = 2000;
let saveInfoTimeout = null;

function CompanyJobPositions() {
    const { companyId } = useParams();
    const history = useHistory();

    const {
        showNotification, languageTitles, loggedUser,
        coreCatalogs, currentLanguage,
        setIsDirtyData, converToPdf,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    if ((Number(companyId) !== Number(loggedUser.EntityId) && loggedUser.UserType !== 'companyadmin')
    || !loggedUser.UserType.includes('company')) return <ErrorPage />;

    const [state, dispatch] = useReducer(reducer, { ...initState, isEditing: loggedUser.IsCompanyUser });

    const {
        records, isEditing, isDirty, printCompanyJobPositionId,
    } = state;

    const [getCompany, { loading: isLoading }] = useLazyQuery(CompanyGraphql.GET_COMPANY_JOB_POSITIONS, {
        onCompleted: (res) => {
            if (res.companyJobPositions) {
                const {
                    data, success, message, error,
                } = res.companyJobPositions;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }
                let _records = data;

                if (data?.length === 0) {
                    _records = initState.records;
                }

                dispatch({
                    type: ACTION_TYPES.SET_RECORDS,
                    value: _records,
                });
                setIsDirtyData(false);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const callGetData = () => {
        getCompany({
            variables: {
                companyId: Number(companyId),
            },
        });
    };

    const mutationQuery = Number(companyId) > 0 ? CompanyGraphql.UPDATE_COMPANY_JOB_POSITIONS : CompanyGraphql.CREATE_COMPANY_JOB_POSITIONS;
    const [saveRecord, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (res) => {
            if (res?.addCompanyJobPosition || res?.updateCompanyJobPosition) {
                const {
                    success, message, error, data,
                } = res?.addCompanyJobPosition || res?.updateCompanyJobPosition;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                const valueToState = {
                    isEditing: loggedUser.IsCompanyUser || false,
                    isDirty: false,
                    isSaving: false,
                    records: data,
                };

                // const atLeastOneAdded = records?.filter((c) => c.Status === LineActions.NEW || c.Status === LineActions.DELETED)?.length > 0;

                // if (atLeastOneAdded) valueToState.records = data;

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: valueToState,
                });
                setIsDirtyData(false);
                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS, 500);
                // callGetData();
            }
        },
        onError: (error) => {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isDirty: true,
                },
            });
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const [printCompanyJobTemplate, { loading: printingTemplate }] = useLazyQuery(CompanyGraphql.PRINT_COMPANY_JOB_TEMPLATE, {
        onCompleted: (res) => {
            if (res.printCompanyJobTemplate) {
                const pdfBase64 = res.printCompanyJobTemplate;
                const cjob = records.find((c) => c.CompanyJobPositionId === printCompanyJobPositionId);
                converToPdf(pdfBase64, `Job-Position-${cjob.JobPositionTitle.replace(/\s/g, '-')}-${uuidv4()}.pdf`);
                dispatch({
                    type: ACTION_TYPES.SET_PRINT_COMPANY_JOB_POSITION,
                    value: {
                        printCompanyJobPositionId: 0,
                    },
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
            dispatch({
                type: ACTION_TYPES.SET_PRINT_COMPANY_JOB_POSITION,
                value: {
                    printCompanyJobPositionId: 0,
                },
            });
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const exportCompanyJobTemplate = (companyJobPositionId) => {
        printCompanyJobTemplate({
            variables: {
                id: companyJobPositionId,
                options: JSON.stringify(pdfOptions),
            },
        });
    };

    const OnPrintCompanyJobTemplate = (companyJobPositionId) => {
        dispatch({
            type: ACTION_TYPES.SET_PRINT_COMPANY_JOB_POSITION,
            value: {
                printCompanyJobPositionId: companyJobPositionId,
                fireEvent: exportCompanyJobTemplate,
            },
        });
    };

    const onSave = async (currentRecords) => {
        await saveRecord({
            variables: {
                companyId: Number(companyId),
                records: currentRecords.map((item) => {
                    const { IsDirty, ...rest } = item;
                    return {
                        ...rest,
                        JobPositionNumber: Number(item.JobPositionNumber ?? 0),
                    };
                }),
            },
        });
    };

    const onActionButtons = (e, action) => {
        e.preventDefault();

        if (action === 'edit') {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isEditing: true,
                },
            });
        } else if (action === 'cancel') {
            if (isDirty) {
                callGetData();
            }

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isEditing: false,
                    isDirty: false,
                },
            });
            setIsDirtyData(false);
        } else if (action === 'save') {
            onSave(records);
        } else if (action === 'refresh') {
            callGetData();

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isEditing: loggedUser.IsCompanyUser || false,
                    isDirty: false,
                },
            });
            setIsDirtyData(false);
        }
    };

    const onAddItemSection = (e) => {
        e.preventDefault();

        const newJobPosition = {
            CompanyJobPositionId: state.indexJobPosition - 1,
            JobPositionTitle: '',
            JobPositionNumber: 0,
            SalaryPerHour: '',
            JobDescription: '',
            JobDescriptionDocumentUrl: '',
            MiscellaneousConditions: '',
            YearsOfExperience: '',
            EducationalLevelId: null,
            EmploymentStatusId: null,
            MonthsEmploymentPerYear: '',
            IsDirty: true,
            Status: LineActions.NEW,
        };

        records.push(newJobPosition);

        onSave([newJobPosition]);

        dispatch({
            type: ACTION_TYPES.ADD_JOB_POSITIONS,
            value: records,
        });
    };

    const onRemoveItemSection = (e, item) => {
        e.preventDefault();

        const newRecords = records.map((c) => {
            const exp = { ...c };
            if (Number(exp.CompanyJobPositionId) === Number(item.CompanyJobPositionId)) {
                exp.Status = LineActions.DELETED;
                exp.IsDirty = true;
            }
            return exp;
        });

        const recordsToDelete = newRecords.filter((c) => c.Status === LineActions.DELETED);

        onSave(recordsToDelete);

        dispatch({
            type: ACTION_TYPES.REMOVE_JOB_POSITIONS,
            value: newRecords,
        });
    };

    const OnChangeItemSection = (data, companyJobPositionId, fieldName) => {
        const newRecords = records.map((exp) => {
            const item = { ...exp };
            if (Number(exp.CompanyJobPositionId) === Number(companyJobPositionId)) {
                item[fieldName] = data;
                item.Status = exp.CompanyJobPositionId > 0 ? LineActions.UPDATED : LineActions.NEW;
                item.IsDirty = true;
            }
            return item;
        });

        const recordsToUpdate = newRecords.filter((c) => c.Status === LineActions.UPDATED || c.Status === LineActions.NEW);

        clearTimeout(saveInfoTimeout);

        saveInfoTimeout = setTimeout(() => {
            onSave(recordsToUpdate);
        }, timerForUpdateInfo);

        dispatch({
            type: ACTION_TYPES.UPDATE_JOB_POSITIONS,
            value: newRecords,
        });
    };

    useEffect(() => {
        if (Number(companyId) > 0) {
            callGetData();
        }
    }, [companyId]);

    // useEffect(() => {
    //     // eslint-disable-next-line no-undef
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [records.length]);

    if (!records) return <h1 className="page-header row m-20px">{languageTitles.recordNotFound}</h1>;

    const itemjobPositions = records?.filter((item) => item.Status !== LineActions.DELETED).map((item, i) => (
        <div key={item.CompanyJobPositionId} className="col-md-12 mb-20px">
            <div className="border-1 card-inverse card">
                <div className="row card-body">
                    <div className="col-md-6">
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-4">{languageTitles.jobPositionTitle}</label>
                            <div className="col-md-8">
                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{item.JobPositionTitle}</label>
                                <div className={isEditing ? '' : 'hide'}>
                                    <input
                                        autoFocus={i === records?.filter((item1) => item1.Status !== LineActions.DELETED).length - 1}
                                        type="text"
                                        className="form-control"
                                        name="JobPositionTitle"
                                        required
                                        onChange={(e) => OnChangeItemSection(e.target.value, item.CompanyJobPositionId, e.target.name)}
                                        value={item.JobPositionTitle}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-4">{languageTitles.jobPositionNumber}</label>
                            <div className="col-md-8">
                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{item.JobPositionNumber}</label>
                                <div className={isEditing ? '' : 'hide'}>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="JobPositionNumber"
                                        required
                                        onChange={(e) => OnChangeItemSection(e.target.value, item.CompanyJobPositionId, e.target.name)}
                                        value={item.JobPositionNumber}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-4">{languageTitles.salaryPerHour}</label>
                            <div className="col-md-8">
                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{item.SalaryPerHour}</label>
                                <div className={isEditing ? '' : 'hide'}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="SalaryPerHour"
                                        required
                                        onChange={(e) => OnChangeItemSection(e.target.value, item.CompanyJobPositionId, e.target.name)}
                                        value={item.SalaryPerHour}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-4">{languageTitles.employmentStatusId}</label>
                            <div className="col-md-8">
                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                    {
                                        coreCatalogs.find((c) => Number(c.orden) === 5
                                        && c.category.toLowerCase() === Catalogs.EMPLOYMENT_STATUS.toLowerCase()
                                        && String(c.value) === String(item.EmploymentStatusId))?.[currentLanguage]
                                    }
                                </label>
                                <div className={isEditing ? '' : 'hide'}>
                                    <Catalog
                                        returnSelectedValue={(data, options) => OnChangeItemSection(data, item.CompanyJobPositionId, options.name)}
                                        name="EmploymentStatusId"
                                        catalogType={Catalogs.EMPLOYMENT_STATUS}
                                        value={String(item.EmploymentStatusId)}
                                    />
                                </div>
                            </div>
                        </div>
                        { coreCatalogs.find((c) => Number(c.orden) === 5
                            && c.category.toLowerCase() === Catalogs.EMPLOYMENT_STATUS.toLowerCase()
                            && String(c.value) === String(item.EmploymentStatusId))?.Es.toLowerCase() === 'estacional' && (
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.monthsEmploymentPerYear}</label>
                                <div className="col-md-8">
                                    <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{item.MonthsEmploymentPerYear}</label>
                                    <div className={isEditing ? '' : 'hide'}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="MonthsEmploymentPerYear"
                                            placeholder={languageTitles.monthsEmploymentPerYear}
                                            required
                                            onChange={(e) => OnChangeItemSection(e.target.value, item.CompanyJobPositionId, e.target.name)}
                                            value={item.MonthsEmploymentPerYear}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-4">{languageTitles.educationalLevelId}</label>
                            <div className="col-md-8">
                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                    {
                                        coreCatalogs.find((c) => Number(c.orden) === 5
                                        && c.category.toLowerCase() === Catalogs.EDUCATIONAL_LEVEL.toLowerCase()
                                        && String(c.value) === String(item.EducationalLevelId))?.[currentLanguage]
                                    }
                                </label>
                                <div className={isEditing ? '' : 'hide'}>
                                    <Catalog
                                        returnSelectedValue={(data, options) => OnChangeItemSection(data, item.CompanyJobPositionId, options.name)}
                                        name="EducationalLevelId"
                                        catalogType={Catalogs.EDUCATIONAL_LEVEL}
                                        value={String(item.EducationalLevelId)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-4">{languageTitles.yearsOfExperience}</label>
                            <div className="col-md-8">
                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{item.YearsOfExperience}</label>
                                <div className={isEditing ? '' : 'hide'}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="YearsOfExperience"
                                        placeholder={languageTitles.yearsOfExperience}
                                        required
                                        onChange={(e) => OnChangeItemSection(e.target.value, item.CompanyJobPositionId, e.target.name)}
                                        value={item.YearsOfExperience}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-4">{languageTitles.jobDescription}</label>
                            <div className="col-md-8">
                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{item.JobDescription}</label>
                                <div className={isEditing ? '' : 'hide'}>
                                    <textarea
                                        rows={4}
                                        type="text"
                                        className="form-control"
                                        name="JobDescription"
                                        required
                                        onChange={(e) => OnChangeItemSection(e.target.value, item.CompanyJobPositionId, e.target.name)}
                                        value={item.JobDescription}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-4">{languageTitles.jobDescriptionDocumentUrl}</label>
                            <div className="col-md-8">
                                {item.JobDescriptionDocumentUrl && (
                                    <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                        {item.JobDescriptionDocumentUrl}
                                        {' '}
                                        <a href={item.JobDescriptionDocumentUrl} target="_blank" rel="noreferrer">
                                            {languageTitles.donwloadTitle}
                                        </a>
                                    </label>
                                )}
                                <div className={isEditing ? '' : 'hide'}>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="JobDescriptionDocumentUrl"
                                            disabled
                                            value={item.JobDescriptionDocumentUrl}
                                        />
                                        <UploadFile
                                            additionalInfo={{ companyId: Number(companyId) }}
                                            graphql={CompanyGraphql.UPLOAD_COMPANY_JOB_FILE}
                                            resultNode="uploadCompanyJobFile"
                                            acceptFiles=".pdf"
                                            uploading={(val) => {
                                                dispatch({
                                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                                    value: {
                                                        isUploading: val,
                                                    },
                                                });
                                            }}
                                            afterUpload={(val) => OnChangeItemSection(val?.data?.FileUrl, item.CompanyJobPositionId, 'JobDescriptionDocumentUrl')}
                                        />
                                        { item.JobDescriptionDocumentUrl
                                        && (
                                            <a href={item.JobDescriptionDocumentUrl} target="_blank" rel="noreferrer">
                                                <Button
                                                    color="info"
                                                    className="me-1"
                                                    disabled={!item.JobDescriptionDocumentUrl}
                                                >
                                                    <i className="fa fa-download" />
                                                </Button>
                                            </a>
                                        ) }
                                        {!item.JobDescriptionDocumentUrl
                                        && (
                                            <Button
                                                color="info"
                                                className="me-1"
                                                disabled
                                            >
                                                <i className="fa fa-download" />
                                            </Button>
                                        ) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-4">{languageTitles.miscellaneousConditions}</label>
                            <div className="col-md-8">
                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{item.MiscellaneousConditions}</label>
                                <div className={isEditing ? '' : 'hide'}>
                                    <textarea
                                        rows={4}
                                        type="text"
                                        className="form-control"
                                        name="MiscellaneousConditions"
                                        placeholder={languageTitles.miscellaneousConditions}
                                        required
                                        onChange={(e) => OnChangeItemSection(e.target.value, item.CompanyJobPositionId, e.target.name)}
                                        value={item.MiscellaneousConditions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col ms-15px">
                    {
                        isEditing && (
                            <TSFButton
                                action="custom"
                                btnColor="danger"
                                className="mb-3 me-3"
                                useIcon
                                iconClass="fas fa-ban"
                                label={languageTitles.removeButton}
                                onClick={(e) => onRemoveItemSection(e, item)}
                            />
                        )
                    }
                    {item.CompanyJobPositionId > 0
                        && (
                            <TSFButton
                                action="custom"
                                btnColor="info"
                                className="mb-3 me-3"
                                useIcon
                                iconClass="fas fa-print"
                                label={languageTitles.printJobBtnTitle}
                                isWorking={printingTemplate && printCompanyJobPositionId === item.CompanyJobPositionId}
                                onClick={() => OnPrintCompanyJobTemplate(item.CompanyJobPositionId)}
                            />
                        )}
                    {isEditing && i === records?.filter((item1) => item1.Status !== LineActions.DELETED).length - 1
                    && (
                        <TSFButton
                            action="custom"
                            btnColor="primary"
                            className="mb-3"
                            label={languageTitles.addButton}
                            useIcon
                            iconClass="fas fa-plus"
                            onClick={onAddItemSection}
                        />
                    )}
                    {!isDirty && i === records?.filter((item1) => item1.Status !== LineActions.DELETED).length - 1
                        && (
                            <div className="float-end">
                                <TSFButton
                                    action="custom"
                                    btnColor="success"
                                    useIcon
                                    iconClass="fas fa-share"
                                    label={languageTitles.nextSectionWorkforceBtn}
                                    onClick={() => { history.push(`/employees/company/${Number(companyId)}`); }}
                                />
                            </div>
                        )}
                </div>
            </div>
        </div>
    ));

    return (
        <Panel>
            <PanelHeader title={languageTitles.companyTabIdentificationJob}>
                <ButtonGroup className="pull-right">
                    <TSFButton
                        action="save"
                        isWorking={isSaving}
                        isHiddden={!(isEditing && isDirty)}
                        onClick={(e) => onActionButtons(e, 'save')}
                    />
                    <TSFButton
                        action="refresh"
                        isWorking={isLoading}
                        isDisabled={isSaving}
                        onClick={(e) => onActionButtons(e, 'refresh')}
                    />
                </ButtonGroup>
            </PanelHeader>
            <PanelBody>
                <div className="card-body">
                    <div className="row row-space-2 mb-3">
                        {itemjobPositions}
                    </div>
                </div>
            </PanelBody>
        </Panel>
    );
}

export default CompanyJobPositions;
