import toPascalCase from '../../util/messages';

/* eslint-disable no-undef */
const USER_AUTH = '43l50sj098';
const TOKEN_AUTH = 'tkyh8j49BJWo';
const LANG_KEY = 'kl23lkjl';
const COMPANY_SET = 'I1235YLKJH';

const useCustomLocalStorage = () => {
    const getKeyLocalStorage = (key) => {
        const value = localStorage.getItem(key);
        if (value) return value;

        return '';
    };

    const addKeyLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    const removeKeyLocalStorage = (key) => {
        localStorage.removeItem(key);
    };

    const clearLocalStorage = () => {
        removeKeyLocalStorage(USER_AUTH);
        removeKeyLocalStorage(TOKEN_AUTH);
        removeKeyLocalStorage(COMPANY_SET);
    };

    const setUserAuth = (value) => {
        localStorage.setItem(USER_AUTH, JSON.stringify(value));
    };

    const getUserAuth = () => {
        const value = localStorage.getItem(USER_AUTH);
        if (value) return JSON.parse(value);

        return null;
    };

    const removeUserAuth = () => {
        localStorage.removeItem(USER_AUTH);
    };

    const setToken = (val) => {
        localStorage.setItem(TOKEN_AUTH, val);
    };

    const getToken = () => localStorage.getItem(TOKEN_AUTH);

    const setLanguageLS = (value) => localStorage.setItem(LANG_KEY, value);
    const getLanguageLS = () => toPascalCase(localStorage.getItem(LANG_KEY));

    const setCompany = (val) => {
        localStorage.setItem(COMPANY_SET, val);
    };

    const getCompany = () => localStorage.getItem(COMPANY_SET);

    return {
        getKeyLocalStorage,
        addKeyLocalStorage,
        removeKeyLocalStorage,
        clearLocalStorage,
        setUserAuth,
        getUserAuth,
        removeUserAuth,
        setToken,
        getToken,
        getLanguageLS,
        setLanguageLS,
        setCompany,
        getCompany,
    };
};

export default useCustomLocalStorage;
