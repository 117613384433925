import { gql } from '@apollo/client';

export default class CompanyGraphql {
    static CREATE_COMPANY = gql`
    mutation addCompany($record: CompanyInput!) {
      addCompany(record: $record){
        success,
        message,
        error,
        data{
          CompanyId
        }
      }
    }
  `;

    static UPDATE_COMPANY = gql`
    mutation updateCompany($record: CompanyInput!) {
        updateCompany(record: $record){
        success,
        message,
        error,
        data{
          CompanyId
        }
      }
    }
  `;

    static DELETE_COMPANY = gql`
    mutation deleteCompany($id: ID!) {
        deleteCompany(id: $id){
        success,
        message,
        error,
      }
    }
  `;

    static GET_COMPANY_LIST = gql`
    query companies(
      $paging: PaginationInput
      $sort: [SortField]
      $filters: [Filters]
      $search: String
      $usePaging: Boolean
    ) {
      companies(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
        success
        message
        error
        total
        data {
          CompanyId
          CompanyName
          ImageUrl
          CoordinatesPhone
          MainAddressStreet
          ContactName
          CompanyProfile
          CoordinatesWebsiteLink
          UserId
          User {
              Email
          }
        }
      }
  }`;

    static GET_COMPANY = gql`
query company($id: ID!) {
  company(id: $id) {
    success
    message
    error
    data {
      CompanyId
      CompanyName
      ImageUrl
      CoordinatesPhone
      MainAddressStreet
      ContactName
      CoordinatesWebsiteLink
    }
  }
}`;

    static UPLOAD_COMPANY_PICTURE = gql`
    mutation uploadPictureCompany($file: FileUpload!, $companyId: Int!) {
      uploadPictureCompany(file: $file, companyId: $companyId){
        success
        message
        error
        data{
          FileUrl
        }
      }
    }
  `;

    static GET_COMPANY_FULL = gql`
query company($id: ID!) {
  company(id: $id) {
    success
    message
    error
    data {
      CompanyId
      CompanyName
      ImageUrl
      CompanyProfile
      MainAddressStreet
      MainAddressOffice
      MainAddressCity
      MainAddressProvince
      MainAddressPostalCode
      IsJobSameMainAddress
      JobAddressStreet
      JobAddressOffice
      JobAddressCity
      JobAddressProvince
      JobAddressPostalCode
      HaveAnotherJobAddress
      OtherJobAddressStreet
      OtherJobAddressOffice
      OtherJobAddressCity
      OtherJobAddressProvince
      OtherJobAddressPostalCode
      CoordinatesPhone
      CoordinatesFax
      CoordinatesWebsiteLink
      ContactName
      ContactTitle
      ContactPhone
      ContactPoste
      ContactEmail
      HasSecondaryContact
      SecondaryContactName
      SecondaryContactTitle
      SecondaryContactPhone
      SecondaryContactPoste
      SecondaryContactEmail
      CompanySigner
      CompanySignerEmail
      AccountsPayableResponsible
      AccountsPayableResponsibleEmail
      CompanyIsFranchise
      CompanyOrganizationTypeId
      CompanyFoundationDate
      CompanyBusinessNumber
      CompanyNEQ
      CompanyRevenueOverflow
      CompanyAllowEDSC
      CompanyEDSCDescription
      CompanyJobPositionSyndicate
      CompanyJobPositionSyndicateName
      CompanyJobPositionSyndicateLocal
      HasInternationalRecruitmentBefore
      InternationalRecruitmentHistory
      HasEmployeeBenefit
      BenefitDisabilityInsurance
      BenefitDentalInsurance
      BenefitPensionPlan
      BenefitMedicalInsurance
      BenefitWorkWear
      BenefitWorkShoes
      BenefitVisionGlasses
      BenefitOther
      HasAdditionalVacation
      AdditionalVacationDescription
      HoursPerWeekAllowed
      OvertimeHoursAllowedAfterId
      OvertimeHoursAllowedAfterDetail
      QuarterScheduleId
      QuarterScheduleDetail
      SalaryPaymentFrecuencyId
      HasBonus
      BonusId
      BonusDetail
      WorkShiftInit
      WorkShiftEnd
      WorkShiftComment
      EmployeeMustProvideToolsOrEquipment
      BenefitDisabilityInsuranceDetail,
      BenefitDentalInsuranceDetail,
      BenefitPensionPlanDetail,
      BenefitMedicalInsuranceDetail,
      BenefitWorkWearDetail,
      BenefitWorkShoesDetail,
      BenefitVisionGlassesDetail,
      EmployeeMustProvideToolsOrEquipmentDetail,
    }
  }
}`;

    static PRINT_COMPANY_JOB_TEMPLATE = gql`
    query printCompanyJobTemplate($id: Int!, $options: String!) {
      printCompanyJobTemplate(id: $id, options: $options)
    }`;

    static UPLOAD_COMPANY_JOB_FILE = gql`
    mutation uploadCompanyJobFile($file: FileUpload!, $companyId: Int!) {
      uploadCompanyJobFile(file: $file, companyId: $companyId){
        success
        message
        error
        data{
          FileUrl
        }
      }
    }
    `;

    static GET_COMPANY_JOB_POSITIONS = gql`
    query companyJobPositions($companyId: ID!) {
      companyJobPositions(companyId: $companyId) {
        success
        message
        error
        data {
          CompanyJobPositionId
          JobPositionTitle
          JobPositionNumber
          SalaryPerHour
          JobDescription
          JobDescriptionDocumentUrl
          MiscellaneousConditions
          EmploymentStatusId,
          MonthsEmploymentPerYear,
          EducationalLevelId,
          YearsOfExperience
        }
      }
    }`;

    static CREATE_COMPANY_JOB_POSITIONS = gql`
    mutation addCompanyJobPosition($companyId: ID!, $records: [CompanyJobPositionInput!]!) {
      addCompanyJobPosition(companyId: $companyId, records: $records){
        success,
        message,
        error,
        data {
          CompanyJobPositionId
          JobPositionTitle
          JobPositionNumber
          SalaryPerHour
          JobDescription
          JobDescriptionDocumentUrl
          MiscellaneousConditions
          EmploymentStatusId,
          MonthsEmploymentPerYear,
          EducationalLevelId,
          YearsOfExperience
        }
      }
    }
    `;

    static UPDATE_COMPANY_JOB_POSITIONS = gql`
    mutation updateCompanyJobPosition($companyId: ID!, $records: [CompanyJobPositionInput!]!) {
      updateCompanyJobPosition(companyId: $companyId, records: $records){
        success,
        message,
        error,
        data {
          CompanyJobPositionId
          JobPositionTitle
          JobPositionNumber
          SalaryPerHour
          JobDescription
          JobDescriptionDocumentUrl
          MiscellaneousConditions
          EmploymentStatusId,
          MonthsEmploymentPerYear,
          EducationalLevelId,
          YearsOfExperience
        }
      }
    }
    `;

    static GET_COMPANY_EMPLOYEE = gql`
query company($id: ID!) {
  company(id: $id) {
    success
    message
    error
    data {
      CompanyEmployees
    }
  }
}`;

    static GET_COMPANY_GOVERMENT_FILES = gql`
query company($id: ID!) {
  company(id: $id) {
    success
    message
    error
    data {
      FileGoverment1
      FileGoverment2
      FileGoverment3
      FileGoverment4
      FileGoverment5
      FileGoverment6
      FileGoverment7
      AdditionalFile1
      AdditionalFile2
      AdditionalFile3
      AdditionalFile4
      AdditionalFile5
      AdditionalFile6
      AcceptDisclaimer
      GovermentFilesData
    }
  }
}`;

    static DELETE_COMPANY_GOVERMENT_FILES = gql`
mutation deleteCompanyGovermentFile($companyId: ID!, $columnName: String!) {
  deleteCompanyGovermentFile(companyId: $companyId, columnName: $columnName){
    success,
    message,
    error,
    data,
  }
}
`;

    static UPLOAD_COMPANY_GOVERMENT_FILES = gql`
mutation companyUploadGovermentFile($file: FileUpload!, $companyId: Int!, $columnName: String!) {
  companyUploadGovermentFile(file: $file, companyId: $companyId, columnName: $columnName){
    success
    message
    error
    data
  }
}
`;

    static NOTIFYADMIN = gql`
    query notifyAdmin($companyId: ID!) {
      notifyAdmin(companyId: $companyId) {
        success
        message
        error
      }
    }`;

    static PRINT_COMPANY_FULL_REPORT = gql`
    query printCompanyFullReport($id: Int!, $options: String!) {
      printCompanyFullReport(id: $id, options: $options)
    }`;
}
