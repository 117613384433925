/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import AppContext from '../../config/appContext';

function Catalog(props) {
    const { currentLanguage, coreCatalogs } = useContext(AppContext);

    const {
        returnSelectedValue, catalogType,
        name, value, placeholder,
        localData, useStringAskey,
        isNullable, menuPosition,
    } = props;

    const onChange = (selectedOption, options) => {
        returnSelectedValue(selectedOption?.value ?? (isNullable ? null : 0), options);
    };

    const data = localData || coreCatalogs.filter((c) => Number(c.orden) === 5
    && String(c.category.toLowerCase()) === catalogType.toLowerCase()).map((c) => (
        {
            value: useStringAskey ? c[currentLanguage] : c.value,
            label: c[currentLanguage],
        }));

    const selectedValue = data.filter((c) => String(c.value) === String(value))
        .map((c) => (
            {
                value: c.value,
                label: c.label,
            }
        ));

    return (
        <Select
            isClearable
            name={name}
            onChange={(selectedOption, options) => onChange(selectedOption, options)}
            options={data}
            value={selectedValue}
            placeholder={placeholder}
            menuPlacement={menuPosition}
        />
    );
}

Catalog.propTypes = {
    catalogType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    returnSelectedValue: PropTypes.func.isRequired,
    localData: PropTypes.arrayOf(PropTypes.object),
    useStringAskey: PropTypes.bool,
    isNullable: PropTypes.bool,
    menuPosition: PropTypes.string,
};

Catalog.defaultProps = {
    placeholder: '',
    value: null,
    localData: null,
    useStringAskey: false,
    isNullable: true,
    menuPosition: 'bottom',
};

export default Catalog;
