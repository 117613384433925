/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button } from 'reactstrap';
import UserGraphql from '../../../servicesapollo/userGraphql';
import ToastApp from '../../toast/toast';
import { FetchPolicy, NotificationType } from '../../../enums/general';

import FooterCopyRight from './footerCopyRight';
import HeaderTitle from './headerTitle';
import AppContext from '../../../config/appContext';
import useMessageHelper from '../../../helpers/messageHelper';

function ResetPassword() {
    const { emx, vcx } = useParams();
    const history = useHistory();
    const { showNotification, languageTitles } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const [currentState, setCurrentState] = useState({
        newPassword: '',
        newPasswordConfirm: '',
        sweetAlertSuccess: false,
    });

    const {
        newPassword, newPasswordConfirm, sweetAlertSuccess,
    } = currentState;

    const handleInputChange = (e) => {
        const { target: { name, value } } = e;
        setCurrentState({
            ...currentState,
            [name]: value,
        });
    };

    const [resetPassword, { loading: isSaving }] = useMutation(UserGraphql.RESET_PASSWORD, {
        onCompleted: (mutationData) => {
            if (mutationData?.resetPassword) {
                const {
                    success, message, error,
                } = mutationData?.resetPassword;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(error?.length > 0 ? error : languageTitles.errorWhenChangePassword, NotificationType.DANGER);
                    return;
                }

                setCurrentState({
                    ...currentState,
                    sweetAlertSuccess: true,
                });
            }
        },
        onError: (serverError) => {
            showNotification(getErrorMessage(serverError), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onSave = (e) => {
        e.preventDefault();

        if (isSaving) return;

        if (newPassword !== newPasswordConfirm) {
            showNotification(languageTitles.userPasswordDontMatch, NotificationType.DANGER);
            return;
        }

        resetPassword({
            variables: {
                record: {
                    emx,
                    vcx,
                    npx: newPassword,
                },
            },
        });
    };

    return (
        <>
            <div className="login login-v2 fw-bold">
                <div className="login-cover">
                    <div className="login-cover-img" style={{ backgroundImage: 'url(/assets/img/login-bg/quebec_city.jpg)' }} />
                    <div className="login-cover-bg" />
                </div>

                <div className="login-container">
                    <HeaderTitle />

                    <div className="login-content">
                        <form onSubmit={onSave}>
                            <div className="form-floating mb-20px">
                                <input
                                    type="password"
                                    className="form-control fs-13px h-45px border-0"
                                    placeholder="Contraseña"
                                    name="newPassword"
                                    id="newPassword"
                                    value={newPassword}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="newPassword" className="d-flex align-items-center text-gray-600 fs-13px">Nueva Contraseña</label>
                            </div>
                            <div className="form-floating mb-20px">
                                <input
                                    type="password"
                                    className="form-control fs-13px h-45px border-0"
                                    placeholder="Contraseña"
                                    name="newPasswordConfirm"
                                    id="newPasswordConfirm"
                                    value={newPasswordConfirm}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="newPasswordConfirm" className="d-flex align-items-center text-gray-600 fs-13px">Confirmación de Contraseña</label>
                            </div>
                            <div className="mb-20px">
                                <Button
                                    color="yellow"
                                    className="d-block w-100 h-45px btn-lg"
                                    disabled={isSaving}
                                    type="submit"
                                >
                                    {
                                        isSaving
                                            ? <i className="fa fa-spinner fa-spin fa-fw" /> : ''
                                    }
                                    {
                                        isSaving
                                            ? 'En proceso.... ' : 'Cambiar contraseña'
                                    }
                                </Button>
                            </div>
                            <div className="text-grey-darker mb-20px">
                                Solicitar nuevo cambio de contraseña
                                {' '}
                                <Link to="/forgotpassword">aqui.</Link>
                            </div>
                            <div className="text-grey-darker mb-20px">
                                Regresar al
                                {' '}
                                <Link to="/login">login.</Link>
                            </div>
                            <FooterCopyRight />
                        </form>
                    </div>
                </div>
                <ToastApp />
            </div>
            {(sweetAlertSuccess
            && (
                <SweetAlert
                    success
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    title="Cambio de contraseña"
                    onConfirm={() => history.push('/login')}
                >
                    Su contraseña se ha cambiado con exito
                </SweetAlert>
            )
            )}
        </>
    );
}

export default ResetPassword;
