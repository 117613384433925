/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AppContext from '../../../config/appContext';
import { FetchPolicy, NotificationType } from '../../../enums/general';

import UserGraphql from '../../../servicesapollo/userGraphql';
import Access from '../../../enums/permissions';
import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';

function UserPassword(props) {
    const {
        showNotification, languageTitles, onLogout, loggedUser, hasPermission,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const { onClosePopup, userId } = props;

    const [record, setRecord] = useState({
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        Token: '',
    });

    const {
        newPassword, newPasswordConfirm, Token,
    } = record;

    const canChangeOtherPassword = hasPermission(Access.USER_CHANGE_OTHER_PASSWORD) || loggedUser?.UserType === 'companyadmin';

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setRecord({
            ...record,
            [name]: value,
        });
    };

    const [changePassword, { loading: isSaving }] = useMutation(UserGraphql.CHANGE_PASSWORD, {
        onCompleted: (mutationData) => {
            if (mutationData?.changePassword) {
                const {
                    success, message, error,
                } = mutationData?.changePassword;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                showNotification(languageTitles.userNeedLoginAgain, NotificationType.SUCCESS);

                if (userId === loggedUser.UserId) {
                    setTimeout(() => {
                        onLogout();
                    }, 3000);
                }
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onChangePassword = (e) => {
        e.preventDefault();

        if (isSaving) return;

        if (!newPassword) {
            showNotification(languageTitles.userProvidePassword, NotificationType.INFO);
            return;
        }

        if (!newPasswordConfirm) {
            showNotification(languageTitles.userConfirmPassword, NotificationType.INFO);
            return;
        }

        if (!Token && !canChangeOtherPassword) {
            showNotification(languageTitles.userProvideToken, NotificationType.INFO);
            return;
        }

        if (newPassword !== newPasswordConfirm) {
            showNotification(languageTitles.userPasswordDontMatch, NotificationType.INFO);
            return;
        }

        // if (newPassword === newPasswordConfirm && currentPassword === '' && newPassword !== '') {
        //     showNotification('Debe ingresar la contraseña actual.', NotificationType.INFO);
        //     return;
        // }

        changePassword({
            variables: {
                record: {
                    UserId: userId,
                    NewPassword: newPassword,
                    NewPasswordConfirm: newPasswordConfirm,
                    Token,
                },
            },
        });
    };

    const [requestToken, { loading: isRequestingToken }] = useLazyQuery(UserGraphql.REQUEST_TOKEN, {
        onCompleted: (mutationData) => {
            if (mutationData?.requestToken) {
                const {
                    success, message, error,
                } = mutationData?.requestToken;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenRequestToken,
                        NotificationType.DANGER,
                    );
                    return;
                }

                showNotification(languageTitles.codeSentToEmail, NotificationType.SUCCESS);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onRequestToken = (e) => {
        e.preventDefault();

        if (isRequestingToken || isSaving) return;

        setRecord({
            ...record,
            Token: '',
        });

        requestToken({
            variables: {
                userId,
            },
        });
    };

    return (
        <Modal isOpen toggle={onClosePopup}>
            <ModalHeader toggle={() => onClosePopup} close={<button type="button" className="btn-close" onClick={onClosePopup} />}>
                {languageTitles.userChangePasswordTitle}
            </ModalHeader>
            <ModalBody>
                {/* <div className="row mb-3">
                    <label className="form-label col-form-label col-md-3">{languageTitles.userOldPassword}</label>
                    <div className="col-md-9">
                        <input
                            type="password"
                            className="form-control m-b-5"
                            name="currentPassword"
                            value={currentPassword}
                            onChange={handleInputChange}
                        />
                    </div>
                </div> */}
                <div className="row mb-3">
                    <label className="form-label col-form-label col-md-3">{languageTitles.userNewPassword}</label>
                    <div className="col-md-9">
                        <input
                            type="password"
                            className="form-control m-b-5"
                            name="newPassword"
                            value={newPassword}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="form-label col-form-label col-md-3">{languageTitles.userVerifyPassword}</label>
                    <div className="col-md-9">
                        <input
                            type="password"
                            className="form-control m-b-5"
                            name="newPasswordConfirm"
                            value={newPasswordConfirm}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                { (!canChangeOtherPassword || loggedUser.UserId === userId)
                && (
                    <div className="row mb-3">
                        <label className="form-label col-form-label col-md-3">{languageTitles.userToken}</label>
                        <div className="col-md-9">
                            <div className="input-group">
                                <input
                                    type="text"
                                    value={Token}
                                    className="form-control m-b-3"
                                    name="Token"
                                    onChange={handleInputChange}
                                />
                                <div className="input-group-text">
                                    <Button
                                        color="grey"
                                        size="xs"
                                        onClick={onRequestToken}
                                    >
                                        { isRequestingToken && <i className="fa fa-spinner fa-spin fa-fw" /> }
                                        {languageTitles.userRequestToken}
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>
                ) }
            </ModalBody>
            <ModalFooter>
                <TSFButton
                    action="cancel"
                    onClick={onClosePopup}
                />
                <TSFButton
                    action="save"
                    isWorking={isSaving}
                    onClick={onChangePassword}
                />
            </ModalFooter>
        </Modal>
    );
}

UserPassword.propTypes = {
    onClosePopup: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
};

export default UserPassword;
