/* eslint-disable import/prefer-default-export */
const Access = Object.freeze({
    APPLICANT_READ_LIST: 'applicant:read_list',
    APPLICANT_WRITE_OTHER: 'applicant:write_other',
    APPLICANT_GENERATE_PDF: 'applicant:generate_pdf',
    APPLICANT_SEND_EMAIL: 'applicant:send_email',
    APPLICANT_WRITE_COMMENTS: 'applicant:write_comments',
    APPLICANT_PROCESS_APPLICATION: 'applicant:process_application',
    APPLICANT_MANAGE_DOCUMENTS: 'applicant:manage_documents',
    TEST_READ_LIST: 'test:read_list',
    TEST_ANALYSE: 'test:analyse',
    TEST_DELETE: 'test:delete',
    JOBS_READ_LIST: 'jobs:read_list',
    EMAIL_TEMPLATE_WRITE: 'email_template:write',
    EMAIL_TEMPLATE_DELETE: 'email_template:delete',
    USER_LIST: 'user:read_list',
    USER_DELETE: 'user:delete',
    USER_VALIDATE: 'user:validate',
    USER_CHANGE_OTHER_PASSWORD: 'user:change_other_password',
    USER_ASSIGN_TEST_TERM: 'user:assign_test_term',
    USER_ASSIGN_TEST_DISC: 'user:assign_test_disc',
    COMPANY_READ_LIST: 'company:read_list',
    COMPANY_USER_LIST: 'company:users_list',
    COMPANY_WRITE: 'company:write',
    COMPANY_DELETE: 'company:delete',
    COMPANY_USER_MANAGE: 'company:users_manage',
    COMPANY_ASSOCIATE_USER: 'company:associate_user',
    COMPANY_CHANGE_PASSWORD: 'company:change_company_password',
    PERMISSION_ASSIGN: 'permission:assign',
});

export default Access;
