import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppContext from '../../config/appContext';

function WidgetStatsV2(props) {
    const { languageTitles } = useContext(AppContext);

    const {
        bgColor,
        title,
        total,
        labelBottom,
        link,
        labelLink,
        icon,
    } = props;

    return (
        <div className={`widget widget-stats ${bgColor}`}>
            <div className="stats-icon stats-icon-lg"><i className={`fa fa-fw ${icon}`} /></div>
            <div className="stats-content">
                <div className="stats-title">{title}</div>
                <div className="stats-number">{total}</div>
                {labelBottom && !link
                    && <div className="stats-desc">{labelBottom}</div>}
                {link
                    && (
                        <Link to={link}>
                            {labelLink || languageTitles.dashboardlinkTitle}
                            {' '}
                            <i className="fa fa-arrow-alt-circle-right" />
                        </Link>
                    )}
            </div>
        </div>

    // <div className={"widget widget-stats " + this.props.cback}>
    //     <div className="stats-icon stats-icon-lg"><i className={"fa fa-fw " + this.props.icon}></i></div>
    //     <div className="stats-info">
    //         <h4>{this.props.title}</h4>
    //         <p>{this.props.amount}</p>
    //     </div>
    //     <div className="stats-link">
    //         {
    //             this.props.overrideLink?
    //             <a href={this.props.link}>Descargar</a>
    //             :
    //             (
    //             this.props.forceLink || (this.props.amount>0 && this.props.link !== null && this.props.link !== '') ?
    //                 < Link to={this.props.link}>{languageTitles.LinkTitle} <i className="fa fa-arrow-alt-circle-right"></i></Link>
    //                 :
    //                 < Link to="#">&nbsp;</Link>
    //             )
    //         }
    //     </div>
    // </div >
    );
}

WidgetStatsV2.propTypes = {
    bgColor: PropTypes.string.isRequired,
    title: PropTypes.string,
    total: PropTypes.number,
    labelBottom: PropTypes.string,
    link: PropTypes.string,
    labelLink: PropTypes.string,
    icon: PropTypes.string,
};

WidgetStatsV2.defaultProps = {
    title: '',
    total: 0,
    labelBottom: '',
    link: '',
    labelLink: '',
    icon: '',
};

export default WidgetStatsV2;
