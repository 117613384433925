/* eslint-disable arrow-body-style */
import React, {
    useContext, useEffect, useReducer,
} from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
    Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup,
} from 'reactstrap';
import AppContext from '../../../config/appContext';
import { Panel, PanelHeader, PanelBody } from '../../panel/panel';
import SearchField from '../../search-field/searchfield';
import CompanyDropdown from '../company/company-dropdown';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';
import { EnrollProcess, FetchPolicy, NotificationType } from '../../../enums/general';

import './manage-fases.css';
import ContentForProcess from '../../layout/content/contentForProcess';
import TSFButton from '../../actionButtons/tsfButton';
import DocumentSender from '../documents/documentSender';
import DeleteAlert from '../../alert/deleteAlert';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
    SET_SELECTED_APPLICANT: 'setSelectedApplicant',
    SET_RECORDS_AFTER_DROP: 'setRecordAfterDrop',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        if (action.fireAction) action.fireAction();

        return { ...state, ...action.payload };
    }
    case ACTION_TYPES.SET_SELECTED_APPLICANT: {
        const { value, applicantId, faseId } = action.payload;

        const howSelected = state.records.filter((c) => Number(c.FaseId) === Number(faseId)
        && c.selected
        && c.ApplicantId !== applicantId
        && applicantId)?.length || (Boolean(value) === true ? 1 : 0);

        const newRecords = state.records.map((ele) => {
            const current = { ...ele };
            if (ele.ApplicantId === applicantId) {
                current.selected = value;
                current.disable = false;
            }

            if (!applicantId) {
                current.selected = Boolean(value) === true && current.FaseId === faseId;
            }

            if (howSelected > 0 && ele.FaseId !== faseId) current.disable = true; else current.disable = false;

            return current;
        });

        return { ...state, records: newRecords };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.payload;
    }
    case ACTION_TYPES.SET_RECORDS_AFTER_DROP: {
        const { records, idsToSendId, faseId } = action.payload;

        if (action.saveAfterDrop) action.saveAfterDrop(idsToSendId, faseId);

        return { ...state, records };
    }
    default:
        return state;
    }
};

function ManageFases() {
    const {
        currentLanguage, showNotification,
        coreCatalogs, languageTitles,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const initState = {
        records: [],
        faseTitles: [],
        emailsToSend: [],
        idsToDelete: [],
        // params
        search: '',
        companyId: 0,
        openEmailPopup: false,
        applicantSelected: null,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        idsToDelete, search, companyId, faseTitles, records, openEmailPopup, applicantSelected,
    } = state;

    const [getData, { loading }] = useLazyQuery(ApplicantsGraphql.GET_APPLICANTS_BY_COMPANY_AND_FASE, {
        onCompleted: (res) => {
            if (res.applicants) {
                const {
                    data, total, success, message, error,
                } = res.applicants;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                if (data.length > 0) {
                    const dataProcesed = data.map((c) => (
                        {
                            ...c,
                            FaseId: c.FaseId === 0 ? 15 : c.FaseId,
                        }
                    ));

                    dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        payload: {
                            records: dataProcesed,
                            total,
                            applicantSelected: null,
                        },
                    });
                } else {
                    dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        payload: {
                            records: [],
                            total: 0,
                            applicantSelected: null,
                        },
                    });
                }
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const getCandidates = () => {
        if (companyId === 0 && records.length === 0) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                payload: {
                    records: [],
                    applicantSelected: null,
                },
            });

            return;
        }

        getData({
            variables: {
                usePaging: false,
                search,
                filters: [{
                    fieldName: 'CompanyId',
                    value: String(companyId),
                }],
            },
        });
    };

    const [applicantAssignToProcess, { loading: processing }] = useLazyQuery(ApplicantsGraphql.APPLICANT_ASSIGN_TO_PROCESS, {
        onCompleted: (res) => {
            if (res.applicantAssignToProcess) {
                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS);

                getCandidates();
            } else {
                showNotification(languageTitles.errorWhenSave, NotificationType.DANGER);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onDesasociateApplicants = () => {
        applicantAssignToProcess({
            variables: {
                ids: idsToDelete,
                updateType: EnrollProcess.REMOVE_COMPANY,
            },
        });
    };

    const saveOnFase = (ids, faseId) => {
        applicantAssignToProcess({
            variables: {
                ids,
                updateType: EnrollProcess.FASE,
                value: faseId,
            },
        });
    };

    const onDragStart = (ev, id, faseId) => {
        ev.dataTransfer.setData('id', id);
        ev.dataTransfer.setData('faseId', faseId);
    };

    const onDragOver = (ev) => {
        ev.preventDefault();
    };

    const onDrop = (ev, cat) => {
        const id = ev.dataTransfer.getData('id');
        const faseId = ev.dataTransfer.getData('faseId');

        if (Number(faseId) === Number(cat)) return;

        let newRecords = [];
        const recordsSelected = records.filter((c) => c?.selected)?.map((c) => Number(c.ApplicantId));

        if (recordsSelected?.length > 0 && recordsSelected.includes(Number(id))) { // lo pasa multiple
            newRecords = records.map((item) => {
                if (recordsSelected.includes(Number(item.ApplicantId))) {
                    return {
                        ...item, FaseId: cat, selected: false, disable: false,
                    };
                }
                return { ...item, selected: false, disable: false };
            });
        } else { // lo pase individual
            newRecords = records.map((item) => {
                if (Number(item.ApplicantId) === Number(id)) {
                    return {
                        ...item, FaseId: cat,
                    };
                }
                return { ...item };
            });
        }

        dispatch({
            type: ACTION_TYPES.SET_RECORDS_AFTER_DROP,
            payload: {
                records: newRecords,
                idsToSendId: recordsSelected?.length > 0 && recordsSelected.includes(Number(id)) ? recordsSelected : [Number(id)],
                faseId: String(cat),
            },
            saveAfterDrop: saveOnFase,
        });
    };

    const setSearchValue = (val) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            payload: {
                search: val,
                records: [],
            },
            fireAction: getCandidates,
        });
    };

    const setDropdownValue = (value) => {
        if (companyId === value) return;

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            payload: {
                companyId: value,
                records: [],
            },
        });
    };

    useEffect(() => {
        if (companyId > 0) getCandidates();
    }, [companyId]);

    useEffect(() => {
        if (coreCatalogs?.filter((c) => Number(c.orden) === 5)?.length > 0) {
            const datafase = coreCatalogs.filter((c) => Number(c.orden) === 5 && String(c.category) === 'Fase');

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                payload: {
                    faseTitles: datafase,
                },
            });
        }
    }, [coreCatalogs]);

    const multipleSelected = records?.filter((c) => c?.selected)?.map((c) => c.ApplicantId);
    const faseMultipleSelected = records?.find((c) => c?.selected)?.FaseId;
    const bgHeader = ['bg-info', 'bg-warning', 'bg-purple', 'bg-success'];
    const bgBody = ['bg-light', 'bg-default', 'bg-light', 'bg-default'];

    return (
        <>
            <Panel>
                <PanelHeader>
                    <div className="panel-heading-btn justify-content-end">
                        <div className="col-md-5 me-2">
                            <SearchField
                                returnSearchValue={(val) => setSearchValue(val)}
                                returnOnClear={() => setSearchValue('')}
                            />
                        </div>
                        <div className="col-md-2">
                            <CompanyDropdown
                                className="me-1"
                                returnSelectedValue={(val, options) => setDropdownValue(val, options)}
                                name="company"
                                value={companyId}
                            />
                        </div>
                        <div className="">
                            <TSFButton
                                action="refresh"
                                isWorking={loading}
                                onClick={() => getCandidates()}
                            />
                        </div>
                    </div>
                </PanelHeader>
                <PanelBody>
                    <ContentForProcess reduceHeight={180}>
                        <div className="row container-drag" style={{ height: '100%', overflow: 'auto' }}>
                            {faseTitles.map((item, i) => {
                                const candidateList = records?.filter((c) => c.FaseId === item.value);
                                return (
                                    <div
                                        className="col-lg-3 manage-fases"
                                        key={item.value}
                                        onDragOver={(e) => onDragOver(e)}
                                        onDrop={(e) => onDrop(e, item.value)}
                                    >
                                        <Panel
                                            className="widget-todolist"
                                            key={item.value}
                                            onDragOver={(e) => onDragOver(e)}
                                            onDrop={(e) => onDrop(e, item.value)}
                                        >
                                            <PanelHeader className={` ${bgHeader[i]}`} showBackHistory={false}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={
                                                        faseMultipleSelected === item.value
                                                    && candidateList?.length === candidateList.filter((c) => c.selected)?.length
                                                    }
                                                    onChange={(e) => dispatch({
                                                        type: ACTION_TYPES.SET_SELECTED_APPLICANT,
                                                        payload: {
                                                            value: e.target.checked,
                                                            faseId: item.value,
                                                        },
                                                    })}
                                                />
                                                { ' ' }
                                                {`${item[currentLanguage]} (${candidateList.length})`}
                                                <ButtonGroup className="pull-right">
                                                    {faseMultipleSelected === item.value && (
                                                        <Button
                                                            className="me-1"
                                                            color="primary"
                                                            size="xs"
                                                            onClick={() => dispatch({
                                                                type: ACTION_TYPES.SET_STATE_VALUES,
                                                                payload: {
                                                                    openEmailPopup: true,
                                                                },
                                                            })}
                                                        >
                                                            <i className="fa fa-md fa-envelope" />
                                                        </Button>
                                                    )}
                                                    {faseMultipleSelected === item.value && (
                                                        <Button
                                                            className="me-1"
                                                            color="danger"
                                                            size="xs"
                                                            onClick={() => dispatch({
                                                                type: ACTION_TYPES.SET_STATE_VALUES,
                                                                payload: {
                                                                    idsToDelete: multipleSelected,
                                                                },
                                                            })}
                                                        >
                                                            <i className="fa fa-md fa-trash fa-fw" />
                                                        </Button>
                                                    )}
                                                </ButtonGroup>
                                            </PanelHeader>
                                            <PanelBody className={`widget-list ${bgBody[i]}`}>
                                                {candidateList.map((t) => {
                                                    const fullName = `${t.Name} ${t.LastName}`;
                                                    return (
                                                        <div
                                                            className="widget-list-item draggable"
                                                            key={t.ApplicantId}
                                                            draggable
                                                            onDragStart={(e) => onDragStart(e, t.ApplicantId, t.FaseId)}
                                                        >
                                                            <div className="widget-list-input">
                                                                <div className="form-check">
                                                                    {!t?.disable
                                                            && (
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={t?.selected}
                                                                    onChange={(e) => dispatch({
                                                                        type: ACTION_TYPES.SET_SELECTED_APPLICANT,
                                                                        payload: {
                                                                            value: e.target.checked,
                                                                            applicantId: t.ApplicantId,
                                                                            faseId: t.FaseId,
                                                                        },
                                                                    })}
                                                                />
                                                            )}
                                                                </div>
                                                            </div>
                                                            <div className="widget-list-media">
                                                                <img src={t?.User?.PictureURL ?? '/assets/img/user/user-13.jpg'} width="50" alt="" className="rounded" />
                                                            </div>
                                                            <div className="widget-list-content">
                                                                <h4 className="widget-list-title">
                                                                    <Link to={`/applicant/${t.ApplicantId}`} className="">
                                                                        {fullName}
                                                                    </Link>

                                                                </h4>
                                                                <p className="widget-list-desc">{t.Email}</p>
                                                            </div>
                                                            <div className="widget-list-action">
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle tag="a" className="text-gray-500">
                                                                        <i className="fa fa-ellipsis-h fs-14px" />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem
                                                                            onClick={() => dispatch({
                                                                                type: ACTION_TYPES.SET_STATE_VALUES,
                                                                                payload: {
                                                                                    openEmailPopup: true,
                                                                                    applicantSelected: t,
                                                                                },
                                                                            })}
                                                                        >
                                                                            <i className="fa fa-md fa-envelope fa-fw text-primary" />
                                                                            {' '}
                                                                            Enviar correo
                                                                        </DropdownItem>
                                                                        <DropdownItem divider />
                                                                        <DropdownItem
                                                                            onClick={() => dispatch({
                                                                                type: ACTION_TYPES.SET_STATE_VALUES,
                                                                                payload: {
                                                                                    idsToDelete: [t.ApplicantId],
                                                                                },
                                                                            })}
                                                                        >
                                                                            <i className="fa fa-md fa-trash fa-fw text-danger" />
                                                                            {' '}
                                                                            Remover
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                );
                            })}
                        </div>
                    </ContentForProcess>
                </PanelBody>
            </Panel>
            {openEmailPopup && (
                <DocumentSender
                    showInPopup
                    openPopup={openEmailPopup}
                    onClosePopup={() => dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        payload: {
                            openEmailPopup: false,
                            applicantSelected: null,
                        },
                    })}
                    ids={
                        applicantSelected ? [{ id: applicantSelected.UserId, name: applicantSelected.Email }]
                            : records?.filter((c) => c?.selected)?.map((item) => ({ id: item.UserId, name: item.Email }))
                    }
                />
            )}
            {idsToDelete?.length > 0 && (
                <DeleteAlert
                    isDeleting={processing}
                    onConfirm={onDesasociateApplicants}
                    onCancel={() => dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        payload: {
                            idsToDelete: [],
                            applicantSelected: null,
                        },
                    })}
                />
            )}
        </>
    );
}

export default ManageFases;
