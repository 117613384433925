/* eslint-disable no-shadow */
import moment from 'moment';
import 'moment-timezone';

const FORMAT = 'DD/MM/YYYY';

const getWithTwoDigits = (number) => {
    if (String(number).length === 1) return `0${number}`;
    return number;
};

const useDateHelper = () => {
    const isValid = (value = null) => moment(value).isValid();

    const getUTCDate = () => moment().utc();

    const getUTCDateFormat = () => moment().utc().format();

    const toLocal = (value = null) => {
        if (moment(value).isValid()) {
            return moment(value).local();
        }

        return null;
    };

    const fromNow = (value = null) => {
        if (moment(value).isValid()) {
            return moment(value).fromNow();
        }

        return null;
    };

    const formatDate = (value = null, format = FORMAT) => {
        if (moment(value).isValid()) {
            return moment(value).format(format);
        }

        return '';
    };

    const formatUTC = (value = null) => {
        if (moment(value).isValid()) {
            return moment.utc(value).format();
        }

        return null;
    };

    const formatToLocal = (value = null, format = FORMAT) => {
        if (isValid(value)) {
            return moment.utc(value).local().format(format);
        }

        return null;
    };

    const subtractAndFormatUTC = (value, valueSubtract, type = 'hours') => {
        if (moment(value).isValid()) {
            return moment.utc(value).subtract(valueSubtract, type).format();
        }

        return null;
    };

    const calendarWithoutTime = (value) => {
        if (isValid(value)) {
            return moment(value).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd',
                sameElse: 'DD/MM/YYYY',
            });
        }

        return null;
    };

    const calendarWithTime = (value) => {
        if (isValid(value)) {
            return moment(value).calendar(null, {
                sameDay: '[Today] hh:mm a',
                nextDay: '[Tomorrow] hh:mm a',
                nextWeek: 'dddd hh:mm a',
                lastDay: '[Yesterday] hh:mm a',
                lastWeek: '[Last] dddd hh:mm a',
                sameElse: 'DD/MM/YYYY hh:mm a',
            });
        }

        return null;
    };

    const calendar = (value) => {
        if (isValid(value)) {
            return moment(value).calendar();
        }

        return null;
    };

    const isSameOrAfter = (firstDate, secondDate) => {
        if (isValid(firstDate) && isValid(secondDate)) {
            return moment(firstDate).isSameOrAfter(secondDate);
        }

        return null;
    };

    const getDate = () => moment().toDate();

    const add = (value, number, type = 'days') => {
        if (moment(value).isValid()) {
            return moment(value).add(number, type);
        }

        return null;
    };

    const diff = (date1, date2, type = 'days') => {
        if (moment(date1).isValid() && moment(date2).isValid()) {
            return moment(date1).diff(date2, type);
        }

        return null;
    };

    const getDateByTimezone = (date, timeZone) => {
        if (isValid(date)) return moment.tz(date, 'UTC').tz(timeZone);

        return date;
    };

    const getOnlyDate = (date) => {
        if (isValid(date)) {
            // let currentDate = date;
            const currentYear = date.getFullYear();
            const currentMonth = getWithTwoDigits(date.getMonth() + 1);
            const currentDay = getWithTwoDigits(date.getDate());
            const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
            return currentDate;
            // if (date.toString().includes('GMT')) { currentDate = `${currentYear}-${currentMonth}-${currentDay}`; }

            // if (currentDate.includes('T')) {
            //     const dateSplit = currentDate.split('T');
            //     console.log(dateSplit[0], moment(dateSplit[0]).format(FORMAT));
            //     return moment(dateSplit[0]).format(FORMAT);
            // }
        }

        return '';
    };

    const isSame = (firstDate, secondDate, type = 'days') => {
        if (isValid(firstDate) && isValid(secondDate)) {
            return moment(firstDate).isSame(secondDate, type);
        }

        return false;
    };

    const getDateOverrideToLocalTime = (date) => {
        if (this.isValid(date)) {
            const currentDate = date;
            if (currentDate.includes('T')) {
                const localTime = new Date().toLocaleTimeString();
                const newDate = new Date(`${date.split('T')[0]} ${localTime}`);
                return newDate;
            }

            return date;
        }

        return null;
    };

    const convertToDateFromString = (date) => {
        if (!isValid(date)) return null;
        const newDate = new Date(`${date} 00:00:00`);
        return newDate;
    };

    const formatDateFromOnlyStringDate = (value = null, format = FORMAT) => {
        if (!isValid(convertToDateFromString(value))) return '';
        return moment(convertToDateFromString(value)).format(format);
    };

    const setTimeToZero = (date) => moment(date).utcOffset(0);

    const convertToDate = (date) => {
        if (date === '' || date === null || date === undefined) return null;
        return new Date(date);
    };

    return {
        isValid,
        getUTCDate,
        getUTCDateFormat,
        toLocal,
        fromNow,
        formatDate,
        formatUTC,
        formatToLocal,
        subtractAndFormatUTC,
        calendarWithoutTime,
        calendarWithTime,
        getDate,
        calendar,
        isSameOrAfter,
        add,
        diff,
        getDateByTimezone,
        getOnlyDate,
        isSame,
        setTimeToZero,
        convertToDate,
        getDateOverrideToLocalTime,
        convertToDateFromString,
        formatDateFromOnlyStringDate,
    };
};

export default useDateHelper;
