const Lang = {
    // general labels
    searchApplication: 'Rechercher client',
    notificationLabel: 'Notifications',
    notificationViewMore: 'voir plus',
    nightMode: 'Sombre',
    lightMode: 'Mode lumière',
    editProfileTitle: 'Mettre à jour le profil',
    closeAppTitle: 'Quitter l’application',
    writeToSearch: 'Tapez ici pour rechercher',
    loadingRecords: 'Chargement de l’enregistrements',
    loadingRecord: 'Chargement des enregistrement',
    recordNotFound: 'Aucun enregistrement trouvé avec cet identifiant',

    // menu sidebar: '',
    menuHome: 'Début',
    menuApplicants: 'Candidats',
    menuAddCV: 'Ajouter un CV',
    menuEditCV: 'Modifier CV',
    menuAdminFases: 'Gérer les phases',
    menuCompanies: 'Entreprises',
    menuUsers: 'Utilisateurs',
    menuEmailTemplates: 'Modèles de courrier',
    menuJobs: 'Emplois',
    menuPyscoTest: 'Test psychométrique',
    menuDocumentShared: 'Documents partagés',
    menuAccess: 'Autorisations',
    menuManageDocuments: 'Envoyer des documents',
    menuEditCompany: 'Informations sur l’enterprise',
    menuEmails: 'Courriels',
    menuEditCompanyJobPositions: 'Postes à pourvoir',
    menuEditCompanyEmployees: 'Main-d’œuvre au sein de l’entreprise',
    menuEditCompanyFiles: 'Affichages de l’offre d’emploi',

    // Dashboard: '',
    dashboardTotalUpdates: 'Mise à jour au cours des 60 derniers jours',
    dashboardTotal: 'nombre total de candidats',
    dashboardTotalToday: 'Demandes d’aujourd’hui',
    dashboardGraphTitle: 'Demandes par mois et par année',
    dashboardGraphLegend: 'Candidats',
    dashboardGrahpBarGenderTitle: 'Candidats par sexe',
    dashboardPsycoTestTableLabel: 'Tests psychométriques',
    dashboardPsycoTestNoRecordsFound: 'Aucun test activé',
    dashboardPsycoTestColumnDetail: 'Détail',
    dashboardPsycoTestColumnAction: 'Action',
    dashboardPsycoTestColumnStatus: 'État',
    dashboardPsycoTestEnableLegend: 'Il a été activé pour effectuer le',
    dashboardPsycoTestUnableTest: 'Le test n\'est plus disponible ',
    dashboardPsycoTestStartBtn: 'Commencer',
    dashboardPsycoTestDISC: 'Test 1',
    dashboardPsycoTestTerman: 'Test 2',
    dashboardProfileChartComplete: 'Profil complété',
    dashboardProfileChartPendingPoints: 'En attente',
    dashboardProfileChartCompleteLabel: '% terminé',
    dashboardProfileChartPendingLabel: '% en attente',
    dashboardLastLoginTitle: 'Derniers accès',
    dashboardlinkTitle: 'Accès',
    dashboardCompanyWelcomeMessage: `<p>Cher client,</p>
    <p>
    Il nous fait plaisir de collaborer avec vous pour la réalisation de votre projet de recrutement.
    </p>
    <p>
    Par ce questionnaire, TSF récoltera toutes les informations nécessaires pour l’analyse du dossier et pour bien cibler les candidats qui s’ajouteront à votre équipe.
    </p>
    <p>
    Nous nous assurerons aussi d’avoir en main toutes les données requises pour satisfaire le Programme des Travailleurs Étrangers Temporaires par lequel vos travailleurs seront embauchés.
    </p>
    <p>
    N’hésitez pas à communiquer avec un membre de l’équipe de TSF pour toute question; il nous fera plaisir de vous guider.
    </p>`,
    dashboardCompanyStartButton: 'Commencer',

    // candidates: '',
    candidatesTableColumnId: 'Id',
    candidatesTableColumnImage: 'Image',
    candidatesTableColumnName: 'Nom',
    candidatesTableColumnEmail: 'Courriel',
    candidatesTableColumnAge: 'Âge',
    candidatesTableColumnProfession: 'Profession',
    candidatesTableColumnJobsApplicant: 'Poste pour lequel vous postulez',
    candidatesTableColumnActions: 'Possibilités',
    candidatesEditBtnTitle: 'Mettre à jour les données des candidats',
    candidatesExportBtnTitle: 'Exporter au format pdf',
    candidatesSendEmailBtnTitle: 'Envoyer un e-mail',
    candidatesMessageBtnTitle: 'Ajouter un commentaire',
    candidatesWhatsappBtnTitle: 'Écrire un WhatsApp',
    candidatesProcessApplicationBtnTitle: 'Traiter la demande',
    candidatesEnableTermanBtnTitle: 'Activer le test terman',
    candidatesEnableDISCBtnTitle: 'Activer le test DISC',
    candidatesActionDropdownLabel: 'Actions',
    candidatesActionDropdownAddFase: 'Ajouter une étape',
    candidatesActionDropdownAddLabel: 'Ajouter une couleur d’étiquette',
    candidatesActionDropdownAsignCompany: 'Attribuer à une entreprise',
    candidatesActionDropdownSentEmail: 'Envoyer du courrier',
    candidatesActionButtonsMoreFilter: 'Appliquer d’autres filtres',
    candidatesActionButtonsExportPDF: 'Exporter la liste au format PDF',
    candidatesActionButtonsExportExcel: 'Exporter la liste vers Excel',
    candidatesActionButtonsRefresh: 'Actualiser les données',
    candidatesActionButtonsRestart: 'Nettoyer les filtres',
    candidatesActionButtonsSearch: 'Chercher',
    candidatesFiltersSortBy: 'Trier par',
    candidatesFiltersSortByNameASC: 'Nom ASC',
    candidatesFiltersSortByNameDESC: 'Nom DESC',
    candidatesFiltersSortByLastNameASC: 'Nom de famille ASC',
    candidatesFiltersSortByLastNameDESC: 'Nom de famille DESC',
    candidatesFiltersSortByCountryASC: 'Pays ASC',
    candidatesFiltersSortByCountryDESC: 'Pays DESC',
    candidatesFiltersSortByAgeASC: 'Âge ASC',
    candidatesFiltersSortByAgeDESC: 'Âge DESC',
    candidatesFiltersWithoutJob: 'Nettoyer les filtres',
    candidatesFiltersAgeLabel: 'Âge',
    candidatesFiltersSelectDates: 'Sélectionner des dates',
    candidatesFiltersGender: 'Genre',
    candidatesFiltersProfesion: 'Profession',
    candidatesFiltersJobs: 'Emplois',
    candidatesFiltersCountry: 'Pays',
    candidateTabProfile: 'Profil',
    candidateTabStudies: 'Études',
    candidateTabExperience: 'Expériences de travail',
    candidateTabFiles: 'Archives',
    candidateTabJob: 'Compétences professionnelles',
    candidateJobPosition: 'Poste pour lequel vous postulez',
    candidateName: 'Prénoms',
    candidateLastName: 'Noms',
    candidateBirthday: 'Date de naissance',
    candidateMaritalStatus: 'État civil',
    candidateGender: 'Genre',
    candidateIdentification: '# de pièce d’identité',
    candidateHasLicence: 'Avez-vous un permis de conduire',
    candidateCategoryLicence: 'Catégorie de licence',
    candidateProfession: 'Profession',
    candidateEnglishLevel: 'Niveau d’anglais',
    candidateFrenchLevel: 'Niveau de Français',
    candidateChildrens: 'Avez-vous des enfants',
    candidateChildrensCount: 'Nombre d’enfants',
    candidateCountryBorn: 'Pays de naissance',
    candidateCityBorn: 'Ville de naissance',
    candidateDepartmentBorn: 'Département ou municipalité de naissance',
    candidateCountryResident: 'Pays de résidence',
    candidateCityResident: 'Ville de résidence',
    candidateDepartmentResident: 'Appartement ou municipalité de résidence',
    candidateAddress: 'Adresse',
    candidateAddress2: 'Adresse 2',
    candidateCellphone: 'Téléphone portable',
    candidateHomePhone: 'Téléphone au domicile',
    candidateEmail: 'Courriel',
    candidateHasPassport: 'Vous avez un passeport',
    candidatePassport: 'Numéro de passeport',
    candidatePassportExpirationDate: 'Date d’expiration du passeport',
    candidateHasTravel: 'Indiquez les 2 derniers trajets effectués',
    candidateStartDateTravel: 'Date d’entrée',
    candidateEndDateTravel: 'Date de départ',
    candidateCountryTravel: 'Pays',
    candidateAboutMe: 'Opinion personnelle',
    candidateVisaDenied: 'Un visa vous a été refusé',
    candidateVisaDeniedDate: 'Date de refus de visa',
    candidateVisaDeniedComments: 'Raisons pour lesquelles il a été refusé',
    candidateLiveInOtherCountry: 'Vous vivez dans un autre pays depuis plus de 6 mois',
    candidateLivedCountry: 'Quel pays ?',
    candidateLivedCountryDate: 'Quelle date ?',
    candidateLivedCountryDatePlaceHolder: 'Date à laquelle vous avez vécu à l’extérieur du pays',
    candidateLivedCountryMigrateStatus: 'Quel était le statut d’immigration ?',
    candidateLivedCountryMigrateStatusPlaceHolder: 'Statut d’immigration ?',
    candidateLeadSource: 'Comment avez-vous entendu parler de nous ?',
    candidateIndicationsToFillData: 'Entrez les données dans l’ordre chronologique en tapant du plus récent premier au plus ancien à la fin',
    candidateStudiesCenter: 'Nom du centre d’études',
    candidateStudiesStart: 'Date de début',
    candidateStudiesEnd: 'Date de fin',
    candidateStudiesTitle: 'Diplôme obtenu',
    candidateStudiesTitleType: 'Type de diplôme obtenu',
    candidateExperienceCompany: 'Nom de l’entreprise',
    candidateExperienceStart: 'Date de début',
    candidateExperienceEnd: 'Date de fin',
    candidateExperienceJob: 'Poste',
    candidateExperienceJobDescription: 'Description d’emploi',
    candidateMessageLabel: 'Commentaires',
    candidateExperienceCompanyLocation: 'Localisation de l\'entreprise',
    candidateExperienceCompanyLocationPlaceholder: 'Ville ou municipalité',

    // actions: '',
    saveTitle: 'Sauvegarder',
    savingTitle: 'Sauvegarder',
    updateTitle: 'Éditer',
    updatingTitle: 'Mettre à jour',
    refreshTitle: 'Rafraîchir',
    refreshingTitle: 'Rafraîchir',
    deleteTitle: 'Supprimez',
    deletingTitle: 'Supprimez',
    sendTitle: 'Envoyer',
    sendingTitle: 'Envoi',
    cancelTitle: 'Annuler',
    cancelingTitle: 'Annulation',
    printTitle: 'Imprimer',
    printJobBtnTitle: 'Affichage offre d\'emploi',
    printingTitle: 'Impression',
    uploadingTitle: 'Télécharger',
    donwloadTitle: 'Télécharger',
    chooseTitle: 'Choisir',
    closeTitle: 'Fermer',
    backTitle: 'Retourner',
    removeButton: 'Supprimer',
    addButton: 'Ajouter',
    nextBtn: 'Suivant',
    previousBtn: 'Précédent',
    clearBtnTitle: 'Nettoyer',
    nextSectionPositionsBtn: 'Rubrique Posts',
    nextSectionWorkforceBtn: 'Rubrique Main-d’œuvre',
    nextSectionJobPostingBtn: 'Rubrique Affichages',
    notifiqueToCompanyAdminBtn: 'Terminé – soumettre à TSF',

    // company: '',
    companyAddBtn: 'Ajouter une entreprise',
    companyPhoneLabel: 'Téléphone',
    companyAddress: 'Adresse',
    companyUserAssociatedBtnTitle: 'Candidats associés',
    companyEditBtnTitle: 'Modifier l’entreprise',
    companyPopupAddTitle: 'Ajouter une entreprise',
    companyPopupEditTitle: 'Mise à jour des données de l’entreprise',
    companyContactName: 'Nom de la personne de contact',
    companyPrintFullReport: 'Imprimer le rapport complet',

    // users: '',
    usersTableColumnId: 'Id',
    usersTableColumnImage: 'Image',
    usersTableColumnName: 'Nom',
    usersTableColumnEmail: 'Courriel',
    usersTableColumnActions: 'Actions',
    usersBtnValidateUser: 'Valider l’utilisateur',
    usersBtnChangePassword: 'Changer le mot de passe',
    usersActionDropdownValidate: 'Valider les utilisateurs',
    usersActionDropdownDelete: 'Supprimer des utilisateurs',
    userChangePasswordTitle: 'Changement de mot de passe',
    userOldPassword: 'Mot de passe précédent',
    userNewPassword: 'Nouveau mot de passe',
    userAssociateUser: 'Utilisateur associé',
    userVerifyPassword: 'Vérifier le mot de passe',
    userToken: 'Jeton',
    userRequestToken: 'Demander un Jeton',
    userProfileTitle: 'Mise à jour des utilisateurs',
    userImageIndication: 'Remarque: la photo doit être avec un fond blanc, présentable et souriant',

    // email template: '',
    emailTemplateTitle: 'Titre du modèle',
    emailContentTitle: 'Contenu du modèle',

    // psycotest: '',
    psycoTestTableColumnName: 'Nom',
    psycoTestTableColumnEmail: 'Courrier',
    psycoTestTableColumnTest: 'Test',
    psycoTestTableColumnActive: 'Actif',
    psycoTestTableColumnExpiration: 'Expiration',
    psycoTestTableColumnStatus: 'État',
    psycoTestTableColumnActions: 'Actions',
    psycoTestBtnAnalize: 'Analyser',

    termanCategory1: 'Information',
    termanCategory2: 'Compréhension',
    termanCategory3: 'Verbal',
    termanCategory4: 'Sélection logique',
    termanCategory5: 'Arithmétique',
    termanCategory6: 'Jugement pratique',
    termanCategory7: 'Analogies',
    termanCategory8: 'Ordonner',
    termanCategory9: 'Classification',
    termanCategory10: 'Sériation',
    termanChart1Title: 'Évaluation du QI terman version TSF',
    termanChart1TotalLabel: 'Total',
    termanChart2Title: 'Questions répondues vs sans réponse',
    termanChart2Serie1: 'Bonnes réponses',
    termanChart2Serie2: 'Réponses incorrectes',
    termanChart2Serie3: 'Sans réponse',
    termanConclusionPercentageLabel1: 'Bas',
    termanConclusionPercentageLabel2: 'Inférieur',
    termanConclusionPercentageLabel3: 'Milieu',
    termanConclusionPercentageLabel4: 'Haut',
    termanConclusionPercentageLabel5: 'Supérieur',
    termanConclusionPercentageLabel6: 'Excellente',
    termanCandidateName: 'Nom',
    termanCorrectAnswer: 'Questions correctes',
    termanAveragePercentage: 'Moyenne pondérée',
    termanApplyTo: 'Aspirant',
    termanTestLabel: '# test',
    termanResultLabel: 'Score',
    termanConclusionLabel: 'Conclusions',
    termanClassificationLabel: 'Classification',

    discChart1Title: 'Évaluation du test DISC',
    discChart1Serie1: 'Dominant',
    discChart1Serie2: 'Influent',
    discChart1Serie3: 'Étable',
    discChart1Serie4: 'Consciencieux',
    discPatternLabel: 'Patron de l’',
    discPatternLabel1: 'Émotions',
    discPatternLabel2: 'But',
    discPatternLabel3: 'Jugez les autres en fonction de',
    discPatternLabel4: 'Influencer les autres',
    discPatternLabel5: 'Sa valeur pour',
    discPatternLabel6: 'Abus de',
    discPatternLabel7: 'Sous pression',
    discPatternLabel8: 'Peur',
    discPatternLabel9: 'Il serait plus efficace si',

    // documents: '',
    documentShareLegend: 'Fichiers partagés, téléchargez-les, remplissez-les, puis téléchargez-les ici',
    documentRepositoryTableColumn1: 'Type de fichier',
    documentRepositoryTableColumn2: 'Nom du fichier',
    documentRepositoryTableColumn3: 'URL',
    documentRepositoryTableColumn4: 'Fécha',
    documentRepositoryTableColumn5: 'Actions',
    documentRepositoryBtnChooseFile: 'Choisir',
    documentRepositoryChooseFileTitle: 'Sélectionner des fichiers',
    documentRepositoryBtnAddFile: 'Ajouter',
    documentRepositoryBtnUploadFiles: 'Télécharger le fichier',
    documentRepositoryBtnUploadingFile: 'Téléchargement du fichier',
    documentRepositoryBtnUploadingFiles: 'Téléchargement de fichiers',

    // sweetalert for delete: '',
    sweetAlertDeleteConfirmBtnText: 'Oui, continuez',
    sweetAlertDeleteCancelBtnText: 'Annuler',
    sweetAlertDeleteTitle: 'Êtes-vous sûr?',
    sweetAlertDeleteConfirm: 'La modification ne peut pas être annulée !',
    // sweetalert to confirm: '',
    sweetAlertConfirmBtnText: 'Continuer',
    sweetAlertCancelBtnText: 'Annuler',

    // inbox: '',
    mailInboxCreateEmail: 'Écrire',
    mailInboxFolderAll: 'Tout',
    mailInboxFolderUnread: 'Non lu',
    mailInboxFolderImportant: 'Important',
    mailInboxFolderSent: 'Envoyé',
    mailInboxMessageLabel: 'Messages',
    mailDetailReply: 'Répondre',
    mailDetailFrom: 'De',
    mailDetailTo: 'Pour',
    mailSentCopy: 'Copier',
    mailSentBlankCopy: 'Copie cachée',
    mailSelectTemplate: 'Sélectionner un modèle',
    mailSelectFiles: 'Ajouter des fichiers',
    mailSelectFile: 'Ajouter le fichier',

    // pagination: '',
    pageLabel: 'Page',
    pageSeparator: 'de',
    pageFirst: 'Première page',
    pageNext: 'Page suivante',
    pagePrevious: 'Page précédente',
    pageLast: 'Dernière page',

    // company form: '',
    companyTabIdentification: '1- Identification du client',
    companyTabHistoryRecruitment: '2- Historique de recrutement',
    companyTabConditions: '3- Conditions de travail et salaires',
    companyName: 'Nom de la société selon le Registraire des Entreprises',
    companyImageUrl: 'Logo de l’entreprise',
    mainAddressStreet: 'Adresse principale',
    mainAddressStreetPlaceHolder: 'Rue et numéro de rue',
    mainAddressOffice: 'Bureau',
    mainAddressCity: 'Ville',
    mainAddressProvince: 'Province',
    mainAddressPostalCode: 'Code postal',
    isJobSameMainAddress: 'Le lieu de travail est-il le même que l’adresse principale?',
    jobAddressStreet: 'Adresse',
    jobAddressOffice: 'Bureau',
    jobAddressCity: 'Ville',
    jobAddressProvince: 'Province',
    jobAddressPostalCode: 'Code postal',
    haveAnotherJobAddress: 'Vous souhaitez ajouter un autre lieu de travail ?',
    otherJobAddressStreet: 'Adresse',
    otherJobAddressOffice: 'Bureau',
    otherJobAddressCity: 'Ville',
    otherJobAddressProvince: 'Province',
    otherJobAddressPostalCode: 'Code postal',
    coordinatesPhone: 'Numéro de téléphone principal',
    coordinatesFax: 'Numéro de fax',
    coordinatesWebsiteLink: 'Site web de l’entreprise',
    contactName: 'Nom de la personne de contact',
    contactTitle: 'Titre',
    contactPhone: 'Numéro de téléphone',
    contactPoste: 'Poste',
    contactEmail: 'Adresse courriel',
    hasSecondaryContact: 'Ajouter une autre personne de contact',
    secondaryContactName: 'Nom de la personne de contact secondaire',
    secondaryContactTitle: 'Titre',
    secondaryContactPhone: 'Numéro de téléphone',
    secondaryContactPoste: 'Poste',
    secondaryContactEmail: 'Adresse courriel',
    companySigner: 'Qui sera le signataire ?',
    companySignerText: 'Le signataire doit être le propriétaire, le franchisé, le gérant principal ou le dirigeant principal (président, vice-président, etc.)',
    companySignerEmail: 'Courriel de la personne qui signe',
    accountsPayableResponsible: 'Nom de la personne responsable des comptes fournisseurs',
    accountsPayableResponsibleEmail: 'E-mail du responsable des comptes fournisseurs',
    companyIsFranchise: 'L’entreprise est-elle une franchise ?',
    companyOrganizationType: 'Quel est le type d’organisation de l’entreprise ?',
    companyFoundationDate: 'Date de début de l’entreprise',
    companyBusinessNumber: 'Numéro d’entreprise de l’ARC',
    companyNEQ: 'Numéro d’entreprise du Québec (NEQ)',
    companyRevenueOverflow: 'L’entreprise a réalisé un chiffre d’affaires brut annuel de plus de 5 millions de dollars au cours de son dernier exercice financier.',
    companyAllowEDSC: 'L’entreprise reçoit-elle un soutien du programme de partage du travail d’EDSC?',
    companyEDSCDescription: 'Détails du programme',
    companyJobPositionSyndicate: 'Le poste à pourvoir est syndiqué',
    companyJobPositionSyndicateName: 'Nom du syndicat',
    companyJobPositionSyndicateLocal: 'Nom de la convention collective',
    hasInternationalRecruitmentBefore: 'L’entreprise a-t-elle déjà recruté des travailleurs dans le cadre du programme des travailleurs étrangers temporaires?',
    hasEmployeeBenefit: 'L’entreprise offre-t-elle des avantages sociaux à ses employés?',
    benefitDisabilityInsurance: 'Assurance invalidité',
    benefitDentalInsurance: 'Assurance dentaire',
    benefitPensionPlan: 'Régime de retraite fourni par l’employeur',
    benefitMedicalInsurance: 'Assurance médicale complémentaire (médicaments, soins paramédicaux, etc.)',
    benefitWorkWear: 'Vêtements de travail',
    benefitWorkShoes: 'Bottes de travail',
    benefitVisionGlasses: 'Lunettes adaptées à la vue',
    benefitOther: 'Un autre avantage',
    hasAdditionalVacation: 'L’entreprise offre-t-elle plus de jours fériés légaux que ne le stipulent les normes du travail?',
    additionalVacationDescription: 'Expliquer',
    hoursPerWeekAllowed: 'Combien d’heures par semaine les travailleurs étrangers travailleront-ils?',
    overtimeHoursAllowedAfter: 'Les heures supplémentaires sont calculées après',
    overtimeHoursAllowedAfterDetail: 'Détails',
    quarterSchedule: 'Dans quel quart de travail les travailleurs étrangers devront-ils travailler?',
    quarterScheduleDetail: 'Détails',
    salaryPaymentFrecuency: 'Quelle est la fréquence de paiement?',
    hasBonus: 'L’entreprise offre-t-elle des primes à ses employés (quart de soir, bonus de production ou autre)?',
    bonusType: 'Type de prime',
    bonus: 'Type de bonus',
    bonusDetail: 'Détails',
    workShift: 'Horaire de travail',
    workShiftInit: 'Heure de début et heure de fin',
    workShiftEnd: 'Heure de fin',
    workShiftComment: 'Commentaires',
    employeeMustProvideToolsOrEquipment: 'L’employé doit-il fournir des outils ou de l’équipement à son arrivée',
    recruitmentJobPositionTitle: 'Métier',
    recruitmentAcceptanceDate: 'Date d’acceptation',
    recruitmentInCourse: 'En cours',
    recruitmentSalaryType: 'Traitement régulier haut / Bas salaire',
    recruitmentSimplifiedProcessing: 'Traitement simplifié',
    recruitmentSalary: 'Salaire actuel du travailleur',
    recruitmentComment: 'Commentaire',
    recruitmentTableHeader1: 'Demande d’étude d’impact sur le marché du travail (EIMT)',
    recruitmentTableHeader2: 'Type d’EIMT',
    detailLabel: 'Détails',

    // company jobs: '',
    companyTabIdentificationJob: 'Identification du poste à combler',
    jobPositionTitle: 'Quel est le titre du poste à combler ?',
    jobPositionNumber: 'Combien de travailleurs sont nécessaires pour ce poste?',
    salaryPerHour: 'Quel sera le salaire horaire disponible pour les travailleurs étrangers?',
    jobDescription: 'Description détaillée de la tâche',
    jobDescriptionDocumentUrl: 'Joindre une description de tâches',
    miscellaneousConditions: 'Conditions supplémentaires',
    employmentStatusId: 'Statut d’emploi',
    monthsEmploymentPerYear: 'Indiquez le nombre de mois par an',
    educationalLevelId: 'Niveau d’éducation',
    yearsOfExperience: 'Années d’expérience',

    // employés de l'entreprise
    companyTabEmployeeTitle: 'Main-d’œuvre au sein de l’enterprise',
    companyTabEmployeeTab1Title: 'NOMBRE D’EMPLOYÉS',
    companyTabEmployeeTab2Title: 'RELEVÉS D’EMPLOI',
    alienEmployeeTitle: '', // 'L’entreprise a-t-elle recruté des travailleurs étrangers en 2014 ou avant?  Si OUI :Du 25 mai au 20 juin, combien y avait-il',
    fullTimeEmployees: 'D’employés à temps plein (incluant les T.E.T)',
    partTimeEmployees: 'D’employés à temps partiel (moins de 30 heures), incluant les T.E.T)',
    temporaryEmployees: 'De travailleur étranger temporaire',
    nationalEmployeeTitle: 'Nombre total d’employés À L’ÉCHELLE NATIONALE sous le numéro d’ARC cité plus haut',
    localEmployeeTitle: 'Nombre total d’employés SUR LE LIEU DE TRAVAIL UNIQUEMENT',
    localWorkingEmployeeTitle: 'Nombre total d’employés OCCUPANT LE POSTE VISÉ sur LE LIEU DE TRAVAIL',
    otherWorkingEmployeeTitle: 'Nombre total d’employés SUR UN AUTRE LIEU DE TRAVAIL',
    companyEmployeeJobTitle: 'Titre du poste',
    temporaryFullTimeEmployees: 'Nombre de travailleurs étrangers temporaires à temps plein',
    temporaryPartTimeEmployees: 'Nombre de travailleurs étrangers temporaires à temps partiel',
    canadianFullTimeEmployees: 'Nombre de travailleurs canadiens à temps plein',
    canadianPartTimeEmployees: 'Nombre de travailleurs canadiens à temps partiel',

    temporaryRegisterEmployees: 'Nombre de relevés d’emploi émis au cours des 12 derniers mois pour des travailleurs canadiens occupant le poste visé',
    canadianRegisterEmployees: 'Nombre de relevés d’emploi émis au cours des 12 derniers mois pour des travailleurs étrangers temporaires occupant le poste visé',
    canadianRegisterQuestion: 'Combien de relevés émis pour',
    registerEmployeesJobTitle: 'Titre du poste',
    registerEmployeesBackToSchool: 'Retour aux études',
    registerEmployeesSickness: 'Maladie / blessure',
    registerEmployeesVoluntaryResignation: 'Départ volontaire',
    registerEmployeesMaternity: 'Maternité / Paternité / Parental',
    registerEmployeesRetirement: 'Retraite',
    registerLackOfWork: 'Manque de travail',
    registerEmployeesDismissal: 'Congédiement',
    registerEmployeesOther: 'Motif exact du congédiement',
    registerEmployeesReturn: 'Combien de travailleurs sont revenus?',
    addJobButton: 'Ajouter des informations pour un autre poste',
    addJobNewSection: 'Total d\'employés sur un autre lieu de travail',
    removeSectionButton: 'Supprimer la section',

    fileGovermentTab1Title: 'Affichages de l’offre d’emploi',
    fileGovermentTab2Title: 'Documents supplémentaires à joindre',
    companyTabFilesTitle: 'Fichiers gouvernementaux',
    fileGovermentTableColumn1: 'Remplir le fichier',
    fileGovermentTableColumn2: 'Type de document à télécharger',
    fileGovermentTableColumn3: 'Nom du fichier téléchargé',
    fileGovermentTableColumn4: 'Actions',
    fileGovermentConsentImportant: 'IMPORTANT',
    fileGovermentConsent: `Il est essentiel que tous les affichages demeurent actifs pour toute la durée du processus de recrutement, soit jusqu’à la réception des acceptations 
    gouvernementales fédérale et provinciale. SVP s’assurer de les renouveler avant leur expiration.`,
    fileGovermentConsentCheckbox: 'J’ai compris',
    fileGovermentLowSalaries: 'Instructions A: Postes à bas salaire – Afficher sur ces 4 plates-formes',
    fileGovermentHighSalaries: 'Instructions B: Postes à haut salaire – Afficher sur ces 3 plates-formes',
    fileGovermentExemption: 'Instructions C: Exemption d’affichage – Aucun affichage requis',
    fileAdditionalFiles: 'Preuve de capacité financière',

    Yes: 'OUI',
    No: 'NON',
    createUser: 'Créer un utilisateur pour l’entreprise',
    accessDeniedPage: 'Vous n’avez pas la permission d’accéder à cette page...',

    loginEmail: 'Courrier électronique',
    loginPassword: 'Mot de passe',
    loginBtnLabel: 'Connexion',
    loginBtnAuthLabel: 'Authentification...',
    loginForgotPasswordLabel: 'Vous avez oublié votre mot de passe? Récupérez-le ',
    loginForgotPasswordLinkLabel: 'ici',
    forgotPasswordBtn: 'Récupérer le mot de passe',
    forgotPasswordInProcessBtn: 'En cours...',
    forgotPasswordBackLoginLabel: 'Retour à ',
    forgotPasswordBackLoginLinkLabel: 'connexion',
    loginCopyRight: 'Tous droits réservés',
    loginNeedUser: 'Vous n\'avez pas d\'utilisateur? Inscrivez-vous ',
    loginHelpValidateAccount: 'Avez-vous besoin d\'aide pour valider votre compte? Cliquez ',
    forgetPasswordMessageToResetPassword: 'Un message a été envoyé à votre adresse e-mail pour réinitialiser votre mot de passe',
    registerName: 'Prénoms',
    registerLastName: 'Nom de famille',
    registerPhone: 'Téléphone',
    registerJobApply: 'Appliquer le poste',
    registerConfirmPassword: 'Confirmer mot de passe',
    registerInProcessLabel: 'Enregistrement d\'un utilisateur...',
    registerBtnLabel: 'Enregistrez-vous',
    registerAlreadyHaveUser: 'Vous êtes déjà inscrit, Accéder ',
    registerWelcomeMessage: 'Merci pour votre inscription, un email vous a été envoyé pour vérifier votre compte',
    validateBtnLabel: 'Valider le compte',
    validateConfirmMessage: 'Un message a été envoyé à l\'administrateur système pour l\'aider à valider le compte',

    exitWithoutSave: 'Il y a des données que vous n’avez pas enregistrées, voulez-vous quitter sans enregistrer?',

    errorWhenSave: 'Une erreur est survenue lors de la sauvegarde des données.',
    errorWhenLoad: 'Une erreur est survenue lors du chargement des données.',
    errorWhenDelete: 'Une erreur est survenue lors de la suppression des données.',
    successWhenSave: 'Les données ont été enregistrées avec succès',
    successWhenDelete: 'Les données ont été supprimées avec succès',
    errorWhenLoadMessages: 'Une erreur s\'est produite lors du chargement des messages',
    errorWhenEnableTest: 'Une erreur s\'est produite lors de l\'activation du test',
    successWhenEnableTest: 'Le test a été activé avec succès pour l\'utilisateur',
    enableTermanTest: 'Activer le test terman',
    enableDiscTest: 'Activer le test DISC',
    successWhenDeleteTest: 'Le test a été supprimé avec succès',
    askEnalbeTest: 'Êtes-vous sûr de vouloir activer le test?',
    askDeleteTest: 'Êtes-vous sûr de vouloir supprimer le test?',
    deleteTestForApplicant: 'Supprimer le test pour ce candidat',
    errorWhenSaveTest: 'Une erreur s\'est produite lors de l\'enregistrement des données de test',
    errorToStartTest: 'Une erreur s\'est produite lors de la tentative de démarrage du test',
    errorWhenDeleteFile: 'Une erreur s\'est produite lors de la tentative de suppression du fichier',
    successWhenDeleteFile: 'Le fichier a été supprimé avec succès',
    errorWhenLoadFiles: 'Une erreur s\'est produite lors du chargement des fichiers',
    errorWhenSentMail: 'Une erreur s\'est produite lors de l\'envoi du mail',
    successWhenSentEmail: 'L\'e-mail a été envoyé avec succès',
    allowOnlyPdf: 'Seuls les fichiers PDF sont autorisés',
    errorWhenLoadMails: 'Une erreur s\'est produite lors du chargement des e-mails',
    userNeedLoginAgain: 'Le mot de passe a été mis à jour, l\'utilisateur doit se reconnecter',
    userSavedAndNeedLoginAgain: 'Vos données ont été mises à jour, si vous avez changé votre mot de passe, vous devez vous reconnecter',
    userProvideEmail: 'Vous devez saisir l\'e-mail',
    userProvideEmailOrSelect: 'Select a user or add an email and password',
    userProvideThePassword: 'Vous devez saisir le mot de passe',
    userProvideCurrentPassword: 'Vous devez saisir le mot de passe actuel',
    userProvidePassword: 'Vous devez entrer le nouveau mot de passe',
    userConfirmPassword: 'Vous devez confirmer le nouveau mot de passe',
    userPasswordDontMatch: 'Les mots de passe ne correspondent pas.',
    userProvideToken: 'Vous devez entrer le jeton qui a été envoyé à votre adresse e-mail ou en demander un nouveau',
    userDeleteSuccess: 'Utilisateur supprimé avec succès',
    errorWhenRequestToken: 'Une erreur s\'est produite lors de la demande du jeton',
    codeSentToEmail: 'Un code a été envoyé sur votre email',
    userErrorWhenValidate: 'Une erreur s\'est produite lors de la validation de l\'utilisateur',
    userSuccessWhenValidate: 'L\'utilisateur a été validé avec succès',
    userDeleteAllData: 'Supprimer toutes les données utilisateur',
    userAskToDelete: 'Êtes-vous sûr de vouloir supprimer l\'utilisateur et toutes ses données?',
    userValidateManual: 'Valider l\'utilisateur manuellement',
    userAskValidateManual: 'Êtes-vous sûr de vouloir valider l\'utilisateur?',
    usersDeleteAllData: 'Supprimer toutes les données des utilisateurs sélectionnés',
    usersAskToDelete: 'Êtes-vous sûr de vouloir supprimer les utilisateurs et toutes leurs données?',
    usersValidateManual: 'Valider manuellement les utilisateurs sélectionnés',
    usersAskValidateManual: 'Êtes-vous sûr de vouloir valider les utilisateurs?',
    candidateAssociatedToCompany: 'Les candidats sélectionnés ont été associés à l\'entreprise',
    errorWhenCreateUser: 'Une erreur s\'est produite lors de la création de l\'utilisateur',
    successWhenCreateUser: 'L\'utilisateur a été créé avec succès',
    notAllowPermissionToAction: 'Vous n\'êtes pas autorisé à effectuer cette action',

    notifyAdmin: 'L\'administrateur a été informé que vous avez rempli toutes les informations.',
    unknowError: 'Une erreur s\'est produite sur le serveur, contactez l\'administrateur système.',
    associatedUser: 'Utilisateur',

    logoutSuccess: 'À bientôt...',

    pickerImageTitle: 'Changement de l\'image de profil',
    pickerImageLegend: 'Veuillez sélectionner une option pour la photo:',
    pickerImageText1: 'Prendre une image de ma galerie',
    pickerImageText2: 'Prendre une image avec mon appareil photo',
    pickerImageText3: 'Annuler',

    errorWhenAuthenticate: 'Une erreur s\'est produite lors de l\'authentification',
    errorWhenChangePassword: 'Une erreur s\'est produite lors de la modification du mot de passe',
    errorWhenValidateAccount: 'Une erreur s\'est produite lors de la validation du compte',
    validatingAccount: 'VALIDATION DU COMPTE...',
    accountValidateSuccess: 'Votre compte a été validé avec succès',
    validationSweetTitle: 'Validation du compte',
    validatePositionSelection: 'Vous devez sélectionner le poste',
    errorWhenRegisterUser: 'Une erreur s\'est produite lors de l\'enregistrement de l\'utilisateur',
    selectLabel: 'Sélectionner',
    errorWhenRequestPassword: 'Une erreur s\'est produite lors de la demande de modification de votre mot de passe',
    resetPasswordSweetTitle: 'Réinitialisation du mot de passe',
    errorWhenRequestValidateAccount: 'Une erreur s\'est produite lors de la demande de validation',
    fileTypeSelectedAlreadyExists: 'Le type de fichier sélectionné existe déjà',
    notSelectedFileInLine: 'Vous n\'avez pas sélectionné le fichier sur la ligne',
    notSelectedFileTypeInLine: 'Vous n\'avez pas sélectionné le type de fichier dans la ligne',
    candidateAddedToNewFase: 'Les candidats ont été ajoutés à la nouvelle phase',
    selectFase: 'Sélectionner la phase',
    changeFase: 'Changer de phase',
    changeTagColor: 'Ajout d\'une couleur de tag aux candidats sélectionnés',
    addTagColor: 'Ajouter une couleur de tag',
    chooseTagColor: 'Choisir la balise',
};

export default Lang;
