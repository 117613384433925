/* eslint-disable no-undef */
import React, { useContext } from 'react';
import AppContext from '../../../config/appContext';
import TSFButton from '../../actionButtons/tsfButton';

function ErrorPage() {
    const { languageTitles } = useContext(AppContext);

    return (
        <div className="error">
            <div className="error-code">404</div>
            <div className="error-content">
                <div className="error-message">{languageTitles.accessDeniedPage}</div>
                <div>
                    <TSFButton
                        action="custom"
                        btnColor="info"
                        label={languageTitles.backTitle}
                        iconClass="fa-arrow-left"
                        onClick={(e) => { e.preventDefault(); window.history.back(); }}
                    />
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
