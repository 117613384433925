import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import AppContext from '../../../config/appContext';

import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';
import useMessageHelper from '../../../helpers/messageHelper';

function UserDropdown(props) {
    const { showNotification } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    const {
        returnSelectedValue, name, value, placeHolder, className, companyUsers,
    } = props;

    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');

    const query = companyUsers ? ApplicantsGraphql.GET_USER_COMPANY_LIST_DROPDOWN : ApplicantsGraphql.GET_USER_LIST_DROPDOWN;
    const [getData] = useLazyQuery(query, {
        onCompleted: (res) => {
            if (res.applicantDropdown) {
                const dataProcessed = res.applicantDropdown.map((c) => ({
                    value: c.UserId,
                    label: c.FullName,
                }));

                setData(dataProcessed);
            }

            if (res.usersCompanyDropdown) {
                const dataProcessed = res.usersCompanyDropdown.map((c) => ({
                    value: c.UserId,
                    label: c.FullName,
                }));

                setData(dataProcessed);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        getData();
    }, []);

    const onChange = (selectedOption, options) => {
        returnSelectedValue(selectedOption?.value ?? 0, options);
    };

    const selectedValue = data.filter((c) => Number(c.value) === Number(value))
        .map((c) => (
            {
                value: c.value,
                label: c.label,
            }
        ));

    const pagingData = search === '' ? data.slice(0, 50)
        : data.filter((c) => c.label.toLowerCase().includes(search.toLowerCase()));

    return (
        <Select
            isClearable
            name={name}
            onChange={(selectedOption, options) => onChange(selectedOption, options)}
            options={pagingData}
            value={selectedValue}
            placeholder={placeHolder}
            Clearable
            className={className}
            onInputChange={(val) => { if (val.length > 3) setSearch(val); }}
        />
    );
}

UserDropdown.propTypes = {
    returnSelectedValue: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    placeHolder: PropTypes.string,
    className: PropTypes.string,
    companyUsers: PropTypes.bool,
};

UserDropdown.defaultProps = {
    value: 0,
    placeHolder: 'Seleccione',
    className: '',
    companyUsers: false,
};

export default UserDropdown;
