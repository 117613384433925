/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
import React, { useContext, useReducer, useEffect } from 'react';
import {
    Button, ButtonGroup, Modal, ModalBody,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactTags from 'react-tag-autocomplete';
import ReactQuill from 'react-quill';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import AppContext from '../../../config/appContext';
import { FetchPolicy, FileType, NotificationType } from '../../../enums/general';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';
import FileRepository from '../uploadFile/fileRepository';
import EmailTemplateV2 from '../email/emailTemplateV2';
import TSFButton from '../../actionButtons/tsfButton';

import './documents.css';

import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    ADD_ITEM: 'addItem',
    DELETE_ITEM: 'deleteItem',
    SET_FILES_AFTER_DELETE: 'setFilesAfterDelete',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.ADD_ITEM: {
        const { type, item } = action.value;
        const newState = {
            applicantSelected: state.applicantSelected,
            emailCopy: state.emailCopy,
            emailBBCopy: state.emailBBCopy,
        };

        switch (type) {
        case 'applicant':
            newState.applicantSelected.push(item);
            break;
        case 'copy':
            newState.emailCopy.push(item);
            break;
        case 'copyBB':
            newState.emailBBCopy.push(item);
            break;

        default:
            break;
        }

        return {
            ...state,
            ...newState,
        };
    }
    case ACTION_TYPES.DELETE_ITEM: {
        const { type, item } = action.value;
        const newState = {
            applicantSelected: state.applicantSelected,
            emailCopy: state.emailCopy,
            emailBBCopy: state.emailBBCopy,
        };

        switch (type) {
        case 'applicant':
            newState.applicantSelected = newState.applicantSelected.filter((c) => c.id !== item.id);
            break;
        case 'copy':
            newState.emailCopy = newState.emailCopy.filter((_, i) => i !== item.id);
            break;
        case 'copyBB':
            newState.emailBBCopy = newState.emailBBCopy.filter((_, i) => i !== item.id);
            break;

        default:
            break;
        }

        return {
            ...state,
            ...newState,
        };
    }
    case ACTION_TYPES.SET_FILES_AFTER_DELETE: {
        const newArr = state.Files.filter((c) => c.FileId !== action.idDeleting);

        return {
            ...state,
            Files: newArr,
            idDeleting: 0,
        };
    }
    default:
        return state;
    }
};

function DocumentSender(props) {
    const { languageTitles, loggedUser, showNotification } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    const {
        showInPopup, onClosePopup, openPopup, ids, comeFromEmails,
    } = props;

    const initState = {
        applicantSelected: [],
        emailCopy: [],
        emailBBCopy: [],
        filesSelected: [],
        emailContent: '',
        subject: '',
        editor: {
            height: (window.innerHeight > 500) ? window.innerHeight - 438 : 300,
        },
        isDirty: false,
        openSelectDocuments: false,
        openSelectTemplate: false,
        emailsSuggestion: [
            { id: 3, name: 'Bananas' },
            { id: 4, name: 'Mangos' },
            { id: 5, name: 'Lemons' },
            { id: 6, name: 'Apricots' },
        ],
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        emailCopy,
        emailBBCopy,
        emailContent,
        editor, subject, openSelectDocuments,
        openSelectTemplate, filesSelected, applicantSelected,
        emailsSuggestion,
    } = state;

    const [getData, { loading }] = useLazyQuery(ApplicantsGraphql.GET_EMAIL_LIST_USER, {
        variables: {
            paging: {
                page: 1,
                limit: 10,
            },
            usePaging: false,
        },
        onCompleted: (res) => {
            if (res.users) {
                const {
                    data, success, message, error,
                } = res.users;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoadFiles,
                        NotificationType.DANGER,
                    );
                    return;
                }

                const mappedData = data.map((item) => (
                    {
                        id: item.UserId,
                        name: item.Email,
                    }
                ));

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        emailsSuggestion: mappedData,
                    },
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [sendEmail, { loading: isSaving }] = useMutation(ApplicantsGraphql.SEND_EMAIL, {
        onCompleted: (mutationData) => {
            if (mutationData?.sendEmail) {
                const {
                    success, message, error,
                } = mutationData?.sendEmail;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSentMail,
                        NotificationType.DANGER,
                    );
                    return;
                }

                showNotification(languageTitles.successWhenSentEmail, NotificationType.SUCCESS);

                onClosePopup();
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const dispatchEvent = (value, type = ACTION_TYPES.SET_STATE_VALUES) => {
        dispatch({
            type,
            value,
        });
    };

    const onDeleteItem = (val, type) => {
        dispatchEvent({
            type,
            item: val,
        }, ACTION_TYPES.DELETE_ITEM);
    };

    const onAddItem = (val, type) => {
        dispatchEvent({
            type,
            item: val,
        }, ACTION_TYPES.ADD_ITEM);
    };

    const onCloseFileOrTemplatePopup = (type) => {
        dispatchEvent({
            openSelectDocuments: type === 'files' ? false : openSelectDocuments,
            openSelectTemplate: type === 'template' ? false : openSelectTemplate,
        });
    };

    const onSelectedItems = (items, type) => {
        if (type === 'template') {
            dispatchEvent({
                subject: items.TemplateName,
                emailContent: items.Template,
                openSelectTemplate: false,
            });
        } else if (type === 'files') {
            dispatchEvent({
                filesSelected: items.map((c) => ({
                    FileId: c.FileId,
                    FileTitle: c.FileTitle,
                })),
                openSelectDocuments: false,
            });
        }
    };

    const onSendEmail = () => {
        sendEmail({
            variables: {
                record: {
                    subject,
                    content: emailContent,
                    fileIds: filesSelected.map((c) => c.FileId),
                    userIds: applicantSelected.map((c) => c.id),
                },
            },
        });
    };

    const onClearEmail = () => {
        dispatchEvent({
            filesSelected: [],
            emailContent: '',
            subject: '',
            applicantSelected: [],
            emailCopy: [],
            emailBBCopy: [],
            isDirty: false,
        });
    };

    useEffect(() => {
        if (ids && ids.length > 0) {
            dispatchEvent({
                applicantSelected: ids,
            });
        }
    }, [ids]);

    useEffect(() => {
        getData();
    }, []);

    const mainContent = (
        <div className="mailbox mailboxv2">
            {!comeFromEmails && (
                <div className="mailbox-sidebar">
                    <div className="mailbox-sidebar-content collapse d-lg-block show">
                        <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                            <div className="table-responsive " style={{ height: '100%' }}>
                                <table className="table table-striped mb-0 align-middle">
                                    <tbody>
                                        { applicantSelected?.map((item) => (
                                            <tr key={item.id}>
                                                {/* <td>{item.FullName}</td> */}
                                                <td>{item.name}</td>
                                                <td className="with-btn text-nowrap text-end">
                                                    <Button
                                                        color="danger"
                                                        size="xs"
                                                        className="me-1 mb-1"
                                                        onClick={() => onDeleteItem(item, 'applicant')}
                                                    >
                                                        <i className="fa fa-trash-alt" />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
            ) }
            <div className={`mailbox-content ${comeFromEmails ? 'forceFullWidth' : ''}`}>
                <div className="mailbox-content-body">
                    <form className="mailbox-form">
                        {(comeFromEmails || !comeFromEmails) && (
                            <div className="mailbox-to">
                                <span className="control-label">
                                    {languageTitles.mailDetailTo}
                                    :
                                </span>
                                <ReactTags
                                    placeholderText="Elegir correo"
                                    tags={applicantSelected}
                                    suggestions={emailsSuggestion}
                                    onDelete={(val) => {
                                        const applicantId = state.applicantSelected?.length > 0 ? state.applicantSelected[val].id : 0;
                                        onDeleteItem({ id: applicantId }, 'applicant');
                                    }}
                                    onAddition={(val) => onAddItem(val, 'applicant')}
                                />
                            </div>
                        ) }
                        <div className="mailbox-to">
                            <span className="control-label">
                                {languageTitles.mailSentCopy}
                                :
                            </span>
                            <ReactTags
                                placeholderText="Agregar correo"
                                tags={emailCopy}
                                allowNew
                                onDelete={(val) => onDeleteItem({ id: val }, 'copy')}
                                onAddition={(val) => onAddItem(val, 'copy')}
                            />
                        </div>
                        <div className="mailbox-to">
                            <span className="control-label">
                                {languageTitles.mailSentBlankCopy}
                                :
                            </span>
                            <ReactTags
                                placeholderText="Agregar correo"
                                tags={emailBBCopy}
                                allowNew
                                onDelete={(val) => onDeleteItem({ id: val }, 'copyBB')}
                                onAddition={(val) => onAddItem(val, 'copyBB')}
                            />
                        </div>
                        <div className="mailbox-subject">
                            <input
                                type="text"
                                value={subject}
                                className="form-control"
                                placeholder="Titulo"
                                onChange={(e) => dispatchEvent({
                                    subject: e.target.value,
                                })}
                            />
                        </div>
                        <div className="mailbox-subject">
                            <ButtonGroup style={{ paddingLeft: '10px' }}>
                                <Button
                                    color="primary"
                                    className="me-1 mb-1"
                                    onClick={() => dispatchEvent({
                                        openSelectTemplate: true,
                                    })}
                                >
                                    <i className="fa fa-plus fa-fw" />
                                    {languageTitles.mailSelectTemplate}
                                </Button>
                                <Button
                                    color="primary"
                                    className="me-1 mb-1"
                                    onClick={() => dispatchEvent({
                                        openSelectDocuments: true,
                                    })}
                                >
                                    <i className="fa fa-paperclip fa-fw" />
                                    {languageTitles.mailSelectFiles}
                                </Button>
                            </ButtonGroup>
                        </div>
                        { filesSelected && filesSelected.length > 0
                                            && (
                                                <div className="mailbox-subject">
                                                    <input
                                                        type="text"
                                                        value={filesSelected.map((c) => c.FileTitle).toString()}
                                                        className="form-control disabled"
                                                    />
                                                </div>
                                            )}
                        <div className="mailbox-input">
                            <ReactQuill
                                value={emailContent}
                                className="border-0"
                                onChange={(val) => dispatchEvent({ emailContent: val })}
                                style={{ height: `${editor.height}px`, marginBottom: '20px' }}
                            />
                        </div>
                    </form>
                </div>
                <div className="mailbox-content-footer d-flex align-items-center justify-content-end">
                    <TSFButton
                        action="cancel"
                        label={languageTitles.closeTitle}
                        onClick={onClosePopup}
                    />
                    <TSFButton
                        action="custom"
                        iconClass="fa-eraser"
                        btnColor="white"
                        label={languageTitles.clearBtnTitle}
                        onClick={onClearEmail}
                    />
                    <TSFButton
                        action="custom"
                        btnColor="yellow"
                        iconClass="fa-paper-plane"
                        label={languageTitles.sendTitle}
                        isWorking={isSaving}
                        onClick={onSendEmail}
                    />
                </div>
            </div>
            <FileRepository
                userId={loggedUser.UserId}
                applicantId={loggedUser.EntityId}
                filters={
                    [{ fieldName: 'ApplicantId', value: String(loggedUser.EntityId) }]
                }
                isEditing
                isRefreshing={loading}
                fileTypes={[FileType.FILES_TEMPLATE]}
                selectionMode
                showInPopup
                openPopup={openSelectDocuments}
                onClosePopup={() => onCloseFileOrTemplatePopup('files')}
                onSelectedItems={(items) => onSelectedItems(items, 'files')}
                defaultFileTypeId={FileType.FILES_TEMPLATE}
            />
            <EmailTemplateV2
                openPopup={openSelectTemplate}
                onClosePopup={() => onCloseFileOrTemplatePopup('template')}
                onSelectedItems={(items) => onSelectedItems(items, 'template')}
                showInPopup
            />
        </div>
    );

    if (showInPopup) {
        return (
            <Modal
                isOpen={openPopup}
                size="xl"
                toggle={onClosePopup}
            >
                {/* <ModalHeader toggle={onClosePopup}>Enviar Correo</ModalHeader> */}
                <ModalBody>
                    { mainContent }
                </ModalBody>
            </Modal>
        );
    }

    return mainContent;
}

DocumentSender.propTypes = {
    showInPopup: PropTypes.bool,
    onClosePopup: PropTypes.func,
    openPopup: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.object),
    comeFromEmails: PropTypes.bool,
};

DocumentSender.defaultProps = {
    showInPopup: false,
    onClosePopup: () => {},
    openPopup: false,
    ids: [],
    comeFromEmails: false,
};

export default DocumentSender;
