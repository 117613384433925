/* eslint-disable no-nested-ternary */
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import PermissionsGraphql from '../../servicesapollo/permissionGraphql';
import { FetchPolicy, NotificationType } from '../../enums/general';
import useCustomLocalStorage from './useCustomLocalStorage';
import useNotification from './useNotification';
import useMessageHelper from '../../helpers/messageHelper';

const usePermission = (currentPermissions, userId) => {
    const { showNotification } = useNotification();
    const { getErrorMessage } = useMessageHelper();
    const { getUserAuth, getCompany } = useCustomLocalStorage();
    const userCurrent = getUserAuth();

    const currentUserId = userId ?? userCurrent?.UserId;

    const [getData, { data, loading }] = useLazyQuery(PermissionsGraphql.GET_PERMISSION_BY_USER, {
        variables: {
            userId: currentUserId,
            onlyActive: true,
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (currentUserId > 0) getData();
    }, [currentUserId]);

    if (currentPermissions?.length > 0) return currentPermissions;

    const permissions = data?.permissionByUser?.data?.map((c) => c.AccessKey);
    const user = data?.user?.data;

    const selectedCompanyInLocalStorage = Number(getCompany());

    const companyInfo = (user?.Company?.length > 0 && user?.Company?.length === 1) ? user?.Company[0] : (
        user?.Company?.length > 1 && selectedCompanyInLocalStorage > 0 && user?.Company?.find((c) => c.CompanyId === selectedCompanyInLocalStorage)
            ? user?.Company?.find((c) => c.CompanyId === selectedCompanyInLocalStorage) : {
                ImageUrl: '',
                CompanyId: 0,
                CompanyName: '',
            }
    );

    return {
        permissions,
        userData: {
            UserId: user?.UserId,
            Email: user?.Email,
            UserType: user?.UserType,
            ProfilePicture: ['company', 'companyadmin'].includes(user?.UserType?.toLowerCase()) ? companyInfo.ImageUrl : user?.PictureURL,
            FullName: ['company', 'companyadmin'].includes(user?.UserType?.toLowerCase()) ? companyInfo.CompanyName : `${user?.Applicant?.Name} ${user?.Applicant?.LastName}`,
            EntityId: ['company', 'companyadmin'].includes(user?.UserType?.toLowerCase()) ? companyInfo.CompanyId : user?.Applicant?.ApplicantId,
            IsCompanyUser: ['company', 'companyadmin'].includes(user?.UserType?.toLowerCase()),
            Companies: user?.Company,
        },
        loading,
    };
};

export default usePermission;
