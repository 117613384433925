/* eslint-disable radix */
export default class ApplicantsMapping {
    static mapApplicantsByCompany(records) {
        const noFaseText = '-';
        if (records) {
            return records.map((data) => ({
                ApplicantId: data.ApplicantId,
                Name: data.Name,
                LastName: data.LastName,
                es: data.Fase?.Es || noFaseText,
                fr: data.Fase?.Fr || noFaseText,
                en: data.Fase?.En || noFaseText,
            }));
        }

        return [];
    }

    static mapApplicantsWithUser(records) {
        if (records) {
            return records.map((data) => ({
                ApplicantId: data.ApplicantId,
                Name: data.Name,
                LastName: data.LastName,
                UserId: data.UserId,
                PictureURL: data.User.PictureURL,
                Email: data.User.Email,
                ValidatedAccount: data.User.ValidatedAccount,
            }));
        }

        return [];
    }

    static mapApplicantsMainList(records) {
        if (records) {
            return records.map((data) => ({
                ApplicantId: data.ApplicantId,
                UserId: data.UserId,
                Name: data.Name,
                LastName: data.LastName,
                PictureURL: data.User.PictureURL,
                Email: data.Email,
                FaseId: data.FaseId,
                PhoneNumberOne: data?.PhoneNumberOne?.replaceAll(' ', ''),
                Age: data.Birthday ? parseInt((Date.now() - new Date(data.Birthday)) / (31557600000)) : '',
                CountryId: data.CountryId,
                ResidentCity: data.ResidentCity ?? '',
                ProfessionId: data?.ProfessionId ?? null,
                JobPositionIds: data.JobPositionIds?.split(',').map(Number),
            }));
        }

        return [];
    }

    static mapApplicant(data) {
        return {
            ...data,
            JobPositionIds: data?.JobPositionIds?.split(',').map(Number),
            JobPositions: data?.JobPositions?.split('|').map(Number),
        };
    }
}
