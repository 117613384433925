/* eslint-disable react/prop-types */
import React from 'react';

function FullContent(props) {
    const { children } = props;

    return (
        <div style={{ height: '100%', display: 'flex' }}>
            {children}
        </div>
    );
}

export default FullContent;
