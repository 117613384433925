/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, ButtonGroup } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PermissionsGraphql from '../../../servicesapollo/permissionGraphql';
import AppContext from '../../../config/appContext';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import { Panel, PanelHeader, PanelBody } from '../../panel/panel';

import './permission.css';
import ContentForProcess from '../../layout/content/contentForProcess';
import ErrorPage from '../extraPages/error';
import Access from '../../../enums/permissions';
import TSFButton from '../../actionButtons/tsfButton';
import UserDropdown from '../user/user-dropdown';
import FullContent from '../../layout/content/fullContent';
import useMessageHelper from '../../../helpers/messageHelper';

function Permissions() {
    const {
        darkTheme, languageTitles, loggedUser, showNotification, hasPermission,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    if (!hasPermission(Access.PERMISSION_ASSIGN)) return <ErrorPage />;

    const [records, setRecords] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [userId, setUserId] = useState(loggedUser.UserId);

    const { data, loading, refetch } = useQuery(PermissionsGraphql.GET_PERMISSION_BY_USER_TO_ASSIGN, {
        variables: {
            userId,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveData, { loading: isSaving }] = useMutation(PermissionsGraphql.ADD_PERMISSIONS_TO_USER, {
        onCompleted: (mutationData) => {
            if (mutationData.addPermisionsUser) {
                const {
                    success, message, error,
                } = mutationData.addPermisionsUser;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                setIsDirty(false);

                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSave = () => {
        saveData({
            variables: {
                record: {
                    UserId: userId,
                    PermissionIds: records?.filter((c) => c.Active).map((c) => c.PermissionId),
                },
            },
        });
    };

    const assingPermission = (permissionId, active) => {
        const result = records.map((c) => {
            const item = { ...c };
            if (c.PermissionId === permissionId) item.Active = !active;

            return item;
        });

        setRecords(result);
        setIsDirty(true);
    };

    useEffect(() => {
        if (!loading) {
            setRecords(data?.permissionByUser?.data);
        }
    }, [loading]);

    const helper = {};
    const newData = records?.reduce((current, record) => {
        const key = `${record.Category}`;

        if (!helper[key]) {
            helper[key] = {
                Category: key,
                Permissions: [{
                    PermissionId: record.PermissionId,
                    Description: record.Description,
                    Active: record.Active,
                    AccessKey: record.AccessKey,
                }],
            };

            current.push(helper[key]);
        } else {
            helper[key].Permissions.push({
                PermissionId: record.PermissionId,
                Description: record.Description,
                Active: record.Active,
                AccessKey: record.AccessKey,
            });
        }

        return current;
    }, []);

    return (
        <FullContent>
            <Panel>
                <PanelHeader>
                    <div className="panel-heading-btn justify-content-end">
                        <div className="col-md-4">
                            <UserDropdown
                                className="me-1"
                                returnSelectedValue={(val) => setUserId(val)}
                                name="user"
                                value={userId}
                                placeHolder={languageTitles.selectLabel}
                            />
                        </div>
                        <ButtonGroup className="pull-right">
                            <TSFButton
                                action="save"
                                isDisabled={isSaving || loading || !isDirty}
                                isWorking={isSaving}
                                onClick={onSave}
                            />
                            <TSFButton
                                action="refresh"
                                isWorking={loading}
                                isDisabled={isSaving || loading}
                                onClick={() => { setIsDirty(false); refetch(); }}
                            />
                        </ButtonGroup>
                    </div>
                </PanelHeader>
                <PanelBody>
                    <PerfectScrollbar className="h-100" options={{ suppressScrollX: false }}>
                        <div className="permissions">
                            {newData?.map((c) => (
                                <div className={`widget-todolist rounded mb-4 ${darkTheme ? 'inverse-mode ' : ''}`} key={c.Category}>
                                    <div className="widget-todolist-header">
                                        <div className="widget-todolist-header-title">{c.Category}</div>
                                    </div>
                                    <div className="widget-todolist-body">
                                        {c.Permissions?.map((item) => (
                                            <div className="widget-todolist-item col-xs-6 col-lg-3 col-sm-6 col-md-4" key={item.PermissionId}>
                                                <div className="widget-todolist-input">
                                                    <div className="form-check">
                                                        <input
                                                            checked={item.Active}
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={item.PermissionId}
                                                            onChange={() => assingPermission(item.PermissionId, item.Active)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="widget-todolist-content">
                                                    <h6 className="mb-2px">
                                                        {item.Description}
                                                    </h6>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            ))}
                        </div>
                    </PerfectScrollbar>
                </PanelBody>
            </Panel>
        </FullContent>
    );
}

export default Permissions;
