/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useReducer } from 'react';
import {
    Link, useParams, useHistory, useLocation,
} from 'react-router-dom';
import {
    UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle,
    Modal, ModalHeader, Card, Collapse, CardBody, Button, ModalBody,
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import DateRangePicker from 'react-daterange-picker';
import { useLazyQuery, useMutation } from '@apollo/client';
import SweetAlert from 'react-bootstrap-sweetalert';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Panel, PanelHeader, PanelBody } from '../../panel/panel';
import SearchField from '../../search-field/searchfield';
import Country from '../../country/country-dropdown';
import Profession from '../../profession/profession-dropdown';
import Jobs from '../../jobs/jobs-dropdown';
import AppContext from '../../../config/appContext';
import CompanyAssign from '../company/company-assign';
import Catalog from '../../catalog/catalog-dropdown';

import TagAssign from '../tags/tags';
import ProcessFaseAssign from '../process-fase/process-fase';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';
import { Catalogs, FetchPolicy, NotificationType } from '../../../enums/general';
import ApplicantsMapping from '../../../mapping/applicantsMapping';

import Access from '../../../enums/permissions';
import TestApplicantGraphql from '../../../servicesapollo/testApplicantGraphql';
import ErrorPage from '../extraPages/error';
import TSFButton from '../../actionButtons/tsfButton';
import DocumentSender from '../documents/documentSender';
import useDateHelper from '../../../helpers/dateHelper';

import './applicants.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-daterange-picker/dist/css/react-calendar.css';
import FullContent from '../../layout/content/fullContent';
import Paginator from '../../paginator/paginator';
import useMessageHelper from '../../../helpers/messageHelper';
import { pdfOptions } from '../../../helpers/pdfHelper';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
    LOAD_MORE: 'setLoadMore',
    SET_PRINT_REPORT: 'setPrintReport',
    SET_PRINT_APPLICANT_REPORT: 'setPrintApplicantReport',
    SET_CHECK_ITEM: 'setCheckItem',
    SET_APPROVE_ALL: 'setApproveAll',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return {
            ...action.value,
        };
    }
    case ACTION_TYPES.SET_PRINT_REPORT: {
        const {
            printFileType, pageOrAll, fireEvent,
        } = action.value;

        if (fireEvent) fireEvent(printFileType, pageOrAll);

        return { ...state, printFileType };
    }
    case ACTION_TYPES.SET_PRINT_APPLICANT_REPORT: {
        const {
            printApplicantId, fireEvent,
        } = action.value;

        if (fireEvent) fireEvent(printApplicantId);

        return { ...state, printApplicantId };
    }
    case ACTION_TYPES.SET_APPROVE_ALL: {
        const newRecords = state.records.map((item) => ({
            ...item,
            IsChecked: !action.value,
        }));

        return {
            ...state,
            records: newRecords,
        };
    }
    case ACTION_TYPES.SET_CHECK_ITEM: {
        const newRecords = state.records.map((item) => ({
            ...item,
            IsChecked: item.ApplicantId === action.value ? !item.IsChecked : item.IsChecked,
        }));

        return {
            ...state,
            records: newRecords,
        };
    }
    default:
        return state;
    }
};

function Applicants() {
    const {
        languageTitles, showNotification, setApplicantMessageId, hasPermission,
        loggedUser, coreCatalogs, currentLanguage, converToPdf,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const history = useHistory();
    const {
        pageNumber, pageResult,
    } = useParams();

    const { search: additionalPar } = useLocation();

    const searchParam = new URLSearchParams(additionalPar).get('srch');

    const { formatDate } = useDateHelper();

    const initState = {
        emailsToSend: [],
        openCompanyPopup: false,
        openProfessFasePopup: false,
        openTagPopup: false,
        openEmailPopup: false,
        filters: {
            CountryId: 0,
            JobId: 0,
            ProfessionId: 0,
            GenderId: 0,
            JobEmpty: false,
            AgeGreater: 0,
            AgeLess: 0,
            StartDate: null,
            EndDate: null,
        },
        records: [],
        total: 0,

        emailsMasiveToSend: [],

        isCalendarOpen: false,
        dateRangeSelectedLabel: null,
        // SpecialType: st || '',
        // FromDate: fromdate || '',
        openAdvanceSearch: false,

        isSearching: true,
        canPreviousPage: false,
        canNextPage: false,
        pendingPointSelected: null,

        showAlertAction: false,
        whichAction: '',
        actionTitle: '',
        actionDescription: '',
        itemSelected: null,
        additionalParams: additionalPar,
        // params
        sort: {
            fieldName: 'ApplicantId',
            dir: 'ASC',
        },
        search: searchParam || '', // keyword
        page: Number(pageNumber),
        limit: Number(pageResult) || 10,
        usePaging: true,
        pages: 0,
        printFileType: '',
        printApplicantId: 0,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        records, filters,
        emailsToSend, isCalendarOpen, dateRangeSelectedLabel,
        openAdvanceSearch, isSearching,
        canPreviousPage, canNextPage, pendingPointSelected,
        whichAction, actionTitle, actionDescription, itemSelected, showAlertAction,
        search, page, limit, usePaging, pages, sort, printFileType, printApplicantId,
        additionalParams,
    } = state;

    const jobPositionList = coreCatalogs.filter((c) => String(c.category.toLowerCase()) === Catalogs.POSITION.toLowerCase()).map((c) => (
        {
            value: c.value,
            label: c[currentLanguage],
        }));

    const countries = coreCatalogs.filter((c) => String(c.category.toLowerCase()) === Catalogs.COUNTRY.toLowerCase()).map((c) => (
        {
            value: c.value,
            label: c[currentLanguage],
        }));

    const professions = coreCatalogs.filter((c) => String(c.category.toLowerCase()) === Catalogs.PROFESSION.toLowerCase()).map((c) => (
        {
            value: c.value,
            label: c[currentLanguage],
        }));

    const dispatchEvent = (value, type = ACTION_TYPES.SET_STATE_VALUES) => {
        dispatch({
            type,
            value,
        });
    };

    const [getPercentageByApplicants] = useLazyQuery(ApplicantsGraphql.GET_PERCENTAGE_BY_APPLICANT, {
        onCompleted: (res) => {
            if (res.getPercentageByApplicant) {
                const {
                    data,
                } = res.getPercentageByApplicant;

                const newRecords = records.map((c) => {
                    const currentPercentage = data.find((x) => x.ApplicantId === c.ApplicantId);
                    return {
                        ...c,
                        PercentageFilled: currentPercentage.PercentageFilled,
                        PendingPoints: currentPercentage.PendingPoints,
                    };
                });

                dispatchEvent({
                    records: newRecords,
                });
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getData, { loading }] = useLazyQuery(ApplicantsGraphql.GET_APPLICANT_MAIN_LIST, {
        onCompleted: (res) => {
            if (res.applicantsMainList) {
                const {
                    data, total, success, message, error, pages: totalPages,
                } = res.applicantsMainList;

                const processData = ApplicantsMapping.mapApplicantsMainList(data);

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                getPercentageByApplicants({
                    variables: {
                        ids: data.map((c) => c.ApplicantId),
                    },
                });

                dispatchEvent({
                    records: processData,
                    total,
                    pages: totalPages,
                    isSearching: false,
                    canPreviousPage: page > 1,
                    canNextPage: page < totalPages,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [printList, { loading: printing }] = useLazyQuery(ApplicantsGraphql.PRINT_APPLICANT_LIST, {
        onCompleted: (res) => {
            if (res.printApplicantList) {
                const pdfBase64 = res.printApplicantList;
                converToPdf(pdfBase64, `ListDeCandidatos-${uuidv4()}.pdf`, 'A4', 'landscape');
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [printApplicantReport, { loading: printingReport }] = useLazyQuery(ApplicantsGraphql.PRINT_APPLICANT_REPORT, {
        onCompleted: (res) => {
            if (res.printApplicantReport) {
                const pdfBase64 = res.printApplicantReport;
                const cname = records.find((c) => c.ApplicantId === printApplicantId);
                converToPdf(pdfBase64, `Candidato-${cname.Name}-${cname.LastName}-${uuidv4()}.pdf`);

                dispatchEvent({
                    printApplicantId: 0,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);

            dispatchEvent({
                printApplicantId: 0,
            });
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onActionCancel = () => {
        dispatchEvent({
            showAlertAction: false,
            whichAction: '',
            actionTitle: '',
            actionDescription: '',
            itemSelected: null,
        });
    };

    const exportList = (type, pageOrAll) => {
        printList({
            variables: {
                paging: {
                    page,
                    limit,
                },
                sort,
                filters,
                search,
                usePaging: pageOrAll ?? true,
                fileType: type,
                options: JSON.stringify({
                    ...pdfOptions,
                    orientation: 'landscape',
                }),
            },
        });
    };

    const exportApplicant = (applicantId) => {
        printApplicantReport({
            variables: {
                id: applicantId,
                options: JSON.stringify(pdfOptions),
            },
        });
    };

    const [enableTest, { loading: allowingTest }] = useMutation(TestApplicantGraphql.ENABLE_TEST, {
        onCompleted: (mutationData) => {
            const { success, message, error } = mutationData?.addTestApplicant;

            if (!success) {
                if (message) {
                    showNotification(message, NotificationType.INFO);
                    return;
                }

                showNotification(
                    error?.length > 0 ? error : languageTitles.errorWhenEnableTest,
                    NotificationType.DANGER,
                );
                return;
            }

            showNotification(languageTitles.successWhenEnableTest, NotificationType.SUCCESS);

            onActionCancel();
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const callGetData = () => {
        const newUrl = `/applicants/page/${page}/show/${limit}${additionalParams}`;
        history.push(newUrl);

        getData({
            variables: {
                paging: {
                    page,
                    limit,
                },
                sort,
                filters,
                search,
                usePaging,
            },
        });
    };

    // handlers
    const setOrderBy = () => {
        // const { filters } = this.state;
        // filters.search = '';
        // filters.Page = 0;
        // filters.PageCounter = 0;
        // filters.hasMore = true;
        // filters.OrderBy = order;

        // this.setState({
        //     filters,
        //     records: [],
        //     isloading: false,
        // }, () => this.getApplicantsFromZero());
    };

    const handleInputChange = (e) => {
        e.preventDefault();

        const { target, target: { name, type } } = e;

        const targetValue = name.includes('Age') ? Number(target.value) : target.value;
        const value = type === 'checkbox' ? target.checked : targetValue;

        dispatchEvent({
            search: '',
            page: 1,
            filters: {
                ...filters,
                [name]: value,
            },
        });
    };

    const onDateRangeSelect = (value) => {
        dispatchEvent({
            search: '',
            page: 1,
            filters: {
                ...filters,
                StartDate: value.start,
                EndDate: value.end,
            },
            isCalendarOpen: false,
            dateRangeSelectedLabel: value,
        });
    };

    const setDropdownValue = (data, options) => {
        dispatchEvent({
            search: '',
            page: 1,
            filters: {
                ...filters,
                [`${options.name}`]: data,
            },
        });
    };

    const addToSendEmail = (element) => {
        emailsToSend.push({
            id: element.UserId,
            name: element.Email,
        });

        dispatchEvent({
            emailsToSend,
            openEmailPopup: true,
        });
    };

    // action buttons
    const onClickButton = (e, btnTarget, printCurrentPageOrAll) => {
        e.preventDefault();

        switch (btnTarget) {
        case 'btnexportpdf':
            dispatchEvent({
                printFileType: 'pdf',
                pageOrAll: printCurrentPageOrAll,
                fireEvent: exportList,
            }, ACTION_TYPES.SET_PRINT_REPORT);
            break;
        case 'btnexportxls':
            dispatchEvent({
                printFileType: 'xls',
                pageOrAll: printCurrentPageOrAll,
                fireEvent: exportList,
            }, ACTION_TYPES.SET_PRINT_REPORT);
            break;
        case 'btnrefresh':
            callGetData();
            break;
        case 'btnclearfilter':
            dispatchEvent({ ...initState, page: 1 }, ACTION_TYPES.SET_INITIAL_STATE);
            break;
        case 'searching':
            dispatchEvent({
                isSearching: true,
            });
            break;
        default:
            break;
        }
    };

    const onAction = (e, item, action) => {
        e.preventDefault();

        switch (action) {
        case 'enableterman':
            dispatchEvent({
                showAlertAction: true,
                whichAction: 'enableterman',
                actionTitle: languageTitles.enableTermanTest,
                actionDescription: languageTitles.askEnalbeTest,
                itemSelected: item,
            });
            break;
        case 'enabledisc':
            dispatchEvent({
                showAlertAction: true,
                whichAction: 'enabledisc',
                actionTitle: languageTitles.enableDiscTest,
                actionDescription: languageTitles.askEnalbeTest,
                itemSelected: item,
            });
            break;
        case 'showComments':
            setApplicantMessageId(item.ApplicantId);
            break;
        case 'sendEmail':
            addToSendEmail(item);
            break;
        case 'exportToPdf':
            dispatchEvent({
                printApplicantId: item.ApplicantId,
                fireEvent: exportApplicant,
            }, ACTION_TYPES.SET_PRINT_APPLICANT_REPORT);
            break;
        case 'processApplication':
            history.push(`/process-application/${item.ApplicantId}`);
            break;
        default:
            break;
        }
    };

    const onActionConfirm = () => {
        dispatchEvent({
            showAlertAction: false,
        });

        if (whichAction === 'enableterman' || whichAction === 'enabledisc') {
            enableTest({
                variables: {
                    record: {
                        ApplicantId: itemSelected.ApplicantId,
                        TypeTest: whichAction === 'enableterman' ? 1 : 2,
                    },
                },
            });
        }
    };

    const setPage = (type) => {
        switch (type) {
        case 'first':
            dispatchEvent({
                page: 1,
                isSearching: true,
            });

            break;
        case 'previous':
            dispatchEvent({
                page: page - 1,
                isSearching: true,
            });
            break;
        case 'next':
            dispatchEvent({
                page: page + 1,
                isSearching: true,
            });
            break;
        case 'last':
            dispatchEvent({
                page: pages,
                isSearching: true,
            });
            break;
        default:
            break;
        }
    };

    useEffect(() => {
        if (isSearching) callGetData();
    }, [isSearching]);

    const enableButtons = loading || printing || allowingTest || printingReport;

    const items = records?.map((item) => (
        <tr className={item.Tags !== null ? `table-${item.Tags}` : ''} key={item.ApplicantId}>
            <td>
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={`table_checkbox${item.ApplicantId}`}
                    checked={item.IsChecked}
                    onChange={() => dispatch({ type: ACTION_TYPES.SET_CHECK_ITEM, value: item.ApplicantId })}
                />
            </td>
            <td>{item.ApplicantId}</td>
            <td className="with-img">
                <img src={item.PictureURL ?? '/assets/img/user/user-13.jpg'} className="img-rounded height-50 zoom" alt="" />
            </td>
            <td>
                {(hasPermission(Access.APPLICANT_WRITE_OTHER) || Number(item.ApplicantId) === Number(loggedUser.EntityId)) && (
                    <Link to={`/applicant/${item.ApplicantId}`} className="">{`${item.Name} ${item.LastName}`}</Link>
                )}
                {!hasPermission(Access.APPLICANT_WRITE_OTHER) && Number(item.ApplicantId) !== Number(loggedUser.EntityId) && (
                    `${item.Name} ${item.LastName}`
                )}
                {' '}
                {`- ${item.Age ? `${item.Age} años` : ''}`}
                {' '}
                <br />
                {item.Email}
                {' '}
                <br />
                {countries?.find((c) => c.value === item.CountryId)?.label}
                {' '}
                -
                {' '}
                {item.ResidentCity}
                <br />
                <div className="progress">
                    <div
                        className={`progress-bar ${item.PercentageFilled < 60 ? 'bg-warning' : 'bg-primary'} fs-10px fw-bold`}
                        style={{ width: `${(item.PercentageFilled ?? 0)}%` }}
                    >
                        {`${item.PercentageFilled ?? 0} `}
                        {' '}
                        {languageTitles.dashboardProfileChartCompleteLabel}
                    </div>
                    <div
                        className="progress-bar bg-danger fs-10px fw-bold"
                        style={{ width: `${(100 - (item.PercentageFilled ?? 0))}%`, cursor: 'pointer' }}
                        onClick={() => dispatchEvent({ pendingPointSelected: item.PendingPoints })}
                    >
                        {`${100 - (item.PercentageFilled ?? 0)} `}
                        {' '}
                        {languageTitles.dashboardProfileChartPendingLabel}
                    </div>
                </div>
            </td>
            <td>
                {professions?.find((c) => c.value === item.ProfessionId)?.label}
                <br />
                {jobPositionList.filter((c) => item.JobPositionIds?.includes(c.value)).map((c) => c.label).join(', ')}
            </td>
            <td className="with-btn text-nowrap text-end">
                {(hasPermission(Access.APPLICANT_WRITE_OTHER) || Number(item.ApplicantId) === Number(loggedUser.EntityId)) && (
                    <Button
                        color="info"
                        size="sm"
                        title={languageTitles.candidatesEditBtnTitle}
                        className={`me-1 ${enableButtons ? 'disabled' : ''}`}
                        onClick={() => history.push(`/applicant/${item.ApplicantId}`)}
                    >
                        <i className="fa fa-pen" />
                    </Button>
                )}
                {hasPermission(Access.APPLICANT_GENERATE_PDF)
                && (
                    <Button
                        color="info"
                        size="sm"
                        title={languageTitles.candidatesExportBtnTitle}
                        className={`me-1 ${enableButtons ? 'disabled' : ''}`}
                        onClick={(e) => onAction(e, item, 'exportToPdf')}
                    >
                        {printingReport && printApplicantId === item.ApplicantId
                            ? <i className="fa fa-spinner fa-spin" />
                            : <i className="fa fa-file-pdf" />}

                    </Button>
                )}
                {hasPermission(Access.APPLICANT_SEND_EMAIL)
                && (
                    <Button
                        color="info"
                        size="sm"
                        title={languageTitles.candidatesSendEmailBtnTitle}
                        className={`me-1 ${enableButtons ? 'disabled' : ''}`}
                        onClick={(e) => onAction(e, item, 'sendEmail')}
                    >
                        <i className="fa fa-envelope" />
                    </Button>
                )}
                {hasPermission(Access.APPLICANT_WRITE_COMMENTS)
                && (
                    <Button
                        color="info"
                        size="sm"
                        title={languageTitles.candidatesMessageBtnTitle}
                        className={`btn me-1 ${enableButtons ? 'disabled' : ''}`}
                        onClick={(e) => onAction(e, item, 'showComments')}
                    >
                        <i className="fa fa-comment" />
                    </Button>
                )}
                {hasPermission(Access.APPLICANT_WRITE_COMMENTS)
                && (
                    <a
                        href={`https://api.whatsapp.com/send?phone=${item.PhoneNumberOne}&text=Hola,%20le%20saludamos%20de%20TSF.`}
                        target="blank"
                    >
                        <Button
                            color="info"
                            size="sm"
                            title={languageTitles.candidatesWhatsappBtnTitle}
                            className={`btn me-1 ${enableButtons ? 'disabled' : ''}`}
                        >
                            <i className="fab fa-whatsapp fa-lg" />
                        </Button>
                    </a>
                )}
                {hasPermission(Access.APPLICANT_PROCESS_APPLICATION)
                && (
                    <Button
                        color="info"
                        size="sm"
                        title={languageTitles.candidatesProcessApplicationBtnTitle}
                        className={`btn me-1 ${enableButtons ? 'disabled' : ''}`}
                        onClick={(e) => onAction(e, item, 'processApplication')}
                    >
                        <i className="fa fa-edit" />
                    </Button>
                )}
                {hasPermission(Access.USER_ASSIGN_TEST_TERM)
                && (
                    <Button
                        color="success"
                        size="sm"
                        title={languageTitles.candidatesEnableTermanBtnTitle}
                        className={`me-1 ${enableButtons ? 'disabled' : ''}`}
                        onClick={(e) => onAction(e, item, 'enableterman')}
                    >
                        {allowingTest && itemSelected.ApplicantId === item.ApplicantId && whichAction === 'enableterman'
                            ? <i className="fa fa-spinner fa-spin" />
                            : <i className="fa fa-clipboard" />}
                    </Button>
                )}
                {hasPermission(Access.USER_ASSIGN_TEST_DISC)
                && (
                    <Button
                        color="warning"
                        size="sm"
                        title={languageTitles.candidatesEnableDISCBtnTitle}
                        className={`me-1 ${enableButtons ? 'disabled' : ''}`}
                        onClick={(e) => onAction(e, item, 'enabledisc')}
                    >
                        {allowingTest && itemSelected.ApplicantId === item.ApplicantId && whichAction === 'enabledisc'
                            ? <i className="fa fa-spinner fa-spin" />
                            : <i className="fa fa-clipboard" />}
                    </Button>
                )}
            </td>
        </tr>
    ));

    const allApproved = records.length > 0 && records.filter((c) => c.IsChecked).length === records.length;

    const recordsSelected = records?.filter((c) => c.IsChecked);

    if (!hasPermission(Access.APPLICANT_READ_LIST)) return <ErrorPage />;

    return (
        <FullContent>
            <Panel>
                <PanelHeader showBackHistory>
                    <div className="panel-heading-btn justify-content-end">
                        <div className="col-md-5 me-2 d-flex">
                            {recordsSelected.length > 0
                            && (
                                <UncontrolledButtonDropdown className="me-1">
                                    <DropdownToggle caret color="default">
                                        {languageTitles.candidatesActionDropdownLabel}
                                        {' '}
                                        <i className="fa fa-caret-down" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => dispatchEvent({ openProfessFasePopup: true })}>{languageTitles.candidatesActionDropdownAddFase}</DropdownItem>
                                        <DropdownItem onClick={() => dispatchEvent({ openTagPopup: true })}>{languageTitles.candidatesActionDropdownAddLabel}</DropdownItem>
                                        <DropdownItem onClick={() => dispatchEvent({ openCompanyPopup: true })}>{languageTitles.candidatesActionDropdownAsignCompany}</DropdownItem>
                                        {/* <DropdownItem onClick={() => dispatchEvent({ openEmailPopup: true })}>{languageTitles.candidatesActionDropdownSentEmail}</DropdownItem> */}
                                    </DropdownMenu>
                                    <span className="badge">{recordsSelected?.length}</span>
                                </UncontrolledButtonDropdown>
                            ) }
                            <SearchField
                                initialValue={search}
                                returnSearchValue={(val) => dispatchEvent({
                                    ...initState,
                                    search: val,
                                    isSearching: true,
                                    page: 1,
                                }, ACTION_TYPES.SET_INITIAL_STATE)}
                                returnOnClear={() => dispatchEvent({
                                    ...initState,
                                    search: '',
                                    isSearching: true,
                                    page: 1,
                                }, ACTION_TYPES.SET_INITIAL_STATE)}
                            />
                        </div>
                        <div className="d-flex me-2">
                            <Button
                                color="success"
                                title={languageTitles.candidatesActionButtonsMoreFilter}
                                className={`me-1 ${enableButtons ? 'disabled' : ''}`}
                                onClick={() => dispatchEvent({
                                    openAdvanceSearch: !openAdvanceSearch,
                                })}
                            >
                                { openAdvanceSearch && <i className="fa fa-angle-double-up" />}
                                { !openAdvanceSearch && <i className="fa fa-angle-double-down" />}
                            </Button>
                            <Button
                                color="success"
                                title={languageTitles.candidatesActionButtonsExportPDF}
                                className={`me-1 ${enableButtons ? 'disabled' : ''}`}
                                onClick={(e) => onClickButton(e, 'btnexportpdf')}
                            >
                                {printing && printFileType === 'pdf' ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-file-pdf" />}
                            </Button>
                            {/* <Button
                                color="success"
                                title={languageTitles.candidatesActionButtonsExportExcel}
                                className={`me-1 ${enableButtons ? 'disabled' : ''}`}
                                onClick={(e) => onClickButton(e, 'btnexportxls')}
                            >
                                {printing && printFileType === 'xls' ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-file-excel" />}
                            </Button> */}
                            <Button
                                color="success"
                                title={languageTitles.candidatesActionButtonsRefresh}
                                className={`me-1 ${enableButtons ? 'disabled' : ''}`}
                                onClick={(e) => onClickButton(e, 'btnrefresh')}
                            >
                                { loading ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-redo" /> }
                            </Button>
                            <Button
                                color="success"
                                title={languageTitles.candidatesActionButtonsRestart}
                                className={`${enableButtons ? 'disabled' : ''}`}
                                onClick={(e) => onClickButton(e, 'btnclearfilter')}
                            >
                                <i className="fa fa-times" />
                            </Button>
                        </div>
                        <Paginator
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            loading={loading}
                            setPage={setPage}
                            page={page}
                            pages={pages}
                        />
                    </div>
                </PanelHeader>
                <PanelBody>
                    <Card className="rounded-0 border-0">
                        <Collapse isOpen={openAdvanceSearch}>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <Country
                                            returnSelectedValue={(data, options) => setDropdownValue(data, options)}
                                            name="CountryId"
                                            value={String(filters.CountryId)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <Jobs
                                            returnSelectedValue={(data, options) => setDropdownValue(data, options)}
                                            name="JobId"
                                            value={filters.JobId}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <Profession
                                            returnSelectedValue={(data, options) => setDropdownValue(data, options)}
                                            name="ProfessionId"
                                            value={filters.ProfessionId}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <Catalog
                                            returnSelectedValue={(data, options) => setDropdownValue(data, options)}
                                            name="GenderId"
                                            catalogType="Gender"
                                            value={String(filters.GenderId)}
                                            placeholder={languageTitles.candidatesFiltersGender}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <TSFButton
                                            action="custom"
                                            btnColor="white"
                                            className="col-md-12"
                                            label={dateRangeSelectedLabel === null ? languageTitles.candidatesFiltersSelectDates
                                                : `${formatDate(dateRangeSelectedLabel.start)} - ${formatDate(dateRangeSelectedLabel.end)}`}
                                            onClick={() => dispatchEvent({ isCalendarOpen: !isCalendarOpen })}
                                        />
                                        {isCalendarOpen && (
                                            <DateRangePicker
                                                value={dateRangeSelectedLabel}
                                                onSelect={onDateRangeSelect}
                                                singleDateRange
                                                className="z-index-10"
                                            />
                                        )}
                                    </div>
                                    <div className="col-md-3 d-flex align-items-center justify-content-left">
                                        <label className="control-label me-2">
                                            {languageTitles.candidatesFiltersAgeLabel}
                                            :
                                            {' '}
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control me-2"
                                            placeholder="mayor de"
                                            name="AgeGreater"
                                            value={filters.AgeGreater}
                                            onChange={(e) => {
                                                if (e.target.value < 0) return;
                                                handleInputChange(e);
                                            }}
                                        />
                                        <label className="control-label me-2"> y </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="menor de"
                                            name="AgeLess"
                                            value={filters.AgeLess}
                                            onChange={(e) => {
                                                if (e.target.value < 0) return;
                                                handleInputChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-3 d-flex align-items-center justify-content-left">
                                        <div className="form-check mb-2">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={handleInputChange}
                                                id="flexCheckCheckedfilter"
                                                checked={filters.JobEmpty}
                                                name="JobEmpty"
                                            />
                                            <label className="form-check-label" htmlFor="flexCheckCheckedfilter">
                                                {languageTitles.candidatesFiltersWithoutJob}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-3 d-flex align-items-center justify-content-left">
                                        <div className="form-group col-md-6 me-2">
                                            <UncontrolledButtonDropdown className="fullwidth">
                                                <DropdownToggle color="white" className="" caret>
                                                    {languageTitles.candidatesFiltersSortBy}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => setOrderBy('Name ASC')}>{languageTitles.candidatesFiltersSortByNameASC}</DropdownItem>
                                                    <DropdownItem onClick={() => setOrderBy('Name DESC')}>{languageTitles.candidatesFiltersSortByNameDESC}</DropdownItem>
                                                    <DropdownItem onClick={() => setOrderBy('LastName ASC')}>{languageTitles.candidatesFiltersSortByLastNameASC}</DropdownItem>
                                                    <DropdownItem onClick={() => setOrderBy('LastName DESC')}>{languageTitles.candidatesFiltersSortByLastNameDESC}</DropdownItem>
                                                    <DropdownItem onClick={() => setOrderBy('Country ASC')}>{languageTitles.candidatesFiltersSortByCountryASC}</DropdownItem>
                                                    <DropdownItem onClick={() => setOrderBy('Country DESC')}>{languageTitles.candidatesFiltersSortByCountryDESC}</DropdownItem>
                                                    <DropdownItem onClick={() => setOrderBy('Age ASC')}>{languageTitles.candidatesFiltersSortByAgeASC}</DropdownItem>
                                                    <DropdownItem onClick={() => setOrderBy('Age DESC')}>{languageTitles.candidatesFiltersSortByAgeDESC}</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                        <Button
                                            color="success"
                                            className={`me-1 ${loading ? 'disabled' : ''}`}
                                            onClick={(e) => onClickButton(e, 'searching')}
                                        >
                                            <i className="fa fa-filter fa-fw" />
                                            {languageTitles.candidatesActionButtonsSearch}
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Collapse>
                    </Card>
                    <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                        <div className="table-responsive">
                            <table className="table table-striped mb-0 align-middle">
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="table_checkboxall"
                                                checked={allApproved ?? false}
                                                onChange={() => dispatch({ type: ACTION_TYPES.SET_APPROVE_ALL, value: allApproved })}
                                            />
                                        </th>
                                        <th>{languageTitles.candidatesTableColumnId}</th>
                                        <th>{languageTitles.candidatesTableColumnImage}</th>
                                        <th>{languageTitles.candidatesTableColumnName}</th>
                                        <th>
                                            {languageTitles.candidatesTableColumnProfession}
                                            {' '}
                                            /
                                            {' '}
                                            {languageTitles.candidatesTableColumnJobsApplicant}
                                        </th>
                                        <th className="text-end">{languageTitles.candidatesTableColumnActions}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items}
                                </tbody>
                            </table>
                        </div>
                    </PerfectScrollbar>
                </PanelBody>
            </Panel>
            {state.openProfessFasePopup && (
                <ProcessFaseAssign
                    openPopup={state.openProfessFasePopup}
                    onClosePopup={() => dispatchEvent({ openProfessFasePopup: false })}
                    ids={recordsSelected.map((c) => c.ApplicantId)}
                />
            ) }
            {state.openTagPopup && (
                <TagAssign
                    openPopup={state.openTagPopup}
                    onClosePopup={() => dispatchEvent({ openTagPopup: false })}
                    ids={recordsSelected.map((c) => c.ApplicantId)}
                />
            )}
            {state.openCompanyPopup && (
                <CompanyAssign
                    openPopup={state.openCompanyPopup}
                    onClosePopup={() => dispatchEvent({ openCompanyPopup: false })}
                    ids={recordsSelected.map((c) => c.ApplicantId)}
                />
            )}
            {state.openEmailPopup && (
                <DocumentSender
                    showInPopup
                    openPopup={state.openEmailPopup}
                    onClosePopup={() => dispatchEvent({ openEmailPopup: false, emailsToSend: [] })}
                    ids={emailsToSend.length === 0
                        ? recordsSelected.map((item) => ({ id: item.UserId, name: item.Email }))
                        : emailsToSend}
                />
            )}
            {pendingPointSelected && (
                <Modal
                    isOpen
                    toggle={() => dispatchEvent({ pendingPointSelected: null })}
                >
                    <ModalHeader toggle={() => dispatchEvent({ pendingPointSelected: null })}>
                        Pendientes
                    </ModalHeader>
                    <ModalBody>
                        {
                            pendingPointSelected?.split(',')?.map((item, i) => (
                                <div key={i}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {item}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </ModalBody>
                </Modal>
            )}

            {showAlertAction
            && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={languageTitles.sweetAlertDeleteConfirmBtnText}
                    confirmBtnBsStyle="success"
                    cancelBtnText={languageTitles.sweetAlertDeleteCancelBtnText}
                    cancelBtnBsStyle="default"
                    title={actionTitle}
                    onConfirm={onActionConfirm}
                    onCancel={onActionCancel}
                >
                    {actionDescription}
                </SweetAlert>
            ) }
        </FullContent>
    );
}

export default Applicants;
