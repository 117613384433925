/* eslint-disable no-undef */
import {
    ApolloClient, InMemoryCache, split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import useCustomLocalStorage from './components/hooks/useCustomLocalStorage';

export const getApolloHeaders = () => ({
    HTTP_URL: process.env.REACT_APP_API,
    WS_URL: process.env.REACT_APP_WS,
});

const {
    getToken, clearLocalStorage, getUserAuth, getLanguageLS,
} = useCustomLocalStorage();

const currentToken = `Bearer ${getToken()}`;
const currentUser = getUserAuth();

// Build an HTTP link
const httpLink = createUploadLink({
    uri: getApolloHeaders().HTTP_URL,
    credentials: 'same-origin',
});

// Create auth link to set headers like authorization and others
const authLink = setContext((_, { headers }) => ({
    headers: {
        ...headers,
        iqwerh: currentToken || '',
        erftd: currentUser?.UserId,
        uysaoi: getLanguageLS(),
    },
}));

// Create the Web Socket link to make ws requests
const wsLink = new WebSocketLink({
    uri: getApolloHeaders().WS_URL,
    options: {
        reconnect: true,
        lazy: true,
        connectionParams: async () => ({
            iqwerh: currentToken || '',
            erftd: currentUser?.UserId,
        }),
    },
});

const errorLink = onError((result) => {
    const { networkError, graphQLErrors } = result;
    const { origin } = window.location;

    if (graphQLErrors && graphQLErrors?.some((x) => x.message.includes('Cannot open server \'tsf-sql-server\' requested by the login'))) {
        fetch(`https://api.callmebot.com/whatsapp.php?phone=+50557025167&text=URGENTE:-->+Fallo+el+logeo+en-TSF+' '+${new Date()}&apikey=101134`);
    }

    if (Number(networkError?.statusCode) === Number(401)) {
        clearLocalStorage();
        window.location.replace(`${origin}/#/login`);
    }
    if (graphQLErrors?.some((x) => x.message.includes('UNAUTHENTICATED'))
        || networkError?.result?.errors?.some((e) => e.extensions.code.includes('UNAUTHENTICATED'))) {
        clearLocalStorage();
        window.location.replace(`${origin}/#/login`);
    }
});

// Use split to use one or the other link depending on the type of operation to be performed.
// If it is an http operation (query or mutation) use authLink, otherwise use wsLink
// More info: https://www.apollographql.com/docs/link/composition#directional
const LinkArray = split(
    // split based on operation type
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);

        return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    // Concat function merge links - in this case merges authLink with httpLink
    errorLink.concat(authLink.concat(httpLink)),
);

// Build Apollo Client with the specific link
const client = new ApolloClient({
    link: LinkArray,
    cache: new InMemoryCache({
        addTypename: false,
    }),
});

export default client;
