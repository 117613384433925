/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import {
    Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AppContext from '../../../config/appContext';
import { FetchPolicy, NotificationType } from '../../../enums/general';

import UserGraphql from '../../../servicesapollo/userGraphql';
// import Access from '../../../enums/permissions';
import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';
import UserDropdown from '../user/user-dropdown';

function CompanyUser(props) {
    const {
        showNotification, languageTitles, // hasPermission,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const { onClosePopup, companyId } = props;

    const [record, setRecord] = useState({
        email: '',
        password: '',
        userSelected: 0,
    });

    const {
        password, email, userSelected,
    } = record;

    // const canChangeOtherPassword = hasPermission(Access.USER_CHANGE_OTHER_PASSWORD);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setRecord({
            ...record,
            [name]: value,
            userSelected: 0,
        });
    };

    const setUserId = (val) => {
        setRecord({
            ...record,
            userSelected: val,
            password: '',
            email: '',
        });
    };

    const [addUser, { loading: isSaving }] = useMutation(UserGraphql.ADD_BASIC_USER, {
        onCompleted: (mutationData) => {
            if (mutationData?.createUserToCompany) {
                const {
                    success, message, error,
                } = mutationData?.createUserToCompany;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenCreateUser,
                        NotificationType.DANGER,
                    );
                    return;
                }

                showNotification(languageTitles.successWhenCreateUser, NotificationType.SUCCESS);

                onClosePopup(true);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onAddUser = (e) => {
        e.preventDefault();

        if (isSaving) return;

        if (!email && !password && userSelected === 0) {
            showNotification(languageTitles.userProvideEmailOrSelect, NotificationType.INFO);
            return;
        }

        if (!email && userSelected === 0) {
            showNotification(languageTitles.userProvideEmail, NotificationType.INFO);
            return;
        }

        if (!password && userSelected === 0) {
            showNotification(languageTitles.userProvideThePassword, NotificationType.INFO);
            return;
        }

        addUser({
            variables: {
                record: {
                    email,
                    px: password,
                    companyId,
                    userSelectedId: userSelected,
                },
            },
        });
    };

    return (
        <Modal isOpen toggle={onClosePopup}>
            <ModalHeader toggle={() => onClosePopup} close={<button type="button" className="btn-close" onClick={onClosePopup} />}>
                {languageTitles.createUser}
            </ModalHeader>
            <ModalBody>
                <div className="row mb-3">
                    <label className="form-label col-form-label col-md-4">{languageTitles.userAssociateUser}</label>
                    <div className="col-md-8">
                        <UserDropdown
                            className="me-1"
                            returnSelectedValue={(val) => setUserId(val)}
                            name="userSelected"
                            value={userSelected}
                            companyUsers
                            disabled={email !== '' || password !== ''}
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <label className="form-label col-form-label col-md-4">{languageTitles.usersTableColumnEmail}</label>
                    <div className="col-md-8">
                        <input
                            type="email"
                            className="form-control m-b-5"
                            name="email"
                            id="emailAddress"
                            value={email}
                            onChange={handleInputChange}
                            required
                            disabled={userSelected > 0}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="form-label col-form-label col-md-4">{languageTitles.userNewPassword}</label>
                    <div className="col-md-8">
                        <input
                            type="password"
                            className="form-control m-b-5"
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            disabled={userSelected > 0}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <TSFButton
                    action="cancel"
                    onClick={onClosePopup}
                />
                <TSFButton
                    action="save"
                    isWorking={isSaving}
                    onClick={onAddUser}
                />
            </ModalFooter>
        </Modal>
    );
}

CompanyUser.propTypes = {
    onClosePopup: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
};

export default CompanyUser;
