import React from 'react';

function Splash() {
    return (
        <div style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
        }}
        >
            <img src="/assets/img/tsf-logo.png" className="imageRotateSpin" alt="Travailleur Sans Frontiere" />
        </div>
    );
}

export default Splash;
