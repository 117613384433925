import React, { useContext, useState } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import AppContext from '../../../config/appContext';
import CompanyDropdown from './company-dropdown';
import { NotificationType, EnrollProcess } from '../../../enums/general';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';

import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';

function CompanyAssign(props) {
    const { showNotification, languageTitles } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const [companyId, setCompanyId] = useState(0);

    const { ids, openPopup, onClosePopup } = props;

    const [saveData, { loading: isSaving }] = useLazyQuery(ApplicantsGraphql.APPLICANT_ASSIGN_TO_PROCESS, {
        onCompleted: (res) => {
            if (res.applicantAssignToProcess) {
                showNotification(languageTitles.applicantAssociatedToCompany, NotificationType.SUCCESS);

                onClosePopup();
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSaveData = async () => {
        if (isSaving) return;

        await saveData({
            variables: {
                ids,
                updateType: EnrollProcess.COMPANY,
                value: String(companyId),
            },
        });
    };

    if (!ids || ids.length === 0) { return null; }

    return (
        <Modal
            size="sm"
            isOpen={openPopup}
            toggle={onClosePopup}
        >
            <ModalHeader toggle={onClosePopup}>Asignar a una compañia</ModalHeader>
            <ModalBody>
                <div className="form-group m-b-6">
                    <CompanyDropdown
                        returnSelectedValue={(data) => setCompanyId(data)}
                        name="Company"
                        value={companyId}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <TSFButton
                    action="cancel"
                    onClick={onClosePopup}
                />
                <TSFButton
                    action="save"
                    isWorking={isSaving}
                    isDisabled={companyId === 0}
                    onClick={onSaveData}
                />
            </ModalFooter>
        </Modal>
    );
}

CompanyAssign.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    openPopup: PropTypes.bool.isRequired,
    onClosePopup: PropTypes.func.isRequired,
};

export default CompanyAssign;
