/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Button } from 'reactstrap';
import EmailMessageGraphql from '../../../servicesapollo/emailMessagesGraphql';
import AppContext from '../../../config/appContext';
import useDateHelper from '../../../helpers/dateHelper';

function EmailDetail(props) {
    const {
        loggedUser, languageTitles,
    } = useContext(AppContext);

    const { formatDate } = useDateHelper();

    const { item, onClose } = props;

    const [readEmailMessage] = useLazyQuery(EmailMessageGraphql.READ_EMAIL_MESSAGE);

    useEffect(() => {
        // getEmail();
        document.getElementById('emailContentId').innerHTML = item.DataMessage;
        readEmailMessage({
            variables: {
                userId: loggedUser.UserId,
                emailMessageId: item.EmailMessageId,
            },
        });
    }, []);

    return (
        <div className="mailbox-content">
            <div className="mailbox-content-header">
                <div className="btn-toolbar">
                    <div className="btn-group me-2">
                        <Button
                            color="white"
                            size="sm"
                            onClick={onClose}
                        >
                            <i className="fa fa-fw fa-times" />
                        </Button>
                    </div>
                    <div className="btn-group ms-auto">
                        <Link to="/email/detail" className="btn btn-white btn-sm">
                            <i className="fa fa-fw fa-reply" />
                            {' '}
                            <span className="d-none d-lg-inline">{languageTitles.mailDetailReply}</span>
                        </Link>

                    </div>
                </div>
            </div>
            <div className="mailbox-content-body">
                <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                    <div className="p-3">
                        <h3 className="mb-3">{item.TitleMessage}</h3>
                        <div className="d-flex mb-3">
                            <Link to="/email/detail">
                                <img className="rounded-pill" width="48" alt="" src={item?.FromUser?.PictureURL ?? '/assets/img/user/user-13.jpg'} />
                            </Link>
                            <div className="ps-3">
                                <div className="email-from text-dark fs-14px mb-3px fw-bold">
                                    {languageTitles.mailDetailFrom}
                                    {' '}
                                    :
                                    {' '}
                                    {item?.FromUser?.Email}
                                </div>
                                <div className="mb-3px">
                                    <i className="fa fa-clock fa-fw" />
                                    {' '}
                                    {formatDate(item.CreatedOn, 'DD/MM/YYYY HH:mm A')}
                                </div>
                                <div className="email-to">
                                    {languageTitles.mailDetailTo}
                                    {' '}
                                    :
                                    {' '}
                                    {item?.ToUser?.Email}
                                </div>
                            </div>
                        </div>
                        <hr className="bg-gray-500" />
                        <div id="emailContentId" />
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
    );
}

EmailDetail.propTypes = {
    item: PropTypes.objectOf(PropTypes.object).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default EmailDetail;
