import { gql } from '@apollo/client';

export default class FilesGraphql {
    static UPLOAD_APPLICANT_FILES = gql`
mutation singleUpload($file: FileUpload!, $userId: Int!, $applicantId: Int!, $fileTypeId: Int!) {
  singleUpload(file: $file, userId: $userId, applicantId: $applicantId, fileTypeId: $fileTypeId){
    success
    message
    error
    data{
      FileId
      FileTypeId
      FileUrl
      FileSize
      FileTitle
      CreatedOn
    }
  }
}
`;

    static DELETE_APPLICANT_FILES = gql`
mutation deleteFiles($fileId: ID!, $userId: Int!) {
  deleteFiles(id: $fileId, userId: $userId){
    success
    message
    error
  }
}
`;

    static GET_FILES_BY_FILTERS = gql`
query files($filters: [Filters], $sort: [SortField], $fileTypes: [Int]) {
  files(filters: $filters, sort: $sort, fileTypes: $fileTypes){
    success
    message
    error
    data{
      FileId
      FileTypeId
      FileUrl
      FileSize
      FileTitle
      CreatedOn
    }
  }
}
`;
}
