import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import DropdownNotification from './dropdown/notification';
import DropdownLanguage from './dropdown/language';
import DropdownProfile from './dropdown/profile';
// import SearchForm from './search/form';
import AppContext from '../../../config/appContext';
import Logo from '../logo/logo';
import LogoBlack from '../logo/logoBlack';

function Header() {
    const [state, setState] = useState({ appHeaderMegaMenuMobile: false });

    const {
        toggleAppSidebarMobile, toggleAppSidebarEndMobile, toggleAppTopMenuMobile,
        appHeaderLanguageBar, appHeaderMegaMenu, appHeaderInverse, appSidebarTwo, appTopMenu, appSidebarNone,
        handleSetAppDarkMode, languageTitles, appDarkMode, loggedUser,
    } = useContext(AppContext);

    const toggleAppHeaderMegaMenuMobile = () => {
        setState({ appHeaderMegaMenuMobile: !state.appHeaderMegaMenuMobile });
    };

    return (
        <div id="header" className={`app-header ${appHeaderInverse ? 'app-header-inverse' : ''}`}>
            <div className="navbar-header">
                {appSidebarTwo && (
                    <button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarEndMobile}>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                )}
                <Link to="/" className="navbar-brand">
                    {appDarkMode && <Logo />}
                    {!appDarkMode && <LogoBlack />}
                </Link>
                <span className="btn-group float-end ms-20px p-2px bg-black bg-opacity-20 rounded d-block d-sm-none">
                    <button type="button" className={`btn btn-sm btn-white border-0 ${!appDarkMode ? 'active ' : ''}`} onClick={() => handleSetAppDarkMode(false)}>
                        <i className="fa fa-sun text-blue" />
                        {' '}
                        Light
                    </button>
                    <button type="button" className={`btn btn-sm btn-white border-0 ${appDarkMode ? 'active ' : ''}`} onClick={() => handleSetAppDarkMode(true)}>
                        <i className="fa fa-moon" />
                        {' '}
                        Dark
                    </button>
                </span>
                {appHeaderMegaMenu && (
                    <button type="button" className="navbar-mobile-toggler" onClick={toggleAppHeaderMegaMenuMobile}>
                        <span className="fa-stack fa-lg text-inverse">
                            <i className="far fa-square fa-stack-2x" />
                            <i className="fa fa-cog fa-stack-1x" />
                        </span>
                    </button>
                )}
                {appTopMenu && !appSidebarNone && (
                    <button type="button" className="navbar-mobile-toggler" onClick={toggleAppTopMenuMobile}>
                        <span className="fa-stack fa-lg text-inverse">
                            <i className="far fa-square fa-stack-2x" />
                            <i className="fa fa-cog fa-stack-1x" />
                        </span>
                    </button>
                )}
                {appSidebarNone && appTopMenu && (
                    <button type="button" className="navbar-mobile-toggler" onClick={toggleAppTopMenuMobile}>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                )}
                {!appSidebarNone && (
                    <button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarMobile}>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                )}
            </div>

            <div className="navbar-nav">
                <span className="btn-group float-end ms-20px p-2px bg-black bg-opacity-20 rounded d-none d-sm-block">
                    <button type="button" className={`btn btn-sm btn-white border-0 ${!appDarkMode ? 'active ' : ''}`} onClick={() => handleSetAppDarkMode(false)}>
                        <i className="fa fa-sun text-blue" />
                        {' '}
                        {languageTitles.lightMode}
                    </button>
                    <button type="button" className={`btn btn-sm btn-white border-0 ${appDarkMode ? 'active ' : ''}`} onClick={() => handleSetAppDarkMode(true)}>
                        <i className="fa fa-moon" />
                        {' '}
                        {languageTitles.nightMode}
                    </button>
                </span>
                {/* {!loggedUser.IsCompanyUser && <SearchForm />} */}
                <DropdownNotification />

                {appHeaderLanguageBar
                && !loggedUser.IsCompanyUser
                && (
                    <DropdownLanguage />
                )}

                <DropdownProfile />
            </div>
        </div>
    );
}

export default Header;
