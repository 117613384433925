import { gql } from '@apollo/client';

export default class EmailMessageGraphql {
    static GET_EMAIL_MESSAGES_PAGING = gql`
      query emailMessagesPaging(
          $paging: PaginationInput
          $sort: [SortField]
          $filters: [Filters]
          $search: String
          $usePaging: Boolean
    ){
      emailMessagesPaging(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
          success
          message
          error
          total
          pages
          data {
            EmailMessageId
            ParentEmailMessageId
            FromUserId
            FromUser{
              Email
              PictureURL
            }
            ToUserId
            ToUser{
              Email
              PictureURL
            }
            TitleMessage
            DataMessage
            MessageDate
            ImportanceId
            Read
            ReadOn
            CreatedOn
            Important
          }
        }
      }
    `;

    static GET_EMAIL_MESSAGE = gql`
      query emailMessage($id: ID!) {
        emailMessage(id: $id) {
          success
          error
          message
          data{
            EmailMessageId
            ParentEmailMessageId
            FromUserId
            FromUser{
              Email
            }
            ToUser {
              Email
            }
            TitleMessage
            DataMessage
            MessageDate
            ImportanceId
            Read
            ReadOn
            CreatedOn
          }
        }
      }
    `;

    static ADD_EMAIL_MESSAGE = gql`
  mutation addMessage($record: MessageInput!) {
    addMessage(record: $record){
      success,
      message,
      error,
      data{
        EmailMessageId
      }
    }
  }
  `;

    static DELETE_EMAIL_MESSAGE = gql`
      mutation deleteMessage($id: ID!) {
        deleteMessage(id: $id){
          success
          error
          message
          data
        }
      }
    `;

    static SUBSCRIBE_EMAIL_MESSAGE = gql`
      subscription emailMessagesChanged{
        emailMessagesChanged{
          success
          id
          action
          data
        }
      }
    `;

    static READ_EMAIL_MESSAGE = gql`
query readEmailMessage($userId: Int!, $emailMessageId: Int!) {
  readEmailMessage(userId: $userId, emailMessageId: $emailMessageId)
}
`;

    static SET_IMPORTANT_EMAIL = gql`
query setImportantEmail($userId: Int!, $emailMessageId: Int!, $important: Boolean) {
  setImportantEmail(userId: $userId, emailMessageId: $emailMessageId, important: $important)
}
`;

    static GET_EMAIL_COUNT = gql`
      query getEmailCount($userId: Int!) {
        getEmailCount(userId: $userId) {
          Total
          Important
          Unread
          Sent
        }
      }
    `;
}
