import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import AppContext from '../../config/appContext';

function DeleteAlert(props) {
    const { languageTitles } = useContext(AppContext);

    const {
        isDeleting, onConfirm, onCancel,
    } = props;
    return (
        <SweetAlert
            danger
            showCancel={!isDeleting}
            showConfirm={!isDeleting}
            confirmBtnText={languageTitles.sweetAlertDeleteConfirmBtnText}
            confirmBtnBsStyle="danger"
            cancelBtnText={languageTitles.sweetAlertDeleteCancelBtnText}
            cancelBtnBsStyle="default"
            title={isDeleting ? languageTitles.deletingTitle : languageTitles.sweetAlertDeleteTitle}
            onConfirm={onConfirm}
            onCancel={onCancel}
        >
            {!isDeleting && languageTitles.sweetAlertDeleteConfirm}
        </SweetAlert>
    );
}

DeleteAlert.propTypes = {
    isDeleting: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default DeleteAlert;
