import React, { Component } from 'react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

class ToastApp extends Component {
    constructor(props) {
        super(props);

        this.showMessage = this.showMessage.bind(this);
    }

    showMessage(message, messagetype) {
        switch (messagetype) {
        case 'success':
            ToastsStore.success(message);
            break;
        case 'info':
            ToastsStore.info(message);
            break;
        case 'warning':
            ToastsStore.warning(message);
            break;
        case 'danger':
            ToastsStore.danger(message);
            break;
        default:
            break;
        }
    }

    render() {
        return (
            <div>
                <ToastsContainer lightBackground store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
            </div>
        );
    }
}

export default ToastApp;
