/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useContext, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import {
    TabContent, TabPane, Nav, NavItem, NavLink, ButtonGroup, Button,
} from 'reactstrap';
import MultiSelect from 'react-multi-select-component';
import DateTime from 'react-datetime';
import classnames from 'classnames';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import {
    Panel, PanelHeader, PanelBody,
} from '../../panel/panel';
import Country from '../../country/country-dropdown';
import Catalog from '../../catalog/catalog-dropdown';
import Profession from '../../profession/profession-dropdown';
import AppContext from '../../../config/appContext';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';
import {
    Catalogs, FetchPolicy, FileType, LineActions, NotificationType,
} from '../../../enums/general';
import JobPositionGraphql from '../../../servicesapollo/jobPositionGraphql';

import UploadFile from '../uploadFile/uploadFile';
import UserGraphql from '../../../servicesapollo/userGraphql';
import FileRepository from '../uploadFile/fileRepository';
import ErrorPage from '../extraPages/error';
import Access from '../../../enums/permissions';
import ApplicantsMapping from '../../../mapping/applicantsMapping';
import useDateHelper from '../../../helpers/dateHelper';
import TSFButton from '../../actionButtons/tsfButton';

import 'react-datetime/css/react-datetime.css';
import './applicant.css';
import useMessageHelper from '../../../helpers/messageHelper';
import toPascalCase from '../../../util/messages';
import { pdfOptions } from '../../../helpers/pdfHelper';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
    ADD_STUDIES: 'addStudies',
    ADD_EXPERIENCE: 'addExperience',
    REMOVE_STUDIES: 'removeStudies',
    REMOVE_EXPERIENCE: 'removeExperience',
    UPDATE_STUDIES: 'updateStudies',
    UPDATE_EXPERIENCE: 'updateExperience',
    UPDATE_SKILL: 'updateSkill',
    SET_FILES_AFTER_DELETE: 'setFilesAfterDelete',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...state,
            record: action.value,
        };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        const { value } = action;
        return { ...state, record: value, isDirty: true };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.ADD_STUDIES: {
        const { record } = state;

        const newStudy = {
            StudiesId: state.indexStudies - 1,
            ApplicantId: record.ApplicantId,
            StudyCenter: '',
            StartDate: new Date(),
            EndDate: new Date(),
            CertificateObtained: '',
            CertificateObtainedType: '',
            IsDirty: false,
            Status: LineActions.NEW,
        };

        record.Studies.push(newStudy);

        return {
            ...state,
            record,
            indexStudies: state.indexStudies - 1,
            isDirty: true,
        };
    }
    case ACTION_TYPES.ADD_EXPERIENCE: {
        const { record } = state;

        const newExperience = {
            ExperienceId: state.indexExperience - 1,
            ApplicantId: record.ApplicantId,
            CompanyName: '',
            StartDate: new Date(),
            EndDate: new Date(),
            JobTitle: '',
            DescriptionJob: '',
            IsDirty: false,
            Status: LineActions.NEW,
        };

        record.Experiences.push(newExperience);

        return {
            ...state,
            record,
            indexExperience: state.indexExperience - 1,
            isDirty: true,
        };
    }
    case ACTION_TYPES.REMOVE_STUDIES: {
        const { record } = state;

        const newArr = record.Studies.map((exp) => {
            const item = { ...exp };
            if (Number(exp.StudiesId) === Number(action.value)) {
                item.Status = LineActions.DELETED;
                item.IsDirty = true;
            }
            return item;
        });

        record.Studies = newArr;

        return {
            ...state,
            record,
            isDirty: true,
        };
    }
    case ACTION_TYPES.REMOVE_EXPERIENCE: {
        const { record } = state;

        const newArr = record.Experiences.map((exp) => {
            const item = { ...exp };
            if (Number(exp.ExperienceId) === Number(action.value)) {
                item.Status = LineActions.DELETED;
                item.IsDirty = true;
            }
            return item;
        });

        record.Experiences = newArr;

        return {
            ...state,
            record,
            isDirty: true,
        };
    }
    case ACTION_TYPES.UPDATE_STUDIES: {
        const { record } = state;

        const newArr = record.Studies.map((exp) => {
            const item = { ...exp };
            if (Number(exp.StudiesId) === Number(action.index)) {
                item[action.fieldName] = action.value;
                item.Status = exp.StudiesId > 0 ? LineActions.UPDATED : LineActions.NEW;
                item.IsDirty = true;
            }
            return item;
        });

        record.Studies = newArr;

        return {
            ...state,
            record,
            isDirty: true,
        };
    }
    case ACTION_TYPES.UPDATE_EXPERIENCE: {
        const { record } = state;

        const newArr = record.Experiences.map((exp) => {
            const item = { ...exp };
            if (Number(exp.ExperienceId) === Number(action.index)) {
                item[action.fieldName] = action.value;
                item.Status = exp.ExperienceId > 0 ? LineActions.UPDATED : LineActions.NEW;
                item.IsDirty = true;
            }
            return item;
        });

        record.Experiences = newArr;

        return {
            ...state,
            record,
            isDirty: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.UPDATE_SKILL: {
        const { record } = state;

        const index = record?.JobPositions?.indexOf(action.value);

        if (index !== -1) {
            record.JobPositions.splice(index, 1);
        } else {
            record.JobPositions.push(action.value);
        }

        return {
            ...state,
            record,
            isDirty: true,
        };
    }
    case ACTION_TYPES.SET_FILES_AFTER_DELETE: {
        const newArr = state.record.Files.filter((c) => c.FileId !== state.idDeleting);

        return {
            ...state,
            record: {
                ...state.record,
                Files: newArr,
            },
            idDeleting: 0,
        };
    }
    default:
        return state;
    }
};

function Applicant() {
    const { id } = useParams();

    const {
        showNotification, languageTitles, loggedUser,
        currentLanguage, coreCatalogs, darkTheme,
        hasPermission, updateLoggedUser, converToPdf,
        currentLocaleDate,
    } = useContext(AppContext);

    const { getErrorMessage } = useMessageHelper();

    const {
        formatDate, convertToDate, getOnlyDate, convertToDateFromString,
    } = useDateHelper();

    if (!hasPermission(Access.APPLICANT_WRITE_OTHER) && Number(id) !== Number(loggedUser.EntityId)) return <ErrorPage />;

    const jobPositions = coreCatalogs.filter((c) => String(c.category.toLowerCase()) === Catalogs.POSITION.toLowerCase()).map((c) => (
        {
            value: c.value,
            label: c[currentLanguage],
        }));

    const countries = coreCatalogs.filter((c) => String(c.category.toLowerCase()) === Catalogs.COUNTRY.toLowerCase()).map((c) => (
        {
            value: c.value,
            label: c[currentLanguage],
        }));

    const catalogs = coreCatalogs.filter((c) => Number(c.orden) === 5).map((c) => (
        {
            category: c.category,
            value: c.value,
            label: c[currentLanguage],
        }));

    const initState = {
        itemIdClick: 0,
        itemOpen: false,
        activeTab: '1',
        isEditing: false,
        isDirty: false,
        indexStudies: 0,
        indexFiles: 0,
        indexExperience: 0,
        // isUploading: false,
        idUploading: '',
        isUploadingPictureProfile: false,
        idDeleting: 0,
        record: {
            ApplicantId: 0,
            Name: loggedUser.Name,
            LastName: loggedUser.LastName,
            AddressLineOne: '',
            AddressLineTwo: '',
            CountryId: '',
            Country: '',
            City: '',
            Town: '',
            PostalCode: '',
            Birthday: '',
            Age: 0,
            IdentificationCode: '',
            MaritalStatusId: -1,
            GenderId: -1,
            Childrens: 0,
            HasPassportId: -1,
            PassportNumber: '',
            ExpirationPassport: '',
            PassportUrl: '',
            CountryVisitedOneId: -1,
            CountryVisitedOne: '',
            CountryVisitedOneStartDate: '',
            CountryVisitedOneEndDate: '',
            CountryVisitedTwoId: -1,
            CountryVisitedTwo: '',
            CountryVisitedTwoStartDate: '',
            CountryVisitedTwoEndDate: '',
            HasDriverLicenseId: -1,
            DriverLicenseCategory: '',
            PhoneNumberOne: '',
            PhoneNumberTwo: '',
            Email: loggedUser.Email,
            Profession: '',
            ExperienceYears: '',
            CurriculumUrl: '',
            SubmissionDate: '',
            ModifiedDate: '',
            ModifedByUserId: '',
            EnglishLevelId: -1,
            FrenchLevelId: -1,
            ResidentCountryId: -1,
            ResidentCountry: '',
            ResidentCity: '',
            ResidentTown: '',
            AboutMe: '',
            UserId: 0,
            LicenceUrl: '',
            DiplomaBachillerUrl: '',
            TituloUniversitario1: '',
            TituloUniversitario2: '',
            CertificadoTecnico1: '',
            CertificadoTecnico2: '',
            CertificadoTecnico3: '',
            CertificadoTecnico4: '',
            PictureURL: loggedUser.ProfilePicture,
            Experiences: [{
                Status: 'Saved',
                CompanyName: '',
                ExperienceId: 0,
                StartDate: new Date(),
                EndDate: new Date(),
                JobTitle: '',
                DescriptionJob: '',
                IsDirty: false,
            }],
            Studies: [{
                Status: 'Saved',
                StudyCenter: '',
                StudiesId: 0,
                StartDate: new Date(),
                EndDate: new Date(),
                CertificateObtained: '',
                CertificateObtainedType: '',
                IsDirty: false,
            }],
            JobPositionIds: [],
            JobPositions: [],
            Files: [],
            LeadSourceId: -1,
        },
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        record, isEditing, isDirty, activeTab,
        itemIdClick, itemOpen,
    } = state;

    const [getApplicant, { loading: isLoading }] = useLazyQuery(ApplicantsGraphql.GET_APPLICANT, {
        onCompleted: (res) => {
            if (res.applicant) {
                const {
                    data, success, message, error,
                } = res.applicant;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                const currentFiles = res?.files?.data;

                const mapData = ApplicantsMapping.mapApplicant(data);
                mapData.Birthday = convertToDateFromString(data.Birthday);
                mapData.ExpirationPassport = convertToDateFromString(data.ExpirationPassport);
                mapData.VisaDeniedDate = convertToDateFromString(data.VisaDeniedDate);
                mapData.CountryVisitedOneStartDate = convertToDateFromString(data.CountryVisitedOneStartDate);
                mapData.CountryVisitedOneEndDate = convertToDateFromString(data.CountryVisitedOneEndDate);
                mapData.CountryVisitedTwoStartDate = convertToDateFromString(data.CountryVisitedTwoStartDate);
                mapData.CountryVisitedTwoEndDate = convertToDateFromString(data.CountryVisitedTwoEndDate);
                mapData.LivedCountryDate = convertToDateFromString(data.LivedCountryDate);

                const currentExperiences = mapData.Experiences.map((c) => ({
                    ...c,
                    StartDate: convertToDateFromString(c.StartDate),
                    EndDate: convertToDateFromString(c.EndDate),
                }));

                const currentStudies = mapData.Studies.map((c) => ({
                    ...c,
                    StartDate: convertToDateFromString(c.StartDate),
                    EndDate: convertToDateFromString(c.EndDate),
                }));

                mapData.Files = currentFiles;
                mapData.Studies = currentStudies;
                mapData.Experiences = currentExperiences;

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: mapData,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const { data: jobDetails } = useQuery(JobPositionGraphql.GET_JOB_POSITION_FOR_SKILLS_LIST, {
        variables: {
            usePaging: false,
        },
    });

    const callGetApplicant = () => {
        getApplicant({
            variables: {
                id: Number(id),
                filters: [{ fieldName: 'ApplicantId', value: String(id) }],
            },
        });
    };

    const [printApplicantReport, { loading: printingReport }] = useLazyQuery(ApplicantsGraphql.PRINT_APPLICANT_REPORT, {
        onCompleted: (res) => {
            if (res.printApplicantReport) {
                const pdfBase64 = res.printApplicantReport;
                converToPdf(pdfBase64, `Candidato-${record.Name}-${record.LastName}-${uuidv4()}.pdf`);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const mutationQuery = record.ApplicantId > 0 ? ApplicantsGraphql.UPDATE_APPLICANT : ApplicantsGraphql.CREATE_APPLICANT;
    const [saveRecord, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (res) => {
            if (res?.addApplicant || res?.updateApplicant) {
                const {
                    success, message, error,
                } = res?.addApplicant || res?.updateApplicant;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        isEditing: false,
                        isDirty: false,
                        isSaving: false,
                    },
                });

                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS);

                callGetApplicant();
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSave = async () => {
        record.UserId = record.ApplicantId > 0 ? record.UserId : loggedUser.UserId;

        const recordToSave = {
            ApplicantId: record.ApplicantId,
            UserId: record.UserId,
            Name: record.Name,
            LastName: record.LastName,
            AddressLineOne: record.AddressLineOne,
            AddressLineTwo: record.AddressLineTwo,
            CountryId: record.CountryId,
            City: record.City,
            Town: record.Town,
            PostalCode: record.PostalCode,
            Birthday: getOnlyDate(record.Birthday),
            IdentificationCode: record.IdentificationCode,
            MaritalStatusId: record.MaritalStatusId,
            GenderId: record.GenderId,
            Childrens: record.Childrens,
            HasPassportId: record.HasPassportId,
            PassportNumber: record.PassportNumber,
            PassportUrl: record.PassportUrl,
            CountryVisitedOneId: record.CountryVisitedOneId,
            CountryVisitedOneStartDate: getOnlyDate(record.CountryVisitedOneStartDate),
            CountryVisitedOneEndDate: getOnlyDate(record.CountryVisitedOneEndDate),
            CountryVisitedTwoId: record.CountryVisitedTwoId,
            CountryVisitedTwoStartDate: getOnlyDate(record.CountryVisitedTwoStartDate),
            CountryVisitedTwoEndDate: getOnlyDate(record.CountryVisitedTwoEndDate),
            HasDriverLicenseId: record.HasDriverLicenseId,
            DriverLicenseCategory: record.DriverLicenseCategory,
            PhoneNumberOne: record.PhoneNumberOne,
            PhoneNumberTwo: record.PhoneNumberTwo,
            Email: record.Email,
            ProfessionId: record.ProfessionId,
            ProfessionTwo: record.ProfessionTwo,
            ExperienceYears: record.ExperienceYears,
            EnglishLevelId: record.EnglishLevelId,
            FrenchLevelId: record.FrenchLevelId,
            ExpirationPassport: getOnlyDate(record.ExpirationPassport),
            ResidentCountryId: record.ResidentCountryId,
            ResidentCity: record.ResidentCity,
            ResidentTown: record.ResidentTown,
            AboutMe: record.AboutMe,
            VisaDeniedId: record.VisaDeniedId,
            VisaDeniedDate: getOnlyDate(record.VisaDeniedDate),
            VisaDeniedComments: record.VisaDeniedComments,
            LivedCountryMoreThan6MonthsId: record.LivedCountryMoreThan6MonthsId,
            LivedCountryId: record.LivedCountryId,
            LivedCountryDate: getOnlyDate(record.LivedCountryDate),
            LivedCountryMigrateStatus: record.LivedCountryMigrateStatus,
            JobPositionIds: record.JobPositionIds,
            JobPositions: record.JobPositions,
            Studies: record.Studies.filter((c) => c.IsDirty).map((c) => ({
                ...c,
                StartDate: getOnlyDate(c.StartDate),
                EndDate: getOnlyDate(c.EndDate),
            })),
            Experiences: record.Experiences.filter((c) => c.IsDirty).map((c) => ({
                ...c,
                StartDate: getOnlyDate(c.StartDate),
                EndDate: getOnlyDate(c.EndDate),
            })),
            LeadSourceId: record.LeadSourceId,
        };

        await saveRecord({
            variables: {
                record: recordToSave,
            },
        });
    };

    const getExportToPdf = () => {
        printApplicantReport({
            variables: {
                id: Number(id),
                options: JSON.stringify(pdfOptions),
            },
        });
    };

    const setMultipleSelected = (selectedOption) => {
        const jobPositionIds = selectedOption.map((c) => c.value);

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                JobPositionIds: jobPositionIds,
            },
        });
    };

    const onActionButtons = (e, action) => {
        e.preventDefault();

        if (action === 'edit') {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isEditing: true,
                },
            });
        } else if (action === 'cancel') {
            if (isDirty) {
                callGetApplicant();
            }

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isEditing: false,
                    isDirty: false,
                },
            });
        } else if (action === 'save') {
            onSave();
        } else if (action === 'refresh') {
            callGetApplicant();
        } else if (action === 'getpdf') {
            getExportToPdf();
        }
    };

    // selecte tabs
    const togglePill = (tab) => {
        if (state.activeTab !== tab) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    activeTab: tab,
                },
            });
        }
    };

    // methods to change information for applicant
    const handleInputChange = (e) => {
        const { target } = e;

        const value = target.type === 'checkbox' ? target.checked : target.value;
        let allowChange = true;
        if (target.name.toLowerCase().includes('phone')) {
            const re = /^[0-9\b]+$/;
            if (!(value === '' || re.test(value))) allowChange = false;
        }

        if (allowChange) {
            dispatch({
                type: ACTION_TYPES.SET_RECORD_CHANGED,
                value: {
                    ...record,
                    [target.name]: value,
                },
            });
        }
    };

    const handleDateChange = (date, whichdate) => {
        record[whichdate] = date._d;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                [whichdate]: date._d,
            },
        });
    };

    const handleSelectControlChange = (data, options) => {
        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                [options.name]: data,
            },
        });
    };

    // methods for add, remove and change information for studies and experiences
    const onAddSection = (e, section) => {
        e.preventDefault();

        if (section === 'Studies') {
            dispatch({
                type: ACTION_TYPES.ADD_STUDIES,
            });
        } else if (section === 'Experience') {
            dispatch({
                type: ACTION_TYPES.ADD_EXPERIENCE,
            });
        }
    };

    const onRemoveItemInSections = (e, item, action) => {
        e.preventDefault();

        if (action === 'Studies') {
            dispatch({
                type: ACTION_TYPES.REMOVE_STUDIES,
                value: item.StudiesId,
            });
        } else if (action === 'Experience') {
            dispatch({
                type: ACTION_TYPES.REMOVE_EXPERIENCE,
                value: item.ExperienceId,
            });
        }
    };

    const OnChangeValueInSections = (data, index, action, fieldName) => {
        if (action === 'Studies') {
            dispatch({
                type: ACTION_TYPES.UPDATE_STUDIES,
                fieldName,
                value: data,
                index,
            });
        } else if (action === 'Experiences') {
            dispatch({
                type: ACTION_TYPES.UPDATE_EXPERIENCE,
                fieldName,
                value: data,
                index,
            });
        }
    };

    const addJobSkill = (e, jobDetailId) => {
        e.preventDefault();

        dispatch({
            type: ACTION_TYPES.UPDATE_SKILL,
            value: jobDetailId,
        });
    };

    const onAfterUpload = (val) => {
        if (record.UserId === loggedUser.UserId) { // si el usuario es el mismo loggeado
            updateLoggedUser({
                ...loggedUser,
                ProfilePicture: val?.data?.FileUrl,
            });
        }

        dispatch({
            type: ACTION_TYPES.SET_RECORD,
            value: {
                ...record,
                User: {
                    ...record.User,
                    PictureURL: val?.data?.FileUrl,
                },
            },
        });
    };

    useEffect(() => {
        if (Number(id) > 0) {
            callGetApplicant();
        }
    }, [id]);

    if (!record) return <h1 className="page-header">{languageTitles.recordNotFound}</h1>;

    const isCommonUser = loggedUser.UserType === 'user';

    const items = record?.Studies?.filter((item) => item.Status !== LineActions.DELETED).map((item) => (
        <div key={item.StudiesId} className="col-md-12 border-1 card-inverse card  mb-20px">
            <div className="row card-body">
                <div className="form-group col-md-1" style={{ width: '115px' }}>
                    <label className="control-label field">
                        {languageTitles.candidateStudiesStart}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{formatDate(item.StartDate)}</p>}
                    {isEditing && (
                        <DateTime
                            dateFormat="DD-MM-YYYY"
                            name="StartDate"
                            timeFormat={false}
                            inputProps={{ name: 'StartDate' }}
                            onChange={(data) => OnChangeValueInSections(data._d, item.StudiesId, 'Studies', 'StartDate')}
                            closeOnSelect
                            value={convertToDate(item.StartDate)}
                            locale={currentLocaleDate}
                            className="rdtPickerRight"
                        />
                    ) }
                </div>
                <div className="form-group col-md-1" style={{ width: '115px' }}>
                    <label className="control-label field">
                        {languageTitles.candidateStudiesEnd}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{formatDate(item.EndDate)}</p>}
                    {isEditing && (
                        <DateTime
                            dateFormat="DD-MM-YYYY"
                            name="EndDate"
                            timeFormat={false}
                            inputProps={{ name: 'EndDate' }}
                            onChange={(data) => OnChangeValueInSections(data._d, item.StudiesId, 'Studies', 'EndDate')}
                            closeOnSelect
                            value={convertToDate(item.EndDate)}
                            locale={currentLocaleDate}
                            className="rdtPickerRight"
                        />
                    )}
                </div>
                <div className="form-group col-md-3">
                    <label className="control-label field">
                        {languageTitles.candidateStudiesCenter}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{item.StudyCenter}</p>}
                    {isEditing && (
                        <textarea
                            type="text"
                            className="form-control"
                            rows="2"
                            name="StudyCenter"
                            onChange={(e) => OnChangeValueInSections(e.target.value, item.StudiesId, 'Studies', 'StudyCenter')}
                            value={item.StudyCenter ?? ''}
                        />
                    ) }
                </div>
                <div className="form-group col-md-2">
                    <label className="control-label field">
                        {languageTitles.candidateStudiesTitle}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{item.CertificateObtained}</p>}
                    {isEditing && (
                        <textarea
                            type="text"
                            className="form-control"
                            rows="2"
                            name="CertificateObtained"
                            onChange={(e) => OnChangeValueInSections(e.target.value, item.StudiesId, 'Studies', 'CertificateObtained')}
                            value={item.CertificateObtained ?? ''}
                        />
                    )}
                </div>
                <div className="form-group col-md-3">
                    <label className="control-label field">
                        {languageTitles.candidateStudiesTitleType}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{item.CertificateObtainedType}</p>}
                    {isEditing && (
                        <textarea
                            type="text"
                            className="form-control"
                            rows="2"
                            name="CertificateObtainedType"
                            onChange={(e) => OnChangeValueInSections(e.target.value, item.StudiesId, 'Studies', 'CertificateObtainedType')}
                            value={item.CertificateObtainedType ?? ''}
                        />
                    )}
                </div>
                {isEditing && (
                    <div className="form-group col-md-1">
                        <TSFButton
                            action="custom"
                            btnColor="danger"
                            className="mt-3"
                            useIcon
                            iconClass="fas fa-ban"
                            label={languageTitles.deleteTitle}
                            onClick={(e) => onRemoveItemInSections(e, item, 'Studies')}
                        />
                    </div>
                ) }
            </div>
        </div>
    ));

    const itemsExperience = record?.Experiences?.filter((item) => item.Status !== LineActions.DELETED).map((item) => (
        <div key={item.ExperienceId} className="col-md-12 border-1 border-primary card-inverse card  mb-20px">
            <div className="row card-body">
                <div className="form-group col-md-1" style={{ width: '115px' }}>
                    <label className="control-label field">
                        {languageTitles.candidateExperienceStart}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{formatDate(item.StartDate)}</p>}
                    {isEditing && (
                        <DateTime
                            dateFormat="DD-MM-YYYY"
                            name="StartDate"
                            timeFormat={false}
                            inputProps={{ name: 'StartDate' }}
                            onChange={(data) => OnChangeValueInSections(data._d, item.ExperienceId, 'Experiences', 'StartDate')}
                            closeOnSelect
                            value={convertToDate(item.StartDate)}
                            locale={currentLocaleDate}
                            className="rdtPickerRight"
                        />
                    )}
                </div>
                <div className="form-group col-md-1" style={{ width: '115px' }}>
                    <label className="control-label field">
                        {languageTitles.candidateExperienceEnd}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{formatDate(item.EndDate)}</p>}
                    {isEditing && (
                        <DateTime
                            dateFormat="DD-MM-YYYY"
                            name="EndDate"
                            timeFormat={false}
                            inputProps={{ name: 'EndDate' }}
                            onChange={(data) => OnChangeValueInSections(data._d, item.ExperienceId, 'Experiences', 'EndDate')}
                            closeOnSelect
                            value={convertToDate(item.EndDate)}
                            locale={currentLocaleDate}
                            className="rdtPickerRight"
                        />
                    )}
                </div>
                <div className="form-group col-md-2">
                    <label className="control-label field">
                        {languageTitles.candidateExperienceCompany}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{item.CompanyName}</p>}
                    {isEditing && (
                        <textarea
                            type="text"
                            className="form-control"
                            rows="2"
                            name="CompanyName"
                            onChange={(e) => OnChangeValueInSections(e.target.value, item.ExperienceId, 'Experiences', 'CompanyName')}
                            value={item.CompanyName ?? ''}
                        />
                    )}
                </div>
                <div className="form-group col-md-2">
                    <label className="control-label field">
                        {languageTitles.candidateExperienceJob}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{item.JobTitle}</p>}
                    {isEditing && (
                        <textarea
                            type="text"
                            className="form-control"
                            rows="2"
                            name="JobTitle"
                            onChange={(e) => OnChangeValueInSections(e.target.value, item.ExperienceId, 'Experiences', 'JobTitle')}
                            value={item.JobTitle ?? ''}
                        />
                    )}
                </div>
                <div className="form-group col-md-2">
                    <label className="control-label field">
                        {languageTitles.candidateExperienceCompanyLocation}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{item.CompanyLocation}</p>}
                    {isEditing && (
                        <textarea
                            type="text"
                            className="form-control"
                            rows="2"
                            name="CompanyLocation"
                            onChange={(e) => OnChangeValueInSections(e.target.value, item.ExperienceId, 'Experiences', 'CompanyLocation')}
                            value={item.CompanyLocation ?? ''}
                            placeholder={languageTitles.candidateExperienceCompanyLocationPlaceholder}
                        />
                    )}
                </div>
                <div className="form-group col-md-3">
                    <label className="control-label field">
                        {languageTitles.candidateExperienceJobDescription}
                        :
                    </label>
                    {!isEditing && <p className="col-form-label">{item.DescriptionJob}</p>}
                    {isEditing && (
                        <textarea
                            type="text"
                            className="form-control"
                            rows="2"
                            name="DescriptionJob"
                            onChange={(e) => OnChangeValueInSections(e.target.value, item.ExperienceId, 'Experiences', 'DescriptionJob')}
                            value={item.DescriptionJob ?? ''}
                        />
                    )}
                </div>
                {isEditing && (
                    <div className="form-group col-md-1">
                        <TSFButton
                            action="custom"
                            btnColor="danger"
                            className="mt-3"
                            useIcon
                            iconClass="fas fa-ban"
                            label={languageTitles.deleteTitle}
                            onClick={(e) => onRemoveItemInSections(e, item, 'Experience')}
                        />
                    </div>
                ) }
            </div>
        </div>
    ));

    const jobIdSelected = record?.JobPositionIds;
    const jobDetailIds = record?.JobPositions;

    const itemsjobs = jobDetails?.jobPositions?.data?.filter((c) => jobIdSelected?.includes(c.JobPositionId))
        .map((item) => {
            if (isEditing) {
                return (
                    <div className="col-sm-6 col-lg-4 col-xs-12" key={item.JobPositionId}>
                        <div className={`widget-todolist rounded mb-4 ${darkTheme ? 'dark-mode ' : ''}`}>
                            <div className="widget-todolist-header">
                                <div className="widget-todolist-header-title">{item[toPascalCase(currentLanguage)]}</div>
                            </div>
                            <div className={`widget-todolist-body ${(itemIdClick === item.JobPositionId && itemOpen) ? ' ' : ''}`}>
                                {
                                    item?.JobPositionDetail?.map((detail) => {
                                        const applied = jobDetailIds?.includes(detail?.JobPositionDetailId);
                                        return (
                                            <div
                                                className="widget-todolist-item"
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => addJobSkill(e, detail?.JobPositionDetailId)}
                                                key={detail.JobPositionDetailId}
                                            >
                                                <div className="widget-todolist-input">
                                                    <div className="form-check">
                                                        <input
                                                            checked={applied ?? false}
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="JobPositionDetailId"
                                                            onChange={() => null}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="widget-todolist-content">
                                                    <h6 className="mb-2px">{detail[toPascalCase(currentLanguage)]}</h6>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                <div className="col-sm-6 col-lg-4 col-xs-12" key={item.JobPositionId}>
                    <div className={`widget-todolist rounded mb-4 ${darkTheme ? 'dark-mode ' : ''}`}>
                        <div className="widget-todolist-header">
                            <div className="widget-todolist-header-title">{item[toPascalCase(currentLanguage)]}</div>
                        </div>
                        <div className="widget-todolist-body">
                            {
                                item?.JobPositionDetail?.filter((c) => jobDetailIds?.includes(c?.JobPositionDetailId))
                                    .map((detail) => (
                                        <div className="widget-todolist-item" key={detail.JobPositionDetailId}>
                                            <div className="widget-todolist-content">
                                                <h6 className="mb-2px">{detail[toPascalCase(currentLanguage)]}</h6>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>

            );
        });

    const jobPositionSelected = jobPositions?.filter((c) => jobIdSelected?.includes(c.value));

    return (
        <Panel>
            <PanelHeader>
                <ButtonGroup className="pull-right">
                    {
                        hasPermission(Access.APPLICANT_GENERATE_PDF)
                            && (
                                <Button
                                    color="orange"
                                    title={languageTitles.candidatesExportBtnTitle}
                                    className="me-1"
                                    onClick={(e) => onActionButtons(e, 'getpdf')}
                                >
                                    {
                                        printingReport
                                            ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-fw fa-file-pdf" />
                                    }
                                    {languageTitles.candidatesExportBtnTitle}
                                </Button>
                            )
                    }
                    <Button
                        color="green"
                        title={languageTitles.candidatesEditBtnTitle}
                        className={`btn-sm me-1 ${(isEditing || isLoading) ? 'hide ' : ''}`}
                        onClick={(e) => onActionButtons(e, 'edit')}
                    >
                        <i className="fa fa-edit" />
                        {' '}
                        {languageTitles.updateTitle}
                    </Button>
                    <TSFButton
                        action="refresh"
                        isHiddden={isEditing}
                        isWorking={isLoading || isSaving || printingReport}
                        onClick={(e) => onActionButtons(e, 'refresh')}
                    />
                    <TSFButton
                        action="save"
                        isWorking={isSaving || printingReport}
                        isHiddden={!(isEditing && isDirty)}
                        onClick={(e) => onActionButtons(e, 'save')}
                    />
                    <TSFButton
                        action="cancel"
                        isHiddden={!isEditing}
                        onClick={(e) => onActionButtons(e, 'cancel')}
                    />
                </ButtonGroup>
            </PanelHeader>
            <PanelBody>
                <div className="nav-wizards-container">
                    <Nav className="nav nav-wizards-2" pills>
                        <NavItem className="nav-item col">
                            <NavLink
                                className={classnames({ active: activeTab === '1' }, 'completed')}
                                onClick={() => { togglePill('1'); }}
                            >
                                <span className="d-sm-none nav-text">Per</span>
                                <span className="d-sm-block d-none nav-text">{languageTitles.candidateTabProfile}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="nav-item col">
                            <NavLink
                                className={classnames({ active: activeTab === '2' }, 'completed')}
                                onClick={() => { togglePill('2'); }}
                            >
                                <span className="d-sm-none nav-text">Est</span>
                                <span className="d-sm-block d-none nav-text">{languageTitles.candidateTabStudies}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="nav-item col">
                            <NavLink
                                className={classnames({ active: activeTab === '3' }, 'completed')}
                                onClick={() => { togglePill('3'); }}
                            >
                                <span className="d-sm-none nav-text">Exp</span>
                                <span className="d-sm-block d-none nav-text">{languageTitles.candidateTabExperience}</span>
                            </NavLink>
                        </NavItem>
                        {record.ApplicantId > 0 && (
                            <NavItem className="nav-item col">
                                <NavLink
                                    className={classnames({ active: activeTab === '4' }, 'completed')}
                                    onClick={() => { togglePill('4'); }}
                                >
                                    <span className="d-sm-none nav-text">Arc</span>
                                    <span className="d-sm-block d-none nav-text">{languageTitles.candidateTabFiles}</span>
                                </NavLink>
                            </NavItem>
                        )}
                        {record.ApplicantId > 0 && (
                            <NavItem className="nav-item col">
                                <NavLink
                                    className={classnames({ active: activeTab === '5' }, 'completed')}
                                    onClick={() => { togglePill('5'); }}
                                >
                                    <span className="d-sm-none nav-text">Pues</span>
                                    <span className="d-sm-block d-none nav-text">{languageTitles.candidateTabJob}</span>
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </div>
                <div className="card mt-20px">
                    <div className="card-body">
                        <TabContent className="p-15 rounded bg-white mb-4" activeTab={activeTab}>
                            <TabPane tabId="1">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <img
                                                    className="thumbnailimage"
                                                    alt=""
                                                    src={record?.User?.PictureURL ?? '/assets/img/user/user-13.jpg'}
                                                />
                                                {isEditing
                                                    && (
                                                        <UploadFile
                                                            additionalInfo={{ userId: record.UserId }}
                                                            graphql={UserGraphql.UPLOAD_USER_PICTURE}
                                                            resultNode="uploadPictureProfile"
                                                            acceptFiles=".jpg,.jpeg,.png"
                                                            afterUpload={(val) => onAfterUpload(val)}
                                                        />
                                                    )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateName}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.Name}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateName}
                                                        name="Name"
                                                        required
                                                        onChange={handleInputChange}
                                                        value={record.Name}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateLastName}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.LastName}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateLastName}
                                                        name="LastName"
                                                        onChange={handleInputChange}
                                                        value={record.LastName}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateBirthday}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {formatDate(record.Birthday)}
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <DateTime
                                                        input
                                                        dateFormat="DD-MM-YYYY"
                                                        name="Birthday"
                                                        timeFormat={false}
                                                        inputProps={{ placeholder: languageTitles.candidateBirthday, name: 'Birthday' }}
                                                        onChange={(data) => handleDateChange(data, 'Birthday')}
                                                        closeOnSelect
                                                        value={record.Birthday}
                                                        locale={currentLocaleDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateMaritalStatus}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        catalogs.find((c) => c.category.toLowerCase() === Catalogs.MARITAL_STATUS.toLowerCase()
                                                        && String(c.value) === String(record.MaritalStatusId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Catalog
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="MaritalStatusId"
                                                        catalogType={Catalogs.MARITAL_STATUS}
                                                        value={String(record.MaritalStatusId)}
                                                        placeholder={languageTitles.candidateMaritalStatus}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateGender}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        catalogs.find((c) => c.category.toLowerCase() === Catalogs.GENDER.toLowerCase()
                                                        && String(c.value) === String(record.GenderId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Catalog
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="GenderId"
                                                        catalogType={Catalogs.GENDER}
                                                        value={String(record.GenderId)}
                                                        placeholder={languageTitles.candidateGender}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateIdentification}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.IdentificationCode}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateIdentification}
                                                        name="IdentificationCode"
                                                        onChange={handleInputChange}
                                                        value={record.IdentificationCode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateHasLicence}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        catalogs.find((c) => c.category.toLowerCase() === Catalogs.PASSPORT_OPTIONS.toLowerCase()
                                                        && String(c.value) === String(record.HasDriverLicenseId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Catalog
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="HasDriverLicenseId"
                                                        catalogType={Catalogs.PASSPORT_OPTIONS}
                                                        value={String(record.HasDriverLicenseId)}
                                                        placeholder={languageTitles.candidateHasLicence}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        { record.HasDriverLicenseId === 12 && (
                                            <div className="row mb-3">
                                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateCategoryLicence}</label>
                                                <div className="col-md-8">
                                                    <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.DriverLicenseCategory}</label>
                                                    <div className={isEditing ? '' : 'hide'}>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={languageTitles.candidateCategoryLicence}
                                                            name="DriverLicenseCategory"
                                                            onChange={handleInputChange}
                                                            value={record.DriverLicenseCategory}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateProfession}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        coreCatalogs.find((c) => c.category.toLowerCase() === Catalogs.PROFESSION.toLowerCase()
                                                        && String(c.value) === String(record.ProfessionId))?.[currentLanguage]
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Profession returnSelectedValue={(data, options) => handleSelectControlChange(data, options)} name="ProfessionId" value={record.ProfessionId} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateEnglishLevel}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        catalogs.find((c) => c.category.toLowerCase() === Catalogs.LANGUAGE_LEVEL.toLowerCase()
                                                        && String(c.value) === String(record.EnglishLevelId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Catalog
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="EnglishLevelId"
                                                        catalogType={Catalogs.LANGUAGE_LEVEL}
                                                        value={String(record.EnglishLevelId)}
                                                        placeholder={languageTitles.candidateEnglishLevel}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateFrenchLevel}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        catalogs.find((c) => c.category.toLowerCase() === Catalogs.LANGUAGE_LEVEL.toLowerCase()
                                                        && String(c.value) === String(record.FrenchLevelId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Catalog
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="FrenchLevelId"
                                                        catalogType={Catalogs.LANGUAGE_LEVEL}
                                                        value={String(record.FrenchLevelId)}
                                                        placeholder={languageTitles.candidateFrenchLevel}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateChildrens}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.Childrens}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateChildrensCount}
                                                        name="Childrens"
                                                        onChange={handleInputChange}
                                                        value={record.Childrens}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">

                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateJobPosition}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{jobPositionSelected.map((c) => c.label).toString()}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <MultiSelect
                                                        hasSelectAll={false}
                                                        options={jobPositions}
                                                        value={jobPositionSelected}
                                                        onChange={setMultipleSelected}
                                                        labelledBy="Select"
                                                        className="selectCustomClass"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateCountryBorn}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        countries.find((c) => String(c.value) === String(record.CountryId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Country
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="CountryId"
                                                        value={String(record.CountryId)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateCityBorn}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.City}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateCityBorn}
                                                        name="City"
                                                        onChange={handleInputChange}
                                                        value={record.City}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateDepartmentBorn}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.Town}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateDepartmentBorn}
                                                        name="Town"
                                                        onChange={handleInputChange}
                                                        value={record.Town}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateCountryResident}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        countries.find((c) => String(c.value) === String(record.ResidentCountryId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Country
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="ResidentCountryId"
                                                        value={String(record.ResidentCountryId)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateCityResident}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.ResidentCity}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateCityResident}
                                                        name="ResidentCity"
                                                        onChange={handleInputChange}
                                                        value={record.ResidentCity}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateDepartmentResident}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.ResidentTown}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateDepartmentResident}
                                                        name="ResidentTown"
                                                        onChange={handleInputChange}
                                                        value={record.ResidentTown}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateAddress}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.AddressLineOne}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateAddress}
                                                        name="AddressLineOne"
                                                        onChange={handleInputChange}
                                                        value={record.AddressLineOne}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateAddress2}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.AddressLineTwo}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateAddress2}
                                                        name="AddressLineTwo"
                                                        onChange={handleInputChange}
                                                        value={record.AddressLineTwo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateCellphone}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    <i className="fa fa-mobile fa-lg m-r-5" />
                                                    {' '}
                                                    {record.PhoneNumberOne}
                                                    {' '}
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateCellphone}
                                                        name="PhoneNumberOne"
                                                        onChange={handleInputChange}
                                                        value={record.PhoneNumberOne}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateHomePhone}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    <i className="fa fa-mobile fa-lg m-r-5" />
                                                    {' '}
                                                    {record.PhoneNumberTwo}
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateHomePhone}
                                                        name="PhoneNumberTwo"
                                                        onChange={handleInputChange}
                                                        value={record.PhoneNumberTwo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateEmail}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.Email}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <input
                                                        readOnly
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateEmail}
                                                        name="Email"
                                                        onChange={handleInputChange}
                                                        value={record.Email}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateHasPassport}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        catalogs.find((c) => c.category.toLowerCase() === Catalogs.PASSPORT_OPTIONS.toLowerCase()
                                                        && String(c.value) === String(record.HasPassportId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Catalog
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="HasPassportId"
                                                        catalogType={Catalogs.PASSPORT_OPTIONS}
                                                        value={String(record.HasPassportId)}
                                                        placeholder={languageTitles.candidateHasPassport}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        { record.HasPassportId === 12 // si tiene pasaporte
                                        && (
                                            <>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidatePassport}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.PassportNumber}</label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={languageTitles.candidatePassport}
                                                                name="PassportNumber"
                                                                onChange={handleInputChange}
                                                                value={record.PassportNumber}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidatePassportExpirationDate}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                            {formatDate(record.ExpirationPassport)}
                                                        </label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <DateTime
                                                                input
                                                                dateFormat="DD-MM-YYYY"
                                                                name="ExpirationPassport"
                                                                timeFormat={false}
                                                                inputProps={{ placeholder: languageTitles.candidatePassportExpirationDate, name: 'ExpirationPassport' }}
                                                                onChange={(data) => handleDateChange(data, 'ExpirationPassport')}
                                                                closeOnSelect
                                                                value={record.ExpirationPassport}
                                                                locale={currentLocaleDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label className="col-form-label col-md-12 mb-2 mt-2 text-center">{languageTitles.candidateHasTravel}</label>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateCountryTravel}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                            {
                                                                countries.find((c) => String(c.value) === String(record.CountryVisitedOneId))?.label
                                                            }
                                                        </label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <Country
                                                                returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                                name="CountryVisitedOneId"
                                                                value={String(record.CountryVisitedOneId)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateStartDateTravel}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                            {formatDate(record.CountryVisitedOneStartDate)}
                                                        </label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <DateTime
                                                                dateFormat="DD-MM-YYYY"
                                                                name="CountryVisitedOneStartDate"
                                                                timeFormat={false}
                                                                inputProps={{ placeholder: languageTitles.candidateStartDateTravel, name: 'CountryVisitedOneStartDate' }}
                                                                onChange={(data) => handleDateChange(data, 'CountryVisitedOneStartDate')}
                                                                closeOnSelect
                                                                value={record.CountryVisitedOneStartDate}
                                                                locale={currentLocaleDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateEndDateTravel}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                            {formatDate(record.CountryVisitedOneEndDate)}
                                                        </label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <DateTime
                                                                dateFormat="DD-MM-YYYY"
                                                                name="CountryVisitedOneEndDate"
                                                                timeFormat={false}
                                                                inputProps={{ placeholder: languageTitles.candidateEndDateTravel, name: 'CountryVisitedOneEndDate' }}
                                                                onChange={(data) => handleDateChange(data, 'CountryVisitedOneEndDate')}
                                                                closeOnSelect
                                                                value={record.CountryVisitedOneEndDate}
                                                                locale={currentLocaleDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateCountryTravel}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                            {
                                                                countries.find((c) => String(c.value) === String(record.CountryVisitedTwoId))?.label
                                                            }
                                                        </label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <Country
                                                                returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                                name="CountryVisitedTwoId"
                                                                value={String(record.CountryVisitedTwoId)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateStartDateTravel}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                            {formatDate(record.CountryVisitedTwoStartDate)}

                                                        </label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <DateTime
                                                                dateFormat="DD-MM-YYYY"
                                                                name="CountryVisitedTwoStartDate"
                                                                timeFormat={false}
                                                                inputProps={{ placeholder: languageTitles.candidateStartDateTravel, name: 'CountryVisitedTwoStartDate' }}
                                                                onChange={(data) => handleDateChange(data, 'CountryVisitedTwoStartDate')}
                                                                closeOnSelect
                                                                value={record.CountryVisitedTwoStartDate}
                                                                locale={currentLocaleDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateEndDateTravel}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                            {formatDate(record.CountryVisitedTwoEndDate)}

                                                        </label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <DateTime
                                                                dateFormat="DD-MM-YYYY"
                                                                name="CountryVisitedTwoEndDate"
                                                                timeFormat={false}
                                                                inputProps={{ placeholder: languageTitles.candidateEndDateTravel, name: 'CountryVisitedTwoEndDate' }}
                                                                onChange={(data) => handleDateChange(data, 'CountryVisitedTwoEndDate')}
                                                                closeOnSelect
                                                                value={record.CountryVisitedTwoEndDate}
                                                                locale={currentLocaleDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateVisaDenied}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        catalogs.find((c) => c.category.toLowerCase() === Catalogs.OPTIONAL.toLowerCase()
                                                        && String(c.value) === String(record.VisaDeniedId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Catalog
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="VisaDeniedId"
                                                        catalogType={Catalogs.OPTIONAL}
                                                        value={String(record.VisaDeniedId)}
                                                        placeholder={languageTitles.candidateVisaDenied}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {record.VisaDeniedId === 25 && (
                                            <>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateVisaDeniedDate}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                            {formatDate(record.VisaDeniedDate)}
                                                        </label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <DateTime
                                                                input
                                                                dateFormat="DD-MM-YYYY"
                                                                name="VisaDeniedDate"
                                                                timeFormat={false}
                                                                inputProps={{ placeholder: languageTitles.candidateVisaDeniedDate, name: 'VisaDeniedDate' }}
                                                                onChange={(data) => handleDateChange(data, 'VisaDeniedDate')}
                                                                closeOnSelect
                                                                value={record.VisaDeniedDate}
                                                                locale={currentLocaleDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateVisaDeniedComments}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.VisaDeniedComments}</label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={languageTitles.candidateVisaDeniedComments}
                                                                rows="4"
                                                                name="VisaDeniedComments"
                                                                onChange={handleInputChange}
                                                                value={record.VisaDeniedComments ?? ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateLiveInOtherCountry}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        catalogs.find((c) => c.category.toLowerCase() === Catalogs.OPTIONAL.toLowerCase()
                                                        && String(c.value) === String(record.LivedCountryMoreThan6MonthsId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Catalog
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="LivedCountryMoreThan6MonthsId"
                                                        catalogType={Catalogs.OPTIONAL}
                                                        value={String(record.LivedCountryMoreThan6MonthsId)}
                                                        placeholder=""

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {record.LivedCountryMoreThan6MonthsId === 25 && (
                                            <>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateLivedCountry}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                            {
                                                                countries.find((c) => String(c.value) === String(record.LivedCountryId))?.label
                                                            }
                                                        </label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <Country
                                                                returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                                name="LivedCountryId"
                                                                value={String(record.LivedCountryId)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateLivedCountryDate}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                            {formatDate(record.LivedCountryDate)}

                                                        </label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <DateTime
                                                                input
                                                                dateFormat="DD-MM-YYYY"
                                                                timeFormat={false}
                                                                inputProps={{ placeholder: languageTitles.candidateLivedCountryDatePlaceHolder, name: 'LivedCountryDate' }}
                                                                onChange={(data) => handleDateChange(data, 'LivedCountryDate')}
                                                                closeOnSelect
                                                                value={record.LivedCountryDate}
                                                                locale={currentLocaleDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateLivedCountryMigrateStatus}</label>
                                                    <div className="col-md-8">
                                                        <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.LivedCountryMigrateStatus}</label>
                                                        <div className={isEditing ? '' : 'hide'}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={languageTitles.candidateLivedCountryMigrateStatusPlaceHolder}
                                                                name="LivedCountryMigrateStatus"
                                                                onChange={handleInputChange}
                                                                value={record.LivedCountryMigrateStatus}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateLeadSource}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>
                                                    {
                                                        catalogs.find((c) => c.category.toLowerCase() === Catalogs.LEAD_SOURCE.toLowerCase()
                                                        && String(c.value) === String(record.LeadSourceId))?.label
                                                    }
                                                </label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <Catalog
                                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                        name="LeadSourceId"
                                                        catalogType={Catalogs.LEAD_SOURCE}
                                                        value={String(record.LeadSourceId)}
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-8">
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateAboutMe}</label>
                                            <div className="col-md-8">
                                                <label className={`col-form-label field ${isEditing ? 'hide' : ''}`}>{record.AboutMe}</label>
                                                <div className={isEditing ? '' : 'hide'}>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={languageTitles.candidateAboutMe}
                                                        rows="4"
                                                        name="AboutMe"
                                                        onChange={handleInputChange}
                                                        value={record.AboutMe ?? ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="mb-1">{languageTitles.candidateIndicationsToFillData}</div>
                                <div className="row row-space-2 mt-3 forceMargin">
                                    {items}
                                    {
                                        isEditing
                                            ? (
                                                <h4 className="mt-3 mb-3">
                                                    <TSFButton
                                                        action="custom"
                                                        className="btn btn-primary"
                                                        label={languageTitles.addButton}
                                                        useIcon
                                                        iconClass="fas fa-plus"
                                                        onClick={(e) => onAddSection(e, 'Studies')}
                                                    />
                                                </h4>
                                            ) : ''
                                    }
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <div className="mb-1">{languageTitles.candidateIndicationsToFillData}</div>

                                <div className="row row-space-2 mt-3 forceMargin">
                                    {itemsExperience}
                                    {
                                        isEditing
                                            ? (
                                                <h4 className="mt-3 mb-3">
                                                    <TSFButton
                                                        action="custom"
                                                        className="btn btn-primary"
                                                        label={languageTitles.addButton}
                                                        useIcon
                                                        iconClass="fas fa-plus"
                                                        onClick={(e) => onAddSection(e, 'Experience')}
                                                    />
                                                </h4>
                                            ) : ''
                                    }
                                </div>
                            </TabPane>
                            {record.ApplicantId > 0 && (
                                <TabPane tabId="4">
                                    <FileRepository
                                        userId={isCommonUser ? loggedUser.UserId : record.UserId}
                                        applicantId={isCommonUser ? loggedUser.EntityId : record.ApplicantId}
                                        filters={
                                            [
                                                { fieldName: 'ApplicantId', value: isCommonUser ? String(loggedUser.EntityId) : String(record.ApplicantId) },
                                                { fieldName: 'UserId', value: isCommonUser ? String(loggedUser.UserId) : String(record.UserId) }]
                                        }
                                        isEditing={isEditing}
                                        isRefreshing={isLoading}
                                        fileTypes={[
                                            FileType.CURRICULUM,
                                            FileType.IDENTIFICATION,
                                            FileType.PASSPORT,
                                            FileType.DIPLOMA,
                                            FileType.TITLE,
                                            FileType.CERTIFICATE,
                                            FileType.LICENCE]}
                                    />
                                </TabPane>
                            )}
                            {record.ApplicantId > 0 && (
                                <TabPane tabId="5">
                                    <div className="row row-space-30">
                                        {itemsjobs}
                                    </div>
                                </TabPane>
                            )}
                        </TabContent>
                    </div>
                </div>
            </PanelBody>
        </Panel>
    );
}

export default Applicant;
