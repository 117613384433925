/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route } from 'react-router-dom';
import useCustomLocalStorage from '../components/hooks/useCustomLocalStorage';

function AppRoute(props) {
    const {
        component: Component,
        layout: Layout,
        isAuthProtected,
        location,
        ...rest
    } = props;

    const { getToken } = useCustomLocalStorage();

    return (
        <Route
            {...rest}
            render={() => {
                if (isAuthProtected && !getToken()) {
                    window.location.href = '/#/login';
                    window.location.reload();
                }

                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                );
            }}
        />
    );
}

export default AppRoute;
