/* eslint-disable react/no-unknown-property */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-undef */
import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import {
    Button, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import { Panel, PanelBody } from '../../panel/panel';
import FilesGraphql from '../../../servicesapollo/filesGraphql';
import AppContext from '../../../config/appContext';
import { Catalogs, FetchPolicy, NotificationType } from '../../../enums/general';

import useDateHelper from '../../../helpers/dateHelper';
import useMessageHelper from '../../../helpers/messageHelper';

import './fileRepository.css';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    SET_FILES_AFTER_DELETE: 'setFilesAfterDelete',
    SET_TABLE_APPROVED: 'setTableApproved',
    SET_CHECK_ITEM: 'setCheckItem',
    SET_APPROVE_ALL: 'setApproveAll',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_FILES_AFTER_DELETE: {
        const newArr = state.Files.filter((c) => c.FileId !== action.value.idDeleting);

        return {
            ...state,
            Files: newArr,
            idDeleting: 0,
        };
    }
    case ACTION_TYPES.SET_APPROVE_ALL: {
        const newRecords = state.Files.map((item) => ({
            ...item,
            IsChecked: !action.value,
        }));

        return {
            ...state,
            Files: newRecords,
        };
    }
    case ACTION_TYPES.SET_CHECK_ITEM: {
        const newRecords = state.Files.map((item) => ({
            ...item,
            IsChecked: item.FileId === action.value ? !item.IsChecked : item.IsChecked,
        }));

        return {
            ...state,
            Files: newRecords,
        };
    }
    default:
        return state;
    }
};

function FileRepository(props) {
    const {
        applicantId, userId, isEditing, isRefreshing,
        filters, selectionMode, showInPopup,
        onClosePopup, openPopup, defaultFileTypeId,
        onSelectedItems, fileTypes,
    } = props;

    const {
        showNotification, languageTitles,
        coreCatalogs, currentLanguage,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const initState = {
        Files: [],
        indexFiles: 0,
        idDeleting: 0,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const { formatDate } = useDateHelper();

    const { Files, indexFiles, idDeleting } = state;

    const dataFileType = coreCatalogs.filter((c) => Number(c.orden) === 5
    && String(c.category).toLowerCase() === Catalogs.FILE_TYPE.toLowerCase()
    && fileTypes.includes(Number(c.value))).map((c) => (
        {
            category: c.category,
            value: c.value,
            label: c[currentLanguage],
        }));

    const [getFiles, { refetch }] = useLazyQuery(FilesGraphql.GET_FILES_BY_FILTERS, {
        onCompleted: (res) => {
            if (res.files) {
                const {
                    data, success,
                } = res.files;

                if (success) {
                    dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        value: {
                            Files: data,
                        },
                    });
                }
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onAddFileSection = (e) => {
        e.preventDefault();

        Files.push({
            FileId: indexFiles - 1,
            ApplicationId: applicantId,
            FileTitle: '',
        });

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                Files,
                indexFiles: indexFiles - 1,
            },
        });
    };

    const [deleteFile, { loading: isDeleting }] = useMutation(FilesGraphql.DELETE_APPLICANT_FILES, {
        onCompleted: (mutationData) => {
            if (mutationData.deleteFiles) {
                const { success, message, error } = mutationData.deleteFiles;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenDeleteFile,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_FILES_AFTER_DELETE,
                    value: {
                        idDeleting,
                    },
                });

                showNotification(languageTitles.successWhenDeleteFile, NotificationType.SUCCESS);
                refetch();
            }
        },
        onError: (error) => {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    idDeleting: 0,
                },
            });
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onRemoveFileInSections = async (e, item) => {
        e.preventDefault();

        if (item.FileId > 0) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    idDeleting: item.FileId,
                },
            });

            deleteFile({
                variables: {
                    fileId: item.FileId,
                    userId,
                },
            });
        } else {
            dispatch({
                type: ACTION_TYPES.SET_FILES_AFTER_DELETE,
                value: {
                    idDeleting: item.FileId,
                },
            });
        }
    };

    const handleFileChange = (columnName, value, ele) => {
        if (columnName === 'FileTypeId' && (
            value === 27 || value === 28 || value === 29 || value === 30
        )) {
            const exists = Files.find((c) => c.FileTypeId === value);

            if (exists) {
                showNotification(languageTitles.fileTypeSelectedAlreadyExists, 'danger');
                return;
            }
        }

        if (columnName === 'FileInput' && value.files && !(value.files[0].type.includes('pdf'))) {
            showNotification(languageTitles.allowOnlyPdf, 'danger');
            return;
        }

        const newFiles = Files.map((c) => {
            const item = { ...c };
            if (item.FileId === ele.FileId) {
                item[columnName] = value;
                item.FileUrl = columnName === 'FileInput' ? value.files[0].name : item.FileUrl;
                item.FileTitle = columnName === 'FileInput' ? value.files[0].name : item.FileTitle;
            }

            return item;
        });

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                Files: newFiles,
            },
        });
    };

    const validateFilesBeforeSend = (result) => {
        let resultado = true;

        for (let index = 0; index < Files.length; index += 1) {
            const c = Files[index];

            if (c.FileId < 0) {
                if (c?.FileInput === '' || c?.FileInput === undefined || c?.FileInput === null) {
                    showNotification(`${languageTitles.notSelectedFileInLine} ${index + 1}`, 'danger');
                    resultado = false;
                    break;
                }
                if ((c.FileTypeId === '' || c.FileTypeId === undefined || c.FileTypeId === null) && !defaultFileTypeId) {
                    showNotification(`${languageTitles.notSelectedFileTypeInLine} ${index + 1}`, 'danger');
                    resultado = false;
                    break;
                }
            }
        }

        return result(resultado);
    };

    const [uploadFile, { loading: isUploading }] = useMutation(FilesGraphql.UPLOAD_APPLICANT_FILES, {
    });

    const onUploadFile = async (e) => {
        e.preventDefault();

        const validateFiles = validateFilesBeforeSend((res) => res);
        if (!validateFiles) return;

        const filesToUpload = Files.filter((c) => c?.FileInput !== undefined);
        const oldFiles = Files.filter((c) => c?.FileInput === undefined);

        if (filesToUpload.length > 0) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    errorMessage: '',
                },
            });

            const promises = filesToUpload.map((file) => {
                const { files } = file.FileInput;

                return uploadFile({
                    variables: {
                        file: files[0],
                        userId,
                        applicantId,
                        fileTypeId: defaultFileTypeId ?? file.FileTypeId,
                    },
                })
                    .then((res) => {
                        const {
                            success, data: dataFile,
                        } = res?.data?.singleUpload;

                        if (success) {
                            file.FileId = dataFile.FileId;
                            file.FileUrl = dataFile.FileUrl;
                            file.FileTitle = dataFile.FileTitle;
                            file.FileSize = dataFile.FileSize;
                            file.CreatedOn = dataFile.CreatedOn;
                            file.FileTypeId = dataFile.FileTypeId;
                            delete file.IsChecked;
                            delete file.FileInput;
                        }

                        return file;
                    }).catch((err) => {
                        console.log(err);
                    });
            });

            const resultFiles = await Promise.all(promises);
            const newFiles = [
                ...oldFiles,
                ...resultFiles,
            ];

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    Files: newFiles,
                },
            });
        }
    };

    useEffect(() => {
        if (applicantId > 0) {
            getFiles({
                variables: {
                    filters,
                    fileTypes,
                },
            });
        }
    }, [applicantId]);

    useEffect(() => {
        if (isRefreshing && applicantId > 0) refetch();
    }, [isRefreshing]);

    const allApproved = Files.length > 0 && Files.filter((c) => c.IsChecked).length === Files.length;

    const recordsSelected = Files?.filter((c) => c.IsChecked);

    const mainReturn = (
        <Panel>
            <PanelBody>
                <table className="table table-striped align-middle" style={{ marginBottom: '165px' }}>
                    <thead>
                        <tr>
                            <th width="10px">#</th>
                            { selectionMode && (
                                <th width="10%">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="table_checkboxall"
                                        checked={allApproved ?? false}
                                        onChange={() => dispatch({ type: ACTION_TYPES.SET_APPROVE_ALL, value: allApproved })}
                                    />
                                </th>
                            )}
                            {!defaultFileTypeId
                                && <th width="20%">{languageTitles.documentRepositoryTableColumn1}</th> }
                            <th>{languageTitles.documentRepositoryTableColumn2}</th>
                            <th>{languageTitles.documentRepositoryTableColumn3}</th>
                            <th>{languageTitles.documentRepositoryTableColumn4}</th>
                            <th width="1%">{languageTitles.documentRepositoryTableColumn5}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { Files?.map((c, i) => (
                            <tr key={c.FileId}>
                                <td>{i + 1}</td>
                                { selectionMode
                                    && (
                                        <td>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`table_checkbox${c.FileId}`}
                                                checked={c.IsChecked}
                                                onChange={() => dispatch({ type: ACTION_TYPES.SET_CHECK_ITEM, value: c.FileId })}
                                            />
                                        </td>
                                    )}
                                {!defaultFileTypeId
                                        && (
                                            <td>
                                                {isEditing && (
                                                    <Select
                                                        onChange={(selectedOption) => handleFileChange('FileTypeId', selectedOption.value, c)}
                                                        options={dataFileType}
                                                        value={{ value: c.FileTypeId, label: dataFileType?.find((x) => x.value === c.FileTypeId)?.label ?? '' }}
                                                        placeholder={languageTitles.documentRepositoryTableColumn1}
                                                        className="customrelativedropdown"
                                                    />
                                                )}
                                                {!isEditing && dataFileType?.find((x) => x.value === c.FileTypeId)?.label}
                                            </td>
                                        )}
                                <td>{c.FileTitle}</td>
                                <td>{c.FileUrl}</td>
                                <td>{formatDate(c.CreatedOn)}</td>
                                <td nowrap="true" style={{ display: 'inline-grid' }}>
                                    <input
                                        id={`fileinput${i}id`}
                                        className="hide"
                                        onChange={(e) => handleFileChange('FileInput', e.target, c)}
                                        type="file"
                                        placeholder={languageTitles.mailSelectFile}
                                        accept=".pdf"
                                    />
                                    {isEditing
                            && (
                                <Button
                                    color="info"
                                    size="sm"
                                    className={`me-1 mb-1 ${isDeleting || isUploading ? 'disabled' : ''}`}
                                    onClick={() => document.getElementById(`fileinput${i}id`).click()}
                                >
                                    { !c.isUploading && <i className="fa fa-file fa-fw" />}
                                    { c.isUploading && <i className="fa fa-spinner fa-spin fa-fw" /> }
                                    { !c.isUploading && languageTitles.documentRepositoryBtnChooseFile }
                                    { c.isUploading && languageTitles.uploadingTitle }
                                </Button>
                            ) }
                                    {isEditing && !isUploading && (
                                        <Button
                                            color="danger"
                                            size="sm"
                                            className={`me-1 mb-1 ${isDeleting || isUploading ? 'disabled' : ''}`}
                                            onClick={(e) => onRemoveFileInSections(e, c)}
                                        >
                                            { isDeleting && idDeleting === c.FileId && <i className="fa fa-spinner fa-spin fa-fw" /> }
                                            { !idDeleting && <i className="fa fa-trash-alt fa-fw" /> }
                                            {languageTitles.deleteTitle}
                                        </Button>
                                    )}
                                    { c.FileId > 0 && !isUploading && (
                                        <a href={c.FileUrl} target="_blank" rel="noreferrer">
                                            <Button
                                                color="info"
                                                size="sm"
                                                className={`me-1 mb-1 ${isDeleting || isUploading ? 'disabled' : ''}`}
                                            >
                                                <i className="fa fa-download" />
                                                {' '}
                                                {languageTitles.donwloadTitle}
                                            </Button>
                                        </a>
                                    )}
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={selectionMode ? 7 : 6}>
                                <div className="btn-group">
                                    {
                                        isEditing && !isUploading && !isDeleting && (
                                            <Button
                                                color="primary"
                                                className={`me-1 mb-1 ${isDeleting || isUploading ? 'disabled' : ''}`}
                                                onClick={(e) => onAddFileSection(e)}
                                            >
                                                <i className="fa fa-plus" />
                                                {' '}
                                                {languageTitles.documentRepositoryBtnAddFile}
                                            </Button>
                                        )
                                    }
                                    {
                                        isEditing && Files?.filter((c) => c?.FileInput !== undefined)?.length > 0 && (
                                            <Button
                                                color="success"
                                                className={`me-1 mb-1 ${isDeleting || isUploading ? 'disabled' : ''}`}
                                                onClick={(e) => onUploadFile(e)}
                                            >
                                                { isUploading ? <i className="fa fa-spinner fa-spin fa-fw" /> : <i className="fa fa-cloud fa-fw" /> }
                                                { isUploading ? languageTitles.documentRepositoryBtnUploadingFiles : languageTitles.documentRepositoryBtnUploadFiles }
                                            </Button>
                                        )
                                    }
                                    {
                                        selectionMode && recordsSelected?.length > 0 && (
                                            <Button
                                                color="success"
                                                className={`me-1 mb-1 ${isDeleting || isUploading ? 'disabled' : ''}`}
                                                onClick={() => onSelectedItems(recordsSelected)}
                                            >
                                                <i className="fa fa-check" />
                                                {' '}
                                                {languageTitles.chooseTitle}
                                            </Button>
                                        )
                                    }
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </PanelBody>
        </Panel>
    );

    if (showInPopup) {
        return (
            <Modal
                isOpen={openPopup}
                size="xl"
                toggle={onClosePopup}
            >
                <ModalHeader toggle={onClosePopup}>{languageTitles.documentRepositoryChooseFileTitle}</ModalHeader>
                <ModalBody>
                    { mainReturn }
                </ModalBody>
            </Modal>
        );
    }

    return (<>{mainReturn}</>);
}

FileRepository.propTypes = {
    applicantId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isRefreshing: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.object),
    selectionMode: PropTypes.bool,
    showInPopup: PropTypes.bool,
    onClosePopup: PropTypes.func,
    openPopup: PropTypes.bool,
    defaultFileTypeId: PropTypes.number,
    onSelectedItems: PropTypes.func,
    fileTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

FileRepository.defaultProps = {
    isRefreshing: false,
    filters: null,
    selectionMode: false,
    showInPopup: false,
    defaultFileTypeId: null,
    onClosePopup: () => {},
    openPopup: false,
    onSelectedItems: () => {},
};

export default FileRepository;
