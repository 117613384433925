/* eslint-disable max-len */
const termanResultTitle = {
    1: `El subtest de <strong>Información y Conocimientos</strong> es para detectar la cultura y conocimientos generales; reeja la idea y
    experiencia que ha aprendido y organizado la persona. Memoria mediata (largo plazo”).`,
    2: `El subtest de <strong>Comprensión</strong> representa el juicio y sentido común; manejo de la realidad. Implica la capacidad de juicio
    para comprender y responder a situaciones dadas en la práctica. Sugiere el nivel de funcionamiento intelectual.`,
    3: `El subtest de <strong>Significados Verbales</strong> representa la capacidad de análisis y síntesis en la formación de conceptos
    verbales. Cultura General. Implica el manejo de símbolos verbales, y la riqueza de vocabulario que la persona ha
    logrado desarrollar en su medio ambiente.`,
    4: `El subtest de <strong>Selección Lógica</strong> está dirigido a conocer la capacidad para razonar y deducir lógicamente conceptos
    verbales de acuerdo a la experiencia. Capacidad de abstracción. El ejercicio requiere de conceptualizar los principios
    de las cosas, a través de una clasificación en base a la importancia de la organización misma del material, y
    finalmente de la selección en base a una lógica establecida`,
    5: 'El ejercicio de <strong>Aritmética</strong> se basa en el razonamiento y manejo de aspectos cuantitativos y conocimientos adquiridos. Mide la habilidad del sujeto para manejar operaciones de tipo aritmético. Refleja la práctica y la experiencia en manejar cantidades y problemas basados en las matemáticas. Se utiliza el razonamiento de proporciones. La persona hace uso de su atención y concentración para entender el problema, manejarlo mentalmente, y proponer las operaciones aritméticas necesarias para encontrar un resultado.',
    6: ` El subtest de <strong>Juicio Práctico</strong> está orientado hacia la capacidad para captar situaciones de la vida diaria con sentido
    común. Este ejercicio tiene un contenido semejante al de la primera serie encontrándose como operación
    fundamental de la inteligencia, la comprensión y el sentido de frases.`,
    7: ` El subtest de <strong>Analogías</strong> evalúa la habilidad para comprender conceptos expresados en palabras, la habilidad para
    razonar, abstraer, generalizar y pensar en forma organizada. Implica una analogía basada en áreas abstractas,
    buscando proporciones y semejanzas. Se necesita conceptualizar el razonamiento sobre una base de clasificación y
    vocabularios adecuados.`,
    8: `El ejercicio de <strong>Ordenamiento de Frases</strong> está orientado a la planeación, comprensión y organización de conceptos
    verbales, y atención a pequeños detalles. Mide la capacidad para ordenar un material desestructurado, utilizando
    detalles y clasificación lógica para llegar a formar una frase. Requiere de un buen grado de atención y cuidado de los
    detalles, así como permanecer atento a la tarea y llegar a conjuntar un material organizado, por lo que la serie es rica
    para medir la capacidad de sintetizar los elementos para formar un programa.`,
    9: `El subtest de <strong>Clasificación</strong> mide la discriminación lógica de conceptos verbales, la rapidez de conceptualización,
    procediendo a realizar la clasificación, discriminación y jerarquización de los conceptos mismos`,
    10: `El subtest de <strong>Seriación</strong> está orientado al análisis y síntesis en el manejo de aspectos BAJO cuantitativos. Es la
    capacidad de deducción. Participa de las características de la serie de Aritmética, sin embargo mide en forma
    especial el razonamiento basado en las cantidades. El individuo requiere analizar las partes como un todo para llegar
    a encontrar la respuesta; necesita abstraer un principio y aplicarlo en su conclusión. Debe conocer y manejar las
    proporciones aritméticas`,
};

const termanResultText = {
    /// /informacion y conocimientos
    // Menor a 30% DEFICIENTE
    '1_1': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo no ha abstraído de su medio ambiente casi nada de información; refleja una carente ambición intelectual, el candidato no posee formación intelectual básica.',
    // Mayor al 30% y menor al 40% INFERIOR
    '1_2': 'Debido a la inferior puntuación del candidato en este subtest se prevé que este individuo no ha abstraído de su medio ambiente casi nada de información; refleja una carente ambición intelectual, el candidato no posee formación intelectual básica.',
    // Mayor al 40% y menor al 60% TERMINO MEDIO
    '1_3': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo no ha abstraído de su medio ambiente suficiente información; refleja poca ambición intelectual, el candidato posee formación intelectual básica relativamente baja.',
    // Mayor al 60% y menor al 80% TERMINO ALTO
    '1_4': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo posee un nivel de informacion general o cultura general, levemente por encima del promedio, ha abstraído de su medio ambiente suficiente información y no se queda con lo que el medio le proporciona, busca mas allá , se documenta , se informa y mantiene buen nivel de conversación ; refleja ambición intelectual, el candidato posee formación intelectual básica relativamente alta.',
    // Mayor al 80% y menor al 90% TERMINO SUPERIOR
    '1_5': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo posee un nivel de informacion general o cultura general, superior, ha abstraído de su medio ambiente mas información que la que le ha sido suministrada en el sistema educativo, es una persona muy bien instruido y maneja de muchos temas, es autodidacta , tiene fuertes deseos de progresar y se documenta , se informa y mantiene muy buen nivel de conversación ; refleja ambición intelectual, el candidato posee formación intelectual básica superior , es capaz de tomar decisiones con muy buenos criterios.',
    // Mayor al 90% y menor o igual al 100% EXCELENTE
    '1_6': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo posee un nivel de informacion general o cultura general, excelente, es una persona bien culta',

    /// /comprension
    // Menor a 30% DEFICIENTE
    '2_1': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca inteligencia abstracta',
    // Mayor al 30% y menor al 40% INFERIOR
    '2_2': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo presenta un bajo nivel de inteligencia abstracta',
    // Mayor al 40% y menor al 60% TERMINO MEDIO
    '2_3': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo presenta un nivel medio de inteligencia abstracta con gran ajuste a las normas sociales establecidas y un buen aprovechamiento de experiencias previas.',
    // Mayor al 60% y menor al 80% TERMINO ALTO
    '2_4': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel alto de inteligencia abstracta con gran ajuste a las normas sociales establecidas y un buen aprovechamiento de experiencias previas.',
    // Mayor al 80% y menor al 90% TERMINO SUPERIOR
    '2_5': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de inteligencia abstracta con gran ajuste a las normas sociales establecidas y un buen aprovechamiento de experiencias previas.',
    // Mayor al 90% y menor o igual al 100% EXCELENTE
    '2_6': 'Debido a la excelente puntuación del candidato en este subtest se prevé que este individuo posee un nivel de inteligencia abstracta sobresaliente, es una persona centrada',

    /// /significados verbales
    // Menor a 30% DEFICIENTE
    '3_1': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca riqueza de vocabulario',
    // Mayor al 30% y menor al 40% INFERIOR
    '3_2': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca riqueza de vocabulario',
    // Mayor al 40% y menor al 60% TERMINO MEDIO
    '3_3': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo presenta un bajo nivel de vocabulario',
    // Mayor al 60% y menor al 80% TERMINO ALTO
    '3_4': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo presenta un nivel medio en riqueza de vocabulario',
    // Mayor al 80% y menor al 90% TERMINO SUPERIOR
    '3_5': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de vocabulario y articulación de ideas, se expresa muy bien',
    // Mayor al 90% y menor o igual al 100% EXCELENTE
    '3_6': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de vocabulario y articulación de ideas, se expresa muy bien',

    /// /seleccion logica
    '4_1': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de razonamiento lógico',
    '4_2': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de razonamiento lógico',
    '4_3': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta bajo nivel de razonamiento lógico y capacidad de abstraccion',
    '4_4': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo presenta un nivel medio en razonamiento lógico y capacidad de abstraccion',
    '4_5': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de razonamiento lógico y capacidad de abstraccion',
    '4_6': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de razonamiento lógico y capacidad de abstraccion',
    /// /
    '5_1': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad numérica',
    '5_2': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad numérica',
    '5_3': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta bajo nivel de habilidad numerica , puede hacer operaciones matemáticas sencillas',
    '5_4': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo presenta un nivel medio en razonamiento lógico y capacidad de abstraccion',
    '5_5': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de razonamiento lógico y capacidad de abstraccion',
    '5_6': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de razonamiento lógico y capacidad de abstraccion',
    /// /juicio practico
    '6_1': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad para resolver problemas cotidianos',
    '6_2': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad para resolver problemas cotidianos ',
    '6_3': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta una deficiente habilidad para resolver problemas cotidianos',
    '6_4': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta la habilidad promedio para resolver problemas cotidianos',
    '6_5': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta buen nivel de juicio practico para resolver problemas cotidianos',
    '6_6': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de razonamiento de juicio practico y toma de decisiones acertadas en la solución de problemas',
    /// /analogias
    '7_1': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de comunicación ',
    '7_2': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de comunicación',
    '7_3': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de comunicación y poca capacidad de comprension',
    '7_4': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de comunicación y poca capacidad de comprension',
    '7_5': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo presenta buenas habilidades de comunicación y alta capacidad de comprensión',
    '7_6': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de vocabulario, comunicación , comprensión y articulación de ideas',
    /// /Ordenamiento
    '8_1': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de sintetizar',
    '8_2': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de sintetizar',
    '8_3': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de sintetizar',
    '8_4': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de sintetizar y poca capacidad de resumir',
    '8_5': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo presenta buenas habilidades de sinterización y resumen',
    '8_6': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de sinterización, ordenamiento y resumen de ideas',
    /// /clasificacion
    '9_1': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de conceptualización',
    '9_2': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de conceptualización',
    '9_3': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de conceptualización',
    '9_4': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad de conceptualización',
    '9_5': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo presenta buenas habilidades de comunicación y alta capacidad de comprensión',
    '9_6': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior de vocabulario, comunicación , comprensión y articulación de ideas',
    /// /seriacion
    '10_1': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad numérica',
    '10_2': 'Debido a la deficiente puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad numérica',
    '10_3': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad numérica',
    '10_4': 'Debido a la baja puntuación del candidato en este subtest se prevé que este individuo presenta muy poca habilidad numérica y de razonamiento lógico',
    '10_5': 'Debido a la puntuación del candidato en este subtest se prevé que este individuo presenta buenas Muy buenas habilidades numéricas, de símbolos y proporciones',
    '10_6': 'Debido a la alta puntuación del candidato en este subtest se prevé que este individuo presenta un nivel superior buenas habilidades numéricas, de símbolos y proporciones',
};

export { termanResultTitle, termanResultText };
