/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import NVD3Chart from 'react-nvd3';
import { useLazyQuery, useSubscription } from '@apollo/client';
import moment from 'moment';
import * as d3 from 'd3';
import {
    Alert,
    Button, Card, CardBody, CardImg, CardTitle, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import HorizontalTimeline from 'react-horizontal-timeline';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppContext from '../../../config/appContext';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';
import { FetchPolicy, NotificationType, TestStatus } from '../../../enums/general';
import WidgetStatsV2 from '../../widgetstats/widgetstatsV2';
import { Panel, PanelBody, PanelHeader } from '../../panel/panel';
import TestApplicantGraphql from '../../../servicesapollo/testApplicantGraphql';
import UserGraphql from '../../../servicesapollo/userGraphql';

import './dashboard.css';
import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';
import '../company/company.css';
import useCustomLocalStorage from '../../hooks/useCustomLocalStorage';

const VALUES = [
    '2008-06-01',
    '2010-06-01',
    '2013-06-01',
    '2015-03-01',
    '2019-01-01',
    '2019-06-17',
    '2019-08-01',
];

function DashboardV2() {
    const {
        color, languageTitles, loggedUser, showNotification,
        handleSetAppContentClass,
        handleSetAppContentFullHeight,
        setCompanyToEdit,
    } = useContext(AppContext);
    const { setCompany } = useCustomLocalStorage();
    const { getErrorMessage } = useMessageHelper();

    const isAdmin = loggedUser?.UserType === 'admin' || loggedUser?.UserType === 'superadmin';
    const isCompany = loggedUser?.IsCompanyUser;
    const companies = loggedUser?.Companies;
    const history = useHistory();

    const [pendingPointSelected, setPendingPoint] = useState('');

    const [getData, { data, refetch }] = useLazyQuery(ApplicantsGraphql.GET_DASHBOARD_INFO, {
        variables: {
            filters: [{ fieldName: 'ApplicantId', value: String(loggedUser.EntityId) }],
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getPercentageByApplicants, { data: dashboardUserData, refetch: dashboardUserRefetch }] = useLazyQuery(ApplicantsGraphql.GET_DASHBOARD_INFO_FOR_USER, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const colorChart = [color.blue, color.teal];

    const records = data?.getDashboardInfo;
    const lastUserLogged = data?.lastLogins;
    const testApplications = data?.testApplicants?.data || dashboardUserData?.testApplicants?.data;

    const totalApplicants = records?.find((c) => c.Key === 1).Total;

    // const formatDate = (d) => {
    //     const monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    //     d = new Date(d);
    //     d = `${monthsName[d.getMonth()]} ${d.getFullYear()}`;
    //     return d;
    // };

    const getDate = (minusDate) => {
        let d = new Date();
        d = d.setDate(d.getDate() - minusDate);
        return d;
    };

    const donutChartOptions = {
        donut: true,
        showLegend: false,
        growOnHover: false,
        arcsRadius: [
            { inner: 0.65, outer: 0.93 },
            { inner: 0.6, outer: 1 },
        ],
        margin: {
            left: 10, right: 10, top: 10, bottom: 10,
        },
        donutRatio: 0.5,
        valueFormat: d3.format(',.0f'),
    };

    const donutChartData = records?.filter((c) => c.Key === 6)
        .map((c, i) => ({
            label: c.Description,
            value: c.Total,
            percentage: (Number(c.Total) * 100) / Number(totalApplicants),
            color: colorChart[i],
        })) ?? [];

    const areaChartOptions = {
        pointSize: 0.5,
        useInteractiveGuideline: false,
        // durection: 300,
        showControls: false,
        controlLabels: {
            stacked: 'Stacked',
        },
        yAxis: {
            tickFormat: d3.format(',.0f'),
        },
        xAxis: {
            tickFormat(d) {
                let dd = d;
                const monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                dd = new Date(d);
                dd = `${monthsName[dd.getMonth()]} ${dd.getFullYear()}`;
                return dd;
            },
        },
    };

    const areaChartData = [
        {
            key: languageTitles.dashboardGraphLegend,
            color: colorChart[0],
            values: [],
        },
    ];

    records?.filter((c) => c.Key === 4)
        .forEach((c) => {
            areaChartData[0].values.push(
                {
                    x: getDate(Number(c.Description)),
                    y: Number(c.Total),
                },
            );
        });

    const reloadDashboard = () => {
        if (isAdmin) refetch();
        else dashboardUserRefetch();
    };

    useSubscription(ApplicantsGraphql.DASHBOARD_CHANGED, {
        onSubscriptionData: ({ subscriptionData }) => {
            if (subscriptionData?.data?.dashboardChanged?.success) reloadDashboard();
        },
    });

    useSubscription(TestApplicantGraphql.TEST_CHANGED, {
        onSubscriptionData: ({ subscriptionData }) => {
            if (subscriptionData?.data?.testApplicantChanged?.success) reloadDashboard();
        },
    });

    useSubscription(UserGraphql.SUBSCRIBE_USER, {
        onSubscriptionData: ({ subscriptionData }) => {
            if (subscriptionData?.data?.usersChanged?.success) reloadDashboard();
        },
    });

    const dataPercentage = dashboardUserData?.getPercentageByApplicant?.data?.[0];

    const donutChartDataPercentage = [{
        label: languageTitles.dashboardProfileChartCompleteLabel,
        value: dataPercentage?.PercentageFilled,
        percentage: dataPercentage?.PercentageFilled,
        color: colorChart[0],
    }, {
        label: languageTitles.dashboardProfileChartPendingLabel,
        value: 100 - dataPercentage?.PercentageFilled ?? 0,
        percentage: 100 - dataPercentage?.PercentageFilled ?? 0,
        color: colorChart[1],
    }];

    const getPsychometricTest = () => (
        <Panel>
            <PanelHeader showBackHistory={false}>{languageTitles.dashboardPsycoTestTableLabel}</PanelHeader>
            <PanelBody>
                <div className="table-responsive">
                    <table className="table table-striped mb-0 align-middle">
                        { testApplications?.length === 0 ? (
                            <tbody>
                                <tr>
                                    <td>{languageTitles.dashboardPsycoTestNoRecordsFound}</td>
                                </tr>
                            </tbody>
                        ) : (
                            <>
                                <thead>
                                    <tr>
                                        <th>{languageTitles.dashboardPsycoTestColumnDetail}</th>
                                        <th>{languageTitles.dashboardPsycoTestColumnAction}</th>
                                        <th>{languageTitles.dashboardPsycoTestColumnStatus}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {testApplications?.map((item) => {
                                        let status = item.Status;
                                        if (new Date(item.ExpirationDate) < new Date() && item?.Status.toLowerCase() !== TestStatus.COMPLETADA.toLowerCase()) status = TestStatus.VENCIDA;
                                        let statusColor = 'primary';
                                        if (status.toLowerCase() === TestStatus.COMPLETADA.toLowerCase()) statusColor = 'green';
                                        if (status.toLowerCase() === TestStatus.VENCIDA.toLowerCase()) statusColor = 'danger';

                                        return (
                                            <tr key={item.TestApplicantId}>
                                                <td>
                                                    {[TestStatus.VENCIDA.toLowerCase(), TestStatus.COMPLETADA.toLowerCase()].includes(item.Status.toLowerCase()) && languageTitles.dashboardPsycoTestUnableTest}
                                                    {![TestStatus.VENCIDA.toLowerCase(), TestStatus.COMPLETADA.toLowerCase()].includes(item.Status.toLowerCase())
                                                        && `${languageTitles.dashboardPsycoTestEnableLegend} ${item.TypeTest === 1 ? languageTitles.dashboardPsycoTestDISC : languageTitles.dashboardPsycoTestTerman}`}
                                                </td>
                                                <td nowrap="true">
                                                    {status.toLowerCase() !== TestStatus.COMPLETADA.toLowerCase()
                                                                    && status.toLowerCase() !== TestStatus.VENCIDA.toLowerCase()
                                                                    && (
                                                                        <Button
                                                                            color="primary"
                                                                            size="sm"
                                                                            title={languageTitles.candidatesProcessApplicationBtnTitle}
                                                                            className="btn me-1"
                                                                            onClick={() => history.push(`/test/${item.TypeTest === 1 ? 'prueba' : 'prueba2'}/${item.TestApplicantId}`)}
                                                                        >
                                                                            {languageTitles.dashboardPsycoTestStartBtn}
                                                                        </Button>
                                                                    )}
                                                </td>
                                                <td className="text-center fs-15px"><span className={`badge bg-${statusColor} rounded-pill`}>{status}</span></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </>
                        )}
                    </table>
                </div>
            </PanelBody>
        </Panel>
    );

    useEffect(() => {
        handleSetAppContentFullHeight(true);
        handleSetAppContentClass('p-10 bg-white border-content');

        return () => {
            handleSetAppContentFullHeight(false);
            handleSetAppContentClass('');
        };
    }, []);

    useEffect(() => {
        if (loggedUser?.EntityId > 0 && isAdmin && !isCompany) getData();

        if (loggedUser?.EntityId > 0 && !isAdmin && !isCompany) {
            getPercentageByApplicants({
                variables: {
                    ids: [loggedUser.EntityId],
                    filters: [{ fieldName: 'ApplicantId', value: String(loggedUser.EntityId) }],
                },
            });
        }
    }, [loggedUser?.EntityId]);

    if (isCompany) {
        return (
            <div className="h-100 d-flex flex-column">
                <PerfectScrollbar className="" options={{ suppressScrollX: true }}>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <Alert color="">
                                <strong>
                                    <div className="font-size-15" dangerouslySetInnerHTML={{ __html: languageTitles.dashboardCompanyWelcomeMessage }} />

                                    <Button
                                        color="primary"
                                        className="btn-lg"
                                        onClick={() => history.push(`/company/${loggedUser.EntityId}`)}
                                    >
                                        <i className="fa fa-hand-point-right fa-fw" />
                                        {languageTitles.dashboardCompanyStartButton}
                                    </Button>
                                </strong>
                            </Alert>
                        </div>
                    </div>
                    {companies.length > 0 && companies.length > 1
                    && (
                        <div className="row gallery-company">
                            {companies?.map((item) => (
                                <div className="col-xl-3 col-sm-6" key={item.CompanyId}>
                                    <Card className="border-0">
                                        <CardImg
                                            style={{ cursor: 'pointer' }}
                                            className="company-image"
                                            top
                                            src={item.ImageUrl ?? '/assets/img/logo.png'}
                                            alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = '/assets/img/logo.png';
                                            }}
                                            onClick={() => {
                                                setCompanyToEdit(item);
                                                setCompany(item.CompanyId);
                                                history.push(`/company/${item.CompanyId}`);
                                            }}
                                        />
                                        <CardBody>
                                            <CardTitle tag="h4" className="mt-0 mb-10px text-center">{item.CompanyName}</CardTitle>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    )}
                    { 1 === 2 && (
                        <div className="col-xl-12 col-lg-12">
                            <div className="panel panel-inverse">
                                <div className="panel-heading">
                                    <h4 className="panel-title">Timeline</h4>
                                </div>
                                <div className="panel-body">
                                    <div className="scrollbar-container h-300px ps ps--active-y">
                                        <div className="d-flex">
                                            {/* Bounding box for the Timeline */}
                                            <div style={{ width: '100%', height: '100px', margin: '0 auto' }}>
                                                <HorizontalTimeline
                                                    index={0}
                                                    styles={
                                                        {
                                                            background: '#fff',
                                                            foreground: '#ffd900',
                                                            outline: '#cccccc',
                                                        }
                                                    }
                                                    indexClick={() => {
                                                    // this.setState({ value: index, previous: this.state.value });
                                                    }}
                                                    values={VALUES}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </PerfectScrollbar>
                {isCompany
                && (
                    <div id="footer" className="app-footer m-0">
                        <div className="col-xl-4 col-xs-12">
                            <img style={{ width: '100%' }} src="/assets/img/stephani-info.jpeg" className="" alt="Travailleur Sans Frontiere" />
                        </div>
                    </div>
                )}
            </div>
        );
    }
    if (!isAdmin && !isCompany) {
        return (
            <div className="row">
                <div className="col-xl-6 col-lg-6">
                    <div className="widget-chart inverse-mode overrideSidebarChart">
                        <div className="widget-chart-sidebar bg-gray-900">
                            <div className="chart-number">
                                {records?.find((c) => c.Key === 1).Total}
                                <small>{languageTitles.dashboardProfileChartComplete}</small>
                            </div>
                            <div className="flex-grow-1 d-flex align-items-center dark-mode m-auto">
                                <NVD3Chart tooltip={{ hideDelay: 0 }} type="pieChart" datum={donutChartDataPercentage} height={300} options={donutChartOptions} x="label" y="value" />
                            </div>
                            <ul
                                className="chart-legend fs-11px"
                            >
                                <li key={donutChartDataPercentage[0]?.label}>
                                    <i className="fa fa-circle fa-fw text-blue fs-9px me-5px t-minus-1" />
                                    {`${donutChartDataPercentage[0]?.percentage}`}
                                    <span>{donutChartDataPercentage[0]?.label}</span>
                                </li>
                                <li key={donutChartDataPercentage[1]?.label}>
                                    <i className="fa fa-circle fa-fw text-blue fs-9px me-5px t-minus-1" />
                                    {`${donutChartDataPercentage[1]?.percentage}`}
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setPendingPoint(dataPercentage.PendingPoints)}
                                    >
                                        {donutChartDataPercentage[1]?.label}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                    {getPsychometricTest()}
                </div>

                {pendingPointSelected && (
                    <Modal
                        isOpen
                        toggle={() => setPendingPoint()}
                    >
                        <ModalHeader toggle={() => setPendingPoint()}>
                            {languageTitles.dashboardProfileChartPendingPoints}
                        </ModalHeader>
                        <ModalBody>
                            {
                                pendingPointSelected?.split(',')?.map((item, i) => (
                                    <div key={i}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {item}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Link to={`/applicant/${loggedUser.EntityId}`}>
                                <TSFButton
                                    action="custom"
                                    btnColor="primary"
                                    useIcon={false}
                                    label="Visitar perfil"
                                    onClick={() => null}
                                />

                            </Link>
                        </ModalFooter>
                    </Modal>
                )}
            </div>
        );
    }

    if (isAdmin && !isCompany) {
        return (
            <>
                <div className="row">
                    <div className="col-xl-3 col-md-6">
                        <WidgetStatsV2
                            bgColor="bg-warning"
                            title={languageTitles.dashboardTotalUpdates}
                            total={records?.find((c) => c.Key === 2).Total}
                            labelBottom=""
                            link="/applicants/page/1/show/10?st=lastupt"
                            labelLink=""
                            icon="fa-link"
                            iconLarge="fa-link"
                        />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <WidgetStatsV2
                            bgColor="bg-indigo"
                            title={languageTitles.dashboardTotal}
                            total={totalApplicants}
                            labelBottom=""
                            link="/applicants/page/1/show/10"
                            labelLink=""
                            icon="fa-users"
                            iconLarge=""
                        />
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <WidgetStatsV2
                            bgColor="bg-dark"
                            title={languageTitles.dashboardTotalToday}
                            total={records?.find((c) => c.Key === 3).Total}
                            labelBottom=""
                            link="/applicants/page/1/show/10?st=today"
                            labelLink=""
                            icon="fa-clock"
                            iconLarge=""
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-8 col-lg-6">
                        <div className="widget-chart with-sidebar inverse-mode">
                            <div className="widget-chart-content bg-gray-800">
                                <h4 className="chart-title">
                                    {languageTitles.dashboardGraphTitle}
                                </h4>
                                <div className="widget-chart-full-width dark-mode">
                                    <NVD3Chart tooltip={{ hideDelay: 0 }} type="stackedAreaChart" datum={areaChartData} height={260} options={areaChartOptions} />
                                </div>
                            </div>
                            <div className="widget-chart-sidebar bg-gray-900">
                                <div className="chart-number">
                                    {records?.find((c) => c.Key === 1).Total}
                                    <small>{languageTitles.dashboardGrahpBarGenderTitle}</small>
                                </div>
                                <div className="flex-grow-1 d-flex align-items-center dark-mode">
                                    <NVD3Chart tooltip={{ hideDelay: 0 }} type="pieChart" datum={donutChartData} height={180} options={donutChartOptions} x="label" y="value" />
                                </div>
                                <ul className="chart-legend fs-11px">
                                    {donutChartData?.map((c) => (
                                        <li key={c.label}>
                                            <i className="fa fa-circle fa-fw text-blue fs-9px me-5px t-minus-1" />
                                            {' '}
                                            {c.percentage.toFixed(2)}
                                            %
                                            {' '}
                                            <span>{c.label}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-6">
                            {getPsychometricTest()}
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <Panel>
                            <PanelHeader noButton showBackHistory={false}>
                                <div className="d-flex">
                                    {languageTitles.dashboardLastLoginTitle}
                                </div>
                            </PanelHeader>
                            <ul className="registered-users-list clearfix">
                                {lastUserLogged?.map((item, i) => (
                                    <li key={i}>
                                        <Link to={`/applicant/${item.ApplicantId}`}>
                                            <img
                                                src={item.PictureUrl ?? '../assets/img/user/user-13.jpg'}
                                                alt=""
                                                className=""
                                            />
                                        </Link>
                                        <h4 className="username text-ellipsis">
                                            {item.FullName}
                                            <small>{item.CountryName}</small>
                                            <small>{moment(item.LastDate).fromNow()}</small>
                                        </h4>
                                    </li>
                                ))}
                            </ul>
                        </Panel>

                    </div>
                </div>
            </>
        );
    }

    return null;
}

export default DashboardV2;
