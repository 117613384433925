/* eslint-disable react/no-children-prop */
import React, { useContext, useState } from 'react';
import { Route } from 'react-router-dom';
import SidebarNavList from './sidebar-nav-list';
import AppContext from '../../../config/appContext';
import useMenu from './menu';

function SidebarNav() {
    const {
        hasPermission,
    } = useContext(AppContext);

    const { menus } = useMenu();

    const [state, setState] = useState({
        active: -1,
        clicked: -1,
    });

    const handleExpand = (e, i, match) => {
        e.preventDefault();

        if (state.clicked === -1 && match) {
            setState({
                active: -1,
                clicked: 1,
            });
        } else {
            setState({
                active: (state.active === i ? -1 : i),
                clicked: 1,
            });
        }
    };

    const newArr2 = menus?.filter((exp) => hasPermission(exp.access) || exp.access === '' || exp.access === true);

    return (
        <div className="menu">
            {newArr2?.map((menu, i) => (
                <Route
                    path={menu.path}
                    exact={menu.exact ?? true}
                    key={menu.id}
                    children={({ match }) => (
                        <SidebarNavList
                            data={menu}
                            key={menu.id}
                            expand={(e) => handleExpand(e, i, match)}
                            active={i === state.active}
                            clicked={state.clicked}
                        />
                    )}
                />
            ))}
        </div>
    );
}

export default SidebarNav;
