/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Button } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import UserGraphql from '../../../servicesapollo/userGraphql';

import ToastApp from '../../toast/toast';
import HeaderTitle from './headerTitle';
import FooterCopyRight from './footerCopyRight';
import AppContext from '../../../config/appContext';
import useMessageHelper from '../../../helpers/messageHelper';

function ForgotPassword() {
    const history = useHistory();
    const { showNotification, languageTitles } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    const [currentState, setCurrentState] = useState({
        sweetAlertSuccess: false,
        email: '',
    });

    const {
        email, sweetAlertSuccess,
    } = currentState;

    const handleInputChange = (e) => {
        const { target: { name, value } } = e;
        setCurrentState({
            ...currentState,
            [name]: value,
        });
    };

    const [resetPassword, { loading: isSaving }] = useLazyQuery(UserGraphql.FORGOT_PASSWORD, {
        onCompleted: (mutationData) => {
            if (mutationData?.forgotPassword) {
                const {
                    success, message, error,
                } = mutationData?.forgotPassword;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(error?.length > 0 ? error : languageTitles.errorWhenRequestPassword, NotificationType.DANGER);
                    return;
                }

                setCurrentState({
                    ...currentState,
                    sweetAlertSuccess: true,
                });
            }
        },
        onError: (serverError) => {
            showNotification(getErrorMessage(serverError), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onSave = (e) => {
        e.preventDefault();

        if (isSaving) return;

        if (email === '') return;

        resetPassword({
            variables: {
                emx: email,
            },
        });
    };

    return (
        <>
            <div className="login login-v2 fw-bold">
                <div className="login-cover">
                    <div className="login-cover-img" style={{ backgroundImage: 'url(/assets/img/login-bg/quebec_city.jpg)' }} />
                    <div className="login-cover-bg" />
                </div>

                <div className="login-container">
                    <HeaderTitle />

                    <div className="login-content">
                        <form onSubmit={onSave}>
                            <div className="form-floating mb-20px">
                                <input
                                    type="text"
                                    className="form-control fs-13px h-45px border-0"
                                    name="email"
                                    id="emailAddress"
                                    value={email}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="emailAddress" className="d-flex align-items-center text-gray-600 fs-13px">{languageTitles.loginEmail}</label>
                            </div>
                            <div className="mb-20px">
                                <Button
                                    color="yellow"
                                    className="d-block w-100 h-45px btn-lg"
                                    disabled={isSaving}
                                    type="submit"
                                >
                                    {isSaving && <i className="fa fa-spinner fa-spin fa-fw" />}
                                    {isSaving ? languageTitles.forgotPasswordInProcessBtn : languageTitles.forgotPasswordBtn}
                                </Button>
                            </div>
                            <div className="text-grey-darker mb-20px">
                                {languageTitles.forgotPasswordBackLoginLabel}
                                {' '}
                                <Link to="/login">
                                    {languageTitles.forgotPasswordBackLoginLinkLabel}
                                    .
                                </Link>
                            </div>
                            <FooterCopyRight />
                        </form>
                    </div>
                </div>
                <ToastApp />
            </div>
            {(sweetAlertSuccess
            && (
                <SweetAlert
                    success
                    confirmBtnText={languageTitles.sweetAlertConfirmBtnText}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    title={languageTitles.resetPasswordSweetTitle}
                    onConfirm={() => history.push('/login')}
                >
                    {languageTitles.forgetPasswordMessageToResetPassword}
                </SweetAlert>
            )
            )}
        </>
    );
}

export default ForgotPassword;
