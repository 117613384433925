/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
    Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import AppContext from '../../../config/appContext';
import CompanyGraphql from '../../../servicesapollo/companyGraphql';
import { FetchPolicy, NotificationType } from '../../../enums/general';

import UploadFile from '../uploadFile/uploadFile';
import TSFButton from '../../actionButtons/tsfButton';
import ErrorPage from '../extraPages/error';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...state,
            record: action.value,
        };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        const { value, isDirty } = action;
        return { ...state, record: value, isDirty };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

function Company(props) {
    const { showNotification, languageTitles, loggedUser } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const { onClosePopup, companyId, isOpened } = props;

    if (loggedUser?.UserType?.toLowerCase() === 'user' && !hasPermission(Access.COMPANY_WRITE)) return <ErrorPage />;

    const initState = {
        isDirty: false,
        isUploading: false,
        errorMessage: '',
        record: {
            CompanyId: 0,
            CompanyName: '',
            ImageUrl: '',
            CoordinatesPhone: '',
            MainAddressStreet: '',
            ContactName: '',
            CoordinatesWebsiteLink: '',
        },
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        record, errorMessage, isUploading,
    } = state;

    const handleInputChange = (e) => {
        const { target } = e;
        const { value } = target;

        record[target.name] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: record,
            isDirty: true,
        });
    };

    const [getRecord] = useLazyQuery(CompanyGraphql.GET_COMPANY, {
        onCompleted: (res) => {
            if (res.company) {
                const {
                    data, success, message, error,
                } = res.company;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: data,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const mutationQuery = record.CompanyId > 0 ? CompanyGraphql.UPDATE_COMPANY : CompanyGraphql.CREATE_COMPANY;
    const [saveCompany, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (mutationData) => {
            if (mutationData?.addCompany || mutationData?.updateCompany) {
                const {
                    success, message, error,
                } = mutationData?.addCompany || mutationData?.updateCompany;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS);

                onClosePopup(true);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSave = () => {
        saveCompany({
            variables: {
                record,
            },
        });
    };

    useEffect(() => {
        if (companyId > 0) {
            getRecord({
                variables: {
                    id: companyId,
                },
            });
        }
    }, [companyId]);

    return (
        <Modal isOpen={isOpened} toggle={onClosePopup}>
            <ModalHeader toggle={() => onClosePopup} close={<button type="button" className="btn-close" onClick={onClosePopup} />}>
                {companyId === 0
                    ? languageTitles.companyPopupAddTitle
                    : languageTitles.companyPopupEditTitle}
                {' '}
            </ModalHeader>
            <ModalBody>
                <form onSubmit={onSave}>
                    <fieldset>
                        <div className={`row mb-3 ${record.CompanyId === 0 ? 'hide' : ''}`}>
                            <label className="form-label col-form-label col-md-3">{languageTitles.companyImage}</label>
                            <div className="col-md-9">
                                <img className="thumbnailimage" alt="" src={record.ImageUrl} />
                                <UploadFile
                                    additionalInfo={{ companyId: record.CompanyId }}
                                    graphql={CompanyGraphql.UPLOAD_COMPANY_PICTURE}
                                    resultNode="uploadPictureCompany"
                                    acceptFiles=".jpg,.jpeg,.png"
                                    uploading={(val) => {
                                        dispatch({
                                            type: ACTION_TYPES.SET_STATE_VALUES,
                                            value: {
                                                isUploading: val,
                                            },
                                        });
                                    }}
                                    afterUpload={(val) => {
                                        dispatch({
                                            type: ACTION_TYPES.SET_RECORD,
                                            value: {
                                                ...record,
                                                ImageUrl: val?.data?.FileUrl,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-3">{languageTitles.companyName}</label>
                            <div className="col-md-9">
                                <input type="text" className="form-control m-b-5" name="CompanyName" value={record.CompanyName} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-3">{languageTitles.coordinatesPhone}</label>
                            <div className="col-md-9">
                                <input type="text" className="form-control m-b-5" name="CoordinatesPhone" value={record.CoordinatesPhone} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-3">{languageTitles.mainAddressStreet}</label>
                            <div className="col-md-9">
                                <textarea
                                    rows="2"
                                    className="form-control m-b-5"
                                    name="MainAddressStreet"
                                    value={record.MainAddressStreet}
                                    onChange={handleInputChange}
                                    placeholder={languageTitles.mainAddressStreetPlaceHolder}
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-3">{languageTitles.companyContactName}</label>
                            <div className="col-md-9">
                                <input type="text" className="form-control m-b-5" name="ContactName" value={record.ContactName} onChange={handleInputChange} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-3">{languageTitles.coordinatesWebsiteLink}</label>
                            <div className="col-md-9">
                                <input type="text" className="form-control m-b-5" name="CoordinatesWebsiteLink" value={record.CoordinatesWebsiteLink} onChange={handleInputChange} />
                            </div>
                        </div>

                        {errorMessage
                            ? <div className="text-danger text-center p-t-10">{errorMessage}</div> : ''}
                    </fieldset>
                </form>
            </ModalBody>
            <ModalFooter>
                <TSFButton
                    action="cancel"
                    onClick={onClosePopup}
                />
                <TSFButton
                    action="save"
                    isWorking={isSaving}
                    isDisabled={isSaving || isUploading}
                    onClick={onSave}
                />
            </ModalFooter>
        </Modal>
    );
}

Company.propTypes = {
    onClosePopup: PropTypes.func.isRequired,
    companyId: PropTypes.number,
    isOpened: PropTypes.bool.isRequired,
};

Company.defaultProps = {
    companyId: 0,
};

export default Company;
