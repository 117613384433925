import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import AppContext from '../../config/appContext';

function Paginator(props) {
    const { languageTitles } = useContext(AppContext);
    const {
        canPreviousPage, canNextPage, loading, setPage,
        page, pages,
    } = props;

    return (
        <div className="d-flex me-2">
            <Button
                color="white"
                title={languageTitles.pageFirst}
                className={`btn-md me-1 ${!canPreviousPage || loading ? 'disabled' : 'active'}`}
                disabled={!canPreviousPage || loading}
                onClick={() => setPage('first')}
            >
                <i className="fa fa-angle-double-left" />
            </Button>
            <Button
                color="white"
                title={languageTitles.pagePrevious}
                className={`btn-md me-1 ${!canPreviousPage || loading ? 'disabled' : 'active'}`}
                disabled={!canPreviousPage || loading}
                onClick={() => setPage('previous')}
            >
                <i className="fa fa-angle-left" />
            </Button>
            <div style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100px',
                alignSelf: 'center',
            }}
            >
                {languageTitles.pageLabel}
                {' '}
                {page}
                {' '}
                {languageTitles.pageSeparator}
                {' '}
                {pages}
            </div>
            <Button
                color="white"
                title={languageTitles.pageNext}
                className={`btn-md me-1 ${!canNextPage || loading ? 'disabled' : 'active'}`}
                disabled={!canNextPage || loading}
                onClick={() => setPage('next')}
            >
                <i className="fa fa-angle-right" />
            </Button>
            <Button
                color="white"
                title={languageTitles.pageLast}
                className={`btn-md ${!canNextPage || loading ? 'disabled' : 'active'}`}
                disabled={!canNextPage || loading}
                onClick={() => setPage('last')}
            >
                <i className="fa fa-angle-double-right" />
            </Button>
        </div>
    );
}

Paginator.propTypes = {
    canPreviousPage: PropTypes.bool.isRequired,
    canNextPage: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
};

export default Paginator;
