import { useContext } from 'react';
import AppContext from '../../../config/appContext';
import Access from '../../../enums/permissions';
import useCustomLocalStorage from '../../hooks/useCustomLocalStorage';

const useMenu = () => {
    const { loggedUser, hasPermission } = useContext(AppContext);
    const { getCompany } = useCustomLocalStorage();
    const companySetId = loggedUser.UserType === 'companyadmin' ? (getCompany() ?? loggedUser.EntityId) : loggedUser.EntityId;

    const hasPermissionToCompany = hasPermission(Access.COMPANY_READ_LIST);

    const menuApplicant = [
        {
            id: 1,
            path: '/home',
            icon: 'fa fa-home',
            title: 'menuHome',
            access: '',
        },
        {
            id: 2,
            path: '/applicants/page/1/show/10',
            icon: 'fa fa-address-card',
            title: 'menuApplicants',
            access: Access.APPLICANT_READ_LIST,
        },
        {
            id: 3,
            path: `/applicant/${loggedUser.EntityId ?? 0}`,
            icon: 'fa fa-edit',
            title: `${loggedUser.EntityId > 0 ? 'menuEditCV' : 'menuAddCV'}`,
            access: '',
        },
        {
            id: 4,
            path: '/administrarfases',
            icon: 'fa fa-th',
            title: 'menuAdminFases',
            access: Access.COMPANY_USER_MANAGE,
        },
        {
            id: 5,
            path: '/companies',
            icon: 'fa fa-building',
            title: 'menuCompanies',
            access: Access.COMPANY_READ_LIST,
        },
        {
            id: 6,
            path: '/users/page/1/show/15',
            icon: 'fa fa-users',
            title: 'menuUsers',
            access: Access.USER_LIST,
        },
        {
            id: 7,
            path: '/emailstemplate',
            icon: 'fa fa-at',
            title: 'menuEmailTemplates',
            access: Access.EMAIL_TEMPLATE_WRITE,
        },
        {
            id: 8,
            path: '/jobspositions',
            icon: 'fa fa-clipboard',
            title: 'menuJobs',
            access: '',
        },
        {
            id: 9,
            path: '/pruebas',
            icon: 'fa fa-list',
            title: 'menuPyscoTest',
            access: Access.TEST_READ_LIST,
        },
        // {
        //     id: 10,
        //     path: '/documents',
        //     icon: 'fa fa-file-pdf',
        //     title: 'menuDocumentShared',
        //     access: '',
        // },
        // {
        //     id: 11,
        //     path: '/manage-documents',
        //     icon: 'fa fa-file-import',
        //     title: 'menuManageDocuments',
        //     access: Access.APPLICANT_MANAGE_DOCUMENTS,
        // },
        {
            id: 12,
            path: '/permissions',
            icon: 'fa fa-lock-open',
            title: 'menuAccess',
            access: Access.PERMISSION_ASSIGN,
        },
        // {
        //     id: 13,
        //     path: '/emails/page/1/show/20',
        //     icon: 'fa fa-at',
        //     title: 'menuEmails',
        //     access: '',
        // },
    ];

    const menuCompany = [
        {
            id: 1,
            path: '/home',
            icon: 'fa fa-home',
            title: 'menuHome',
            access: '',
        },
        {
            id: 7,
            path: '/companies',
            icon: 'fa fa-building',
            title: 'menuCompanies',
            access: loggedUser.UserType === 'companyadmin' || (hasPermissionToCompany && loggedUser.UserType === 'superadmin') || (hasPermissionToCompany && loggedUser.UserType === 'admin'),
        },
        {
            id: 2,
            path: `/company/${companySetId ?? 0}`,
            icon: 'fa fa-edit',
            title: 'menuEditCompany',
            access: loggedUser.UserType === 'companyadmin' || (loggedUser.Companies?.length > 0 && loggedUser.EntityId > 0),
        },
        {
            id: 3,
            path: `/jobpositions/company/${companySetId ?? 0}`,
            icon: 'fa fa-edit',
            title: 'menuEditCompanyJobPositions',
            access: loggedUser.UserType === 'companyadmin' || (loggedUser.Companies?.length > 0 && loggedUser.EntityId > 0),
        },
        {
            id: 4,
            path: `/employees/company/${companySetId ?? 0}`,
            icon: 'fa fa-users',
            title: 'menuEditCompanyEmployees',
            access: loggedUser.UserType === 'companyadmin' || (loggedUser.Companies?.length > 0 && loggedUser.EntityId > 0),
        },
        {
            id: 5,
            path: `/files/company/${companySetId ?? 0}`,
            icon: 'fa fa-file',
            title: 'menuEditCompanyFiles',
            access: loggedUser.UserType === 'companyadmin' || (loggedUser.Companies?.length > 0 && loggedUser.EntityId > 0),
        },
        // {
        //     id: 6,
        //     path: '/emails/page/1/show/20',
        //     icon: 'fa fa-at',
        //     title: 'menuEmails',
        //     access: '',
        // },
    ];

    const menus = loggedUser.IsCompanyUser ? menuCompany : menuApplicant;

    return {
        menus,
    };
};

export default useMenu;
