import React, { useContext, useEffect, useReducer } from 'react';
import {
    Modal, ModalHeader, ModalBody, Button, Card, CardImg, CardBody, CardTitle, CardText, ButtonGroup,
} from 'reactstrap';
import { useLazyQuery } from '@apollo/client';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppContext from '../../../config/appContext';
import { Panel, PanelHeader, PanelBody } from '../../panel/panel';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import JobPositionGraphql from '../../../servicesapollo/jobPositionGraphql';

import TSFButton from '../../actionButtons/tsfButton';
import FullContent from '../../layout/content/fullContent';
import useMessageHelper from '../../../helpers/messageHelper';
import toPascalCase from '../../../util/messages';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

function JobPositions() {
    const {
        showNotification,
        languageTitles,
        currentLanguage,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const initState = {
        records: [],
        total: 0,
        search: '',
        selectedItem: null,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        records, selectedItem, search,
    } = state;

    const [getData, { loading, refetch }] = useLazyQuery(JobPositionGraphql.GET_JOB_POSITION_LIST, {
        onCompleted: (res) => {
            if (res.jobPositions) {
                const {
                    data, total, success, message, error,
                } = res.jobPositions;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        records: data,
                        total,
                    },
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const callGetData = () => {
        getData({
            variables: {
                search,
                usePaging: false,
            },
        });
    };

    useEffect(() => {
        callGetData();
    }, []);

    const setSelectedJob = (item = null) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                selectedItem: item,
            },
        });
    };

    return (
        <FullContent>
            <Panel>
                <PanelHeader>
                    <ButtonGroup className="pull-right">
                        <TSFButton
                            action="refresh"
                            isWorking={loading}
                            onClick={() => refetch()}
                        />
                    </ButtonGroup>
                </PanelHeader>
                <PanelBody>
                    <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                        <div className="row">
                            {records?.map((item) => (
                                <div className="col-xl-3 col-sm-6" key={item.JobPositionId}>
                                    <Card className="border-0">
                                        <CardImg
                                            top
                                            src={item?.PictureUrl?.replace('lang', currentLanguage?.toLowerCase()) ?? '/assets/img/logo.png'}
                                            alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = '/assets/img/logo.png';
                                            }}
                                        />
                                        <CardBody>
                                            <CardTitle tag="h4" className="mt-0 mb-10px">
                                                {item[toPascalCase(currentLanguage)]}
                                                {' '}
                                                <Button
                                                    className="btn-success"
                                                    color="default"
                                                    size="sm"
                                                    onClick={() => setSelectedJob(item)}
                                                >
                                                    <i className="fa fa-sm fa-search" />
                                                </Button>

                                            </CardTitle>
                                            <CardText>
                                                {item.Description}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </PerfectScrollbar>
                </PanelBody>
            </Panel>

            <Modal
                isOpen={selectedItem !== null}
                toggle={() => setSelectedJob()}
            >
                <ModalHeader toggle={() => setSelectedJob()}>
                    {selectedItem?.Title ?? ''}
                </ModalHeader>
                <ModalBody>
                    <ul>
                        {
                            selectedItem?.JobPositionDetail?.map((detail) => <li key={detail.JobPositionDetailId}>{detail[toPascalCase(currentLanguage)]}</li>)
                        }
                    </ul>
                </ModalBody>
            </Modal>
        </FullContent>
    );
}

export default JobPositions;
