import { gql } from '@apollo/client';

export default class CoreGraphql {
    static GET_CORE_CATALOGS_LIST = gql`
    query coreCatalogs{
      coreCatalogs {
        Id
        Es
        Orden
        CatalogType
        Fr
        En
      }
      
  }`;
}
