/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useContext, useEffect, useReducer,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    Modal, ModalHeader, ModalBody, Button, Card, CardImg, CardBody, CardTitle, CardText,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { v4 as uuidv4 } from 'uuid';
import { Panel, PanelHeader, PanelBody } from '../../panel/panel';
import SearchField from '../../search-field/searchfield';
import Company from './company';
import CompanyGraphql from '../../../servicesapollo/companyGraphql';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import AppContext from '../../../config/appContext';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';
import ApplicantsMapping from '../../../mapping/applicantsMapping';

import './company.css';

import Access from '../../../enums/permissions';
import ErrorPage from '../extraPages/error';
import TSFButton from '../../actionButtons/tsfButton';
import FullContent from '../../layout/content/fullContent';
import DeleteAlert from '../../alert/deleteAlert';
import CompanyUser from './companyUser';
import UserPassword from '../user/userPassword';
import useMessageHelper from '../../../helpers/messageHelper';
import useCustomLocalStorage from '../../hooks/useCustomLocalStorage';
import { pdfOptions } from '../../../helpers/pdfHelper';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        if (action.fireAction) {
            action.fireAction(true);
        }

        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

function Companies() {
    const {
        currentLanguage, languageTitles, showNotification, hasPermission,
        loggedUser, converToPdf,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const {
        setCompany,
    } = useCustomLocalStorage();

    const history = useHistory();
    const hasPermissionToCompany = hasPermission(Access.COMPANY_READ_LIST);

    // eslint-disable-next-line max-len
    if (!(loggedUser.UserType === 'companyadmin' || (hasPermissionToCompany && loggedUser.UserType === 'superadmin') || (hasPermissionToCompany && loggedUser.UserType === 'admin'))) return <ErrorPage />;

    const initState = {
        records: [],
        total: 0,
        applicantsByCompany: [],
        filters: {
            keyword: '',
        },
        selectedItem: null,
        idToDelete: 0,
        companyPopupOpen: false,
        applicantsPopupOpen: false,
        addUserPopoupOpen: false,
        changePasswordPopoupOpen: false,
        // params
        search: '',
        usePaging: false,
        idToPrint: 0,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        records, idToDelete, applicantsPopupOpen, companyPopupOpen, selectedItem,
        applicantsByCompany, search, usePaging, addUserPopoupOpen, changePasswordPopoupOpen,
        idToPrint,
    } = state;

    const [getData, { loading, refetch }] = useLazyQuery(CompanyGraphql.GET_COMPANY_LIST, {
        onCompleted: (res) => {
            if (res.companies) {
                const {
                    data, total, success, message, error,
                } = res.companies;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        records: data,
                        total,
                    },
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [printCompanyFullReport, { loading: printingReport }] = useLazyQuery(CompanyGraphql.PRINT_COMPANY_FULL_REPORT, {
        onCompleted: (res) => {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    idToPrint: 0,
                },
            });

            if (res.printCompanyFullReport) {
                const pdfBase64 = res.printCompanyFullReport;
                const cname = records.find((c) => c.CompanyId === idToPrint);

                converToPdf(pdfBase64, `Company-${cname.CompanyName}-${uuidv4()}.pdf`);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const callGetData = (isClear = false) => {
        getData({
            variables: {
                search: isClear ? '' : search,
                usePaging,
            },
        });
    };

    const [getApplicants, { loading: loadingApplicants }] = useLazyQuery(ApplicantsGraphql.GET_APPLICANTS_LIST_BY_COMPANY, {
        onCompleted: (res) => {
            if (res.applicants) {
                const { applicants: { data } } = res;

                const dataMapped = ApplicantsMapping.mapApplicantsByCompany(data);

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        applicantsByCompany: dataMapped,
                    },
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [deleteCompany, { loading: deleting }] = useMutation(CompanyGraphql.DELETE_COMPANY, {
        onCompleted: (mutationData) => {
            if (mutationData.deleteCompany) {
                const { success, message, error } = mutationData.deleteCompany;
                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenDelete,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        idToDelete: 0,
                    },
                });

                showNotification(languageTitles.successWhenDelete, NotificationType.SUCCESS);

                callGetData();
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onDeleteCompany = async () => {
        await deleteCompany({
            variables: {
                id: idToDelete,
            },
        });
    };

    const printFullReport = (companyId) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                idToPrint: companyId,
            },
        });
        printCompanyFullReport({
            variables: {
                id: companyId,
                options: JSON.stringify(pdfOptions),
            },
        });
    };

    const tooglePopup = (type, success) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                companyPopupOpen: type === 'company' ? !companyPopupOpen : companyPopupOpen,
                applicantsPopupOpen: type === 'applicant' ? !applicantsPopupOpen : applicantsPopupOpen,
                selectedItem: type === 'company' && !companyPopupOpen ? null : selectedItem,
                addUserPopoupOpen: type === 'adduser' ? !addUserPopoupOpen : addUserPopoupOpen,
                changePasswordPopoupOpen: type === 'changepassword' ? !changePasswordPopoupOpen : changePasswordPopoupOpen,
            },
        });

        if (success) refetch();
    };

    const setCompanyToEdit = (companyId) => {
        setCompany(companyId);
        history.push(`/company/${companyId}`);
    };

    useEffect(() => {
        if (search) callGetData();
    }, [search]);

    useEffect(() => {
        callGetData();
    }, []);

    return (
        <FullContent>
            <Panel>
                <PanelHeader>
                    <div className="panel-heading-btn justify-content-end">
                        <div className="col-md-5 me-2">
                            <SearchField
                                returnSearchValue={(val) => dispatch({
                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                    value: {
                                        search: val,
                                    },
                                })}
                                returnOnClear={() => dispatch({
                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                    value: {
                                        search: '',
                                    },
                                    fireAction: callGetData,
                                })}
                            />
                        </div>
                        <div className="d-flex me-2">
                            {hasPermission(Access.COMPANY_WRITE) && (
                                <Button
                                    color="primary"
                                    className="me-1"
                                    onClick={() => tooglePopup('company')}
                                >
                                    <i className="fa fa-plus fa-fw" />
                                    {languageTitles.companyAddBtn}
                                </Button>
                            ) }
                            <TSFButton
                                action="refresh"
                                isWorking={loading}
                                onClick={() => refetch()}
                            />
                        </div>
                    </div>
                </PanelHeader>
                <PanelBody>
                    <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                        <div className="row gallery-company">
                            {records?.map((item) => (
                                <div className="col-xl-3 col-sm-6" key={item.CompanyId}>
                                    <Card className="border-0">
                                        <CardImg
                                            className="company-image"
                                            top
                                            src={item.ImageUrl ?? '/assets/img/logo.png'}
                                            alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = '/assets/img/logo.png';
                                            }}
                                        />
                                        <CardBody>
                                            <CardTitle tag="h4" className="mt-0 mb-10px">{item.CompanyName}</CardTitle>
                                            <CardText>
                                                {languageTitles.companyPhoneLabel}
                                                :
                                                {' '}
                                                {item.CoordinatesPhone}
                                                <br />
                                                {languageTitles.companyAddress}
                                                :
                                                {' '}
                                                {item.MainAddressStreet}
                                                <br />
                                                {languageTitles.associatedUser}
                                                :
                                                {' '}
                                                {item?.User?.Email}
                                            </CardText>
                                            <div className="text-start">
                                                {hasPermission(Access.COMPANY_ASSOCIATE_USER) && (
                                                    <Button
                                                        className="btn-success me-1 mb-1"
                                                        color="default"
                                                        title={languageTitles.createUser}
                                                        size="sm"
                                                        onClick={() => {
                                                            dispatch({
                                                                type: ACTION_TYPES.SET_STATE_VALUES,
                                                                value: {
                                                                    selectedItem: item,
                                                                    addUserPopoupOpen: true,
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        <i className="fa fa-md fa-user-lock" />
                                                    </Button>
                                                ) }
                                                {item.UserId && (hasPermission(Access.COMPANY_CHANGE_PASSWORD)) && (
                                                    <Button
                                                        className="btn-success me-1 mb-1"
                                                        color="default"
                                                        title={languageTitles.usersBtnChangePassword}
                                                        size="sm"
                                                        onClick={() => {
                                                            dispatch({
                                                                type: ACTION_TYPES.SET_STATE_VALUES,
                                                                value: {
                                                                    selectedItem: item,
                                                                    changePasswordPopoupOpen: true,
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        <i className="fa fa-md fa-key" />
                                                    </Button>
                                                ) }
                                                { hasPermission(Access.COMPANY_USER_LIST)
                                            && (
                                                <Button
                                                    className="btn-success me-1 mb-1"
                                                    color="default"
                                                    size="sm"
                                                    title={languageTitles.companyUserAssociatedBtnTitle}
                                                    onClick={() => {
                                                        dispatch({
                                                            type: ACTION_TYPES.SET_STATE_VALUES,
                                                            value: {
                                                                selectedItem: item,
                                                                applicantsPopupOpen: true,
                                                                applicantsByCompany: [],
                                                            },
                                                        });

                                                        getApplicants({
                                                            variables: {
                                                                usePaging: false,
                                                                filters: [{
                                                                    fieldName: 'CompanyId',
                                                                    value: String(item.CompanyId),
                                                                }],
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-md fa-users" />
                                                </Button>
                                            ) }
                                                { hasPermission(Access.COMPANY_WRITE)
                                            && (
                                                <Button
                                                    className="btn-success me-1 mb-1"
                                                    color="default"
                                                    title={languageTitles.companyEditBtnTitle}
                                                    size="sm"
                                                    onClick={() => {
                                                        dispatch({
                                                            type: ACTION_TYPES.SET_STATE_VALUES,
                                                            value: {
                                                                selectedItem: item,
                                                                companyPopupOpen: true,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-md fa-pen" />
                                                </Button>
                                            ) }
                                                { loggedUser.UserType === 'companyadmin'
                                            && (
                                                <Button
                                                    className="btn-success me-1 mb-1"
                                                    color="default"
                                                    title={languageTitles.companyEditBtnTitle}
                                                    size="sm"
                                                    onClick={() => setCompanyToEdit(item.CompanyId)}
                                                >
                                                    <i className="fa fa-md fa-eye" />
                                                </Button>
                                            ) }
                                                { loggedUser.UserType === 'companyadmin'
                                            && (
                                                <Button
                                                    className="btn-success me-1 mb-1"
                                                    color="default"
                                                    title={languageTitles.companyPrintFullReport}
                                                    size="sm"
                                                    onClick={() => printFullReport(item.CompanyId)}
                                                >
                                                    {printingReport && idToPrint === item.CompanyId
                                                        ? <i className="fa fa-spinner fa-spin" />
                                                        : <i className="fa fa-md fa-file-pdf" />}
                                                </Button>
                                            ) }
                                                { hasPermission(Access.COMPANY_DELETE)
                                            && (
                                                <Button
                                                    className="btn-danger me-1 mb-1"
                                                    color="default"
                                                    title={languageTitles.deleteTitle}
                                                    size="sm"
                                                    onClick={() => {
                                                        dispatch({
                                                            type: ACTION_TYPES.SET_STATE_VALUES,
                                                            value: {
                                                                idToDelete: item.CompanyId,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <i className="fa fa-md fa-trash" />
                                                </Button>
                                            ) }
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </PerfectScrollbar>
                </PanelBody>
            </Panel>
            {companyPopupOpen
            && (
                <Company
                    companyId={selectedItem?.CompanyId}
                    isOpened={companyPopupOpen}
                    onClosePopup={(success) => {
                        tooglePopup('company', success);
                    }}
                />
            )}
            {idToDelete > 0 && (
                <DeleteAlert
                    isDeleting={deleting}
                    onConfirm={onDeleteCompany}
                    onCancel={() => dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        value: { idToDelete: 0 },
                    })}
                />
            )}

            <Modal
                isOpen={applicantsPopupOpen}
                toggle={() => tooglePopup('applicant')}
            >
                <ModalHeader toggle={() => tooglePopup('applicant')}>
                    {selectedItem?.companyName ?? ''}
                </ModalHeader>
                <ModalBody>
                    {loadingApplicants && languageTitles.loadingRecords}
                    {!loadingApplicants && (
                        applicantsByCompany?.map((item) => (
                            <div key={item.ApplicantId}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Link to={`/applicant/${item.ApplicantId}`} className="">
                                            {`${item.Name} ${item.LastName}`}
                                        </Link>
                                    </div>
                                    <div className="col-md-6">
                                        {item[currentLanguage]}
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </ModalBody>
            </Modal>
            {addUserPopoupOpen
            && <CompanyUser companyId={selectedItem.CompanyId} onClosePopup={(success) => tooglePopup('adduser', success)} /> }
            {changePasswordPopoupOpen
            && <UserPassword userId={selectedItem.UserId} onClosePopup={(success) => tooglePopup('changepassword', success)} /> }
        </FullContent>
    );
}

export default Companies;
