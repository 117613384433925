/* eslint-disable object-curly-newline */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link, useHistory } from 'react-router-dom';
import MultiSelect from 'react-multi-select-component';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import UserGraphql from '../../../servicesapollo/userGraphql';

import { FetchPolicy, NotificationType } from '../../../enums/general';
import HeaderTitle from './headerTitle';
import FooterCopyRight from './footerCopyRight';
import AppContext from '../../../config/appContext';
import useMessageHelper from '../../../helpers/messageHelper';
import toPascalCase from '../../../util/messages';

const jobPositionsData = [
    { value: 1, Es: 'Chef / Cocineros', En: 'Chef/Cooks', Fr: 'Chef / Cuisinier' },
    { value: 2, Es: 'Electromecánica', En: 'Electromechanical', Fr: 'Électromécanicien' },
    { value: 3, Es: 'Mecánica Automotriz Liviano / Pesado', En: 'Automotive Mechanics Light / Heavy', Fr: 'Mécanique automobile lourde/légère' },
    { value: 4, Es: 'Mecánica de Precisión', En: 'Precision Mechanics', Fr: 'Mécanicien de précision/Machiniste' },
    { value: 5, Es: 'Mecánica Industrial', En: 'Industrial mechanics', Fr: 'Mécanicien industriel' },
    { value: 6, Es: 'Obreros Industriales', En: 'Industrial Workers', Fr: 'Ouvrier industriel' },
    { value: 7, Es: 'Operador de maquinas de coser', En: 'Sewing machine operator', Fr: 'Opérateur de machine à coudre' },
    { value: 8, Es: 'Pintor Automotriz / Industrial', En: 'Automotive/Industrial Painter', Fr: 'Peintre automobile/industriel' },
    { value: 9, Es: 'Soldadura', En: 'Welding', Fr: 'Soudeur' },
    { value: 10, Es: 'Asistente de Salud', En: 'Health assistant', Fr: 'Assistante de santé' },
];

function Register() {
    const history = useHistory();
    const { showNotification, languageTitles, currentLanguage } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    const [currentState, setCurrentState] = useState({
        sweetAlertSuccess: false,
        record: {
            Name: '',
            LastName: '',
            Email: '',
            Phone: '',
            Password: '',
            PasswordConfirm: '',
        },
        jobPositionSelected: [],
    });

    const {
        record, sweetAlertSuccess, jobPositionSelected,
    } = currentState;

    const handleInputChange = (e) => {
        const { target: { name, value } } = e;
        setCurrentState({
            ...currentState,
            record: {
                ...record,
                [name]: value,
            },
        });
    };

    const setMultipleSelected = (selectedOption) => {
        setCurrentState({
            ...currentState,
            jobPositionSelected: selectedOption,
        });
    };

    const [registerUser, { loading: isSaving }] = useMutation(UserGraphql.REGISTER_USER, {
        onCompleted: (mutationData) => {
            if (mutationData?.registerUser) {
                const {
                    success, message, error,
                } = mutationData?.registerUser;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(error?.length > 0 ? error : languageTitles.errorWhenRegisterUser, NotificationType.DANGER);
                    return;
                }

                setCurrentState({
                    ...currentState,
                    sweetAlertSuccess: true,
                });
            }
        },
        onError: (serverError) => {
            showNotification(getErrorMessage(serverError), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onSave = (e) => {
        e.preventDefault();

        if (isSaving) return;

        if (record.password !== record.passwordConfirm) {
            showNotification(languageTitles.userPasswordDontMatch, NotificationType.DANGER);
            return;
        }

        if (jobPositionSelected.length === 0) {
            showNotification(languageTitles.validatePositionSelection, NotificationType.DANGER);
            return;
        }

        const user = {
            Name: record.Name,
            LastName: record.LastName,
            Email: record.Email,
            Phone: record.Phone,
            Password: record.Password,
            JobPositionIds: jobPositionSelected.map((c) => c.value),
        };

        registerUser({
            variables: {
                record: user,
            },
        });
    };

    const jobPositions = jobPositionsData.map((c) => ({
        value: c.value,
        label: c[toPascalCase(currentLanguage)],
    }));

    // const jobPositionSelected = jobPositions?.filter((c) => jobPositionIds?.includes(c.value));

    return (
        <>
            <div className="login login-v2 fw-bold">
                <div className="login-cover">
                    <div className="login-cover-img" style={{ backgroundImage: 'url(/assets/img/login-bg/quebec-night.jpg)' }} />
                    <div className="login-cover-bg" />
                </div>

                <div className="login-container">
                    <HeaderTitle />

                    <div className="login-content">
                        <form onSubmit={onSave}>
                            <div className="form-floating mb-20px">
                                <input
                                    type="text"
                                    className="form-control fs-13px h-45px border-0"
                                    name="Name"
                                    id="name"
                                    value={record.Name}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="name" className="d-flex align-items-center text-gray-600 fs-13px">{languageTitles.registerName}</label>
                            </div>
                            <div className="form-floating mb-20px">
                                <input
                                    type="text"
                                    className="form-control fs-13px h-45px border-0"
                                    name="LastName"
                                    id="lastname"
                                    value={record.LastName}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="lastname" className="d-flex align-items-center text-gray-600 fs-13px">{languageTitles.registerLastName}</label>
                            </div>
                            <div className="form-floating mb-20px">
                                <input
                                    type="email"
                                    className="form-control fs-13px h-45px border-0"
                                    name="Email"
                                    id="email"
                                    value={record.Email}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="email" className="d-flex align-items-center text-gray-600 fs-13px">{languageTitles.loginEmail}</label>
                            </div>
                            <div className="form-floating mb-20px">
                                <input
                                    type="text"
                                    className="form-control fs-13px h-45px border-0"
                                    name="Phone"
                                    id="phone"
                                    value={record.Phone}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="phone" className="d-flex align-items-center text-gray-600 fs-13px">{languageTitles.registerPhone}</label>
                            </div>
                            <div className="form-floating mb-20px">
                                <MultiSelect
                                    hasSelectAll={false}
                                    options={jobPositions}
                                    value={jobPositionSelected}
                                    onChange={setMultipleSelected}
                                    labelledBy={languageTitles.registerJobApply}
                                    required
                                    className="selectCustomClass"
                                />
                            </div>
                            <div className="form-floating mb-20px">
                                <input
                                    type="password"
                                    className="form-control fs-13px h-45px border-0"
                                    name="Password"
                                    id="password"
                                    value={record.Password}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="password" className="d-flex align-items-center text-gray-600 fs-13px">{languageTitles.loginPassword}</label>
                            </div>
                            <div className="form-floating mb-20px">
                                <input
                                    type="password"
                                    className="form-control fs-13px h-45px border-0"
                                    name="PasswordConfirm"
                                    id="passwordConfirm"
                                    value={record.PasswordConfirm}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label htmlFor="passwordConfirm" className="d-flex align-items-center text-gray-600 fs-13px">{languageTitles.registerConfirmPassword}</label>
                            </div>
                            <div className="mb-20px">
                                <Button
                                    type="submit"
                                    color="yellow"
                                    className="d-block w-100 h-45px btn-lg"
                                    disabled={isSaving}
                                >
                                    {
                                        isSaving
                                            ? <i className="fa fa-spinner fa-spin fa-fw" /> : ''
                                    }
                                    {
                                        isSaving
                                            ? languageTitles.registerInProcessLabel : languageTitles.registerBtnLabel
                                    }
                                </Button>
                            </div>
                            <div className="text-grey-darker mb-20px">
                                {languageTitles.registerAlreadyHaveUser}
                                <Link to="/login">{languageTitles.loginForgotPasswordLinkLabel}</Link>
                            </div>
                            <FooterCopyRight />
                        </form>
                    </div>
                </div>
            </div>

            {(sweetAlertSuccess && (
                <SweetAlert
                    success
                    confirmBtnText="Continuar"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    title="Registro exitoso"
                    onConfirm={() => history.push('/login')}
                >
                    {languageTitles.registerWelcomeMessage}
                </SweetAlert>
            )
            )}
        </>
    );
}

export default Register;
