/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppContext from '../../../config/appContext';

function SidebarNavList(props) {
    const {
        handleAppSidebarOnMouseOver, handleAppSidebarOnMouseOut, appSidebarMinified, languageTitles, loggedUser,
        isDirtyData, setIsDirtyData,
    } = useContext(AppContext);
    const history = useHistory();

    const {
        data, expand, active, clicked,
    } = props;

    const [state, setState] = useState({
        active: -1,
        clicked: -1,
    });

    const handleExpand = (e, i) => {
        e.preventDefault();

        setState({
            active: (state.active === i ? -1 : i),
            clicked: 1,
        });
    };

    const icon = data.icon && <div className="menu-icon"><i className={data.icon} /></div>;
    const img = data.img && <div className="menu-icon-img"><img src={data.img} alt="" /></div>;
    const caret = (data.children && !data.badge) && <div className="menu-caret" />;
    const label = data.label && <span className="menu-label ms-5px">{data.label}</span>;
    const badge = data.badge && <div className="menu-badge">{data.badge}</div>;
    const highlight = data.highlight && <i className="fa fa-paper-plane text-theme" />;

    let { title } = data;
    const linkTo = title === 'menuTemplateCV' ? `${window.location.origin}/${data.path}` : data.path;
    title = (title === 'menuAddCV' || title === 'menuEditCV') ? (loggedUser?.EntityId > 0 ? 'menuEditCV' : 'menuAddCV') : title;

    title = languageTitles[title] && (
        <div className="menu-text">
            {languageTitles[title]}
            {' '}
            {label}
            {' '}
            {highlight}
        </div>
    );

    const linkToUrl = (url) => {
        if (isDirtyData) {
            if (window.confirm(languageTitles.exitWithoutSave) === true) {
                setIsDirtyData(false);
                history.push(url);
            }
            return;
        }
        history.push(url);
    };

    return (
        <Route
            path={data.path}
            exact={data.exact}
            children={({ match }) => (
                <div className={`menu-item ${match ? 'active ' : ''}
                ${(active || (clicked === -1 && match) || data.search) ? 'expand ' : 'closed '}
                ${data.children ? 'has-sub ' : ''}`}
                >
                    {data.children ? (
                        <span
                            className="menu-link"
                            onFocus={(e) => handleAppSidebarOnMouseOver(e, data)}
                            onBlur={(e) => handleAppSidebarOnMouseOut(e, data)}
                            onClick={expand}
                        >
                            { img }
                            {' '}
                            { icon }
                            {' '}
                            { title }
                            { caret }
                            {' '}
                            { badge }
                        </span>
                    ) : (
                        <span
                            className="menu-link"
                            onClick={() => linkToUrl(linkTo)}
                        >
                            { img }
                            {' '}
                            { icon }
                            {' '}
                            { badge }
                            {' '}
                            { title }
                            { caret }
                            {' '}
                        </span>
                    )}
                    {data.children && (
                        <div className={`menu-submenu ${((active || (clicked === -1 && match) || data.search) && !appSidebarMinified) ? 'd-block ' : 'd-none'}`}>
                            {data.children && data.children.map((submenu, i) => (
                                <SidebarNavList
                                    data={submenu}
                                    key={submenu.id}
                                    expand={(e) => handleExpand(e, i, match)}
                                    active={i === state.active}
                                    clicked={state.clicked}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
        />
    );
}

SidebarNavList.propTypes = {
    expand: PropTypes.func.isRequired,
    clicked: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
};

export default SidebarNavList;
