/* eslint-disable react/function-component-definition */
import { useMutation, useSubscription } from '@apollo/client';
import moment from 'moment';
import React, {
    useContext, useState,
} from 'react';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import AppContext from '../../../../config/appContext';
import NotificationGraphql from '../../../../servicesapollo/notificationGraphql';

import './header.css';
import EmailTemplateGraphql from '../../../../servicesapollo/emailTemplateGraphql';
import { SubscriptionType } from '../../../../enums/general';
import UserGraphql from '../../../../servicesapollo/userGraphql';
import EmailMessageGraphql from '../../../../servicesapollo/emailMessagesGraphql';
import useNotification from '../../../hooks/useNotification';

const DropdownNotification = () => {
    const {
        languageTitles, loggedUser, notifications, refreshNotificationByUser,
    } = useContext(AppContext);
    const [dropdownOpen, toggle] = useState(false);
    const [notification, setNotification] = useState(null);

    const [readNotification] = useMutation(NotificationGraphql.READ_NOTIFICATION);
    const { showExternalNotification } = useNotification();

    useSubscription(NotificationGraphql.NOTIFICATION_CHANGED, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { success } = subscriptionData?.data?.notificationChanged;
            if (success) {
                showExternalNotification();
                refreshNotificationByUser();
            }
        },
    });

    useSubscription(EmailTemplateGraphql.EMAILTEMPLATE_CHANGED, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { success, action } = subscriptionData?.data?.emailTemplatesChanged;
            if (success && action === SubscriptionType.USED) {
                showExternalNotification();
                refreshNotificationByUser();
            }
        },
    });

    useSubscription(UserGraphql.USER_CHANGED, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { success, action } = subscriptionData?.data?.usersChanged;
            if (success && action === SubscriptionType.USED) {
                showExternalNotification();
                refreshNotificationByUser();
            }
        },
    });

    useSubscription(EmailMessageGraphql.SUBSCRIBE_EMAIL_MESSAGE, {
        onSubscriptionData: ({ subscriptionData }) => {
            const { success, action } = subscriptionData?.data?.emailMessagesChanged;
            if (success && action === SubscriptionType.USED) {
                showExternalNotification();
                refreshNotificationByUser();
            }
        },
    });

    const onReadNotification = (item) => {
        readNotification({
            variables: {
                userId: loggedUser.UserId,
                notificationId: item.NotificationId,
            },
        });

        setNotification(item);
    };

    return (
        <>
            <Dropdown isOpen={dropdownOpen} toggle={() => toggle(!dropdownOpen)} className="navbar-item dropdown" tag="div">
                <DropdownToggle className="navbar-link dropdown-toggle icon" tag="a">
                    <i className="fa fa-bell" />
                    <span className="badge">{notifications?.filter((c) => !c.Read).length}</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu media-list dropdown-menu-end dropdown-notification" end tag="div">
                    <DropdownItem className="dropdown-header" tag="div" header>
                        {languageTitles.notificationLabel}
                        {' '}
                        {notifications?.length}
                    </DropdownItem>
                    {notifications?.map((element) => (
                        <DropdownItem
                            className="dropdown-item media tsfNotification"
                            key={element.NotificationId}
                            onClick={() => onReadNotification(element)}
                        >
                            <div className="media-body">
                                <h6 className="media-heading">
                                    {element.Title}
                                    {' '}
                                    {!element.Read && <i className="fa fa-exclamation-circle text-info" />}
                                </h6>
                                <p>
                                    {element.Message}
                                </p>
                                <div className="text-muted fs-10px">{moment(element.CreatedOn).fromNow()}</div>
                            </div>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
            {notification
            && (
                <Modal
                    isOpen={notification !== null}
                    toggle={() => setNotification(null)}
                >
                    <ModalHeader toggle={() => setNotification(null)}>
                        {notification?.Title ?? ''}
                    </ModalHeader>
                    <ModalBody>
                        {notification?.Message ?? ''}
                        <br />
                        <br />
                        {notification.Link
                        && (
                            <Link to={notification.Link} className="name">Ir al enlace</Link>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="white"
                            onClick={() => setNotification(null)}
                        >
                            <i className="fa fa-times fa-fw" />
                            {languageTitles.cancelTitle}
                        </Button>
                    </ModalFooter>
                </Modal>
            ) }
        </>
    );
};

export default DropdownNotification;
