import { gql } from '@apollo/client';

export default class UserGraphql {
    static GET_USERS_PAGING = gql`
      query users(
          $paging: PaginationInput
          $sort: [SortField]
          $filters: [Filters]
          $search: String
          $usePaging: Boolean
    ){
        users(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
          success
          message
          error
          total
          pages
          data {
            UserId
            EmployeeId
            Username
            Email
            ValidatedAccount
            IsAdmin
          }
        }
      }
    `;

    static GET_USERS = gql`
      query users($filters: [filters] =null){
        users(filters: $filters ) {
          offset
          pages
          success
          message
          error
          total
          data  {
            UserId
            Email
            ValidatedAccount
            UserType
          }
        }
      }
    `;

    static GET_USER = gql`
      query user($id: ID!) {
        user(id: $id) {
          success
          error
          message
          data{
            UserId
            Email
            UserType
            PictureURL
            Applicant {
              Name
              LastName
            }
          }
        }
      }
    `;

    static REGISTER_USER = gql`
      mutation registerUser($record: UserRegisterInput!) {
        registerUser(record: $record) {
          success
          error
          message
          data{
            UserId
            Email
            UserType
          }
        }
      }
    `;

    static UPDATE_USER = gql`
      mutation updateUser($record: UserInput!) {
        updateUser(record: $record) {
          success
          error
          message
          data{
            UserId
            Email
            UserType
            Applicant {
              Name
              LastName
            }
          }
        }
      }
    `;

    static DELETE_USER = gql`
      mutation deleteUser($ids: [ID!]!) {
        deleteUser(ids: $ids){
          success
          error
          message
          data
        }
      }
    `;

    static SUBSCRIBE_USER = gql`
      subscription usersChanged{
        usersChanged{
          success
          id
          action
          data
        }
      }
    `;

    static LOGIN_USER = gql`
      query authenticateUser($ux: String!, $px: String!){
        authenticateUser(ux: $ux, px: $px) {
        success
        message
        error
        data{
          UserId,
          PictureURL
          Email
          UserType
          Token
          Applicant{
            ApplicantId
            Name
            LastName
          }
        }
      }
    }`;

    static LOGOUT_USER = gql`
      query logOut($ux: Int!){
        logOut(ux: $ux) {
          success
          message
          error
          data{
            UserId
          }
        }
      }
    `;

    static VALIDATE_USER = gql`
      query validateUser($emx: String!, $vcx: String!) {
        validateUser(emx: $emx, vcx: $vcx){
          success
          message
          error
          data{
            UserId
          }
        }
      }
    `;

    static REQUEST_VALIDATE_USER = gql`
      query requestValidateUser($emx: String!) {
        requestValidateUser(emx: $emx){
          success
          message
          error
          data{
            UserId
          }
        }
      }
    `;

    static FORGOT_PASSWORD = gql`
      query forgotPassword($emx: String!) {
        forgotPassword(emx: $emx){
          success
          message
          error
          data{
            UserId
          }
        }
      }
    `;

    static RESET_PASSWORD = gql`
      mutation resetPassword($record: ResetPasswordInput) {
        resetPassword(record: $record){
          success
          message
          error
          data{
            UserId
          }
        }
      }
    `;

    static UPLOAD_USER_PICTURE = gql`
      mutation uploadPictureProfile($file: FileUpload!, $userId: Int!) {
        uploadPictureProfile(file: $file, userId: $userId){
          success
          message
          error
          data{
            FileUrl
          }
        }
      }
    `;

    static CHANGE_PASSWORD = gql`
      mutation changePassword($record: ChangePasswordInput!) {
        changePassword(record: $record){
          success
          error
          message
        }
    }`;

    static REQUEST_TOKEN = gql`
      query requestToken($userId: Int!) {
        requestToken(userId: $userId){
          success
          error
          message
        }
    }`;

    static VALIDATE_USER_MANUALLY = gql`
      query validateUserManually($ids: [ID!]!) {
        validateUserManually(ids: $ids){
          success
          message
          error
          data{
            UserId
          }
        }
    }`;

    static USER_CHANGED = gql`
    subscription usersChanged{
      usersChanged{
        success
        id
        action
      }
    }`;

    static LOGIN_COMPANY = gql`
      query authenticateCompany($ux: String!, $px: String!){
        authenticateCompany(ux: $ux, px: $px) {
        success
        message
        error
        data{
          UserId,
          PictureURL
          Email
          UserType
          Token
          Company{
            CompanyId
            CompanyName
          }
        }
      }
    }`;

    static ADD_BASIC_USER = gql`
  mutation createUserToCompany($record: BasicUserInput!) {
    createUserToCompany(record: $record) {
      success
      error
      message
      data{
        UserId
      }
    }
  }
  `;
}
