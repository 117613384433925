import React, { useContext } from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import AppContext from '../../config/appContext';

function YesNoControl(props) {
    const { languageTitles } = useContext(AppContext);
    const {
        value, onChange, name, disabled,
    } = props;

    const handleChange = (val) => {
        const newValue = { target: { name, value: val } };
        onChange(newValue);
    };

    return (
        <ButtonGroup size="sm" style={{ zIndex: 0 }}>
            <Button
                color="primary"
                outline={!value}
                active={value}
                onClick={() => handleChange(true)}
                disabled={disabled}
            >
                {languageTitles.Yes}
            </Button>
            <Button
                color="primary"
                outline={value}
                active={!value}
                onClick={() => handleChange(false)}
                disabled={disabled}
            >
                {languageTitles.No}
            </Button>
        </ButtonGroup>
    );
}

YesNoControl.propTypes = {
    value: PropTypes.bool,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

YesNoControl.defaultProps = {
    disabled: false,
    onChange: () => null,
    value: false,
};

export default YesNoControl;
