import React, { useContext, useEffect, useReducer } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    Button, DropdownItem, DropdownMenu,
    DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Panel, PanelHeader, PanelBody } from '../../panel/panel';
import SearchField from '../../search-field/searchfield';
import AppContext from '../../../config/appContext';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import ApplicantsMapping from '../../../mapping/applicantsMapping';
import UserGraphql from '../../../servicesapollo/userGraphql';

import Access from '../../../enums/permissions';
import UserPassword from './userPassword';
import ErrorPage from '../extraPages/error';

import './user.css';
import TSFButton from '../../actionButtons/tsfButton';
import FullContent from '../../layout/content/fullContent';
import Paginator from '../../paginator/paginator';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
    SET_CHECK_ITEM: 'setCheckItem',
    SET_APPROVE_ALL: 'setApproveAll',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_CHECK_ITEM: {
        const newRecords = state.records.map((item) => ({
            ...item,
            IsChecked: item.UserId === action.value ? !item.IsChecked : item.IsChecked,
        }));

        return {
            ...state,
            records: newRecords,
        };
    }
    case ACTION_TYPES.SET_APPROVE_ALL: {
        const newRecords = state.records.map((item) => ({
            ...item,
            IsChecked: !action.value,
        }));

        return {
            ...state,
            records: newRecords,
        };
    }
    default:
        return state;
    }
};

function Users() {
    const {
        languageTitles, showNotification, hasPermission,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const history = useHistory();
    const { pageNumber, pageResult } = useParams();

    if (!hasPermission(Access.USER_LIST)) return <ErrorPage />;

    const initState = {
        records: [],
        total: 0,
        applicantsByCompany: [],
        selectedItemToChangePassword: null,
        isSearching: true,
        canPreviousPage: false,
        canNextPage: false,

        showAlertAction: false,
        whichAction: '',
        actionTitle: '',
        actionDescription: '',
        itemSelected: null,

        // params
        search: '',
        page: Number(pageNumber),
        limit: Number(pageResult) || 15,
        pages: 0,
        usePaging: true,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        records, selectedItemToChangePassword,
        isSearching, canPreviousPage, canNextPage,
        whichAction, actionTitle, actionDescription, itemSelected, showAlertAction,
        search, page, limit, usePaging, pages,
    } = state;

    const dispatchEvent = (value, type = ACTION_TYPES.SET_STATE_VALUES) => {
        dispatch({
            type,
            value,
        });
    };

    const [getData, { loading, refetch }] = useLazyQuery(ApplicantsGraphql.GET_APPLICANTS_LIST_USER, {
        onCompleted: (res) => {
            if (res.applicants) {
                const {
                    data, total, success, message, error, pages: totalPages,
                } = res.applicants;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                const mappedData = ApplicantsMapping.mapApplicantsWithUser(data);

                dispatchEvent({
                    records: mappedData,
                    total,
                    pages: totalPages,
                    isSearching: false,
                    canPreviousPage: page > 1,
                    canNextPage: page < totalPages,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const callGetData = () => {
        const newUrl = `/users/page/${page}/show/${limit}`;
        history.push(newUrl);

        getData({
            variables: {
                paging: {
                    page,
                    limit,
                },
                usePaging,
                search,
            },
        });
    };

    const onActionCancel = () => {
        dispatchEvent({
            showAlertAction: false,
            whichAction: '',
            actionTitle: '',
            actionDescription: '',
            itemSelected: null,
        });
    };

    const [deleteUser, { loading: deleting }] = useMutation(UserGraphql.DELETE_USER, {
        onCompleted: (mutationData) => {
            const { success, message, error } = mutationData?.deleteUser;

            if (!success) {
                if (message) {
                    showNotification(message, NotificationType.INFO);
                    return;
                }

                showNotification(
                    error?.length > 0 ? error : languageTitles.errorWhenDelete,
                    NotificationType.DANGER,
                );
                return;
            }

            showNotification(languageTitles.userDeleteSuccess, NotificationType.SUCCESS);

            onActionCancel();

            callGetData();
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const [validateUser, { loading: validatingUser }] = useLazyQuery(UserGraphql.VALIDATE_USER_MANUALLY, {
        onCompleted: (mutationData) => {
            const { success, message, error } = mutationData?.validateUserManually;

            if (!success) {
                if (message) {
                    showNotification(message, NotificationType.INFO);
                    return;
                }

                showNotification(
                    error?.length > 0 ? error : languageTitles.userErrorWhenValidate,
                    NotificationType.DANGER,
                );
                return;
            }

            showNotification(languageTitles.userSuccessWhenValidate, NotificationType.SUCCESS);

            dispatchEvent({
                idToDelete: 0,
            });

            onActionCancel();
            callGetData();
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onAction = (item, action) => {
        switch (action) {
        case 'delete':
            dispatchEvent({
                showAlertAction: true,
                whichAction: 'delete',
                actionTitle: languageTitles.userDeleteAllData,
                actionDescription: languageTitles.userAskToDelete,
                itemSelected: item,
            });
            break;
        case 'validate':
            dispatchEvent({
                showAlertAction: true,
                whichAction: 'validate',
                actionTitle: languageTitles.userValidateManual,
                actionDescription: languageTitles.userAskValidateManual,
                itemSelected: item,
            });
            break;
        case 'changePassword':
            dispatchEvent({
                selectedItemToChangePassword: item,
            });
            break;
        case 'deletemultiple':
            dispatchEvent({
                showAlertAction: true,
                whichAction: 'deletemultiple',
                actionTitle: languageTitles.usersDeleteAllData,
                actionDescription: languageTitles.usersAskToDelete,
                itemSelected: null,
            });
            break;
        case 'validatemultiple':
            dispatchEvent({
                showAlertAction: true,
                whichAction: 'validatemultiple',
                actionTitle: languageTitles.usersValidateManual,
                actionDescription: languageTitles.usersAskValidateManual,
                itemSelected: null,
            });
            break;
        default:
            break;
        }
    };

    const onActionConfirm = () => {
        dispatchEvent({
            showAlertAction: false,
        });

        if (whichAction === 'delete') {
            deleteUser({
                variables: {
                    ids: [itemSelected.UserId],
                },
            });
        } else if (whichAction === 'validate') {
            validateUser({
                variables: {
                    ids: [itemSelected.UserId],
                },
            });
        } else if (whichAction === 'deletemultiple') {
            deleteUser({
                variables: {
                    id: itemSelected.UserId,
                },
            });
        } else if (whichAction === 'validatemultiple') {
            validateUser({
                variables: {
                    id: itemSelected.UserId,
                },
            });
        }
    };

    const setPage = (type) => {
        switch (type) {
        case 'first':
            dispatchEvent({
                page: 1,
                isSearching: true,
            });

            break;
        case 'previous':
            dispatchEvent({
                page: page - 1,
                isSearching: true,
            });
            break;
        case 'next':
            dispatchEvent({
                page: page + 1,
                isSearching: true,
            });
            break;
        case 'last':
            dispatchEvent({
                page: pages,
                isSearching: true,
            });
            break;
        default:
            break;
        }
    };

    useEffect(() => {
        if (isSearching) callGetData();
    }, [isSearching]);

    const blockButtons = validatingUser || deleting;

    const recordList = records.map((item, i) => (
        <tr key={item.UserId}>
            <td>{i + 1}</td>
            <td>
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={`table_checkbox${item.UserId}`}
                    checked={item.IsChecked}
                    onChange={() => dispatch({ type: ACTION_TYPES.SET_CHECK_ITEM, value: item.UserId })}
                />
            </td>
            <td>{item.UserId}</td>
            <td className="with-img">
                <img src={item.PictureURL ?? '/assets/img/user/user-13.jpg'} className="img-rounded height-30 zoom" alt="" />
            </td>
            <td>
                <Link to={`/applicant/${item.ApplicantId}`} className="">
                    {item.Name}
                    {' '}
                    {item.LastName}
                </Link>
            </td>
            <td>{item.Email}</td>
            <td className="with-btn text-nowrap">
                {hasPermission(Access.USER_VALIDATE)
                && (
                    <Button
                        color="primary"
                        size="sm"
                        title={languageTitles.usersBtnValidateUser}
                        className={`me-1 mb-1 ${blockButtons || item.ValidatedAccount ? 'disabled' : ''}`}
                        onClick={() => (item.ValidatedAccount ? null : onAction(item, 'validate'))}
                    >
                        {
                            validatingUser && itemSelected.UserId === item.UserId && whichAction === 'validate'
                                ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-check" />
                        }
                    </Button>
                )}
                {hasPermission(Access.USER_CHANGE_OTHER_PASSWORD)
                && (
                    <Button
                        color="success"
                        size="sm"
                        title={languageTitles.usersBtnChangePassword}
                        className="me-1 mb-1"
                        onClick={() => onAction(item, 'changePassword')}
                    >
                        <i className="fa fa-key" />
                    </Button>
                )}
                {hasPermission(Access.USER_DELETE)
                && (
                    <Button
                        color="danger"
                        size="sm"
                        title={languageTitles.deleteTitle}
                        className={`me-1 mb-1 ${blockButtons ? 'disabled' : ''}`}
                        onClick={() => onAction(item, 'delete')}
                    >
                        {deleting && itemSelected.UserId === item.UserId && whichAction === 'delete'
                            ? <i className="fa fa-spinner fa-spin" />
                            : <i className="fa fa-trash-alt" />}
                    </Button>
                )}
            </td>
        </tr>
    ));

    const allApproved = records.length > 0 && records.filter((c) => c.IsChecked).length === records.length;
    const recordsSelected = records?.filter((c) => c.IsChecked);
    return (
        <FullContent>
            <Panel>
                <PanelHeader>
                    <div className="panel-heading-btn justify-content-end">
                        <div className="col-md-5 me-2 d-flex">
                            {recordsSelected.length > 0
                            && (
                                <UncontrolledButtonDropdown className="me-1">
                                    <DropdownToggle caret color="default">
                                        {languageTitles.candidatesActionDropdownLabel}
                                        {' '}
                                        <i className="fa fa-caret-down" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {/* onClick={() => onAction(null, 'validatemultiple')} */}
                                        <DropdownItem>
                                            {languageTitles.usersActionDropdownValidate}

                                        </DropdownItem>
                                        {/* onClick={() => onAction(null, 'deletemultiple')} */}
                                        <DropdownItem>
                                            {languageTitles.usersActionDropdownDelete}

                                        </DropdownItem>
                                    </DropdownMenu>
                                    <span className="badge">{recordsSelected?.length}</span>
                                </UncontrolledButtonDropdown>
                            ) }
                            <SearchField
                                returnSearchValue={(val) => dispatchEvent({
                                    ...initState,
                                    search: val,
                                    isSearching: true,
                                }, ACTION_TYPES.SET_INITIAL_STATE)}
                                returnOnClear={() => dispatchEvent({
                                    ...initState,
                                    search: '',
                                    isSearching: true,
                                }, ACTION_TYPES.SET_INITIAL_STATE)}
                            />
                        </div>
                        <div className="d-flex me-1">
                            <TSFButton
                                action="refresh"
                                useLabel={false}
                                isWorking={loading}
                                isDisabled={loading}
                                onClick={() => refetch()}
                            />
                            <TSFButton
                                action="cancel"
                                btnColor="info"
                                useLabel={false}
                                isDisabled={loading}
                                onClick={() => dispatchEvent(initState, ACTION_TYPES.SET_INITIAL_STATE)}
                            />
                        </div>
                        <Paginator
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            loading={loading}
                            setPage={setPage}
                            page={page}
                            pages={pages}
                        />
                    </div>
                </PanelHeader>
                <PanelBody>
                    <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                        <div className="table-responsive">
                            <table className="table table-striped mb-0 align-middle">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="table_checkboxall-users"
                                                checked={allApproved ?? false}
                                                onChange={() => dispatch({ type: ACTION_TYPES.SET_APPROVE_ALL, value: allApproved })}
                                            />
                                        </th>
                                        <th>{languageTitles.usersTableColumnId}</th>
                                        <th>{languageTitles.usersTableColumnImage}</th>
                                        <th>{languageTitles.usersTableColumnName}</th>
                                        <th>{languageTitles.usersTableColumnEmail}</th>
                                        <th width="1%">{languageTitles.usersTableColumnActions}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recordList}
                                </tbody>
                            </table>
                        </div>
                    </PerfectScrollbar>
                </PanelBody>
            </Panel>
            {selectedItemToChangePassword
            && (
                <UserPassword
                    userId={selectedItemToChangePassword.UserId}
                    onClosePopup={() => dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        value: {
                            selectedItemToChangePassword: null,
                        },
                    })}
                />
            ) }
            {showAlertAction
            && (
                <SweetAlert
                    info
                    showCancel
                    confirmBtnText={languageTitles.sweetAlertConfirmBtnText}
                    cancelBtnText={languageTitles.sweetAlertCancelBtnText}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    title={actionTitle}
                    onConfirm={onActionConfirm}
                    onCancel={onActionCancel}
                >
                    {actionDescription}
                </SweetAlert>
            ) }
        </FullContent>
    );
}

export default Users;
