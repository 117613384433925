import { gql } from '@apollo/client';

export default class TestApplicantGraphql {
    static GET_APPLICANTS_LIST_TEST = gql`
  query testApplicants(
        $paging: PaginationInput
        $sort: [SortField]
        $filters: [Filters]
        $search: String
        $usePaging: Boolean
  ) {
    testApplicants(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
      success
      message
      error
      total
      data {
        TestApplicantId
        Status
        TypeTest
        ExpirationDate
        Active
        Applicant {
          ApplicantId
          Email
          Name
          LastName
        }
      }
    }
  }`;

    static DELETE_TEST_APPLICANT = gql`
  mutation deleteTestApplicant($id: ID!) {
    deleteTestApplicant(id: $id){
      success
      error
      message
      data
    }
  }`;

    static ENABLE_TEST = gql`
  mutation addTestApplicant($record: TestApplicantInput!) {
    addTestApplicant(record: $record){
      success
      error
      message
      data {
        TestApplicantId
      }
    }
  }`;

    static GET_APPLICANTS_TEST = gql`
query testApplicant($id: ID!) {
  testApplicant(id: $id) {
    success
    message
    error
    data {
        JsonTest
        JobPositionsLabels
        Applicant {
          ApplicantId
          Name
          LastName
        }
    }
  }
}`;

    static GET_CURRENT_TEST = gql`
query testApplicant($id: ID!) {
  testApplicant(id: $id) {
    success
    message
    error
    data {
      TestApplicantId
            Status
            JsonTest
            ExpirationDate
            Active
            TypeTest
            LastQuestion
            LastTestTab
    }
  }
}`;

    static SAVE_TEST = gql`
      mutation updateTestApplicant($record: TestApplicantInput!) {
        updateTestApplicant(record: $record){
          success,
          message,
          error,
          data {
            TestApplicantId
            Status
            JsonTest
            ExpirationDate
            Active
            TypeTest
            LastQuestion
            LastTestTab
          }
        }
      }
    `;

    static TEST_CHANGED = gql`
subscription testApplicantChanged{
  testApplicantChanged{
    success
    id
    action
    data
  }
}
`;
}
