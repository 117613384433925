/* eslint-disable no-undef */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
    useContext, useEffect, useReducer,
} from 'react';
import { useLazyQuery, useSubscription } from '@apollo/client';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Panel, PanelBody, PanelHeader } from '../../panel/panel';
import { termanResultTitle, termanResultText } from './termaninterpretation';
import AppContext from '../../../config/appContext';
import { FetchPolicy, NotificationType, SubscriptionType } from '../../../enums/general';
import TestApplicantGraphql from '../../../servicesapollo/testApplicantGraphql';

import './psycoanalytics.css';
import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...state,
            record: action.value,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

function TermanAnalytics() {
    const { showNotification, languageTitles } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    const { id } = useParams();

    const categories = [
        languageTitles.termanCategory1,
        languageTitles.termanCategory2,
        languageTitles.termanCategory3,
        languageTitles.termanCategory4,
        languageTitles.termanCategory5,
        languageTitles.termanCategory6,
        languageTitles.termanCategory7,
        languageTitles.termanCategory8,
        languageTitles.termanCategory9,
        languageTitles.termanCategory10,
    ];

    const initState = {
        record: {},
        cidata: [],
        answered: [],
        answeredbad: [],
        noanswered: [],
        qansweredTotal: [],
        percentages: [],
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        percentages, record, qansweredTotal, cidata,
    } = state;

    const options = {
        title: {
            text: languageTitles.termanChart1Title,

        },
        xAxis: {
            categories,
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: `${languageTitles.termanChart1TotalLabel}: {point.y}`,
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
            },
            labels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: 'black',
                },
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true,
                },
            },
        },
        series: [
            {
                colorByPoint: true,
                data: state.cidata,
            },
        ],
    };

    const options2 = {
        chart: {
            type: 'column',
        },
        title: {
            text: languageTitles.termanChart2Title,
        },
        xAxis: {
            categories,
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
            },
            labels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: 'black',
                },
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color:
            // theme
            (Highcharts.defaultOptions.title.style
              && Highcharts.defaultOptions.title.style.color)
            || 'black',
                },
            },
        },
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: `{series.name}: {point.y}<br/>${languageTitles.termanChart1TotalLabel}: {point.stackTotal}`,
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                },
            },
        },
        series: [
            {
                name: languageTitles.termanChart2Serie1,
                data: state.answered || [5, 3, 4, 7, 2, 5, 7, 6, 3, 8],
            },
            {
                name: languageTitles.termanChart2Serie2,
                data: state.answeredbad || [5, 3, 4, 7, 2, 5, 7, 6, 3, 8],
            },
            {
                name: languageTitles.termanChart2Serie3,
                data: state.noanswered || [5, 7, 6, 3, 8, 5, 3, 4, 7, 2],
            },
        ],
    };

    const prepareDataChart = (currentData) => {
        const cidatal = [];
        const answeredl = [];
        const noansweredl = [];
        const answeredlbad = [];
        const percentagel = [];
        let qansweredT = 0;

        for (let i = 0; i < currentData?.length; i += 1) {
            const element = currentData[i];

            const currentRecord = {
                name: element.TestName,
                y: 0,
            };

            let qanswered = 0;
            let qnoanswered = 0;
            let qansweredbad = 0;

            for (let j = 0; j < element?.Data?.length; j += 1) {
                const qt = element.Data[j];

                currentRecord.y += Number(qt.CorrectAnswer) === Number(qt.UserAnswer) ? 1 : 0;
                qanswered += qt.Answered && Number(qt.CorrectAnswer) === Number(qt.UserAnswer) ? 1 : 0;
                qansweredT += qt.Answered && Number(qt.CorrectAnswer) === Number(qt.UserAnswer) ? 1 : 0;
                qansweredbad += qt.Answered && Number(qt.CorrectAnswer) !== Number(qt.UserAnswer) ? 1 : 0;
                qnoanswered += qt.Answered ? 0 : 1;
            }

            cidatal.push(currentRecord);
            answeredl.push(qanswered);
            answeredlbad.push(qansweredbad);
            noansweredl.push(qnoanswered);
            percentagel.push(Math.round((Number(qanswered) * 100) / element.Data.length));
        }

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                cidata: cidatal,
                answered: answeredl,
                answeredbad: answeredlbad,
                noanswered: noansweredl,
                qansweredTotal: qansweredT,
                percentages: percentagel,
            },
        });
    };

    const [getTestData, { loading }] = useLazyQuery(TestApplicantGraphql.GET_APPLICANTS_TEST, {
        onCompleted: (res) => {
            if (res.testApplicant) {
                const {
                    data, success, message, error,
                } = res.testApplicant;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                const jsonTest = JSON.parse(data.JsonTest);
                // setRecord(data.Record);
                prepareDataChart(jsonTest);

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        Name: data.Applicant?.Name,
                        LastName: data.Applicant?.LastName,
                        PictureURL: data.PictureURL,
                        JobPositionsLabels: data.JobPositionsLabels,
                    },
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const printReport = () => {
        window.print();
    };

    useEffect(() => {
        getTestData({
            variables: {
                id,
            },
        });
    }, []);

    useSubscription(TestApplicantGraphql.TEST_CHANGED, {
        onSubscriptionData: ({ subscriptionData }) => {
            const {
                id: testApplicantId, data, action, success,
            } = subscriptionData?.data?.testApplicantChanged;

            if (success && action.toLowerCase() === SubscriptionType.UPDATED.toLowerCase()
            && Number(id) === Number(testApplicantId)) {
                prepareDataChart(JSON.parse(data));
            }
            // if (subscriptionData?.data?.testApplicantChanged?.success) refetch();
        },
    });

    const getPercentage = (c) => {
        const currentPercentage = percentages[c];

        switch (true) {
        case currentPercentage <= 31:
            return 1;
        case currentPercentage > 31 && currentPercentage < 41:
            return 2;
        case currentPercentage > 41 && currentPercentage < 61:
            return 3;
        case currentPercentage > 61 && currentPercentage < 81:
            return 4;
        case currentPercentage > 81 && currentPercentage < 91:
            return 5;
        case currentPercentage > 91 && currentPercentage < 101:
            return 6;
        default:
            return 0;
        }
    };

    const getPercentageLabel = (c) => {
        const currentPercentage = percentages[c];
        switch (true) {
        case currentPercentage <= 31:
            return <span className="badge bg-danger rounded-pill">{languageTitles.termanConclusionPercentageLabel1}</span>;
        case currentPercentage > 31 && currentPercentage < 41:
            return <span className="badge bg-warning rounded-pill">{languageTitles.termanConclusionPercentageLabel2}</span>;
        case currentPercentage > 41 && currentPercentage < 61:
            return <span className="badge bg-info rounded-pill">{languageTitles.termanConclusionPercentageLabel3}</span>;
        case currentPercentage > 61 && currentPercentage < 81:
            return <span className="badge bg-success rounded-pill">{languageTitles.termanConclusionPercentageLabel4}</span>;
        case currentPercentage > 81 && currentPercentage < 91:
            return <span className="badge bg-indigo rounded-pill">{languageTitles.termanConclusionPercentageLabel5}</span>;
        case currentPercentage > 91 && currentPercentage < 101:
            return <span className="badge bg-green rounded-pill">{languageTitles.termanConclusionPercentageLabel6}</span>;
        default:
            return '';
        }
    };

    return (
        <Panel>
            <PanelHeader>
                <div className="d-flex me-2 justify-content-end">
                    <TSFButton
                        action="refresh"
                        isWorking={loading}
                        onClick={() => getTestData({
                            variables: {
                                id,
                            },
                        })}
                    />
                    <Button
                        color="success"
                        className={`me-1 ${loading ? 'disabled' : ''}`}
                        onClick={printReport}
                    >
                        <i className="fa fa-print fa-fw" />
                        { languageTitles.printTitleBtn }
                    </Button>
                </div>
            </PanelHeader>
            <PanelBody>
                <div className="row">
                    <div className="col-xl-1 col-xs-1">
                        <img alt="" src={(record.PictureURL === '' || record.PictureURL === null) ? '/assets/img/user/user-13.jpg' : record.PictureURL} className="img-rounded img-responsive" />
                    </div>
                    <div className="col-xl-11 col-xs-11">
                        <div className="col-xl-12">
                            {languageTitles.termanCandidateName}
                            :
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {record.Name}
                                {' '}
                                {record.LastName}
                            </span>
                        </div>
                        <div className="col-xl-12">
                            {languageTitles.termanCorrectAnswer}
                            :
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {qansweredTotal}
                                /173
                            </span>
                            {' '}
                            |
                            {' '}
                            {languageTitles.termanAveragePercentage}
                            :
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>
                                {((Number(qansweredTotal) * 100) / 173).toFixed(2)}
                                {' '}
                                %
                            </span>
                        </div>
                        <div className="col-xl-12">
                            {languageTitles.termanApplyTo}
                            :
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>{record.JobPositionsLabels?.join(', ')}</span>
                        </div>
                    </div>
                    <div className="col-xl-9 col-xs-9">
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>
                    <br />
                    <div className="col-xl-3 col-xs-3">
                        <div className="table-responsive table-striped">
                            <table className="table table-valign-middle mb-0">
                                <thead>
                                    <tr>
                                        <th className="p-l-15">{languageTitles.termanTestLabel}</th>
                                        <th>{languageTitles.termanResultLabel}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {percentages.length > 0 && percentages.map((item, c) => (
                                        <tr style={{ fontWeight: 'bold' }} key={c}>
                                            <td className="p-l-15">
                                                <label className="label label-primary">
                                                    {categories[c]}
                                                </label>
                                            </td>
                                            <td>
                                                {cidata[c]?.y ?? 0}
                                                {' '}
                                                -
                                                {' '}
                                                {percentages[c]}
                                                %
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop: '20px' }}>
                    <div className="col-xl-12">
                        <HighchartsReact className="chart2onlyprint" highcharts={Highcharts} options={options2} />
                    </div>
                </div>

                <div className="row" style={{ paddingTop: '20px' }}>
                    <div className="col-xl-12">
                        <div className="table-responsive table-resume table-striped">
                            <table className="table table-valign-middle mb-0">
                                <thead>
                                    <tr>
                                        <th>{languageTitles.termanConclusionLabel}</th>
                                        <th>{languageTitles.termanClassificationLabel}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {percentages.length > 0 && percentages.map((item, c) => {
                                        const clevel = getPercentage(c);

                                        return (
                                            <tr key={c}>
                                                <td>
                                                    <span dangerouslySetInnerHTML={{ __html: termanResultTitle[c + 1] }} />
                                                    <br />
                                                    <span style={{ fontWeight: 'bold' }}>{termanResultText[`${c + 1}_${clevel}`]}</span>
                                                </td>
                                                <td className="text-center fs-15px">
                                                    {getPercentageLabel(c)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </PanelBody>
        </Panel>
    );
}

export default TermanAnalytics;
