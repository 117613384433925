import { gql } from '@apollo/client';

export default class EmailTemplateGraphql {
    static GET_EMAIL_TEMPLATE_LIST = gql`
    query emailTemplates(
      $paging: PaginationInput
      $sort: [SortField]
      $filters: [Filters]
      $search: String
      $usePaging: Boolean
    ) {
      emailTemplates(paging: $paging, sort: $sort, filters: $filters, search: $search, usePaging: $usePaging) {
        success
        message
        error
        total
        data {
          EmailTemplateId
          Template
          TemplateName
        }
      }
  }`;

    static SAVE_EMAIL_TEMPLATE = gql`
mutation addEmailTemplate($record: EmailTemplateInput!) {
  addEmailTemplate(record: $record){
      success,
      message,
      error,
      data{
        EmailTemplateId
      }
    }
}
`;

    static UPDATE_EMAIL_TEMPLATE = gql`
mutation updateEmailTemplate($record: EmailTemplateInput!) {
  updateEmailTemplate(record: $record){
        success,
        message,
        error,
        data{
          EmailTemplateId
          Template
          TemplateName
        }
      }
}
`;

    static DELETE_EMAIL_TEMPLATE = gql`
    mutation deleteEmailTemplate($id: ID!) {
      deleteEmailTemplate(id: $id){
        success,
        message,
        error,
      }
    }
  `;

    static EMAILTEMPLATE_CHANGED = gql`
subscription emailTemplatesChanged{
  emailTemplatesChanged{
    success
    id
    action
  }
}
`;
}
