/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-alert */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
    useEffect, useContext, useReducer,
} from 'react';
import { useParams } from 'react-router-dom';
import {
    ButtonGroup, Button, TabPane, Nav, NavItem, NavLink, TabContent, Alert,
} from 'reactstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import {
    Panel, PanelHeader, PanelBody,
} from '../../panel/panel';
import AppContext from '../../../config/appContext';
import {
    FetchPolicy, NotificationType,
} from '../../../enums/general';

import ErrorPage from '../extraPages/error';
import TSFButton from '../../actionButtons/tsfButton';
import 'react-datetime/css/react-datetime.css';
import './companyFull.css';
import CompanyGraphql from '../../../servicesapollo/companyGraphql';
import useMessageHelper from '../../../helpers/messageHelper';
import useDateHelper from '../../../helpers/dateHelper';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
    SET_FILES_AFTER_DELETE: 'setFilesAfterDelete',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.SET_FILES_AFTER_DELETE: {
        const newArr = state.Files.map((c) => ({
            ...c,
            FileUrl: state.columnDeleting === c.ColumnName ? null : c.FileUrl,
            FileTitle: state.columnDeleting === c.ColumnName ? '' : c.FileTitle,
            FileInput: state.columnDeleting === c.ColumnName ? undefined : c.FileInput,
        }));

        return {
            ...state,
            Files: newArr,
            columnDeleting: null,
        };
    }
    default:
        return state;
    }
};

const initState = {
    columnDeleting: null,
    columnUploading: null,
    AcceptDisclaimer: false,
    activeTab: '1',
    Files: [
        {
            Sort: 1,
            ColumnName: 'FileGoverment1',
            LinkToDownload: 'https://www.guichetemplois.gc.ca/accueil',
            TextToDownload: 'Guichet Emploi Fédéral ',
            FileTitle: '',
            FileUrl: '',
            FileType: `Tableau de bord – Là où nous pouvons voir le numéro de l’affichage ainsi que
            la date de début et de fin de la publication.`,
            Type: 'Goverment',
        },
        {
            Sort: 2,
            ColumnName: 'FileGoverment2',
            LinkToDownload: 'https://ge.cepn-fnec.com/',
            TextToDownload: 'Guichet Emploi des Premières Nations',
            FileTitle: '',
            FileUrl: '',
            FileType: `Tableau de bord – Là où nous pouvons voir la date de début et
            de fin de chaque publication.`,
            Type: 'Goverment',
        },
        {
            Sort: 3,
            ColumnName: 'FileGoverment3',
            LinkToDownload: 'http://www.quebecemploi.gouv.qc.ca',
            TextToDownload: 'Québec Emploi',
            FileTitle: '',
            FileUrl: '',
            FileType: 'Tableau de bord – Là où nous pouvons voir le numéro de l’affichage ainsi que la date de début et de fin de la publication.',
            Type: 'Goverment',
        },
        {
            Sort: 4,
            ColumnName: 'FileGoverment4',
            LinkToDownload: 'https://www.rcjeq.org',
            TextToDownload: 'CJE local ou organisme d’aide à l’emploi visant les personnes immigrantes ou handicapées',
            FileTitle: '',
            FileUrl: '',
            FileType: 'Preuve montrant la date de publication et d’expiration de l’affichage ( printscreen de l’affichage, courriel de confirmation d’un agent du CJE, etc ).',
            Type: 'Goverment',
        },
        {
            Sort: 5,
            ColumnName: 'FileGoverment5',
            LinkToDownload: 'https://www.guichetemplois.gc.ca/accueil',
            TextToDownload: 'Guichet Emploi Fédéral',
            FileTitle: '',
            FileUrl: '',
            FileType: 'Tableau de bord – Là où nous pouvons voir le numéro de l’affichage ainsi que la date de début et de fin de la publication.',
            Type: 'Goverment',
        },
        {
            Sort: 6,
            ColumnName: 'FileGoverment6',
            LinkToDownload: 'http://www.quebecemploi.gouv.qc.ca',
            TextToDownload: 'Québec Emploi',
            FileTitle: '',
            FileUrl: '',
            StartDate: '',
            EndDate: '',
            FileType: 'Tableau de bord – Là où nous pouvons voir le numéro de l’affichage ainsi que la date de début et de fin de la publication.',
            Type: 'Goverment',
        },
        {
            Sort: 7,
            ColumnName: 'FileGoverment7',
            LinkToDownload: 'http://www.jobillico.com',
            TextToDownload: 'Job Illico et/ou Indeed ',
            FileTitle: '',
            FileUrl: '',
            FileType: 'Tableau de bord – Là où nous pouvons voir la date de début et de fin de chaque publication.',
            Type: 'Goverment',
        },
        {
            Sort: 8,
            ColumnName: 'AdditionalFile1',
            LinkToDownload: '',
            TextToDownload: 'Grille salariale détaillée',
            FileTitle: '',
            FileUrl: '',
            Type: 'Additionals',
        },
        {
            Sort: 9,
            ColumnName: 'AdditionalFile2',
            LinkToDownload: '',
            TextToDownload: `Simulation de paie pour chacune des professions visées par la demande, 
            pour chaque quart de travail demandé, incluant les déductions normales faites par l’Entreprise, selon le salaire accordé aux nouveaux travailleurs`,
            FileTitle: '',
            FileUrl: '',
            Type: 'Additionals',
        },
        {
            Sort: 10,
            ColumnName: 'AdditionalFile3',
            LinkToDownload: '',
            TextToDownload: 'Annexe 100 du T2 de la dernière année financière',
            FileTitle: '',
            FileUrl: '',
            Type: 'Additionals',
        },
        {
            Sort: 11,
            ColumnName: 'AdditionalFile4',
            LinkToDownload: '',
            TextToDownload: 'Annexe 125 du T2 de la dernière année financière',
            FileTitle: '',
            FileUrl: '',
            Type: 'Additionals',
        },
        {
            Sort: 12,
            ColumnName: 'AdditionalFile5',
            LinkToDownload: '',
            TextToDownload: 'Sommaire T4 de la dernière année fiscale',
            FileTitle: '',
            FileUrl: '',
            Type: 'Additionals',
        },
        {
            Sort: 13,
            ColumnName: 'AdditionalFile6',
            LinkToDownload: '',
            TextToDownload: 'OU - Soumettre une lettre d’avocat',
            FileTitle: '',
            FileUrl: '',
            Type: 'Additionals',
        },
    ],
};

function CompanyGovermentFiles() {
    const { companyId } = useParams();

    const {
        showNotification, languageTitles, loggedUser,
        setIsDirtyData,
    } = useContext(AppContext);

    const { convertToDateFromString } = useDateHelper();

    const { getErrorMessage } = useMessageHelper();

    if ((Number(companyId) !== Number(loggedUser.EntityId) && loggedUser.UserType !== 'companyadmin')
    || !loggedUser.UserType.includes('company')) return <ErrorPage />;

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        Files, columnDeleting, AcceptDisclaimer, activeTab, columnUploading,
    } = state;

    const [getFiles, { loading: isLoading, refetch }] = useLazyQuery(CompanyGraphql.GET_COMPANY_GOVERMENT_FILES, {
        onCompleted: (res) => {
            if (res.company) {
                const {
                    data, success, message, error,
                } = res.company;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                const govermentFilesData = data.GovermentFilesData && JSON.parse(data.GovermentFilesData);

                const newRecords = initState.Files.map((c) => (
                    {
                        ...c,
                        FileUrl: data[c.ColumnName],
                        FileTitle: data[c.ColumnName]?.replace(/^.*[\\/]/, ''),
                        StartDate: convertToDateFromString(govermentFilesData?.[c.ColumnName]?.StartDate),
                        EndDate: convertToDateFromString(govermentFilesData?.[c.ColumnName]?.EndDate),
                    }
                ));

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        Files: newRecords,
                        AcceptDisclaimer: data.AcceptDisclaimer,
                    },
                });
                setIsDirtyData(false);
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onActionButtons = (e, action) => {
        e.preventDefault();

        if (action === 'refresh') {
            refetch();
        }
    };

    const [deleteFile, { loading: isDeleting }] = useMutation(CompanyGraphql.DELETE_COMPANY_GOVERMENT_FILES, {
        onCompleted: (mutationData) => {
            if (mutationData.deleteCompanyGovermentFile) {
                const { success, message, error } = mutationData.deleteCompanyGovermentFile;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenDeleteFile,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_FILES_AFTER_DELETE,
                });

                setIsDirtyData(false);
                showNotification(languageTitles.successWhenDeleteFile, NotificationType.SUCCESS, 500);
                refetch();
            }
        },
        onError: (error) => {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    columnDeleting: null,
                },
            });
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onRemoveFileInSections = async (e, item) => {
        e.preventDefault();

        if (item.FileUrl && item.FileUrl !== '') {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    columnDeleting: item.ColumnName,
                },
            });

            deleteFile({
                variables: {
                    columnName: item.ColumnName,
                    companyId: Number(companyId),
                },
            });
        } else {
            dispatch({
                type: ACTION_TYPES.SET_FILES_AFTER_DELETE,
            });
        }
    };

    const [saveRecord] = useMutation(CompanyGraphql.UPDATE_COMPANY, {
        onCompleted: (res) => {
            if (res?.updateCompany) {
                const {
                    success, message, error,
                } = res?.updateCompany;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                setIsDirtyData(false);
                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS, 500);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const [notifyAdminRequest, { loading: notifying }] = useLazyQuery(CompanyGraphql.NOTIFYADMIN, {
        onCompleted: (res) => {
            if (res.notifyAdmin) {
                const {
                    success, message, error,
                } = res.notifyAdmin;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                showNotification(languageTitles.notifyAdmin, NotificationType.SUCCESS, 500);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const saveDisclaimer = async (val) => {
        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                AcceptDisclaimer: val,
            },
        });

        saveRecord({
            variables: {
                record: {
                    CompanyId: Number(companyId),
                    AcceptDisclaimer: val,
                },
            },
        });
    };

    const [uploadFile, { loading: isUploading }] = useMutation(CompanyGraphql.UPLOAD_COMPANY_GOVERMENT_FILES, {});

    const onUploadFile = async () => {
        // e.preventDefault();

        const filesToUpload = Files.filter((c) => c?.FileInput !== undefined && c?.ColumnName === columnUploading);
        const oldFiles = Files.filter((c) => c?.FileInput === undefined && c?.ColumnName !== columnUploading);

        if (filesToUpload.length > 0) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    errorMessage: '',
                },
            });

            const promises = filesToUpload.map((file) => {
                const { files } = file.FileInput;

                return uploadFile({
                    variables: {
                        file: files[0],
                        companyId: Number(companyId),
                        columnName: file.ColumnName,
                    },
                })
                    .then((res) => {
                        const {
                            success, data: dataFile,
                        } = res?.data?.companyUploadGovermentFile;

                        if (success) {
                            file.FileUrl = dataFile;
                            file.FileTitle = dataFile.replace(/^.*[\\/]/, '');
                            delete file.FileInput;
                        }

                        return file;
                    }).catch((err) => {
                        dispatch({
                            type: ACTION_TYPES.SET_STATE_VALUES,
                            value: {
                                columnUploading: null,
                            },
                        });
                        console.log(err);
                    });
            });

            const resultFiles = await Promise.all(promises);
            const newFiles = [
                ...oldFiles,
                ...resultFiles,
            ];

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    Files: newFiles.sort((a, b) => a.Sort - b.Sort),
                    columnUploading: null,
                },
            });

            // setIsDirtyData(false);
        }
    };

    const handleFileChange = (ColumnName, value, ele) => {
        if (ColumnName === 'FileInput' && value.files && !(value.files[0].type.includes('pdf'))) {
            showNotification(languageTitles.allowOnlyPdf, 'danger');
            return;
        }

        const newFiles = Files.map((c) => {
            const item = { ...c };
            if (item.ColumnName === ele.ColumnName) {
                item[ColumnName] = value;
                item.FileTitle = value.files[0].name;
            }

            return item;
        });

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                Files: newFiles,
                columnUploading: ele.ColumnName,
            },
        });
        // setIsDirtyData(true);
    };

    const togglePill = (tab) => {
        if (state.activeTab !== tab) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    activeTab: tab,
                },
            });
        }
    };

    const notifyAdmin = () => {
        notifyAdminRequest({
            variables: {
                companyId,
            },
        });
    };

    useEffect(() => {
        if (columnUploading) {
            onUploadFile(columnUploading);
        }
    }, [columnUploading]);

    useEffect(() => {
        if (companyId > 0) {
            getFiles({
                variables: {
                    id: companyId,
                },
            });
        }
    }, [companyId]);

    return (
        <Panel>
            <PanelHeader title={languageTitles.companyTabFilesTitle}>
                <ButtonGroup className="pull-right">
                    <TSFButton
                        action="refresh"
                        isWorking={isLoading || isUploading}
                        onClick={(e) => onActionButtons(e, 'refresh')}
                    />
                </ButtonGroup>
            </PanelHeader>
            <PanelBody>
                <div className="card-body">
                    <div className="row row-space-2 mb-3">
                        <div className="nav-wizards-container">
                            <Nav className="nav nav-wizards-2" pills>
                                <NavItem className="nav-item col">
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' }, 'completed')}
                                        onClick={() => { togglePill('1'); }}
                                    >
                                        <span className="d-sm-none nav-text">
                                            {languageTitles.fileGovermentTab1Title}
                                        </span>
                                        <span className="d-sm-block d-none nav-text">{languageTitles.fileGovermentTab1Title}</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="nav-item col">
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' }, 'completed')}
                                        onClick={() => { togglePill('2'); }}
                                    >
                                        <span className="d-sm-none nav-text">
                                            {languageTitles.fileGovermentTab2Title}
                                        </span>
                                        <span className="d-sm-block d-none nav-text">{languageTitles.fileGovermentTab2Title}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                </div>
                <TabContent className="rounded bg-white" activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="table-responsive">
                            <table className="table table-striped mb-0 align-middle">
                                <thead>
                                    <tr>
                                        <th width="1px">#</th>
                                        <th width="25%">{languageTitles.fileGovermentTableColumn1}</th>
                                        <th width="35%">{languageTitles.fileGovermentTableColumn2}</th>
                                        <th width="25%">{languageTitles.fileGovermentTableColumn3}</th>
                                        <th width="15%">{languageTitles.fileGovermentTableColumn4}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { Files?.filter((c) => c.Type === 'Goverment').map((c, i) => {
                                        const newid = uuidv4();

                                        return (
                                            <React.Fragment key={i}>
                                                {i === 0 && <tr><th className="" colSpan="6">{languageTitles.fileGovermentLowSalaries}</th></tr>}
                                                {i === 4 && <tr><th className="" colSpan="6">{languageTitles.fileGovermentHighSalaries}</th></tr>}
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>
                                                        {i === 6 && (
                                                            <>
                                                                <a href="http://www.jobillico.com" target="_blank" rel="noreferrer">Job Illico</a>
                                                                { ' et/ou '}
                                                                <a href="https://emplois.ca.indeed.com/" target="_blank" rel="noreferrer">Indeed</a>
                                                            </>
                                                        )}
                                                        {i !== 6 && <a href={c?.LinkToDownload} target="_blank" rel="noreferrer">{c.TextToDownload}</a>}
                                                    </td>
                                                    <td>{c.FileType}</td>
                                                    <td>{c.FileTitle}</td>
                                                    <td nowrap="true">
                                                        <input
                                                            id={newid}
                                                            className="hide"
                                                            onChange={(e) => handleFileChange('FileInput', e.target, c)}
                                                            type="file"
                                                            placeholder={languageTitles.mailSelectFile}
                                                            accept=".pdf"
                                                        />
                                                        <Button
                                                            color="info"
                                                            size="sm"
                                                            className="me-1 mb-1"
                                                            disabled={isDeleting || isUploading}
                                                            // eslint-disable-next-line no-undef
                                                            onClick={() => document.getElementById(newid).click()}
                                                        >
                                                            { isUploading && columnUploading === c.ColumnName && <i className="fa fa-spinner fa-spin fa-fw" /> }
                                                            { columnUploading !== c.ColumnName && <i className="fa fa-file fa-fw" />}
                                                        </Button>
                                                        <Button
                                                            color="danger"
                                                            size="sm"
                                                            className="me-1 mb-1"
                                                            disabled={isDeleting || isUploading || !c.FileTitle || c.FileTitle === ''}
                                                            onClick={(e) => onRemoveFileInSections(e, c)}
                                                        >
                                                            { isDeleting && columnDeleting && columnDeleting === c.ColumnName && <i className="fa fa-spinner fa-spin fa-fw" /> }
                                                            { columnDeleting !== c.ColumnName && <i className="fa fa-trash-alt fa-fw" /> }
                                                        </Button>
                                                        <a href={c.FileUrl ?? null} target="_blank" rel="noreferrer">
                                                            <Button
                                                                color="info"
                                                                size="sm"
                                                                className="me-1 mb-1"
                                                                disabled={isDeleting || isUploading || !c.FileUrl}
                                                            >
                                                                <i className="fa fa-download" />
                                                            </Button>
                                                        </a>
                                                    </td>
                                                </tr>
                                                {i === 6 && <tr key={(c.Sort * i * 47)}><th className="" colSpan="6">{languageTitles.fileGovermentExemption}</th></tr>}
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <Alert color="light" isOpen>
                                <strong>
                                    <span className="text-danger">{languageTitles.fileGovermentConsentImportant}</span>
                                    {' '}
                                    {languageTitles.fileGovermentConsent}
                                </strong>
                                <div className="form-check pt-3">
                                    <input
                                        onChange={() => saveDisclaimer(!AcceptDisclaimer)}
                                        type="checkbox"
                                        className="form-check-input"
                                        data-checked="email-checkbox"
                                        id="consentfiles"
                                        checked={AcceptDisclaimer}
                                    />
                                    <strong><label className="form-check-label" htmlFor="consentfiles">{languageTitles.fileGovermentConsentCheckbox}</label></strong>
                                </div>
                            </Alert>
                        </div>
                        <div className="float-end">
                            <TSFButton
                                action="custom"
                                btnColor="primary"
                                useIcon
                                iconClass="fas fa-arrow-right"
                                label={languageTitles.nextBtn}
                                onClick={() => { togglePill('2'); }}
                            />
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="table-responsive">
                            <table className="table table-striped mb-0 align-middle">
                                <thead>
                                    <tr>
                                        <th width="1px">#</th>
                                        <th width="39%">{languageTitles.fileGovermentTableColumn1}</th>
                                        <th width="45%">{languageTitles.fileGovermentTableColumn3}</th>
                                        <th width="15%">{languageTitles.fileGovermentTableColumn4}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { Files.filter((c) => c.Type === 'Additionals')?.map((c, i) => {
                                        const newid = uuidv4();
                                        return (
                                            <React.Fragment key={i}>
                                                {i === 2 && <tr><th className="" colSpan="4">{languageTitles.fileAdditionalFiles}</th></tr>}
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>
                                                        {i === 5 && (
                                                            <>
                                                                {c.TextToDownload}
                                                                {' -> '}
                                                                <a href="https://tsffiles.blob.core.windows.net/travailleur/TSFFiles/Exemple d'attestation de capacité finanicère.doc" target="_blank" rel="noreferrer">Télécharger un fichier</a>
                                                            </>
                                                        )}
                                                        {i !== 5 && c.TextToDownload}

                                                    </td>
                                                    <td>{c.FileTitle}</td>
                                                    <td nowrap="true">
                                                        <input
                                                            id={newid}
                                                            className="hide"
                                                            onChange={(e) => handleFileChange('FileInput', e.target, c)}
                                                            type="file"
                                                            placeholder={languageTitles.mailSelectFile}
                                                            accept=".pdf"
                                                        />
                                                        <Button
                                                            color="info"
                                                            size="sm"
                                                            className="me-1 mb-1"
                                                            disabled={isDeleting || isUploading}
                                                            // eslint-disable-next-line no-undef
                                                            onClick={() => document.getElementById(newid).click()}
                                                        >
                                                            { isUploading && columnUploading === c.ColumnName && <i className="fa fa-spinner fa-spin fa-fw" /> }
                                                            { columnUploading !== c.ColumnName && <i className="fa fa-file fa-fw" />}
                                                        </Button>
                                                        <Button
                                                            color="danger"
                                                            size="sm"
                                                            className="me-1 mb-1"
                                                            disabled={isDeleting || isUploading || !c.FileTitle || c.FileTitle === ''}
                                                            onClick={(e) => onRemoveFileInSections(e, c)}
                                                        >
                                                            { isDeleting && columnDeleting && columnDeleting === c.ColumnName && <i className="fa fa-spinner fa-spin fa-fw" /> }
                                                            { columnDeleting !== c.ColumnName && <i className="fa fa-trash-alt fa-fw" /> }
                                                        </Button>
                                                        <a href={c.FileUrl ?? null} target="_blank" rel="noreferrer">
                                                            <Button
                                                                color="info"
                                                                size="sm"
                                                                className="me-1 mb-1"
                                                                disabled={isDeleting || isUploading || !c.FileUrl}
                                                            >
                                                                <i className="fa fa-download" />
                                                            </Button>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="float-end">
                            <TSFButton
                                action="custom"
                                btnColor="primary"
                                className="me-2 mt-4"
                                useIcon
                                iconClass="fas fa-arrow-left"
                                label={languageTitles.previousBtn}
                                onClick={() => { togglePill('1'); }}
                            />
                            <TSFButton
                                action="custom"
                                btnColor="success"
                                className="mt-4"
                                useIcon
                                disabled={notifying}
                                iconClass={notifying ? 'fa fa-spinner fa-spin' : 'fas fa-phone-volume'}
                                label={languageTitles.notifiqueToCompanyAdminBtn}
                                onClick={notifyAdmin}
                            />
                        </div>
                    </TabPane>
                </TabContent>
            </PanelBody>
        </Panel>
    );
}

export default CompanyGovermentFiles;
