/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import ReactQuill from 'react-quill';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation, useQuery } from '@apollo/client';
import EmailTemplateGraphql from '../../../servicesapollo/emailTemplateGraphql';
import { FetchPolicy, NotificationType } from '../../../enums/general';
import AppContext from '../../../config/appContext';

import './email.css';
import Access from '../../../enums/permissions';
import ErrorPage from '../extraPages/error';
import TSFButton from '../../actionButtons/tsfButton';
import DeleteAlert from '../../alert/deleteAlert';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...action.value,
        };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        const { value } = action;
        return {
            ...state,
            ...value,
            isDirty: true,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

function EmailTemplateV2(props) {
    const { showNotification, languageTitles, hasPermission } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    if (!hasPermission(Access.EMAIL_TEMPLATE_WRITE)) return <ErrorPage />;

    const initState = {
        EmailTemplateId: 0,
        TemplateName: '',
        Template: '',
        isDirty: false,
        idToDelete: 0,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const editor = {
        height: (window.innerHeight > 600) ? window.innerHeight - 315 : 300,
    };

    const {
        showInPopup, onClosePopup, openPopup,
        onSelectedItems,
    } = props;

    const { data, loading, refetch } = useQuery(EmailTemplateGraphql.GET_EMAIL_TEMPLATE_LIST, {
        variables: {
            usePaging: false,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const mutationQuery = state.EmailTemplateId > 0 ? EmailTemplateGraphql.UPDATE_EMAIL_TEMPLATE : EmailTemplateGraphql.SAVE_EMAIL_TEMPLATE;
    const [saveTemplate, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (mutationData) => {
            if (mutationData?.addEmailTemplate || mutationData?.updateEmailTemplate) {
                const {
                    success, message, error,
                } = mutationData?.addEmailTemplate || mutationData?.updateEmailTemplate;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS);

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: initState,
                });

                refetch();
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSave = () => {
        saveTemplate({
            variables: {
                record: {
                    EmailTemplateId: state.EmailTemplateId,
                    TemplateName: state.TemplateName,
                    Template: state.Template,
                },
            },
        });
    };

    const [deleteTemplate, { loading: isDeleting }] = useMutation(EmailTemplateGraphql.DELETE_EMAIL_TEMPLATE, {
        onCompleted: (mutationData) => {
            if (mutationData.deleteEmailTemplate) {
                const { success, message, error } = mutationData.deleteEmailTemplate;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenDelete,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        idToDelete: 0,
                    },
                });

                showNotification(languageTitles.successWhenDelete, NotificationType.SUCCESS);

                refetch();
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onDeleteEmail = () => {
        deleteTemplate({
            variables: {
                id: state.idToDelete,
            },
        });
    };

    const mainContent = (
        <div className="mailbox mailboxv2 mailboxTemplate">
            <div className="mailbox-sidebar">
                <div className="mailbox-sidebar-content collapse d-lg-block show">
                    <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                        <div className="table-responsive " style={{ height: '100%' }}>
                            <table className="table table-striped mb-0 align-middle">
                                <tbody>
                                    { data?.emailTemplates?.data?.map((item) => (
                                        <tr key={item.EmailTemplateId}>
                                            <td>{item.TemplateName}</td>
                                            <td className="with-btn text-nowrap text-end">
                                                {showInPopup
                                                    && (
                                                        <Button
                                                            color="success"
                                                            size="sm"
                                                            className="me-1 mb-1"
                                                            onClick={() => onSelectedItems(item)}
                                                        >
                                                            <i className="fa fa-check" />
                                                        </Button>
                                                    ) }
                                                { hasPermission(Access.EMAIL_TEMPLATE_WRITE)
                                                && (
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        title={languageTitles.updateTitle}
                                                        className="me-1 mb-1"
                                                        onClick={() => dispatch({
                                                            type: ACTION_TYPES.SET_RECORD,
                                                            value: {
                                                                EmailTemplateId: item.EmailTemplateId,
                                                                TemplateName: item.TemplateName,
                                                                Template: item.Template,
                                                                isDirty: false,
                                                            },
                                                        })}
                                                    >
                                                        <i className="fa fa-edit" />
                                                    </Button>
                                                )}
                                                { hasPermission(Access.EMAIL_TEMPLATE_DELETE)
                                                && (
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        title={languageTitles.deleteTitle}
                                                        className="me-1 mb-1"
                                                        onClick={() => dispatch({
                                                            type: ACTION_TYPES.SET_STATE_VALUES,
                                                            value: { idToDelete: item.EmailTemplateId },
                                                        })}
                                                    >
                                                        { isDeleting && state.idToDelete === item.EmailTemplateId
                                                            ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-trash-alt" />}

                                                    </Button>
                                                ) }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
            <div className="mailbox-content">
                <div className="mailbox-content-body">
                    <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                        <div className="mailbox-form">
                            <div className="mailbox-subject">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="TemplateName"
                                    placeholder={languageTitles.emailTemplateTitle}
                                    value={state.TemplateName}
                                    onChange={(e) => dispatch({
                                        type: ACTION_TYPES.SET_RECORD_CHANGED,
                                        value: {
                                            TemplateName: e.target.value,
                                        },
                                    })}
                                />
                            </div>
                            <div className="mailbox-input">
                                <ReactQuill
                                    value={state.Template}
                                    className="border-0"
                                    name="Template"
                                    placeholder={languageTitles.emailContentTitle}
                                    onChange={(val) => dispatch({
                                        type: ACTION_TYPES.SET_RECORD_CHANGED,
                                        value: {
                                            Template: val,
                                        },
                                    })}
                                    style={{ height: `${editor.height}px`, marginBottom: '20px' }}
                                />
                            </div>
                        </div>
                    </PerfectScrollbar>
                </div>
                <div className="mailbox-content-footer d-flex align-items-center justify-content-end">
                    <TSFButton
                        action="cancel"
                        isDisabled={isSaving || loading}
                        onClick={() => dispatch({
                            type: ACTION_TYPES.SET_RECORD,
                            value: initState,
                        })}
                    />
                    { hasPermission(Access.EMAIL_TEMPLATE_WRITE)
                    && (
                        <TSFButton
                            action="save"
                            isWorking={isSaving}
                            isDisabled={isSaving || loading || !state.isDirty}
                            onClick={onSave}
                        />
                    ) }
                </div>
            </div>
            {state.idToDelete > 0 && (
                <DeleteAlert
                    isDeleting={isDeleting}
                    onConfirm={onDeleteEmail}
                    onCancel={() => dispatch({
                        type: ACTION_TYPES.SET_STATE_VALUES,
                        value: { idToDelete: 0 },
                    })}
                />
            )}
        </div>
    );

    if (showInPopup) {
        return (
            <Modal
                isOpen={openPopup}
                size="xl"
                toggle={onClosePopup}
            >
                <ModalHeader toggle={onClosePopup}>Seleccionar template</ModalHeader>
                <ModalBody>
                    { mainContent }
                </ModalBody>
            </Modal>
        );
    }

    return (<>{mainContent}</>);
}

EmailTemplateV2.propTypes = {
    showInPopup: PropTypes.bool,
    onClosePopup: PropTypes.func,
    openPopup: PropTypes.bool,
    onSelectedItems: PropTypes.func,
};

EmailTemplateV2.defaultProps = {
    showInPopup: false,
    onClosePopup: () => {},
    openPopup: false,
    onSelectedItems: () => {},
};

export default EmailTemplateV2;
