import React, { useContext } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import AppContext from '../../config/appContext';
import { Catalogs } from '../../enums/general';

function Profession(props) {
    const { coreCatalogs, currentLanguage, languageTitles } = useContext(AppContext);

    const {
        returnSelectedValue,
        name, value,
    } = props;

    const onChange = (selectedOption, options) => {
        returnSelectedValue(selectedOption?.value ?? 0, options);
    };

    const data = coreCatalogs.filter((c) => String(c.category.toLowerCase()) === Catalogs.PROFESSION.toLowerCase()).map((c) => (
        {
            value: c.value,
            label: c[currentLanguage],
        }));

    const selectedValue = data.filter((c) => String(c.value) === String(value))
        .map((c) => (
            {
                value: c.value,
                label: c.label,
            }
        ));

    return (
        <Select
            isClearable
            name={name}
            onChange={(selectedOption, options) => onChange(selectedOption, options)}
            options={data}
            value={selectedValue}
            placeholder={languageTitles.candidatesFiltersProfesion}
            Clearable
        />
    );
}

Profession.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number || PropTypes.string,
    returnSelectedValue: PropTypes.func.isRequired,
};

Profession.defaultProps = {
    value: null,
};

export default Profession;
