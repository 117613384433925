/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../panel/panel';
import AppContext from '../../../config/appContext';
import UserGraphql from '../../../servicesapollo/userGraphql';
import { FetchPolicy, NotificationType } from '../../../enums/general';

import UploadFile from '../uploadFile/uploadFile';

import './user.css';
import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_STATE_VALUES: 'setStateValue',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...state,
            record: action.value,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    default:
        return state;
    }
};

function User() {
    const {
        languageTitles, showNotification, loggedUser, updateLoggedUser,
        handleSetAppContentClass,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const initState = {
        errorMessage: '',
        record: {
            Name: '',
            LastName: '',
            Email: '',
        },
        isUploading: false,
    };

    const { id } = useParams();

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        record, newPassword, currentPassword,
        newPasswordConfirm, errorMessage, isUploading,
    } = state;

    const handleInputChange = (e) => {
        const { target } = e;
        const { value } = target;

        dispatch({
            type: ACTION_TYPES.SET_RECORD,
            value: {
                ...record,
                [target.name]: value,
            },
        });
    };

    const [getUser, { loading }] = useLazyQuery(UserGraphql.GET_USER, {
        onCompleted: (res) => {
            if (res.user) {
                const {
                    data, success, message, error,
                } = res.user;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: {
                        UserId: data.UserId,
                        Email: data.Email,
                        Name: data.Applicant.Name,
                        LastName: data.Applicant.LastName,
                        PictureURL: data.PictureURL,
                    },
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [saveUser, { loading: isSaving }] = useMutation(UserGraphql.UPDATE_USER, {
        onCompleted: (mutationData) => {
            if (mutationData?.updateUser) {
                const {
                    success, message, error,
                } = mutationData?.updateUser;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                showNotification(languageTitles.userSavedAndNeedLoginAgain, NotificationType.SUCCESS);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSave = (e) => {
        e.preventDefault();

        if (isSaving) return;

        if (newPassword !== newPasswordConfirm) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    errorMessage: languageTitles.userPasswordDontMatch,
                },
            });

            return;
        }

        if (newPassword === newPasswordConfirm && currentPassword === '' && newPassword !== '') {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    errorMessage: languageTitles.userProvideCurrentPassword,
                },
            });

            return;
        }

        dispatch({
            type: ACTION_TYPES.SET_STATE_VALUES,
            value: {
                errorMessage: '',
            },
        });

        saveUser({
            variables: {
                record: {
                    UserId: record.UserId,
                    Name: record.Name,
                    LastName: record.LastName,
                    Password: currentPassword,
                    NewPassword: newPassword,
                },
            },
        });
    };

    const onAfterUpload = (val) => {
        if (record.UserId === loggedUser.UserId) { // si el usuario es el mismo loggeado
            updateLoggedUser({
                ...loggedUser,
                ProfilePicture: val?.data?.FileUrl,
            });
        }

        dispatch({
            type: ACTION_TYPES.SET_RECORD,
            value: {
                ...record,
                PictureURL: val?.data?.FileUrl,
            },
        });
    };

    useEffect(() => {
        handleSetAppContentClass('p-10');
        getUser({
            variables: {
                id,
            },
        });
    }, []);

    return (
        <div className="row">
            <div className="col-lg-5 userform">
                <Panel>
                    <PanelHeader>
                        <div className="loader text-center">
                            {loading && languageTitles.loadingRecord}
                            {!loading && languageTitles.userProfileTitle}
                        </div>

                    </PanelHeader>
                    <PanelBody>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-3">
                                {languageTitles.usersImage}
                                {' '}
                                <br />
                                <br />
                                {' '}
                                {languageTitles.userImageIndication}
                            </label>
                            <div className="col-md-9">
                                <img className="thumbnailimage" alt="" src={record?.PictureURL ?? '/assets/img/user/template_image.png'} />
                                <br />
                                <UploadFile
                                    additionalInfo={{ userId: loggedUser.UserId }}
                                    graphql={UserGraphql.UPLOAD_USER_PICTURE}
                                    resultNode="uploadPictureProfile"
                                    acceptFiles=".jpg,.jpeg,.png"
                                    uploading={(val) => {
                                        dispatch({
                                            type: ACTION_TYPES.SET_STATE_VALUES,
                                            value: {
                                                isUploading: val,
                                            },
                                        });
                                    }}
                                    afterUpload={(val) => onAfterUpload(val)}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-3">{languageTitles.candidateEmail}</label>
                            <div className="col-md-9">
                                <input type="text" className="form-control m-b-5" name="Email" value={record.Email} readOnly />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-3">{languageTitles.candidateName}</label>
                            <div className="col-md-9">
                                <input type="text" className="form-control m-b-5" name="Name" value={record.Name} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="form-label col-form-label col-md-3">{languageTitles.candidateLastName}</label>
                            <div className="col-md-9">
                                <input type="text" className="form-control m-b-5" name="LastName" value={record.LastName} onChange={handleInputChange} />
                            </div>
                        </div>
                        { errorMessage ? <div className="text-danger text-center p-t-10">{errorMessage}</div> : '' }
                    </PanelBody>
                    <PanelFooter className="text-end">
                        <TSFButton
                            action="save"
                            isWorking={isSaving}
                            isDisabled={isSaving || isUploading}
                            onClick={onSave}
                        />
                    </PanelFooter>
                </Panel>
            </div>
        </div>
    );
}

export default User;
