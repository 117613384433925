import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../../config/appContext';

function SidebarProfile() {
    const {
        loggedUser,
    } = useContext(AppContext);

    return (
        <div>
            <div className="menu-profile">
                <Link to="/" className="menu-profile-link">
                    <div className="menu-profile-cover with-shadow" />
                    <div className="menu-profile-image">
                        <img src={loggedUser.ProfilePicture ? loggedUser.ProfilePicture : '../assets/img/user/user-13.jpg'} alt="" />
                    </div>
                    <div className="menu-profile-info">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                {loggedUser.FullName}
                            </div>
                        </div>
                        <small>{loggedUser.Email}</small>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default SidebarProfile;
