import React, { useContext, useState } from 'react';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import AppContext from '../../../config/appContext';
import { NotificationType, EnrollProcess } from '../../../enums/general';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';

import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';

function TagAssign(props) {
    const { showNotification, languageTitles } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const [tag, setTag] = useState('');

    const { ids, openPopup, onClosePopup } = props;

    const [saveData, { loading: isSaving }] = useLazyQuery(ApplicantsGraphql.APPLICANT_ASSIGN_TO_PROCESS, {
        onCompleted: (res) => {
            if (res.applicantAssignToProcess) {
                showNotification(languageTitles.changeTagColor, NotificationType.SUCCESS);

                onClosePopup();
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSaveData = async () => {
        if (isSaving) return;

        await saveData({
            variables: {
                ids,
                updateType: EnrollProcess.TAG,
                value: String(tag),
            },
        });
    };

    if (!ids || ids.length === 0) { return null; }

    return (
        <Modal
            size="sm"
            isOpen={openPopup}
            toggle={onClosePopup}
        >
            <ModalHeader toggle={onClosePopup}>{languageTitles.addTagColor}</ModalHeader>
            <ModalBody>
                <div className="row input-group p-t-10">
                    <div className="input-group-append">
                        <div className="btn-group m-r-5 m-b-5">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle className={`btn btn-${tag === '' ? 'default' : tag}`}>
                                    {languageTitles.chooseTagColor}
                                    {' '}
                                    <i className="fa fa-caret-down" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className="btn btn-default" onClick={() => setTag('')}>&nbsp;</DropdownItem>
                                    <DropdownItem className="btn btn-primary" onClick={() => setTag('primary')}>&nbsp;</DropdownItem>
                                    <DropdownItem className="btn btn-info" onClick={() => setTag('info')}>&nbsp;</DropdownItem>
                                    <DropdownItem className="btn btn-warning" onClick={() => setTag('warning')}>&nbsp;</DropdownItem>
                                    <DropdownItem className="btn btn-danger" onClick={() => setTag('danger')}>&nbsp;</DropdownItem>
                                    <DropdownItem className="btn btn-success" onClick={() => setTag('success')}>&nbsp;</DropdownItem>
                                    <DropdownItem className="btn btn-green" onClick={() => setTag('green')}>&nbsp;</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <TSFButton
                    action="cancel"
                    onClick={onClosePopup}
                />
                <TSFButton
                    action="save"
                    isWorking={isSaving}
                    isDisabled={tag === ''}
                    onClick={onSaveData}
                />
            </ModalFooter>
        </Modal>
    );
}

TagAssign.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    openPopup: PropTypes.bool.isRequired,
    onClosePopup: PropTypes.func.isRequired,
};

export default TagAssign;
