import React, { useState } from 'react';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import AppContext from '../../../../config/appContext';

const LanguageData = {
    Es: {
        Es: 'Español',
        Fr: 'Francés',
        En: 'Inglés',
    },
    Fr: {
        Es: 'Espagnol',
        Fr: 'Français',
        En: 'Anglais',
    },
    En: {
        Es: 'Spanish',
        Fr: 'French',
        En: 'English',
    },
};

function DropdownLanguage() {
    const [dropdownOpen, setDropDown] = useState(false);
    return (
        <AppContext.Consumer>
            {({ setLanguage, currentLanguage }) => (
                <Dropdown isOpen={dropdownOpen} toggle={() => setDropDown(!dropdownOpen)} className="navbar-item navbar-language dropdown" tag="div">
                    <DropdownToggle className="navbar-link dropdown-toggle" tag="a">
                        <span className={`flag-icon flag-icon-us me-5px flag-icon-${currentLanguage}`} title={currentLanguage} />
                        <span className="name d-none d-sm-inline">{LanguageData[currentLanguage][currentLanguage]}</span>
                        <b className="caret" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu dropdown-menu-end" tag="div">
                        <DropdownItem onClick={() => setLanguage('Es')}>
                            <span className="flag-icon flag-icon-us me-5px" title="Es" />
                            {' '}
                            {LanguageData[currentLanguage].Es}
                        </DropdownItem>
                        <DropdownItem onClick={() => setLanguage('Fr')}>
                            <span className="flag-icon flag-icon-cn me-5px" title="fr" />
                            {' '}
                            {LanguageData[currentLanguage].Fr}
                        </DropdownItem>
                        <DropdownItem onClick={() => setLanguage('En')}>
                            <span className="flag-icon flag-icon-jp me-5px" title="en" />
                            {' '}
                            {LanguageData[currentLanguage].En}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            )}
        </AppContext.Consumer>

    );
}

export default DropdownLanguage;
