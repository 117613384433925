/* eslint-disable import/no-extraneous-dependencies */
import { Store } from 'react-notifications-component';
import { NotificationType } from '../../enums/general';

const useNotification = () => {
    const showMessageApp = (notificationType, notificationMessage, notificationPosition, notificationTimer = 2000) => {
        Store.addNotification(
            {
                message: notificationMessage ?? '',
                type: notificationType,
                insert: 'top',
                container: notificationPosition || 'top-right',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: { duration: notificationTimer },
                dismissable: { click: true },
            },
        );
    };

    const showNotification = (notificationMessage, notificationType, notificationTimer = 2000) => showMessageApp(notificationType, notificationMessage, null, notificationTimer);

    const showExternalNotification = () => showMessageApp(NotificationType.INFO, '', 'Ha recibido una notification', 'bottom-left');

    return {
        showNotification,
        showExternalNotification,
    };
};

export default useNotification;
