/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useMutation } from '@apollo/client';
import React, { useEffect, useContext } from 'react';
import { Button } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import AppContext from '../../../config/appContext';

function UploadFile(props) {
    const { languageTitles } = useContext(AppContext);

    let resultUpload = {
        success: false,
    };

    const {
        additionalInfo, graphql, uploading, resultNode,
        afterUpload, acceptFiles,
    } = props;

    const [uploadPicture, { loading: isUploading }] = useMutation(graphql, {
        onCompleted: (mutationData) => {
            if (mutationData?.[resultNode]) {
                const {
                    success, message, error, data,
                } = mutationData?.[resultNode];

                resultUpload = {
                    success,
                    message,
                    error,
                    data,
                };

                afterUpload(resultUpload);
            }
        },
        onError: () => {
            resultUpload.error = 'Sucedio un error desconocido al subir el archivo';
        },
    });

    function onChangeImage(e) {
        const { validity, files } = e.target;

        if (validity.valid) {
            uploadPicture({
                variables: {
                    file: files[0],
                    ...additionalInfo,
                },
            });
        }
    }

    const newId = `fileinput${uuidv4()}`;

    const openUploadSection = () => {
        const fileInputToClick = document.getElementById(newId);
        fileInputToClick.click();
    };

    useEffect(() => {
        uploading(isUploading);
    }, [isUploading]);

    return (
        <>
            <Button
                color="info"
                className="me-1"
                onClick={openUploadSection}
            >
                { !isUploading && <i className="fa fa-file fa-fw" />}
                { isUploading && <i className="fa fa-spinner fa-spin fa-fw" /> }
                { !isUploading && languageTitles.documentRepositoryBtnChooseFile }
                { isUploading && languageTitles.documentRepositoryBtnUploadingFile }
            </Button>
            <input
                type="file"
                className="hide"
                accept={acceptFiles}
                onChange={onChangeImage}
                name="image"
                id={newId}
            />
        </>
    );
}

UploadFile.propTypes = {
    uploading: PropTypes.func,
    resultNode: PropTypes.string.isRequired,
    afterUpload: PropTypes.func,
    acceptFiles: PropTypes.string,
};

UploadFile.defaultProps = {
    uploading: () => {},
    afterUpload: () => {},
    acceptFiles: '.pdf',
};

export default UploadFile;
