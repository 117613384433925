import React, {
    useEffect, useContext, useReducer,
} from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Panel, PanelHeader, PanelBody } from '../../panel/panel';
import SearchField from '../../search-field/searchfield';
import AppContext from '../../../config/appContext';
import { FetchPolicy, NotificationType, TestStatus } from '../../../enums/general';
import TestApplicantGraphql from '../../../servicesapollo/testApplicantGraphql';
import TestApplicantMapping from '../../../mapping/testApplicantMapping';

import Access from '../../../enums/permissions';
import useDateHelper from '../../../helpers/dateHelper';
import ErrorPage from '../extraPages/error';

import './psycotest.css';
import TSFButton from '../../actionButtons/tsfButton';
import FullContent from '../../layout/content/fullContent';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

function PsycoTestList() {
    const {
        showNotification, hasPermission, languageTitles,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    const { formatDate } = useDateHelper();

    if (!hasPermission(Access.TEST_READ_LIST)) return <ErrorPage />;

    const initState = {
        records: [],
        total: 0,
        applicantsByCompany: [],
        showAlertAction: false,
        whichAction: '',
        actionTitle: '',
        actionDescription: '',
        itemSelected: null,
        // params
        search: '',
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        records, whichAction, actionTitle, actionDescription,
        itemSelected, showAlertAction, search,
    } = state;

    const dispatchEvent = (value, type = ACTION_TYPES.SET_STATE_VALUES) => {
        dispatch({
            type,
            value,
        });
    };

    const [getData, { loading, refetch }] = useLazyQuery(TestApplicantGraphql.GET_APPLICANTS_LIST_TEST, {
        onCompleted: (res) => {
            if (res.testApplicants) {
                const {
                    data, total, success, message, error,
                } = res.testApplicants;

                const mappedData = TestApplicantMapping.mapApplicantTest(data);

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatchEvent({
                    records: mappedData,
                    total,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const callGetData = () => {
        getData({
            variables: {
                usePaging: false,
                search,
            },
        });
    };

    const onActionCancel = () => {
        dispatchEvent({
            showAlertAction: false,
            whichAction: '',
            actionTitle: '',
            actionDescription: '',
            itemSelected: null,
        });
    };

    const [deleteTestApplicant, { loading: deleting }] = useMutation(TestApplicantGraphql.DELETE_TEST_APPLICANT, {
        onCompleted: (mutationData) => {
            const { success, message, error } = mutationData?.deleteTestApplicant;

            if (!success) {
                if (message) {
                    showNotification(message, NotificationType.INFO);
                    return;
                }

                showNotification(
                    error?.length > 0 ? error : languageTitles.errorWhenDelete,
                    NotificationType.DANGER,
                );
                return;
            }

            showNotification(languageTitles.successWhenDeleteTest, NotificationType.SUCCESS);

            onActionCancel();

            callGetData();
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onAction = (item, action) => {
        switch (action) {
        case 'delete':
            dispatchEvent({
                showAlertAction: true,
                whichAction: 'delete',
                actionTitle: languageTitles.deleteTestForApplicant,
                actionDescription: languageTitles.askDeleteTest,
                itemSelected: item,
            });
            break;
        default:
            break;
        }
    };

    const onActionConfirm = () => {
        dispatchEvent({
            showAlertAction: false,
        });

        if (whichAction === 'delete') {
            deleteTestApplicant({
                variables: {
                    id: itemSelected.TestApplicantId,
                },
            });
        }
    };

    useEffect(() => {
        if (search) callGetData();
    }, [search]);

    useEffect(() => {
        callGetData();
    }, []);

    return (
        <FullContent>
            <Panel>
                <PanelHeader>
                    <div className="panel-heading-btn justify-content-end">
                        <div className="col-md-5 me-2">
                            <SearchField
                                returnSearchValue={(val) => dispatchEvent({
                                    search: val,
                                })}
                                returnOnClear={() => dispatchEvent({
                                    search: '',
                                })}
                            />
                        </div>
                        <div className="d-flex me-2">
                            <TSFButton
                                action="refresh"
                                isWorking={loading}
                                onClick={() => refetch()}
                            />
                        </div>
                    </div>
                </PanelHeader>
                <PanelBody>
                    <PerfectScrollbar className="h-100" options={{ suppressScrollX: true }}>
                        <div className="table-responsive">
                            <table className="table table-striped mb-0 align-middle">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{languageTitles.psycoTestTableColumnName}</th>
                                        <th>{languageTitles.psycoTestTableColumnEmail}</th>
                                        <th>{languageTitles.psycoTestTableColumnTest}</th>
                                        <th>{languageTitles.psycoTestTableColumnActive}</th>
                                        <th>{languageTitles.psycoTestTableColumnExpiration}</th>
                                        <th className="text-center">{languageTitles.psycoTestTableColumnStatus}</th>
                                        <th width="1%">{languageTitles.psycoTestTableColumnActions}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        records.map((item, i) => {
                                            let statusColor = 'primary';
                                            if (item.Status.toLowerCase() === TestStatus.COMPLETADA.toLowerCase()) statusColor = 'green';
                                            if (item.Status.toLowerCase() === TestStatus.VENCIDA.toLowerCase()) statusColor = 'danger';

                                            return (
                                                <tr key={item.TestApplicantId}>
                                                    <td>{i + 1}</td>
                                                    <td>
                                                        {item.Name}
                                                        {' '}
                                                        {item.LastName}
                                                    </td>
                                                    <td>{item.Email}</td>
                                                    <td>{item.TestName}</td>
                                                    <td>
                                                        {Number(item.Active) === 1 ? 'Activa' : 'Inactiva'}
                                                    </td>
                                                    <td>{formatDate(item.ExpirationDate)}</td>
                                                    <td className="text-center fs-15px">
                                                        <span className={`badge bg-${statusColor} rounded-pill`}>{item.Status}</span>
                                                    </td>
                                                    <td className="with-btn text-nowrap">
                                                        {hasPermission(Access.TEST_ANALYSE)
                                                && (
                                                    <Link to={`test/${item.TypeTest === 1 ? 'termanresult' : 'discresult'}/${item.TestApplicantId}`} className="">
                                                        <Button
                                                            color="info"
                                                            size="sm"
                                                            className="me-1"
                                                            title="Analizar prueba"
                                                        >
                                                            <i className="fa fa-chart-line fa-fw" />
                                                            {languageTitles.psycoTestBtnAnalize}
                                                        </Button>
                                                    </Link>
                                                )}
                                                        {hasPermission(Access.TEST_DELETE)
                                                && (
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        title={languageTitles.deleteTitle}
                                                        className={`me-1 ${deleting ? 'disabled' : ''}`}
                                                        onClick={() => onAction(item, 'delete')}
                                                    >
                                                        {
                                                            deleting && itemSelected.TestApplicantId === item.TestApplicantId && whichAction === 'delete'
                                                                ? <i className="fa fa-spinner fa-spin" /> : <i className="fa fa-trash-alt" />
                                                        }
                                                    </Button>
                                                )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </PerfectScrollbar>
                </PanelBody>
            </Panel>
            {showAlertAction
            && (
                <SweetAlert
                    info
                    showCancel
                    confirmBtnText={languageTitles.sweetAlertConfirmBtnText}
                    cancelBtnText={languageTitles.sweetAlertDeleteCancelBtnText}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    title={actionTitle}
                    onConfirm={onActionConfirm}
                    onCancel={onActionCancel}
                >
                    {actionDescription}
                </SweetAlert>
            ) }

        </FullContent>
    );
}

export default PsycoTestList;
