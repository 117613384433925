/* eslint-disable no-undef */
import React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';

function BackHistory() {
    const history = useHistory();

    return (
        <Button
            color="info"
            onClick={(e) => { e.preventDefault(); history.goBack(); }}
        >
            <i className="fa fa-arrow-left" />
        </Button>
    );
}

export default BackHistory;
