/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useReducer } from 'react';
import CountdownTimer from 'react-component-countdown-timer';
import { v4 as uuidv4 } from 'uuid';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { disctest } from './datatest';
import {
    Panel, PanelBody, PanelHeader, PanelFooter,
} from '../../panel/panel';
import AppContext from '../../../config/appContext';

import { NotificationType, TestStatus } from '../../../enums/general';
import TestApplicantGraphql from '../../../servicesapollo/testApplicantGraphql';

import './psycotest.css';
import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_INITIAL_TEST: 'setInitialTest',
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
    SET_COMPLETE_TASK: 'setCompleteTask',
    SET_NUMBER_TEST: 'setNumberTest',
    SET_ANSWER_QUESTION: 'setAnswerQuestion',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_INITIAL_TEST: {
        const {
            JsonTest, LastTestTab, LastQuestion, Status, Active, ExpirationDate,
        } = action.value;

        let currentData = disctest;
        let testNumber = 0;
        let questionNumber = 0;

        if (JsonTest) {
            currentData = JSON.parse(JsonTest);
            testNumber = LastTestTab || 0;
            questionNumber = LastQuestion || 0;
        } else {
            testNumber = LastTestTab;
        }

        return {
            ...state,
            testNumber,
            questionNumber,
            data: currentData,
            Status,
            Active,
            ExpirationDate,
        };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_COMPLETE_TASK: {
        if (action.saveData) action.saveData(...state.data, TestStatus.COMPLETADA);

        return {
            ...state,
            hasFinished: true,
        };
    }
    case ACTION_TYPES.SET_NUMBER_TEST: {
        const { testNumber, questionNumber, started } = action.value;

        const currentData = state.data;
        currentData[testNumber - 1].Started = started;
        currentData[testNumber - 1].LastQuestion = questionNumber;

        if (action.saveData) action.saveData(currentData, TestStatus.PROCESO);

        return {
            ...state,
            testNumber,
            questionNumber,
            data: currentData,
        };
    }
    case ACTION_TYPES.SET_ANSWER_QUESTION: {
        let currentData = state.data;
        let qAnswered = 0;
        let qNoAnswered = 0;
        let qfounded = false;

        const { question, optionSelected, operation } = action.value;

        if (currentData === null) currentData = disctest;

        for (let i = 0; i < currentData.length; i += 1) {
            const element = currentData[i];
            for (let j = 0; j < element.Data.length; j += 1) {
                const qt = element.Data[j];

                if (qt.IdentityId === question.IdentityId) {
                    if (operation === 'less') qt.UserAnswerLess = optionSelected;
                    else qt.UserAnswerMore = optionSelected;

                    qt.Answered = !!((qt.UserAnswerMore !== '' && qt.UserAnswerLess !== ''));
                    qfounded = true;
                    break;
                }
            }

            if (qfounded) break;
        }

        // updating Stats
        for (let i = 0; i < currentData.length; i += 1) {
            const element = currentData[i];
            for (let j = 0; j < element.Data.length; j += 1) {
                const qt = element.Data[j];

                if (qt.IdentityId < state.questionNumber) {
                    qAnswered += qt.Answered ? 1 : 0;
                    qNoAnswered += qt.Answered ? 0 : 1;
                } else {
                    qfounded = true;
                    break;
                }
            }

            if (qfounded) break;
        }

        const currentCounterQuestion = {
            ...state.counterQuestion,
            Answered: qAnswered,
            NoAnswered: qNoAnswered,
            Stats: `${((qAnswered + qNoAnswered) * 100) / state.counterQuestion.Total}`,
        };

        if (action.saveData) action.saveData(currentData, TestStatus.PROCESO);

        return {
            ...state,
            data: currentData,
            counterQuestion: currentCounterQuestion,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

function Disctest() {
    const { showNotification } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    const history = useHistory();

    const { id } = useParams();

    const initState = {
        counterQuestion: {
            Total: 28,
            Answered: 0,
            NoAnswered: 0,
            Pending: 0,
            Stats: '0',
        },
        testNumber: 0,
        questionNumber: 0,
        hasFinished: false,
        data: disctest,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const {
        counterQuestion,
        testNumber,
        questionNumber,
        hasFinished,
        data,
        Status,
    } = state;

    const verifyComplete = (status) => {
        if (status?.toLowerCase() === TestStatus.COMPLETADA.toLowerCase() && !hasFinished) {
            alert('Esta prueba ya esta completada');
            history.push('/home');
        }
    };

    const [saveCurrentJson] = useMutation(TestApplicantGraphql.SAVE_TEST, {
        onCompleted: (mutationData) => {
            if (mutationData?.updateTestApplicant) {
                const {
                    success, message, error, data: result,
                } = mutationData?.updateTestApplicant;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSaveTest,
                        NotificationType.DANGER,
                    );
                    return;
                }

                verifyComplete(result.Status);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSave = (currentTest, status) => {
        saveCurrentJson({
            variables: {
                record: {
                    TestApplicantId: Number(id),
                    Status: status,
                    LastTestTab: testNumber,
                    LastQuestion: questionNumber,
                    JsonTest: JSON.stringify(currentTest),
                },
            },
        });
    };

    const [getCurrentTest, { loading }] = useLazyQuery(TestApplicantGraphql.GET_CURRENT_TEST, {
        onCompleted: (mutationData) => {
            if (mutationData?.testApplicant) {
                const {
                    success, message, error, data: result,
                } = mutationData?.testApplicant;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                verifyComplete(result.Status);

                if (result?.Status?.toLowerCase() === TestStatus.PROCESO.toLowerCase()) {
                    onSave(JSON.parse(result.JsonTest), TestStatus.COMPLETADA);
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_INITIAL_TEST,
                    value: result,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const [startTest] = useMutation(TestApplicantGraphql.SAVE_TEST, {
        onCompleted: (mutationData) => {
            if (mutationData?.updateTestApplicant) {
                const {
                    success, message, error, data: result,
                } = mutationData?.updateTestApplicant;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorToStartTest,
                        NotificationType.DANGER,
                    );
                    return;
                }

                verifyComplete(result.Status);

                dispatch({
                    type: ACTION_TYPES.SET_INITIAL_TEST,
                    value: result,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onStartTest = () => {
        startTest({
            variables: {
                record: {
                    TestApplicantId: Number(id),
                    Status: TestStatus.PROCESO,
                    LastQuestion: 1,
                    LastTestTab: 1,
                },
            },
        });
    };

    const setNumberTestTimer = (tn) => {
        if (tn > 1) {
            dispatch({
                type: ACTION_TYPES.SET_COMPLETE_TASK,
                saveData: onSave,
            });
        } else {
            dispatch({
                type: ACTION_TYPES.SET_NUMBER_TEST,
                value: {
                    testNumber: tn,
                    questionNumber: 0,
                    started: false,
                },
                saveData: onSave,
            });
        }
    };

    const setQuestionNumberTest = (tn, qn) => {
        if (tn > 1) {
            dispatch({
                type: ACTION_TYPES.SET_COMPLETE_TASK,
                saveData: onSave,
            });
        } else if (testNumber === tn) {
            dispatch({
                type: ACTION_TYPES.SET_NUMBER_TEST,
                value: {
                    testNumber: tn,
                    questionNumber: qn,
                    started: true,
                },
                saveData: onSave,
            });
        } else {
            dispatch({
                type: ACTION_TYPES.SET_NUMBER_TEST,
                value: {
                    testNumber: tn,
                    questionNumber: 0,
                    started: false,
                },
                saveData: onSave,
            });
        }
    };

    const answerQuestion = (question, optionSelected, operation) => {
        dispatch({
            type: ACTION_TYPES.SET_ANSWER_QUESTION,
            value: {
                question,
                optionSelected,
                operation,
            },
            saveData: onSave,
        });
    };

    const answerQuestionLess = (question, optionSelected) => {
        answerQuestion(question, optionSelected, 'less');
    };

    useEffect(() => {
        getCurrentTest({
            variables: {
                id: Number(id),
            },
        });
    }, []);

    if (Status?.toLowerCase() === TestStatus.COMPLETADA.toLowerCase() || hasFinished) {
        return (
            <Panel theme="success">
                <PanelHeader showBackHistory={false}>Prueba Finalizada</PanelHeader>
                <PanelBody className="text-center">
                    <p>Agradacemos de parte del grupo de TSF haber completado la prueba.</p>
                    <br />
                    <p>Un correo ha sido enviado al personal de TSF para evaluar tu prueba.</p>
                    <br />
                    <p>Continuaremos con el proceso de tu aplicación.</p>
                    <br />
                    {!loading
                    && (
                        <Link to="/inicio" className="btn btn-warning btn-lg">
                            Finalizado
                        </Link>
                    )}
                </PanelBody>
            </Panel>
        );
    }

    if (questionNumber === 0 && testNumber === 0) {
        return (
            <Panel>
                <PanelHeader showBackHistory={false}>Iniciar Prueba</PanelHeader>
                <PanelBody className="text-center">
                    <p>Tu has sido invitado a realizar algunas pruebas psicometricas.</p>
                    <p>Esto te brinda la oportunidad de demostrar tus habilidades de una manera justa y objetiva.</p>
                    <p>Verifica tu conexion a internet, que sea estable para no afectar el proceso de realizacion de la prueba.</p>
                    <p>
                        No refresques ni recargues la pagina durante el proceso de la prueba porque va a ser finalizada
                        <br />
                        tu progreso se vera afectado y el resultado no sera el optimo y deseado para tu aplicacion.
                    </p>
                    <p>Relajate y sientete tranquilo mientras realizas la prueba, lee las instrucciones.</p>
                    <p>Presione el boton Empezar para iniciar la prueba</p>
                    {!loading
                    && (
                        <Button
                            color="warning"
                            className="btn-lg"
                            onClick={onStartTest}
                        >
                            <i className="fa fa-clock fa-fw" />
                            Iniciar
                        </Button>
                    )}
                </PanelBody>
            </Panel>
        );
    }

    const currentTest = data[testNumber - 1];
    const currentQuestion = currentTest.Data.find((c) => c.IdentityId === questionNumber);

    return (
        <Panel>
            <PanelHeader showBackHistory={false}>
                { !currentTest.Started
                    && (
                        <div className="p-10 questionInstruction text-center">{currentTest.Instruction}</div>
                    )}
                { currentTest.Started && (
                    <CountdownTimer
                        count={currentTest.Timer}
                        // count={50000}
                        className="inline left"
                        border
                        showTitle
                        hideHours
                        hideDay
                        responsive
                        onEnd={() => setNumberTestTimer(Number(currentTest.TestNumber) + 1)}
                    />
                )}
            </PanelHeader>
            <div className="panel-toolbar disctest">
                {currentTest.Started && currentQuestion.Options.map((opt, y) => {
                    const guid = uuidv4();

                    return (
                        <div className="row" key={guid}>
                            <div className="form-group col-md-8 p-20 fs-20 bold">
                                {' '}
                                {opt[y + 1]}
                                {' '}
                            </div>

                            <div
                                className={`form-check p-20 col-md-2 ${Number(currentQuestion.UserAnswerMore) === Number(y + 1) ? 'selected' : ''}`}
                                onChange={() => answerQuestion(currentQuestion, y + 1)}
                            >
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`cssRadio${guid}`}
                                    checked={Number(currentQuestion.UserAnswerMore) === Number(y + 1)}
                                    onChange={() => null}
                                />
                                <label className="form-check-label" htmlFor={`cssRadio${guid}`}>Mas</label>
                            </div>
                            <div
                                className={`form-check p-20 col-md-2 ${Number(currentQuestion.UserAnswerLess) === Number(y + 1) ? 'selected' : ''}`}
                                onChange={() => answerQuestionLess(currentQuestion, y + 1)}
                            >
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`cssRadioless${guid}`}
                                    checked={Number(currentQuestion.UserAnswerLess) === Number(y + 1)}
                                    onChange={() => null}
                                />
                                <label className="form-check-label" htmlFor={`cssRadioless${guid}`}>Menos</label>
                            </div>
                        </div>
                    );
                })}
            </div>
            <PanelFooter className="text-end">
                <div className="progress mb-2">
                    <div className="progress-bar bg-warning fs-10px fw-bold" style={{ width: `${counterQuestion?.Stats}%` }} />
                </div>
                <div className="text-start">
                    <h3 className="bold">
                        {`PC ${counterQuestion.Answered} | PNC ${counterQuestion.NoAnswered} |
                        PP ${counterQuestion.Total - counterQuestion.Answered - counterQuestion.NoAnswered} / ${counterQuestion.Total}`}
                    </h3>
                </div>
                {!currentTest.Started && (
                    <TSFButton
                        action="custom"
                        btnColor="warning"
                        label="Empezar Prueba"
                        onClick={() => setQuestionNumberTest(currentTest.TestNumber, currentTest.Data[0].IdentityId)}
                    />
                )}

                {currentTest.Started && (
                    <TSFButton
                        action="custom"
                        btnColor="warning"
                        label="Siguiente"
                        onClick={() => setQuestionNumberTest(
                            currentTest.TotalQuestions === currentQuestion.Number ? currentTest.TestNumber + 1 : currentTest.TestNumber, // este es el valor de la prueba actual o la siguiente
                            currentQuestion.IdentityId + 1, // este es valor de la siguiente pregunta
                        )}
                    />
                )}
            </PanelFooter>
        </Panel>
    );
}

export default Disctest;
