/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BackHistory from '../back-history/back-history';

const PanelStat = React.createContext();

function Panel(props) {
    const {
        collapse, isCollapse, className, theme, children,
    } = props;

    const initState = {
        expand: false,
        collapse: collapse || false,
        reload: false,
        remove: false,
    };

    const [currentState, setCurrentState] = useState(initState);

    const toggleExpand = () => {
        setCurrentState({
            ...currentState,
            expand: !currentState.expand,
        });
    };

    const toggleRemove = () => {
        setCurrentState({
            ...currentState,
            remove: !currentState.remove,
        });
    };

    const toggleCollapse = () => {
        setCurrentState({
            ...currentState,
            collapse: !currentState.collapse,
        });

        isCollapse(!currentState.collapse);
    };

    const toggleReload = () => {
        if (currentState !== true) {
            setCurrentState({
                ...currentState,
                reload: true,
            });

            setTimeout(() => {
                setCurrentState({
                    ...currentState,
                    reload: false,
                });
            }, 2000);
        }
    };
    return (
        <PanelStat.Provider value={{
            ...currentState,
            toggleExpand,
            toggleRemove,
            toggleCollapse,
            toggleReload,
        }}
        >
            {!currentState.remove && (
                <div
                    className={`panel panel-${theme}
                            ${currentState.expand ? 'panel-expand ' : ' '}${currentState.reload ? 'panel-loading ' : ' '}
                            ${className}`}
                    style={{
                        flex: '1 1',
                    }}
                >
                    { children }
                </div>
            )}
        </PanelStat.Provider>
    );
}

Panel.propTypes = {
    theme: PropTypes.string,
    className: PropTypes.string,
    collapse: PropTypes.bool,
    isCollapse: PropTypes.func,
};

Panel.defaultProps = {
    theme: 'inverse',
    className: '',
    collapse: false,
    isCollapse: () => {},
};

function PanelHeader(props) {
    const {
        className, children, showButton, showBackHistory,
    } = props;

    return (
        <div className={`panel-heading ${className}`}>
            {showBackHistory && <BackHistory /> }
            <h4 className="panel-title">{ children }</h4>
            {(showButton
                && (
                    <PanelStat.Consumer>
                        {({
                            toggleCollapse, collapse,
                        }) => (
                            <div className="panel-heading-btn justify-content-end">
                                {/* <button className="btn btn-xs btn-icon btn-circle btn-default" onClick={toggleExpand}><i className="fa fa-expand"></i></button>&nbsp;&nbsp; */}
                                {/* <button className="btn btn-xs btn-icon btn-circle btn-success" onClick={toggleReload}><i className="fa fa-redo"></i></button>&nbsp;&nbsp; */}
                                <button
                                    type="button"
                                    className="btn btn-xs btn-icon btn-circle btn-warning"
                                    onClick={toggleCollapse}
                                >
                                    <i className={`fa ${collapse ? 'fa-angle-double-down fw' : 'fa-angle-double-up fw'}`} />
                                </button>
                            </div>
                        )}
                    </PanelStat.Consumer>
                )
            )}
        </div>

    );
}

PanelHeader.propTypes = {
    className: PropTypes.string,
    showButton: PropTypes.bool,
    showBackHistory: PropTypes.bool,
    // title: PropTypes.string,
};

PanelHeader.defaultProps = {
    className: '',
    showButton: false,
    showBackHistory: true,
    // title: '',
};

function PanelBody(props) {
    const { className, children } = props;

    return (
        <PanelStat.Consumer>
            {({ collapse, reload }) => (
                <div style={{ overflow: 'hidden' }} className={`panel-body ${collapse ? 'd-none ' : ' '}${className}`}>
                    { children }

                    {(reload
                        && (
                            <div className="panel-loader">
                                <span className="spinner spinner-sm" />
                            </div>
                        )
                    )}
                </div>
            )}
        </PanelStat.Consumer>
    );
}

PanelBody.propTypes = {
    className: PropTypes.string,
};

PanelBody.defaultProps = {
    className: '',
};

function PanelFooter(props) {
    const { className, children } = props;
    return (
        <div className={`panel-footer ${className}`}>
            { children }
        </div>
    );
}

PanelFooter.propTypes = {
    className: PropTypes.string,
};

PanelFooter.defaultProps = {
    className: '',
};

export {
    Panel, PanelHeader, PanelBody, PanelFooter,
};
