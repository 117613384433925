import { TestStatus } from '../enums/general';

export default class TestApplicantMapping {
    static mapApplicantTest(records) {
        if (records) {
            return records.map((data) => ({
                TestApplicantId: data.TestApplicantId,
                Status: (new Date() > new Date(data.ExpirationDate) && data.Status.toLowerCase() !== TestStatus.COMPLETADA.toLowerCase()) ? 'Vencida' : data.Status,
                TestName: data.TypeTest === 1 ? 'TERMAN' : 'DISC',
                TypeTest: data.TypeTest,
                ExpirationDate: data.ExpirationDate,
                Active: data.Active,
                ApplicantId: data?.Applicant?.ApplicantId,
                Email: data?.Applicant?.Email,
                Name: data?.Applicant?.Name,
                LastName: data?.Applicant?.LastName,
            }));
        }

        return [];
    }
}
