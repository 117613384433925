/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useContext, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import {
    ButtonGroup,
} from 'reactstrap';
import MultiSelect from 'react-multi-select-component';
import DateTime from 'react-datetime';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    Panel, PanelHeader, PanelBody,
} from '../../panel/panel';
import Country from '../../country/country-dropdown';
import Catalog from '../../catalog/catalog-dropdown';
import Profession from '../../profession/profession-dropdown';
import AppContext from '../../../config/appContext';
import ApplicantsGraphql from '../../../servicesapollo/applicantsGraphql';
import {
    Catalogs, FetchPolicy, LineActions, NotificationType,
} from '../../../enums/general';

import useLanguage from '../../hooks/useLanguage';

import 'react-datetime/css/react-datetime.css';
import ErrorPage from '../extraPages/error';
import Access from '../../../enums/permissions';
import ContentForProcess from '../../layout/content/contentForProcess';
import useDateHelper from '../../../helpers/dateHelper';
import TSFButton from '../../actionButtons/tsfButton';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
    ADD_STUDIES: 'addStudies',
    ADD_EXPERIENCE: 'addExperience',
    REMOVE_STUDIES: 'removeStudies',
    REMOVE_EXPERIENCE: 'removeExperience',
    UPDATE_STUDIES: 'updateStudies',
    UPDATE_EXPERIENCE: 'updateExperience',
    UPDATE_SKILL: 'updateSkill',
    SET_FILES_AFTER_DELETE: 'setFilesAfterDelete',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        return {
            ...state,
            record: action.value,
        };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        const { value } = action;
        return { ...state, record: value, isDirty: true };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.ADD_STUDIES: {
        const { record } = state;

        const newStudy = {
            StudiesId: state.indexStudies - 1,
            ApplicantId: record.ApplicantId,
            StudyCenter: '',
            StartDate: new Date(),
            EndDate: new Date(),
            CertificateObtained: '',
            CertificateObtainedType: '',
            IsDirty: false,
            Status: LineActions.NEW,
        };

        record.Studies.push(newStudy);

        return {
            ...state,
            record,
            indexStudies: state.indexStudies - 1,
            isDirty: true,
        };
    }
    case ACTION_TYPES.ADD_EXPERIENCE: {
        const { record } = state;

        const newExperience = {
            ExperienceId: state.indexExperience - 1,
            ApplicantId: record.ApplicantId,
            CompanyName: '',
            StartDate: new Date(),
            EndDate: new Date(),
            JobTitle: '',
            DescriptionJob: '',
            IsDirty: false,
            Status: LineActions.NEW,
        };

        record.Experiences.push(newExperience);

        return {
            ...state,
            record,
            indexExperience: state.indexExperience - 1,
            isDirty: true,
        };
    }
    case ACTION_TYPES.REMOVE_STUDIES: {
        const { record } = state;

        const newArr = record.Studies.map((exp) => {
            const item = { ...exp };
            if (Number(exp.StudiesId) === Number(action.value)) {
                item.Status = LineActions.DELETED;
                item.IsDirty = true;
            }
            return item;
        });

        record.Studies = newArr;

        return {
            ...state,
            record,
            isDirty: true,
        };
    }
    case ACTION_TYPES.REMOVE_EXPERIENCE: {
        const { record } = state;

        const newArr = record.Experiences.map((exp) => {
            const item = { ...exp };
            if (Number(exp.ExperienceId) === Number(action.value)) {
                item.Status = LineActions.DELETED;
                item.IsDirty = true;
            }
            return item;
        });

        record.Experiences = newArr;

        return {
            ...state,
            record,
            isDirty: true,
        };
    }
    case ACTION_TYPES.UPDATE_STUDIES: {
        const { record } = state;

        const newArr = record.Studies.map((exp) => {
            const item = { ...exp };
            if (Number(exp.StudiesId) === Number(action.index)) {
                item[action.fieldName] = action.value;
                item.Status = exp.StudiesId > 0 ? LineActions.UPDATED : LineActions.NEW;
                item.IsDirty = true;
            }
            return item;
        });

        record.Studies = newArr;

        return {
            ...state,
            record,
            isDirty: true,
        };
    }
    case ACTION_TYPES.UPDATE_EXPERIENCE: {
        const { record } = state;

        const newArr = record.Experiences.map((exp) => {
            const item = { ...exp };
            if (Number(exp.ExperienceId) === Number(action.index)) {
                item[action.fieldName] = action.value;
                item.Status = exp.ExperienceId > 0 ? LineActions.UPDATED : LineActions.NEW;
                item.IsDirty = true;
            }
            return item;
        });

        record.Experiences = newArr;

        return {
            ...state,
            record,
            isDirty: true,
        };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.UPDATE_SKILL: {
        const { record } = state;

        const index = record.JobPositions.indexOf(action.value);

        if (index !== -1) {
            record.JobPositions.splice(index, 1);
        } else {
            record.JobPositions.push(action.value);
        }

        return {
            ...state,
            record,
            isDirty: true,
        };
    }
    case ACTION_TYPES.SET_FILES_AFTER_DELETE: {
        const newArr = state.record.Files.filter((c) => c.FileId !== state.idDeleting);

        return {
            ...state,
            record: {
                ...state.record,
                Files: newArr,
            },
            idDeleting: 0,
        };
    }
    default:
        return state;
    }
};

function ApplicantProcessed() {
    const { id } = useParams();

    const {
        showNotification, languageTitles, UserType, loggedUser, currentLanguage, coreCatalogs,
        hasPermission, currentLocaleDate,
    } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();

    if (!hasPermission(Access.APPLICANT_PROCESS_APPLICATION)) return <ErrorPage />;

    const { translate } = useLanguage();

    translate('hola mundo');

    const jobPositions = coreCatalogs.filter((c) => String(c.category.toLowerCase()) === Catalogs.POSITION.toLowerCase()).map((c) => (
        {
            value: c.value,
            label: c[currentLanguage],
        }));

    const initState = {
        itemIdClick: 0,
        itemOpen: false,
        activeTab: '1',
        isEditing: false,
        isDirty: false,
        generatingPdf: false,
        indexStudies: 0,
        indexFiles: 0,
        indexExperience: 0,
        // isUploading: false,
        idUploading: '',
        isUploadingPictureProfile: false,
        idDeleting: 0,
        record: {
            ApplicantId: 0,
            Name: loggedUser.Name,
            LastName: loggedUser.LastName,
            AddressLineOne: '',
            AddressLineTwo: '',
            CountryId: '',
            Country: '',
            City: '',
            Town: '',
            PostalCode: '',
            Birthday: '',
            Age: 0,
            IdentificationCode: '',
            MaritalStatusId: -1,
            GenderId: -1,
            Childrens: 0,
            HasPassportId: -1,
            PassportNumber: '',
            ExpirationPassport: '',
            PassportUrl: '',
            CountryVisitedOneId: -1,
            CountryVisitedOne: '',
            CountryVisitedOneStartDate: '',
            CountryVisitedOneEndDate: '',
            CountryVisitedTwoId: -1,
            CountryVisitedTwo: '',
            CountryVisitedTwoStartDate: '',
            CountryVisitedTwoEndDate: '',
            HasDriverLicenseId: -1,
            DriverLicenseCategory: '',
            PhoneNumberOne: '',
            PhoneNumberTwo: '',
            Email: loggedUser.Email,
            Profession: '',
            ExperienceYears: '',
            CurriculumUrl: '',
            SubmissionDate: '',
            ModifiedDate: '',
            ModifedByUserId: '',
            EnglishLevelId: -1,
            FrenchLevelId: -1,
            ResidentCountryId: -1,
            ResidentCountry: '',
            ResidentCity: '',
            ResidentTown: '',
            AboutMe: '',
            UserId: 0,
            LicenceUrl: '',
            DiplomaBachillerUrl: '',
            TituloUniversitario1: '',
            TituloUniversitario2: '',
            CertificadoTecnico1: '',
            CertificadoTecnico2: '',
            CertificadoTecnico3: '',
            CertificadoTecnico4: '',
            PictureURL: loggedUser.ProfilePicture,
            Experiences: [{
                Status: 'Saved',
                CompanyName: '',
                ExperienceId: 0,
                StartDate: new Date(),
                EndDate: new Date(),
                JobTitle: '',
                DescriptionJob: '',
                IsDirty: false,
            }],
            Studies: [{
                Status: 'Saved',
                StudyCenter: '',
                StudiesId: 0,
                StartDate: new Date(),
                EndDate: new Date(),
                CertificateObtained: '',
                CertificateObtainedType: '',
                IsDirty: false,
            }],
            JobPositionIds: [],
            JobPositions: [],
            Files: [],
        },

    };

    const [state, dispatch] = useReducer(reducer, initState);

    const { convertToDate } = useDateHelper();

    const {
        record, isEditing, isDirty,
    } = state;

    const [getApplicant, { loading: isLoading }] = useLazyQuery(ApplicantsGraphql.GET_APPLICANT, {
        onCompleted: (res) => {
            if (res.applicant) {
                const {
                    data, success, message, error,
                } = res.applicant;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                const currentFiles = res?.files?.data;

                const mapData = {
                    ...data,
                    JobPositionIds: data?.JobPositionIds?.split(',').map(Number),
                    JobPositions: data?.JobPositions?.split('|').map(Number),
                    Files: currentFiles,
                };

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: mapData,
                });
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    // const { data: jobDetails } = useQuery(JobPositionGraphql.GET_JOB_POSITION_FOR_SKILLS_LIST, {
    //     variables: {
    //         usePaging: false,
    //     },
    // });

    const callGetApplicant = () => {
        getApplicant({
            variables: {
                id: Number(id),
                filters: [{ fieldName: 'ApplicantId', value: String(id) }],
            },
        });
    };

    const mutationQuery = record.ApplicantId > 0 ? ApplicantsGraphql.UPDATE_APPLICANT : ApplicantsGraphql.CREATE_APPLICANT;
    const [saveRecord, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (res) => {
            if (res?.addApplicant || res?.updateApplicant) {
                const {
                    success, message, error,
                } = res?.addApplicant || res?.updateApplicant;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        isEditing: false,
                        isDirty: false,
                        isSaving: false,
                    },
                });

                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS);

                callGetApplicant();
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSave = async () => {
        record.UserId = record.ApplicantId > 0 ? record.UserId : loggedUser.UserId;

        const recordToSave = {
            ApplicantId: record.ApplicantId,
            UserId: record.UserId,
            Name: record.Name,
            LastName: record.LastName,
            AddressLineOne: record.AddressLineOne,
            AddressLineTwo: record.AddressLineTwo,
            CountryId: record.CountryId,
            City: record.City,
            Town: record.Town,
            PostalCode: record.PostalCode,
            Birthday: record.Birthday,
            IdentificationCode: record.IdentificationCode,
            MaritalStatusId: record.MaritalStatusId,
            GenderId: record.GenderId,
            Childrens: record.Childrens,
            HasPassportId: record.HasPassportId,
            PassportNumber: record.PassportNumber,
            PassportUrl: record.PassportUrl,
            CountryVisitedOneId: record.CountryVisitedOneId,
            CountryVisitedOneStartDate: record.CountryVisitedOneStartDate,
            CountryVisitedOneEndDate: record.CountryVisitedOneEndDate,
            CountryVisitedTwoId: record.CountryVisitedTwoId,
            CountryVisitedTwoStartDate: record.CountryVisitedTwoStartDate,
            CountryVisitedTwoEndDate: record.CountryVisitedTwoEndDate,
            HasDriverLicenseId: record.HasDriverLicenseId,
            DriverLicenseCategory: record.DriverLicenseCategory,
            PhoneNumberOne: record.PhoneNumberOne,
            PhoneNumberTwo: record.PhoneNumberTwo,
            Email: record.Email,
            ProfessionId: record.ProfessionId,
            ProfessionTwo: record.ProfessionTwo,
            ExperienceYears: record.ExperienceYears,
            EnglishLevelId: record.EnglishLevelId,
            FrenchLevelId: record.FrenchLevelId,
            ExpirationPassport: record.ExpirationPassport,
            ResidentCountryId: record.ResidentCountryId,
            ResidentCity: record.ResidentCity,
            ResidentTown: record.ResidentTown,
            AboutMe: record.AboutMe,
            VisaDeniedId: record.VisaDeniedId,
            VisaDeniedDate: record.VisaDeniedDate,
            VisaDeniedComments: record.VisaDeniedComments,
            LivedCountryMoreThan6MonthsId: record.LivedCountryMoreThan6MonthsId,
            LivedCountryId: record.LivedCountryId,
            LivedCountryDate: record.LivedCountryDate,
            LivedCountryMigrateStatus: record.LivedCountryMigrateStatus,
            JobPositionIds: record.JobPositionIds,
            JobPositions: record.JobPositions,
            Studies: record.Studies.filter((c) => c.IsDirty),
            Experiences: record.Experiences.filter((c) => c.IsDirty),
        };

        await saveRecord({
            variables: {
                record: recordToSave,
            },
        });
    };

    const getExportToPdf = () => {
        // this.setState({
        //     generatingPdf: true,
        //     generatingPdfId: record.ApplicantId,
        // });

        // applicantService.get(record.ApplicantId, `/api/getapplicantreport?id=${record.ApplicantId}`)
        //     .then((res) => {
        //         if (res.status === 401) {
        //             history.push('/login');
        //             return;
        //         }

        //         const result = res.data;

        //         if (!result.Success) {
        //             this.setState({
        //                 errorMessage: result.ErrorMessage,
        //                 generatingPdf: false,
        //             });
        //             return;
        //         }

        //         this.setState({
        //             errorMessage: '',
        //             generatingPdf: false,
        //         });

        //         window.open(result.Record, '_blank');
        //     }).catch((error) => {
        //         const res = error.response;
        //         console.log(res);

        //         this.setState({
        //             errorMessage: 'Sucedio un error en el servidor, contactar al administrador del sistema.',
        //             generatingPdf: false,
        //         });
        //     });
    };

    // const OnChangeValueInSections = (data, index, action, fieldName) => {
    //     if (action === 'Studies') {
    //         dispatch({
    //             type: ACTION_TYPES.UPDATE_STUDIES,
    //             fieldName,
    //             value: data,
    //             index,
    //         });
    //     } else if (action === 'Experiences') {
    //         dispatch({
    //             type: ACTION_TYPES.UPDATE_EXPERIENCE,
    //             fieldName,
    //             value: data,
    //             index,
    //         });
    //     }
    // };

    const setMultipleSelected = (selectedOption) => {
        const jobPositionIds = selectedOption.map((c) => c.value);

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                JobPositionIds: jobPositionIds,
            },
        });
    };

    const onActionButtons = (e, action) => {
        e.preventDefault();

        if (action === 'edit') {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isEditing: true,
                },
            });
        } else if (action === 'cancel') {
            if (isDirty) {
                callGetApplicant();
            }

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isEditing: false,
                    isDirty: false,
                },
            });
        } else if (action === 'save') {
            onSave();
        } else if (action === 'refresh') {
            callGetApplicant();
        } else if (action === 'getpdf') {
            getExportToPdf();
        }
    };

    // methods to change information for applicant
    const handleInputChange = (e) => {
        const { target } = e;

        const value = target.type === 'checkbox' ? target.checked : target.value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                [target.name]: value,
            },
        });
    };

    const handleDateChange = (date, whichdate) => {
        record[whichdate] = date._d;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                [whichdate]: date._d,
            },
        });
    };

    const handleSelectControlChange = (data, options) => {
        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                [options.name]: data,
            },
        });
    };

    const generatingPdf = false;

    useEffect(() => {
        if (Number(id) > 0) {
            callGetApplicant();
        }
    }, [id]);

    if (!record) return <h1 className="page-header">{languageTitles.recordNotFound}</h1>;

    // const items = record?.Studies?.filter((item) => item.Status !== 'Removed').map((item) => (
    //     <div key={item.StudiesId} className="col-md-4">
    //         <div className="form-group m-r-10">
    //             <label className="control-label field">Centro de Estudio:</label>
    //             <textarea
    //                 type="text"
    //                 className={`form-control ${isEditing ? '' : ' hide '}`}
    //                 placeholder="Centro de estudio"
    //                 rows="1"
    //                 name="StudyCenter"
    //                 onChange={(e) => OnChangeValueInSections(e.target.value, item.StudiesId, 'Studies', 'StudyCenter')}
    //                 value={item.StudyCenter}
    //             />
    //         </div>
    //         <div className="form-group m-r-10">
    //             <label className="control-label field">Fecha de Inicio:</label>
    //             <DateTime
    //                 className={isEditing ? '' : 'hide'}
    //                 dateFormat="DD-MM-YYYY"
    //                 name="StartDate"
    //                 timeFormat={false}
    //                 inputProps={{ placeholder: 'Fecha de ingreso', name: 'StartDate' }}
    //                 onChange={(data) => OnChangeValueInSections(data._d, item.StudiesId, 'Studies', 'StartDate')}
    //                 closeOnSelect
    //                 value={convertToDate(item.StartDate)}
    //             />
    //         </div>
    //         <div className="form-group m-r-10">
    //             <label className="control-label field">Fecha de Finalización:</label>
    //             <DateTime
    //                 className={isEditing ? '' : 'hide'}
    //                 dateFormat="DD-MM-YYYY"
    //                 name="EndDate"
    //                 timeFormat={false}
    //                 inputProps={{ placeholder: 'Fecha de salida', name: 'EndDate' }}
    //                 onChange={(data) => OnChangeValueInSections(data._d, item.StudiesId, 'Studies', 'EndDate')}
    //                 closeOnSelect
    //                 value={convertToDate(item.EndDate)}
    //             />
    //         </div>
    //         <div className="form-group m-r-10">
    //             <label className="control-label field">Titulo obtenido:</label>
    //             <textarea
    //                 type="text"
    //                 className={`form-control ${isEditing ? '' : ' hide '}`}
    //                 placeholder="Titulo obtenido"
    //                 rows="2"
    //                 name="CertificateObtained"
    //                 onChange={(e) => OnChangeValueInSections(e.target.value, item.StudiesId, 'Studies', 'CertificateObtained')}
    //                 value={item.CertificateObtained}
    //             />
    //         </div>
    //         <div className="form-group m-r-10">
    //             <label className="control-label field">Tipo de Titulo obtenido:</label>
    //             <textarea
    //                 type="text"
    //                 className={`form-control ${isEditing ? '' : ' hide '}`}
    //                 placeholder="Tipo de Titulo obtenido"
    //                 rows="2"
    //                 name="CertificateObtainedType"
    //                 onChange={(e) => OnChangeValueInSections(e.target.value, item.StudiesId, 'Studies', 'CertificateObtainedType')}
    //                 value={item.CertificateObtainedType}
    //             />
    //         </div>
    //     </div>
    // ));

    // const itemsExperience = record?.Experiences?.filter((item) => item.Status !== 'Removed').map((item) => (
    //     <div key={item.ExperienceId} className="col-md-4">
    //         <div className="form-group m-r-10">
    //             <label className="control-label field">Nombre de la compañia:</label>
    //             <textarea
    //                 type="text"
    //                 className={`form-control ${isEditing ? '' : ' hide '}`}
    //                 placeholder="Nombre de la compañia"
    //                 rows="2"
    //                 name="CompanyName"
    //                 onChange={(e) => OnChangeValueInSections(e.target.value, item.ExperienceId, 'Experiences', 'CompanyName')}
    //                 value={item.CompanyName}
    //             />
    //         </div>
    //         <div className="form-group m-r-10">
    //             <label className="control-label field">Fecha de Inicio:</label>
    //             <DateTime
    //                 className={isEditing ? '' : 'hide'}
    //                 dateFormat="DD-MM-YYYY"
    //                 name="StartDate"
    //                 timeFormat={false}
    //                 inputProps={{ placeholder: 'Fecha de ingreso', name: 'StartDate' }}
    //                 onChange={(data) => OnChangeValueInSections(data._d, item.ExperienceId, 'Experiences', 'StartDate')}
    //                 closeOnSelect
    //                 value={convertToDate(item.StartDate)}
    //             />
    //         </div>
    //         <div className="form-group m-r-10">
    //             <label className="control-label field">Fecha de Finalización:</label>
    //             <DateTime
    //                 className={isEditing ? '' : 'hide'}
    //                 dateFormat="DD-MM-YYYY"
    //                 name="EndDate"
    //                 timeFormat={false}
    //                 inputProps={{ placeholder: 'Fecha de salida', name: 'EndDate' }}
    //                 onChange={(data) => OnChangeValueInSections(data._d, item.ExperienceId, 'Experiences', 'EndDate')}
    //                 closeOnSelect
    //                 value={convertToDate(item.EndDate)}
    //             />
    //         </div>
    //         <div className="form-group m-r-10">
    //             <label className="control-label field">Cargo:</label>
    //             <textarea
    //                 type="text"
    //                 className={`form-control ${isEditing ? '' : ' hide '}`}
    //                 placeholder="Puesto"
    //                 rows="2"
    //                 name="JobTitle"
    //                 onChange={(e) => OnChangeValueInSections(e.target.value, item.ExperienceId, 'Experiences', 'JobTitle')}
    //                 value={item.JobTitle}
    //             />
    //         </div>
    //         <div className="form-group m-r-10">
    //             <label className="control-label field">Descripción del puesto:</label>
    //             <textarea
    //                 type="text"
    //                 className={`form-control ${isEditing ? '' : ' hide '}`}
    //                 placeholder="Description"
    //                 rows="4"
    //                 name="DescriptionJob"
    //                 onChange={(e) => OnChangeValueInSections(e.target.value, item.ExperienceId, 'Experiences', 'DescriptionJob')}
    //                 value={item.DescriptionJob}
    //             />
    //         </div>
    //     </div>
    // ));

    // const itemsjobs = jobDetails?.jobPositions?.data?.filter((c) => jobIdSelected?.includes(c.JobPositionId))
    //     .map((item) => {
    //         if (isEditing) {
    //             return (
    //                 <div className="col-sm-6 col-lg-4 col-xs-12" key={item.JobPositionId}>
    //                     <div className={`widget-todolist rounded mb-4 ${darkTheme ? 'dark-mode ' : ''}`}>
    //                         <div className="widget-todolist-header">
    //                             <div className="widget-todolist-header-title">{item.Title}</div>
    //                         </div>
    //                         <div className={`widget-todolist-body ${(itemIdClick === item.JobPositionId && itemOpen) ? ' ' : ''}`}>
    //                             {
    //                                 item?.JobPositionDetail?.map((detail) => {
    //                                     const applied = jobDetailIds.includes(detail.JobPositionDetailId);
    //                                     return (
    //                                         <div className="widget-todolist-item" key={detail.JobPositionDetailId}>
    //                                             <div className="widget-todolist-input">
    //                                                 <div className="form-check">
    //                                                     <input checked={applied} className="form-check-input" type="checkbox" id={`widget_todolist_${item.JobPositionDetailId}`} />
    //                                                 </div>
    //                                             </div>
    //                                             <div className="widget-todolist-content">
    //                                                 <h6 className="mb-2px">{detail.Description}</h6>
    //                                             </div>
    //                                         </div>
    //                                     );
    //                                 })
    //                             }
    //                         </div>
    //                     </div>
    //                 </div>
    //             );
    //         }

    //         return (
    //             <div className="col-sm-6 col-lg-4 col-xs-12" key={item.JobPositionId}>
    //                 <div className={`widget-todolist rounded mb-4 ${darkTheme ? 'dark-mode ' : ''}`}>
    //                     <div className="widget-todolist-header">
    //                         <div className="widget-todolist-header-title">{item.Title}</div>
    //                     </div>
    //                     <div className="widget-todolist-body">
    //                         {
    //                             item?.JobPositionDetail?.filter((c) => jobDetailIds.includes(c.JobPositionDetailId))
    //                                 .map((detail) => (
    //                                     <div className="widget-todolist-item" key={detail.JobPositionDetailId}>
    //                                         <div className="widget-todolist-content">
    //                                             <h6 className="mb-2px">{detail.Description}</h6>
    //                                         </div>
    //                                     </div>
    //                                 ))
    //                         }
    //                     </div>
    //                 </div>
    //             </div>

    //         );
    //     });

    const jobIdSelected = record?.JobPositionIds;
    // const jobDetailIds = record?.JobPositions;

    const jobPositionSelected = jobPositions?.filter((c) => jobIdSelected?.includes(c.value));

    return (
        <Panel>
            <PanelHeader>
                <ButtonGroup className="pull-right">
                    {
                        UserType === 'admin' && (
                            <TSFButton
                                action="custom"
                                isWorking={generatingPdf}
                                label={languageTitles.getPdfTitle}
                                onClick={(e) => onActionButtons(e, 'getpdf')}
                            />
                        )
                    }
                    <TSFButton
                        action="refresh"
                        isHiddden={isEditing}
                        isWorking={isLoading || isSaving}
                        onClick={(e) => onActionButtons(e, 'refresh')}
                    />
                    <TSFButton
                        action="save"
                        isWorking={isSaving}
                        isHiddden={!(isEditing && isDirty)}
                        onClick={(e) => onActionButtons(e, 'save')}
                    />
                </ButtonGroup>
            </PanelHeader>
            <PanelBody>
                <ContentForProcess reduceHeight={170}>
                    <div style={{ height: '100%', overflow: 'auto' }} className="row">
                        <div className="col-md-6">
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateName}</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" placeholder="Nombres" name="Name" required onChange={handleInputChange} value={record.Name} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateLastName}</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" placeholder="Apellidos" name="LastName" onChange={handleInputChange} value={record.LastName} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateBirthday}</label>
                                <div className="col-md-8">
                                    <DateTime
                                        input
                                        dateFormat="DD-MM-YYYY"
                                        name="Birthday"
                                        timeFormat={false}
                                        inputProps={{ placeholder: 'Fecha de nacimiento', name: 'Birthday' }}
                                        onChange={(data) => handleDateChange(data, 'Birthday')}
                                        closeOnSelect
                                        value={convertToDate(record.Birthday)}
                                        locale={currentLocaleDate}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateMaritalStatus}</label>
                                <div className="col-md-8">
                                    <Catalog
                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                        name="MaritalStatusId"
                                        catalogType={Catalogs.MARITAL_STATUS}
                                        value={String(record.MaritalStatusId)}
                                        placeholder="Estado civil"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateGender}</label>
                                <div className="col-md-8">
                                    <Catalog
                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                        name="GenderId"
                                        catalogType={Catalogs.GENDER}
                                        value={String(record.GenderId)}
                                        placeholder="Genero"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateIdentification}</label>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Identificación"
                                        name="IdentificationCode"
                                        onChange={handleInputChange}
                                        value={record.IdentificationCode}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateHasLicence}</label>
                                <div className="col-md-8">
                                    <Catalog
                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                        name="HasDriverLicenseId"
                                        catalogType={Catalogs.PASSPORT_OPTIONS}
                                        value={String(record.HasDriverLicenseId)}
                                        placeholder="Tiene licencia de conducir"
                                    />
                                </div>
                            </div>
                            { record.HasDriverLicenseId === 12 && (
                                <div className="row mb-3">
                                    <label className="form-label col-form-label col-md-4">{languageTitles.candidateCategoryLicence}</label>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Categoria de licencia de conducir"
                                            name="DriverLicenseCategory"
                                            onChange={handleInputChange}
                                            value={record.DriverLicenseCategory}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateProfession}</label>
                                <div className="col-md-8">
                                    <Profession returnSelectedValue={(data, options) => handleSelectControlChange(data, options)} name="ProfessionId" value={record.ProfessionId} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateEnglishLevel}</label>
                                <div className="col-md-8">
                                    <Catalog
                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                        name="EnglishLevelId"
                                        catalogType={Catalogs.LANGUAGE_LEVEL}
                                        value={String(record.EnglishLevelId)}
                                        placeholder="Nivel de Ingles"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateFrenchLevel}</label>
                                <div className="col-md-8">
                                    <Catalog
                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                        name="FrenchLevelId"
                                        catalogType={Catalogs.LANGUAGE_LEVEL}
                                        value={String(record.FrenchLevelId)}
                                        placeholder="Nivel de Frances"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateChildrens}</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" placeholder="Número de hij@s" name="Childrens" onChange={handleInputChange} value={record.Childrens} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateJobPosition}</label>
                                <div className="col-md-8">
                                    <MultiSelect
                                        hasSelectAll={false}
                                        options={jobPositions}
                                        value={jobPositionSelected}
                                        onChange={setMultipleSelected}
                                        labelledBy="Select"
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateCountryBorn}</label>
                                <div className="col-md-8">
                                    <Country
                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                        name="CountryId"
                                        value={String(record.CountryId)}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateCityBorn}</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" placeholder="Ciudad" name="City" onChange={handleInputChange} value={record.City} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateDepartmentBorn}</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" placeholder="Departamento o Ciudad" name="Town" onChange={handleInputChange} value={record.Town} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateCountryResident}</label>
                                <div className="col-md-8">
                                    <Country
                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                        name="ResidentCountryId"
                                        value={String(record.ResidentCountryId)}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateCityResident}</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" placeholder="Ciudad" name="ResidentCity" onChange={handleInputChange} value={record.ResidentCity} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateDepartmentResident}</label>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Departamento o Ciudad"
                                        name="ResidentTown"
                                        onChange={handleInputChange}
                                        value={record.ResidentTown}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateAddress}</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" placeholder="Dirección" name="AddressLineOne" onChange={handleInputChange} value={record.AddressLineOne} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateAddress2}</label>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Dirección Linea 2"
                                        name="AddressLineTwo"
                                        onChange={handleInputChange}
                                        value={record.AddressLineTwo}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateCellphone}</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" placeholder="Teléfono movil" name="PhoneNumberOne" onChange={handleInputChange} value={record.PhoneNumberOne} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateHomePhone}</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" placeholder="Teléfono casa" name="PhoneNumberTwo" onChange={handleInputChange} value={record.PhoneNumberTwo} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateEmail}</label>
                                <div className="col-md-8">
                                    <input readOnly type="text" className="form-control" placeholder="Correo electrónico" name="Email" onChange={handleInputChange} value={record.Email} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateHasPassport}</label>
                                <div className="col-md-8">
                                    <Catalog
                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                        name="HasPassportId"
                                        catalogType={Catalogs.PASSPORT_OPTIONS}
                                        value={String(record.HasPassportId)}
                                        placeholder="Tiene pasaporte"
                                    />
                                </div>
                            </div>
                            { record.HasPassportId === 12 // si tiene pasaporte
                                && (
                                    <div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidatePassport}</label>
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Número de Pasaporte"
                                                    name="PassportNumber"
                                                    onChange={handleInputChange}
                                                    value={record.PassportNumber}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidatePassportExpirationDate}</label>
                                            <div className="col-md-8">
                                                <DateTime
                                                    input
                                                    dateFormat="DD-MM-YYYY"
                                                    name="ExpirationPassport"
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: 'Expiracion Pasaporte', name: 'ExpirationPassport' }}
                                                    onChange={(data) => handleDateChange(data, 'ExpirationPassport')}
                                                    closeOnSelect
                                                    value={convertToDate(record.ExpirationPassport)}
                                                    locale={currentLocaleDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateCountryTravel}</label>
                                            <div className="col-md-8">
                                                <Country
                                                    returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                    name="CountryVisitedOneId"
                                                    value={String(record.CountryVisitedOneId)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateStartDateTravel}</label>
                                            <div className="col-md-8">
                                                <DateTime
                                                    dateFormat="DD-MM-YYYY"
                                                    name="CountryVisitedOneStartDate"
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: 'Fecha de entrada', name: 'CountryVisitedOneStartDate' }}
                                                    onChange={(data) => handleDateChange(data, 'CountryVisitedOneStartDate')}
                                                    closeOnSelect
                                                    value={convertToDate(record.CountryVisitedOneStartDate)}
                                                    locale={currentLocaleDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateEndDateTravel}</label>
                                            <div className="col-md-8">
                                                <DateTime
                                                    dateFormat="DD-MM-YYYY"
                                                    name="CountryVisitedOneEndDate"
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: 'Fecha de salida', name: 'CountryVisitedOneEndDate' }}
                                                    onChange={(data) => handleDateChange(data, 'CountryVisitedOneEndDate')}
                                                    closeOnSelect
                                                    value={convertToDate(record.CountryVisitedOneEndDate)}
                                                    locale={currentLocaleDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateCountryTravel}</label>
                                            <div className="col-md-8">
                                                <Country
                                                    returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                    name="CountryVisitedTwoId"
                                                    value={String(record.CountryVisitedTwoId)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateStartDateTravel}</label>
                                            <div className="col-md-8">
                                                <DateTime
                                                    dateFormat="DD-MM-YYYY"
                                                    name="CountryVisitedTwoStartDate"
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: 'Fecha de entrada', name: 'CountryVisitedTwoStartDate' }}
                                                    onChange={(data) => handleDateChange(data, 'CountryVisitedTwoStartDate')}
                                                    closeOnSelect
                                                    value={convertToDate(record.CountryVisitedTwoStartDate)}
                                                    locale={currentLocaleDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.candidateEndDateTravel}</label>
                                            <div className="col-md-8">
                                                <DateTime
                                                    dateFormat="DD-MM-YYYY"
                                                    name="CountryVisitedTwoEndDate"
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: 'Fecha de salida', name: 'CountryVisitedTwoEndDate' }}
                                                    onChange={(data) => handleDateChange(data, 'CountryVisitedTwoEndDate')}
                                                    closeOnSelect
                                                    value={convertToDate(record.CountryVisitedTwoEndDate)}
                                                    locale={currentLocaleDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) }

                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.candidateVisaDenied}</label>
                                <div className="col-md-8">
                                    <Catalog
                                        returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                        name="VisaDeniedId"
                                        catalogType={Catalogs.OPTIONAL}
                                        value={String(record.VisaDeniedId)}
                                        placeholder="Le han negado la visa"
                                    />
                                </div>
                            </div>
                            {record.VisaDeniedId === 25 && (
                                <>
                                    <div className="row mb-3">
                                        <label className="form-label col-form-label col-md-4">{languageTitles.candidateVisaDeniedDate}</label>
                                        <div className="col-md-8">
                                            <DateTime
                                                input
                                                dateFormat="DD-MM-YYYY"
                                                name="Fecha de denegacion de la visa"
                                                timeFormat={false}
                                                inputProps={{ placeholder: 'Fecha de negacion de visa', name: 'VisaDeniedDate' }}
                                                onChange={(data) => handleDateChange(data, 'VisaDeniedDate')}
                                                closeOnSelect
                                                value={convertToDate(record.VisaDeniedDate)}
                                                locale={currentLocaleDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="form-label col-form-label col-md-4">{languageTitles.candidateVisaDeniedComments}</label>
                                        <div className="col-md-8">
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder="Comentarios del porque le negaron la visa"
                                                rows="4"
                                                name="VisaDeniedComments"
                                                onChange={handleInputChange}
                                                value={record.VisaDeniedComments}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="form-label col-form-label col-md-4">{languageTitles.candidateLiveInOtherCountry}</label>
                                        <div className="col-md-8">
                                            <Catalog
                                                returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                name="LivedCountryMoreThan6MonthsId"
                                                catalogType={Catalogs.OPTIONAL}
                                                value={String(record.LivedCountryMoreThan6MonthsId)}
                                                placeholder=""

                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {record.LivedCountryMoreThan6MonthsId === 25 && (
                                <div>
                                    <div className="row mb-3">
                                        <label className="form-label col-form-label col-md-4">{languageTitles.candidateLivedCountry}</label>
                                        <div className="col-md-8">
                                            <Country
                                                returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                name="LivedCountryId"
                                                value={String(record.LivedCountryId)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="form-label col-form-label col-md-4">{languageTitles.candidateLivedCountryDate}</label>
                                        <div className="col-md-8">
                                            <DateTime
                                                input
                                                dateFormat="DD-MM-YYYY"
                                                timeFormat={false}
                                                inputProps={{ placeholder: 'Fecha que vivio fuera', name: 'LivedCountryDate' }}
                                                onChange={(data) => handleDateChange(data, 'LivedCountryDate')}
                                                closeOnSelect
                                                value={convertToDate(record.LivedCountryDate)}
                                                locale={currentLocaleDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="form-label col-form-label col-md-4">{languageTitles.candidateLivedCountryMigrateStatus}</label>
                                        <div className="col-md-8">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Estatus Migratorio"
                                                name="LivedCountryMigrateStatus"
                                                onChange={handleInputChange}
                                                value={record.LivedCountryMigrateStatus}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="row mb-3">
                                <label className="form-label col-form-label col-md-4">{languageTitles.AboutMe}</label>
                                <div className="col-md-8">
                                    <textarea type="text" className="form-control" placeholder="Acerca de mi" rows="4" name="AboutMe" onChange={handleInputChange} value={record.AboutMe} />
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentForProcess>
            </PanelBody>
        </Panel>
    );
}

export default ApplicantProcessed;
