/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-this-in-sfc */
import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import AppContext from '../../../config/appContext';
import { FetchPolicy, FileType } from '../../../enums/general';
import { Panel, PanelHeader, PanelBody } from '../../panel/panel';
import ContentForProcess from '../../layout/content/contentForProcess';
import FilesGraphql from '../../../servicesapollo/filesGraphql';
import FileRepository from '../uploadFile/fileRepository';
import useDateHelper from '../../../helpers/dateHelper';
import TSFButton from '../../actionButtons/tsfButton';

function Documents() {
    const {
        darkTheme, loggedUser, languageTitles,
    } = useContext(AppContext);

    const { formatDate } = useDateHelper();

    const { data, loading, refetch } = useQuery(FilesGraphql.GET_FILES_BY_FILTERS, {
        variables: {
            filters: [
                { fieldName: 'ApplicantId', value: String(loggedUser.EntityId) },
                { fieldName: 'FileTypeId', value: String(FileType.FILES_RECEIVED) },
            ],
            sort: [
                {
                    fieldName: 'CreatedOn',
                    dir: 'DESC',
                },
            ],
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    return (
        <Panel>
            <PanelHeader>
                <div className="panel-heading-btn justify-content-end">
                    <div className="d-flex me-2">
                        <TSFButton
                            action="refresh"
                            isWorking={loading}
                            onClick={() => refetch()}
                        />
                    </div>
                </div>
            </PanelHeader>
            <PanelBody>
                <ContentForProcess reduceHeight={170}>
                    <div className="row documents" style={{ height: '30%', overflow: 'auto' }}>
                        <div className={`widget-todolist rounded mb-4 ${darkTheme ? 'inverse-mode ' : ''}`}>
                            <div className="widget-todolist-header">
                                <div className="widget-todolist-header-title">{languageTitles.documentShareLegend}</div>
                            </div>
                            <div className="widget-todolist-body">
                                {data?.files?.data?.map((item) => (
                                    <div className="widget-todolist-item col-xs-6 col-lg-3 col-sm-6 col-md-4" key={item.FileId}>
                                        <div className="widget-todolist-input">
                                            <div className="form-check">
                                                <i className="fa fa-file-pdf fa-2x text-red" />
                                            </div>
                                        </div>
                                        <div className="widget-todolist-content">
                                            <a href={item.FileUrl} target="_blank" rel="noreferrer">
                                                <h6 className="mb-2px">{item.FileTitle}</h6>
                                            </a>
                                            <div className="text-gray-500 fw-bold fs-11px">{formatDate(item.CreatedOn)}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ height: '70%', overflow: 'auto' }}>
                        <FileRepository
                            userId={loggedUser?.UserId || 0}
                            applicantId={loggedUser?.EntityId || 0}
                            filters={
                                [{ fieldName: 'ApplicantId', value: String(loggedUser.EntityId) }]
                            }
                            isEditing
                            isRefreshing={loading}
                            fileTypes={[FileType.FILES_SENT]}
                            defaultFileTypeId={FileType.FILES_SENT}
                            customTableHeigth="45vh"
                        />
                    </div>
                </ContentForProcess>
            </PanelBody>
        </Panel>
    );
}

export default Documents;
