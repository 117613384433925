import { gql } from '@apollo/client';

export default class PermissionsGraphql {
    static GET_PERMISSION_BY_USER_TO_ASSIGN = gql`
    query permissionByUser($userId: ID!){
      permissionByUser(userId: $userId) {
        success
        message
        error
        data{
          PermissionId
          Description
          Category
          Active
        }
      }
  }`;

    static GET_PERMISSION_BY_USER = gql`
query permissionByUser($userId: ID!, $onlyActive: Boolean){
  permissionByUser(userId: $userId, onlyActive: $onlyActive) {
    success
    message
    error
    data{
      AccessKey
    }
  }
  user(id: $userId){
    success
    message
    error
    data{
      UserId,
      PictureURL
      Email
      UserType
      Applicant{
        ApplicantId
        Name
        LastName
      }
      Company {
        CompanyId
        CompanyName
        ImageUrl
      }
    }
  }
}`;

    static ADD_PERMISSIONS_TO_USER = gql`
mutation addPermisionsUser($record: PermissionUserInput!){
  addPermisionsUser(record: $record) {
    success
    message
    error
    data{
      PermissionId
      Description
      Category
      Active
    }
  }
}`;
}
