import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppContext from '../../../config/appContext';
import SidebarProfile from './sidebar-profile';
import SidebarNav from './sidebar-nav';

function Sidebar() {
    const {
        toggleAppSidebarMinify, toggleAppSidebarMobile,
        appSidebarTransparent, appSidebarGrid, appSidebarSearch,
    } = useContext(AppContext);

    return (
        <>
            <div id="sidebar" className={`app-sidebar ${appSidebarTransparent ? 'app-sidebar-transparent' : ''}${appSidebarGrid ? 'app-sidebar-grid' : ''}`}>
                <PerfectScrollbar className="app-sidebar-content" options={{ suppressScrollX: true }}>
                    <div className="menu">
                        {!appSidebarSearch && (
                            <SidebarProfile />
                        )}
                    </div>
                    <SidebarNav />
                    <div className="menu">
                        <div className="menu-item d-flex">
                            <Link to="/" className="app-sidebar-minify-btn ms-auto" onClick={toggleAppSidebarMinify}>
                                <i className="fa fa-angle-double-left" />
                            </Link>
                        </div>
                    </div>
                </PerfectScrollbar>
            </div>
            <div className="app-sidebar-bg" />
            <div className="app-sidebar-mobile-backdrop"><Link to="/" onClick={toggleAppSidebarMobile} className="stretched-link" /></div>
        </>
    );
}

export default Sidebar;
