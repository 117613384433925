import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import AppContext from '../../../../config/appContext';
import UserPassword from '../../../pages/user/userPassword';

function DropdownProfile() {
    const [dropdownOpen, toggle] = useState(false);
    const [openChangePass, setOpenChangePass] = useState(false);
    const history = useHistory();
    const {
        languageTitles, loggedUser, onLogout,
    } = useContext(AppContext);

    return (
        <>
            <Dropdown isOpen={dropdownOpen} toggle={() => toggle(!dropdownOpen)} className="navbar-item navbar-user dropdown" tag="div">
                <DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center">
                    <img src={loggedUser.ProfilePicture ? loggedUser.ProfilePicture : '../assets/img/user/user-13.jpg'} alt="" />
                    <span>
                        <span className="d-none d-md-inline">{loggedUser.FullName}</span>
                        <b className="caret" />
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
                    {!loggedUser.IsCompanyUser && (
                        <>
                            <DropdownItem
                                onClick={() => history.push(`/user/${loggedUser.UserId}`)}
                            >
                                <i className="fa fa-user-circle" />
                                {' '}
                                {languageTitles.editProfileTitle}
                            </DropdownItem>
                            <div className="dropdown-divider" />
                        </>
                    )}
                    <DropdownItem
                        onClick={() => setOpenChangePass(true)}
                    >
                        <i className="fa fa-key" />
                        {' '}
                        {languageTitles.usersBtnChangePassword}
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <DropdownItem onClick={onLogout}>
                        <i className="fa fa-power-off" />
                        {' '}
                        {languageTitles.closeAppTitle}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            {openChangePass
            && <UserPassword userId={loggedUser.UserId} onClosePopup={() => setOpenChangePass(false)} /> }
        </>
    );
}

export default DropdownProfile;
