import { useContext } from 'react';
import AppContext from '../config/appContext';

const useMessageHelper = () => {
    const appContext = useContext(AppContext);

    const getErrorMessage = (error) => {
        let errorMessages = '';

        if (error?.graphQLErrors && error?.graphQLErrors?.some((x) => x.message.includes('Cannot open server \'tsf-sql-server\' requested by the login'))) {
            fetch(`https://api.callmebot.com/whatsapp.php?phone=+50557025167&text=URGENTE:-->+Fallo+el+logeo+en-TSF+' '+${new Date()}&apikey=101134`);
        }

        if (error?.graphQLErrors?.some((x) => x.message.includes('FORBIDDEN'))
                || error?.networkError?.result?.errors?.some((e) => e.extensions.code.includes('FORBIDDEN'))) {
            return appContext?.languageTitles?.notAllowPermissionToAction || 'No tiene permiso para realizar esta accion';
        }

        if (error?.graphQLErrors?.length > 0) {
            try {
                errorMessages = error?.graphQLErrors?.filter((c) => c?.message !== '')
                    ?.map((c) => c.message)
                    ?.join('.');
            } catch (ex) {
                errorMessages = appContext?.languageTitles?.unknowError;
            }
        } else errorMessages = appContext?.languageTitles?.unknowError;

        return errorMessages ?? '';
    };

    return { getErrorMessage };
};

export default useMessageHelper;
