/* eslint-disable no-unreachable */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useContext, useReducer } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    TabContent, TabPane, Nav, NavItem, NavLink, ButtonGroup, Button,
} from 'reactstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
import classnames from 'classnames';
import DateTime from 'react-datetime';
import InputMask from 'react-input-mask';
import { v4 as uuidv4 } from 'uuid';
import MultiSelect from 'react-multi-select-component';
import {
    Panel, PanelHeader, PanelBody,
} from '../../panel/panel';
import AppContext from '../../../config/appContext';
import {
    FetchPolicy, NotificationType, Catalogs,
} from '../../../enums/general';

import ErrorPage from '../extraPages/error';
import Access from '../../../enums/permissions';
import TSFButton from '../../actionButtons/tsfButton';

import 'react-datetime/css/react-datetime.css';
import './companyFull.css';
import CompanyGraphql from '../../../servicesapollo/companyGraphql';
import UploadFile from '../uploadFile/uploadFile';
import Catalog from '../../catalog/catalog-dropdown';
import useDateHelper from '../../../helpers/dateHelper';

import YesNoControl from '../../widgets/YesNoControl';
import useMessageHelper from '../../../helpers/messageHelper';

export const ACTION_TYPES = {
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
    ADD_RECRUITMENT_HISTORY: 'AddRecruitmentHistory',
    UPDATE_RECRUITMENT_HISTORY: 'UpdateRecruitmentHistory',
    DELETE_RECRUITMENT_HISTORY: 'DeleteRecruitmentHistory',
};

const timerForUpdateInfo = 2000;
let saveInfoTimeout = null;

const initState = {
    itemIdClick: 0,
    activeTab: '1',
    isEditing: false,
    isDirty: false,
    isUploadingPictureProfile: false,
    record: {
        CompanyId: 0,
        CompanyName: '',
        ImageUrl: '',
        CompanyProfile: '',
        MainAddressStreet: '',
        MainAddressOffice: '',
        MainAddressCity: '',
        MainAddressProvince: '',
        MainAddressPostalCode: '',
        IsJobSameMainAddress: false,
        JobAddressStreet: '',
        JobAddressOffice: '',
        JobAddressCity: '',
        JobAddressProvince: '',
        JobAddressPostalCode: '',
        HaveAnotherJobAddress: false,
        OtherJobAddressStreet: '',
        OtherJobAddressOffice: '',
        OtherJobAddressCity: '',
        OtherJobAddressProvince: '',
        OtherJobAddressPostalCode: '',
        CoordinatesPhone: '',
        CoordinatesFax: '',
        CoordinatesWebsiteLink: '',
        ContactName: '',
        ContactTitle: '',
        ContactPhone: '',
        ContactPoste: '',
        ContactEmail: '',
        HasSecondaryContact: false,
        SecondaryContactName: '',
        SecondaryContactTitle: '',
        SecondaryContactPhone: '',
        SecondaryContactPoste: '',
        SecondaryContactEmail: '',
        CompanySigner: '',
        CompanySignerEmail: '',
        AccountsPayableResponsible: '',
        AccountsPayableResponsibleEmail: '',
        CompanyIsFranchise: false,
        CompanyOrganizationTypeId: 0,
        CompanyFoundationDate: '',
        CompanyBusinessNumber: '',
        CompanyNEQ: '',
        CompanyRevenueOverflow: false,
        CompanyAllowEDSC: false,
        CompanyEDSCDescription: '',
        CompanyJobPositionSyndicate: false,
        CompanyJobPositionSyndicateName: '',
        CompanyJobPositionSyndicateLocal: '',
        HasInternationalRecruitmentBefore: false,
        InternationalRecruitmentHistory: '',
        HasEmployeeBenefit: false,
        BenefitDisabilityInsurance: false,
        BenefitDentalInsurance: false,
        BenefitPensionPlan: false,
        BenefitMedicalInsurance: false,
        BenefitWorkWear: false,
        BenefitWorkShoes: false,
        BenefitVisionGlasses: false,
        BenefitOther: '',
        HasAdditionalVacation: false,
        AdditionalVacationDescription: '',
        HoursPerWeekAllowed: 0,
        OvertimeHoursAllowedAfterId: 0,
        OvertimeHoursAllowedAfterDetail: '',
        QuarterScheduleId: '',
        QuarterScheduleDetail: '',
        SalaryPaymentFrecuencyId: '',
        HasBonus: false,
        BonusId: '',
        BonusDetail: '',
        WorkShiftInit: '',
        WorkShiftEnd: '',
        WorkShiftComment: '',
        BenefitDisabilityInsuranceDetail: '',
        BenefitDentalInsuranceDetail: '',
        BenefitPensionPlanDetail: '',
        BenefitMedicalInsuranceDetail: '',
        BenefitWorkWearDetail: '',
        BenefitWorkShoesDetail: '',
        BenefitVisionGlassesDetail: '',
        EmployeeMustProvideToolsOrEquipmentDetail: '',
    },
    recruitments: [{
        Id: uuidv4(),
        JobPositionTitle: '',
        AcceptanceDate: '',
        InCourse: '',
        SalaryType: '', // bajo o alto
        SimplifiedProcessing: '',
        Salary: 0,
        Comment: '',
    }],
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD: {
        const recruitments = action.value.InternationalRecruitmentHistory?.length > 0
            ? JSON.parse(action.value.InternationalRecruitmentHistory) : initState.recruitments;

        return {
            ...state,
            record: action.value,
            recruitments,
        };
    }
    case ACTION_TYPES.SET_RECORD_CHANGED: {
        const { value } = action;
        return { ...state, record: value };
    }
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    case ACTION_TYPES.UPDATE_RECRUITMENT_HISTORY: {
        return {
            ...state,
            recruitments: action.value,
        };
    }
    default:
        return state;
    }
};

function CompanyFull() {
    const { id } = useParams();

    const {
        showNotification, languageTitles, loggedUser,
        hasPermission, coreCatalogs, currentLanguage,
        setIsDirtyData, currentLocaleDate,
    } = useContext(AppContext);

    const { getErrorMessage } = useMessageHelper();

    const history = useHistory();

    const { formatDate, convertToDateFromString, getOnlyDate } = useDateHelper();

    if ((Number(id) !== Number(loggedUser.EntityId) && loggedUser.UserType !== 'companyadmin')
    || !loggedUser.UserType.includes('company')) return <ErrorPage />;

    const [state, dispatch] = useReducer(reducer, ({ ...initState, isEditing: loggedUser.IsCompanyUser }));

    const {
        record, isEditing, isDirty, activeTab, recruitments,
    } = state;

    const [getCompany, { loading: isLoading }] = useLazyQuery(CompanyGraphql.GET_COMPANY_FULL, {
        onCompleted: (res) => {
            if (res.company) {
                const {
                    data, success, message, error,
                } = res.company;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenLoad,
                        NotificationType.DANGER,
                    );
                    return;
                }

                data.CompanyFoundationDate = convertToDateFromString(data.CompanyFoundationDate);

                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: data,
                });
                setIsDirtyData(false);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const callGetCompany = () => {
        getCompany({
            variables: {
                id: Number(id),
            },
        });
    };

    const mutationQuery = record.CompanyId > 0 ? CompanyGraphql.UPDATE_COMPANY : CompanyGraphql.CREATE_COMPANY;
    const [saveRecord, { loading: isSaving }] = useMutation(mutationQuery, {
        onCompleted: (res) => {
            if (res?.addCompany || res?.updateCompany) {
                const {
                    success, message, error,
                } = res?.addCompany || res?.updateCompany;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenSave,
                        NotificationType.DANGER,
                    );
                    return;
                }

                dispatch({
                    type: ACTION_TYPES.SET_STATE_VALUES,
                    value: {
                        isEditing: loggedUser.IsCompanyUser || false,
                        isDirty: false,
                        isSaving: false,
                    },
                });
                setIsDirtyData(false);
                showNotification(languageTitles.successWhenSave, NotificationType.SUCCESS, 500);
                // callGetCompany();
            }
        },
        onError: (error) => {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isDirty: true,
                },
            });
            setIsDirtyData(true);
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    const onSave = async (currentRecord, currentRecruitments) => {
        // return false;
        if (record.CompanyId === 0) return;
        await saveRecord({
            variables: {
                record: {
                    ...currentRecord,
                    CompanyOrganizationTypeId: currentRecord.CompanyOrganizationTypeId === 0 ? null : currentRecord.CompanyOrganizationTypeId,
                    HoursPerWeekAllowed: Number(currentRecord.HoursPerWeekAllowed),
                    OvertimeHoursAllowedAfterId: currentRecord.OvertimeHoursAllowedAfterId === 0 ? null : currentRecord.OvertimeHoursAllowedAfterId,
                    QuarterScheduleId: currentRecord.QuarterScheduleId?.toString() || null,
                    SalaryPaymentFrecuencyId: currentRecord?.SalaryPaymentFrecuencyId?.toString() || null,
                    InternationalRecruitmentHistory: JSON.stringify(currentRecruitments),
                    BonusId: currentRecord?.BonusId?.toString() || null,
                    CompanyFoundationDate: getOnlyDate(currentRecord.CompanyFoundationDate),
                },
            },
        });
    };

    const onActionButtons = (e, action) => {
        e.preventDefault();

        if (action === 'edit') {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isEditing: true,
                },
            });
        } else if (action === 'cancel') {
            if (isDirty) {
                callGetCompany();
            }

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isEditing: loggedUser.IsCompanyUser || false,
                    isDirty: false,
                },
            });
            setIsDirtyData(false);
        } else if (action === 'save') {
            onSave(record, recruitments);
        } else if (action === 'refresh') {
            callGetCompany();

            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    isEditing: loggedUser.IsCompanyUser || false,
                    isDirty: false,
                },
            });

            setIsDirtyData(false);
        }
    };

    const togglePill = (tab) => {
        if (state.activeTab !== tab) {
            dispatch({
                type: ACTION_TYPES.SET_STATE_VALUES,
                value: {
                    activeTab: tab,
                },
            });
        }
    };

    const handleInputChange = (e) => {
        clearTimeout(saveInfoTimeout);

        const { target } = e;
        const recordUpdated = {
            ...record,
            [target.name]: target.value,
        };

        saveInfoTimeout = setTimeout(() => {
            onSave(recordUpdated, recruitments);
        }, timerForUpdateInfo);

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: recordUpdated,
        });
        // setIsDirtyData(true);
    };

    const handleSelectControlChange = (data, options) => {
        clearTimeout(saveInfoTimeout);

        const recordUpdated = {
            ...record,
            [options.name]: data,
        };

        saveInfoTimeout = setTimeout(() => {
            onSave(recordUpdated, recruitments);
        }, timerForUpdateInfo);

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: recordUpdated,
        });
        // setIsDirtyData(true);
    };

    const handleDateChange = (date, whichdate) => {
        clearTimeout(saveInfoTimeout);

        const recordUpdated = {
            ...record,
            [whichdate]: date._d,
        };

        saveInfoTimeout = setTimeout(() => {
            onSave(recordUpdated, recruitments);
        }, timerForUpdateInfo);

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: {
                ...record,
                [whichdate]: date._d,
            },
        });
        // setIsDirtyData(true);
    };

    const OnChangeItemSection = (data, index, fieldName) => {
        const newArr = recruitments.map((c) => {
            const item = { ...c };
            if (String(c.Id) === String(index)) {
                item[fieldName] = data;
            }
            return item;
        });

        clearTimeout(saveInfoTimeout);

        saveInfoTimeout = setTimeout(() => {
            onSave(record, newArr);
        }, timerForUpdateInfo);

        dispatch({
            type: ACTION_TYPES.UPDATE_RECRUITMENT_HISTORY,
            value: newArr,
        });
        // setIsDirtyData(true);
    };

    const onRemoveItemSection = (e, item) => {
        e.preventDefault();

        const newArr = recruitments.filter((c) => c.Id !== item.Id);

        onSave(record, newArr);

        dispatch({
            type: ACTION_TYPES.UPDATE_RECRUITMENT_HISTORY,
            value: newArr,
        });
        // setIsDirtyData(true);
    };

    const onAddItemSection = (e) => {
        e.preventDefault();

        const newRecruitment = {
            Id: uuidv4(),
            JobPositionTitle: '',
            AcceptanceDate: '',
            InCourse: '',
            SalaryType: '',
            SimplifiedProcessing: '',
            Salary: 0,
            Comment: '',
        };

        recruitments.push(newRecruitment);

        // clearTimeout(saveInfoTimeout);

        onSave(record, recruitments);

        dispatch({
            type: ACTION_TYPES.UPDATE_RECRUITMENT_HISTORY,
            value: recruitments,
        });
        // setIsDirtyData(true);
    };

    const setMultipleSelected = (selectedOption, field) => {
        const values = selectedOption.map((c) => c.value);

        clearTimeout(saveInfoTimeout);

        const recordUpdated = {
            ...record,
            [field]: values,
        };

        saveInfoTimeout = setTimeout(() => {
            onSave(recordUpdated, recruitments);
        }, timerForUpdateInfo);

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: recordUpdated,
        });
        // setIsDirtyData(true);
    };

    useEffect(() => {
        if (Number(id) > 0) {
            callGetCompany();
        }
    }, [id]);

    if (!record) return <h1 className="page-header">{languageTitles.recordNotFound}</h1>;

    const bonusList = coreCatalogs.filter((c) => String(c.category.toLowerCase()) === Catalogs.BONUS.toLowerCase()).map((c) => (
        {
            value: c.value,
            label: c[currentLanguage],
        }));

    const paymentFrequencyList = coreCatalogs.filter((c) => Number(c.orden) === 5
    && String(c.category.toLowerCase()) === Catalogs.SALARAY_PAYMENT_FRECUENCY.toLowerCase()).map((c) => (
        {
            value: c.value,
            label: c[currentLanguage],
        }));

    const quarteScheduleFrequencyList = coreCatalogs.filter((c) => Number(c.orden) === 5
    && String(c.category.toLowerCase()) === Catalogs.QUARTER_SCHEDULE.toLowerCase()).map((c) => (
        {
            value: c.value,
            label: c[currentLanguage],
        }));

    return (
        <Panel>
            <PanelHeader>
                <ButtonGroup className="pull-right">
                    {hasPermission(Access.COMPANY_WRITE)
            && (
                <Button
                    color="green"
                    title={languageTitles.candidatesEditBtnTitle}
                    className={`btn-sm me-1 ${(isEditing || isLoading) ? 'hide ' : ''}`}
                    onClick={(e) => onActionButtons(e, 'edit')}
                >
                    <i className="fa fa-edit" />
                    {' '}
                    {languageTitles.updateTitle}
                </Button>
            ) }
                    {record.CompanyId > 0
                    && (
                        <TSFButton
                            action="save"
                            isWorking={isSaving}
                            isHiddden={!(isEditing && isDirty)}
                            onClick={(e) => onActionButtons(e, 'save')}
                        />
                    )}
                    <TSFButton
                        action="refresh"
                        isWorking={isLoading}
                        onClick={(e) => onActionButtons(e, 'refresh')}
                    />
                </ButtonGroup>
            </PanelHeader>
            <PanelBody>
                <div className="nav-wizards-container">
                    <Nav className="nav nav-wizards-2" pills>
                        <NavItem className="nav-item col">
                            <NavLink
                                className={classnames({ active: activeTab === '1' }, 'completed')}
                                onClick={() => { togglePill('1'); }}
                            >
                                <span className="d-sm-none nav-text">
                                    {languageTitles.companyTabIdentification}
                                </span>
                                <span className="d-sm-block d-none nav-text">{languageTitles.companyTabIdentification}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="nav-item col">
                            <NavLink
                                className={classnames({ active: activeTab === '2' }, 'completed')}
                                onClick={() => { togglePill('2'); }}
                            >
                                <span className="d-sm-none nav-text">
                                    {languageTitles.companyTabHistoryRecruitment}
                                </span>
                                <span className="d-sm-block d-none nav-text">{languageTitles.companyTabHistoryRecruitment}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="nav-item col">
                            <NavLink
                                className={classnames({ active: activeTab === '3' }, 'completed')}
                                onClick={() => { togglePill('3'); }}
                            >
                                <span className="d-sm-none nav-text">
                                    {languageTitles.companyTabConditions}
                                </span>
                                <span className="d-sm-block d-none nav-text">{languageTitles.companyTabConditions}</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div className="card mt-20px">
                    <div className="card-body">
                        <TabContent className="p-15 rounded bg-white mb-4" activeTab={activeTab}>
                            <TabPane key={1} tabId="1">
                                <div className="row">
                                    <div className="col-md-6 border-end border-light">
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <label className="form-label col-form-label col-md-4">{languageTitles.companyImageUrl}</label>
                                                <img
                                                    className="thumbnailimage"
                                                    alt="logo"
                                                    src={record?.ImageUrl ?? '/assets/img/tsf-logo.png'}
                                                />
                                                {isEditing
                                    && (
                                        <UploadFile
                                            additionalInfo={{ companyId: record.CompanyId }}
                                            graphql={CompanyGraphql.UPLOAD_COMPANY_PICTURE}
                                            resultNode="uploadPictureCompany"
                                            acceptFiles=".jpg,.jpeg,.png"
                                            uploading={(val) => {
                                                dispatch({
                                                    type: ACTION_TYPES.SET_STATE_VALUES,
                                                    value: {
                                                        isUploading: val,
                                                    },
                                                });
                                            }}
                                            afterUpload={(val) => {
                                                dispatch({
                                                    type: ACTION_TYPES.SET_RECORD,
                                                    value: {
                                                        ...record,
                                                        ImageUrl: val?.data?.FileUrl,
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.companyName}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.CompanyName}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input type="text" className="form-control" name="CompanyName" required onChange={handleInputChange} value={record.CompanyName} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.mainAddressStreet}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.MainAddressStreet}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <textarea
                                                            type="text"
                                                            rows="2"
                                                            className="form-control"
                                                            name="MainAddressStreet"
                                                            placeholder={languageTitles.mainAddressStreetPlaceHolder}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.MainAddressStreet ?? ''}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.mainAddressOffice}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.MainAddressOffice}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="MainAddressOffice"
                                                            placeholder={languageTitles.mainAddressOffice}
                                                            onChange={handleInputChange}
                                                            value={record.MainAddressOffice}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.mainAddressCity}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.MainAddressCity}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="MainAddressCity"
                                                            placeholder={languageTitles.mainAddressCity}
                                                            onChange={handleInputChange}
                                                            value={record.MainAddressCity}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.mainAddressProvince}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.MainAddressProvince}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="MainAddressProvince"
                                                            placeholder={languageTitles.mainAddressProvince}
                                                            onChange={handleInputChange}
                                                            value={record.MainAddressProvince}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.mainAddressPostalCode}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.MainAddressPostalCode}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="MainAddressPostalCode"
                                                            placeholder={languageTitles.mainAddressPostalCode}
                                                            onChange={handleInputChange}
                                                            value={record.MainAddressPostalCode}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.isJobSameMainAddress}</label>
                                            <div className="col-md-8">
                                                <YesNoControl name="IsJobSameMainAddress" value={record.IsJobSameMainAddress} onChange={handleInputChange} disabled={!isEditing} />
                                            </div>
                                        </div>
                                        { !record.IsJobSameMainAddress && (
                                            <>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.jobAddressStreet}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.JobAddressStreet}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <textarea
                                                                    type="text"
                                                                    rows="2"
                                                                    className="form-control"
                                                                    name="JobAddressStreet"
                                                                    placeholder={languageTitles.jobAddressStreet}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.JobAddressStreet ?? ''}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.jobAddressOffice}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.JobAddressOffice}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="JobAddressOffice"
                                                                    placeholder={languageTitles.jobAddressOffice}
                                                                    onChange={handleInputChange}
                                                                    value={record.JobAddressOffice}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.jobAddressCity}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.JobAddressCity}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="JobAddressCity"
                                                                    placeholder={languageTitles.jobAddressCity}
                                                                    onChange={handleInputChange}
                                                                    value={record.JobAddressCity}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.jobAddressProvince}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.JobAddressProvince}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="JobAddressProvince"
                                                                    placeholder={languageTitles.jobAddressProvince}
                                                                    onChange={handleInputChange}
                                                                    value={record.JobAddressProvince}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.jobAddressPostalCode}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.JobAddressPostalCode}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="JobAddressPostalCode"
                                                                    placeholder={languageTitles.JobAddressPostalCode}
                                                                    onChange={handleInputChange}
                                                                    value={record.JobAddressPostalCode}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.haveAnotherJobAddress}</label>
                                            <div className="col-md-8">
                                                <YesNoControl name="HaveAnotherJobAddress" value={record.HaveAnotherJobAddress} onChange={handleInputChange} disabled={!isEditing} />
                                            </div>
                                        </div>
                                        { record.HaveAnotherJobAddress && (
                                            <>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.otherJobAddressStreet}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.OtherJobAddressStreet}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <textarea
                                                                    type="text"
                                                                    rows="2"
                                                                    className="form-control"
                                                                    name="OtherJobAddressStreet"
                                                                    placeholder={languageTitles.otherJobAddressStreet}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.OtherJobAddressStreet ?? ''}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.otherJobAddressOffice}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.OtherJobAddressOffice}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="OtherJobAddressOffice"
                                                                    placeholder={languageTitles.otherJobAddressOffice}
                                                                    onChange={handleInputChange}
                                                                    value={record.OtherJobAddressOffice}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.otherJobAddressCity}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.OtherJobAddressCity}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="OtherJobAddressCity"
                                                                    placeholder={languageTitles.otherJobAddressCity}
                                                                    onChange={handleInputChange}
                                                                    value={record.OtherJobAddressCity}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.otherJobAddressProvince}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.OtherJobAddressProvince}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="OtherJobAddressProvince"
                                                                    placeholder={languageTitles.otherJobAddressProvince}
                                                                    onChange={handleInputChange}
                                                                    value={record.OtherJobAddressProvince}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.otherJobAddressPostalCode}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.OtherJobAddressPostalCode}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="OtherJobAddressPostalCode"
                                                                    placeholder={languageTitles.otherJobAddressPostalCode}
                                                                    onChange={handleInputChange}
                                                                    value={record.OtherJobAddressPostalCode}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.coordinatesPhone}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.CoordinatesPhone}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="CoordinatesPhone"
                                                            placeholder={languageTitles.coordinatesPhone}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.CoordinatesPhone}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.coordinatesFax}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.CoordinatesFax}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="CoordinatesFax"
                                                            placeholder={languageTitles.coordinatesFax}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.CoordinatesFax}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.coordinatesWebsiteLink}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.CoordinatesWebsiteLink}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="CoordinatesWebsiteLink"
                                                            placeholder={languageTitles.coordinatesWebsiteLink}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.CoordinatesWebsiteLink}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.contactName}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.ContactName}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="ContactName"
                                                            placeholder={languageTitles.contactName}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.ContactName}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.contactTitle}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.ContactTitle}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="ContactTitle"
                                                            placeholder={languageTitles.contactTitle}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.ContactTitle}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.contactPhone}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.ContactPhone}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="ContactPhone"
                                                            placeholder={languageTitles.contactPhone}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.ContactPhone}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.contactPoste}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.ContactPoste}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="ContactPoste"
                                                            placeholder={languageTitles.contactPoste}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.ContactPoste}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.contactEmail}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.ContactEmail}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="ContactEmail"
                                                            placeholder={languageTitles.contactEmail}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.ContactEmail}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.hasSecondaryContact}</label>
                                            <div className="col-md-8">
                                                <YesNoControl name="HasSecondaryContact" value={record.HasSecondaryContact} onChange={handleInputChange} disabled={!isEditing} />
                                            </div>
                                        </div>
                                        {record.HasSecondaryContact && (
                                            <>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.secondaryContactName}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.SecondaryContactName}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="SecondaryContactName"
                                                                    placeholder={languageTitles.secondaryContactName}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.SecondaryContactName}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.secondaryContactTitle}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.SecondaryContactTitle}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="SecondaryContactTitle"
                                                                    placeholder={languageTitles.secondaryContactTitle}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.SecondaryContactTitle}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.secondaryContactPhone}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.SecondaryContactPhone}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="SecondaryContactPhone"
                                                                    placeholder={languageTitles.secondaryContactPhone}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.SecondaryContactPhone}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.secondaryContactPoste}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.SecondaryContactPoste}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="SecondaryContactPoste"
                                                                    placeholder={languageTitles.secondaryContactPoste}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.SecondaryContactPoste}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.secondaryContactEmail}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.SecondaryContactEmail}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="SecondaryContactEmail"
                                                                    placeholder={languageTitles.secondaryContactEmail}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.SecondaryContactEmail}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">
                                                {languageTitles.companySigner}
                                                <br />
                                                <span style={{ fontSize: '9px' }}>
                                                    {languageTitles.companySignerText}
                                                </span>
                                            </label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.CompanySigner}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="CompanySigner"
                                                            placeholder={languageTitles.companySigner}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.CompanySigner}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">
                                                {languageTitles.companySignerEmail}
                                            </label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.CompanySignerEmail}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="CompanySignerEmail"
                                                            placeholder={languageTitles.companySignerEmail}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.CompanySignerEmail}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">
                                                {languageTitles.accountsPayableResponsible}
                                            </label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.AccountsPayableResponsible}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="AccountsPayableResponsible"
                                                            placeholder={languageTitles.accountsPayableResponsible}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.AccountsPayableResponsible}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">
                                                {languageTitles.accountsPayableResponsibleEmail}
                                            </label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.AccountsPayableResponsibleEmail}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="AccountsPayableResponsibleEmail"
                                                            placeholder={languageTitles.accountsPayableResponsibleEmail}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.AccountsPayableResponsibleEmail}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.companyIsFranchise}</label>
                                            <div className="col-md-8 mt-1">
                                                <YesNoControl name="CompanyIsFranchise" value={record.CompanyIsFranchise} onChange={handleInputChange} disabled={!isEditing} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.companyOrganizationType}</label>
                                            <div className="col-md-8">
                                                {!isEditing && (
                                                    <label className="col-form-label field">
                                                        {
                                                            coreCatalogs.find((c) => Number(c.orden) === 5
                                                && c.category.toLowerCase() === Catalogs.COMPANY_ORGANIZATION_TYPE.toLowerCase()
                                                && String(c.value) === String(record.CompanyOrganizationTypeId))?.[currentLanguage]
                                                        }
                                                    </label>
                                                )}
                                                {isEditing && (
                                                    <div>
                                                        <Catalog
                                                            returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                            name="CompanyOrganizationTypeId"
                                                            catalogType={Catalogs.COMPANY_ORGANIZATION_TYPE}
                                                            value={String(record.CompanyOrganizationTypeId)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.companyFoundationDate}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{formatDate(record.CompanyFoundationDate)}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <DateTime
                                                            input
                                                            dateFormat="DD-MM-YYYY"
                                                            name="CompanyFoundationDate"
                                                            timeFormat={false}
                                                            inputProps={{ name: 'CompanyFoundationDate' }}
                                                            onChange={(data) => handleDateChange(data, 'CompanyFoundationDate')}
                                                            closeOnSelect
                                                            value={record.CompanyFoundationDate}
                                                            locale={currentLocaleDate}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.companyBusinessNumber}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.CompanyBusinessNumber}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <InputMask
                                                            mask="999999999 RP 9999"
                                                            className="form-control"
                                                            name="CompanyBusinessNumber"
                                                            placeholder="123456789 RP 0001"
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.CompanyBusinessNumber}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.companyNEQ}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.CompanyNEQ}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <InputMask
                                                            mask="9999999999"
                                                            className="form-control"
                                                            name="CompanyNEQ"
                                                            placeholder="123456789"
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.CompanyNEQ}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.companyRevenueOverflow}</label>
                                            <div className="col-md-8 mt-1">
                                                <YesNoControl name="CompanyRevenueOverflow" value={record.CompanyRevenueOverflow} onChange={handleInputChange} disabled={!isEditing} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.companyAllowEDSC}</label>
                                            <div className="col-md-8 mt-1">
                                                <YesNoControl name="CompanyAllowEDSC" value={record.CompanyAllowEDSC} onChange={handleInputChange} disabled={!isEditing} />
                                            </div>
                                        </div>
                                        { record.CompanyAllowEDSC && (
                                            <div className="row mb-3">
                                                <label className="form-label col-form-label col-md-4">{languageTitles.companyEDSCDescription}</label>
                                                <div className="col-md-8">
                                                    {!isEditing && <label className="col-form-label field">{record.CompanyEDSCDescription}</label>}
                                                    {isEditing && (
                                                        <div>
                                                            <textarea
                                                                rows={2}
                                                                type="text"
                                                                className="form-control"
                                                                name="CompanyEDSCDescription"
                                                                placeholder={languageTitles.companyEDSCDescription}
                                                                required
                                                                onChange={handleInputChange}
                                                                value={record.CompanyEDSCDescription ?? ''}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.companyJobPositionSyndicate}</label>
                                            <div className="col-md-8 mt-1">
                                                <YesNoControl
                                                    name="CompanyJobPositionSyndicate"
                                                    value={record.CompanyJobPositionSyndicate}
                                                    onChange={handleInputChange}
                                                    disabled={!isEditing}
                                                />
                                            </div>
                                        </div>
                                        { record.CompanyJobPositionSyndicate && (
                                            <>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.companyJobPositionSyndicateName}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.CompanyJobPositionSyndicateName}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="CompanyJobPositionSyndicateName"
                                                                    placeholder={languageTitles.companyJobPositionSyndicateName}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.CompanyJobPositionSyndicateName}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.companyJobPositionSyndicateLocal}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.CompanyJobPositionSyndicateLocal}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="CompanyJobPositionSyndicateLocal"
                                                                    placeholder={languageTitles.companyJobPositionSyndicateLocal}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.CompanyJobPositionSyndicateLocal}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                                <div className="float-end">
                                    <TSFButton
                                        action="custom"
                                        btnColor="primary"
                                        useIcon
                                        iconClass="fas fa-arrow-right"
                                        label={languageTitles.nextBtn}
                                        onClick={() => { togglePill('2'); }}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.hasInternationalRecruitmentBefore}</label>
                                            <div className="col-md-8 mt-1">
                                                <YesNoControl
                                                    name="HasInternationalRecruitmentBefore"
                                                    value={record.HasInternationalRecruitmentBefore}
                                                    onChange={handleInputChange}
                                                    disabled={!isEditing}
                                                />
                                            </div>
                                        </div>
                                        { record.HasInternationalRecruitmentBefore && (
                                            <>
                                                <div className="row row-space-2 mb-3">

                                                    <table className="table table-striped mb-0 align-middle">
                                                        <thead>
                                                            <tr>
                                                                <th rowSpan={2}>{languageTitles.recruitmentJobPositionTitle}</th>
                                                                <th colSpan={2} className="text-center">{languageTitles.recruitmentTableHeader1}</th>
                                                                <th colSpan={2} className="text-center">{languageTitles.recruitmentTableHeader2}</th>
                                                                <th rowSpan={2}>{languageTitles.recruitmentSalary}</th>
                                                                <th rowSpan={2}>{languageTitles.recruitmentComment}</th>
                                                            </tr>
                                                            <tr>
                                                                <th>{languageTitles.recruitmentAcceptanceDate}</th>
                                                                <th>{languageTitles.recruitmentInCourse}</th>
                                                                <th>{languageTitles.recruitmentSalaryType}</th>
                                                                <th>{languageTitles.recruitmentSimplifiedProcessing}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {recruitments.map((item) => (
                                                                <tr key={item.Id}>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="JobPositionTitle"
                                                                            required
                                                                            onChange={(e) => OnChangeItemSection(e.target.value, item.Id, e.target.name)}
                                                                            value={item.JobPositionTitle}
                                                                        />

                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="AcceptanceDate"
                                                                            required
                                                                            onChange={(e) => OnChangeItemSection(e.target.value, item.Id, e.target.name)}
                                                                            value={item.AcceptanceDate}
                                                                        />

                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="InCourse"
                                                                            placeholder={languageTitles.InternationalRecruitmentJobPositionTitle}
                                                                            required
                                                                            onChange={(e) => OnChangeItemSection(e.target.value, item.Id, e.target.name)}
                                                                            value={item.InCourse}
                                                                        />

                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="SalaryType"
                                                                            placeholder={languageTitles.InternationalRecruitmentJobPositionTitle}
                                                                            required
                                                                            onChange={(e) => OnChangeItemSection(e.target.value, item.Id, e.target.name)}
                                                                            value={item.SalaryType}
                                                                        />

                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="SimplifiedProcessing"
                                                                            placeholder={languageTitles.InternationalRecruitmentJobPositionTitle}
                                                                            required
                                                                            onChange={(e) => OnChangeItemSection(e.target.value, item.Id, e.target.name)}
                                                                            value={item.SimplifiedProcessing}
                                                                        />

                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="Salary"
                                                                            placeholder={languageTitles.InternationalRecruitmentJobPositionTitle}
                                                                            required
                                                                            onChange={(e) => OnChangeItemSection(e.target.value, item.Id, e.target.name)}
                                                                            value={item.Salary}
                                                                        />

                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="Comment"
                                                                            placeholder={languageTitles.InternationalRecruitmentJobPositionTitle}
                                                                            required
                                                                            onChange={(e) => OnChangeItemSection(e.target.value, item.Id, e.target.name)}
                                                                            value={item.Comment}
                                                                        />

                                                                    </td>
                                                                    <td nowrap="true">
                                                                        <TSFButton
                                                                            action="custom"
                                                                            btnColor="danger"
                                                                            className="mt-3"
                                                                            useIcon
                                                                            iconClass="fas fa-ban"
                                                                            label={languageTitles.deleteTitle}
                                                                            onClick={(e) => onRemoveItemSection(e, item)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                </div>
                                                {
                                                    isEditing
                                                        ? (
                                                            <h4 className="mt-3 mb-3">
                                                                <TSFButton
                                                                    action="custom"
                                                                    className="btn btn-primary"
                                                                    label={languageTitles.addButton}
                                                                    useIcon
                                                                    iconClass="fas fa-plus"
                                                                    onClick={onAddItemSection}
                                                                />
                                                            </h4>
                                                        ) : ''
                                                }
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="float-end">
                                    <TSFButton
                                        action="custom"
                                        btnColor="primary"
                                        className="me-2"
                                        useIcon
                                        iconClass="fas fa-arrow-left"
                                        label={languageTitles.previousBtn}
                                        onClick={() => { togglePill('1'); }}
                                    />
                                    <TSFButton
                                        action="custom"
                                        btnColor="primary"
                                        useIcon
                                        iconClass="fas fa-arrow-right"
                                        label={languageTitles.nextBtn}
                                        onClick={() => { togglePill('3'); }}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <div className="row">
                                    <div className="col-md-6 border-end border-light">
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.hasEmployeeBenefit}</label>
                                            <div className="col-md-8 mt-1">
                                                <YesNoControl name="HasEmployeeBenefit" value={record.HasEmployeeBenefit} onChange={handleInputChange} disabled={!isEditing} />
                                            </div>
                                        </div>
                                        { record.HasEmployeeBenefit && (
                                            <>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.benefitDisabilityInsurance}</label>
                                                    <div className="col-md-8 mt-1">
                                                        <YesNoControl
                                                            name="BenefitDisabilityInsurance"
                                                            value={record.BenefitDisabilityInsurance}
                                                            onChange={handleInputChange}
                                                            disabled={!isEditing}
                                                        />
                                                    </div>
                                                </div>
                                                { record.BenefitDisabilityInsurance && (
                                                    <div className="row mb-3">
                                                        <label className="form-label col-form-label col-md-4">{languageTitles.detailLabel}</label>
                                                        <div className="col-md-8">
                                                            {!isEditing && <label className="col-form-label field">{record.BenefitDisabilityInsuranceDetail}</label>}
                                                            {isEditing && (
                                                                <div>
                                                                    <textarea
                                                                        rows={2}
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="BenefitDisabilityInsuranceDetail"
                                                                        placeholder={languageTitles.detailLabel}
                                                                        required
                                                                        onChange={handleInputChange}
                                                                        value={record.BenefitDisabilityInsuranceDetail ?? ''}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.benefitDentalInsurance}</label>
                                                    <div className="col-md-8 mt-1">
                                                        <YesNoControl name="BenefitDentalInsurance" value={record.BenefitDentalInsurance} onChange={handleInputChange} disabled={!isEditing} />
                                                    </div>
                                                </div>
                                                { record.BenefitDentalInsurance && (
                                                    <div className="row mb-3">
                                                        <label className="form-label col-form-label col-md-4">{languageTitles.detailLabel}</label>
                                                        <div className="col-md-8">
                                                            {!isEditing && <label className="col-form-label field">{record.BenefitDentalInsuranceDetail}</label>}
                                                            {isEditing && (
                                                                <div>
                                                                    <textarea
                                                                        rows={2}
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="BenefitDentalInsuranceDetail"
                                                                        placeholder={languageTitles.detailLabel}
                                                                        required
                                                                        onChange={handleInputChange}
                                                                        value={record.BenefitDentalInsuranceDetail ?? ''}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.benefitPensionPlan}</label>
                                                    <div className="col-md-8 mt-1">
                                                        <YesNoControl name="BenefitPensionPlan" value={record.BenefitPensionPlan} onChange={handleInputChange} disabled={!isEditing} />
                                                    </div>
                                                </div>
                                                { record.BenefitPensionPlan && (
                                                    <div className="row mb-3">
                                                        <label className="form-label col-form-label col-md-4">{languageTitles.detailLabel}</label>
                                                        <div className="col-md-8">
                                                            {!isEditing && <label className="col-form-label field">{record.BenefitPensionPlanDetail}</label>}
                                                            {isEditing && (
                                                                <div>
                                                                    <textarea
                                                                        rows={2}
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="BenefitPensionPlanDetail"
                                                                        placeholder={languageTitles.detailLabel}
                                                                        required
                                                                        onChange={handleInputChange}
                                                                        value={record.BenefitPensionPlanDetail ?? ''}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.benefitMedicalInsurance}</label>
                                                    <div className="col-md-8 mt-1">
                                                        <YesNoControl
                                                            name="BenefitMedicalInsurance"
                                                            value={record.BenefitMedicalInsurance}
                                                            onChange={handleInputChange}
                                                            disabled={!isEditing}
                                                        />
                                                    </div>
                                                </div>
                                                { record.BenefitMedicalInsurance && (
                                                    <div className="row mb-3">
                                                        <label className="form-label col-form-label col-md-4">{languageTitles.detailLabel}</label>
                                                        <div className="col-md-8">
                                                            {!isEditing && <label className="col-form-label field">{record.BenefitMedicalInsuranceDetail}</label>}
                                                            {isEditing && (
                                                                <div>
                                                                    <textarea
                                                                        rows={2}
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="BenefitMedicalInsuranceDetail"
                                                                        placeholder={languageTitles.detailLabel}
                                                                        required
                                                                        onChange={handleInputChange}
                                                                        value={record.BenefitMedicalInsuranceDetail ?? ''}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.benefitWorkWear}</label>
                                                    <div className="col-md-8 mt-1">
                                                        <YesNoControl name="BenefitWorkWear" value={record.BenefitWorkWear} onChange={handleInputChange} disabled={!isEditing} />
                                                    </div>
                                                </div>
                                                { record.BenefitWorkWear && (
                                                    <div className="row mb-3">
                                                        <label className="form-label col-form-label col-md-4">{languageTitles.detailLabel}</label>
                                                        <div className="col-md-8">
                                                            {!isEditing && <label className="col-form-label field">{record.BenefitWorkWearDetail}</label>}
                                                            {isEditing && (
                                                                <div>
                                                                    <textarea
                                                                        rows={2}
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="BenefitWorkWearDetail"
                                                                        placeholder={languageTitles.detailLabel}
                                                                        required
                                                                        onChange={handleInputChange}
                                                                        value={record.BenefitWorkWearDetail ?? ''}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.benefitWorkShoes}</label>
                                                    <div className="col-md-8 mt-1">
                                                        <YesNoControl name="BenefitWorkShoes" value={record.BenefitWorkShoes} onChange={handleInputChange} disabled={!isEditing} />
                                                    </div>
                                                </div>
                                                { record.BenefitWorkShoes && (
                                                    <div className="row mb-3">
                                                        <label className="form-label col-form-label col-md-4">{languageTitles.detailLabel}</label>
                                                        <div className="col-md-8">
                                                            {!isEditing && <label className="col-form-label field">{record.BenefitWorkShoesDetail}</label>}
                                                            {isEditing && (
                                                                <div>
                                                                    <textarea
                                                                        rows={2}
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="BenefitWorkShoesDetail"
                                                                        placeholder={languageTitles.detailLabel}
                                                                        required
                                                                        onChange={handleInputChange}
                                                                        value={record.BenefitWorkShoesDetail ?? ''}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.benefitVisionGlasses}</label>
                                                    <div className="col-md-8 mt-1">
                                                        <YesNoControl name="BenefitVisionGlasses" value={record.BenefitVisionGlasses} onChange={handleInputChange} disabled={!isEditing} />
                                                    </div>
                                                </div>
                                                { record.BenefitVisionGlasses && (
                                                    <div className="row mb-3">
                                                        <label className="form-label col-form-label col-md-4">{languageTitles.detailLabel}</label>
                                                        <div className="col-md-8">
                                                            {!isEditing && <label className="col-form-label field">{record.BenefitVisionGlassesDetail}</label>}
                                                            {isEditing && (
                                                                <div>
                                                                    <textarea
                                                                        rows={2}
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="BenefitVisionGlassesDetail"
                                                                        placeholder={languageTitles.detailLabel}
                                                                        required
                                                                        onChange={handleInputChange}
                                                                        value={record.BenefitVisionGlassesDetail ?? ''}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.benefitOther}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.BenefitOther}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="BenefitOther"
                                                                    placeholder={languageTitles.benefitOther}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.BenefitOther}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.hasAdditionalVacation}</label>
                                            <div className="col-md-8 mt-1">
                                                <YesNoControl name="HasAdditionalVacation" value={record.HasAdditionalVacation} onChange={handleInputChange} disabled={!isEditing} />
                                            </div>
                                        </div>
                                        { record.HasAdditionalVacation && (
                                            <div className="row mb-3">
                                                <label className="form-label col-form-label col-md-4">{languageTitles.additionalVacationDescription}</label>
                                                <div className="col-md-8">
                                                    {!isEditing && <label className="col-form-label field">{record.AdditionalVacationDescription}</label>}
                                                    {isEditing && (
                                                        <div>
                                                            <textarea
                                                                rows={2}
                                                                type="text"
                                                                className="form-control"
                                                                name="AdditionalVacationDescription"
                                                                placeholder={languageTitles.additionalVacationDescription}
                                                                required
                                                                onChange={handleInputChange}
                                                                value={record.AdditionalVacationDescription ?? ''}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.hasBonus}</label>
                                            <div className="col-md-8 mt-1">
                                                <YesNoControl name="HasBonus" value={record.HasBonus} onChange={handleInputChange} disabled={!isEditing} />
                                            </div>
                                        </div>
                                        { record.HasBonus && (
                                            <>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.bonusType}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && (
                                                            <label className="col-form-label field">
                                                                {bonusList?.filter((c) => record.BonusId?.includes(c.value)).map((c) => c.label).toString()}
                                                            </label>
                                                        )}
                                                        {isEditing && (
                                                            <div>
                                                                <MultiSelect
                                                                    hasSelectAll={false}
                                                                    options={bonusList}
                                                                    value={bonusList?.filter((c) => record?.BonusId?.includes(c.value))}
                                                                    onChange={(val) => setMultipleSelected(val, 'BonusId')}
                                                                    labelledBy="Select"
                                                                    className="selectCustomClass"
                                                                    menuPlacement="top"
                                                                    menuPosition="top"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label className="form-label col-form-label col-md-4">{languageTitles.bonusDetail}</label>
                                                    <div className="col-md-8">
                                                        {!isEditing && <label className="col-form-label field">{record.BonusDetail}</label>}
                                                        {isEditing && (
                                                            <div>
                                                                <textarea
                                                                    rows={4}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="BonusDetail"
                                                                    placeholder={languageTitles.bonusDetail}
                                                                    required
                                                                    onChange={handleInputChange}
                                                                    value={record.BonusDetail ?? ''}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.hoursPerWeekAllowed}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.HoursPerWeekAllowed}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="HoursPerWeekAllowed"
                                                            placeholder={languageTitles.hoursPerWeekAllowed}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.HoursPerWeekAllowed}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.overtimeHoursAllowedAfter}</label>
                                            <div className="col-md-8">
                                                {!isEditing && (
                                                    <label className="col-form-label field">
                                                        {
                                                            coreCatalogs.find((c) => Number(c.orden) === 5
                                                && c.category.toLowerCase() === Catalogs.OVERTIME_HOURS_ALLOWED_AFTER.toLowerCase()
                                                && String(c.value) === String(record.OvertimeHoursAllowedAfterId))?.[currentLanguage]
                                                        }
                                                    </label>
                                                )}
                                                {isEditing && (
                                                    <div>
                                                        <Catalog
                                                            returnSelectedValue={(data, options) => handleSelectControlChange(data, options)}
                                                            name="OvertimeHoursAllowedAfterId"
                                                            catalogType={Catalogs.OVERTIME_HOURS_ALLOWED_AFTER}
                                                            value={String(record.OvertimeHoursAllowedAfterId)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        { coreCatalogs.find((c) => Number(c.orden) === 5
                                    && c.category.toLowerCase() === Catalogs.OVERTIME_HOURS_ALLOWED_AFTER.toLowerCase()
                                    && String(c.value) === String(record.OvertimeHoursAllowedAfterId))?.Es.toLowerCase() === 'otro'
                                    && (
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">
                                                {languageTitles.overtimeHoursAllowedAfterDetail}
                                            </label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.OvertimeHoursAllowedAfterDetail}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <textarea
                                                            rows={2}
                                                            type="text"
                                                            className="form-control"
                                                            name="OvertimeHoursAllowedAfterDetail"
                                                            placeholder={languageTitles.overtimeHoursAllowedAfterDetail}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.OvertimeHoursAllowedAfterDetail ?? ''}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.quarterSchedule}</label>
                                            <div className="col-md-8">
                                                {!isEditing && (
                                                    <label className="col-form-label field">
                                                        {quarteScheduleFrequencyList?.filter((c) => record.QuarterScheduleId?.includes(c.value)).map((c) => c.label).toString()}
                                                    </label>
                                                )}
                                                {isEditing && (
                                                    <div>
                                                        <MultiSelect
                                                            hasSelectAll={false}
                                                            options={quarteScheduleFrequencyList}
                                                            value={quarteScheduleFrequencyList?.filter((c) => record?.QuarterScheduleId?.includes(c.value))}
                                                            onChange={(val) => setMultipleSelected(val, 'QuarterScheduleId')}
                                                            labelledBy="Select"
                                                            className="selectCustomClass"
                                                            menuPlacement="top"
                                                            menuPosition="top"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        { record.QuarterScheduleId?.length > 0 && (
                                            <div className="row mb-3">
                                                <label className="form-label col-form-label col-md-4">{languageTitles.quarterScheduleDetail}</label>
                                                <div className="col-md-8">
                                                    {!isEditing && <label className="col-form-label field">{record.QuarterScheduleDetail}</label>}
                                                    {isEditing && (
                                                        <div>
                                                            <textarea
                                                                rows={2}
                                                                type="text"
                                                                className="form-control"
                                                                name="QuarterScheduleDetail"
                                                                placeholder={languageTitles.quarterScheduleDetail}
                                                                required
                                                                onChange={handleInputChange}
                                                                value={record.QuarterScheduleDetail ?? ''}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.salaryPaymentFrecuency}</label>
                                            <div className="col-md-8">
                                                {!isEditing && (
                                                    <label className="col-form-label field">
                                                        {paymentFrequencyList?.filter((c) => record.SalaryPaymentFrecuencyId?.includes(c.value)).map((c) => c.label).toString()}
                                                    </label>
                                                )}
                                                {isEditing && (
                                                    <div>
                                                        <MultiSelect
                                                            hasSelectAll={false}
                                                            options={paymentFrequencyList}
                                                            value={paymentFrequencyList?.filter((c) => record?.SalaryPaymentFrecuencyId?.includes(c.value))}
                                                            onChange={(val) => setMultipleSelected(val, 'SalaryPaymentFrecuencyId')}
                                                            labelledBy="Select"
                                                            className="selectCustomClass"
                                                            menuPlacement="top"
                                                            menuPosition="top"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4 border-bottom border-light">{languageTitles.workShift}</label>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.workShiftInit}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.WorkShiftInit}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <textarea
                                                            rows={4}
                                                            type="text"
                                                            className="form-control"
                                                            name="WorkShiftInit"
                                                            placeholder={languageTitles.detailLabel}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.WorkShiftInit ?? ''}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.workShiftComment}</label>
                                            <div className="col-md-8">
                                                {!isEditing && <label className="col-form-label field">{record.WorkShiftComment}</label>}
                                                {isEditing && (
                                                    <div>
                                                        <textarea
                                                            type="text"
                                                            rows={2}
                                                            className="form-control"
                                                            name="WorkShiftComment"
                                                            placeholder={languageTitles.workShiftComment}
                                                            required
                                                            onChange={handleInputChange}
                                                            value={record.WorkShiftComment ?? ''}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className="form-label col-form-label col-md-4">{languageTitles.employeeMustProvideToolsOrEquipment}</label>
                                            <div className="col-md-8 mt-1">
                                                <YesNoControl
                                                    name="EmployeeMustProvideToolsOrEquipment"
                                                    value={record.EmployeeMustProvideToolsOrEquipment}
                                                    onChange={handleInputChange}
                                                    disabled={!isEditing}
                                                />
                                            </div>
                                        </div>
                                        { record.EmployeeMustProvideToolsOrEquipment && (
                                            <div className="row mb-3">
                                                <label className="form-label col-form-label col-md-4">{languageTitles.detailLabel}</label>
                                                <div className="col-md-8">
                                                    {!isEditing && <label className="col-form-label field">{record.EmployeeMustProvideToolsOrEquipmentDetail}</label>}
                                                    {isEditing && (
                                                        <div>
                                                            <textarea
                                                                rows={2}
                                                                type="text"
                                                                className="form-control"
                                                                name="EmployeeMustProvideToolsOrEquipmentDetail"
                                                                placeholder={languageTitles.detailLabel}
                                                                required
                                                                onChange={handleInputChange}
                                                                value={record.EmployeeMustProvideToolsOrEquipmentDetail ?? ''}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="float-end">
                                    <TSFButton
                                        action="custom"
                                        btnColor="primary"
                                        className="me-2"
                                        useIcon
                                        iconClass="fas fa-arrow-left"
                                        label={languageTitles.previousBtn}
                                        onClick={() => { togglePill('2'); }}
                                    />
                                    {!isDirty && (
                                        <TSFButton
                                            action="custom"
                                            btnColor="success"
                                            useIcon
                                            iconClass="fas fa-share"
                                            label={languageTitles.nextSectionPositionsBtn}
                                            onClick={() => { history.push(`/jobpositions/company/${Number(id)}`); }}
                                        />
                                    )}
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </PanelBody>
        </Panel>
    );
}

export default CompanyFull;
