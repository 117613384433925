/* eslint-disable no-undef */
import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useParams, useHistory } from 'react-router-dom';
import AppContext from '../../../config/appContext';
import { NotificationType } from '../../../enums/general';
import useMessageHelper from '../../../helpers/messageHelper';

import UserGraphql from '../../../servicesapollo/userGraphql';
import FooterCopyRight from './footerCopyRight';
import HeaderTitle from './headerTitle';

function ValidateAccount() {
    const { emx, vcx } = useParams();
    const history = useHistory();

    const { showNotification, languageTitles } = useContext(AppContext);
    const { getErrorMessage } = useMessageHelper();
    const [showMessage, setShowMessage] = useState(false);

    const [validateUser] = useLazyQuery(UserGraphql.VALIDATE_USER, {
        onCompleted: (mutationData) => {
            if (mutationData?.validateUser) {
                const {
                    success, message, error, // data,
                } = mutationData?.validateUser;

                if (!success) {
                    if (message) {
                        showNotification(message, NotificationType.INFO);
                        return;
                    }

                    showNotification(
                        error?.length > 0 ? error : languageTitles.errorWhenValidateAccount,
                        NotificationType.DANGER,
                    );
                    return;
                }

                setShowMessage(true);
            }
        },
        onError: (error) => {
            showNotification(getErrorMessage(error), NotificationType.DANGER);
        },
    });

    useEffect(() => {
        if (emx && vcx) {
            validateUser({
                variables: {
                    emx,
                    vcx,
                },
            });
        }
    }, [emx, vcx]);

    return (
        <>
            <div className="login login-v2 fw-bold">
                <div className="login-cover">
                    <div className="login-cover-img" style={{ backgroundImage: 'url(/assets/img/login-bg/quebec_city.jpg)' }} />
                    <div className="login-cover-bg" />
                </div>

                <div className="login-container">
                    <HeaderTitle />

                    <div className="login-content">
                        <form>
                            <div className="form-floating mb-20px text-center">
                                {languageTitles.validatingAccount}
                                {' '}
                                <i className="fa fa-spinner fa-spin" />
                            </div>
                            <FooterCopyRight />
                        </form>
                    </div>
                </div>
            </div>
            {(showMessage
                        && (
                            <SweetAlert
                                success
                                confirmBtnText={languageTitles.sweetAlertConfirmBtnText}
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="default"
                                title={languageTitles.validationSweetTitle}
                                onConfirm={() => history.push('/login')}
                            >
                                {languageTitles.accountValidateSuccess}
                            </SweetAlert>
                        )
            )}
        </>
    );
}

export default ValidateAccount;
