/* eslint-disable no-undef */
import React from 'react';
import { Redirect } from 'react-router-dom';
import Companies from '../components/pages/company/companies';
// import Login from '../components/pages/login/login';
import DiscAnalytics from '../components/pages/psycotest/discanalytics';
import Disctest from '../components/pages/psycotest/disctest';
import PsycoTestList from '../components/pages/psycotest/psycotestlist';
import TermanAnalytics from '../components/pages/psycotest/termananalytics';
import TermanTest from '../components/pages/psycotest/termantest';
import Users from '../components/pages/user/users';
import ManageFases from '../components/pages/manage-fases/manage-fases';
import JobPositions from '../components/pages/jobs/jobs';
import Register from '../components/pages/login/register';
import ForgotPassword from '../components/pages/login/forgot-password';
import ResetPassword from '../components/pages/login/reset-password';
import ValidateAccount from '../components/pages/login/validate-account';
import ValidAccount from '../components/pages/login/validaccount';
import Applicants from '../components/pages/applicant/applicants';
import User from '../components/pages/user/user';
import Permissions from '../components/pages/permissions/permission';
import Applicant from '../components/pages/applicant/applicant';
import DashboardV2 from '../components/pages/dashboard/dashboardv2';
import Documents from '../components/pages/documents/documents';
import DocumentSender from '../components/pages/documents/documentSender';
import EmailTemplateV2 from '../components/pages/email/emailTemplateV2';
import LoginV2 from '../components/pages/login/loginV2';
import ApplicantProcessed from '../components/pages/applicant/applicantProcessed';
import CompanyFull from '../components/pages/company/companyFull';
import LoginCompany from '../components/pages/login/loginCompany';
import EmailInbox from '../components/pages/email/emailInbox';
import CompanyJobPositions from '../components/pages/company/companyJobPositions';
import CompanyEmployee from '../components/pages/company/companyEmployee';
import CompanyGovermentFiles from '../components/pages/company/companyGovermentFiles';

const authProtectedRoutes = [
    {
        id: 1, path: '/home', exact: true, component: DashboardV2, title: 'Inicio',
    },
    {
        id: 2, path: '/companies', exact: true, component: Companies, title: 'Compañias',
    },
    {
        id: 3, path: '/users/page/:pageNumber/show/:pageResult', exact: true, component: Users, title: 'Usuarios',
    },
    {
        id: 4, path: '/pruebas', exact: true, component: PsycoTestList, title: 'Pruebas',
    },
    {
        id: 5, path: '/administrarfases', exact: true, component: ManageFases, title: 'Fases',
    },
    {
        id: 6, path: '/jobspositions', exact: true, component: JobPositions, title: 'Puestos de trabajo',
    },
    {
        id: 7, path: '/emailstemplate', exact: true, component: EmailTemplateV2, title: 'Plantilla de correos',
    },
    {
        id: 8, path: '/permissions', exact: true, component: Permissions, title: 'Permisos',
    },
    {
        id: 9, path: '/user/:id', exact: true, component: User, title: 'Usuario',
    },
    {
        id: 10, path: '/process-application/:id', exact: true, component: ApplicantProcessed, title: 'Procesamiento de Candidato',
    },
    {
        id: 11, path: '/applicant/:id', exact: true, component: Applicant, title: 'Candidato',
    },
    {
        id: 12, path: '/applicants/page/:pageNumber/show/:pageResult', component: Applicants, title: 'Candidatos',
    },
    {
        id: 13, path: '/test/termanresult/:id', exact: true, component: TermanAnalytics, title: 'Resultado de Prueba',
    },
    {
        id: 14, path: '/test/discresult/:id', exact: true, component: DiscAnalytics, title: 'Resultado de Prueba',
    },
    {
        id: 15, path: '/test/prueba/:id', exact: true, component: TermanTest, title: 'Prueba 1',
    },
    {
        id: 16, path: '/test/prueba2/:id', exact: true, component: Disctest, title: 'Prueba 2',
    },
    {
        id: 17, path: '/documents', exact: true, component: Documents, title: 'Documentos',
    },
    {
        id: 18, path: '/manage-documents', exact: true, component: DocumentSender, title: 'Enviar Documentos',
    },
    {
        id: 19, path: '/jobpositions/company/:companyId', exact: true, component: CompanyJobPositions, title: 'Puestos de trabajo por Compañia',
    },
    {
        id: 20, path: '/company/:id', exact: true, component: CompanyFull, title: 'Compañia',
    },
    {
        id: 21, path: '/', exact: true, component: () => <Redirect to="/home" />,
    },
    {
        id: 22, path: '/emails/page/:pageNumber/show/:pageResult', exact: true, component: EmailInbox, title: 'Emails',
    },
    {
        id: 23, path: '/employees/company/:companyId', exact: true, component: CompanyEmployee, title: 'Empleados de la compañia',
    },
    {
        id: 24, path: '/files/company/:companyId', exact: true, component: CompanyGovermentFiles, title: 'Archivos de gobierno de la compañia',
    },
];

const publicRoutes = [
    {
        id: 51, path: '/register', component: Register, title: 'Register',
    },
    {
        id: 52, path: '/forgotpassword', component: ForgotPassword, title: 'Olvide la contraseña',
    },
    {
        id: 53, path: '/resetpassword/:emx?/:vcx?', component: ResetPassword, title: 'Cambiar la contraseña',
    },
    {
        id: 54, path: '/validateaccount/:emx?/:vcx?', component: ValidateAccount, title: 'Validando acceso',
    },
    {
        id: 55, path: '/validaccount/:emx?/:vcx?', component: ValidAccount, title: 'Cuenta validada',
    },
    // { path: '/logout', component: Logout },
    // { path: '/auth-lock-screen', component: AuthLockScreen },
];

if (window?.location?.host?.includes('app.ritsf.com')) {
    publicRoutes.push({
        id: 50, path: '/login', component: LoginCompany, title: 'Login Company',
    });
} else {
    publicRoutes.push({
        id: 50, path: '/login', component: LoginV2, title: 'Login',
    });
    if (window?.location?.host?.includes('localhost')) {
        publicRoutes.push({
            id: 60, path: '/logincompany', component: LoginCompany, title: 'Login Company',
        });
    }
}

export { authProtectedRoutes, publicRoutes };
