/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import axios from 'axios';

const languageApiSettings = {
    type: 'service_account',
    project_id: 'tsf-application-340604',
    private_key_id: '1e0f0915f6465b96b30dfb17ec2f2193b116675a',
    private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC1rg+5xVUEOG1U\noO1PCHaWN8LzDmzKHsdBAyY1tjHAUiuxvD9k6a//r9ee35kF/UYPO3oAWJ9CjPyd\nFnW+fvmN3mov+lgzdqxJcoBj51DAGwShiuYAqj0fhzNcc+Zp6I3GmM4wNwyjVk+P\nDW/wmnYH+AzRshrGXBfAWm4UISZmiZhP97sqHJ+RVSticmHbAmM2bMPR2EPpAYwn\nry5kdIcJY5F9U7tih3gyrOf3hKQyzmfJrHO9j8mxznofye85Z724VRixmZkx0HFd\nCS77u/TI0bW2PAqOy5vBxV/D14RBLUnxU1kbh9v6IcLb5g6871wYPT9w4SWUnhcn\n9brdgaxzAgMBAAECggEAObpNFOI22Vh0pOddgDdYNvMQ5to2hyKwfV/azEZQGKQD\nbj46hn4ahkwBX2H85YJMuJpVcba3JBZYTjHMx/LgqMt7QjNxwyh6JfxT/2qIpgHv\nvsS7wIpXUaVMFJxfIlfadLgpC/jx8xSBwxqp7vGlf3K54AePqmeoxF33jD3cTcfv\nuF7eTiH03rtmLpC+3o7e+R4ouBupgELNLF/5BX8gUsF8n69pdBnURXAjQhYZyTO6\nXNbV/JtAU4YDxfs2IRFRH0LlkQN6cSEtWEn/Tykxb1GGhgjcPUUj66hlCJmg1tfw\n9UDFEqsTwPfDrVr+gYsh1QKt/zTawSDaLHV3nQA4AQKBgQD/GHT+QfE0MT1Fp/zW\niqGUOd4MHeW3kRTsETMuuvq99LFQtiXM6TKxbW8njcLQkrNa2iIwvfjAllRcak0C\nlehggxbBgiP7+kXFWStKf22VBbNUPjY5DJgoJqZSRxrq5H+Yc1mdOK8t+Rms18et\n4GdDItwXVF3WakutpzicICmI8wKBgQC2UveXeSGlZGrXvSCzjAE+nJrj4euk4dqP\nk1K5eP0CMquSsAScgb+tHFLKH0p7nMraYLlV7+/63KcrCP965UyW7mItompcJvmH\naV67wVcepkQsRJgh4cdBC5QUEHjRztnaotW1vwu94v3/VUWrjNl8+gt1Y8e8QhPr\npoJCBCgugQKBgGaOgpqmED8XfLbAbtoiKgvZp/ffHTfL0EmIMLec3Kn58j+su03C\ng2rpwEJ6r9QC5rCj+hL2jUeAKLJu2j8r3ZzPlxp9I9JuMBbDXrA4wumivRIlYM9W\nzOV3hG6Xv66n5tR3q5OV8nfdKgFfTxwMl/dZ0HpgpMlLOtm53PyHwasZAoGBAIdZ\nmExutDegEGEQGhKwQMxGXgYGVXUV5bboW2UtGEZlb1F6MjItcCoG+L7r89NIVp8/\nZtcRREorPD6Kc368ctspJOFTzTmUBXItGxKvOQmbzMhAM+ATSGRVQByVDdxTfw78\nC0LdDM5ukLjYiNwNt/c7eP4wkLqFI1yxQ8nWZC4BAoGBANvyrsdPTDEIhqr+usQ8\n8mvvB5RQEdFk42zAt4H9Qtj+pD28ZAGFxQ5b48f8Xi3m2ovhppfbwwq62MrTf+u7\npF0waSiIwA+bKL++N/Ml/VV/jsbcDjnG2FdHhEKGOjqKlTUWwJD7tYWEozXPOKyN\nI+Lgmng2RqMcaAcKLsg9uJug\n-----END PRIVATE KEY-----\n',
    client_email: 'tsf-service-account@tsf-application-340604.iam.gserviceaccount.com',
    client_id: '100247221935176284710',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    client_x509_cert_url: 'https://www.googleapis.com/robot/v1/metadata/x509/tsf-service-account%40tsf-application-340604.iam.gserviceaccount.com',
};

const useLanguage = (sourceLanguage = 'Es', targetLanguage = 'En') => {
    const translate = async (text) => {
        const result = await axios.post(
            'https://translation.googleapis.com/language/translate/v2',
            {
                key: languageApiSettings,
                source: sourceLanguage,
                target: targetLanguage,
                q: text,
            },
            // {
            //     headers: {
            //         'X-HTTP-Method-Override': 'GET',
            //     },
            // },
        );
    };

    return {
        translate,
    };
};

export default useLanguage;
