/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useReducer } from 'react';
import {
    Switch, useHistory, HashRouter, Route,
} from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { ReactNotifications } from 'react-notifications-component';
// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import MainLayout from './mainLayout';
import NonAuthLayout from './nonAuthLayout';
import { authProtectedRoutes, publicRoutes } from './routes';
import AppRoute from './routes/appRoute';
import AppContext from './config/appContext';
import LangEs from './config/lang-es';
import LangFr from './config/lang-fr';
import LangEn from './config/lang-en';
import { FetchPolicy } from './enums/general';
import CoreGraphql from './servicesapollo/coreGraphql';
import useNotification from './components/hooks/useNotification';
import usePermission from './components/hooks/usePermission';
import useCustomLocalStorage from './components/hooks/useCustomLocalStorage';
import UserGraphql from './servicesapollo/userGraphql';
import NotificationGraphql from './servicesapollo/notificationGraphql';
import 'react-notifications-component/dist/theme.css';
import 'moment/locale/fr-ca';
import 'moment/locale/en-gb';
import 'moment/locale/es';

export const ACTION_TYPES = {
    SET_STATE_VALUES: 'setStateValue',
    SET_INITIAL_STATE: 'setInitialState',
};

const reducer = (state, action) => {
    switch (action.type) {
    case ACTION_TYPES.SET_STATE_VALUES: {
        return { ...state, ...action.value };
    }
    case ACTION_TYPES.SET_INITIAL_STATE: {
        return action.value;
    }
    default:
        return state;
    }
};

function App() {
    const {
        clearLocalStorage, getUserAuth, setLanguageLS, getLanguageLS,
    } = useCustomLocalStorage();

    const initLang = getLanguageLS();

    const initState = {
        appTheme: 'yellow',
        appDarkMode: false,
        appGradientEnabled: false,

        appHeaderNone: false,
        appHeaderFixed: true,
        appHeaderInverse: false,
        appHeaderMegaMenu: false,
        appHeaderLanguageBar: true,
        hasScroll: false,

        appSidebarNone: false,
        appSidebarWide: false,
        appSidebarLight: false,
        appSidebarMinify: false,
        appSidebarMobileToggled: false,
        appSidebarTransparent: false,
        appSidebarSearch: false,
        appSidebarFixed: true,
        appSidebarGrid: false,

        appSidebarFloatSubMenuActive: false,
        appSidebarFloatSubMenu: '',
        appSidebarFloatSubMenuTop: 'auto',
        appSidebarFloatSubMenuLeft: 'auto',
        appSidebarFloatSubMenuBottom: 'auto',
        appSidebarFloatSubMenuLineTop: 'auto',
        appSidebarFloatSubMenuLineBottom: 'auto',
        appSidebarFloatSubMenuArrowTop: 'auto',
        appSidebarFloatSubMenuArrowBottom: 'auto',
        appSidebarFloatSubMenuOffset: '',

        appContentNone: false,
        appContentClass: '',
        appContentFullHeight: false,

        appTopMenu: false,
        appTopMenuMobileToggled: false,

        appSidebarTwo: false,

        appSidebarEnd: false,
        appSidebarEndToggled: false,
        appSidebarEndMobileToggled: false,

        font: {
            family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
            size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
            weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
        },
        color: {
            componentColor: window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim(),
            componentBg: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim(),
            dark: window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim(),
            light: window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim(),
            blue: window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim(),
            indigo: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim(),
            purple: window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim(),
            pink: window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim(),
            red: window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
            orange: window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim(),
            yellow: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim(),
            green: window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim(),
            success: window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim(),
            teal: window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim(),
            cyan: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim(),
            white: window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim(),
            gray: window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim(),
            lime: window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim(),
            gray100: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim(),
            gray200: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim(),
            gray300: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim(),
            gray400: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim(),
            gray500: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim(),
            gray600: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim(),
            gray700: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim(),
            gray800: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim(),
            gray900: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim(),
            black: window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim(),
            componentColorRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim(),
            componentBgRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim(),
            darkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim(),
            lightRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim(),
            blueRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim(),
            indigoRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim(),
            purpleRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim(),
            pinkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim(),
            redRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim(),
            orangeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim(),
            yellowRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim(),
            greenRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim(),
            successRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim(),
            tealRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim(),
            cyanRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim(),
            whiteRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim(),
            grayRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim(),
            limeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim(),
            gray100Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim(),
            gray200Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim(),
            gray300Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim(),
            gray400Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim(),
            gray500Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim(),
            gray600Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim(),
            gray700Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim(),
            gray800Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim(),
            gray900Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim(),
            blackRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim(),
        },
        // eslint-disable-next-line no-nested-ternary
        languageTitles: initLang === 'Fr' ? LangFr : (initLang === 'En' ? LangEn : LangEs),
        currentLanguage: initLang,
        applicantMessageId: 0,
        loggedUser: {},
        // loggedUser: {
        //     UserId: 1939,
        //     Email: 'eatgamuzita_23@hotmail.com',
        //     ProfilePicture: 'https://tsffiles.blob.core.windows.net/travailleur/users/1/611237af-afc7-4716-8f13-3a019a72df50_41b489f7-49a8-43c2-b02d-12c7a8e58943_profilepicture.jpeg',
        //     FullName: 'Ernesto Antonio Toruno Delgado',
        //     UserType: 'company',
        //     EntityId: 940,
        // },
        coreCatalogs: [],
        permissions: [],
        notifications: [],
        isDirtyData: false,
        // eslint-disable-next-line no-nested-ternary
        currentLocaleDate: initLang === 'Fr' ? 'fr-ca' : (initLang === 'En' ? 'en-ca' : 'es'),
    };

    const history = useHistory();

    const [state, dispatch] = useReducer(reducer, initState);

    const { showNotification } = useNotification();

    const { permissions: permissionLoaded, userData } = usePermission(state.permissions, state.loggedUser.UserId);

    const dispatchEvent = (value, type = ACTION_TYPES.SET_STATE_VALUES) => {
        dispatch({
            type,
            value,
        });
    };

    const toggleAppSidebarMinify = (e) => {
        e.preventDefault();
        dispatchEvent({
            appSidebarMinify: !state.appSidebarMinify,
            appSidebarFloatSubMenuActive: state.appSidebarMinify ? false : state.appSidebarFloatSubMenuActive,
        });
    };
    const toggleAppSidebarMobile = (e) => {
        e.preventDefault();
        dispatchEvent({
            appSidebarMobileToggled: !state.appSidebarMobileToggled,
        });
    };
    const handleSetAppSidebarNone = (value) => {
        dispatchEvent({
            appSidebarNone: value,
        });
    };
    const handleSetAppSidebarMinified = (value) => {
        dispatchEvent({
            appSidebarMinify: value,
        });
    };
    const handleSetAppSidebarWide = (value) => {
        dispatchEvent({
            appSidebarWide: value,
        });
    };
    const handleSetAppSidebarLight = (value) => {
        dispatchEvent({
            appSidebarLight: value,
        });
    };
    const handleSetAppSidebarTransparent = (value) => {
        dispatchEvent({
            appSidebarTransparent: value,
        });
    };
    const handleSetAppSidebarSearch = (value) => {
        dispatchEvent({
            appSidebarSearch: value,
        });
    };
    const handleSetAppSidebarFixed = (value) => {
        if (value === true && !state.appHeaderFixed) {
            dispatchEvent({
                appHeaderFixed: value,
            });
        }
        dispatchEvent({
            appSidebarFixed: value,
        });
    };
    const handleSetAppSidebarGrid = (value) => {
        dispatchEvent({
            appSidebarGrid: value,
        });
    };

    const toggleAppSidebarEnd = (e) => {
        e.preventDefault();
        dispatchEvent({
            appSidebarEndToggled: !state.appSidebarEndToggled,
        });
    };
    const toggleAppSidebarEndMobile = (e) => {
        e.preventDefault();
        dispatchEvent({
            appSidebarEndMobileToggled: !state.appSidebarEndMobileToggled,
        });
    };
    const handleSetAppSidebarEnd = (value) => {
        dispatchEvent({
            appSidebarEnd: value,
        });
    };

    let appSidebarFloatSubMenuRemove;
    let appSidebarFloatSubMenuCalculate;
    const appSidebarFloatSubMenuRemoveTime = 250;
    const handleAppSidebarFloatSubMenuOnMouseOver = (e) => {
        e.preventDefault(); // mio
        clearTimeout(appSidebarFloatSubMenuRemove);
        clearTimeout(appSidebarFloatSubMenuCalculate);
    };
    const handleAppSidebarFloatSubMenuOnMouseOut = (e) => {
        e.preventDefault(); // mio
        appSidebarFloatSubMenuRemove = setTimeout(() => {
            dispatchEvent({
                appSidebarFloatSubMenuActive: false,
            });
        }, appSidebarFloatSubMenuRemoveTime);
    };
    const handleAppSidebarOnMouseOver = (e, menu) => {
        if (state.appSidebarMinify) {
            if (menu.children) {
                const left = `${document.getElementById('sidebar').offsetWidth + document.getElementById('sidebar').offsetLeft}px`;

                clearTimeout(appSidebarFloatSubMenuRemove);
                clearTimeout(appSidebarFloatSubMenuCalculate);

                dispatchEvent({
                    appSidebarFloatSubMenu: menu,
                    appSidebarFloatSubMenuActive: true,
                    appSidebarFloatSubMenuLeft: left,
                });

                const offset = e.currentTarget.offsetParent.getBoundingClientRect();

                appSidebarFloatSubMenuCalculate = setTimeout(() => {
                    const targetTop = offset.top;
                    const windowHeight = window.innerHeight;
                    const targetHeight = document.querySelector('.app-sidebar-float-submenu-container').offsetHeight;
                    let top; let bottom; let arrowTop; let arrowBottom; let lineTop; let
                        lineBottom;

                    if ((windowHeight - targetTop) > targetHeight) {
                        top = `${offset.top}px`;
                        bottom = 'auto';
                        arrowTop = '20px';
                        arrowBottom = 'auto';
                        lineTop = '20px';
                        lineBottom = 'auto';
                    } else {
                        const aBottom = (windowHeight - targetTop) - 21;
                        top = 'auto';
                        bottom = '0';
                        arrowTop = 'auto';
                        arrowBottom = `${aBottom}px`;
                        lineTop = '20px';
                        lineBottom = `${aBottom}px`;
                    }

                    dispatchEvent({
                        appSidebarFloatSubMenuTop: top,
                        appSidebarFloatSubMenuBottom: bottom,
                        appSidebarFloatSubMenuLineTop: lineTop,
                        appSidebarFloatSubMenuLineBottom: lineBottom,
                        appSidebarFloatSubMenuArrowTop: arrowTop,
                        appSidebarFloatSubMenuArrowBottom: arrowBottom,
                        appSidebarFloatSubMenuOffset: offset,
                    });
                }, 0);
            } else {
                appSidebarFloatSubMenuRemove = setTimeout(() => {
                    dispatchEvent({
                        appSidebarFloatSubMenu: '',
                        appSidebarFloatSubMenuActive: false,
                    });
                }, appSidebarFloatSubMenuRemoveTime);
            }
        }
    };
    const handleAppSidebarOnMouseOut = (e) => {
        e.preventDefault(); // mio
        if (state.appSidebarMinify) {
            appSidebarFloatSubMenuRemove = setTimeout(() => {
                dispatchEvent({
                    appSidebarFloatSubMenuActive: false,
                });
            }, appSidebarFloatSubMenuRemoveTime);
        }
    };
    const handleAppSidebarFloatSubMenuClick = () => {
        if (state.appSidebarMinify) {
            const windowHeight = window.innerHeight;
            const targetHeight = document.getElementById('app-sidebar-float-submenu').offsetHeight;
            const targetTop = state.appSidebarFloatSubMenuOffset.top;
            const top = ((windowHeight - targetTop) > targetHeight) ? targetTop : 'auto';
            const left = `${state.appSidebarFloatSubMenuOffset.left + document.getElementById('sidebar').offsetWidth}px`;
            const bottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : '0';
            const arrowTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
            const arrowBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : `${(windowHeight - targetTop) - 21}px`;
            const lineTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
            const lineBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : `${(windowHeight - targetTop) - 21}px`;

            dispatchEvent({
                appSidebarFloatSubMenuTop: top,
                appSidebarFloatSubMenuLeft: left,
                appSidebarFloatSubMenuBottom: bottom,
                appSidebarFloatSubMenuLineTop: lineTop,
                appSidebarFloatSubMenuLineBottom: lineBottom,
                appSidebarFloatSubMenuArrowTop: arrowTop,
                appSidebarFloatSubMenuArrowBottom: arrowBottom,
            });
        }
    };

    const handleSetAppContentNone = (value) => {
        dispatchEvent({
            appContentNone: value,
        });
    };
    const handleSetAppContentClass = (value) => {
        dispatchEvent({
            appContentClass: value,
        });
    };
    const handleSetAppContentFullHeight = (value) => {
        dispatchEvent({
            appContentFullHeight: value,
        });
    };

    const handleSetAppHeaderNone = (value) => {
        dispatchEvent({
            appHeaderNone: value,
        });
    };
    const handleSetAppHeaderFixed = (value) => {
        if (value === false && state.appSidebarFixed) {
            dispatchEvent({
                appSidebarFixed: false,
            });
        }
        dispatchEvent({
            appHeaderFixed: value,
        });
    };
    const handleSetAppHeaderInverse = (value) => {
        dispatchEvent({
            appHeaderInverse: value,
        });
    };
    const handleSetAppHeaderMegaMenu = (value) => {
        dispatchEvent({
            appHeaderMegaMenu: value,
        });
    };
    const handleSetAppHeaderLanguageBar = (value) => {
        dispatchEvent({
            appHeaderLanguageBar: value,
        });
    };

    const handleSetAppTopMenu = (value) => {
        dispatchEvent({
            appTopMenu: value,
        });
    };
    const toggleAppTopMenuMobile = (e) => {
        e.preventDefault();
        dispatchEvent({
            appTopMenuMobileToggled: !state.appTopMenuMobileToggled,
        });
    };

    const updateLoggedUser = (val) => {
        dispatchEvent({
            loggedUser: val,
        });
    };
    // const handleSetAppSidebarTwo = (value) => {
    //     dispatchEvent({
    //         appSidebarTwo: value,
    //     });
    //     dispatchEvent({
    //         appSidebarEndToggled: value,
    //     });
    // };
    const handleSetAppBoxedLayout = (value) => {
        if (value === true) {
            document.body.classList.add('boxed-layout');
        } else {
            document.body.classList.remove('boxed-layout');
        }
    };
    const handleSetColor = () => {
        dispatchEvent({
            color: {
                componentColor: window.getComputedStyle(document.body).getPropertyValue('--app-component-color').trim(),
                componentBg: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg').trim(),
                dark: window.getComputedStyle(document.body).getPropertyValue('--bs-dark').trim(),
                light: window.getComputedStyle(document.body).getPropertyValue('--bs-light').trim(),
                blue: window.getComputedStyle(document.body).getPropertyValue('--bs-blue').trim(),
                indigo: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo').trim(),
                purple: window.getComputedStyle(document.body).getPropertyValue('--bs-purple').trim(),
                pink: window.getComputedStyle(document.body).getPropertyValue('--bs-pink').trim(),
                red: window.getComputedStyle(document.body).getPropertyValue('--bs-red').trim(),
                orange: window.getComputedStyle(document.body).getPropertyValue('--bs-orange').trim(),
                yellow: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow').trim(),
                green: window.getComputedStyle(document.body).getPropertyValue('--bs-green').trim(),
                success: window.getComputedStyle(document.body).getPropertyValue('--bs-success').trim(),
                teal: window.getComputedStyle(document.body).getPropertyValue('--bs-teal').trim(),
                cyan: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan').trim(),
                white: window.getComputedStyle(document.body).getPropertyValue('--bs-white').trim(),
                gray: window.getComputedStyle(document.body).getPropertyValue('--bs-gray').trim(),
                lime: window.getComputedStyle(document.body).getPropertyValue('--bs-lime').trim(),
                gray100: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100').trim(),
                gray200: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200').trim(),
                gray300: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300').trim(),
                gray400: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400').trim(),
                gray500: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500').trim(),
                gray600: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600').trim(),
                gray700: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700').trim(),
                gray800: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800').trim(),
                gray900: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900').trim(),
                black: window.getComputedStyle(document.body).getPropertyValue('--bs-black').trim(),
                componentColorRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-color-rgb').trim(),
                componentBgRgb: window.getComputedStyle(document.body).getPropertyValue('--app-component-bg-rgb').trim(),
                darkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-dark-rgb').trim(),
                lightRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-light-rgb').trim(),
                blueRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-blue-rgb').trim(),
                indigoRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-indigo-rgb').trim(),
                purpleRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-purple-rgb').trim(),
                pinkRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-pink-rgb').trim(),
                redRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-red-rgb').trim(),
                orangeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-orange-rgb').trim(),
                yellowRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-yellow-rgb').trim(),
                greenRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-green-rgb').trim(),
                successRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-success-rgb').trim(),
                tealRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-teal-rgb').trim(),
                cyanRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-cyan-rgb').trim(),
                whiteRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-white-rgb').trim(),
                grayRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-rgb').trim(),
                limeRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-lime-rgb').trim(),
                gray100Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-100-rgb').trim(),
                gray200Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-200-rgb').trim(),
                gray300Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-300-rgb').trim(),
                gray400Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-400-rgb').trim(),
                gray500Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-500-rgb').trim(),
                gray600Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-600-rgb').trim(),
                gray700Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-700-rgb').trim(),
                gray800Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-800-rgb').trim(),
                gray900Rgb: window.getComputedStyle(document.body).getPropertyValue('--bs-gray-900-rgb').trim(),
                blackRgb: window.getComputedStyle(document.body).getPropertyValue('--bs-black-rgb').trim(),
            },
        });
    };
    const handleSetAppDarkMode = (value) => {
        if (value === true) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
        handleSetColor();
        dispatchEvent({
            appDarkMode: value,
        });
    };

    const handleSetAppGradientEnabled = (value) => {
        dispatchEvent({
            appGradientEnabled: value,
        });
    };
    const handleSetFont = () => {
        dispatchEvent({
            font: {
                family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
                size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
                weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
            },
        });
    };
    const handleSetAppTheme = (value) => {
        const newTheme = `theme-${value}`;
        for (let x = 0; x < document.body.classList.length; x += 1) {
            if ((document.body.classList[x]).indexOf('theme-') > -1 && document.body.classList[x] !== newTheme) {
                document.body.classList.remove(document.body.classList[x]);
            }
        }
        document.body.classList.add(newTheme);
    };

    // #region metodos mios
    const hasPermission = (permission) => {
        if (state?.loggedUser.UserType === 'superadmin') return true;
        const res = state?.permissions?.includes(permission);
        return res;
    };

    // const handleScroll = () => {
    //     if (window.scrollY > 0) { dispatchEvent({ hasScroll: true }); } else { dispatchEvent({ hasScroll: false }); }
    // };

    const setLanguage = (language) => {
        // const languageToSet = language !== 'Es' && language === 'En' ? 'Fr' : language;
        dispatchEvent({
            currentLanguage: language,
            // eslint-disable-next-line no-nested-ternary
            languageTitles: language === 'Fr' ? LangFr : language === 'En' ? LangEn : LangEs,
            // eslint-disable-next-line no-nested-ternary
            currentLocaleDate: language === 'Fr' ? 'fr-ca' : (language === 'En' ? 'en-ca' : 'es'),
        });
        setLanguageLS(language);
    };

    const setApplicantMessageId = (applicantId) => {
        dispatchEvent({
            applicantMessageId: applicantId,
            appSidebarTwo: applicantId > 0,
            appSidebarEndToggled: applicantId > 0,
            appSidebarMinify: applicantId > 0,
            appSidebarFloatSubMenuActive: state.appSidebarMinify ? false : state.appSidebarFloatSubMenuActive,
        });
    };

    const [getGlobalData] = useLazyQuery(CoreGraphql.GET_CORE_CATALOGS_LIST, {
        onCompleted: (res) => {
            if (res.coreCatalogs) {
                const coreCatalogProcessed = res?.coreCatalogs.map((c) => (
                    {
                        value: c.Id,
                        Es: c.Es,
                        Fr: c.Fr,
                        En: c.En,
                        orden: c.Orden,
                        category: c.CatalogType,
                    }
                ));

                dispatchEvent({
                    coreCatalogs: coreCatalogProcessed,
                });
            }
        },
        // onError: (error) => {
        //     showNotification(getErrorMessage(error), NotificationType.DANGER);
        // },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getNotificationsByUser, { refetch: refreshNotificationByUser }] = useLazyQuery(NotificationGraphql.GET_NOTIFICATION_BY_USER, {
        variables: {
            userId: state?.loggedUser?.UserId,
        },
        onCompleted: (res) => {
            if (res.notificationByUser) {
                const {
                    data,
                } = res.notificationByUser;

                if (data && data.length > 0) {
                    dispatchEvent({
                        notifications: data,
                    });
                }
            }
        },
        // onError: (error) => {
        //     showNotification(getErrorMessage(error), NotificationType.DANGER);
        // },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const goToLogin = () => {
        window.location.href = '/#/login';
        window.location.reload();
    };

    const [logOutUser] = useLazyQuery(UserGraphql.LOGOUT_USER, {
        onCompleted: () => {
            clearLocalStorage();
            goToLogin();
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const onLogout = () => {
        const currentUser = getUserAuth();
        if (!currentUser) {
            goToLogin();
            return;
        }

        logOutUser({
            variables: {
                ux: Number(currentUser.UserId),
            },
        });
    };

    useEffect(() => {
        // window.addEventListener('scroll', handleScroll);
        handleSetColor();
        handleSetFont();
        handleSetAppTheme(state.appTheme);
        if (state?.loggedUser?.UserId > 0) {
            getGlobalData();
            getNotificationsByUser();
        }

        return () => {
            // window.removeEventListener('scroll', handleScroll);
        };
    }, [state?.loggedUser?.UserId]);

    useEffect(() => {
        if (userData?.UserId > 0) {
            dispatchEvent({
                permissions: permissionLoaded,
                loggedUser: userData,
            });
        }
    }, [userData?.UserId]);

    useEffect(() => {
        const checkClientSite = window?.location?.host?.includes('app.ritsf.com') || state?.loggedUser?.IsCompanyUser;
        const languageLS = getLanguageLS();
        if (checkClientSite && !languageLS) {
            setLanguage('Fr');
        } else {
            setLanguage(languageLS ?? 'Es');
        }
    }, []);

    const setIsDirtyData = (value) => {
        dispatchEvent({
            isDirtyData: value,
        });
    };

    const linkToUrl = (url) => {
        history.push(url);
    };

    const setCompanyToEdit = (companySelected) => {
        updateLoggedUser({
            ...state?.loggedUser,
            ProfilePicture: companySelected.ImageUrl,
            FullName: companySelected.CompanyName,
            EntityId: companySelected.CompanyId,
        });
    };

    function downloadPDF(html, fileName, format = 'A4', orientation = 'portrait') {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                html,
                pdfOptions: {
                    format,
                    orientation,
                    printBackground: false,
                    border: {
                        left: 20,
                        right: 20,
                        bottom: 20,
                        top: 20,
                    },
                    margin: {
                        top: 20,
                        bottom: 20,
                        left: 20,
                        right: 20,
                    },
                },
            }),
        };
        fetch(`${process.env.REACT_APP_PDF_EXPORT_URL}/convertpdf`, requestOptions)
            .then((res) => { console.log(res); return res.blob(); })
            .then((blob) => {
                console.log(blob);
                const linkSource = window.URL.createObjectURL(blob);
                const downloadLink = document.createElement('a');
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            });
    }

    function converToPdf(base64Data, fileName) {
        const linkSource = `data:application/pdf;base64,${base64Data}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    // #endregion

    // #region definicion de metodos
    const methodsToChangeState = {
        handleSetAppHeaderNone,
        handleSetAppHeaderInverse,
        handleSetAppHeaderLanguageBar,
        handleSetAppHeaderMegaMenu,
        handleSetAppHeaderFixed,
        handleSetAppSidebarNone,
        handleSetAppSidebarWide,
        handleSetAppSidebarLight,
        handleSetAppSidebarMinified,
        handleSetAppSidebarTransparent,
        handleSetAppSidebarSearch,
        handleSetAppSidebarFixed,
        handleSetAppSidebarGrid,
        handleAppSidebarOnMouseOut,
        handleAppSidebarOnMouseOver,
        toggleAppSidebarMinify,
        toggleAppSidebarMobile,
        handleAppSidebarFloatSubMenuOnMouseOver,
        handleAppSidebarFloatSubMenuOnMouseOut,
        handleAppSidebarFloatSubMenuClick,
        handleSetAppContentNone,
        handleSetAppContentClass,
        handleSetAppContentFullHeight,
        toggleAppTopMenuMobile,
        handleSetAppTopMenu,
        // handleSetAppSidebarTwo,
        toggleAppSidebarEnd,
        toggleAppSidebarEndMobile,
        handleSetAppSidebarEnd,

        handleSetAppBoxedLayout,
        handleSetAppDarkMode,
        handleSetAppGradientEnabled,
        handleSetAppTheme,

        handleSetColor,
        // showLayout,
        // setCurrentUser,
        // setGlobalEmailsToSend,
        // removeEmail,
        // setGlobalUserToFase,
        // removeUserToFase,
        showNotification,

        // history.push,
        setLanguage,
        setApplicantMessageId,
        hasPermission,
        onLogout,
        refreshNotificationByUser,
        updateLoggedUser,
        setIsDirtyData,
        linkToUrl,
        setCompanyToEdit,
        downloadPDF,
        converToPdf,
    };
    // #endregion

    return (
        <>
            <ReactNotifications />
            <AppContext.Provider value={{
                ...state,
                ...methodsToChangeState,
            }}
            >
                <HashRouter>
                    <Route path="/">
                        <Switch>
                            {publicRoutes.map((route) => (
                                <AppRoute
                                    path={route.path}
                                    layout={NonAuthLayout}
                                    component={route.component}
                                    key={route.id}
                                    isAuthProtected={false}
                                />
                            ))}

                            {authProtectedRoutes.map((route) => (
                                <AppRoute
                                    path={route.path}
                                    layout={MainLayout}
                                    component={route.component}
                                    key={route.id}
                                    isAuthProtected
                                    exact
                                />
                            ))}
                        </Switch>
                    </Route>
                </HashRouter>
            </AppContext.Provider>
        </>
    );
}

export default App;
