import React, { useState, useContext } from 'react';
import {
    InputGroup, Input, Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AppContext from '../../config/appContext';

function SearchField(props) {
    const { languageTitles } = useContext(AppContext);
    const { returnSearchValue, returnOnClear, initialValue } = props;
    const [searchValue, setSearchValue] = useState(initialValue);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            returnSearchValue(searchValue);
        }
    };

    const onClear = (e) => {
        e.preventDefault();

        setSearchValue('');

        returnOnClear();
    };

    return (
        <InputGroup>
            <Input
                placeholder={languageTitles.writeToSearch}
                type="input"
                className="input-white"
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleKeyDown}
                value={searchValue}
            />
            {searchValue && (
                <Button
                    color="danger"
                    onClick={(e) => onClear(e)}
                >
                    <i className="fa fa-times" />
                </Button>
            ) }
            <Button
                color="primary"
                onClick={() => returnSearchValue(searchValue)}
            >
                <i className="fa fa-search" />
            </Button>
        </InputGroup>
    );
}

SearchField.propTypes = {
    returnSearchValue: PropTypes.func.isRequired,
    returnOnClear: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
};

SearchField.defaultProps = {
    initialValue: '',
};

export default SearchField;
