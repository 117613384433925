const Lang = {
    // general labels
    searchApplication: 'Client Search ',
    notificationLabel: 'Notifications',
    notificationViewMore: 'Show more',
    nightMode: 'Dark',
    lightMode: 'Light',
    editProfileTitle: 'Update profile',
    closeAppTitle: 'Log out',
    writeToSearch: 'Type here to search',
    loadingRecords: 'Loading regristers',
    loadingRecord: 'Loading regrister',
    recordNotFound: 'No record found with this id',

    // menu sidebar
    menuHome: 'Home',
    menuApplicants: 'Applicants',
    menuAddCV: 'Add curriculum',
    menuEditCV: 'Edit curriculum',
    menuAdminFases: 'Manage phases',
    menuCompanies: 'Companies',
    menuUsers: 'Users',
    menuEmailTemplates: 'Email templates',
    menuJobs: 'Job positions',
    menuPyscoTest: 'Pyschometric test',
    menuDocumentShared: 'Shared documents',
    menuAccess: 'Permissions',
    menuManageDocuments: 'Send documents',
    menuEditCompany: 'Company Information',
    menuEmails: 'Emails',
    menuEditCompanyJobPositions: 'Positions to be filled',
    menuEditCompanyEmployees: 'Work force within the company',
    menuEditCompanyFiles: 'Job postings',

    // Dashboard,
    dashboardTotalUpdates: 'Updated in the last 60 days',
    dashboardTotal: 'Total of candidates',
    dashboardTotalToday: 'Today\'s request',
    dashboardGraphTitle: 'Requests per month and year',
    dashboardGraphLegend: 'Applicants',
    dashboardGrahpBarGenderTitle: 'Candidates by gender',
    dashboardPsycoTestTableLabel: 'psychometric tests',
    dashboardPsycoTestNoRecordsFound: 'No tests authorized',
    dashboardPsycoTestColumnDetail: 'Detail',
    dashboardPsycoTestColumnAction: 'Accion',
    dashboardPsycoTestColumnStatus: 'Status',
    dashboardPsycoTestEnableLegend: 'Has been enabled for',
    dashboardPsycoTestUnableTest: 'The test is no longer available ',
    dashboardPsycoTestStartBtn: 'Start',
    dashboardPsycoTestDISC: 'Test 1',
    dashboardPsycoTestTerman: 'Test 2',
    dashboardProfileChartComplete: 'Profile complete',
    dashboardProfileChartPendingPoints: 'Pending points',
    dashboardProfileChartCompleteLabel: '% Complete',
    dashboardProfileChartPendingLabel: '% Pending',
    dashboardLastLoginTitle: 'Last accesses',
    dashboardlinkTitle: 'Access',
    dashboardCompanyWelcomeMessage: `<p>Dear Customer,</p>
    <p>
    It is our pleasure to collaborate with you for the realization of your project of recruitment.
    </p>
    <p>
    Through this questionnaire, TSF will collect all the information necessary for the analysis of the file and to properly target the candidates who will be added to your team.
    </p>
    <p>
    We will also ensure that we have all the data required to satisfy the Temporary Foreign Worker Program through which your workers will be hired.
    </p>
    <p>
    Do not hesitate to contact a member of the TSF team for any questions; it will be our pleasure to guide you.
    </p>`,
    dashboardCompanyStartButton: 'Start',

    // candidates
    candidatesTableColumnId: 'Id',
    candidatesTableColumnImage: 'Image',
    candidatesTableColumnName: 'Name',
    candidatesTableColumnEmail: 'Email',
    candidatesTableColumnAge: 'Age',
    candidatesTableColumnProfession: 'Profession',
    candidatesTableColumnJobsApplicant: 'Position that applies',
    candidatesTableColumnActions: 'Options',
    candidatesEditBtnTitle: 'Update candidate information',
    candidatesExportBtnTitle: 'Export to  PDF',
    candidatesSendEmailBtnTitle: 'Send a email',
    candidatesMessageBtnTitle: 'Add coment',
    candidatesWhatsappBtnTitle: 'Write on whatsapp',
    candidatesProcessApplicationBtnTitle: 'Process application',
    candidatesEnableTermanBtnTitle: 'Enable Test Terman',
    candidatesEnableDISCBtnTitle: 'Enable Test DISC',
    candidatesActionDropdownLabel: 'Actions',
    candidatesActionDropdownAddFase: 'Add to a phase',
    candidatesActionDropdownAddLabel: 'Add color to label',
    candidatesActionDropdownAsignCompany: 'Assign to a company',
    candidatesActionDropdownSentEmail: 'Send email',
    candidatesActionButtonsMoreFilter: 'Apply others filters',
    candidatesActionButtonsExportPDF: 'Export list to pdf',
    candidatesActionButtonsExportExcel: 'Export list to excel',
    candidatesActionButtonsRefresh: 'Refresh data',
    candidatesActionButtonsRestart: 'Clean filters',
    candidatesActionButtonsSearch: 'Search',
    candidatesFiltersSortBy: 'Sort by',
    candidatesFiltersSortByNameASC: 'Name ASC',
    candidatesFiltersSortByNameDESC: 'Name DESC',
    candidatesFiltersSortByLastNameASC: 'Last name ASC',
    candidatesFiltersSortByLastNameDESC: 'Last name DESC',
    candidatesFiltersSortByCountryASC: 'Country ASC',
    candidatesFiltersSortByCountryDESC: 'Country DESC',
    candidatesFiltersSortByAgeASC: 'Age ASC',
    candidatesFiltersSortByAgeDESC: 'AGE DESC',
    candidatesFiltersWithoutJob: 'Clean filters',
    candidatesFiltersAgeLabel: 'Age',
    candidatesFiltersSelectDates: 'Select dates',
    candidatesFiltersGender: 'Genre',
    candidatesFiltersProfesion: 'Profession',
    candidatesFiltersJobs: 'Positions',
    candidatesFiltersCountry: 'Country',

    candidateTabProfile: 'Profile',
    candidateTabStudies: 'Studies',
    candidateTabExperience: 'Experience',
    candidateTabFiles: 'Files',
    candidateTabJob: 'Job skills',
    candidateJobPosition: 'Job you are applying',
    candidateName: 'Names',
    candidateLastName: 'Last names',
    candidateBirthday: 'Date of birth',
    candidateMaritalStatus: 'Marital status',
    candidateGender: 'Genre',
    candidateIdentification: 'ID',
    candidateHasLicence: 'You have a driver\'s license',
    candidateCategoryLicence: 'License category',
    candidateProfession: 'Profession',
    candidateEnglishLevel: 'English level',
    candidateFrenchLevel: 'French level',
    candidateChildrens: 'You have kids',
    candidateChildrensCount: 'Number of children',
    candidateCountryBorn: 'Country of birth',
    candidateCityBorn: 'City ​​of birth',
    candidateDepartmentBorn: 'Department or municipality of birth',
    candidateCountryResident: 'Country of residence',
    candidateCityResident: 'City of residence',
    candidateDepartmentResident: 'Department or municipality of residence',
    candidateAddress: 'Address',
    candidateAddress2: 'Address 2',
    candidateCellphone: 'Cell phone',
    candidateHomePhone: 'Home phone',
    candidateEmail: 'Email',
    candidateHasPassport: 'You have a passport',
    candidatePassport: 'Passport number',
    candidatePassportExpirationDate: 'Passport expiration date',
    candidateHasTravel: 'Indicate the last two trips made',
    candidateStartDateTravel: 'Entry date',
    candidateEndDateTravel: 'Departure date',
    candidateCountryTravel: 'Country',
    candidateAboutMe: 'Work experiences',
    candidateVisaDenied: 'You have been denied a visa',
    candidateVisaDeniedDate: 'Visa denial date',
    candidateVisaDeniedComments: 'Reasons why it was denied',
    candidateLiveInOtherCountry: 'You have lived in another country for more than 6 months',
    candidateLivedCountry: 'Which country?',
    candidateLivedCountryDate: 'Which date?',
    candidateLivedCountryDatePlaceHolder: 'Date you lived outside the country',
    candidateLivedCountryMigrateStatus: 'What was your immigration status',
    candidateLivedCountryMigrateStatusPlaceHolder: 'Immigration status',
    candidateLeadSource: 'How did you hear about us?',
    candidateIndicationsToFillData: 'Enter the data in chronological order by typing from most recent first to oldest last',
    candidateStudiesCenter: 'Study Center',
    candidateStudiesStart: 'Start date',
    candidateStudiesEnd: 'End date',
    candidateStudiesTitle: 'Obtained title',
    candidateStudiesTitleType: 'Type of diploma obtained',
    candidateExperienceCompany: 'Name of the company',
    candidateExperienceStart: 'Start date',
    candidateExperienceEnd: 'End date',
    candidateExperienceJob: 'Position',
    candidateExperienceJobDescription: 'Job description',
    candidateMessageLabel: 'Comments',
    candidateExperienceCompanyLocation: 'Company Location',
    candidateExperienceCompanyLocationPlaceholder: 'City or Municipality',

    // actions
    saveTitle: 'Save',
    savingTitle: 'Saving',
    updateTitle: 'Edit',
    updatingTitle: 'Updating',
    refreshTitle: 'Refresh',
    refreshingTitle: 'Refreshing',
    deleteTitle: 'Delete',
    deletingTitle: 'Deleting',
    sendTitle: 'Send',
    sendingTitle: 'Sending',
    cancelTitle: 'Cancel',
    cancelingTitle: 'Canceling',
    printTitle: 'Print',
    printJobBtnTitle: 'Post job offer',
    printingTitle: 'Printing',
    uploadingTitle: 'Uploading',
    donwloadTitle: 'Download',
    chooseTitle: 'Select',
    closeTitle: 'Close',
    backTitle: 'Go back',
    removeButton: 'Remove',
    addButton: 'Add',
    nextBtn: 'Next',
    previousBtn: 'Back',
    clearBtnTitle: 'Clear',
    nextSectionPositionsBtn: 'Positions section',
    nextSectionWorkforceBtn: 'Workforce section',
    nextSectionJobPostingBtn: 'Job Posting section',
    notifiqueToCompanyAdminBtn: 'Done – Submit to TSF',

    // company
    companyAddBtn: 'Add company',
    companyPhoneLabel: 'telephone',
    companyAddress: 'Address',
    companyUserAssociatedBtnTitle: 'Associate candidates',
    companyEditBtnTitle: 'Edit company',
    companyPopupAddTitle: 'Add company',
    companyPopupEditTitle: 'Updating company data',
    companyContactName: 'Name of the contact person',
    companyPrintFullReport: 'Print full report',

    // users
    usersTableColumnId: 'Id',
    usersTableColumnImage: 'Image',
    usersTableColumnName: 'Name',
    usersTableColumnEmail: 'Email',
    usersTableColumnActions: 'Actions',
    usersBtnValidateUser: 'Validate users',
    usersBtnChangePassword: 'Change Password',
    usersActionDropdownValidate: 'Validate users',
    usersActionDropdownDelete: 'Delete users',
    userChangePasswordTitle: 'Password change',
    userOldPassword: 'Old Password',
    userNewPassword: 'New Password',
    userAssociateUser: 'Associate user',
    userVerifyPassword: 'Verify password',
    userToken: 'Token',
    userRequestToken: 'Request token',
    userProfileTitle: 'Updating users',
    userImageIndication: 'Note: the photo must be with a white background, presentable and smiling',

    // email template
    emailTemplateTitle: 'Template title',
    emailContentTitle: 'Template content',

    // psycotest
    psycoTestTableColumnName: 'Name',
    psycoTestTableColumnEmail: 'Email',
    psycoTestTableColumnTest: 'Test',
    psycoTestTableColumnActive: 'Active',
    psycoTestTableColumnExpiration: 'Email',
    psycoTestTableColumnStatus: 'Expiration',
    psycoTestTableColumnActions: 'Status',
    psycoTestBtnAnalize: 'Analyze',

    termanCategory1: 'Information',
    termanCategory2: 'Compression',
    termanCategory3: 'Verbal',
    termanCategory4: 'Logical selection',
    termanCategory5: 'Arithmetic',
    termanCategory6: 'Practical judgment',
    termanCategory7: 'Analogies',
    termanCategory8: 'Ordering',
    termanCategory9: 'Classification',
    termanCategory10: 'Serialization',
    termanChart1Title: 'IQ assessment terman tsf version',
    termanChart1TotalLabel: 'Total',
    termanChart2Title: 'Answered vs unanswered questions',
    termanChart2Serie1: 'Right answers',
    termanChart2Serie2: 'Wrong ansers',
    termanChart2Serie3: 'No answers',
    termanConclusionPercentageLabel1: 'Low',
    termanConclusionPercentageLabel2: 'Lower',
    termanConclusionPercentageLabel3: 'Medium',
    termanConclusionPercentageLabel4: 'High',
    termanConclusionPercentageLabel5: 'Higher',
    termanConclusionPercentageLabel6: 'Excelent',
    termanCandidateName: 'Name',
    termanCorrectAnswer: 'Right answers',
    termanAveragePercentage: 'Weighted average',
    termanApplyTo: 'Aspiring to',
    termanTestLabel: '# test',
    termanResultLabel: 'Score',
    termanConclusionLabel: 'Conclusions',
    termanClassificationLabel: 'Classification',

    discChart1Title: 'DISC test evaluation',
    discChart1Serie1: 'Dominant',
    discChart1Serie2: 'Influential',
    discChart1Serie3: 'Stable',
    discChart1Serie4: 'Conscientious',
    discPatternLabel: 'Pattern of',
    discPatternLabel1: 'Emotions',
    discPatternLabel2: 'Goal',
    discPatternLabel3: 'Judge others by',
    discPatternLabel4: 'Influence others',
    discPatternLabel5: 'Its value to',
    discPatternLabel6: 'Abuse of',
    discPatternLabel7: 'Under pressure',
    discPatternLabel8: 'Fear',
    discPatternLabel9: 'It would be more efficient if',

    // documents
    documentShareLegend: 'Shared files, download the them, fill them out and then upload them right here',
    documentRepositoryTableColumn1: 'Type of file',
    documentRepositoryTableColumn2: 'File name',
    documentRepositoryTableColumn3: 'URL',
    documentRepositoryTableColumn4: 'Date',
    documentRepositoryTableColumn5: 'Actions',
    documentRepositoryBtnChooseFile: 'Choose files',
    documentRepositoryChooseFileTitle: 'Select files',
    documentRepositoryBtnAddFile: 'Add',
    documentRepositoryBtnUploadFiles: 'Upload files',
    documentRepositoryBtnUploadingFile: 'Uploading file',
    documentRepositoryBtnUploadingFiles: 'Uploading files',

    // sweetalert for delete
    sweetAlertDeleteConfirmBtnText: 'Yes, proceed',
    sweetAlertDeleteCancelBtnText: 'Cancel',
    sweetAlertDeleteTitle: 'Are you sure?',
    sweetAlertDeleteConfirm: 'The change cannot be reversed!',
    // sweetalert to confirm
    sweetAlertConfirmBtnText: 'Continue',
    sweetAlertCancelBtnText: 'Cancel',

    // inbox
    mailInboxCreateEmail: 'Write',
    mailInboxFolderAll: 'All',
    mailInboxFolderUnread: 'Unread',
    mailInboxFolderImportant: 'Important',
    mailInboxFolderSent: 'Sent',
    mailInboxMessageLabel: 'Messages',
    mailDetailReply: 'Answer',
    mailDetailFrom: 'From',
    mailDetailTo: 'For',
    mailSentCopy: 'Copy',
    mailSentBlankCopy: 'Hidden copy',
    mailSelectTemplate: 'Select template',
    mailSelectFiles: 'Add files',
    mailSelectFile: 'Add file',

    // pagination
    pageLabel: 'Page',
    pageSeparator: 'From',
    pageFirst: 'First page',
    pageNext: 'Next page',
    pagePrevious: 'Previous page',
    pageLast: 'Last page',

    // company form
    companyTabIdentification: '1- Customer ID',
    companyTabHistoryRecruitment: '2- Recruitment history',
    companyTabConditions: '3- Working conditions and salaries',
    companyName: 'Name of the company according to the registry des entreprises',
    companyImageUrl: 'Company logo',
    mainAddressStreet: 'Main address',
    mainAddressStreetPlaceHolder: 'Street and street number',
    mainAddressOffice: 'Office',
    mainAddressCity: 'City',
    mainAddressProvince: 'Province',
    mainAddressPostalCode: 'Postal Code',
    isJobSameMainAddress: 'Is the workplace the same as the main address?',
    jobAddressStreet: 'Address',
    jobAddressOffice: 'Office',
    jobAddressCity: 'City',
    jobAddressProvince: 'Province',
    jobAddressPostalCode: 'Postal Code',
    haveAnotherJobAddress: 'Do you want to add another workplace?',
    otherJobAddressStreet: 'Address',
    otherJobAddressOffice: 'Office',
    otherJobAddressCity: 'City',
    otherJobAddressProvince: 'Province',
    otherJobAddressPostalCode: 'Postal Code',
    coordinatesPhone: 'Primary phone number',
    coordinatesFax: 'Fax number',
    coordinatesWebsiteLink: 'Company website',
    contactName: 'Name of the main contact person',
    contactTitle: 'Title',
    contactPhone: 'Phone number',
    contactPoste: 'Position',
    contactEmail: 'Email address',
    hasSecondaryContact: 'Add another contact person',
    secondaryContactName: 'Secondary contact person Name',
    secondaryContactTitle: 'Title',
    secondaryContactPhone: 'Phone number',
    secondaryContactPoste: 'Position',
    secondaryContactEmail: 'Email address',
    companySigner: 'Who will be the signer?',
    companySignerText: 'The signer must be the owner, franchisee, principal manager, or principal executive (president, vice president, etc.)',
    companySignerEmail: 'Email of the person signing',
    accountsPayableResponsible: 'Name of the person in charge of accounts payable',
    accountsPayableResponsibleEmail: 'Accounts payable manager email',
    companyIsFranchise: 'Is the company a franchise?',
    companyOrganizationType: 'What is the type of organization of the company?',
    companyFoundationDate: 'Business start date',
    companyBusinessNumber: 'CRA business number',
    companyNEQ: 'Québec company number (NEQ)',
    companyRevenueOverflow: 'The company earned more than $5 million in annual gross receipts in its last fiscal year?',
    companyAllowEDSC: 'Does the company receive support from EDSC\'s shared work program?',
    companyEDSCDescription: 'Program details',
    companyJobPositionSyndicate: 'Is the position to be filled unionized?',
    companyJobPositionSyndicateName: 'Syndicate name',
    companyJobPositionSyndicateLocal: 'Name of the collective agreement',
    hasInternationalRecruitmentBefore: 'Has the company ever recruited workers through the Temporary Foreign Worker program?',
    hasEmployeeBenefit: 'Does the company provide benefits to employees?',
    benefitDisabilityInsurance: 'Disability insurance',
    benefitDentalInsurance: 'Dental insurance',
    benefitPensionPlan: 'Pension plan provided by the employer',
    benefitMedicalInsurance: 'Complementary medical insurance (medicines, paramedical care, etc.)',
    benefitWorkWear: 'Workwear',
    benefitWorkShoes: 'Work boots',
    benefitVisionGlasses: 'Glasses adapted to the eye',
    benefitOther: 'Other benefit',
    hasAdditionalVacation: 'Does the company offer more legal vacations than those stipulated by labor regulations?',
    additionalVacationDescription: 'Explain',
    hoursPerWeekAllowed: 'How many hours per week will foreign workers work?',
    overtimeHoursAllowedAfter: 'Overtime is calculated after',
    overtimeHoursAllowedAfterDetail: 'Details',
    quarterSchedule: 'What shift will foreign workers have to work?',
    quarterScheduleDetail: 'Details',
    salaryPaymentFrecuency: 'What is the payment frequency?',
    hasBonus: 'Does the company offer bonuses to its employees (evening shift, production bonus or other)?',
    bonusType: 'Bonus type',
    bonus: 'Bonus type',
    bonusDetail: 'Details',
    workShift: 'Working hours',
    workShiftInit: 'Start Time and Ending Time',
    workShiftEnd: 'Ending Time',
    workShiftComment: 'Comments',
    employeeMustProvideToolsOrEquipment: 'Employee must provide tools or equipment upon arrival',
    recruitmentJobPositionTitle: 'Job',
    recruitmentAcceptanceDate: 'Date of acceptance',
    recruitmentInCourse: 'In progress',
    recruitmentSalaryType: 'Regular High / Low Salary', // low or high
    recruitmentSimplifiedProcessing: 'Simplified Processing',
    recruitmentSalary: 'Worker\'s current salary',
    recruitmentComment: 'Comments',
    recruitmentTableHeader1: 'Labor market impact assessment (LMIA) Application',
    recruitmentTableHeader2: 'LMIA Type',
    detailLabel: 'Details',

    // company jobs
    companyTabIdentificationJob: 'Identification of the position to fill?',
    jobPositionTitle: 'What is the title of the position to be filled?',
    jobPositionNumber: 'How many workers are required for the position?',
    salaryPerHour: 'What will be the hourly wage available for foreign workers?',
    jobDescription: 'Detailed description of the task',
    jobDescriptionDocumentUrl: 'Attach detailed document of the task',
    miscellaneousConditions: 'Additional conditions',
    employmentStatusId: 'Employment status',
    monthsEmploymentPerYear: 'Indicate the number of months per year',
    educationalLevelId: 'Educational level',
    yearsOfExperience: 'Years of experience',

    // company employees
    companyTabEmployeeTitle: 'Company Workforce',
    companyTabEmployeeTab1Title: 'Employee numbers',
    companyTabEmployeeTab2Title: 'Employment Record',
    alienEmployeeTitle: '', // 'The company hired foreign workers in 2024 or earlier? If yes or from May 25 to June 20. How many went?',
    fullTimeEmployees: 'Full-time employees (including T.E.T)',
    partTimeEmployees: 'Part-time employees (less than 30 hours) (including T.E.T)',
    temporaryEmployees: 'Temporary Foreign Worker',
    nationalEmployeeTitle: 'Total number of employees at the NATIONAL LEVEL under the CRA number cited above',
    localWorkingEmployeeTitle: 'Total number of employees OCCUPIING THE POSITION AT THE WORKPLACE',
    otherWorkingEmployeeTitle: 'Total number of employees AT ANOTHER WORKPLACE',
    companyEmployeeJobTitle: 'Job Title',
    temporaryFullTimeEmployees: 'Number of temporary full-time foreign workers',
    temporaryPartTimeEmployees: 'Number of temporary part-time foreign workers',
    canadianFullTimeEmployees: 'Number of Canadian full-time workers',
    canadianPartTimeEmployees: 'Number of Canadian part-time workers',

    temporaryRegisterEmployees: 'Number of Employment Registrations issued in the last 12 months for temporary foreign workers holding the position in question',
    canadianRegisterEmployees: 'Number of employment registrations issued in the last 12 months for Canadian workers holding the position in question',
    canadianRegisterQuestion: 'How many statements issued for',
    registerEmployeesJobTitle: 'Job Title',
    registerEmployeesBackToSchool: 'Back to School',
    registerEmployeesSickness: 'Sickness/Injury',
    registerEmployeesVoluntaryResignation: 'Voluntary departure',
    registerEmployeesMaternity: 'Maternity/Paternity/Parenting',
    registerEmployeesRetirement: 'Retirement',
    registerLackOfWork: 'Lack of work',
    registerEmployeesDismissal: 'Dismissal',
    registerEmployeesOther: 'Exact reason for dismissal',
    registerEmployeesReturn: 'How many workers have returned?',
    addJobButton: 'Add information for another position',
    addJobNewSection: 'Total employees at another workplace',
    removeSectionButton: 'Remove section',

    fileGovermentTab1Title: 'Job Postings',
    fileGovermentTab2Title: 'Additional documents to attach',
    companyTabFilesTitle: 'Government Files',
    fileGovermentTableColumn1: 'Fill file',
    fileGovermentTableColumn2: 'Type of document to download',
    fileGovermentTableColumn3: 'Name of uploaded file',
    fileGovermentTableColumn4: 'Actions',
    fileGovermentConsentImportant: 'IMPORTANT',
    fileGovermentConsent: `It is essential that all the posters demeurent actifs pour toute la durée du processus de recrutement, soit jusqu’à la réception des acceptances
    federal and provincial governments. SVP s'assurer de les renouveler avant leur expiration.`,
    fileGovermentConsentCheckbox: 'I understood',
    fileGovermentLowSalaries: 'Low Salary Positions',
    fileGovermentHighSalaries: 'Highly paid positions',
    fileGovermentExemption: 'C Statements: View Exemption - No View Required',
    fileAdditionalFiles: 'Proof of financial capacity',

    Yes: 'YES',
    No: 'NO',
    createUser: 'Create user for the company',
    accessDeniedPage: 'You do not have permission to access this page...',

    loginEmail: 'Email',
    loginPassword: 'Password',
    loginBtnLabel: 'Login',
    loginBtnAuthLabel: 'Authenticating...',
    loginForgotPasswordLabel: 'Forgot your password, Recover it ',
    loginForgotPasswordLinkLabel: 'here',
    forgotPasswordBtn: 'Recover password',
    forgotPasswordInProcessBtn: 'In process...',
    forgotPasswordBackLoginLabel: 'Back to ',
    forgotPasswordBackLoginLinkLabel: 'login',
    loginCopyRight: 'All Rights Reserved',
    loginNeedUser: 'Don\'t have a user? Sign up ',
    loginHelpValidateAccount: 'Do you need help validating your account? Click ',
    forgetPasswordMessageToResetPassword: 'A message has been sent to your email to reset your password',
    registerName: 'Name',
    registerLastName: 'LastName',
    registerPhone: 'Phone',
    registerJobApply: 'Apply position',
    registerConfirmPassword: 'Confirm Password',
    registerInProcessLabel: 'Registering user...',
    registerBtnLabel: 'Register',
    registerAlreadyHaveUser: 'Are you already registered? Access ',
    registerWelcomeMessage: 'Thank you for registering, an email has been sent to you to verify your account',
    validateBtnLabel: 'Validate account',
    validateConfirmMessage: 'A message has been sent to the system administrator to assist with account validation',

    exitWithoutSave: 'There is data you haven\'t saved, do you want to exit without saving?',

    errorWhenSave: 'An error occurred while saving the data.',
    errorWhenLoad: 'An error occurred while loading the data.',
    errorWhenDelete: 'An error occurred while deleting data.',
    successWhenSave: 'The data was saved successfully',
    successWhenDelete: 'The data was successfully deleted',
    errorWhenLoadMessages: 'An error occurred while loading messages',
    errorWhenEnableTest: 'An error occurred while enabling the test',
    successWhenEnableTest: 'The test was successfully activated for the user',
    enableTermanTest: 'Enable Terman test',
    enableDiscTest: 'Enable DISC test',
    successWhenDeleteTest: 'The test was successfully deleted',
    askEnalbeTest: 'Are you sure you want to enable the test?',
    askDeleteTest: 'Are you sure you want to delete the test?',
    deleteTestForApplicant: 'Delete the test for this applicant',
    errorWhenSaveTest: 'An error occurred while saving test data',
    errorToStartTest: 'An error occurred while trying to start the test',
    errorWhenDeleteFile: 'An error occurred while trying to delete the file',
    successWhenDeleteFile: 'The file was successfully deleted',
    errorWhenLoadFiles: 'There was an error loading files',
    errorWhenSentMail: 'An error occurred while sending the mail',
    successWhenSentEmail: 'The email was sent successfully',
    allowOnlyPdf: 'Only PDF files are allowed',
    errorWhenLoadMails: 'There was an error loading emails',
    userNeedLoginAgain: 'The password has been updated, the user must log in again',
    userSavedAndNeedLoginAgain: 'Your data has been updated, if you changed your password you have to log in again',
    userProvideEmail: 'You must enter the email',
    userProvideEmailOrSelect: 'Seleccione un usuario o agregue un correo y su contraseña',
    userProvideThePassword: 'You must enter the password',
    userProvideCurrentPassword: 'You must enter the current password',
    userProvidePassword: 'You must enter the new password',
    userConfirmPassword: 'You must confirm the new password',
    userPasswordDontMatch: 'Passwords don\'t match.',
    userProvideToken: 'You must enter the token that was sent to your email or request a new one',
    userDeleteSuccess: 'User deleted successfully',
    errorWhenRequestToken: 'An error occurred when requesting the token',
    codeSentToEmail: 'A code has been sent to your email',
    userErrorWhenValidate: 'An error occurred when validating the user',
    userSuccessWhenValidate: 'The user was successfully validated',
    userDeleteAllData: 'Delete all user data',
    userAskToDelete: 'Are you sure you want to delete the user and all his data?',
    userValidateManual: 'Validate the user manually',
    userAskValidateManual: 'Are you sure you want to validate the user?',
    usersDeleteAllData: 'Borrar todos los datos de los usuarios seleccionados',
    usersAskToDelete: 'Esta seguro que desea borrar los usuarios y todos sus datos?',
    usersValidateManual: 'Validar los usuarios seleccionados manualmente',
    usersAskValidateManual: 'Esta seguro que desea validar los usuarios?',
    applicantAssociatedToCompany: 'The selected candidates have been associated with the company',
    errorWhenCreateUser: 'An error occurred while creating the user',
    successWhenCreateUser: 'The user was successfully created',
    notAllowPermissionToAction: 'You do not have permission to perform this action',

    notifyAdmin: 'The administrator has been notified that you have filled in all the information.',
    unknowError: 'An error occurred on the server, contact the system administrator.',
    associatedUser: 'User',

    logoutSuccess: 'See you soon...',

    pickerImageTitle: 'Changing profile image',
    pickerImageLegend: 'Please select an option for the photo:',
    pickerImageText1: 'Take image from my gallery',
    pickerImageText2: 'Take image using my camera',
    pickerImageText3: 'Cancel',

    errorWhenAuthenticate: 'An error occurred while authenticating',
    errorWhenChangePassword: 'An error occurred while changing the password',
    errorWhenValidateAccount: 'An error occurred while validating the account',
    validatingAccount: 'VALIDATING THE ACCOUNT...',
    accountValidateSuccess: 'Your account has been successfully validated',
    validationSweetTitle: 'Account validation',
    validatePositionSelection: 'You must select the position',
    errorWhenRegisterUser: 'An error occurred while registering the user',
    selectLabel: 'Select',
    errorWhenRequestPassword: 'An error occurred while requesting to change your password',
    resetPasswordSweetTitle: 'Resetting password',
    errorWhenRequestValidateAccount: 'An error occurred while requesting validation',
    fileTypeSelectedAlreadyExists: 'The selected file type already exists',
    notSelectedFileInLine: 'You have not selected the file on the line ',
    notSelectedFileTypeInLine: 'You have not selected the file type in the line ',
    candidateAddedToNewFase: 'Candidates were added to the new phase',
    selectFase: 'Select the phase',
    changeFase: 'Change phase',
    changeTagColor: 'Added a tag color to selected candidates',
    addTagColor: 'Add a tag color',
    chooseTagColor: 'Choose Tag',
};

export default Lang;
