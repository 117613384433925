import React from 'react';
import PropTypes from 'prop-types';

function Logo(props) {
    const { customWidth } = props;
    const width = customWidth ? `${customWidth}%` : 'auto';

    return (
        <img width={width} style={{ margin: '10px' }} src="/assets/img/tsf-logo-white.png" alt="" />
    );
}

Logo.propTypes = {
    customWidth: PropTypes.string,
};

Logo.defaultProps = {
    customWidth: 'auto',
};

export default Logo;
